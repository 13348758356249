import cx from 'classnames';

export function ControlItem({
  beforeSection,
  afterSection,
  children,
  wrapperClassName,
  ...buttonProps
}: {
  beforeSection?: React.ReactNode;
  afterSection?: React.ReactNode;
} & Omit<React.ComponentProps<'button'>, 'type'> & {
    wrapperClassName?: React.ComponentProps<'li'>['className'];
  }) {
  return (
    <li className={cx('p-1.5', wrapperClassName)}>
      <button
        {...buttonProps}
        type="button"
        className={cx(
          'focus:z-2 relative flex w-full items-center gap-1 p-1 text-start',
          buttonProps.className
        )}
      >
        {beforeSection}
        <span className={cx('text-primary underline')}>{children}</span>
        {afterSection}
      </button>
    </li>
  );
}
