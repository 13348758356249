export function Search({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.2656,18.7344l-5-5a.353.353,0,0,0-.3523-.0741,5.4117,5.4117,0,1,0-.2522.2524.3543.3543,0,0,0,.0733.3529l5,5a.3756.3756,0,0,0,.5312-.5312ZM10,14.625A4.625,4.625,0,1,1,14.625,10,4.6308,4.6308,0,0,1,10,14.625Z" />
      </g>
    </svg>
  );
}
