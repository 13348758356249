import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dxbwawa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-36 fill-current text-bg antialiased md:w-40 lg:w-44" fillColor="#000" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="mr-2 w-36 fill-current text-bg antialiased md:mr-0 md:w-40"
    fillColor="#fff"
  />
);
