import { Close } from '@curated-property/icon-list';
import { useURLParamState, sanitize, SharedContext } from '@curated-property/utils';
import { Dialog, DialogTitle } from '@dx-ui/osc-dialog-v2';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { createContext, useCallback, useState, useContext } from 'react';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_merge, GIS_Padder } from '../functions/global-instance-styles';
import { HeadingStyle } from '../includes/heading-style';
import type { TagButtonOffersCuratedStyleProps } from './includes/tag';
import { TagButtonOffersCurated as TagButton, defaultStyles as tagStyles } from './includes/tag';
import type { TileOffersCuratedStyleProps } from './includes/tile';
import { defaultStyles as tileStyles, TileOffersCurated } from './includes/tile';
export interface OffersCuratedStyleProps extends StyleObject {
  tagButtons?: TagButtonOffersCuratedStyleProps;
  tiles?: TileOffersCuratedStyleProps;
}

export const defaultStyles: OffersCuratedStyleProps = {
  tagButtons: tagStyles,
  tiles: tileStyles,
};

export interface TagOffersCurated {
  id: string;
  label: string;
}

export interface OfferOffersCurated {
  id?: number;
  image?: string;
  imageAlt?: string;
  title?: string;
  copy?: string;
  tags?: TagOffersCurated[];
  additionalData?: {
    label: string;
    copy: string;
  }[];
  CTA?: {
    adaDescription?: string;
    isNewWindow?: boolean;
    label?: string;
    url?: string;
  };
  terms?: string;
  type?: string;
}

export interface OffersCuratedProps {
  globalStyles: OffersCuratedStyleProps | undefined;
  instanceStyles: OffersCuratedStyleProps | undefined;
  offers: OfferOffersCurated[];
  title?: string;
  copy?: string;
}

export const OffersCuratedContext = createContext<{
  styles: OffersCuratedStyleProps;
  addActiveTag: (newTag: string) => void;
  removeActiveTag: (removalTag: string) => void;
  isTagActive: (checkTag: string) => boolean;
  getActiveTagCount: () => number;
  enableHHR: boolean;
}>({
  styles: defaultStyles,
  addActiveTag: null,
  removeActiveTag: null,
  isTagActive: null,
  getActiveTagCount: null,
  enableHHR: null,
});

export function OffersCurated({
  title,
  copy,
  globalStyles = {},
  instanceStyles = {},
  offers,
}: OffersCuratedProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);

  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const styles = GIS_merge(defaultStyles, inlineStyles) as OffersCuratedStyleProps;
  const [activeId, setActiveId] = useState<number>(null);

  const toggleActive = useCallback(
    (id: number) => {
      if (id === activeId) {
        setActiveId(null);
      } else {
        setActiveId(id);
      }
    },
    [activeId]
  );

  const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

  const { val: activeTags, changeParamValue: setActiveTags } = useURLParamState({
    urlQueryParamName: 'offerTags',
  });

  const addActiveTag = useCallback(
    (newTag: string) => {
      let newTags = activeTags instanceof Array ? activeTags : activeTags.split(',');
      newTags = newTags.filter((v) => v !== '');
      if (newTags.includes(newTag)) return;
      newTags.push(newTag);
      void setActiveTags(newTags.join(','));
    },
    [activeTags, setActiveTags]
  );

  const removeActiveTag = useCallback(
    (removalTag: string) => {
      let newTags = activeTags instanceof Array ? activeTags : activeTags.split(',');
      newTags = newTags.filter((v) => v !== removalTag);
      void setActiveTags(newTags.join(','));
    },
    [activeTags, setActiveTags]
  );

  const isTagActive = (tagId: string) => {
    return activeTags.includes(tagId);
  };

  const getActiveTagCount = () => {
    return activeTags.length;
  };

  let activeTagsArray = activeTags instanceof Array ? activeTags : activeTags.split(',');
  activeTagsArray = activeTagsArray.filter((v) => v !== '');

  const allTags = [];

  const offerTiles = [];
  const activeTagObjs = [];
  for (let i = 0; i < offers.length; i++) {
    let matches = 0;
    offers[i]?.tags?.forEach((tagObj) => {
      if (!allTags?.find((obj) => obj.id === tagObj.id)) {
        allTags.push(tagObj);
      }
      if (activeTagsArray?.includes(tagObj.id)) {
        matches++;
        if (!activeTagObjs.find((obj) => obj.id === tagObj.id)) {
          activeTagObjs.push(tagObj);
        }
      }
    });
    if (matches >= activeTagsArray.length) {
      offerTiles.push(
        <TileOffersCurated
          key={`OfferCurated-${title}-tile-${i}`}
          id={i}
          active={activeId === i}
          setActive={toggleActive}
          offer={offers[i]}
        />
      );
    }
  }

  const [t] = useTranslation();

  const sharedContext = useContext(SharedContext);
  const enableHHR = sharedContext?.enableHHR;

  return (
    <OffersCuratedContext.Provider
      value={{
        styles,
        addActiveTag,
        removeActiveTag,
        isTagActive,
        getActiveTagCount,
        enableHHR,
      }}
    >
      <Dialog
        data-testid="offers-curated-filter-modal"
        isOpen={filterDialogOpen}
        onDismiss={() => setFilterDialogOpen(false)}
        hasChildrenWithTitle
      >
        <div className="flex flex-col pt-4">
          <div className="mb-4 flex items-center">
            <svg className="text-primary mr-2 size-5" viewBox="0 0 64 64" focusable="false">
              <path
                fill="currentColor"
                d="M3.176 12.058c0-3.405 2.849-6.183 6.339-6.183 3.488 0 6.337 2.777 6.337 6.183s-2.849 6.183-6.337 6.183c-3.49 0-6.339-2.766-6.339-6.183zM7.92 59.685c0 .869.718 1.568 1.609 1.568.888 0 1.607-.699 1.607-1.568V21.227c4.497-.752 7.946-4.564 7.946-9.168 0-5.139-4.288-9.308-9.541-9.308S.002 6.919.002 12.059c0 4.604 3.436 8.417 7.946 9.168v38.458h-.027zM25.563 51.945c0-3.403 2.849-6.185 6.339-6.185 3.488 0 6.337 2.782 6.337 6.185 0 3.405-2.849 6.185-6.337 6.185-3.49 0-6.339-2.78-6.339-6.185zm15.878 0c0-4.602-3.438-8.416-7.946-9.168V4.319c0-.869-.718-1.57-1.607-1.57s-1.607.701-1.607 1.57v38.458c-4.497.752-7.946 4.566-7.946 9.168 0 5.139 4.286 9.308 9.539 9.308 5.256 0 9.566-4.182 9.566-9.308zM48.107 12.058c0-3.405 2.849-6.183 6.339-6.183 3.488 0 6.337 2.777 6.337 6.183s-2.849 6.183-6.337 6.183c-3.504 0-6.339-2.766-6.339-6.183zm6.339 49.194c.886 0 1.607-.699 1.607-1.568V21.226c4.494-.752 7.946-4.564 7.946-9.168 0-5.139-4.288-9.308-9.541-9.308s-9.539 4.168-9.539 9.308c0 4.604 3.436 8.417 7.946 9.168v38.458c-.027.869.691 1.568 1.582 1.568z"
              />
            </svg>
            <DialogTitle asChild>
              <h2 className="text-lg font-bold">
                <span className="sr-only">{t('offers.offer')}</span>
                {t('offers.filter')}
              </h2>
            </DialogTitle>
          </div>

          {allTags.map((tag) => (
            <div className="mb-2 flex items-center border-b pb-2" key={tag?.label}>
              <label className="label label-inline-reverse w-full">
                <span>{tag.label}</span>
                <input
                  data-testid={`OfferCurated-tag-checkbox-${tag.id}`}
                  className="form-checkbox"
                  type="checkbox"
                  checked={isTagActive(tag.id)}
                  onChange={(event) => {
                    if (event.currentTarget.checked) {
                      addActiveTag(tag.id);
                    } else {
                      removeActiveTag(tag.id);
                    }
                  }}
                />
              </label>
            </div>
          ))}
          <div className="py-4 text-center font-bold">
            {t('offers.offersCount', { count: offerTiles.length })}
          </div>
          <div className="flex justify-center gap-2">
            <button
              data-testid="offers-curated-filter-modal-reset-filters"
              aria-label="remove all filters"
              className="btn btn-primary-outline !text-xs"
              onClick={() => setActiveTags('')}
            >
              {t('calendar.resetFilters')}
            </button>
            <button
              data-testid="offers-curated-filter-modal-close"
              className="btn btn-primary !text-xs"
              onClick={() => setFilterDialogOpen(false)}
            >
              {t('calendar.done')}
            </button>
          </div>
        </div>
      </Dialog>
      <section
        data-testid="offersContainer"
        data-element-id="curated-offers-container"
        className={cx(inlineStyles?.showHide && 'hidden', 'overflow-hidden', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
            ? 'url(' + inlineStyles?.componentBackgroundImage?.sourceUrl + ')'
            : '',
          backgroundColor: inlineStyles?.componentBackgroundColor || null,
          backgroundSize: inlineStyles?.componentBackgroundSize || null,
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        }}
      >
        <div className="container">
          <div className="mb-8 text-center">
            {title ? <HeadingStyle className="mb-4" text={title} styledAs="h1" /> : null}
            {copy ? <div dangerouslySetInnerHTML={{ __html: sanitize(copy) }} /> : null}
          </div>
          <div className="mb-10 flex flex-col items-center gap-2 md:flex-row">
            <div>
              <button
                data-testid="offers-curated-filter-toggle"
                className="btn btn-primary-outline rounded border-2 py-2"
                onClick={() => setFilterDialogOpen(true)}
              >
                <span className="sr-only">
                  {t('offers.tagsSelected', { count: activeTagObjs.length })}
                </span>
                <span className="sr-only">{t('offers.offer')}</span>
                {t('offers.filters')}
                {activeTagObjs.length > 0 ? ` (${activeTagObjs.length})` : null}
              </button>
              {activeTagObjs.length > 0 ? (
                <button
                  aria-label={t('offers.removeAllFilters')}
                  className="btn btn-primary-outline ml-2 whitespace-nowrap rounded-full border-2 px-4 py-2"
                  onClick={() => setActiveTags('')}
                >
                  {t('calendar.resetFilters')}
                </button>
              ) : null}
            </div>
            <div className="flex-2 flex flex-wrap justify-center gap-2">
              {activeTagObjs
                ? activeTagObjs.map((tag) => (
                    <TagButton
                      key={tag?.label}
                      label={`${tag?.label} filter remove`}
                      tag={tag}
                      onClick={(_e) => {
                        removeActiveTag(tag.id);
                      }}
                    >
                      <div className="flex items-center">
                        <span>{tag.label}</span>
                        <Close
                          className="relative right-[-6px] size-6"
                          fillColor={styles?.tagButtons?.textColor}
                        />
                      </div>
                    </TagButton>
                  ))
                : null}
            </div>
            <span className="text-right md:ml-auto">
              {t('offers.showingOffers', {
                visible: offerTiles.length,
                count: offers.length,
              })}
            </span>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-flow-row-dense md:grid-cols-2 md:gap-8 2xl:grid-cols-3">
            {offerTiles}
          </div>
        </div>
      </section>
    </OffersCuratedContext.Provider>
  );
}
