export function SettingsSolid({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m20.08 10.37-1.83-.3c-.12-.38-.27-.75-.46-1.11l1.08-1.52c.14-.2.12-.47-.05-.64l-1.61-1.61c-.17-.17-.44-.2-.64-.05l-1.52 1.08c-.35-.19-.72-.34-1.11-.46l-.3-1.83c-.04-.24-.25-.42-.49-.42h-2.28c-.25 0-.45.18-.49.42l-.3 1.83c-.38.12-.75.27-1.11.46l-1.52-1.08c-.2-.14-.47-.12-.64.05l-1.61 1.61c-.17.17-.2.44-.05.64l1.08 1.52c-.18.35-.34.72-.45 1.09l-1.84.31c-.24.04-.42.25-.42.49v2.28c0 .24.18.45.42.49l1.84.31c.12.38.27.75.46 1.11l-1.08 1.51c-.14.2-.12.47.05.65l1.61 1.61c.17.17.45.19.64.05l1.51-1.08c.35.19.72.34 1.1.46l.31 1.84c.04.24.25.42.49.42h2.28c.24 0 .45-.18.49-.42l.31-1.84c.38-.12.75-.27 1.1-.46l1.51 1.08c.2.14.47.12.65-.05l1.61-1.61c.17-.17.2-.45.05-.65l-1.08-1.51c.19-.36.34-.73.46-1.12l1.83-.3c.24-.04.42-.25.42-.49v-2.28c0-.25-.18-.45-.42-.49zm-5.58 1.63c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5z" />
    </svg>
  );
}
