export function MiniBar({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16,4.625H4A.3751.3751,0,0,0,3.625,5V19A.3751.3751,0,0,0,4,19.375H16A.3751.3751,0,0,0,16.375,19V5A.3751.3751,0,0,0,16,4.625Zm-.375,14H4.375V5.375h11.25Z" />
          <path d="M20,6.625H18.375V5a.375.375,0,0,0-.75,0V19a.375.375,0,0,0,.75,0V11.375H20A.3751.3751,0,0,0,20.375,11V7A.3751.3751,0,0,0,20,6.625Zm-.375,4h-1.25V7.375h1.25Z" />
          <path d="M11,17.375h3A.3751.3751,0,0,0,14.375,17V12a3.1848,3.1848,0,0,0-.4062-1.6162A2.6228,2.6228,0,0,1,13.625,9V7a.3751.3751,0,0,0-.375-.375h-1.5A.3751.3751,0,0,0,11.375,7V9a2.6228,2.6228,0,0,1-.3438,1.3838A3.1848,3.1848,0,0,0,10.625,12v5A.3751.3751,0,0,0,11,17.375ZM11.375,12a2.4456,2.4456,0,0,1,.3271-1.28A3.3679,3.3679,0,0,0,12.125,9V7.375h.75V9a3.3679,3.3679,0,0,0,.4229,1.72A2.4456,2.4456,0,0,1,13.625,12v4.625h-2.25Z" />
          <path d="M6,17.375H9A.3751.3751,0,0,0,9.375,17V12a3.1848,3.1848,0,0,0-.4062-1.6162A2.6228,2.6228,0,0,1,8.625,9V7a.3751.3751,0,0,0-.375-.375H6.75A.3751.3751,0,0,0,6.375,7V9a2.6228,2.6228,0,0,1-.3438,1.3838A3.1848,3.1848,0,0,0,5.625,12v5A.3751.3751,0,0,0,6,17.375ZM6.375,12a2.4456,2.4456,0,0,1,.3271-1.28A3.3679,3.3679,0,0,0,7.125,9V7.375h.75V9a3.3679,3.3679,0,0,0,.4229,1.72A2.4456,2.4456,0,0,1,8.625,12v4.625H6.375Z" />
        </g>
      </g>
    </svg>
  );
}
