export function HotelLocation({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M7.375,10C7.3749,9.7929,7.2071,9.6251,7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v5
            c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
            C7.2071,10.3749,7.3749,10.2071,7.375,10z"
            />
            <path
              d="M16.625,5v5c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V5C17.3739,4.2411,16.7589,3.6261,16,3.625h-6
            C9.2411,3.6261,8.6261,4.2411,8.625,5v11c0,0.2071,0.1679,0.375,0.375,0.375S9.375,16.2071,9.375,16V5
            C9.3753,4.655,9.655,4.3753,10,4.375h6C16.345,4.3753,16.6247,4.655,16.625,5z"
            />
            <path
              d="M13,11.625h-2c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h2c0.2071,0,0.375-0.1679,0.375-0.375
            S13.2071,11.625,13,11.625z"
            />
            <path
              d="M15,9.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,9.625,15,9.625z"
            />
            <path
              d="M15,7.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,8.375,11,8.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,7.625,15,7.625z"
            />
            <path
              d="M15,5.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,6.375,11,6.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,5.625,15,5.625z"
            />
            <path
              d="M7.0068,13.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,14.375,6,14.375h1.0068c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2139,13.625,7.0068,13.625z"
            />
            <path
              d="M7,11.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,12.375,6,12.375h1c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,11.625,7,11.625z"
            />
            <path
              d="M17,11.625c-1.8573-0.0067-3.3683,1.4935-3.375,3.3508c-0.0027,0.7369,0.2368,1.4543,0.6816,2.0418l2.3926,3.207
            c0.0708,0.0945,0.182,0.15,0.3,0.15H17c0.1181,0.0003,0.2294-0.0554,0.3-0.15l2.4-3.2c1.1183-1.4911,0.816-3.6064-0.6751-4.7247
            C18.4407,11.8618,17.7302,11.625,17,11.625z M19.1006,16.575l-2.1,2.7988l-2.0938-2.8056
            c-0.8645-1.1594-0.6255-2.8001,0.5339-3.6646c1.1594-0.8645,2.8001-0.6255,3.6646,0.5339
            c0.6944,0.9312,0.6923,2.2085-0.0051,3.1374H19.1006z"
            />
            <path
              d="M17,13.625c-0.7594,0-1.375,0.6156-1.375,1.375s0.6156,1.375,1.375,1.375s1.375-0.6156,1.375-1.375
            C18.3739,14.2411,17.7589,13.6261,17,13.625z M17,15.625c-0.3452,0-0.625-0.2798-0.625-0.625s0.2798-0.625,0.625-0.625
            s0.625,0.2798,0.625,0.625C17.6247,15.345,17.345,15.6247,17,15.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
