export function OceanView({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M13,18.625c-3.1016,0-5.625-2.075-5.625-4.625c0-0.2071-0.1679-0.375-0.375-0.375S6.625,13.7929,6.625,14
            c0,2.9639,2.8594,5.375,6.375,5.375c0.2071,0,0.375-0.1679,0.375-0.375S13.2071,18.625,13,18.625z"
            />
            <path
              d="M17,15.625c-3.6533,0-6.625-2.0752-6.625-4.625c0-0.2071-0.1679-0.375-0.375-0.375S9.625,10.7929,9.625,11
            c0,2.9639,3.3086,5.375,7.375,5.375c0.2071,0,0.375-0.1679,0.375-0.375C17.375,15.7929,17.2071,15.625,17,15.625z"
            />
            <path
              d="M20,12.625c-3.5908,0-6.625-1.66-6.625-3.625c0-0.8975,0.7275-1.625,1.625-1.625S16.625,8.1025,16.625,9
            c0,0.2071,0.1679,0.375,0.375,0.375S17.375,9.2071,17.375,9C17.3722,6.5849,15.4151,4.6278,13,4.625
            c-3.7256,0-5.2627,2.5352-6.4971,4.5723C5.6807,10.5537,5.0312,11.625,4,11.625c-0.2071,0-0.375,0.1679-0.375,0.375
            S3.7929,12.375,4,12.375c1.4541,0,2.2744-1.3545,3.1445-2.7891C8.3408,7.6123,9.6963,5.375,13,5.375
            c1.1254,0.0001,2.1868,0.5234,2.8721,1.416C15.5946,6.6808,15.2986,6.6245,15,6.625C13.6889,6.6265,12.6265,7.6889,12.625,9
            c0,2.4531,3.2393,4.375,7.375,4.375c0.2071,0,0.375-0.1679,0.375-0.375S20.2071,12.625,20,12.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
