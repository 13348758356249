// todo fix anys in this file
import uniq from 'lodash/uniq';
import type {
  RoomsTilesFragment,
  RoomsPostsQuery,
} from '@dx-ui/queries-dx-curated-ui/generated/wp';
import type { ExtendedHotelRoomType } from 'apps/dx-curated-ui';

export interface Criteria {
  label: string;
  value: string | number | boolean;
  type?: string;
}

export interface Filters {
  numBeds: Criteria[];
  guests: Criteria[];
  brandName?: Criteria[];
  view: Criteria[];
  balconyDetail: Criteria[];
  adaAccessibleRoom: Criteria[];
  recommendedFor: Criteria[];
  premiumOptions: Criteria[];
  outdoorFeatures: Criteria[]; //
  roomFeatures: Criteria[]; //
  roomType: Criteria[]; //
  bathroomAmenities: Criteria[]; //
  locations: Criteria[]; //
  priceSort: Criteria[];
  customFilter1: Criteria[];
  customFilter2: Criteria[];
  customFilter3: Criteria[];
  bedType: Criteria[];
  showerTubAmenities?: Criteria[]; //
}

function notUndefined<TValue>(value: TValue | undefined): value is TValue {
  return value !== undefined;
}

function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((prev, curr) => {
    return prev.concat(curr);
  }, []);
}

export function buildFilters(rooms: MergedRoom[], t: any): Filters {
  const numBeds = uniq(rooms.map((r) => r.numBeds))
    .filter(notUndefined)
    .sort()
    .map((n) => ({
      label: t(`bed`, { count: n }),
      value: n,
      filter: (value: number, room: MergedRoom) => {
        const roomBeds = room.numBeds;
        return roomBeds === value;
      },
    }));

  const bedType = uniq(rooms.map((r) => r.bedType))
    .filter(notUndefined)
    .sort()
    .map((n) => ({
      label: n,
      value: n,
    }));

  const showerTubAmenities = uniq(rooms.map((r) => r.showerTubAmenities?.[0]?.name))
    .filter(notUndefined)
    .sort()
    .map((n) => ({
      label: n,
      value: n,
    }));

  const guests = (() => {
    const maxOccupancy = rooms
      .map((r) => r.maxOccupancy)
      .filter(notUndefined)
      .sort((a, b) => a - b)
      .reverse()[0];

    if (maxOccupancy <= 12) {
      return Array.from({ length: maxOccupancy + 1 }, (v, n) => ({
        label: t(`guests`, { count: n }),
        value: n,
        type: 'radio',
      }));
    } else {
      const ranges = [];
      const chunkSize = 5;
      let i: number;

      for (i = 0; i <= maxOccupancy - chunkSize; i += chunkSize) {
        const end = i + chunkSize - 1;
        ranges.push({
          label: `${i + 1}–${end + 1}`,
          value: `${i + 1}–${end + 1} ${t('guestsLabel')}`,
          type: 'radio',
        });
      }

      if (i < maxOccupancy) {
        const lastRange = ranges.pop();
        const lastStart = parseInt(lastRange.label.split('–')[0], 10);
        ranges.push({
          label: `${lastStart}–${maxOccupancy}`,
          value: `${lastStart}–${maxOccupancy} ${t('guestsLabel')}`,
          type: 'radio',
        });
      }

      return ranges;
    }
  })();

  const brandName = uniq(rooms.map((r) => r?.brandName))
    .filter(notUndefined)
    .reverse()
    .map((n) => ({
      label: n,
      value: n,
    }));

  const view = uniq(rooms.map((r) => (r.customView && r.view === 'Other' ? r.customView : r.view)))
    .filter(notUndefined)
    .map((n) => ({
      label: n,
      value: n,
    }));

  const balconyDetail = uniq(
    rooms.map((r) =>
      r.customBalcony && r.balconyDetail === 'Other' ? r.customBalcony : r.balconyDetail
    )
  )
    .filter(notUndefined)
    .map((n) => ({
      label: n,
      value: n,
    }))
    .filter((n) => n.value !== 'none');

  const adaAccessibleRoom = uniq(rooms.map((r) => r.adaAccessibleRoom))
    .filter(notUndefined)
    .map((v) => ({
      label: v ? t('accessible') : t('notAccessible'),
      value: v,
    }));

  const recommendedFor = uniq(
    flatten(rooms.map((r) => (r as WpRoomType)?.recommendedFor).filter(notUndefined)).filter(
      notUndefined
    )
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const premiumOptions = uniq(
    flatten(rooms.map((r) => (r as WpRoomType)?.premiumOptions).filter(notUndefined)).filter(
      notUndefined
    )
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const outdoorFeatures = uniq(
    flatten(rooms.map((r) => r.outdoorFeatures).filter(notUndefined)).filter(notUndefined)
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const roomFeatures = uniq(
    flatten(rooms.map((r) => r.roomFeatures).filter(notUndefined)).filter(notUndefined)
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const roomType = uniq(
    flatten(rooms.map((r) => r.roomType).filter(notUndefined)).filter(notUndefined)
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const bathroomAmenities = uniq(
    flatten(rooms.map((r) => r.bathroomAmenities).filter(notUndefined)).filter(notUndefined)
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const locations = uniq(
    flatten(rooms.map((r) => r.locations).filter(notUndefined)).filter(notUndefined)
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const customFilter1 = uniq(
    rooms.map(
      (r) => r.showCustomFilter1?.showCustomFilter1 && r.showCustomFilter1?.customFilter1Select
    )
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const customFilter2 = uniq(
    rooms.map(
      (r) => r.showCustomFilter2?.showCustomFilter2 && r.showCustomFilter2?.customFilter2Select
    )
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const customFilter3 = uniq(
    rooms.map(
      (r) => r.showCustomFilter3?.showCustomFilter3 && r.showCustomFilter3?.customFilter3Select
    )
  ).map((n) => ({
    label: n,
    value: n,
  }));

  const priceSort = [
    { label: t('highToLow'), value: '>', type: 'radio' },
    { label: t('lowToHigh'), value: '<', type: 'radio' },
  ];

  return {
    numBeds,
    guests,
    brandName,
    view,
    balconyDetail,
    adaAccessibleRoom,
    recommendedFor,
    premiumOptions,
    outdoorFeatures,
    roomFeatures,
    roomType,
    bathroomAmenities,
    locations,
    customFilter1,
    customFilter2,
    customFilter3,
    priceSort,
    bedType,
    showerTubAmenities,
  };
}

type WpRoomType = Extract<
  RoomsTilesFragment,
  { __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes' }
>['room'][number];
type WpRoomContent = NonNullable<RoomsPostsQuery['rooms']>['edges'][number]['node']['roomsContent'];

export type WpRoom = WpRoomType | WpRoomContent;
type DxGqlRoom = ExtendedHotelRoomType;

export type Room = Exclude<WpRoom, '__typename'>;
export type MergedRoom = Omit<WpRoom & DxGqlRoom, '__typename'>;
export type MergedWpRoomType = Omit<WpRoomType & DxGqlRoom, '__typename'>;
