import type { Track, VideoControlsProps } from './video-player.controls';
import type { useVideoPlayer } from './hooks/use-video-player';
import { useTranslation } from 'next-i18next';
import { Icon } from '@dx-ui/osc-icon';
import classnames from 'classnames';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useEventListener, useBoolean } from 'usehooks-ts';
import { getKeyDownNavigation } from '@dx-ui/utilities-accessibility';
import { BrandButton } from '@dx-ui/osc-brand-buttons';
import { isValidCue, getCueText, getCueId, getActiveCues } from './util/cue';
import { findSelectedTranscriptTrack } from './util/matchers';
import { getTrackId } from './util/track';
import './video-player.css';

type PlayerTranscriptProps = ReturnType<typeof useVideoPlayer>['videoTranscriptProps'];

type VideoTranscriptProps = PlayerTranscriptProps &
  Pick<VideoControlsProps, 'brandComponentTheme'> &
  React.ComponentProps<'div'>;

export function VideoTranscript({
  activeTranscript: activeTranscriptTrack,
  closeTranscript,
  onCueSelect,
  brandComponentTheme,
  videoElement,
  ...elementProps
}: VideoTranscriptProps) {
  const { t } = useTranslation('osc-video-player');
  const activeTranscript = activeTranscriptTrack
    ? findSelectedTranscriptTrack(videoElement.current, activeTranscriptTrack)
    : null;

  if (!activeTranscript) {
    return null;
  }

  return (
    <div {...elementProps} className={classnames('relative bg-bg p-4', elementProps.className)}>
      <div className="flex w-full items-center justify-between p-1">
        <h3 className="flex items-center pb-2 font-semibold">
          <Icon className="me-2" name="transcripts" />
          {t('transcript')}
        </h3>
        <button aria-label={t('close')} type="button" onClick={closeTranscript} autoFocus>
          <Icon name="close" />
        </button>
      </div>
      <TranscriptList
        key={getTrackId(activeTranscriptTrack as Track)}
        activeTranscript={activeTranscript}
        brandComponentTheme={brandComponentTheme}
        closeTranscript={closeTranscript}
        onCueSelect={onCueSelect}
      />
    </div>
  );
}

function useCues(activeTranscript: HTMLTrackElement) {
  const allCues = useMemo(() => getCues(activeTranscript), [activeTranscript]);
  const [{ activeCues, lastActiveCue }, setActiveCues] = useState<{
    activeCues: VTTCue[];
    lastActiveCue: VTTCue;
  }>(() => {
    const activeCues = getActiveCues(activeTranscript.track);
    const lastActiveCue = activeCues[activeCues.length - 1] || (allCues[0] as VTTCue);
    return { activeCues, lastActiveCue };
  });

  function updateCues() {
    setActiveCues((cueState) => {
      const activeCues = getActiveCues(activeTranscript.track);
      const activeCue = activeCues[activeCues.length - 1];
      const lastActiveCue = activeCue || cueState.lastActiveCue;
      return { activeCues, lastActiveCue };
    });
  }

  return {
    allCues,
    activeCues,
    lastActiveCue,
    updateCues,
  };
}

function TranscriptList({
  activeTranscript,
  closeTranscript,
  onCueSelect,
  brandComponentTheme,
}: Pick<VideoTranscriptProps, 'closeTranscript' | 'onCueSelect' | 'brandComponentTheme'> & {
  activeTranscript: HTMLTrackElement;
}) {
  const { t } = useTranslation('osc-video-player');
  const listRef = useRef<React.ElementRef<'ol'>>(null);
  const { value: isSyncing, setTrue: startSync, setFalse: stopSync } = useBoolean(true);
  const [focusedButton, setFocusedButton] = useState<HTMLButtonElement | null>(null);
  const activeTranscriptRef = useRef(activeTranscript);
  const { allCues, activeCues, lastActiveCue, updateCues } = useCues(activeTranscript);
  const isAutoScrolling = useRef(activeCues.length > 0);

  const onKeyDownNavigation = getKeyDownNavigation({
    elements: getAllButtons(listRef),
    onNextFocus: setFocusedButton,
    onPreviousFocus: setFocusedButton,
  });

  const onScroll = () => {
    if (!isAutoScrolling.current) {
      stopSync();
    }
  };

  const onScrollEnd = () => {
    isAutoScrolling.current = false;
  };

  const scrollToActiveCue = useCallback(() => {
    const firstActiveCue = activeCues[0];
    if (listRef.current && firstActiveCue && isSyncing) {
      const activeButton = getActiveButton(listRef, firstActiveCue);
      if (activeButton && activeButton.parentElement) {
        const { offsetTop, offsetHeight } = activeButton.parentElement;
        const { marginTop, marginBottom } = window.getComputedStyle(activeButton.parentElement);
        const itemMargins = parseInt(marginTop, 10) + parseInt(marginBottom, 10);
        const itemOffset = offsetTop - offsetHeight - itemMargins;
        const top = itemOffset - listRef.current.offsetTop;
        isAutoScrolling.current = true;
        listRef.current.scrollTo({ top });
      }
    }
  }, [isSyncing, activeCues]);

  const onKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Escape':
        closeTranscript();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        onKeyDownNavigation(event);
        break;
      default:
        break;
    }
  };

  useEffect(scrollToActiveCue, [scrollToActiveCue]);
  useEventListener('cuechange', updateCues, activeTranscriptRef);
  useEventListener('scroll', onScroll, listRef);
  useEventListener('scrollend', onScrollEnd, listRef);
  useEventListener('keydown', onKeyDown, listRef);

  return (
    <>
      <ol
        className={classnames(
          'max-h-64 space-y-1 overflow-y-auto overscroll-contain motion-safe:scroll-smooth sm:max-h-80',
          {
            'pb-10': !isSyncing,
          }
        )}
        ref={listRef}
        data-testid="video-player-transcript-list"
      >
        {allCues.map((cue) => {
          const isActive = activeCues.includes(cue);
          const isFocusable = focusedButton
            ? focusedButton.dataset.id === cue.id
            : lastActiveCue === cue;
          return (
            <TranscriptLine
              isActive={isActive}
              isFocusable={isFocusable}
              cue={cue}
              key={cue.id}
              onCueSelect={onCueSelect}
            />
          );
        })}
      </ol>
      {!isSyncing ? (
        <BrandButton
          className="absolute bottom-2 start-1/2 -translate-x-1/2 text-sm rtl:translate-x-1/2"
          onClick={() => {
            isAutoScrolling.current = true;
            startSync();
            setFocusedButton(null);
            getActiveButton(listRef, lastActiveCue)?.focus();
          }}
          label={t('sync')}
          brandComponentTheme={brandComponentTheme}
          variant="solid"
        />
      ) : null}
    </>
  );
}

function TranscriptLine({
  cue,
  onCueSelect,
  isActive,
  isFocusable,
}: Pick<VideoTranscriptProps, 'onCueSelect'> & {
  cue: VTTCue;
  isActive: boolean;
  isFocusable: boolean;
}) {
  const { t } = useTranslation('osc-video-player');
  const { text, html } = getCueText(cue);
  return (
    <li className="p-1">
      <button
        type="button"
        className={classnames('flex items-baseline gap-3 rounded-sm p-1 hover:bg-bg-alt', {
          'bg-bg-alt': isActive,
        })}
        onClick={() => onCueSelect(cue)}
        tabIndex={isFocusable ? 0 : -1}
        data-id={cue.id}
        aria-current={isActive ? 'time' : false}
        aria-label={t('time', {
          minutes: t('minute', { count: floorMinutes(cue.startTime) }),
          seconds: t('second', { count: floorSeconds(cue.startTime) }),
          text,
        })}
      >
        <span className="font-semibold tabular-nums text-primary underline">
          {formatTime(cue.startTime)}
        </span>
        <span className="text-start" dangerouslySetInnerHTML={{ __html: html }} />
      </button>
    </li>
  );
}

function getCues(htmlTrack: HTMLTrackElement) {
  return (Array.from(htmlTrack.track.cues || []) as VTTCue[]).filter(isValidCue).map((cue) => {
    cue.id = cue.id || getCueId(cue);
    return cue;
  });
}

function formatTime(time: number) {
  const minFmt = padTime(floorMinutes(time));
  const secFmt = padTime(floorSeconds(time));
  return `${minFmt}:${secFmt}`;
}

const floorMinutes = (time: number) => Math.floor(time / 60);
const floorSeconds = (time: number) => Math.floor(time % 60);
const padTime = (time: number) => time.toString().padStart(2, '0');

function getActiveButton(
  listRef: React.RefObject<HTMLUListElement>,
  cue: VTTCue
): HTMLButtonElement | null {
  return (listRef.current?.querySelector(`[data-id="${cue.id}"]`) as HTMLButtonElement) || null;
}

function getAllButtons(listRef: React.RefObject<HTMLUListElement>): HTMLButtonElement[] {
  return Array.from(listRef.current?.querySelectorAll('li > button') || []);
}
