import type {
  OfferOffersCurated,
  AccordionProps,
  StyleObject,
  MultiColumnWithHoverProps,
  AddressBarProps,
  IfourStaggeredTiles,
  InformationBarProps,
  IntroTextProps,
  FullWidthImageProps,
  HalfAndHalfProps,
  TwoTwoMediaCopyProps,
  RoomTypesProps,
  Room,
  GallerySliderProps,
  IconBlockProps,
  ImageGalleryProps,
  MultiColumnProps,
  LocationGoogleMapProps,
  TabbedSectionProps,
  HTMLMenuProps,
  TwoColumnIconListProps,
  ComparisonTableProps,
  SpacerProps,
  RetailFlyoutProps,
  RetailRailProps,
  ImageGalleryWallProps,
  RestaurantsProps,
  TwoOneMediaCopyProps,
  VideoMediaCopyProps,
  PatchworkGridCMSProps,
  TwoColumnCompareProps,
  MultiColumnListProps,
  EditorialSnippetComponentProps,
  HeroImageFlexProps,
  CropProps,
  WpRestaurants,
  LogoGridProps,
  WpRoom,
} from '@curated-property/shared-components';
import {
  AddressBar,
  addressBarPropMapper,
  buildFilters,
  ComparisonTable,
  FourStaggeredTiles,
  FullWidthImage,
  HalfAndHalf,
  Hero,
  heroPropMapper,
  HotelAmenities,
  IconBlock,
  ImageGallery,
  IntroText,
  LocationGoogleMap,
  MultiColumn,
  MultiItemCarousel,
  multiItemCarouselPropMapper,
  RoomTypes,
  SingleItemSlider,
  SingleItemSliderPropMapper,
  Spacer,
  TabbedSection,
  TwoColumnCompare,
  twoColumnComparePropMapper,
  TwoColumnIconList,
  TwoOneMediaCopy,
  twoOneMediaCopyPropMapper,
  TwoTwoMediaCopy,
  ResortMap,
  MultiColumnWithHover,
  SocialMediaFeed,
  SocialMediaFeedPropMapper,
  KeyInfoGrid,
  KeyInfoGridPropMapper,
  RetailFlyout,
  RetailRail,
  MediaAndCopyOverlay,
  MediaAndCopyOverlayPropMapper,
  ImageGalleryWall,
  HotelPolicies,
  hotelPolicyPropMapper,
  HTMLMenu,
  WeddingAvailableCalendar,
  EventCalendar,
  meetingsGroupPropMapper,
  MeetingsGroup,
  HotelTitle,
  meetingCapacityPropMapper,
  MeetingCapacity,
  Restaurants,
  mergeRestaurants,
  WeddingAvailabilityCalendarDefaultStyles,
  AccordionComponent,
  GIS_Padder,
  MeetingCapacityCharts,
  OffersCurated,
  GallerySlider,
  Cvent,
  InformationBar,
  VideoMediaCopy,
  videoMediaCopyPropMapper,
  PatchworkGrid,
  PatchworkGridPropMapper,
  eventCalendarHotelInfoPropMapper,
  eventCalRegEventPropMapper,
  eventCalSplEventPropMapper,
  eventCalCustomCategoriesPropMapper,
  MultiColumnList,
  EditorialSnippetComponent,
  editorialSnippetPropMapper,
  OffsetComponent,
  offsetPropMapper,
  LogoGrid,
} from '@curated-property/shared-components';
import { mergeWpRooms } from '@curated-property/utils';
import type {
  WordpressPageInfoQuery,
  WordpressThemeSettingsQuery,
} from '@dx-ui/queries-dx-curated-ui/generated/wp';
import type { AssociatedHotelInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/dx-gql';
import type { SlugConfig } from '../pages/hotels/[hotelSlug]/[[...slug]]';
import { useTranslation } from 'next-i18next';
import type { slugMakeGetStaticProps } from '../utils/slugGetStaticProps';
import { isAfter, isBefore } from 'date-fns';
import type { InteractiveMap } from 'libs/curated/shared-components/src/lib/interactive-map/interactive-map.types';
import type { HotelPoliciesProps } from 'libs/curated/shared-components/src/lib/hotel-policies/hotel-policies.types';
import type { SingleItemSliderProps } from 'libs/curated/shared-components/src/lib/media-and-copy-carousel/media-and-copy-carousel.types';
import type { WACBooking } from 'libs/curated/shared-components/src/lib/wedding-availability-calendar/interfaces';
import type { MediaBlocksMediaLayoutsInterface } from 'libs/curated/shared-components/src/lib/media-blocks';
import type { OffsetComponentProps } from 'libs/curated/shared-components/src/lib/offset/offset.types.ts';

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export type FlexComponents = NonNullable<
  NonNullable<WordpressPageInfoQuery['page']>['acfFlexible']
>['flexibleComponents'];

type FullComponentMapping = SlugConfig<FlexComponents, PageProps & { googleMapsKey: string }>;

export type PartialComponentMapping = Partial<FullComponentMapping>;

export type PageProps = ThenArg<ReturnType<ReturnType<typeof slugMakeGetStaticProps>>>['props'];
/**
 * @description this is the default mapping of Props to React Components
 */
export const defaultComponentMapping: PartialComponentMapping = {
  Page_Acfflexible_FlexibleComponents_Masthead: (
    flex: HeroImageFlexProps,
    {
      dxGqlInfo,
      wpPageInfo,
      wpThemeSettings,
      ctyhocn,
      associatedHotelInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const [t] = useTranslation();
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const oscBookButtonStyle =
      wpThemeSettings?.shopFormSettings?.shopFormSettings?.bookButtonStyleFromGlobal
        ?.shopFormBookGlobalButtonStyleButtonStyle || '';

    const heroProps = heroPropMapper(flex, globalStyles, t, oscBookButtonStyle);

    const pageDetails = getPageDetails(wpPageInfo);
    if (flex?.mastheadComponentSettings?.showHide) {
      return null;
    }

    return (
      <Hero
        {...heroProps}
        isShopFormInHeader={wpThemeSettings?.shopFormSettings?.shopFormSettings?.attachToHeader}
        key={key + (pageDetails?.slug ?? '')}
        componentInstance={key}
        pageDetails={pageDetails}
        bookingWidgetConfig={{
          ctyhocn,
          defaultArrivalDate: dxGqlInfo?.hotel?.display?.resEnabledDate,
          gmtHours: dxGqlInfo?.hotel?.localization?.gmtHours,
          brandCode: dxGqlInfo?.hotel?.brandCode || 'HI',
          resEnabled: dxGqlInfo?.hotel?.display?.resEnabled,
          currency: dxGqlInfo?.hotel?.localization?.currencyCode,
          associatedHotels: associatedHotelInfo,
        }}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_TwoColumnCompare: (
    flex: TwoColumnCompareProps & {
      twoColumnCompareComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const twoColumnCompareProps = twoColumnComparePropMapper(flex, key, globalStyles);
    if (flex?.twoColumnCompareComponentSettings?.showHide) {
      return null;
    }
    return <TwoColumnCompare key={key} {...twoColumnCompareProps} />;
  },

  Page_Acfflexible_FlexibleComponents_ImageReveal: (
    flex: MultiColumnWithHoverProps & {
      imageRevealComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings)?.imageRevealComponentSettings;
    const instanceStyles = flex?.imageRevealComponentSettings;
    if (flex?.imageRevealComponentSettings?.showHide) {
      return null;
    }

    return (
      <MultiColumnWithHover
        key={key}
        instanceStyles={instanceStyles}
        globalStyles={globalStyles}
        header={flex?.header}
        columnCount={flex?.columnCount}
        repeater={flex?.repeater}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_AddressBar: (
    flex: AddressBarProps & { addressBarSettings?: StyleObject },
    { dxGqlInfo, wpThemeSettings, wpPageInfo }: NonNullable<PageProps>,
    key: number
  ) => {
    // now called contact bar
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const addressBarProps = addressBarPropMapper(flex, globalStyles, dxGqlInfo?.hotel);
    if (flex?.addressBarSettings?.showHide) {
      return null;
    }
    const pageComponents = getPageDetails(wpPageInfo)?.acfFlexible?.flexibleComponents;

    return (
      <AddressBar
        key={key}
        componentIndex={key}
        allComponents={pageComponents}
        tripAdvisor={dxGqlInfo?.hotel?.tripAdvisorLocationSummary}
        {...addressBarProps}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_FourStaggeredTiles: (
    flex: {
      fourStaggeredTilesComponentSettings?: StyleObject;
      repeater?: NonNullable<IfourStaggeredTiles>['repeater'];
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings)?.fourStaggeredTilesComponentSettings;

    if (flex?.fourStaggeredTilesComponentSettings?.showHide) {
      return null;
    }
    return (
      <FourStaggeredTiles
        key={key}
        repeater={flex?.repeater}
        globalStyles={globalStyles}
        instanceStyles={flex?.fourStaggeredTilesComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy: (
    flex: TwoOneMediaCopyProps & { twoOneMediaCopySettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const twoOneProps = twoOneMediaCopyPropMapper(flex, globalStyles);
    if (flex?.twoOneMediaCopySettings?.showHide) {
      return null;
    }
    return <TwoOneMediaCopy key={key} {...twoOneProps} />;
  },

  Page_Acfflexible_FlexibleComponents_Introduction: (
    flex: {
      introductionComponentSettings?: StyleObject;
      title?: string;
      title_noTx?: string;
      subtitle?: string;
      subtitle_noTx?: string;
      combinedTitleSubtitle?: string;
      pullQuote?: boolean;
      titleSize?: string;
      subtitleSize?: string;
      copy?: string;
      image?: IntroTextProps['image'];
      imageSize?: string;
      buttons?: IntroTextProps['buttons'];
    },
    { wpThemeSettings, wpPageInfo }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.introductionComponentSettings?.showHide) {
      return null;
    }
    const pageComponents = getPageDetails(wpPageInfo)?.acfFlexible?.flexibleComponents;

    return (
      <IntroText
        key={key}
        componentIndex={key}
        allComponents={pageComponents}
        headline={flex?.title}
        headline_noTx={flex?.title_noTx}
        headlineAccent={flex?.subtitle}
        headlineAccent_noTx={flex?.subtitle_noTx}
        combinedTitleSubtitle={flex?.combinedTitleSubtitle}
        pullQuote={flex?.pullQuote}
        titleSize={flex?.titleSize}
        subtitleSize={flex?.subtitleSize}
        text={flex?.copy}
        image={flex?.image}
        imageSize={flex?.imageSize}
        buttons={flex?.buttons}
        globalStyles={globalStyles?.introductionComponentSettings}
        instanceStyles={flex?.introductionComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_FullWidthImageContent: (
    flex: CropProps & {
      fullWidthImageComponentSettings?: StyleObject;
      image?: { sourceUrl?: string; altText?: string };
      imageAlt?: string;
      mediaBlock?: MediaBlocksMediaLayoutsInterface;
      title?: string;
      title_noTx?: string;
      subtitle?: string;
      subtitle_noTx?: string;
      copy?: string;
      styles?: string;
      buttons?: FullWidthImageProps['buttons'];
      enableCarousel?: boolean;
      carouselItems?: FullWidthImageProps['carouselItems'];
      globalStyles?: StyleObject;
      instanceStyles?: StyleObject;
      customImageSize?: FullWidthImageProps['customImageSize'];
      flipImageHorizontally?: FullWidthImageProps['flipImageHorizontally'];
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.fullWidthImageComponentSettings?.showHide) {
      return null;
    }

    return (
      <FullWidthImage
        key={key}
        image={flex?.image?.sourceUrl}
        imageAlt={flex?.image?.altText}
        mediaBlock={flex?.mediaBlock}
        title={flex?.title}
        title_noTx={flex?.title_noTx}
        subtitle={flex?.subtitle}
        subtitle_noTx={flex?.subtitle_noTx}
        copy={flex?.copy}
        enableCropping={flex?.enableCropping}
        cropType={flex?.cropType}
        xPosition={flex?.xPosition}
        xPositionAdvanced={flex?.xPositionAdvanced}
        yPosition={flex?.yPosition}
        yPositionAdvanced={flex?.yPositionAdvanced}
        cropWidth={flex?.cropWidth}
        cropHeight={flex?.cropHeight}
        autoPosition={flex?.autoPosition}
        style={flex?.styles}
        buttons={flex?.buttons}
        enableCarousel={flex?.enableCarousel}
        carouselItems={flex?.carouselItems}
        globalStyles={globalStyles?.fullWidthImageComponentSettings}
        instanceStyles={flex?.fullWidthImageComponentSettings}
        flipImageHorizontally={flex?.flipImageHorizontally}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_KeyInfoGrid: (
    flex: { keyInfoGridSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const KeyInfoGridProps = KeyInfoGridPropMapper(flex, globalStyles);
    if (flex?.keyInfoGridSettings?.showHide) {
      return null;
    }

    return <KeyInfoGrid key={key} {...KeyInfoGridProps} />;
  },

  Page_Acfflexible_FlexibleComponents_MultiItemCarousel: (
    flex: { multiItemCarouselComponentSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const multiProps = multiItemCarouselPropMapper(flex, globalStyles);
    if (flex?.multiItemCarouselComponentSettings?.showHide) {
      return null;
    }
    return <MultiItemCarousel key={key} {...multiProps} />;
  },

  Page_Acfflexible_FlexibleComponents_SingleItemSlider: (
    flex: SingleItemSliderProps & {
      singleItemSliderComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const SingleItemSliderProps = SingleItemSliderPropMapper(flex, globalStyles);
    if (flex?.singleItemSliderComponentSettings?.showHide) {
      return null;
    }
    return <SingleItemSlider key={key} {...SingleItemSliderProps} />;
  },

  Page_Acfflexible_FlexibleComponents_HalfAndHalf: (
    flex: {
      repeater?: HalfAndHalfProps['repeater'];
      halfAndHalfComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.halfAndHalfComponentSettings?.showHide) {
      return null;
    }
    return (
      <HalfAndHalf
        key={key}
        repeater={flex.repeater}
        globalStyles={globalStyles?.halfAndHalfComponentSettings}
        instanceStyles={flex.halfAndHalfComponentSettings}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy: (
    flex: TwoTwoMediaCopyProps & {
      twoTwoMediaCopySettings?: StyleObject;
      reverseDirection?: boolean;
      column1?: {
        title?: string;
        title_noTx?: string;
        copy?: string;
        image?: TwoTwoMediaCopyProps['image1'];
        buttons?: TwoTwoMediaCopyProps['buttons1'];
      };
      column2?: {
        title?: string;
        title_noTx?: string;
        copy?: string;
        image?: TwoTwoMediaCopyProps['image1'];
        buttons?: TwoTwoMediaCopyProps['buttons1'];
      };
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.twoTwoMediaCopySettings?.showHide) {
      return null;
    }
    return (
      <TwoTwoMediaCopy
        key={key}
        reverse={flex?.reverseDirection}
        title1={flex?.column1?.title}
        title1_noTx={flex?.column1?.title_noTx}
        copy1={flex?.column1?.copy}
        image1={{
          sourceUrl: flex?.column1?.image?.sourceUrl,
          altText: flex?.column1?.image?.altText,
        }}
        buttons1={flex?.column1?.buttons}
        title2={flex?.column2?.title}
        title2_noTx={flex?.column2?.title_noTx}
        copy2={flex?.column2?.copy}
        image2={{
          sourceUrl: flex?.column2?.image?.sourceUrl,
          altText: flex?.column2?.image?.altText,
        }}
        buttons2={flex?.column2?.buttons}
        imageColumn1AutoPosition={flex?.imageColumn1AutoPosition}
        imageColumn1CropHeight={flex?.imageColumn1CropHeight}
        imageColumn1CropType={flex?.imageColumn1CropType}
        imageColumn1CropWidth={flex?.imageColumn1CropWidth}
        imageColumn1EnableCropping={flex?.imageColumn1EnableCropping}
        imageColumn1XPosition={flex?.imageColumn1XPosition}
        imageColumn1XPositionAdvanced={flex?.imageColumn1XPositionAdvanced}
        imageColumn1YPosition={flex?.imageColumn1YPosition}
        imageColumn1YPositionAdvanced={flex?.imageColumn1YPositionAdvanced}
        imageColumn2AutoPosition={flex?.imageColumn2AutoPosition}
        imageColumn2CropHeight={flex?.imageColumn2CropHeight}
        imageColumn2CropType={flex?.imageColumn2CropType}
        imageColumn2CropWidth={flex?.imageColumn2CropWidth}
        imageColumn2EnableCropping={flex?.imageColumn2EnableCropping}
        imageColumn2XPosition={flex?.imageColumn2XPosition}
        imageColumn2XPositionAdvanced={flex?.imageColumn2XPositionAdvanced}
        imageColumn2YPosition={flex?.imageColumn2YPosition}
        imageColumn2YPositionAdvanced={flex?.imageColumn2YPositionAdvanced}
        globalStyles={globalStyles?.twoTwoMediaCopySettings}
        instanceStyles={flex.twoTwoMediaCopySettings}
      />
    );
  },
  Page_Acfflexible_FlexibleComponents_RoomTypes: (
    flex: {
      displayRoomsFromPosts?: boolean;
      room?: Room[];
      roomsComponentSettings?: StyleObject;
      showPricing?: string[] | null;
      hideLeadRates?: string[] | null;
      hideMaxGuestsFilter?: string[] | null;
      hideBedsFilter?: string[] | null;
      hidePriceFilter?: string[] | null;
      ctyhocn?: RoomTypesProps['ctyhocn'];
      pricingDisclaimer?: RoomTypesProps['pricingDisclaimer'];
      sortRoomsBy?: RoomTypesProps['sortRoomsBy'];
      showFindYourBestRoomFilters?: RoomTypesProps['showFindYourBestRoomFilters'];
    },
    {
      roomInfo,
      dxGqlInfo,
      ctyhocn,
      wpThemeSettings,
      additionalWPData,
      additionalRoomData,
      wpPageInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const [t] = useTranslation();

    if (flex?.roomsComponentSettings?.showHide) {
      return null;
    }

    let wpRooms: WpRoom[];

    const ctyhocnOverride =
      flex?.ctyhocn?.toUpperCase() || wpPageInfo?.ctyhocnOverride?.toUpperCase();
    //ctyhocn override
    if (ctyhocnOverride) {
      if (additionalRoomData?.[ctyhocnOverride]) {
        roomInfo = additionalRoomData[ctyhocnOverride];
      }
    }
    if (flex?.displayRoomsFromPosts && additionalWPData?.RoomsPosts) {
      // transform the roomsFromPosts data into the same format as the wpRooms data
      wpRooms = additionalWPData?.RoomsPosts?.RoomsPosts?.map((room) => {
        return {
          roomTypeCode: room?.node?.roomsContent?.roomTypeCode,
          view: room?.node?.roomsContent?.view,
          balconyDetail: room?.node?.roomsContent?.balconyDetail,
          customView: room?.node?.roomsContent?.customView,
          customBalcony: room?.node?.roomsContent?.customBalcony,
          squareFootage: room?.node?.roomsContent?.squareFootage,
          outdoorFeatures: room?.node?.roomsContent?.outdoorFeatures,
          roomFeatures: room?.node?.roomsContent?.roomFeatures,
          roomType: room?.node?.roomsContent?.roomType,
          bathroomAmenities: room?.node?.roomsContent?.bathroomAmenities,
          locations: room?.node?.roomsContent?.locations,
          image: room?.node?.roomsContent?.image,
          carouselImages: room?.node?.roomsContent?.carouselImages,
          showCustomFilter1: room?.node?.roomsContent?.showCustomFilter1,
          showCustomFilter2: room?.node?.roomsContent?.showCustomFilter2,
          showCustomFilter3: room?.node?.roomsContent?.showCustomFilter3,
          balconyIconGroup: room?.node?.roomsContent?.balconyIconGroup,
          viewIconGroup: room?.node?.roomsContent?.viewIconGroup,
          squareFootageIconGroup: room?.node?.roomsContent?.squareFootageIconGroup,
          icon: room?.node?.roomsContent?.icon,
          iconWidth: room?.node?.roomsContent?.iconWidth,
        };
      });
    } else {
      wpRooms = flex?.room;
    }

    const rooms = mergeWpRooms({
      dxGqlRooms: roomInfo || undefined,
      wpRooms,
    });
    const filters = rooms && buildFilters(rooms, t);

    return rooms && filters ? (
      <RoomTypes
        key={key}
        rooms={rooms}
        globalStyles={globalStyles?.roomsComponentSettings}
        instanceStyles={flex.roomsComponentSettings}
        pricingEnabled={flex?.showPricing?.includes('yes') || false}
        allowAdultsOnly={dxGqlInfo?.hotel?.facilityOverview?.allowAdultsOnly}
        hideLeadRates={flex?.hideLeadRates?.includes('yes') || false}
        hideMaxGuestsFilter={flex?.hideMaxGuestsFilter?.includes('hide') || false}
        hideBedsFilter={flex?.hideBedsFilter?.includes('hide') || false}
        hidePriceFilter={flex?.hidePriceFilter?.includes('hide') || false}
        filters={filters}
        gmtHours={dxGqlInfo?.hotel?.localization?.gmtHours}
        arrivalDate={dxGqlInfo.hotel?.display?.resEnabledDate}
        pricingDisclaimer={flex?.pricingDisclaimer}
        sortRoomsBy={flex?.sortRoomsBy}
        ctyhocn={ctyhocnOverride || ctyhocn}
        customFilters={wpThemeSettings?.roomSettings}
        resEnabled={dxGqlInfo?.hotel?.display?.resEnabled}
        associatedHotels={dxGqlInfo?.hotel?.associatedHotels}
        showFindYourBestRoomFilters={flex?.showFindYourBestRoomFilters}
      />
    ) : null;
  },
  Page_Acfflexible_FlexibleComponents_RoomTypesLite: (
    flex: {
      ctyhocn?: string;
      hideRoomCodes?: string;
      roomsComponentSettings?: StyleObject;
      showFindYourBestRoomFilters?: RoomTypesProps['showFindYourBestRoomFilters'];
    },
    {
      roomInfo,
      dxGqlInfo,
      ctyhocn,
      wpThemeSettings,
      additionalRoomData = {},
      wpPageInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const [t] = useTranslation();

    if (flex?.roomsComponentSettings?.showHide) {
      return null;
    }

    const ctyhocnOverride =
      flex?.ctyhocn?.toUpperCase() || wpPageInfo?.ctyhocnOverride?.toUpperCase();
    //ctyhocn override
    if (ctyhocnOverride) {
      if (additionalRoomData[ctyhocnOverride]) {
        roomInfo = additionalRoomData[ctyhocnOverride];
      }
    }
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const filters = roomInfo && buildFilters(roomInfo, t);

    if (flex?.hideRoomCodes) {
      const hideRoomCodesArray = flex?.hideRoomCodes?.split(',');
      roomInfo = roomInfo?.filter((room) => !hideRoomCodesArray.includes(room?.roomTypeCode ?? ''));
    }

    //filter roomInfo to remove items that do not include roomInfo?.accomodationCode
    roomInfo = roomInfo?.filter((room) => room?.accommodationCode);

    return roomInfo && filters ? (
      <RoomTypes
        key={key}
        rooms={roomInfo}
        globalStyles={globalStyles?.roomsComponentSettings}
        instanceStyles={flex?.roomsComponentSettings}
        pricingEnabled={true}
        allowAdultsOnly={dxGqlInfo?.hotel?.facilityOverview?.allowAdultsOnly}
        hideLeadRates={false}
        hideMaxGuestsFilter={false}
        hideBedsFilter={false}
        hidePriceFilter={false}
        filters={filters}
        arrivalDate={dxGqlInfo.hotel?.display?.resEnabledDate}
        pricingDisclaimer=""
        sortRoomsBy=""
        ctyhocn={ctyhocnOverride || ctyhocn}
        resEnabled={dxGqlInfo?.hotel?.display?.resEnabled}
        associatedHotels={dxGqlInfo?.hotel?.associatedHotels}
        showFindYourBestRoomFilters={flex?.showFindYourBestRoomFilters}
        gmtHours={dxGqlInfo?.hotel?.localization?.gmtHours}
      />
    ) : null;
  },

  Page_Acfflexible_FlexibleComponents_IconBlock: (
    flex: IconBlockProps & {
      iconBlockComponentSettings?: StyleObject;
      iconRepeater?: IconBlockProps['repeater'];
      iconColumnCount?: IconBlockProps['columnCount'];
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.iconBlockComponentSettings?.showHide) {
      return null;
    }
    return (
      <IconBlock
        key={key}
        header={flex?.header}
        headerCopy={flex?.headerCopy}
        columnCount={flex?.iconColumnCount}
        iconSize={flex?.iconSize}
        repeater={flex?.iconRepeater}
        globalStyles={globalStyles?.iconBlockComponentSettings}
        instanceStyles={flex.iconBlockComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_HotelAmenities: (
    flex: {
      iconBlockCorePlusComponentSettings?: StyleObject;
      showHotelAmenitiesIcons?: boolean;
      header?: string;
      buttonStyling?: string;
      ctyhocn?: string;
    },
    {
      dxGqlInfo,
      wpThemeSettings,
      associatedHotelInfo = [],
      additionalHotelInfo = [],
      defaultCtyhocn,
      wpPageInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    if (wpPageInfo?.ctyhocnOverride) {
      defaultCtyhocn = wpPageInfo.ctyhocnOverride?.toUpperCase();
    }
    if (flex?.ctyhocn) {
      defaultCtyhocn = flex?.ctyhocn?.toUpperCase();
    }
    const defaultCtyhocnData = getDefaultCtyhocnInfo(
      [...associatedHotelInfo, ...additionalHotelInfo],
      defaultCtyhocn
    );
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.iconBlockCorePlusComponentSettings?.showHide) {
      return null;
    }
    /*
     * This is a long-term-temporay fix. It seems that the content in PIM will not be updated
     * and PIM is being decommed. Once PIM is decommed the content will come from somewhere else.
     * https://jira.hilton.com/browse/CURATED-3290
     *
     * Once the content is correct we can remove this hacky fix.
     */

    const outWithTheOldInWithTheNew = (
      amenitiesFromPim: { name: string }[] | undefined,
      replacementText: { newText: string; oldText: string }[] | undefined
    ) =>
      amenitiesFromPim?.map((amenityObj) => {
        const textReplacement = replacementText?.find((text) => text?.oldText === amenityObj?.name);

        if (textReplacement) {
          return { name: textReplacement?.newText };
        }
        return amenityObj;
      });
    const pimReplacementText = [
      {
        oldText: 'Does Hotel comply with all local and/or national disability laws (out',
        newText:
          'Hotel complies with all local and/or national disability laws (outside U.S.) or the Americans with Disabilities Act of 1990 (for U.S. hotels only)',
      },
      {
        oldText: 'Serv support animals welcome',
        newText: 'Service support animals welcome',
      },
    ];
    /* end of hacky fix */

    return (
      <HotelAmenities
        key={key}
        header={flex?.header}
        buttonStyling={flex?.buttonStyling}
        hotelAmenties={
          defaultCtyhocnData?.hotel?.hotelAmenities || dxGqlInfo?.hotel?.hotelAmenities
        }
        accessibleHotelAmenities={outWithTheOldInWithTheNew(
          defaultCtyhocnData?.hotel?.accessibleFeatures || dxGqlInfo?.hotel?.accessibleFeatures,
          pimReplacementText
        )}
        nonAccessibleHotelAmenities={
          defaultCtyhocnData?.hotel?.nonAccessibleFeatures ||
          dxGqlInfo?.hotel?.nonAccessibleFeatures
        }
        showHotelAmenitiesIcons={flex?.showHotelAmenitiesIcons}
        globalStyles={globalStyles?.iconBlockCorePlusComponentSettings}
        instanceStyles={flex?.iconBlockCorePlusComponentSettings}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_ImageGallery: (
    flex: {
      imageGallery?: ImageGalleryProps['galleries'];
      imageGalleryComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.imageGalleryComponentSettings?.showHide) {
      return null;
    }
    return (
      <ImageGallery
        key={key}
        galleries={flex.imageGallery}
        globalStyles={globalStyles?.imageGalleryComponentSettings}
        instanceStyles={flex?.imageGalleryComponentSettings}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
      />
    );
  },
  Page_Acfflexible_FlexibleComponents_OffersCurated: (
    flex: {
      hideList?: string;
      title?: string;
      copy?: string;
      offersCuratedComponentSettings?: StyleObject;
    },
    { wpThemeSettings, offersCuratedData, ctyhocn, defaultCtyhocn }: NonNullable<PageProps>
  ) => {
    if (flex?.offersCuratedComponentSettings?.showHide) {
      return null;
    }
    const excludeList = flex?.hideList ? flex?.hideList?.replace(' ', '')?.split(',') : [];
    const offers =
      offersCuratedData?.hotelStaticOfferOptions?.offers?.reduce(
        (resultArray: OfferOffersCurated[], rawOffer) => {
          //exlude the offer if it's in the exlude list, or if it doesn't have T&Cs and is a hotel offer
          if (
            excludeList?.indexOf(String(rawOffer?.id)) !== -1 ||
            (!rawOffer?.policy?.terms?.description && rawOffer?.type === 'hotel')
          ) {
            return resultArray;
          }
          let bookStart = rawOffer?.bookStart ? new Date(rawOffer?.bookStart) : null;
          let bookEnd = rawOffer?.bookEnd ? new Date(rawOffer?.bookEnd) : null;

          //validation
          if (bookStart && bookStart instanceof Date && !isNaN(bookStart.valueOf())) {
            if (isBefore(new Date(), bookStart)) {
              return resultArray;
            }
          } else {
            bookStart = null;
          }
          if (bookEnd && bookEnd instanceof Date && !isNaN(bookEnd.valueOf())) {
            if (isAfter(new Date(), bookEnd)) {
              return resultArray;
            }
          } else {
            bookEnd = null;
          }
          /* generate booking url if missing */
          if (!rawOffer?.externalLink?.url) {
            if (!rawOffer?.externalLink) {
              rawOffer.externalLink = {};
            }
            rawOffer.externalLink.url = `https://www.hilton.com/en/book/reservation/flexibledates/?ctyhocn=${
              ctyhocn || defaultCtyhocn
            }&offerId=${rawOffer?.id}`;
          }
          /* we're going to modify the params in the image url. These params control the image size.
           ** we're looking to double the image size and then use CSS to fit the image to the box size.
           */
          const rawUrl = rawOffer?.images[0]?.variants[0]?.url;
          const imageAlt = rawOffer?.images[0]?.altText;
          let imageUrl = null;
          if (rawUrl) {
            const imageUrlObj = new URL(rawOffer?.images[0]?.variants[0]?.url ?? '') || null;
            imageUrlObj?.searchParams.set(
              'rw',
              String(parseFloat(imageUrlObj.searchParams.get('rw') ?? '') * 2)
            );
            imageUrlObj?.searchParams.set(
              'rh',
              String(parseFloat(imageUrlObj.searchParams.get('rh') ?? '') * 2)
            );
            imageUrl = imageUrlObj?.toString();
          }
          const additionalData: OfferOffersCurated['additionalData'] = [];

          const formattedOffer = {
            id: rawOffer.id,
            type: rawOffer.type,
            image: imageUrl?.toString(),
            imageAlt,
            title: rawOffer?.headline,
            copy: rawOffer?.shortDescription,
            additionalData,
            tags: rawOffer?.attributes?.map((item) => {
              return { id: item?.id, label: item?.name };
            }),
            CTA: rawOffer?.externalLink,
            terms: rawOffer?.policy?.terms?.description
              ?.map((term) => `<p class="mt-2">${term?.value}</p>`)
              ?.join(''),
          } as OfferOffersCurated;
          resultArray.push(formattedOffer);
          return resultArray;
        },
        []
      ) || [];
    const mapStyles = (style: StyleObject | undefined) => {
      if (style) {
        style['tagButtons'] = {
          backgroundColor: style?.tagBackgroundColor,
          textColor: style?.tagTextColor,
          disabledBackgroundColor: style?.tagDisabledBackgroundColor,
          disabledTextColor: style?.tagDisabledTextColor,
          hhrTagBackground: style?.tagBackgroundColor,
          hhrTagText: style?.tagTextColor,
          hhrTagDisabledBackground: style?.tagDisabledBackgroundColor,
          hhrTagDisabledText: style?.tagDisabledTextColor,
        };
        style['tiles'] = {
          backgroundColor: style?.tileBackgroundColor,
          textColor: style?.tileTextColor,
          additionalDataAccentColor: style?.additionalDataAccentColor,
          arrowAccentColor: style?.arrowDataAccentColor,
          bookButtonStyle: style?.buttonStyles?.bookButtonStyle,
          termsButtonStyle: style?.buttonStyles?.termsButtonStyle,
          termsBookButtonStyle: style?.buttonStyles?.termsBookButtonStyle,
        };
      }
      return style;
    };
    const compSettings = flex?.offersCuratedComponentSettings as {
      tileTypeSort:
        | 'hotel-brand-enterprise'
        | 'hotel-enterprise-brand'
        | 'brand-hotel-enterprise'
        | 'brand-enterprise-hotel'
        | 'enterprise-hotel-brand'
        | 'enterprise-brand-hotel';
    };

    if (compSettings?.tileTypeSort) {
      const sortOrder = compSettings?.tileTypeSort;
      const assignSortValue = (offer: OfferOffersCurated) => {
        const rank_index = sortOrder?.split('-');
        return rank_index.indexOf(offer?.type ?? '');
      };
      offers?.sort((a: OfferOffersCurated, b: OfferOffersCurated) => {
        return assignSortValue(a) - assignSortValue(b);
      });
    }

    const globalStyles = getGlobalStyles(wpThemeSettings);

    return (
      <OffersCurated
        title={flex?.title}
        copy={flex?.copy}
        globalStyles={mapStyles(globalStyles?.offersCuratedComponentSettings)}
        instanceStyles={mapStyles(flex?.offersCuratedComponentSettings)}
        offers={offers}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_MultiColumn: (
    flex: {
      header?: string;
      headerCopy?: string;
      headingValue?: string;
      columnCount?: number;
      buttons?: MultiColumnProps['buttons'];
      imageSize?: string;
      tileFilters?: boolean;
      filterCategories?: MultiColumnProps['filterCategories'];
      repeater?: MultiColumnProps['repeater'];
      multiColumnComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.multiColumnComponentSettings?.showHide) {
      return null;
    }
    return (
      <MultiColumn
        key={key}
        header={flex?.header}
        headerCopy={flex?.headerCopy}
        headingValue={flex?.headingValue}
        columnCount={flex?.columnCount}
        buttons={flex?.buttons}
        imageSize={flex?.imageSize}
        tileFilters={flex?.tileFilters}
        filterCategories={flex?.filterCategories}
        repeater={flex?.repeater}
        globalStyles={globalStyles?.multiColumnComponentSettings}
        instanceStyles={flex.multiColumnComponentSettings}
        urlQueryParamName={'multiColActiveCat' + key}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_LocationGoogleMap: (
    flex: {
      map?: { latitude?: number; longitude?: number };
      zoomControl?: number;
      title?: string;
      title_noTx?: string;
      addressUrl?: string;
      address?: string;
      addressFmt?: string;
      addressOverride?: boolean;
      phone?: string;
      email?: string;
      emailCtaText?: string;
      emailCtaOverride?: LocationGoogleMapProps['emailCtaOverride'];
      hideEmailCta?: boolean;
      locationGoogleMapComponentSettings?: StyleObject;
    },
    {
      googleMapsKey,
      dxGqlInfo,
      wpThemeSettings,
    }: NonNullable<PageProps> & { googleMapsKey: string },
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const dxAddress = { ...dxGqlInfo?.hotel?.address };

    const address = {
      addressStacked: dxAddress?.addressStacked,
      addressLine1: dxAddress?.addressLine1,
      city: dxAddress?.city,
      state: dxAddress?.state,
      country: dxAddress?.country,
    };

    if (flex?.locationGoogleMapComponentSettings?.showHide) {
      return null;
    }

    return (
      <LocationGoogleMap
        key={key}
        latLng={{
          lat: flex?.map?.latitude || dxGqlInfo?.hotel?.localization?.coordinate?.latitude || 0,
          lng: flex?.map?.longitude || dxGqlInfo?.hotel?.localization?.coordinate?.longitude || 0,
        }}
        zoom={flex?.zoomControl || 10}
        title={flex?.title || dxGqlInfo?.hotel?.name}
        title_noTx={flex?.title_noTx || dxGqlInfo?.hotel?.name}
        address={address}
        phone={flex?.phone || dxGqlInfo?.hotel?.contactInfo?.phoneNumber}
        email={flex?.email || dxGqlInfo?.hotel?.contactInfo?.emailAddress1}
        emailCtaText={flex?.emailCtaText}
        emailCtaOverride={flex?.emailCtaOverride}
        hideEmailCta={flex?.hideEmailCta}
        api_key={googleMapsKey}
        globalStyles={globalStyles?.locationGoogleMapComponentSettings}
        instanceStyles={flex?.locationGoogleMapComponentSettings}
        addressUrl={flex?.addressUrl}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_OnPageMenus: (
    flex: TabbedSectionProps & { onPageMenuSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.onPageMenuSettings?.showHide) {
      return null;
    }
    return (
      <TabbedSection
        key={key}
        header={flex.header}
        menuRepeater={flex.menuRepeater}
        globalStyles={globalStyles?.onPageMenuSettings}
        instanceStyles={flex?.onPageMenuSettings}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_HtmlMenu: (
    flex: {
      header?: string;
      header_noTx?: string;
      menu?: { __typename?: 'Html_menu'; id: string };
      tabTypeButtonStyle?: string;
      menuRepeater?: HTMLMenuProps['menuRepeater'];
      htmlMenuSettings?: StyleObject;
    },
    { wpThemeSettings, additionalWPData }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.htmlMenuSettings?.showHide || (!flex?.menuRepeater && !flex?.menu)) {
      return null;
    }
    return (
      <HTMLMenu
        key={key}
        header={flex?.header}
        header_noTx={flex?.header_noTx}
        menu={flex?.menu?.id}
        tabTypeButtonStyle={flex?.tabTypeButtonStyle}
        menuRepeater={flex?.menuRepeater}
        menuFromPosts={additionalWPData?.HTMLMenu}
        globalStyles={globalStyles?.htmlMenuSettings}
        instanceStyles={flex?.htmlMenuSettings}
        urlQueryParamName={'htmlMenu' + key + 'ActiveTab'}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_TwoColumnIconList: (
    flex: TwoColumnIconListProps & { twoColumnIconListSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.twoColumnIconListSettings?.showHide) {
      return null;
    }
    return (
      <TwoColumnIconList
        key={key}
        title={flex?.title}
        subtitle={flex?.subtitle}
        repeater={flex?.repeater}
        instanceStyles={flex?.twoColumnIconListSettings}
        globalStyles={globalStyles?.twoColumnIconListSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_ResortMapV2: (
    flex: InteractiveMap & {
      mapImage?: InteractiveMap['image'];
      mapPins?: InteractiveMap['repeater'];
      resortMapV2Settings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.resortMapV2Settings?.showHide) {
      return null;
    }
    return (
      <ResortMap
        key={key}
        image={flex?.mapImage}
        repeater={flex?.mapPins}
        mapCategoryTitle={flex?.mapCategoryTitle}
        mapCategoryDescription={flex?.mapCategoryDescription}
        mapCategoryMobileButtonPosition={flex?.mapCategoryMobileButtonPosition}
        mapOverlayPosition={flex?.mapOverlayPosition}
        globalStyles={globalStyles?.resortMapV2Settings}
        instanceStyles={flex?.resortMapV2Settings}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
        pinCategories={
          wpThemeSettings?.interactiveMapSettings?.interactiveMapSettings?.mapCategories
        }
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_SocialMediaFeed: (
    flex: { socialMediaFeedSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const SocialMediaFeedProps = SocialMediaFeedPropMapper(flex, globalStyles);
    if (flex?.socialMediaFeedSettings?.showHide) {
      return null;
    }
    return <SocialMediaFeed key={key} {...SocialMediaFeedProps} />;
  },

  Page_Acfflexible_FlexibleComponents_ComparisonTable: (
    flex: ComparisonTableProps & { comparisonTableSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.comparisonTableSettings?.showHide) {
      return null;
    }
    return (
      <ComparisonTable
        key={key}
        title={flex?.title}
        introduction={flex?.introduction}
        repeater={flex?.repeater}
        globalStyles={globalStyles?.comparisonTableSettings}
        instanceStyles={flex?.comparisonTableSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_Spacer: (
    flex: SpacerProps & { spacerComponentSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.spacerComponentSettings?.showHide) {
      return null;
    }
    return (
      <Spacer
        key={key}
        componentIndex={key}
        componentHeight={flex?.componentHeight}
        group={flex?.group}
        globalStyles={globalStyles?.spacerComponentSettings}
        instanceStyles={flex?.spacerComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_RetailFlyout: (
    flex: RetailFlyoutProps & { retailFlyoutComponentSettings?: StyleObject },
    { wpThemeSettings, dxGqlInfo, wpPageInfo }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.retailFlyoutComponentSettings?.showHide) {
      return null;
    }
    const pageComponents = getPageDetails(wpPageInfo)?.acfFlexible?.flexibleComponents;
    return (
      <RetailFlyout
        key={key}
        componentIndex={key}
        allComponents={pageComponents}
        openByDefault={flex?.openByDefault}
        pagePosition={flex?.pagePosition}
        copy={flex?.copy}
        title={flex?.title}
        link={flex?.link}
        buttonStyle={flex?.buttonStyle}
        brandCode={dxGqlInfo?.hotel?.brandCode}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
        instanceStyles={flex?.retailFlyoutComponentSettings}
        globalStyles={globalStyles?.retailFlyoutComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_RetailRail: (
    flex: RetailRailProps & { retailRailComponentSettings?: StyleObject },
    { wpThemeSettings, wpPageInfo }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.retailRailComponentSettings?.showHide) {
      return null;
    }
    const pageComponents = getPageDetails(wpPageInfo)?.acfFlexible?.flexibleComponents;
    return (
      <RetailRail
        key={key}
        componentIndex={key}
        allComponents={pageComponents}
        copy={flex?.copy}
        title={flex?.title}
        buttons={flex?.buttons}
        globalStyles={globalStyles?.retailRailComponentSettings}
        instanceStyles={flex?.retailRailComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay: (
    flex: { mediaAndCopyOverlayComponentSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const MediaAndCopyOverlayProps = MediaAndCopyOverlayPropMapper(flex, globalStyles);
    if (flex?.mediaAndCopyOverlayComponentSettings?.showHide) {
      return null;
    }
    return <MediaAndCopyOverlay key={key} {...MediaAndCopyOverlayProps} />;
  },

  Page_Acfflexible_FlexibleComponents_ImageGalleryWall: (
    flex: {
      galleryTitle?: string;
      gallerySubtitle?: string;
      columnCount?: number;
      imageVerticalAlignment?: string;
      repeater?: ImageGalleryWallProps['repeater'];
      galleryWallComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.galleryWallComponentSettings?.showHide) {
      return null;
    }
    return (
      <ImageGalleryWall
        key={key}
        galleryTitle={flex?.galleryTitle}
        gallerySubtitle={flex?.gallerySubtitle}
        columnCount={flex?.columnCount}
        imageVerticalAlignment={flex?.imageVerticalAlignment}
        repeater={flex?.repeater}
        globalStyles={globalStyles?.galleryWallComponentSettings}
        instanceStyles={flex?.galleryWallComponentSettings}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_HotelPolicies: (
    flex: HotelPoliciesProps & { hotelPoliciesComponentSettings?: StyleObject },
    { dxGqlInfo, wpThemeSettings, associatedHotelInfo, defaultCtyhocn }: NonNullable<PageProps>,
    key: number
  ) => {
    const defaultCtyhocnData = getDefaultCtyhocnInfo(associatedHotelInfo, defaultCtyhocn);
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const hotelPolicyProps = hotelPolicyPropMapper(
      flex,
      defaultCtyhocnData?.hotel || dxGqlInfo?.hotel
    );
    if (flex?.hotelPoliciesComponentSettings?.showHide) {
      return null;
    }

    return (
      <HotelPolicies
        title={flex?.title}
        subtitle={flex?.subtitle}
        copy={flex?.copy}
        displayHotelAccessibilityLink={flex?.displayHotelAccessibilityLink}
        hotelAccessibilityGuideUrl={flex?.hotelAccessibilityGuideUrl}
        key={key}
        {...hotelPolicyProps}
        globalStyles={globalStyles?.hotelPoliciesComponentSettings}
        instanceStyles={flex?.hotelPoliciesComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_MeetingsGroup: (
    flex: {
      meetingGroupComponentSettings?: StyleObject;
    },
    {
      dxGqlInfo,
      wpThemeSettings,
      associatedHotelInfo,
      defaultCtyhocn,
      ctyhocn,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const defaultCtyhocnData = getDefaultCtyhocnInfo(associatedHotelInfo, defaultCtyhocn);

    const globalStyles = getGlobalStyles(wpThemeSettings);

    const meetingsGroupProps = meetingsGroupPropMapper(
      flex,
      globalStyles,
      defaultCtyhocnData?.hotel?.meetings || dxGqlInfo?.hotel?.meetings,
      defaultCtyhocnData?.hotel?.showMeetingsSimplified || dxGqlInfo?.hotel?.showMeetingsSimplified,
      ctyhocn,
      dxGqlInfo?.hotel?.brandCode,
      wpThemeSettings?.buttonSettings
    );
    if (flex?.meetingGroupComponentSettings?.showHide) {
      return null;
    }
    return (
      <MeetingsGroup
        key={key}
        {...meetingsGroupProps}
        globalStyles={globalStyles?.meetingGroupComponentSettings}
        instanceStyles={flex?.meetingGroupComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid: (
    flex: { eventsCalendarComponentSettings?: StyleObject },
    { dxGqlInfo, additionalWPData, wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const specialEventEntries = additionalWPData?.RecurringEvents?.calendars;
    const regularEventEntries = additionalWPData?.RecurringEvents?.recurringevents;

    const globalStyles = getGlobalStyles(wpThemeSettings);

    const customEventCategories =
      wpThemeSettings?.themeSettings?.SettingsThemeSettings?.customEventCategories;

    const hotelProps = eventCalendarHotelInfoPropMapper(
      dxGqlInfo?.hotel?.name,
      dxGqlInfo?.hotel?.localization,
      dxGqlInfo?.hotel?.address,
      dxGqlInfo?.hotel?.contactInfo
    );

    return (
      <EventCalendar
        customEventCategories={eventCalCustomCategoriesPropMapper(customEventCategories)}
        specialEvents={eventCalSplEventPropMapper(specialEventEntries)}
        regularEvents={eventCalRegEventPropMapper(regularEventEntries)}
        hotelInfo={hotelProps}
        key={key}
        latLng={{
          lat: dxGqlInfo?.hotel?.localization?.coordinate?.latitude || 0,
          lng: dxGqlInfo?.hotel?.localization?.coordinate?.longitude || 0,
        }}
        modalSettings={wpThemeSettings?.modalSettings?.modalSettings}
        globalStyles={globalStyles?.eventsCalendarSettings}
        instanceStyles={flex?.eventsCalendarComponentSettings?.eventsCalendarSettings}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles: () => {
    return null;
  },

  Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar: (
    flex: {
      title?: string;
      introCopy?: string;
      componentSettings?: StyleObject;
    },
    { additionalWPData }: NonNullable<PageProps>
  ) => {
    const styling = WeddingAvailabilityCalendarDefaultStyles;

    styling.thirdPanel = {
      active: {
        backgroundColor: flex?.componentSettings?.activeBackgroundColor,
        textColor: flex?.componentSettings?.activeTextColor,
      },
      inactive: {
        backgroundColor: flex?.componentSettings?.inactiveBackgroundColor,
        textColor: flex?.componentSettings?.inactiveTextColor,
      },
    };

    styling.headerTitleColor = flex?.componentSettings?.headerTitleColor;
    styling.headerTitleAlign = flex?.componentSettings?.headerTitleAlign;

    styling.headerCopyColor = flex?.componentSettings?.headerCopyColor;
    styling.headerCopyAlign = flex?.componentSettings?.headerCopyAlign;

    styling.subheadingColor = flex?.componentSettings?.subheadingColor;
    styling.subheadingAlign = flex?.componentSettings?.subheadingAlign;

    styling.mainTextColor = flex?.componentSettings?.mainTextColor;

    styling.background.primary = flex?.componentSettings?.backgroundPrimary;
    styling.background.secondary = flex?.componentSettings?.backgroundSecondary;
    styling.background.ternary = flex?.componentSettings?.backgroundTernary;

    styling.progress.activeColor = flex?.componentSettings?.progressActive;
    styling.progress.inactiveColor = flex?.componentSettings?.progressInactive;

    styling.calendar.cells.disabled = flex?.componentSettings?.cellDisabledGroup;
    styling.calendar.cells.free = flex?.componentSettings?.cellFreeGroup;
    styling.calendar.cells.partial = flex?.componentSettings?.cellPartialGroup;
    styling.calendar.cells.full = flex?.componentSettings?.cellFullGroup;
    styling.calendar.cells.selected = flex?.componentSettings?.cellSelectedGroup;

    styling.radio.checked = flex?.componentSettings?.radioButtonChecked;
    styling.radio.unchecked = flex?.componentSettings?.radioButtonUnchecked;
    styling.radio.disabled = flex?.componentSettings?.radioButtonDisabled;

    const paddingStyles = GIS_Padder(
      flex?.componentSettings?.paddingTop,
      flex?.componentSettings?.paddingBottom
    );

    const bookings = additionalWPData?.WeddingAvailabilityCalendar?.bookings
      ?.map((node) => node?.node)
      .filter((booking): booking is WACBooking => booking !== undefined);
    const venues = additionalWPData?.WeddingAvailabilityCalendar?.venues
      ?.map((venue) => venue?.venue)
      .filter((v): v is string => v !== undefined);
    const timeSlots = additionalWPData?.WeddingAvailabilityCalendar?.timeSlots
      ?.map((time) => time?.timeSlot)
      .filter((ts): ts is string => ts !== undefined);

    return (
      <div className={paddingStyles}>
        <WeddingAvailableCalendar
          styles={styling}
          timeSlots={timeSlots}
          venues={venues}
          bookings={bookings}
          title={flex?.title}
          copy={flex?.introCopy}
          emailData={{
            address: flex?.componentSettings?.emailAddress,
            title: flex?.componentSettings?.emailTitle,
            copy: flex?.componentSettings?.emailCopy,
          }}
        />
      </div>
    );
  },

  Page_Acfflexible_FlexibleComponents_MeetingCapacity: (
    flex: { meetingCapacitySummaryComponentSettings?: StyleObject },
    { dxGqlInfo, wpThemeSettings, associatedHotelInfo, defaultCtyhocn }: NonNullable<PageProps>,
    key: number
  ) => {
    const defaultCtyhocnData = getDefaultCtyhocnInfo(associatedHotelInfo, defaultCtyhocn);
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const meetingCapacityProps = meetingCapacityPropMapper(
      flex,
      globalStyles,
      defaultCtyhocnData || dxGqlInfo
    );
    if (flex?.meetingCapacitySummaryComponentSettings?.showHide) {
      return null;
    }
    return (
      <MeetingCapacity
        key={key}
        {...meetingCapacityProps}
        globalStyles={globalStyles?.meetingCapacitySummaryComponentSettings}
        instanceStyles={flex?.meetingCapacitySummaryComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_HotelTitle: (
    flex: { hotelTitleComponentSettings?: StyleObject },
    { dxGqlInfo, wpThemeSettings, wpPageInfo }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.hotelTitleComponentSettings?.showHide) {
      return null;
    }
    const pageComponents = getPageDetails(wpPageInfo)?.acfFlexible?.flexibleComponents;

    return (
      <HotelTitle
        key={key}
        componentIndex={key}
        allComponents={pageComponents}
        corePlusHeadline={dxGqlInfo.hotel?.facilityOverview?.headline}
        corePlusShortDesc={dxGqlInfo.hotel?.facilityOverview?.shortDesc}
        globalStyles={globalStyles?.hotelTitleComponentSettings}
        instanceStyles={flex?.hotelTitleComponentSettings}
      />
    );
  },
  Page_Acfflexible_FlexibleComponents_Restaurants: (
    flex: {
      readMoreLess?: RestaurantsProps['readMoreLess'];
      hideList?: RestaurantsProps['hideList'];
      restaurants?: WpRestaurants;
      restaurantsComponentSettings?: StyleObject;
    },
    { dxGqlInfo, wpThemeSettings, associatedHotelInfo, defaultCtyhocn }: NonNullable<PageProps>,
    key: number
  ) => {
    const defaultCtyhocnData = getDefaultCtyhocnInfo(associatedHotelInfo, defaultCtyhocn);
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const restaurants = mergeRestaurants({
      dxGqlRestaurants:
        defaultCtyhocnData?.hotel?.restaurantOverview?.restaurants ||
        dxGqlInfo.hotel?.restaurantOverview?.restaurants ||
        undefined,
      wpRestaurants: flex?.restaurants,
    });
    if (flex?.restaurantsComponentSettings?.showHide) {
      return null;
    }
    return (
      <Restaurants
        key={key}
        readMoreLess={flex?.readMoreLess}
        hideList={flex?.hideList}
        restaurants={restaurants}
        globalStyles={globalStyles?.restaurantsComponentSettings}
        instanceStyles={flex?.restaurantsComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_Accordion: (
    flex: AccordionProps & { accordionComponentSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.accordionComponentSettings?.showHide) {
      return null;
    }
    return (
      <AccordionComponent
        key={key}
        content={flex?.content}
        heading={flex?.heading}
        subtitle={flex?.subtitle}
        panel={flex?.panel}
        globalStyles={globalStyles?.accordionComponentSettings}
        instanceStyles={flex?.accordionComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts: (
    flex: {
      meetingCapacityChartsSettings?: StyleObject;
      title?: string;
      subtitle?: string;
      copy?: string;
    },
    { dxGqlInfo, wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const componentContent = {
      title: flex?.title,
      subtitle: flex?.subtitle,
      copy: flex?.copy,
    };

    const countryCode = dxGqlInfo?.hotel?.address?.country;
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (
      flex?.meetingCapacityChartsSettings?.showHide ||
      !dxGqlInfo?.hotel?.meetings?.meetingRooms
    ) {
      return null;
    }

    return (
      <MeetingCapacityCharts
        key={key}
        content={componentContent}
        country={countryCode}
        meetings={dxGqlInfo?.hotel?.meetings}
        globalStyles={globalStyles?.meetingCapacityChartsSettings}
        instanceStyles={flex?.meetingCapacityChartsSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_Cvent: (
    flex: { cventComponentSettings?: StyleObject; cventCode?: string },
    { wpThemeSettings }: NonNullable<PageProps>
  ) => {
    if (flex?.cventCode && !flex?.cventComponentSettings?.showHide) {
      const globalStyles = getGlobalStyles(wpThemeSettings);
      return (
        <Cvent
          cventCode={flex?.cventCode}
          instanceStyles={flex?.cventComponentSettings}
          globalStyles={globalStyles}
        />
      );
    }
    return null;
  },

  Page_Acfflexible_FlexibleComponents_GallerySlider: (
    flex: GallerySliderProps & { gallerySliderComponentSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.gallerySliderComponentSettings?.showHide) {
      return null;
    }
    return (
      <GallerySlider
        key={key}
        tileHeight={flex?.tileHeight}
        tilePadding={flex?.tilePadding}
        columnCount={flex?.columnCount}
        repeater={flex?.repeater}
        globalStyles={globalStyles?.gallerySliderComponentSettings}
        instanceStyles={flex?.gallerySliderComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_InformationBar: (
    flex: InformationBarProps & {
      informationBarComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.informationBarComponentSettings?.showHide) {
      return null;
    }
    return (
      <InformationBar
        key={key}
        hideIcons={flex?.hideIcons}
        location={flex?.location}
        hours={flex?.hours}
        details={flex?.details}
        phoneNumber={flex?.phoneNumber}
        emailAddress={flex?.emailAddress}
        bookCta={flex?.bookCta}
        globalStyles={globalStyles?.informationBarComponentSettings}
        instanceStyles={flex?.informationBarComponentSettings}
        enableHHR={wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_VideoMediaCopy: (
    flex: VideoMediaCopyProps & {
      componentSettingsVideoMediaCopySettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);

    const videoMediaProps = videoMediaCopyPropMapper(
      flex,
      globalStyles,
      wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme
    );

    if (flex?.componentSettingsVideoMediaCopySettings?.showHide) {
      return null;
    }

    return <VideoMediaCopy key={key} componentInstance={key} {...videoMediaProps} />;
  },

  Page_Acfflexible_FlexibleComponents_PatchworkGrid: (
    flex: PatchworkGridCMSProps & { patchworkGridSettings?: StyleObject },
    { wpThemeSettings }: NonNullable<PageProps>
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const patchworkGridProps = PatchworkGridPropMapper({
      ...flex,
      globalStyles: globalStyles?.patchworkGridSettings,
    });

    if (flex?.patchworkGridSettings?.showHide) {
      return null;
    }

    return <PatchworkGrid {...patchworkGridProps} />;
  },

  Page_Acfflexible_FlexibleComponents_MultiColumnList: (
    flex: MultiColumnListProps & {
      multiColumnListComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>
  ) => {
    if (flex?.multiColumnListComponentSettings?.showHide) {
      return null;
    }

    const globalStyles = getGlobalStyles(wpThemeSettings);

    return (
      <MultiColumnList
        title={flex?.title}
        copy={flex?.copy}
        column={flex?.column}
        globalStyles={globalStyles?.multiColumnListComponentSettings}
        instanceStyles={flex?.multiColumnListComponentSettings}
      />
    );
  },

  Page_Acfflexible_FlexibleComponents_EditorialSnippet: (
    flex: EditorialSnippetComponentProps & {
      editorialSnippetComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const editorialSnippetCompProps = editorialSnippetPropMapper(flex, globalStyles);
    if (flex?.editorialSnippetComponentSettings?.showHide) {
      return null;
    }

    return <EditorialSnippetComponent key={key} {...editorialSnippetCompProps} />;
  },

  Page_Acfflexible_FlexibleComponents_Offset: (
    flex: OffsetComponentProps & {
      offsetComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>,
    key: number
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const offsetComponentCombinedProps = offsetPropMapper(flex, globalStyles);
    if (flex?.offsetComponentSettings?.showHide) {
      return null;
    }

    return <OffsetComponent {...offsetComponentCombinedProps} key={key} />;
  },

  Page_Acfflexible_FlexibleComponents_LogoGrid: (
    flex: LogoGridProps & {
      logoGridComponentSettings?: StyleObject;
    },
    { wpThemeSettings }: NonNullable<PageProps>
  ) => {
    const globalStyles = getGlobalStyles(wpThemeSettings);
    if (flex?.logoGridComponentSettings?.showHide) {
      return null;
    }

    return (
      <LogoGrid
        heading={flex?.heading}
        headingCopy={flex?.headingCopy}
        columnCount={flex?.columnCount}
        logoRepeater={flex?.logoRepeater}
        button={flex?.button}
        globalStyles={globalStyles?.logoGridComponentSettings}
        instanceStyles={flex.logoGridComponentSettings}
      />
    );
  },
};

export function getGlobalStyles(wpThemeSettings: WordpressThemeSettingsQuery) {
  return wpThemeSettings?.componentStyles?.globalComponentSettings?.globalComponentSettings;
}

export function getPageDetails(wpPageInfo: WordpressPageInfoQuery) {
  return wpPageInfo?.page;
}

export function getDefaultCtyhocnInfo(
  associatedHotels: (AssociatedHotelInfoQuery | undefined)[] | undefined,
  defaultCtyhocn: string
) {
  let defaultHotel: AssociatedHotelInfoQuery | undefined;
  if (associatedHotels?.length > 0) {
    associatedHotels?.forEach((hotel) => {
      if (hotel?.hotel?.ctyhocn === defaultCtyhocn) {
        defaultHotel = hotel;
      }
    });
    return defaultHotel;
  } else {
    return null;
  }
}
