export function RelaxationLounge({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m7.5 9.5c1.103 0 2-.897 2-2s-.897-2-2-2-2 .897-2 2 .897 2 2 2zm0-3c.551 0 1 .448 1 1s-.449 1-1 1-1-.448-1-1 .449-1 1-1z" />
      <path d="m14 17.5h-3.706l-4.857-8.743c-.134-.24-.438-.327-.68-.194-.242.135-.329.438-.194.681l5 9c.088.158.255.257.437.257h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
      <path d="m17.836 14.743c-.279-.697-1.085-1.243-1.836-1.243h-2.5c-.375 0-.89-.293-1.082-.615l-1.438-2.411c-.481-.642-1.352-.848-2.068-.488-.416.208-.729.583-.86 1.029-.13.446-.067.932.159 1.308l1.877 3.53c.343.644 1.182 1.147 1.911 1.147h3.5c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-3.5c-.366 0-.856-.294-1.028-.617l-1.89-3.552c-.098-.163-.123-.354-.069-.536.053-.183.176-.33.347-.415.285-.144.63-.062.792.149l1.408 2.367c.375.629 1.209 1.104 1.941 1.104h2.5c.346 0 .779.293.907.614l1.628 4.071c.078.195.266.314.464.314.062 0 .125-.012.186-.036.256-.103.381-.394.279-.649z" />
    </svg>
  );
}
