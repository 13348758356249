export function Steam({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m5.749 7.5c.151 0 .299-.067.398-.196.463-.607 1.181-1.243 2.133-1.89.229-.155.288-.467.133-.695s-.465-.286-.694-.133c-1.042.708-1.838 1.418-2.367 2.11-.167.22-.125.533.094.701.09.069.197.103.303.103z" />
      <path d="m6.782 11.048c-.669-.68-1.246-1.266-1.282-2.07-.013-.276-.251-.52-.522-.477-.276.012-.489.246-.477.521.053 1.188.824 1.97 1.568 2.727.685.695 1.332 1.353 1.434 2.306.12 1.089-.502 2.295-1.849 3.584-.2.191-.207.508-.015.707.098.103.229.154.361.154.125 0 .249-.046.346-.139 1.583-1.517 2.307-3.002 2.151-4.415-.14-1.298-.977-2.148-1.715-2.898z" />
      <path d="m11.616 12.331c.099.112.236.169.375.169.118 0 .236-.041.332-.126.207-.183.226-.499.043-.705-.189-.214-.389-.419-.587-.621-.666-.68-1.24-1.267-1.278-2.071-.05-1.079.886-2.277 2.781-3.562.229-.155.288-.466.133-.694-.155-.229-.467-.287-.695-.134-2.203 1.493-3.286 2.986-3.219 4.438.056 1.186.822 1.968 1.562 2.724.187.191.375.384.553.584z" />
      <path d="m12.945 13.503c-.274.03-.473.277-.442.552.12 1.089-.502 2.295-1.849 3.584-.2.191-.207.508-.015.707.098.103.229.154.361.154.125 0 .249-.046.346-.139 1.583-1.517 2.307-3.002 2.151-4.416-.031-.274-.274-.475-.552-.442z" />
      <path d="m15.499 8.978c-.013-.276-.254-.52-.522-.477-.276.012-.489.246-.477.521.053 1.188.824 1.97 1.568 2.727.685.695 1.332 1.353 1.434 2.305.027.256.244.446.497.446.018 0 .036 0 .054-.003.275-.029.474-.276.444-.551-.14-1.298-.977-2.148-1.715-2.898-.669-.68-1.246-1.266-1.282-2.07z" />
      <path d="m15.749 7.5c.151 0 .299-.067.398-.196.463-.607 1.181-1.243 2.133-1.89.229-.155.288-.467.133-.695-.155-.229-.466-.286-.694-.133-1.042.708-1.838 1.418-2.367 2.11-.167.22-.125.533.094.701.09.069.197.103.303.103z" />
      <path d="m17.798 15.555c-.245-.124-.547-.027-.673.217-.308.602-.79 1.212-1.472 1.868-.199.191-.205.508-.014.707.098.102.229.153.36.153.125 0 .25-.046.347-.14.763-.733 1.309-1.432 1.668-2.132.126-.246.029-.548-.217-.674z" />
    </svg>
  );
}
