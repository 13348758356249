import type { StyleObject } from '../../functions/global-instance-styles';
import cx from 'classnames';

export function MenuItemPrice({
  inlineStyles,
  textAlignClass,
  price,
  color,
}: {
  inlineStyles: StyleObject;
  textAlignClass: string;
  price: string;
  color: string;
}) {
  return (
    <div
      data-html-menu="item-price"
      style={{
        color,
      }}
      className={cx(
        'htmlMenuPrice pt-4 text-base',
        textAlignClass,
        inlineStyles?.htmlMenuItemPriceWeight !== 'bold' ? 'font-normal' : 'font-bold',
        {
          OneLinkNoTx: inlineStyles?.itemPriceNoTx,
        }
      )}
    >
      {price}
    </div>
  );
}
