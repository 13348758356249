import { getDay } from 'date-fns';
import { useMemo } from 'react';
import { useDaysOfWeek } from './use-days-of-week';
import capitalize from 'lodash/capitalize';
import { DEFAULT_LOCALE_OPTIONS, isKanji, LOCALE_OPTIONS, weekStartsOn } from './utils';

type AdditionalFormatOptions = {
  monthName?: Intl.DateTimeFormatOptions['month'];
};

export type UseDateFormatPartTypes = 'day' | 'month' | 'year' | 'weekday';

export type UseDateFormatOptions = Pick<Intl.DateTimeFormatOptions, UseDateFormatPartTypes> &
  AdditionalFormatOptions;

export const DEFAULT_FORMAT_OPTIONS: UseDateFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  weekday: 'short',
  monthName: 'short',
};

type FormatPart = { numeric: string; text: string };

export type DateFormat = {
  day: FormatPart;
  month: FormatPart;
  year: FormatPart;
  weekday: string;
};

type UseDateFormatter = {
  /**
   * Date to format
   */
  date: Date | null;
  /**
   * The language locale to use to format the date
   */
  locale: Intl.Locale;
  /**
   * Format options for the given date. There are options to format both
   * text output and date output. [Intl.DateTimeFormat](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat)
   */
  options?: UseDateFormatOptions;
};

export function formatDateAndText(
  date: Date | null,
  locale: Intl.Locale,
  part: UseDateFormatPartTypes,
  options: UseDateFormatOptions
) {
  const config = {
    ...DEFAULT_LOCALE_OPTIONS,
    ...LOCALE_OPTIONS[locale.baseName || ''],
    ...options,
  } as const;
  const formatter = new Intl.DateTimeFormat(locale.toString(), config);
  const parts = date && formatter.formatToParts(date);
  const isNumeric = options[part] === 'numeric';
  let numeric;
  let text;
  if (isNumeric) {
    numeric = parts?.find(({ type }) => type === part)?.value || '';
    const value = parts?.find(({ type }) => type === 'literal')?.value?.trim() || '';
    text = value.match(/^[,.]$/) ? '' : value;
  } else {
    numeric = '';
    text = parts?.find(({ type }) => type === part)?.value?.trim() || '';
  }
  return { numeric, text };
}

/**
 * Date Formatter that provides the parts of the date broken up into `text` values and `numeric` values.
 *
 * ### Special Format Cases
 * There are are few special cases where we override the default text formats to provide in-language text support for dates
 *
 * #### Kanji
 * Kanji based languages which are `Japanese`, `Korean`, `Traditional Chinese` and `Simplified Chinese` default override will use `{ weekday: 'long' }`
 *
 * #### Bulgarian
 * There is an [issue with bulgarian](https://www.unicode.org/cldr/cldr-aux/charts/31/summary/bg.html), where the short month format is missing.
 * In order to obtain this format additional settings are required, in particular `{ weekday: 'long' }`
 */
export const useDateFormat = ({
  date,
  locale,
  options = DEFAULT_FORMAT_OPTIONS,
}: UseDateFormatter): DateFormat => {
  const config = useMemo(
    () => ({
      ...DEFAULT_FORMAT_OPTIONS,
      ...options,
    }),
    [options]
  );
  if (isKanji(locale)) {
    config.weekday = 'long';
  }
  const daysOfWeek = useDaysOfWeek({ locale, format: config.weekday });
  return useMemo(() => {
    if (!date) {
      return {
        day: { numeric: '', text: '' },
        month: { numeric: '', text: '' },
        year: { numeric: '', text: '' },
        weekday: '',
      };
    }

    const day = formatDateAndText(date, locale, 'day', { day: config.day });
    const month = formatDateAndText(date, locale, 'month', {
      month: config.month,
    });
    const year = formatDateAndText(date, locale, 'year', { year: config.year });
    // get the index day of the week from date-fns
    const dayIndex = getDay(date);
    // find the array index value
    const weekStart = weekStartsOn(locale) ?? 0;
    const index = dayIndex - weekStart;
    const weekday = daysOfWeek.at(index) || '';

    // find a month text if there is not one supplied already
    if (month.text.trim() === '') {
      month.text = formatDateAndText(date, locale, 'month', {
        month: config.monthName,
      }).text;
      /** bug with bulgarian short month format. short format for month is tied to additional settings
       * @see https://www.unicode.org/cldr/cldr-aux/charts/31/summary/bg.html */
      if (locale.baseName === 'bg') {
        const monthConfig = {
          ...config,
          ...{ month: config.monthName, weekday: 'long' as const },
        };
        month.text = formatDateAndText(date, locale, 'month', monthConfig).text;
      }
    }
    // process capitilization
    if (['ru', 'es'].includes(locale.baseName)) {
      month.text = capitalize(month.text);
    }

    return {
      day,
      month,
      year,
      weekday,
    };
  }, [config, date, daysOfWeek, locale]);
};

export default useDateFormat;
