import type { ReactElement, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import '../../../libs/curated/tailwind/src/main.css';
import 'keen-slider/keen-slider.min.css';
import '../styles/_base.css';
import { useRouter } from 'next/router';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import type { WpMenuItemLink } from '@curated-property/utils';
import {
  wpMenuMap,
  wpPageHierarchyMap,
  LinkContextProvider,
  SharedProvider,
} from '@curated-property/utils';
import type { FooterProps, MenuProps, BreadcrumbProps } from '@curated-property/shared-components';
import {
  AdobeAnalytics,
  adobeAnalyticsPropMapper,
  BreadCrumbs,
  Footer,
  Header,
  SchemaJSON,
  ButtonStyles,
  OscColourOverrides,
  ShopFormAndBookStyles,
  isColorLight,
  adjustColor,
} from '@curated-property/shared-components';
import { getDefaultCtyhocnInfo } from '../';
import Head from 'next/head';
import { appProps } from '../app-props';
import { hotelLogos } from '../logos';
import { QueryProvider } from '@dx-ui/framework-react-query';
import {
  headerStylesCMS,
  footerStylesCMS,
  breadcrumbStylesCMS,
  logoStylesCMS,
  modalStylesCMS,
} from '../utils/cms-component-props';
import { CMSLogo } from '../utils/cms-logo';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import type { AppProps } from 'next/app';

interface HotelLogo {
  hotelLogoHeader?: ReactNode;
  hotelLogoFooter?: ReactNode;
  brandLogo?: ReactNode;
}

export interface AdditionalHeadProps {
  pageId?: number;
  CTYHOCN?: string;
  brandCodeID?: string;
  pageSlug?: string;
}

interface CustomAppProps {
  BreadCrumbs?: (p: BreadcrumbProps) => ReactElement;
  AdditionalHead?: (p: AdditionalHeadProps) => ReactElement | undefined;
  Header?: (p: MenuProps) => ReactElement;
  Footer?: (p: FooterProps) => ReactElement;
}

interface FooterLinks {
  label?: string;
  url?: string;
}

function CustomApp({ Component, pageProps, router }: AppProps) {
  const isRouterReady = useRouter().isReady;
  const routerLocale = useRouter().locale || router.locale || 'en';
  const pageId = pageProps?.wpPageInfo?.page?.pageId;
  const { query, asPath, pathname, locale = 'en' } = useRouter();
  const ctyhocnLookup = pageProps?.ctyhocn?.toLowerCase() || '';
  const dxGql = pageProps?.dxGqlInfo;
  const brandCode = dxGql?.hotel?.brandCode?.toLowerCase();

  const [isFromWiFi, setIsFromWiFi] = useState('no');

  // For Conductrics WiFi Page Visit A/B Test
  useEffect(() => {
    const wifiVisited = global.sessionStorage.getItem('hasVisitedWiFi') ?? undefined;

    if (wifiVisited === 'yes' && isFromWiFi === 'yes') return;

    // If no wifi session value is set, add and set to 'no'
    if (!wifiVisited || wifiVisited === undefined) {
      global.sessionStorage.setItem('hasVisitedWiFi', 'no');
    } else {
      if (wifiVisited === 'yes') {
        setIsFromWiFi('yes');
      }
    }
  }, [isFromWiFi]);

  // Enable the new Hilton Hotel & Resorts brand theme (brand code must be "hi" & option to show the new theme must be checke in the CMS)
  const enableHHR =
    pageProps?.wpThemeSettings?.themeSettings?.SettingsThemeSettings?.useHhrBrandTheme;

  const brandThemeCMS = enableHHR
    ? 'hiltonhotelsresorts'
    : pageProps?.wpThemeSettings?.themeSettings?.SettingsThemeSettings?.brandTheme;

  const notThesePathnames =
    pathname !== '/_error' && pathname !== '/404' && pathname !== '/hotels/[hotelSlug]/wifi';

  useEffect(() => {
    const bc = brandCode ?? 'hi';
    document.body.dataset.pageId = pageId;
    document.body.dataset.siteId = ctyhocnLookup;
    document.body.dataset.brandCode = bc;
    document.body.classList.add('theme-' + (brandThemeCMS || bc));
    document.body.dataset.locale = locale;
  }, [ctyhocnLookup, brandCode, pageId, locale, brandThemeCMS]);

  // handles anchor link hover states
  useEffect(() => {
    if (isRouterReady) {
      document
        .querySelectorAll(
          "a:not(.btn,[data-element-id='nav-desktop'] a,[data-element-id='nav-mobile'] a,[data-element-id='nav-utility'] a)"
        )
        .forEach((link) => {
          const anchor = link as HTMLElement;
          const color = window.getComputedStyle(link).color;
          const lighterColor = adjustColor(color, 0.25, true);
          const darkerColor = adjustColor(color, 0.25);
          if (isColorLight(color)) {
            anchor.addEventListener('mouseover', () => {
              anchor.style.setProperty('color', darkerColor, 'important');
            });
            anchor.addEventListener('mouseout', () => {
              anchor.style.setProperty('color', lighterColor, 'important');
            });
          } else {
            anchor.addEventListener('mouseover', () => {
              anchor.style.setProperty('color', lighterColor, 'important');
            });
            anchor.addEventListener('mouseout', () => {
              anchor.style.setProperty('color', darkerColor, 'important');
            });
          }
        });
    }
  }, [isRouterReady, pageId]);

  // Hotel Logo from CMS fields
  const logoEndpoint = pageProps?.wpThemeSettings?.logoSettings?.SettingsLogoSettings;

  const logoStyles = logoStylesCMS(logoEndpoint, brandThemeCMS || brandCode);

  const cmsHotelLogo = logoEndpoint?.hotelLogo?.sourceUrl
    ? CMSLogo({
        id: 'hotel-logo',
        logoUrl: logoEndpoint?.hotelLogo?.sourceUrl,
        classes: 'mr-4 ' + logoEndpoint?.hotelLogoClasses,
        colour: logoEndpoint?.hotelLogoColourInactive || logoStyles?.hotelLogoColourInactive,
        activeColour: logoEndpoint?.hotelLogoColourActive || logoStyles?.hotelLogoColourActive,
      })
    : null;

  const cmsHotelLogoFooter = logoEndpoint?.hotelLogo?.sourceUrl
    ? CMSLogo({
        logoUrl: logoEndpoint?.hotelLogo?.sourceUrl,
        classes: logoEndpoint?.hotelLogoClassesFooter,
        colour: logoEndpoint?.hotelLogoColourFooter,
      })
    : null;

  const cmsBrandLogo = logoEndpoint?.brandLogo?.sourceUrl
    ? CMSLogo({
        id: 'brand-logo',
        logoUrl: logoEndpoint?.brandLogo?.sourceUrl,
        classes: 'ml-4 ' + logoEndpoint?.brandLogoClasses,
        colour: logoEndpoint?.brandLogoColourInactive,
        activeColour: logoEndpoint?.brandLogoColourActive,
      })
    : null;

  const siteLogoFile = hotelLogos[ctyhocnLookup as keyof typeof hotelLogos] as HotelLogo;

  // JS function logos
  const hotelLogoHeader = cmsHotelLogo || siteLogoFile?.hotelLogoHeader || <div />;
  const hotelLogoFooter = cmsHotelLogoFooter || siteLogoFile?.hotelLogoFooter || <div />;
  const brandLogo = cmsBrandLogo || siteLogoFile?.brandLogo || undefined;
  const pageMeta = pageProps?.wpPageInfo?.page?.metaDataFields;
  const menuNavProps = wpMenuMap(pageProps?.menuInfo?.menu?.menuItems);
  let iconMenuProps = wpMenuMap(pageProps?.iconMenuInfo?.menu?.menuItems) as
    | WpMenuItemLink[]
    | undefined;
  let footerNavProps = wpMenuMap(pageProps?.footerMenuInfo?.menu?.menuItems) as
    | WpMenuItemLink[]
    | undefined;
  const langNavProps = {
    colors: pageProps?.langMenuStyles?.languageSelector,
  };

  const defaultCtyhocnData = getDefaultCtyhocnInfo(
    pageProps?.associatedHotelInfo,
    pageProps?.defaultCtyhocn
  );
  // filter items from menus if they don't belong
  footerNavProps = footerNavProps?.filter((item) => item?.location?.includes('FOOTER_NAVIGATION'));
  iconMenuProps = iconMenuProps?.filter((item) => item?.location?.includes('MINI_NAVIGATION'));
  // new footer link query seperates all links into different arrays
  const footerLinksCombinedArray: FooterLinks[] = [];
  pageProps?.dxGqlInfo?.brand?.footer?.sections?.forEach((sect: { links?: FooterLinks[] }) => {
    sect?.links?.forEach((l) => {
      footerLinksCombinedArray.push({ label: l.label, url: l.url });
    });
  });

  const themeSettingProps = pageProps?.wpThemeSettings;
  const footerProps: FooterProps = {
    links: footerLinksCombinedArray,
    menu: footerNavProps,
    hotelLogo: hotelLogoFooter,
    socialLinks: {
      facebookLink: themeSettingProps?.socialLink?.socialLinks?.facebookLink,
      instagramLink: themeSettingProps?.socialLink?.socialLinks?.instagramLink,
      twitterLink: themeSettingProps?.socialLink?.socialLinks?.twitterLink,
      linkedinLink: themeSettingProps?.socialLink?.socialLinks?.linkedinLink,
    },
    appUrlPrefix: query.hotelSlug as string,
    pageSlug: pageProps?.wpPageInfo?.page?.slug,
    disclaimer: themeSettingProps?.footerSettings?.SettingsFooterSettings?.footer?.disclaimerText,
    showCopyright: themeSettingProps?.footerSettings?.SettingsFooterSettings?.copyright?.enable,
    ...footerStylesCMS(
      themeSettingProps?.footerSettings?.SettingsFooterSettings,
      brandThemeCMS || brandCode
    ),
    additionalLogosCMS:
      themeSettingProps?.logoSettings?.SettingsLogoSettings?.additionalLogos || null,
    country: dxGql?.hotel?.address?.country,
  };

  const baseUrl = (global.window && window.location.origin) || process.env.NEXT_PUBLIC_DOMAIN_NAME;
  const fullUrl = new URL(`/${locale}${asPath}`, baseUrl);
  const canonicalUrl = `${fullUrl.origin}${fullUrl.pathname}`;
  const pageSlug = pageProps?.wpPageInfo?.page?.slug;

  const adobeProps = adobeAnalyticsPropMapper(
    pageProps?.wpPageInfo?.page,
    themeSettingProps,
    dxGql?.hotel,
    pageProps.ctyhocn,
    locale.toUpperCase(),
    pageProps?.associatedHotelInfo
  );

  // Favicon assignment
  const brandCtyhocnExceptions = [
    { ctyhocn: 'sjcsmhh', assignedID: 'SA' },
    { ctyhocn: 'lonnmol', assignedID: 'ND' },
    { ctyhocn: 'orlemqq', assignedID: 'EVERMORE' },
    { ctyhogn: 'prghitw', assignedID: 'TW' },
  ];
  let brandCodeID: string;
  brandCtyhocnExceptions.forEach((e: (typeof brandCtyhocnExceptions)[number]) => {
    if (e.ctyhocn === ctyhocnLookup) {
      brandCodeID = e.assignedID && e.assignedID !== undefined ? e.assignedID : '';
      return brandCodeID;
    }
  });

  !brandCodeID && (!brandCode ? (brandCodeID = 'HI') : (brandCodeID = brandCode));

  const brandCodeUppercase = brandCodeID.toUpperCase();
  const allBrandCodes = [
    'CH',
    'DT',
    'ES',
    'GI',
    'HH',
    'HI',
    'HP',
    'HT',
    'HW',
    'ND',
    'OL',
    'PY',
    'QQ',
    'RU',
    'SA',
    'SIGNIA',
    'EVERMORE',
    'UP',
    'WA',
  ];
  const faviconFileName = `${
    allBrandCodes.indexOf(brandCodeUppercase) !== -1 ? brandCodeUppercase : 'HI'
  }-favicon.ico`;

  const isRoomsOnPage: boolean = pageProps?.wpPageInfo?.page?.acfFlexible?.flexibleComponents?.some(
    (item: { __typename?: string }) => item?.__typename?.includes('RoomTypes')
  );

  const attachToHeaderDefault = enableHHR
    ? true
    : themeSettingProps?.shopFormSettings?.shopFormSettings?.attachToHeader;

  const headerProps: MenuProps = {
    activePageId: pageId,
    pageSlug: pageProps?.wpPageInfo?.page?.slug,
    menu: menuNavProps,
    iconMenu: iconMenuProps,
    languageMenu: langNavProps,
    locale,
    isFrontPage: pageProps?.wpPageInfo?.page?.isFrontPage,
    navKey: pageProps?.wpPageInfo?.page?.slug,
    showWidgetOnHomepageLoad: false,
    brandLogo,
    defaultArrivalDate: pageProps?.dxGqlInfo?.hotel?.display?.resEnabledDate,
    gmtHours: pageProps?.dxGqlInfo?.hotel?.localization?.gmtHours,
    currency: pageProps?.dxGqlInfo?.hotel?.localization?.currencyCode,
    hotelLogo: hotelLogoHeader,
    CTYHOCN: pageProps?.wpPageInfo?.page?.metaDataFields?.ctyhocnOverride || pageProps.ctyhocn,
    brandCode,
    cmsTranslationOverrides:
      pageProps?.wpThemeSettings?.translationSettings?.SettingsTranslations
        ?.languageSelectorOverrides,
    cmsLogoSettings: {
      ...logoStyles,
    },
    hotelDates: {
      hotelOpen:
        defaultCtyhocnData?.hotel?.display?.open ||
        pageProps?.dxGqlInfo?.hotel?.display?.open ||
        false,
      openDate:
        defaultCtyhocnData?.hotel?.display?.openDateFmt ||
        pageProps?.dxGqlInfo?.hotel?.display?.openDateFmt ||
        '',
      preOpenMsg:
        defaultCtyhocnData?.hotel?.display?.preOpenMsg ||
        pageProps?.dxGqlInfo?.hotel?.display?.preOpenMsg,
      resEnabled:
        defaultCtyhocnData?.hotel?.display?.resEnabled ||
        pageProps?.dxGqlInfo?.hotel?.display?.resEnabled ||
        false,
      resEnabledDate:
        defaultCtyhocnData?.hotel?.display?.resEnabledDate ||
        pageProps?.dxGqlInfo?.hotel?.display?.resEnabledDate ||
        '',
    },
    hotelAlerts: defaultCtyhocnData?.hotel?.alerts || pageProps?.propertyAlerts?.hotel?.alerts,
    title: pageProps?.dxGqlInfo?.hotel?.name,
    headerSettings: themeSettingProps?.headerSettings,
    alertBarSettings: themeSettingProps?.alertBarSettings?.alertBarSettings,
    modalSettings: {
      ...modalStylesCMS(
        themeSettingProps?.modalSettings?.modalSettings,
        brandThemeCMS || brandCode
      ),
    },
    associatedHotels: pageProps?.associatedHotelInfo,
    styleOptions: {
      ...headerStylesCMS(
        themeSettingProps?.headerSettings?.SettingsHeaderSettings,
        brandThemeCMS || brandCode
      ),
    },
    bookingWidgetConfig: {
      ctyhocn: pageProps?.ctyhocn,
      defaultArrivalDate: pageProps?.dxGqlInfo?.hotel?.display?.resEnabledDate,
      gmtHours: pageProps?.dxGqlInfo?.hotel?.localization?.gmtHours,
      brandCode: pageProps?.dxGqlInfo?.hotel?.brandCode || 'HI',
      resEnabled: pageProps?.dxGqlInfo?.hotel?.display?.resEnabled,
      currency: pageProps?.dxGqlInfo?.hotel?.localization?.currencyCode,
      associatedHotels: pageProps?.associatedHotelInfo,
      shopFormInHeader: !isRoomsOnPage && attachToHeaderDefault,
      oscBookButtonStyle:
        themeSettingProps?.shopFormSettings?.shopFormSettings?.bookButtonStyleFromGlobal
          ?.shopFormBookGlobalButtonStyleButtonStyle,
      shopFormBackgroundColor:
        themeSettingProps?.shopFormSettings?.shopFormSettings?.shopFormBgColor,
      shopFormBackgroundBlur:
        themeSettingProps?.shopFormSettings?.shopFormSettings?.shopFormBackgroundBlur,
    },
  };

  const breadCrumbProps = {
    hierarchy: wpPageHierarchyMap(pageProps?.wpPageInfo?.page),
    hierarchyArrayLength: wpPageHierarchyMap(pageProps?.wpPageInfo?.page).length,
    prefix: `/hotels/${query.hotelSlug}`,
    hotelName: pageProps?.dxGqlInfo?.hotel?.name,
    countryName: pageProps?.dxGqlInfo?.hotel?.address?.countryName,
    countryName_noTx: pageProps?.dxGqlInfo?.hotel?.address?.countryName_noTx,
    city: pageProps?.dxGqlInfo?.hotel?.address?.city,
    city_noTx: pageProps?.dxGqlInfo?.hotel?.address?.city_noTx,
    pageSlug: pageProps?.wpPageInfo?.page?.slug,
    stateName: pageProps.dxGqlInfo?.hotel?.address?.stateName,
    stateName_noTx: pageProps.dxGqlInfo?.hotel?.address?.stateName_noTx,
    delimiter: null,
    ...breadcrumbStylesCMS(
      themeSettingProps?.footerSettings?.SettingsFooterSettings,
      brandThemeCMS || brandCode
    ),
  };
  const siteAppProps = appProps[ctyhocnLookup as keyof typeof appProps] as CustomAppProps;
  // useRouter linting error work around
  if (!siteAppProps?.BreadCrumbs) {
    breadCrumbProps.delimiter = themeSettingProps?.footerSettings?.SettingsFooterSettings
      ?.breadcrumbs?.delimiter && (
      <span
        className={`text-brand-5 md: px-1${breadCrumbProps?.delimiterPadding}`}
        style={{ color: breadCrumbProps?.delimiterColour }}
      >
        {themeSettingProps?.footerSettings?.SettingsFooterSettings?.breadcrumbs?.delimiter}
      </span>
    );
  }
  const FinalHeader = siteAppProps?.Header || Header;
  const FinalFooter = siteAppProps?.Footer || Footer;
  const FinalBreadCrumbs = siteAppProps?.BreadCrumbs || BreadCrumbs;
  const AdditionalHead =
    siteAppProps?.AdditionalHead ||
    (() => {
      return null;
    });

  const mapSchemaProps = {
    title: pageMeta?.openGraphTitle
      ? pageMeta?.openGraphTitle
      : pageMeta?.metaTitle
      ? pageMeta?.metaTitle
      : pageProps?.wpThemeSettings?.allSettings?.generalSettingsTitle,
    description: pageMeta?.openGraphDescription
      ? pageMeta?.openGraphDescription
      : pageProps?.wpThemeSettings?.allSettings?.generalSettingsDescription
      ? pageProps?.wpThemeSettings?.allSettings?.generalSettingsDescription
      : dxGql?.hotel?.facilityOverview?.shortDesc,
    image: pageMeta?.openGraphImage?.sourceUrl
      ? pageMeta?.openGraphImage?.sourceUrl
      : `${baseUrl}/modules/assets/svgs/logos/${brandCode?.toUpperCase() || 'HI'}.svg`,
  };

  return (
    <main>
      <SharedProvider
        pageInfo={{
          pageId: pageProps?.wpPageInfo?.page?.pageId,
          slug: pageProps?.wpPageInfo?.page?.slug,
        }}
        brandCode={brandCode}
        ctyhocn={pageProps.ctyhocn}
        allInclusive={dxGql?.hotel?.hotelAmenities?.some(
          (a: { id: string }) => a.id === 'allInclusive'
        )}
        hotelName={pageProps?.dxGqlInfo?.hotel?.name}
        dxGql={dxGql}
        locale={locale}
        modalStyles={modalStylesCMS(
          themeSettingProps?.modalSettings?.modalSettings,
          brandThemeCMS || brandCode
        )}
        enableHHR={enableHHR}
        hasVisitedWiFi={isFromWiFi}
      >
        <QueryProvider
          dehydratedQueryState={pageProps?.dehydratedState}
          oneLinkConfig={pageProps?.oneLinkConfig}
          routerLocale={routerLocale}
          {...pageProps.queryClientProps}
        >
          <LinkContextProvider itemPrefix={`/hotels/${query.hotelSlug}`}>
            <LocationProvider>
              <>
                <Head>
                  <title>{pageMeta?.metaTitle ? pageMeta?.metaTitle : ''}</title>
                  <meta name="description" content={pageMeta?.metaDescription} />
                  <meta property="og:title" content={mapSchemaProps.title} />
                  <meta property="og:description" content={mapSchemaProps.description} />
                  <meta property="og:type" content="website" />
                  <meta property="og:url" content={canonicalUrl} />
                  <meta property="og:image" content={mapSchemaProps.image} />
                  {AdditionalHead({
                    pageId,
                    CTYHOCN: ctyhocnLookup,
                    brandCodeID,
                    pageSlug,
                  })}
                  <link rel="canonical" href={canonicalUrl} />
                  <link
                    rel="icon"
                    type="image/x-icon"
                    href={`/curated/dx-curated-ui/favicons/${faviconFileName}`}
                  />
                  <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                </Head>
                {isRouterReady ? <AdobeAnalytics key={pageSlug} {...adobeProps} /> : null}
                <SchemaJSON
                  dxGql={pageProps?.dxGqlInfo}
                  wpGql={pageProps?.wpThemeSettings}
                  pageMeta={pageMeta}
                />

                {pageProps.brandCssExists && (
                  <link
                    rel="stylesheet"
                    href={`/${locale}/hotels/${pageProps.hotelSlug}/styles/theme-${
                      brandThemeCMS || brandCode?.toLowerCase()
                    }.css`}
                  />
                )}
                {ctyhocnLookup && pageProps.cssExists && (
                  <link
                    rel="stylesheet"
                    href={`/${locale}/hotels/${pageProps.hotelSlug}/styles/${ctyhocnLookup}.css`}
                  />
                )}

                <OscColourOverrides
                  languageSelectorDropdownStyles={
                    themeSettingProps?.headerSettings?.SettingsHeaderSettings
                      ?.languageSwitchDropdownStyles
                  }
                />

                <ButtonStyles
                  buttons={themeSettingProps?.buttonSettings?.buttonsSettings?.buttonStyles}
                  waldorfButtons={
                    brandCode?.toUpperCase() === 'WA' &&
                    themeSettingProps?.buttonSettings?.buttonsSettings?.waldorfButtonStyles
                  }
                  pageSlug={pageSlug}
                />
                {brandCode?.toUpperCase() !== 'WA' && (
                  <ShopFormAndBookStyles
                    buttons={themeSettingProps?.buttonSettings?.buttonsSettings?.buttonStyles}
                    shopFormAndBookStyles={themeSettingProps?.shopFormSettings?.shopFormSettings}
                  />
                )}
                <noscript>
                  For full functionality of this site it is necessary to enable JavaScript. Here are
                  the{' '}
                  <a href="https://www.enable-javascript.com/">
                    instructions how to enable JavaScript in your web browser
                  </a>
                  .
                </noscript>
                <div className={!isRouterReady ? 'opacity-0' : 'opacity-1'}>
                  {notThesePathnames && <FinalHeader {...headerProps} />}
                  <Component {...pageProps} hotelLogo={hotelLogoHeader} />
                  {notThesePathnames && <FinalBreadCrumbs {...breadCrumbProps} />}
                  {pathname !== '/_error' && pathname !== '/404' && (
                    <FinalFooter {...footerProps} />
                  )}
                </div>
              </>
            </LocationProvider>
          </LinkContextProvider>
        </QueryProvider>
      </SharedProvider>
    </main>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(CustomApp);
