export function CloseSquare({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V7A2.3777,2.3777,0,0,0,17,4.625ZM18.625,17A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7Z" />
          <path d="M15.2656,8.7344a.376.376,0,0,0-.5312,0L12,11.4688,9.2656,8.7344a.3756.3756,0,0,0-.5312.5312L11.4687,12,8.7344,14.7344a.3756.3756,0,0,0,.5312.5312L12,12.5312l2.7344,2.7344a.3756.3756,0,0,0,.5312-.5312L12.5312,12l2.7344-2.7344A.3778.3778,0,0,0,15.2656,8.7344Z" />
        </g>
      </g>
    </svg>
  );
}
