import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./bpncici/hotel-logo').then((i) => i.HotelLogo)
);
const BrandLogo = dynamic<{ className: string }>(() =>
  import('./bpncici/brand-logo').then((i) => i.BrandLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-24 w-40 fill-current text-bg antialiased md:w-48" />
);

export const hotelLogoFooter = (
  <BrandLogo className="h-12 w-[180px] fill-current text-bg antialiased md:w-48" />
);
