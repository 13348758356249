export function filterCookies(cookingString: string, allowedCookies: string[]): string {
  const result = cookingString.split('; ');
  return result
    .filter((cookie) => {
      const [key] = cookie.split('=');
      if (!key) return false;
      return allowedCookies.includes(key);
    })
    .join('; ');
}
