import { ChevronLeft, ChevronRight } from '@curated-property/icons';
import { chunkArray } from '@curated-property/utils';
import {
  addDays,
  addMonths,
  getDaysInMonth,
  isSameDay,
  isSameMonth,
  startOfMonth,
  subDays,
  isSunday,
  previousSunday,
  differenceInCalendarDays,
} from 'date-fns';
import type { MutableRefObject, FC } from 'react';
import { useContext, useEffect, useRef, useState, Fragment } from 'react';
import { WACContext } from '.';
import { GIS_TextAlignment } from '../functions/global-instance-styles';
import { defaultStyles } from './default-styling';
import type { WACStyles } from './interfaces';
import cx from 'classnames';

export enum WACCalendarCellState {
  DISABLED,
  FREE,
  PARTIAL,
  FULL,
  SELECTED,
}

export interface WACCalendarCellProps {
  state: WACCalendarCellState;
  WACStyles: WACStyles;
  onClick?: () => void;
  a11yUp?: () => void;
  a11yDown?: () => void;
  a11yLeft?: () => void;
  a11yRight?: () => void;
  ariaLabel?: string;
  focusRef?: MutableRefObject<HTMLButtonElement | null> | null;
  tabbable?: boolean;
  children?: number;
}

const WACCalendarCell: FC<WACCalendarCellProps> = ({
  focusRef,
  children,
  state,
  WACStyles,
  onClick,
  ariaLabel,
  a11yUp,
  a11yDown,
  a11yLeft,
  a11yRight,
  tabbable = false,
}) => {
  let styles = defaultStyles?.calendar?.cells?.free;

  let buttonStateClass = '';

  switch (state) {
    case WACCalendarCellState.DISABLED:
      styles = WACStyles?.calendar?.cells?.disabled;
      buttonStateClass = 'date-disabled';
      break;
    case WACCalendarCellState.FULL:
      styles = WACStyles?.calendar?.cells?.full;
      buttonStateClass = 'date-full';
      break;
    case WACCalendarCellState.PARTIAL:
      styles = WACStyles?.calendar?.cells?.partial;
      buttonStateClass = 'date-partial';

      break;
    case WACCalendarCellState.FREE:
      styles = WACStyles?.calendar?.cells?.free;
      buttonStateClass = 'date-free';
      break;
    case WACCalendarCellState.SELECTED:
      styles = WACStyles?.calendar?.cells?.selected;
      buttonStateClass = 'date-selected';
      break;
  }

  return (
    <td className="size-10 border border-transparent">
      <div
        className={cx('relative flex size-full items-center justify-center', buttonStateClass)}
        style={{
          color: styles?.textColor,
          backgroundColor: styles?.backgroundColor,
        }}
      >
        <div
          className="absolute size-0"
          style={{
            left: -4,
            top: -1,
            borderColor: 'transparent',
            borderBottomColor: styles?.cornerFlagColor,
            borderWidth: 6,
            borderTopWidth: 0,
            transform: 'rotate(-45deg)',
          }}
        />
        {state === WACCalendarCellState.DISABLED || state === WACCalendarCellState.FULL ? (
          <div>{children}</div>
        ) : (
          <button
            data-testid="dateButton"
            tabIndex={!tabbable ? -1 : undefined}
            ref={focusRef}
            onKeyDown={(e) => {
              switch (e.key) {
                case 'ArrowUp':
                  e.preventDefault();
                  a11yUp?.();
                  break;
                case 'ArrowDown':
                  a11yDown?.();
                  e.preventDefault();
                  break;
                case 'ArrowLeft':
                  a11yLeft?.();
                  e.preventDefault();
                  break;
                case 'ArrowRight':
                  a11yRight?.();
                  e.preventDefault();
                  break;
              }
            }}
            onClick={onClick}
            aria-label={ariaLabel}
            className="size-full"
          >
            {children}
          </button>
        )}
      </div>
    </td>
  );
};

export const WACCalendar: FC<{
  styles: WACStyles;
}> = ({ styles }) => {
  const {
    selectedYearMonth,
    setSelectedYearMonth,
    selectedDate,
    setSelectedDate,
    setSelectedTimeSlot,
    setSelectedVenue,
    minDate,
    maxDate,
    fullAvailability,
  } = useContext(WACContext);
  const [focusDate, setFocusDate] = useState(selectedDate);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    buttonRef?.current?.focus();
  }, [buttonRef, focusDate]);
  const firstDayOfMonth = startOfMonth(selectedYearMonth);
  const monthTitle = firstDayOfMonth.toLocaleDateString('en-US', {
    month: 'long',
  });
  const yearTitle = firstDayOfMonth.getFullYear();
  const daysInMonth = getDaysInMonth(firstDayOfMonth);
  const firstSundayDate = isSunday(firstDayOfMonth)
    ? new Date(firstDayOfMonth)
    : previousSunday(firstDayOfMonth);
  const tmpDate = new Date(firstSundayDate);
  const allDays = [];
  let bookingsForMonth = [];
  bookingsForMonth =
    fullAvailability?.[selectedYearMonth?.getFullYear()]?.[selectedYearMonth?.getMonth()] || [];

  allDays.push(
    ...Array(differenceInCalendarDays(firstDayOfMonth, firstSundayDate)).fill(
      <WACCalendarCell WACStyles={styles} state={WACCalendarCellState.DISABLED} />
    )
  );
  tmpDate.setDate(tmpDate.getDate() + differenceInCalendarDays(firstDayOfMonth, firstSundayDate));
  for (let i = 0; i < daysInMonth; i++) {
    let state = WACCalendarCellState.FREE;
    if (bookingsForMonth[i + 1]) state = WACCalendarCellState.PARTIAL;
    if (isSameDay(tmpDate, selectedDate || 0)) state = WACCalendarCellState.SELECTED;
    const potentialDate = new Date(tmpDate);

    allDays.push(
      <WACCalendarCell
        WACStyles={styles}
        state={state}
        tabbable={(focusDate == null && i === 0) || isSameDay(potentialDate, focusDate || 0)}
        focusRef={isSameDay(potentialDate, focusDate || 0) ? buttonRef : null}
        a11yUp={() => {
          setSelectedTimeSlot?.('');
          setSelectedVenue?.('');
          const newDate = subDays(potentialDate, 7);
          setSelectedDate?.(newDate);
          setSelectedYearMonth?.(newDate);
          setFocusDate(newDate);
        }}
        a11yDown={() => {
          setSelectedTimeSlot?.('');
          setSelectedVenue?.('');
          const newDate = addDays(potentialDate, 7);
          setSelectedDate?.(newDate);
          setSelectedYearMonth?.(newDate);
          setFocusDate(newDate);
        }}
        a11yLeft={() => {
          setSelectedTimeSlot?.('');
          setSelectedVenue?.('');
          const newDate = subDays(potentialDate, 1);
          setSelectedDate?.(newDate);
          setSelectedYearMonth?.(newDate);
          setFocusDate(newDate);
        }}
        a11yRight={() => {
          setSelectedTimeSlot?.('');
          setSelectedVenue?.('');
          const newDate = addDays(potentialDate, 1);
          setSelectedDate?.(newDate);
          setSelectedYearMonth?.(newDate);
          setFocusDate(newDate);
        }}
        onClick={() => {
          setSelectedTimeSlot?.('');
          setSelectedVenue?.('');
          setSelectedDate?.(potentialDate);
        }}
        ariaLabel={tmpDate.toLocaleDateString('en', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })}
      >
        {tmpDate.getDate()}
      </WACCalendarCell>
    );
    tmpDate.setDate(tmpDate.getDate() + 1);
  }
  const disabledCells = Array(42 - allDays.length).fill(
    <WACCalendarCell WACStyles={styles} state={WACCalendarCellState.DISABLED} />
  );
  allDays.push(...disabledCells);
  const allWeeks = chunkArray(allDays, 7);
  const dayTitles = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const prevMonth = () => {
    if (!isSameMonth(selectedYearMonth, minDate))
      setSelectedYearMonth?.(addMonths(selectedYearMonth, -1));
  };
  const nextMonth = () => {
    if (!isSameMonth(selectedYearMonth, maxDate))
      setSelectedYearMonth?.(addMonths(selectedYearMonth, 1));
  };

  return (
    <div>
      <h3
        className="mb-6 text-center text-3xl"
        style={{
          textAlign: GIS_TextAlignment(styles?.subheadingAlign),
          color: styles?.subheadingColor,
        }}
      >
        Calendar
      </h3>
      <div className="align-center flex justify-center">
        <button
          className="disabled:opacity-50"
          disabled={isSameMonth(selectedYearMonth, minDate)}
          aria-label="previous month"
          onClick={prevMonth}
          data-testid="wac-prev-month"
        >
          <ChevronLeft className="mx-4 w-2" />
        </button>
        <div className="mx-6" data-testid="monthYear">
          {monthTitle}, {yearTitle}
        </div>
        <button aria-label="next month" onClick={nextMonth} data-testid="wac-next-month">
          <ChevronRight className="mx-4 w-2" />
        </button>
      </div>
      <table
        data-element-id="wedding-availability-calendar-table"
        className="m-auto border-collapse text-center"
      >
        <thead>
          <tr>
            {dayTitles.map((t, idx) => (
              <th key={idx} className="size-10 border-2 border-transparent">
                {t}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allWeeks.map((week, idx) => (
            <tr key={idx}>
              {week?.map((day, ind: number) => (
                <Fragment key={ind}>{day as React.ReactNode}</Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
