import { useState, useEffect } from 'react';
import cx from 'classnames';
import ImageTag from './image';
import { isReducedMotion, useIsClient } from '@curated-property/utils';
import { HeroImageLogo } from './logo';
import OverlayCopy from './overlay-copy';
import {
  GetHeaderHeight,
  HandleAnimations,
  HasPropertyAlert,
  useWindowSize,
} from '@curated-property/shared-components';
import { HeroImageOverlay } from './overlay';
import { HeroImageSlider } from './image-slider';
import type { VideoTrack } from '@dx-ui/osc-video-player';
import { useVideoPlayer } from '@dx-ui/osc-video-player';
import type { HeroImageProps, IHeroStyles, VideoProps } from './hero-image.types';
import { GIS_merge, GIS_Padder } from '../functions/global-instance-styles';
import OscComposableSearchForm from '../osc-composable/osc-composable-search-form';
import { ImageChangeHandler } from './helpers/image-change-handler';
import { useTranslation } from 'next-i18next';

export function Hero(props: HeroImageProps) {
  const [videoActive, setVideoActive] = useState(props?.videoAutoplay);
  const { t } = useTranslation();
  const headerHeight = GetHeaderHeight();

  const videoId = `mastheadVideo-${props?.componentInstance}`;
  const videoProps = {
    videoId,
    isAutoPlay: props?.videoAutoplay,
    videoLabel: t('en'),
    videoUrl: props?.videoUrl,
  };

  const videoPlayerProps: VideoProps = useVideoPlayer({
    ...videoProps,
    captionTracks: props?.captionTracks as VideoTrack['captionTracks'],
    transcriptTracks: props?.transcriptTracks as VideoTrack['transcriptTracks'],
    audioTracks: props?.audioTracks as VideoTrack[],
  });

  useEffect(() => {
    const isSafariMobile =
      typeof navigator !== 'undefined' &&
      /iP(ad|hone|od).+Version\/[\d.]+.*Safari/i.test(navigator.userAgent);

    // @ts-expect-error - window.safari is not standard and is only in the safari browser
    if (typeof window !== 'undefined' && (window.safari || isSafariMobile)) {
      setTimeout(() => {
        if (
          videoPlayerProps?.videoProps?.videoElement.current &&
          videoPlayerProps?.videoProps?.videoElement.current.src !== videoProps?.videoUrl
        ) {
          videoPlayerProps.videoProps.videoElement.current.src = videoProps?.videoUrl;
        }
      }, 50);
    }
  }, [videoPlayerProps.videoProps.videoElement, videoProps?.videoUrl]);

  useEffect(() => {
    if (props?.videoMuteChoice && !videoPlayerProps?.videoControlsProps?.playerState?.isMuted) {
      videoPlayerProps?.videoControlsProps?.toggleMuteButton();
    }
  }, [props?.videoMuteChoice, videoPlayerProps]);

  const defaultPaddingSize = HasPropertyAlert() ? '164px' : '128px';
  const isVideoPaused = !videoPlayerProps?.videoControlsProps?.playerState?.isPlaying;
  const inlineStyles = GIS_merge(props?.globalStyles, props?.instanceStyles) as IHeroStyles;

  const hideAnimations = inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  const animationDirection: string = inlineStyles?.animationDirection;
  const associatedHotels = props?.bookingWidgetConfig?.associatedHotels;
  let imgSrc: string | undefined = props?.imgSrc;
  if (props?.imageCarouselChoice && imgSrc) {
    imgSrc = null;
  }

  const componentPaddingCheck =
    inlineStyles?.paddingTop?.paddingTopDesktop ||
    inlineStyles?.paddingBottom?.paddingBottomDesktop ||
    inlineStyles?.paddingTop?.paddingTopTablet ||
    inlineStyles?.paddingBottom?.paddingBottomTablet ||
    inlineStyles?.paddingTop?.paddingTopMobile ||
    inlineStyles?.paddingBottom?.paddingBottomMobile
      ? true
      : false;

  const paddingStyles: string = componentPaddingCheck
    ? GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom)
    : '';

  let mastheadGradientStyles = '';

  // Sets gradient to 'None' on the new Hilton themed sites, unless a setting is specifically applied while that theme is active.
  const hhrGradientCheck =
    !inlineStyles?.mastheadGradient && props?.enableHHR ? 'None' : inlineStyles?.mastheadGradient;

  switch (hhrGradientCheck) {
    case 'Bottom to top':
      mastheadGradientStyles = 'from-text bg-gradient-to-t to-transparent top-auto';
      break;
    case 'Top to bottom':
      mastheadGradientStyles = 'from-text bg-gradient-to-b to-transparent';
      break;
    case 'None':
      mastheadGradientStyles = 'hidden';
      break;
    default:
      mastheadGradientStyles = 'from-text bg-gradient-to-b to-transparent';
  }

  let bookingWidgetChoice: boolean | undefined = props?.bookingWidgetChoice;
  bookingWidgetChoice =
    useIsClient() &&
    props?.pageDetails?.isFrontPage &&
    bookingWidgetChoice &&
    !props?.isShopFormInHeader &&
    (props?.bookingWidgetConfig?.resEnabled ||
      props?.bookingWidgetConfig?.associatedHotels?.length > 0);

  const isFullWidthOverlay: boolean = inlineStyles?.overlayFullWidth === 'full';
  const isTabletSize: boolean = useWindowSize()?.width > 768;
  const isDesktopSize: boolean = useWindowSize()?.width >= 1024;
  const isLargeDesktopSize: boolean = useWindowSize()?.width >= 1280;

  // An image gallery control has been clicked, this will pause, mute and hide video, cc and transcripts
  const showImageGallery = () => {
    videoPlayerProps?.videoControlsProps?.playerState?.showCaptions &&
      videoPlayerProps?.videoControlsProps?.toggleCaptions();
    videoPlayerProps?.videoTranscriptProps?.activeTranscript &&
      videoPlayerProps?.videoTranscriptProps?.closeTranscript();

    if (videoActive) {
      setVideoActive(false);
      !isVideoPaused && videoPlayerProps?.videoControlsProps?.togglePlayButton();
      !videoPlayerProps?.videoControlsProps?.playerState?.isMuted &&
        videoPlayerProps?.videoControlsProps?.toggleMuteButton();
    }
  };

  const hasBothControls: boolean =
    props?.videoChoice && props?.imageCarouselChoice && props?.imageCarousel ? true : false;
  let bookingWidgetPosition: string = props?.bookingWidgetPosition;
  if (hasBothControls) bookingWidgetPosition = '';

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
  });

  const mobileCropStyles = [
    props?.mobileCroppingAutoPosition,
    props?.mobileCroppingCropHeight,
    props?.mobileCroppingCropWidth,
    props?.mobileCroppingXPosition,
    props?.mobileCroppingXPositionAdvanced,
    props?.mobileCroppingYPosition,
    props?.mobileCroppingYPositionAdvanced,
  ];
  const showMobileCropping = !isTabletSize && mobileCropStyles.some((s) => s);

  // Aspect ratio classes are used unless a masthead height setting is applied
  let aspectRatio =
    !inlineStyles?.mastheadSpacer && props?.componentInstance === 0
      ? 'aspect-[9/11] sm:aspect-[16/12] md:aspect-[16/10] lg:aspect-[16/12] xl:aspect-[16/11] 2xl:aspect-auto 2xl:h-[90vh] 2xl:max-h-[1400px]'
      : 'aspect-square sm:aspect-video 2xl:aspect-auto 2xl:h-[75vh] 2xl:max-h-[1200px]';

  const mastheadHeightOptions = ['Tall', 'Short', 'Full'];

  if (mastheadHeightOptions.indexOf(inlineStyles?.mastheadHeight) !== -1) {
    switch (inlineStyles?.mastheadHeight) {
      case 'Tall':
        aspectRatio = 'h-[60vh] min-h-[450px] sm:h-[85vh] sm:max-h-[55vh] sm:max-h-[85vh]';
        break;
      case 'Short':
        aspectRatio = 'h-[60vh] min-h-[450px]';
        break;
      case 'Full':
        aspectRatio = 'h-[60vh] min-h-[450px] sm:h-screen';
        break;
    }
  }

  const imageChangeData = ImageChangeHandler({
    imageCarousel: props?.imageCarousel,
    showImageGallery,
  });

  const copyContent = (
    <OverlayCopy
      {...props}
      isFullWidthOverlay={isFullWidthOverlay}
      animations={animations}
      inlineStyles={inlineStyles}
      isTabletSize={isTabletSize}
      isLargeDesktopSize={isLargeDesktopSize}
    />
  );

  return (
    <div
      data-testid="hero"
      className={cx('cp-heroImage', paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundColor:
          inlineStyles?.mastheadSpacer && inlineStyles?.mastheadSpacerColour
            ? inlineStyles?.mastheadSpacerColour
            : undefined,
        paddingTop:
          inlineStyles?.mastheadSpacer && isLargeDesktopSize
            ? !headerHeight && props?.componentInstance === 0
              ? defaultPaddingSize
              : headerHeight
            : undefined,
        transition: 'padding-top .5s ease',
      }}
    >
      <div className={cx('relative', aspectRatio)}>
        {/* If Image Gallery exists, output gallery markup */}
        {props?.imageCarouselChoice && props?.imageCarousel && (
          <HeroImageSlider
            images={props?.imageCarousel}
            styleOptions={{
              imagePositioning: props?.imgPositioning || inlineStyles?.contentBackgroundPosition,
              sliderAnimations: inlineStyles?.sliderAnimations,
            }}
            componentInstance={props?.componentInstance}
            hideAnimations={hideAnimations}
            slideIndex={imageChangeData?.slideIndex}
            navAnimationDir={imageChangeData?.navAnimationDir}
            nextTimeout={imageChangeData?.nextTimeout}
            nextImage={imageChangeData?.nextImage}
            next={imageChangeData?.next}
            prev={imageChangeData?.prev}
          />
        )}
        {/* Output the image tag */}
        <ImageTag
          {...props}
          showMobileCropping={showMobileCropping}
          imgSrc={imgSrc}
          hideAnimations={hideAnimations}
          inlineStyles={inlineStyles}
        />
        {/* Output the markup for masthead gradient */}
        <div
          data-element-id="masthead-gradient"
          data-testid="mastheadGradientStyles"
          className={cx(
            'pointer-events-none absolute inset-0 z-20 h-1/2 opacity-80',
            mastheadGradientStyles
          )}
        />
        {/* Output the markup for the optional hero image logo */}
        {props?.logoSrc && props?.logoSrc !== '' && (
          <HeroImageLogo
            logoSrc={props?.logoSrc}
            logoAlt={props?.logoAlt}
            logoSize={props?.logoSize}
            logoWidth={props?.logoWidth}
            logoHeight={props?.logoHeight}
            logoOverlayColour={props?.logoOverlayColour}
            logoOverlayOpacity={props?.logoOverlayOpacity}
            hasSpacer={inlineStyles?.mastheadSpacer ? true : false}
            isShopFormInHeader={props?.isShopFormInHeader}
            hideAnimations={hideAnimations}
            inlineStyles={inlineStyles}
          />
        )}
        {/* Output the markup for the hero image overlay, which includes title, subtitle, and CTA markup */}
        <HeroImageOverlay
          hideAnimations={hideAnimations}
          videoAutoplay={props?.videoAutoplay}
          videoCtaPlay={props?.videoCtaPlay}
          videoCtaPause={props?.videoCtaPause}
          videoChoice={props?.videoChoice}
          videoActive={videoActive}
          captionTracks={props?.captionTracks}
          transcriptTracks={props?.transcriptTracks}
          audioTracks={props?.audioTracks}
          setVideoActive={setVideoActive}
          videoPlayerProps={videoPlayerProps}
          videoMuteChoice={props?.videoMuteChoice}
          videoUrl={props?.videoUrl}
          videoQualityOverride={props?.videoQualityOverride}
          videoQualitySlider={props?.videoQualitySlider}
          animations={animations}
          imageCarousel={props?.imageCarouselChoice && props?.imageCarousel}
          slideIndex={imageChangeData?.nextImage?.ind}
          copyContent={isTabletSize && copyContent}
          isFullWidthOverlay={isFullWidthOverlay}
          isTabletSize={isTabletSize && !isDesktopSize}
          next={imageChangeData?.next}
          prev={imageChangeData?.prev}
          inlineStyles={inlineStyles}
        />
      </div>
      {!isTabletSize && copyContent}
      {/* Output the markup for the booking widget */}
      {bookingWidgetChoice && (
        <div
          className={cx(
            bookingWidgetPosition === 'Overlapping' ? 'md:h-auto' : '',
            bookingWidgetPosition === 'Bottom' ? 'xl:h-0' : '',
            props?.bookingWidgetMobile ? 'flex' : 'hidden md:flex',
            'justify-center',
            {
              'animate-fade-in-up': !hideAnimations,
            }
          )}
        >
          <div
            data-element-id="osc-composable-hero-div-wrapper"
            data-testid="booking-widget-div-outer"
            className={cx(
              bookingWidgetPosition === 'Bottom' ? 'xl:-translate-y-full' : '',
              bookingWidgetPosition === 'Overlapping' && !isFullWidthOverlay
                ? '2xl:-translate-y-1/2 2xl:shadow-lg'
                : bookingWidgetPosition === 'Overlapping'
                ? 'xl:-translate-y-1/2 xl:shadow-lg'
                : '',
              props?.bookingWidgetMobile
                ? 'inline-block w-full md:w-auto'
                : 'hidden md:inline-block',
              'bg-bg relative z-20 p-3 lg:h-28 lg:p-0 lg:py-8',
              associatedHotels?.length > 0 ? 'lg:h-32 lg:py-5' : ''
            )}
          >
            <OscComposableSearchForm
              ctyhocn={props?.bookingWidgetConfig?.ctyhocn}
              defaultArrivalDate={props?.bookingWidgetConfig?.defaultArrivalDate}
              gmtHours={props?.bookingWidgetConfig?.gmtHours}
              brandCode={props?.bookingWidgetConfig?.brandCode}
              currency={props?.bookingWidgetConfig?.currency}
              associatedHotels={associatedHotels}
              oscBookButtonStyle={
                props?.bookingWidgetConfig?.brandCode === 'WA' ? '' : props?.oscBookButtonStyle
              }
              maxRooms={props?.bookingWidgetConfig?.maxRooms}
              groupBooking={props?.bookingWidgetConfig?.groupBooking}
            />
          </div>
        </div>
      )}
    </div>
  );
}
