import { type SVGAttributes } from 'react';

const PetsIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="currentColor">
        <path
          d="M29.648 8.457c.483-.462 1.151-.76 2.101-.76 2.821 0 2.894 3.124 4.438 3.574.761.222 2.162.628 2.573.628 0 0 .558-.065-.017.788 0 .314-.184 1.608-1.313 1.961-.835.728-.968.731-1.809.731-.84 0-1.473-.706-2.435-.706s-2.37 1.39-2.37 3.74c0 6.157-2.187 6.832-2.905 7.418-.718.586-1.588 3.734-1.588 5.941 0 2.091.228 4.137.776 4.501.978.421.675 1.424.071 1.424h-.91c-1.04 0-2.41-2.15-2.41-6.135 0-2.216.484-5.766.423-6.26-5.349 0-6.254-2.308-8.525-2.308-.265 5.23-5.316 5.521-5.316 7.777 0 5.476 2.136 4.415 2.136 5.986 0 .406-.425.94-1.09.94-1.443 0-1.959-.334-2.447-1.365-.74-1.565-1.249-4.224-1.249-5.708 0-2.392 1.415-1.814 1.396-4.04-.01-1.264-1.396-1.65-1.396-5.46 0-2.523.862-4.334.862-4.334s.217-1.037-.638-2.625c-.855-1.589-2.862-2.27-2.164-3.415.495-.812 2.93.613 3.843 1.918.7.997 1.483 2.824 1.483 2.824s.727-.053 1.59-.053c1.8 0 2.583.68 4.746.68 4.155 0 7.121-.539 8.873-1.63"
          strokeWidth="1.5"
        />
        <path
          d="M17.345 23.461c-.519 2.576-1.332 3.525-1.332 5.217 0 5.476 2.136 4.415 2.136 5.986 0 .406-.425.94-1.09.94-1.443 0-1.959-.334-2.447-1.365-.74-1.565-1.248-4.224-1.248-5.708 0-.34.028-.62.078-.858M30.608 21.262c-.036 3.904.4 6.35.61 8.668.202 2.25.373 3.538.983 4.236 1.134.392.654 1.442-.147 1.442-.7 0-2.027.322-3.174-5.744-.402-2.127-.757-3.416-.818-3.91"
          strokeLinejoin="round"
        />
        <path
          d="M29.518 8.565c-1.447 1.477-5.094 4.626-2.465 6.27 1.727 1.08 3.753-1.545 3.753-4.347"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default PetsIcon;
