import { useRef, useEffect, useMemo, useCallback } from 'react';
import Route from 'next/router';
import { getBrandDetails, sanitize } from '@curated-property/utils';
import type { WordpressPageInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/wp';
import type { PageProps } from '../../../../../../apps/dx-curated-ui/mapping/default-props-to-components';
import type { HotelFilterInput } from '@dx-ui/gql-types';
import type { DigitalData } from '@dx-ui/config-metrics';

/** declare this so that TS doesn't have a fit */
declare global {
  interface Window {
    digitalData?: DigitalData;
    _satellite?: {
      setDebug?: (debug: boolean) => void;
      track?: (event: string) => void;
      pageBottom?: () => void;
      getVisitorId?: () => { getMarketingCloudVisitorID: () => string };
    };
  }
}

export function adobeAnalyticsPropMapper(
  page: WordpressPageInfoQuery['page'],
  theme: NonNullable<PageProps>['wpThemeSettings'],
  dxGql: NonNullable<NonNullable<PageProps>['dxGqlInfo']>['hotel'] & HotelFilterInput,
  ctyhocn: NonNullable<PageProps>['ctyhocn'],
  lang: string,
  multiBrand: NonNullable<PageProps>['associatedHotelInfo']
) {
  const ddOverwriteText = page?.metaDataFields?.adobeDigitaldataOverwriteText
    ?.replace(/\s+/g, '-')
    ?.toLowerCase();
  const ddOverwriteList = page?.metaDataFields?.adobeDigitaldataOverwriteList
    ?.replace(/\s+/g, '-')
    ?.toLowerCase();

  return {
    lang,
    pageSlug: ddOverwriteText || ddOverwriteList || page?.slug,
    pageName:
      page?.metaDataFields?.adobeDigitaldataOverwriteText ||
      page?.metaDataFields?.adobeDigitaldataOverwriteList ||
      page?.title,
    ctyhocn,
    siteTitle: dxGql?.name,
    pageDetails1: page?.metaDataFields?.adobeDigitaldataPageDetail1 || '',
    pageDetails2: page?.metaDataFields?.adobeDigitaldataPageDetail2 || '',
    pageDetails3: page?.metaDataFields?.adobeDigitaldataPageDetail3 || '',
    overwriteBrand: page?.metaDataFields?.adobeDigitaldataOverwriteBrand || '',
    overwriteCtyhocn: page?.metaDataFields?.adobeDigitaldataOverwriteCtyhocn || '',
    isMultiBrand: multiBrand?.length > 0 ? true : false,
    adobeSettings: {
      destinationURL: theme?.propertyInfo?.propertyInfo?.liveUrl,
      country: dxGql?.address?.country,
      brandCode: dxGql?.brandCode,
      brandName: dxGql?.brand?.formalName,
      templateType: dxGql?.display?.templateType,
      siteType: 'B',
      previousPage: '',
    },
  };
}

let adobeDigitalData: Window['digitalData'];

interface Props {
  lang?: string;
  pageName?: string;
  pageSlug?: string;
  siteTitle?: string;
  ctyhocn: string;
  pageDetails1?: string;
  pageDetails2?: string;
  pageDetails3?: string;
  overwriteBrand?: string;
  overwriteCtyhocn?: string;
  isMultiBrand?: boolean;
  adobeSettings?: {
    destinationURL?: string;
    country?: string;
    brandCode?: string;
    brandName?: string;
    templateType?: string | null;
    siteType?: string;
    previousPage?: string;
  };
}

export function AdobeAnalytics({
  lang,
  pageSlug,
  pageName,
  siteTitle,
  pageDetails1,
  pageDetails2,
  pageDetails3,
  overwriteCtyhocn,
  overwriteBrand,
  adobeSettings,
  ctyhocn,
  isMultiBrand,
}: Props) {
  const buildVersion = process?.env?.APP_VER;
  // check for route change
  const currentRoute = Route?.router?.asPath;
  // if the brand and ctyhocn overrides exist, then we cant use multi-brand
  const brandCtyhocnOverrideExists = !!overwriteBrand && !!overwriteCtyhocn;
  const brandNameLogic =
    // Logic for multi-brand EG Resorts World
    isMultiBrand
      ? // ctyhocn override does not exist EG Resorts World overview pages
        !brandCtyhocnOverrideExists
        ? 'Multi Brand'
        : // ctyhocn override does exist EG Resorts World rooms pages
          overwriteBrand + ' Hotels & Resorts'
      : 'Hilton Hotels & Resorts';

  const brandLogic = adobeSettings?.brandCode === 'VH' ? 'MB' : adobeSettings?.brandCode;
  const brandDetails = getBrandDetails(overwriteBrand?.toLowerCase() ?? '');
  const brandCodeOverride = brandDetails?.brandCode;
  // Adobe Team specifically requested this change due to legacy reasons.
  // https://jira.hilton.com/browse/CURCOMP-517
  const pageNameOffersCheck = pageName === 'Property Offers' ? 'Offers' : pageName;
  const pageSlugOffersCheck = pageSlug === 'property-offers' ? 'offers' : pageSlug;

  const ddLang = lang || 'EN';
  const ddExp = 'Browser';
  const ddPrimaryCategory = 'Property';
  const ddExpType = 'New Hilton';
  const ddBrandName = adobeSettings?.brandName || brandNameLogic;
  const ddPageType = pageName || '';
  const ddPageDetail1 = pageDetails1;
  const pageDetailCheck = ddPageDetail1 ? ':' + ddPageDetail1 : '';
  const ddPageDetail2 = pageDetails2;
  const ddPageDetail3 = pageDetails3;
  const ddExpTheme = adobeSettings?.templateType || 'Complex';
  const ddProductID = overwriteCtyhocn || ctyhocn || '';
  const ddBrand = brandCodeOverride || brandLogic || 'HI';
  const ddDestinationURL = adobeSettings?.destinationURL || '';
  const ddSiteName = siteTitle || '';
  const ddSiteType = adobeSettings?.siteType || 'B';
  const ddSubSection = `${ddPrimaryCategory}:${pageSlugOffersCheck || ''}`;
  const ddVersion = `dx-curated-ui:${buildVersion || 1}`;
  const ddPropertySearchCountry = adobeSettings?.country;
  const ddPageName = `${ddExp}:${ddLang}:${ddBrandName}:${ddPrimaryCategory}:${
    pageNameOffersCheck || ''
  }${pageDetailCheck}`;

  const ddEvents = ['propertyPageView'];
  if (pageName === 'Rooms') {
    ddEvents.push('curatedRoomsPageView');
  }

  const digitalData: Window['digitalData'] = useMemo(() => {
    return {
      page: {
        pageInfo: {
          destinationURL: ddDestinationURL,
          language: ddLang,
          pageName: ddPageName,
          pageType: ddPageType,
          pageDetail1: ddPageDetail1,
          pageDetail2: ddPageDetail2,
          pageDetail3: ddPageDetail3,
        },
        category: {
          brand: ddBrand,
          exp: 'Browser',
          primaryCategory: ddPrimaryCategory,
          siteName: ddSiteName,
          siteType: ddSiteType,
          subSection: ddSubSection,
          subSubSection: '',
        },
        attributes: {
          contentTypes: 'images,text',
          siteExperience: 'R',
          propertySearchCountry: ddPropertySearchCountry,
          expType: ddExpType,
          expTheme: ddExpTheme,
          version: ddVersion,
          imageCarouselNumber: '',
        },
      },
      event: [],
      product: [
        {
          productInfo: {
            productID: ddProductID,
          },
        },
      ],
    };
  }, [
    ddBrand,
    ddDestinationURL,
    ddExpTheme,
    ddLang,
    ddPageDetail1,
    ddPageDetail2,
    ddPageDetail3,
    ddPageName,
    ddPageType,
    ddProductID,
    ddPropertySearchCountry,
    ddSiteName,
    ddSiteType,
    ddSubSection,
    ddVersion,
  ]);

  ddEvents.forEach((event) => {
    digitalData.event.push({
      eventInfo: {
        eventAction: event,
      },
    });
  });

  adobeDigitalData = digitalData;

  const adobeScriptOutput = `
    var currentURL = window.location.href;
    var digitalData = ${JSON.stringify(digitalData)}
  `;

  const loadAnalytics = useCallback(() => {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: sanitize(adobeScriptOutput),
        }}
      />
    );
  }, [adobeScriptOutput]);

  useEffect(() => {
    loadAnalytics();
    if (window._satellite) {
      window.digitalData = digitalData;
      setTimeout(() => {
        ddEvents.forEach((eventName) => {
          window._satellite.track(eventName);
        });
      }, 100);
    }
  }, [currentRoute, ddEvents, digitalData, loadAnalytics]);

  return loadAnalytics();
}

interface AdobeEventTrackingProps {
  componentType?: string;
  componentName?: string;
  ctaText?: string | null;
  sectionHeading?: string | null;
  carouselNumber?: string;
  actionDetailPageTitle?: boolean;
  // Expect more to be added to these later.
  actionDetail: 'Button' | 'Carousel' | 'Video';
  interactionName: 'propertyButton' | 'propertyGalleryCarousel' | 'propertyVideo';
}

export function adobeEventTracking(props: AdobeEventTrackingProps) {
  if (!adobeDigitalData) return false;
  const pageTitle =
    adobeDigitalData?.page?.pageInfo?.pageType && props?.actionDetailPageTitle
      ? ':' + adobeDigitalData?.page?.pageInfo?.pageType
      : '';
  const actionDetail = props?.actionDetail;
  const category = adobeDigitalData?.page?.category?.primaryCategory
    ? ':' + adobeDigitalData?.page?.category?.primaryCategory
    : '';
  const sectionHeading = props?.sectionHeading ? ':' + props?.sectionHeading : '';
  const ctaText = props?.ctaText ? ':' + props?.ctaText : '';
  // @ts-expect-error: actionDetail doesn't exist on page attributes - OPEN TO SUGGESTIONS TO FIX
  adobeDigitalData.page.attributes.actionDetail = `${actionDetail}${category}${pageTitle}${sectionHeading}${ctaText}`;
  adobeDigitalData.event[0].eventInfo.eventAction = props?.interactionName;
  if (props?.carouselNumber !== '' || props?.carouselNumber !== undefined) {
    // @ts-expect-error: imageCarouselNumber doesn't exist on page attributes - OPEN TO SUGGESTIONS TO FIX
    adobeDigitalData.page.attributes.imageCarouselNumber = props?.carouselNumber;
  }
  /* FOR TESTING
  console.log(adobeDigitalData.page.attributes.actionDetail);
  console.log(adobeDigitalData.event[0].eventInfo.eventAction);
  console.log(adobeDigitalData.page.attributes?.imageCarouselNumber);
  */
  window._satellite?.track(props?.interactionName);
}

interface AdobeCarouselEvent {
  actionDetailPageTitle?: boolean;
  sectionHeading?: string;
  currentIndex: number;
  totalCount: number | undefined;
  actionDetail?: AdobeEventTrackingProps['actionDetail'];
  interactionName?: AdobeEventTrackingProps['interactionName'];
}

export function useAdobeCarouselEvent(props: AdobeCarouselEvent) {
  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current === true) {
      firstUpdate.current = false;
    } else {
      adobeEventTracking({
        actionDetailPageTitle: props?.actionDetailPageTitle || true,
        carouselNumber: `${props?.currentIndex}:${props?.totalCount}`,
        sectionHeading: props?.sectionHeading,
        actionDetail: props?.actionDetail || 'Carousel',
        interactionName: props?.interactionName || 'propertyGalleryCarousel',
      });
    }
  }, [
    props?.actionDetail,
    props?.actionDetailPageTitle,
    props?.currentIndex,
    props?.interactionName,
    props?.sectionHeading,
    props?.totalCount,
  ]);
}
