const VALID_SAME_SITES = ['none', 'lax', 'strict'];

function getDefaultDomain() {
  return location.hostname === 'localhost' ? 'localhost' : '.hilton.com';
}

export const setCookie = (
  name: string,
  value: string,
  expires: number | null,
  path = '',
  domain = 'hilton.com',
  secure = true,
  escape = true,
  sameSite = 'lax'
) => {
  if (undefined === domain) {
    domain = getDefaultDomain();
  }

  if (sameSite !== 'lax' && sameSite !== null) {
    sameSite = sameSite.toLowerCase();

    if (!VALID_SAME_SITES.includes(sameSite)) {
      throw new TypeError(`sameSite must be one of the following: ${VALID_SAME_SITES}`);
    }
  }

  // set time, it's in milliseconds
  const today = new Date(Date.now());

  /*
  if the expires variable is set, make the correct
  expires time, the current script below will set
  it for x number of days, to make it for hours,
  delete * 24, for minutes, delete * 60 * 24
  */
  if (expires) {
    expires = expires * 1000 * 60 * 60 * 24;
  }
  const expires_date = new Date(today.getTime() + (expires ?? 0));

  const cookieValue = escape ? encodeURIComponent(value) : value;

  document.cookie =
    name +
    '=' +
    cookieValue +
    (expires ? ';expires=' + expires_date.toUTCString() : '') +
    (path ? ';path=' + path : '') +
    (domain ? ';domain=' + domain : '') +
    (secure ? ';secure' : '') +
    (sameSite ? ';sameSite=' + sameSite : '');
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c?.charAt(0) === ' ') {
      c = c?.substring(1);
    }
    if (c?.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return '';
};

export const deleteCookie = (name: string, path: string, domain?: string) => {
  if (undefined === domain) {
    domain = getDefaultDomain();
  }
  if (getCookie(name))
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01-Jan-1970 00:00:01 GMT';
};
