import { FilterFlyout, FilterCheckbox } from './filter-flyout';
import { useTranslation } from 'next-i18next';
import type { Filters } from '../buildFilters';
import type { CustomFilters, State } from '../room-types';

// Trying to modify the original State interface gives some errors
interface StateWithBrandName extends State {
  brandName?: string[];
}

interface FilterDisplayProps {
  filters: Filters;
  filterState: StateWithBrandName;
  dispatchFilterState: (stateArray) => void;
  handleSortState: (item) => void;
  sortState: string;
  hasPrices?: boolean;
  customFilters?: CustomFilters;
  labelColor?: string;
  hideFilters?: {
    beds?: boolean;
    maxGuests?: boolean;
    price?: boolean;
  };
}

export function FilterFlyoutDisplay({
  filters,
  filterState,
  dispatchFilterState,
  handleSortState,
  sortState,
  hasPrices,
  customFilters,
  labelColor,
  hideFilters,
}: FilterDisplayProps) {
  const [t] = useTranslation();

  return (
    <>
      {filters?.brandName && (
        <FilterFlyout
          label={t('brands')}
          items={filters.brandName}
          onChange={(item, checked) =>
            dispatchFilterState({
              key: 'brandName',
              checked,
              item,
            })
          }
          checkedItems={filterState?.brandName}
          labelButtonID="brands"
          labelColor={labelColor}
        />
      )}
      <FilterFlyout
        label={t('premiumOptions')}
        items={filters.premiumOptions}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'premiumOptions',
            checked,
            item,
          })
        }
        checkedItems={filterState.premiumOptions}
        labelButtonID="premiumOptions"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('outdoorFeatures')}
        items={filters.outdoorFeatures}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'outdoorFeatures',
            checked,
            item,
          })
        }
        checkedItems={filterState.outdoorFeatures}
        labelButtonID="outdoorFeatures"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('roomFeatures')}
        items={filters.roomFeatures}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'roomFeatures',
            checked,
            item,
          })
        }
        checkedItems={filterState.roomFeatures}
        labelButtonID="roomFeatures"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('roomType')}
        items={filters.roomType}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'roomType',
            checked,
            item,
          })
        }
        checkedItems={filterState.roomType}
        labelButtonID="roomType"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('bathroomAmenities')}
        items={filters.bathroomAmenities}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'bathroomAmenities',
            checked,
            item,
          })
        }
        checkedItems={filterState.bathroomAmenities}
        labelButtonID="bathroomAmenities"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('locations')}
        items={filters.locations}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'locations',
            checked,
            item,
          })
        }
        checkedItems={filterState.locations}
        labelButtonID="locations"
        labelColor={labelColor}
      />
      {!hideFilters?.maxGuests && (
        <FilterFlyout
          label={t('guestsLabel')}
          items={filters.guests}
          onChange={(item, checked) =>
            dispatchFilterState({
              key: 'guests',
              checked,
              item,
            })
          }
          checkedItems={filterState.guests}
          labelButtonID="guests"
          labelColor={labelColor}
        />
      )}
      {!hideFilters?.beds && (
        <FilterFlyout
          label={t('bedLabel')}
          items={filters.numBeds}
          onChange={(item, checked) =>
            dispatchFilterState({
              key: 'numBeds',
              checked,
              item,
            })
          }
          checkedItems={filterState.numBeds}
          labelButtonID="numBeds"
          labelColor={labelColor}
        />
      )}
      <FilterFlyout
        label={t('recommendedFor')}
        items={filters.recommendedFor}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'recommendedFor',
            checked,
            item,
          })
        }
        checkedItems={filterState.recommendedFor}
        labelButtonID="recommendedFor"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('view')}
        items={filters.view}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'view',
            checked,
            item,
          })
        }
        checkedItems={filterState.view}
        labelButtonID="view"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={t('balcony')}
        items={filters.balconyDetail}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'balconyDetail',
            checked,
            item,
          })
        }
        checkedItems={filterState.balconyDetail}
        labelButtonID="balconyDetail"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={customFilters?.SettingsRoomSettings?.roomFilters1Title || 'Custom Filter 1'}
        items={filters.customFilter1}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'customFilter1',
            checked,
            item,
          })
        }
        checkedItems={filterState.customFilter1}
        labelButtonID="customFilter1"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={customFilters?.SettingsRoomSettings?.roomFilters2Title || 'Custom Filter 2'}
        items={filters.customFilter2}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'customFilter2',
            checked,
            item,
          })
        }
        checkedItems={filterState.customFilter2}
        labelButtonID="customFilter2"
        labelColor={labelColor}
      />
      <FilterFlyout
        label={customFilters?.SettingsRoomSettings?.roomFilters3Title || 'Custom Filter 3'}
        items={filters.customFilter3}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'customFilter3',
            checked,
            item,
          })
        }
        checkedItems={filterState.customFilter3}
        labelButtonID="customFilter3"
        labelColor={labelColor}
      />
      {hasPrices && !hideFilters?.price ? (
        <FilterFlyout
          label={t('price')}
          items={filters.priceSort}
          onChange={handleSortState}
          checkedItems={sortState ? [sortState] : []}
          labelButtonID="priceSort"
          labelColor={labelColor}
        />
      ) : null}
      <FilterCheckbox
        label={t('accessibleLabel')}
        items={filters.adaAccessibleRoom}
        onChange={(item, checked) =>
          dispatchFilterState({
            key: 'adaAccessibleRoom',
            checked,
            item,
          })
        }
        checkedItems={filterState.adaAccessibleRoom}
        labelButtonID="adaAccessibleRoom"
        labelColor={labelColor}
      />
    </>
  );
}
