import classnames from 'classnames';
import { Caption } from '@dx-ui/osc-caption';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { logWarning } from '@dx-ui/framework-logger';
import { useAutoPlay } from './hooks/use-autoplay';
import type { VideoTrack } from './video-player.controls';
import { getTrackId, trackKind } from './util/track';

type VideoElement = React.ComponentProps<'video'>;

export type VideoProps = Omit<VideoElement, 'src' | 'autoPlay' | 'id' | 'poster'> & {
  /**
   * The location (URL) of the video file
   */
  videoUrl: NonNullable<VideoElement['src']>;
  /**
   * Ref that gets added to the video element.
   */
  videoElement: React.RefObject<HTMLVideoElement>;
  /**
   * The poster for the video
   */
  posterImageUrl?: VideoElement['poster'];
  videoId?: VideoElement['id'];
  /**
   * When present, the video will automatically start playing.
   */
  isAutoPlay?: VideoElement['autoPlay'];
  /**
   * Caption link and text
   */
  captionData?: CaptionProps;
  /**
   * Classname for the Video tag
   */
  videoClassName?: React.ComponentProps<'div'>['className'];
  /**
   * Classname for the Figure tag
   */
  figureClassName?: React.ComponentProps<'div'>['className'];
  /**
   * VTT tracks for subtitles/captions
   */
  captionTracks?: VideoTrack['captionTracks'];
  /**
   * VTT tracks for transcripts
   */
  transcriptTracks?: VideoTrack['transcriptTracks'];
};

/**
 * Provides the ability to play videos on a continuous loop with optional caption.
 */
export function Video({
  videoUrl,
  posterImageUrl,
  videoElement,
  videoId,
  captionData,
  isAutoPlay = true,
  muted = isAutoPlay,
  figureClassName,
  videoClassName,
  captionTracks,
  transcriptTracks,
  ...rest
}: VideoProps) {
  const preventPreload = posterImageUrl && !isAutoPlay;
  const autoPlay = useAutoPlay({ isAutoPlay });

  return (
    <figure className={figureClassName}>
      {videoUrl ? (
        <div className={classnames('relative w-full overflow-hidden', rest.className)}>
          <video
            id={videoId}
            data-testid="video-player"
            loop
            playsInline
            {...rest}
            className={classnames('w-full bg-bg-alt object-cover', videoClassName)}
            src={videoUrl}
            poster={posterImageUrl}
            autoPlay={autoPlay}
            muted={muted}
            ref={videoElement}
            crossOrigin="anonymous"
            preload={preventPreload ? 'none' : undefined}
            onError={(e) => {
              logWarning(
                'OSCVideoPlayer',
                new Error('video onError'),
                `Failed to load ${videoUrl}`
              );
              rest.onError?.(e);
            }}
          >
            {captionTracks?.map((track) => (
              <track
                ref={(node) => {
                  if (node?.track) {
                    node.track.mode = 'hidden';
                  }
                }}
                key={getTrackId(track)}
                id={getTrackId(track)}
                src={track.url}
                srcLang={track.language}
                label={track.label}
                kind={trackKind.captionTrack}
              />
            ))}
            {transcriptTracks?.map((track) => (
              <track
                ref={(node) => {
                  if (node?.track) {
                    node.track.mode = 'hidden';
                  }
                }}
                key={getTrackId(track)}
                id={getTrackId(track)}
                src={track.url}
                srcLang={track.language}
                label={track.label}
                kind={trackKind.transcriptTrack}
              />
            ))}
          </video>
        </div>
      ) : null}

      {captionData ? <Caption {...captionData} /> : null}
    </figure>
  );
}
