import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./rjkochi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 text-bg antialiased" fillColor="#426376" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-32 text-bg antialiased" fillColor="#426376" />
);
