import { type SVGAttributes } from 'react';

const KeyIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g strokeWidth="1.5" fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M22.094 21.757c-3.605-3.604-3.605-9.447 0-13.053a9.228 9.228 0 0 1 13.052 0c3.605 3.606 3.605 9.449 0 13.053-3.603 3.604-9.448 3.604-13.052 0zM16.668 31.112l1.824 1.824c.675.677.758 1.686.189 2.255l-3.103 3.105c-.57.57-1.58.484-2.256-.191L11.5 36.283" />
        <path d="M24.615 23.164L10.78 37.001a2.8 2.8 0 0 1-3.96-3.958l13.836-13.837M28.02 15.404a2.853 2.853 0 0 1 4.035-4.033 2.853 2.853 0 0 1-4.034 4.033z" />
      </g>
    </svg>
  );
};

export default KeyIcon;
