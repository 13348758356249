export function BurgerMenu({
  className,
  fillColor,
  height,
  width,
}: {
  className?: string;
  fillColor?: string;
  height?: string;
  width?: string;
}) {
  return (
    <svg
      style={{ fill: fillColor }}
      className={className}
      width={width || '28'}
      height={height || '16'}
    >
      <g data-name="burger-menu">
        <path d="M0 0h28v2H0V0zM0 7h28v2H0V9zM0 14h28v2H0v-3z" />
      </g>
    </svg>
  );
}
