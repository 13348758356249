import BrandTextConradLogo from './brand-text-conrad';
import BrandTextLXRLogo from './brand-text-lxr';
import BrandTextHiltonLogo from './brand-text-hilton';
import LXRLogo from './lxr-logo';
import TapestryLogo from './tapestry-logo';
import MakaiLogo from './makai-logo';
import CurioLogo from './curio-logo';
import ForbesFiveStarLogo from './forbes-five-star-logo';
import ForbesFiveStarLogoTwentyTwentyFour from './forbes-five-star-logo-twenty-twenty-four';
import ForbesFourStarLogo from './forbes-four-star-logo';
import ForbesLogoRec from './forbes-logo-rec';
import TravelLeisureAward from './travel-leisure-award';
import WeddingsLogo from './weddings-logo';
import HonorsLogo from './honors';
import VistaTelAvivLogo from './vista-tel-aviv-logo';

export {
  BrandTextConradLogo,
  BrandTextLXRLogo,
  BrandTextHiltonLogo,
  LXRLogo,
  TapestryLogo,
  MakaiLogo,
  CurioLogo,
  ForbesFiveStarLogo,
  ForbesFiveStarLogoTwentyTwentyFour,
  ForbesFourStarLogo,
  ForbesLogoRec,
  TravelLeisureAward,
  WeddingsLogo,
  HonorsLogo,
  VistaTelAvivLogo,
};
