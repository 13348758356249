/* eslint-disable no-console */
import { env } from '@dx-ui/framework-env';
import { dynatrace } from './logger';

const ERROR_PAGE = 418;

export const LOG_LEVELS = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARN: 'WARN',
} as const;

type LOG_KEYS = StringWithAutocompleteOptions<
  | 'APPLICATION_ERROR'
  | 'AUTHENTICATION_ERROR'
  | 'BOOKING_ERROR'
  | 'BOOKING_INFO'
  | 'EVENT_INFO'
  | 'EXTERNAL_ERROR'
  | 'FATAL_ERROR'
  | 'FETCH_ERROR'
  | 'ROUTING_ERROR'
  | 'VALIDATION_ERROR'
>;

export type LogArgs = Parameters<typeof log>;

export function log(
  key: LOG_KEYS,
  value: Parameters<typeof stringifyValue>[0],
  level: keyof typeof LOG_LEVELS,
  hint: string
) {
  if (dynatrace) {
    const errorKey = `${level}:${key}` as const;
    dynatrace.markAsErrorPage(ERROR_PAGE, `DX-UI Logger ${level}`);
    dynatrace.reportCustomError(errorKey, normalizeError(value), buildHint(hint), true);
    dynatrace.sendBeacon(true, true, true);

    if (!env('DISABLE_LOGGER_CONSOLE_ERROR') || env('DISABLE_LOGGER_CONSOLE_ERROR') === 'false') {
      const customError = new Error(normalizeError(value), { cause: hint });
      customError.name = errorKey;
      console.error(customError);
      return;
    }
  }
  const fn = level.toLowerCase() as Lowercase<typeof level>;
  console[fn](key, value, hint);
}

function buildHint(hint: string) {
  const version = env('APP_VER');
  return [version, hint].filter(Boolean).join('|');
}

type ErrorValue = string | Error | Record<string, unknown>;

function stringifyValue(value: ErrorValue) {
  if (typeof value === 'string') {
    return value;
  }
  if (value instanceof Error) {
    return value.message;
  }
  return JSON.stringify(value);
}

function normalizeError(error: ErrorValue) {
  const errorString = stringifyValue(error);
  const firstLeftArrow = errorString.indexOf('<');
  return (firstLeftArrow > 0 ? errorString.slice(0, firstLeftArrow) : errorString).trim();
}
