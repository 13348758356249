import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./sancnqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-36 fill-current text-bg antialiased md:w-40  lg:w-48" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-32 fill-current text-bg antialiased sm:w-40 lg:w-40" />
);
