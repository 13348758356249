export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/ryk3nmy.css" />
      <style>
        {`
          :root {
            --font-headline: 'freight-big-pro', serif !important;
            --font-button: 'canada-type-gibson', sans-serif !important;
            --font-sans: canada-type-gibson, sans-serif !important;
          }
          div,p,ul,ul li, blockquote,em {
            font-weight:300 !important;
          }
          
          h1,h2,h3,
          .font-headline,.font-black {
            font-family: freight-big-pro, serif !important;
            font-weight:300 !important;
          }

          .btn,  button,.font-semibold, header.cp-header, header.cp-header ul a , h3,h4,h5 {
            font-family: canada-type-gibson, sans-serif !important; 
            font-weight: 500 !important;
            font-style: normal;
          }
        `}
      </style>
    </>
  );
};
