import { type SVGAttributes } from 'react';

export function ChevronLeft(props: SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M7 1L1 8l6 7"
      />
    </svg>
  );
}
