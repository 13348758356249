import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksFourImageInterface } from './interfaces';

export function MediaBlocksFourImage(props: MediaBlocksFourImageInterface) {
  return (
    <div className="grid size-full grid-cols-2" data-testid="media-blocks-four-image">
      <div className="grid-row-2 grid bg-green-500">
        <div
          className="relative flex items-center justify-center overflow-hidden bg-red-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderRightWidth: `${props?.imageInsetBorderWidth}px`,
            borderBottomWidth: `${props?.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageTopLeft?.sourceUrl}
            alt={props?.imageTopLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageTopLeft?.sourceUrl,
                crop: props?.imageTopLeftCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
        <div
          className="relative flex items-center justify-center overflow-hidden bg-yellow-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderRightWidth: `${props?.imageInsetBorderWidth}px`,
            borderTopWidth: `${props?.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageBottomLeft?.sourceUrl}
            alt={props?.imageBottomLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomLeft?.sourceUrl,
                crop: props?.imageBottomLeftCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
      </div>
      <div className="grid-row-2 grid bg-green-500">
        <div
          className="relative flex items-center justify-center overflow-hidden bg-red-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderLeftWidth: `${props?.imageInsetBorderWidth}px`,
            borderBottomWidth: `${props?.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageTopRight?.sourceUrl}
            alt={props?.imageTopRight?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageTopRight?.sourceUrl,
                crop: props?.imageTopRightCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageTopRightCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
        <div
          className="relative flex items-center justify-center overflow-hidden bg-yellow-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderLeftWidth: `${props?.imageInsetBorderWidth}px`,
            borderTopWidth: `${props?.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageBottomRight?.sourceUrl}
            alt={props?.imageBottomRight?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomRight?.sourceUrl,
                crop: props?.imageBottomRightCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
}
