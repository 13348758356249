import type * as React from 'react';

/**
 * @deprecated
 * The `<ShopFormInfoContainer/>` can be used for layout purposes, originally for use as a wrapper around `<ShopFormRooms/>` and `<ShopFormSpecialRates/>`
 */
const ShopFormInfoContainer = ({ children }: { children: React.ReactNode }) => children;

ShopFormInfoContainer.displayName = 'ShopFormInfoContainer';

export { ShopFormInfoContainer };
export default ShopFormInfoContainer;
