import * as React from 'react';
import { useInitConductrics } from '@dx-ui/framework-conductrics';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { SharedContext } from './shared-context';

export const useInitializeConductrics = () => {
  const isConductricsInit = React.useRef(false);
  const { guestInfo, isLoading, isAuthenticated } = useAuth();
  const initConductrics = useInitConductrics();
  const { dxGql, hasVisitedWiFi } = React.useContext(SharedContext);

  React.useEffect(() => {
    if (!isLoading && !isConductricsInit.current) {
      isConductricsInit.current = true;

      const traits = [
        `login:${isAuthenticated ? 'yes' : 'no'}`,
        `Brand:${dxGql?.hotel?.brandCode}`,
        `wifi-visited-curated:${hasVisitedWiFi}`,
      ];
      const { hhonors } = guestInfo || {};

      if (hhonors?.summary?.tier) {
        traits.push(`tier:${hhonors.summary.tier.toLowerCase()}`);
      }

      const teamMember =
        hhonors?.isOwner ||
        hhonors?.isTeamMember ||
        hhonors?.isOwnerHGV ||
        hhonors?.isLongTenure10 ||
        hhonors?.isLongTenure20;

      traits.push(`teamMember:${teamMember ? 'yes' : 'no'}`);
      initConductrics({ traits });
    }
  }, [
    isAuthenticated,
    isLoading,
    guestInfo,
    initConductrics,
    dxGql?.hotel?.brandCode,
    hasVisitedWiFi,
  ]);
};
