import { GraphError, ServerAuthError } from '@dx-ui/types-graphql';

/**
 *
 * @returns a new Error object based on the input argument
 */
export function generateError(arg: unknown) {
  const UNKNOWN_ERROR_MSG = 'An unknown error occurred.';
  if (arg instanceof ServerAuthError || arg instanceof Error || arg instanceof GraphError) {
    return arg;
  }
  if (typeof arg === 'string') {
    return new Error(arg);
  }
  return new Error(UNKNOWN_ERROR_MSG);
}
