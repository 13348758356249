import * as Tabs from '@radix-ui/react-tabs';

export interface TabProps {
  index?: number;
  title?: string;
  textColor?: string;
  handleTabsChange?: any;
  activeTabAccentColour?: string;
  activeTabTextColour?: string;
  tabTypeButtonStyle?: string;
  indexShift?: number;
  isClicked?: number;
}

export function MenuTab({
  index,
  title,
  textColor,
  activeTabAccentColour,
  activeTabTextColour,
  tabTypeButtonStyle,
  isClicked,
}: TabProps) {
  const activeTabText = isClicked === index ? activeTabTextColour : null;
  const activeTabAccent = isClicked === index ? activeTabAccentColour : null;
  const tabStyle = `btn-${tabTypeButtonStyle}`;
  const htmlMenuButton = `btn-html-menu-${isClicked === index ? tabTypeButtonStyle : ''}-selected`;

  return tabTypeButtonStyle ? (
    <Tabs.Trigger
      value={index?.toString()}
      style={{ border: 'initial', padding: 'initial', margin: 'initial' }}
      className="!m-1.5"
    >
      <div className={`btn ${tabStyle} ${htmlMenuButton} overflow-none flex items-center`}>
        {title}
      </div>
    </Tabs.Trigger>
  ) : (
    <Tabs.Trigger
      value={index?.toString()}
      className="cp-htmlMenu-Tab relative w-full px-5 py-7 text-xl sm:w-auto"
      data-testid="tab-button"
      style={{
        color: activeTabText || textColor,
      }}
    >
      {title}
      {isClicked === index && (
        <div
          className="bg-primary absolute -right-0 top-full h-1 w-full"
          style={{ background: activeTabAccent }}
        />
      )}
    </Tabs.Trigger>
  );
}
