export function NoPets({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A8.3479,8.3479,0,0,0,6.0908,6.0715l-.0137.0056-.0055.0136A8.3705,8.3705,0,0,0,17.9092,17.9285l.0137-.0056.0055-.0136A8.3705,8.3705,0,0,0,12,3.625Zm5.642,13.4858-3.269-3.269a.3756.3756,0,1,0-.5312.5312l3.269,3.269A7.6117,7.6117,0,0,1,6.358,6.8892l3.2143,3.2143a.3756.3756,0,1,0,.5312-.5312L6.8892,6.358A7.6117,7.6117,0,0,1,17.642,17.1108Z" />
          <path d="M17.08,8.3535l-1.3194-.0967a2.09,2.09,0,0,0-1.5517-.6152,1.4988,1.4988,0,0,0-1.5586.709,3.1631,3.1631,0,0,0-.43,1.9453.3771.3771,0,0,0-.3262-.1191A12.3858,12.3858,0,0,1,7.65,10.1124a1.64,1.64,0,0,1-.27-1.4.375.375,0,0,0-.707-.25,2.3991,2.3991,0,0,0,.3392,2.0426,2.58,2.58,0,0,0-.1928,1.1234v4.0879a.3753.3753,0,0,0,.0537.1934.9467.9467,0,0,0,.7764.374c.0537.0068.5674.0635.751-.72l.4541-1.9649a2.0706,2.0706,0,0,1,.7139.2862,2.3783,2.3783,0,0,0,2.31.3593l.3448,1.7344a.3737.3737,0,0,0,.0722.1572.8349.8349,0,0,0,.6709.23.6955.6955,0,0,0,.1133.01.6078.6078,0,0,0,.3535-.11.8431.8431,0,0,0,.3076-.72l-.0713-1.914A3.9068,3.9068,0,0,0,14.69,12.624a5.799,5.799,0,0,0,.419-1.4472,5.7091,5.7091,0,0,0,1.5283-.4327c.4678-.2812.51-.5048.666-1.3339l.1172-.6094a.3756.3756,0,0,0-.3408-.4473Zm-3.7344.2891a.4538.4538,0,0,1,.1924-.1817c.1826.5079.4707,1.5479-.1006,1.8262-.1016.0215-.3555.04-.4414-.0674C12.9541,10.167,12.7725,9.8486,13.3457,8.6426Zm.7061,3.5879a3.4582,3.4582,0,0,1-.9629.8857.3771.3771,0,0,0-.1778.333l.0791,2.0987c-.0009.0273-.0029.0488-.0048.0673l-.07-.0029-.4-2.0137a.3774.3774,0,0,0-.2314-.2763.3858.3858,0,0,0-.3574.0459,1.582,1.582,0,0,1-1.8867-.0674A3.446,3.446,0,0,0,8.623,12.79a.3749.3749,0,0,0-.4179.2871L7.67,15.3936a.7531.7531,0,0,1-.0449.14.49.49,0,0,1-.0557-.01V11.6279a2.1923,2.1923,0,0,1,.09-.74,13.4127,13.4127,0,0,0,4.3349.0332.3756.3756,0,0,0,.3252-.3682.9585.9585,0,0,0,.0977.1436,1.1086,1.1086,0,0,0,1.0157.342,2.6418,2.6418,0,0,0,.7919.6615A2.5767,2.5767,0,0,1,14.0518,12.2305Zm2.5146-2.959c-.1357.7217-.1357.7217-.3144.8291a6.521,6.521,0,0,1-1.5206.3779.3756.3756,0,0,0-.3125.3067c-.0075.0432-.0176.09-.0259.1346a1.886,1.886,0,0,1-.2831-.2523,2.3714,2.3714,0,0,0,.2046-2.2691,1.2258,1.2258,0,0,1,.9238.4014.378.378,0,0,0,.3008.1924l1.0654.0781Z" />
        </g>
      </g>
    </svg>
  );
}
