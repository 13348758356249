export function CheckCircle({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
          <path d="M15.7256,8.7441,10.4,14.45l-2.126-2.2773a.3752.3752,0,1,0-.5488.5117l2.4,2.5713a.3757.3757,0,0,0,.5488,0l5.6-6a.3752.3752,0,1,0-.5488-.5118Z" />
        </g>
      </g>
    </svg>
  );
}
