import * as React from 'react';
import type { Dispatch, SetStateAction } from 'react';

type TriggerRef = React.MutableRefObject<HTMLButtonElement | HTMLInputElement | null>;
type PopoverRef = React.MutableRefObject<HTMLSpanElement | null>;

type PopupContext = {
  triggerRef: TriggerRef;
  triggerClickedRef: React.MutableRefObject<boolean>;
  popoverRef: PopoverRef;
  popupId?: string;
  isOpen?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closePopup: () => void;
};

const PopupContext = React.createContext<PopupContext>({} as PopupContext);

export const usePopup = () => {
  const context = React.useContext(PopupContext);
  if (context === undefined) {
    throw new Error('usePopup must be used within a Popup Component parent');
  }
  return context;
};

export { PopupContext };
export default PopupContext;
