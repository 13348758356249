/**
 * WALDORF ASTORIA PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  headerClassName: 'font-headline',
  navStyles: 'font-headline text-sm',
  textColorOnLoad: '#000000',
  bgColorOnLoad: 'rgba(255, 255, 255, 1)',
  textColorOnActive: '#000000',
  bgColorOnActive: 'rgba(255, 255, 255, 1)',
  bgSubmenuColor: 'rgba(255, 255, 255, 0.97)',
  separatorColor: '#000000',
  separatorClasses: undefined,
  fontWeight: 'normal',
  menuItems: {
    colorOnHover: undefined,
    paddingClasses: undefined,
    case: 'uppercase',
    underline: {
      active: true,
      color: '#000000',
      size: 'h-0.5',
    },
    itemActive: {
      classes: undefined,
      bgColor: undefined,
      bgText: undefined,
    },
  },
  iconMenu: {
    extraClasses: 'uppercase',
    fontSize: 'text-xs',
    color: undefined,
    iconColor: undefined,
    activeColor: undefined,
    activeIconColor: undefined,
    separatorColor: '#000000',
    underline: true,
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: undefined,
    extraClasses: 'rounded-none text-sm px-3 py-3 xl:px-7 xl:py-4',
    backgroundColor: '#000000',
    backgroundColorHover: '#9AB9AD',
    textColor: '#ffffff',
    textColorHover: '#000000',
    textCase: 'uppercase',
  },
};

export const brandBreadcrumbProps = {
  activeTextColour: '#000000',
  backgroundColour: '#FFFFFF',
  textColour: '#000000',
};

export const brandFooterProps = {
  brandPhoneNumber: '+1-800-WALDORF',
  backgroundColour: '#032165',
  textColour: '#FFFFFF',
  copyrightBackgroundColour: '#FFFFFF',
  copyrightTextColour: '#000000',
};
