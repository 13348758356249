import { Link } from '@dx-ui/osc-link';
import { getHotelAddressLink, type GetHotelAddressLinkData } from './hotel-address.utils';

type HotelAddressProps = (
  | {
      addressStacked: string;
      singleLineAddress?: never;
    }
  | {
      addressStacked?: never;
      singleLineAddress: string;
    }
) &
  (
    | {
        hasLink?: never;
        linkProps?: never;
      }
    | ({
        hasLink: boolean;
        linkProps?: Omit<React.ComponentProps<typeof Link>, 'url'> & { url?: string };
      } & GetHotelAddressLinkData)
  );

export function HotelAddress({
  addressStacked,
  hasLink,
  linkProps,
  singleLineAddress,
  ...getHotelAddressLinkProps
}: HotelAddressProps) {
  const splitAddress = addressStacked?.split('\n') || [];
  const formattedAddress =
    singleLineAddress || `${splitAddress[0]}\n${splitAddress.slice(1).join(' ')}`;

  if (hasLink) {
    const hotelAddressLink = getHotelAddressLink(
      getHotelAddressLinkProps as GetHotelAddressLinkData
    );
    return (
      <Link
        className="whitespace-pre-line text-text hover:text-text hover:no-underline"
        {...linkProps}
        url={linkProps?.url || hotelAddressLink}
        isNewWindow
      >
        {formattedAddress}
      </Link>
    );
  }

  return <span className="whitespace-pre-line">{formattedAddress}</span>;
}

export default HotelAddress;
