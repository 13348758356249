export function Contact({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16,7.6H7.9746a.375.375,0,1,0,0,.75H16a.375.375,0,0,0,0-.75Z" />
          <path d="M16,9.625H8a.375.375,0,0,0,0,.75h8a.375.375,0,0,0,0-.75Z" />
          <path d="M7.9746,12.375H12a.375.375,0,0,0,0-.75H7.9746a.375.375,0,1,0,0,.75Z" />
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7v6A2.3777,2.3777,0,0,0,7,15.375h.625V19a.3769.3769,0,0,0,.2314.3467A.389.389,0,0,0,8,19.375a.3778.3778,0,0,0,.2656-.1094l4-4a.3756.3756,0,0,0-.5312-.5312l-3.3594,3.36V15A.3751.3751,0,0,0,8,14.625H7A1.6262,1.6262,0,0,1,5.375,13V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7v6A1.6262,1.6262,0,0,1,17,14.625H14a.375.375,0,0,0,0,.75h3A2.3777,2.3777,0,0,0,19.375,13V7A2.3777,2.3777,0,0,0,17,4.625Z" />
        </g>
      </g>
    </svg>
  );
}
