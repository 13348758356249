export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/lee8lvk.css" />
      <style>{`
          :root {
              --font-headline: 'Collier';
              --font-sans:'stevie-sans';
              --font-button:'stevie-sans';
          }
            
          [data-element-id='booking-widget-day-button'],
          [data-osc-product='shop-form-dates'] > span > span,
          h1,h2,h3,h4,h5,.font-black,.font-headline {
              font-family: Collier !important;
              font-weight: 300 !important;
          }
            
          .btn, button,
           div,p, a, li, h4:not(.font-headline), 
           [data-reach-tab], .btn, [data-element-id="subtitle-wrapper"], 
           [data-element-id="nav-desktop"],
           ul[data-element-id="nav-desktop"] > li > div > button { 
                font-family: stevie-sans,sans-serif !important; 
                font-weight:300 !important;
            }

        `}</style>
    </>
  );
};
