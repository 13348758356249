const TapestryLogo = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="140"
      viewBox="0 0 121.7 42.5"
    >
      <g>
        <g>
          <g>
            <path
              fill="#322173"
              d="M10.08.46V2a.29.29 0 01-.28.28H.28A.28.28 0 010 2V.46A.27.27 0 01.28.19H9.8a.28.28 0 01.28.27zM121.67.32a.27.27 0 00-.25-.13h-1.91a.28.28 0 00-.23.13l-3 5.18-3-5.18a.27.27 0 00-.28-.13h-1.9a.27.27 0 00-.25.13.28.28 0 000 .29l4.23 7.15v5a.28.28 0 00.28.27h1.7a.28.28 0 00.28-.27v-5L121.65.6a.28.28 0 00.02-.28zM57.6 10.93h-5.8V7.54h4.65a.29.29 0 00.28-.28V5.72a.28.28 0 00-.28-.27H51.8V2.26h5.56a.28.28 0 00.27-.26V.46a.27.27 0 00-.27-.27h-7.54a.27.27 0 00-.28.27v12.27a.27.27 0 00.28.27h7.78a.27.27 0 00.27-.27v-1.52a.28.28 0 00-.27-.28zm12-5.34c-1.85-.86-2.41-1.47-2.41-2.27S67.75 2 68.92 2a5.48 5.48 0 012.62 1 .42.42 0 00.55-.2l.68-1a.43.43 0 000-.3.32.32 0 00-.14-.21A6.56 6.56 0 0068.92 0c-2.24 0-4 1.25-4 3.32s1.11 3 3.29 4.07c1.63.78 2.55 1.39 2.55 2.32s-.53 1.5-1.93 1.5a5.4 5.4 0 01-3-1.13h-.05a.38.38 0 00-.29-.07.36.36 0 00-.23.17l-.67 1.15c-.14.25-.08.3.08.47a6.41 6.41 0 004.14 1.42c2.66 0 4.26-1.46 4.26-3.48s-1.14-3.09-3.44-4.15zM6 3.86H4.13a.22.22 0 00-.23.22v8.65a.27.27 0 00.28.27H5.9a.28.28 0 00.28-.27V4.08A.22.22 0 006 3.86zm78.71 0h-1.88a.22.22 0 00-.22.22v8.65a.27.27 0 00.27.27h1.73a.27.27 0 00.27-.27V4.08a.22.22 0 00-.22-.22zm20 8.72a.32.32 0 010 .3.28.28 0 01-.25.12h-2a.22.22 0 01-.22-.12L99.79 8h-2.2v4.8a.28.28 0 01-.28.27h-1.7a.28.28 0 01-.28-.27V.46a.28.28 0 01.28-.27h5.06a3.74 3.74 0 014 3.9A3.92 3.92 0 01102 7.67zm-2.42-8.5a1.83 1.83 0 00-2-1.87h-2.68V6h2.62a1.88 1.88 0 002.03-1.92zm-59.93.13A4 4 0 0138 8.3h-2.35v4.43a.29.29 0 01-.28.27h-1.71a.27.27 0 01-.27-.27V.46a.27.27 0 01.27-.27H38c2.58 0 4.33 1.62 4.33 4.02zm-2.24 0a2 2 0 00-2.22-2h-2.25V6.3h2.22a2.07 2.07 0 002.22-2.11zm-13.75 8.42a.27.27 0 010 .26.29.29 0 01-.24.11h-1.61a.44.44 0 01-.47-.32l-.93-2h-5.64l-.93 2a.48.48 0 01-.46.32h-1.62a.29.29 0 01-.24-.11.27.27 0 010-.26L20 .16a.25.25 0 01.24-.16h.18a.25.25 0 01.25.16zm-4.09-3.9l-1.9-4.19h-.15l-1.91 4.19zM88.51.19H79a.27.27 0 00-.27.27V2a.28.28 0 00.27.28h9.53a.28.28 0 00.25-.28V.46a.27.27 0 00-.27-.27z"
            />
            <path
              fill="#84388d"
              d="M70.41 26.66a.26.26 0 010 .35 4.2 4.2 0 01-3 1.09 4.4 4.4 0 01-4.55-4.53A4.42 4.42 0 0167.33 19a4.36 4.36 0 013 1.15.25.25 0 010 .36l-.71.84a.19.19 0 01-.3 0 3.13 3.13 0 00-2-.77 2.8 2.8 0 00-2.81 2.94 2.8 2.8 0 002.83 2.92 3.38 3.38 0 002.07-.71.21.21 0 01.3 0zm-27.51-.2h-3.69v-7.08a.25.25 0 00-.24-.24h-1.16a.24.24 0 00-.24.24v8.35a.24.24 0 00.24.24h5.09a.24.24 0 00.24-.24v-1a.24.24 0 00-.24-.27zm8.32 0h-3.7v-7.08a.24.24 0 00-.24-.24h-1.16a.24.24 0 00-.24.24v8.35a.24.24 0 00.24.24h5.1a.24.24 0 00.24-.24v-1a.24.24 0 00-.24-.27zm8.34-7.32h-5.25a.24.24 0 00-.24.24v8.35a.24.24 0 00.24.24h5.25a.24.24 0 00.24-.24v-1a.24.24 0 00-.24-.24h-3.85v-2.24h3.23a.24.24 0 00.24-.24V23a.25.25 0 00-.24-.24h-3.23v-2.1h3.85a.24.24 0 00.24-.24v-1a.24.24 0 00-.24-.28zM21.9 25.76a.23.23 0 00-.31 0 3.36 3.36 0 01-2.07.71 2.8 2.8 0 01-2.83-2.92 2.8 2.8 0 012.82-2.94 3.1 3.1 0 012 .77.2.2 0 00.31 0l.7-.84a.25.25 0 000-.36 4.34 4.34 0 00-3-1.15 4.43 4.43 0 00-4.56 4.56 4.41 4.41 0 004.56 4.53 4.23 4.23 0 003-1.09.26.26 0 000-.35zm84.64-6.62h-1.16a.24.24 0 00-.24.24v5.21L99.88 19h-.31a.24.24 0 00-.24.23v8.49a.24.24 0 00.24.24h1.14a.24.24 0 00.24-.24v-5.41l5.27 5.79h.3a.23.23 0 00.24-.23v-8.49a.24.24 0 00-.22-.24zm-23.26 0h-1.17a.24.24 0 00-.24.24v8.35a.24.24 0 00.24.24h1.17a.24.24 0 00.24-.24v-8.35a.24.24 0 00-.24-.24zm-4.31 0H72.5a.24.24 0 00-.24.24v1a.24.24 0 00.24.24h2.41v7.07a.24.24 0 00.24.24h1.17a.24.24 0 00.24-.24v-7.03H79a.24.24 0 00.24-.24v-1a.24.24 0 00-.24-.28zm-44.85 4.43A4.54 4.54 0 1129.58 19a4.52 4.52 0 014.54 4.57zm-1.64 0a2.9 2.9 0 10-2.9 2.89 2.92 2.92 0 002.9-2.89zm63.49 0A4.54 4.54 0 1191.42 19 4.52 4.52 0 0196 23.57zm-1.64 0a2.9 2.9 0 10-5.8 0 2.9 2.9 0 005.8 0z"
            />
            <path
              fill="#322173"
              d="M42.27 35.34a2.34 2.34 0 00-1.54.65v-2.71h-1.67v.31l.42.06v6.22l-.42.06v.31h1.67V40a2.09 2.09 0 001.2.35c1.46 0 2.29-.94 2.29-2.58 0-1.46-.82-2.43-1.95-2.43zm-.48 4.56a1.71 1.71 0 01-1.06-.41V36.4a2 2 0 01.85-.24c.82 0 1.31.71 1.31 1.82s-.41 1.92-1.1 1.92zM48 35.43h1.39v.34l-.39.07-1.9 4.69c-.44 1.08-.84 2-1.75 2a3.23 3.23 0 01-.81-.09v-1.09h.27l.19.42a.6.6 0 00.4.13c.57 0 1-.86 1.19-1.43v-.12l-2-4.5-.38-.08v-.34h2.14v.35l-.42.08 1.26 3 1.18-3-.37-.09zm8-1.94h2.28v.34l-.46.06v5.94l.46.06v.35H56v-.35l.49-.06v-2.71h-2.93v2.71l.45.06v.35h-2.24v-.35l.45-.06v-5.94l-.45-.06v-.34H54v.34l-.45.06v2.54h2.92v-2.54l-.47-.06zm3.64.46a.73.73 0 111.45 0 .74.74 0 01-.73.79.7.7 0 01-.73-.74zm1.3 5.92l.43.06v.31h-2.1v-.31l.43-.06V35.8l-.43-.06v-.31h1.67zm3 0l.43.06v.31h-2.1v-.31l.42-.06v-6.22l-.42-.06v-.31h1.67zm3.8-.16a2.85 2.85 0 00.67-.05V40a4.6 4.6 0 01-1.55.31c-1 0-1.19-.44-1.19-1.28v-3.15h-.88v-.45h.91v-1l1.22-.37v1.37h1.13v.45h-1.13v2.86c0 .74.15.97.82.97zm3.45-4.41a2.42 2.42 0 00-2.58 2.58 2.19 2.19 0 002.5 2.5 2.38 2.38 0 002.58-2.5 2.32 2.32 0 00-2.5-2.58zm0 4.71c-.71 0-1.16-.63-1.16-2.16s.45-2.18 1.16-2.18 1.18.66 1.18 2.18S71.9 40 71.14 40zm8.1-.14l.43.06v.31h-2.1v-.31l.42-.06v-2.74c0-.75-.27-1.09-.85-1.09a2.07 2.07 0 00-1 .4v3.43l.43.06v.31h-2.15v-.31l.43-.06v-4l-.43-.06v-.34h1.68v.67a2.67 2.67 0 011.73-.76c.94 0 1.36.55 1.36 1.64zm.41-6.41H81v.29h-.11v-.15h-.36v1.18h.16v.1H80v-.1h.16V33.6h-.36v.15h-.12zm3.12.13v1.19h.13v.1h-.58v-.1h.13v-1.06l-.53 1.19-.51-1.17v1h.13v.1h-.41v-.1h.13v-1.15h-.13v-.1h.51l.4.94.42-.94H83v.1z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default TapestryLogo;
