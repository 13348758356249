import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./snaahhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-30 h-24 fill-bg antialiased md:mr-5 md:w-32" />
);

export const hotelLogoFooter = <HotelLogo className="h-24 w-36 fill-bg antialiased md:w-32" />;
