export function FacebookIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="26px"
      height="26px"
      viewBox="0 0 26 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Facebook icon"
    >
      <g stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
        <g id="facebook" fill="inherit" fillRule="nonzero">
          <path d="M13,0 C5.82029826,0 0,5.82029826 0,13 C0,20.1797017 5.82029826,26 13,26 C20.1797017,26 26,20.1797017 26,13 C26,5.82029826 20.1797017,0 13,0 Z M13,2 C19.0751322,2 24,6.92486776 24,13 C24,19.0751323 19.0751323,24 13,24 C6.92486776,24 2,19.0751322 2,13 C2,6.92486776 6.92486776,2 13,2 Z" />
          <path d="M13.9897378,12.4992388 L15.7647698,12.4992388 L16,10.2590403 L13.9897378,10.2590403 L13.9927537,9.13779968 C13.9927537,8.55352147 14.0476657,8.24045182 14.8777563,8.24045182 L15.987434,8.24045182 L15.987434,6 L14.212151,6 C12.0797504,6 11.3292006,7.08673722 11.3292006,8.9142873 L11.3292006,10.2592941 L10,10.2592941 L10,12.4994925 L11.3292006,12.4994925 L11.3292006,19 L13.9897378,19 L13.9897378,12.4992388 Z" />
        </g>
      </g>
    </svg>
  );
}
