import cx from 'classnames';

export function ControlList(props: Omit<React.ComponentProps<'ul'>, 'role'>) {
  const { children, ...listProps } = props;

  return (
    <ul
      {...listProps}
      role="menu"
      className={cx(
        'motion-safe:animate-fadein divide-border flex w-full flex-col divide-y',
        listProps.className
      )}
    >
      {children}
    </ul>
  );
}
