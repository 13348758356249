export function Tennis({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18.917,5.4258l-.3535-.3545A4.9621,4.9621,0,0,0,13.41,3.8965a.3755.3755,0,0,0,.2421.7109,4.2157,4.2157,0,0,1,4.38.9932l.3535.3545a4.2613,4.2613,0,0,1,0,6.0146l-1.5543,1.5561-.0013.0006a4.8518,4.8518,0,0,1-2.6759,1.3679,4.2709,4.2709,0,0,1-.5048.0491,4.2192,4.2192,0,0,1-3.0039-1.2393l-.3535-.3555a4.1571,4.1571,0,0,1-.7715-1.1045.3748.3748,0,0,0-.335-.207H8.4961a.3753.3753,0,0,0-.374.3438,3.7336,3.7336,0,0,1-.9414,2.5253L3.7344,18.3555a.3755.3755,0,0,0,0,.5293l1.3789,1.38a.3722.3722,0,0,0,.2656.11.3759.3759,0,0,0,.2656-.11l3.4463-3.4492c.8574-.8584,1.9971-.9209,3.3174-.9931a14.5423,14.5423,0,0,0,1.903-.1933,4.9329,4.9329,0,0,0,2.8734-1.3956L18.917,12.499A5.0106,5.0106,0,0,0,18.917,5.4258ZM5.3789,19.47,4.53,18.62l2.916-2.9189.8486.85Zm3.4667-3.4289-.8986-.8994a4.4287,4.4287,0,0,0,.8889-2.3543H8.959a4.7081,4.7081,0,0,0,.8017,1.0918l.3536.3545a4.9546,4.9546,0,0,0,1.289.907A4.6178,4.6178,0,0,0,8.8456,16.0408Z" />
          <path d="M6.3362,7.0781a2.81,2.81,0,0,0,5.6208-.0322L11.9554,7.03l-.0019-.0187a2.81,2.81,0,0,0-5.62.0349l.0018.0179Zm4.8617.0579a2.05,2.05,0,0,1-3.8314.9363,1.3566,1.3566,0,0,0,.196.0527,1.4125,1.4125,0,0,0,.2314.0186A2.3707,2.3707,0,0,0,9.41,7.3105a1.6314,1.6314,0,0,1,1.1923-.6044A.9479.9479,0,0,1,11.1979,7.136ZM9.1445,4.9824A2.0553,2.0553,0,0,1,10.923,6.0193a1.359,1.359,0,0,0-.1955-.0525,2.1639,2.1639,0,0,0-1.8486.8144,1.6226,1.6226,0,0,1-1.1914.6045.9523.9523,0,0,1-.5955-.4294A2.0605,2.0605,0,0,1,9.1445,4.9824Z" />
        </g>
      </g>
    </svg>
  );
}
