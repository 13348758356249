import { isBrowser } from '@dx-ui/utilities-is-browser';
import { logError } from '@dx-ui/framework-logger';

declare global {
  interface Window {
    conductricsSels?: string;
    conductricsClient?: ClientApi | null;
    Conductrics?: {
      ClientApi: {
        new (args?: ConstructorParams): ClientApi;
      };
    };
    conductricsSelsPath?: string;
  }
}

export type AgentStatus = ValuesOf<typeof Status>;

export const Status = {
  OK: 'ok',
  PENDING: 'p',
} as const;

export type Arg = {
  g?: string;
  v?: number;
  a?: string;
  s?: AgentStatus;
};

type ClientApi = {
  exec: (args: Arg[], cb: (err: Error | false, result?: Result | null) => void) => void;
};

export type ConstructorParams = {
  traits?: string[];
  dataLayer?: string;
  inputs?: { [key: string]: string | number | boolean };
};

export type Selection = {
  choice: string | undefined;
  status?: AgentStatus;
  meta?: {
    [key: string]: number | string | boolean;
  };
};

/**
 * @see https://support.conductrics.com/v3.0/docs/runtime-api-commands
 * @description myconductrics
 */
export type Result = {
  sels?: {
    [key: string]: string;
  };
  items?: {
    /**
     * @description name of agent
     */
    a?: string;
    /**
     * @description choice the agent made
     */
    c?: string;
    /**
     * @description meta data on the choice
     */
    md?: {
      [key: string]: number | string | boolean;
    };
    /**
     * @description policy the selected agent was made with
     * p for paused selections (if the agent does not currently have status "running")
     * r for random selections (random agents always make random selections)
     * f for fixed selections (the result of a targeted selection rule that you've set up)
     * a for adaptive selections (when an agent selects the "best" selection using predictive analytics)
     * c for control selections (adaptive agents set aside a small percentage of visitors as a control group, who always get the default variation)
     * s for sticky selections (if a session has already been assigned a selection previously)
     * b for bot selections (if a bot or search crawler is detected on our side, the default selection is returned but the request is not counted in reporting).
     */
    p?: string;
    /**
     * @name status
     * @description "ok" or "p" for pending
     */
    s?: AgentStatus;
  }[];
};

export type Agent = { agentId: string; status?: AgentStatus };

export function isApiLoaded() {
  if (!isBrowser) {
    return null;
  }
  return !!window?.Conductrics;
}

export function initConductrics(params?: ConstructorParams) {
  if (!isBrowser) {
    return null;
  }
  const conductricsClient =
    (window?.Conductrics && new window.Conductrics.ClientApi(params)) || null;
  window.conductricsClient = conductricsClient;
  return null;
}

function getApi() {
  if (!isBrowser) {
    return null;
  }
  return window.conductricsClient;
}

/**
 * Add selections to window object for external tracking
 * adds test1Name=choice;
 */
export function globalizeSelections(choice?: string, agent?: string) {
  const parts = window.conductricsSels?.split(';').filter(Boolean) || [];

  // remove duplicates
  const filteredParts = parts.filter((part) => part.split('=')?.[0] !== agent);

  filteredParts.push(`${agent}=${choice}`);
  window.conductricsSels = filteredParts.join(';');
}

/**
 * "Normalize" the passed in agents to match the conductrics API
 */
function normalizeAgents(agent: string | Agent[], s?: AgentStatus) {
  return typeof agent === 'string'
    ? [{ a: agent, ...(!!s && { s }) }]
    : agent.map(({ agentId, status }) => ({
        a: agentId,
        ...(!!status && { s: status }),
      }));
}

export function getConductricsSelection(
  agent: string | Agent[],
  status?: AgentStatus
): Promise<Result | null | undefined> {
  const conductricsApi = getApi();
  if (!conductricsApi) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    const agents = normalizeAgents(agent, status);
    conductricsApi?.exec(agents, (error, result) => {
      if (error) {
        reject(error);
      }
      resolve(result);
    });
  });
}

export function setConductricsSelectionOK(agent: string | Agent[]) {
  return getConductricsSelection(agent, Status.OK);
}

export function sendRewardAsync(reward: string, value?: number): Promise<void> {
  const conductricsApi = getApi();
  if (!conductricsApi) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    conductricsApi?.exec([{ g: reward, v: value }], (error) => {
      if (error) {
        logError('sendRewardError', error.message, `Reward ID: ${reward}`);
        reject(error);
        return;
      }
      resolve();
    });
  });
}

export function sendReward(reward: string, value?: number) {
  const conductricsApi = getApi();
  if (!conductricsApi) {
    return;
  }

  conductricsApi?.exec([{ g: reward, v: value }], () => null);
}
