import cx from 'classnames';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array } from '../functions/global-instance-styles';
import { GetHeaderHeight, HasPropertyAlert, useWindowSize } from '../functions/helper';
import { inlineBackgroundStyles } from '@curated-property/utils';
import { Spacer as OscSpacer } from '@dx-ui/osc-spacer';

type Height =
  | `${number}px`
  | `${number}%`
  | `${number}em`
  | `${number}rem`
  | `${number}vh`
  | `${number}vw`
  | 'auto'
  | 'fit-content'
  | 'inherit'
  | 'initial'
  | 'unset';

export interface SpacerProps {
  componentIndex?: number;
  componentHeight?: string;
  group?: {
    fieldGroupName?: string;
    large1024px?: string;
    medium768px?: string;
    small640px?: string;
    extraLarge1280px?: string;
    twoXExtraLarge1440px?: string;
  };
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  spacerComponentSettings?: StyleObject;
}

export function Spacer({
  componentIndex,
  componentHeight,
  group,
  globalStyles,
  instanceStyles,
}: SpacerProps) {
  const headerHeight = GetHeaderHeight();
  const hasPropertyAlert = HasPropertyAlert();
  const dynamicHeaderHeights =
    componentIndex === 0 && (headerHeight ? headerHeight : hasPropertyAlert ? '164px' : '128px');
  const defaultHeight = componentHeight || dynamicHeaderHeights || '50px';
  const wWidth = useWindowSize().width ?? 0;
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  let setHeight = defaultHeight;

  switch (true) {
    case wWidth >= 640 && wWidth < 768:
      setHeight = group?.small640px || defaultHeight;
      break;
    case wWidth >= 768 && wWidth < 1024:
      setHeight = group?.medium768px || defaultHeight;
      break;
    case wWidth >= 1024 && wWidth < 1280:
      setHeight = group?.large1024px || defaultHeight;
      break;
    case wWidth >= 1280 && wWidth < 1440:
      setHeight = group?.extraLarge1280px || defaultHeight;
      break;
    case wWidth >= 1440:
      setHeight = group?.twoXExtraLarge1440px || defaultHeight;
      break;
    default:
      setHeight = defaultHeight;
  }

  // Collapse spacer at mobile breakpoints when it's the first component on a page.
  setHeight = wWidth < 1280 && componentIndex === 0 ? '0px' : setHeight;

  if (!inlineStyles.componentBackgroundRepeat) {
    inlineStyles.componentBackgroundRepeat = 'no-repeat';
  }
  if (!inlineStyles.componentBackgroundPosition) {
    inlineStyles.componentBackgroundPosition = 'left-top';
  }

  return (
    <OscSpacer
      wrapperClassName={cx('clear-both w-full', inlineStyles?.showHide && 'hidden')}
      height={setHeight as Height}
      styles={{
        ...inlineBackgroundStyles(inlineStyles, 'component'),
      }}
    />
  );
}
