export function Pool({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10,11.375A.3751.3751,0,0,0,10.375,11V9.375h3.25V11a.375.375,0,0,0,.75,0V4a.5907.5907,0,0,1,.6279-.625A.375.375,0,0,0,15,2.625,1.3364,1.3364,0,0,0,13.625,4V5.625h-3.25V4a.5907.5907,0,0,1,.6279-.625A.375.375,0,0,0,11,2.625,1.3364,1.3364,0,0,0,9.625,4v7A.3751.3751,0,0,0,10,11.375Zm.375-5h3.25v2.25h-3.25Z" />
          <path d="M6.7754,13.2A1.7439,1.7439,0,0,1,6,13.625a.375.375,0,0,0,0,.75A2.1458,2.1458,0,0,0,7.2246,13.8,1.7439,1.7439,0,0,1,8,13.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,10,14.375,2.1458,2.1458,0,0,0,11.2246,13.8,1.7439,1.7439,0,0,1,12,13.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,14,14.375,2.1458,2.1458,0,0,0,15.2246,13.8,1.7439,1.7439,0,0,1,16,13.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,18,14.375a.375.375,0,0,0,0-.75,1.7439,1.7439,0,0,1-.7754-.4248A2.1458,2.1458,0,0,0,16,12.625a2.1458,2.1458,0,0,0-1.2246.5752A1.7439,1.7439,0,0,1,14,13.625a1.7439,1.7439,0,0,1-.7754-.4248A2.1458,2.1458,0,0,0,12,12.625a2.1458,2.1458,0,0,0-1.2246.5752A1.7439,1.7439,0,0,1,10,13.625,1.7439,1.7439,0,0,1,9.2246,13.2,2.1458,2.1458,0,0,0,8,12.625,2.1458,2.1458,0,0,0,6.7754,13.2Z" />
          <path d="M18,16.625a1.7439,1.7439,0,0,1-.7754-.4248A2.1458,2.1458,0,0,0,16,15.625a2.1458,2.1458,0,0,0-1.2246.5752A1.7439,1.7439,0,0,1,14,16.625a1.7439,1.7439,0,0,1-.7754-.4248A2.1458,2.1458,0,0,0,12,15.625a2.1458,2.1458,0,0,0-1.2246.5752A1.7439,1.7439,0,0,1,10,16.625,1.7439,1.7439,0,0,1,9.2246,16.2,2.1458,2.1458,0,0,0,8,15.625,2.1458,2.1458,0,0,0,6.7754,16.2,1.7439,1.7439,0,0,1,6,16.625a.375.375,0,0,0,0,.75A2.1458,2.1458,0,0,0,7.2246,16.8,1.7439,1.7439,0,0,1,8,16.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,10,17.375,2.1458,2.1458,0,0,0,11.2246,16.8,1.7439,1.7439,0,0,1,12,16.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,14,17.375,2.1458,2.1458,0,0,0,15.2246,16.8,1.7439,1.7439,0,0,1,16,16.375a1.7439,1.7439,0,0,1,.7754.4248A2.1458,2.1458,0,0,0,18,17.375a.375.375,0,0,0,0-.75Z" />
          <path d="M16,7.625a.375.375,0,0,0,0,.75A4.6308,4.6308,0,0,1,20.625,13v3A4.6308,4.6308,0,0,1,16,20.625H8A4.6308,4.6308,0,0,1,3.375,16V13A4.6308,4.6308,0,0,1,8,8.375a.375.375,0,0,0,0-.75A5.3809,5.3809,0,0,0,2.625,13v3A5.3809,5.3809,0,0,0,8,21.375h8A5.3809,5.3809,0,0,0,21.375,16V13A5.3809,5.3809,0,0,0,16,7.625Z" />
        </g>
      </g>
    </svg>
  );
}
