import gql from 'graphql-tag-transform.macro';
import { buttonClone } from './common-vars';

export const TwoOneMediaCopy = gql`
fragment TwoOneMediaCopy on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy {
        fieldGroupName
        subtitle
        subtitle_noTx: subtitle
        title
        title_noTx: title
        flipLargeImageHorizontally
        copy
        largeImage {
          altText
          sourceUrl
        }
        smallImage {
          altText
          sourceUrl
        }
        additionalSmallImage {
          altText
          sourceUrl
        }
        imageLargeCropHeight
        imageLargeCropWidth
        imageLargeEnableCropping
        imageLargeCropType
        imageLargeXPosition
        imageLargeYPosition
        imageLargeXPositionAdvanced
        imageLargeYPositionAdvanced
        imageSmallCropHeight
        imageSmallCropWidth
        imageSmallEnableCropping
        imageSmallCropType
        imageSmallXPosition
        imageSmallYPosition
        imageSmallXPositionAdvanced
        imageSmallYPositionAdvanced
        ${buttonClone}
        twoOneMediaCopySettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
          horizontalDirection
          verticalDirection
          smallImageBorderColour
          smallImageBorderWidth
          imageInsetBorderColour
        }
      }
}
`;
