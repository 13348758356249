import { type SVGAttributes } from 'react';

const SpaIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path d="M31.615 28.49a9.375 9.375 0 0 1-18.75 0c0-5.178 9.375-20.625 9.375-20.625s9.375 15.447 9.375 20.625z" />
        <path d="M18.58 24.122c-.874 2.05-1.385 3.617-1.385 4.37a5.045 5.045 0 0 0 5.046 5.045" />
      </g>
    </svg>
  );
};

export default SpaIcon;
