/**
 * CURIO BRAND PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  separatorColor: 'rgba(var(--color-white))',
  textColorOnLoad: 'rgba(9, 25, 78, 1)',
  bgColorOnLoad: 'rgba(var(--color-white))',
  textColorOnActive: 'rgba(9, 25, 78, 1)',
  bgColorOnActive: 'rgba(var(--color-white))',
  bgSubmenuColor: 'rgba(var(--color-white))',
  seperatorClasses: 'px-8',
  menuItems: {
    colorOnHover: undefined,
    paddingClasses: undefined,
    case: undefined,
    underline: {
      active: true,
      color: 'rgba(9, 25, 78, 1)',
    },
    itemActive: {
      bgColor: undefined,
      bgText: 'rgba(9, 25, 78, 1)',
      classes: 'text-secondary',
    },
  },
  iconMenu: {
    extraClasses: '!px-2',
    fontSize: undefined,
    separatorColor: undefined,
    underline: true,
    color: 'rgba(9, 25, 78, 1)',
    activeColor: 'rgba(9, 25, 78, 1)',
    iconColor: 'rgba(9, 25, 78, 1)',
    activeIconColor: 'rgba(9, 25, 78, 1)',
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: 'btn-primary bg-primary',
    extraClasses: undefined,
    backgroundColor: undefined,
    backgroundColorHover: undefined,
    textColor: 'rgba(var(--color-white))',
    textColorHover: undefined,
    textCase: undefined,
  },
};

export const brandFooterProps = {
  brandPhoneNumber: '+1-800-222-TREE',
};
