import BridalRegistryIcon from './BridalRegistryIcon';
import ChampagneIcon from './ChampagneIcon';
import CombinedHeartsIcon from './CombinedHeartsIcon';
import CoupleRoomUpgradeIcon from './CoupleRoomUpgradeIcon';
import CoupleTurndownIcon from './CoupleTurndownIcon';
import DinnerIcon from './DinnerIcon';
import FloralBanquetIcon from './FloralBouquetIcon';
import InRoomBreakfastIcon from './InRoomBreakfastIcon';
import WeddingCakeIcon from './WeddingCakeIcon';
import WeddingGiftIcon from './WeddingGiftIcon';

export interface WeddingIconProps {
  className?: string;
  fillColor?: string;
}

export {
  BridalRegistryIcon,
  ChampagneIcon,
  CombinedHeartsIcon,
  CoupleRoomUpgradeIcon,
  CoupleTurndownIcon,
  DinnerIcon,
  FloralBanquetIcon,
  InRoomBreakfastIcon,
  WeddingCakeIcon,
  WeddingGiftIcon,
};
