import { useRef } from 'react';
import type { QueryClient } from '@tanstack/react-query';
import { isServer } from '@tanstack/react-query';
import type { AuthClient, MakeAuthClientArgs } from '@dx-ui/framework-auth-provider';
import { makeAuthClient } from '@dx-ui/framework-auth-provider';
import type { MakeQueryClientArgs } from './make-query-client';
import { makeQueryClient } from './make-query-client';
import type { MakeDefaultFnOptionalArgs } from './default-fetch-fn';

export type PagePropsUseClients = Pick<
  MakeAuthClientArgs,
  'appId' | 'appName' | 'authEndpoints' | 'gqlEndpoints'
> & {
  authClientState?: MakeAuthClientArgs['restore'];
} & Omit<MakeQueryClientArgs, 'authClient'> &
  MakeDefaultFnOptionalArgs &
  ({ routerLocale: string; language?: string } | { language: string; routerLocale?: never });

export function useClients(pageProps: PagePropsUseClients) {
  const { appId, appName, authEndpoints, gqlEndpoints, authClientState, ...queryClientProps } =
    pageProps;
  const queryClientRef = useRef<QueryClient | null>(null);
  const authClientRef = useRef<AuthClient | null>(null);
  if (!authClientRef.current) {
    authClientRef.current = makeAuthClient({
      appId,
      appName,
      authEndpoints,
      gqlEndpoints,
      restore: authClientState,
      ssrMode: isServer,
      language: queryClientProps.routerLocale || queryClientProps.language || 'en',
    });
  }
  if (!queryClientRef.current) {
    queryClientRef.current = makeQueryClient({
      ...queryClientProps,
      appName,
      authClient: authClientRef.current,
      ssrMode: isServer,
    });
  }
  return {
    queryClient: queryClientRef.current,
    authClient: authClientRef.current,
  };
}
