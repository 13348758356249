import cx from 'classnames';
import { useContext } from 'react';
import Route from 'next/router';
import {
  PlayControl,
  PauseControl,
  AudioOn,
  AudioMuted,
  ClosedCaptions,
  ClosedCaptionsSolid,
} from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import { SettingsMenu } from './video-settings-menu';
import type { HeroImageOverlayProps } from '../hero-image.types';
import { adobeEventTracking } from '../../global/adobe-analytics';
import { SharedContext } from '@curated-property/utils';

export function HeroVideo({
  videoUrl,
  videoQualityOverride,
  videoQualitySlider,
  videoActive,
  videoAutoplay,
  hideAnimations,
  videoPlayerProps,
}: Pick<
  HeroImageOverlayProps,
  | 'videoUrl'
  | 'videoQualityOverride'
  | 'videoQualitySlider'
  | 'videoActive'
  | 'videoAutoplay'
  | 'hideAnimations'
  | 'videoPlayerProps'
>) {
  const currentRoute: string = Route?.router?.asPath;
  if (videoUrl) {
    [
      'https://assets.hiltonstatic.com/video/upload/',
      'https://assets.hiltonstatic.com/hilton-asset-cache/video/upload/',
    ].every((cloudinaryBaseUrl) => {
      if (videoUrl.startsWith(cloudinaryBaseUrl)) {
        let videoQuality: string;
        if (videoQualityOverride && videoQualitySlider !== null) {
          videoQuality = 'q_' + videoQualitySlider;
        } else {
          videoQuality = 'q_auto:low';
        }
        videoUrl =
          cloudinaryBaseUrl + videoQuality + ',f_auto/' + videoUrl.replace(cloudinaryBaseUrl, '');
        return false;
      }
      return true;
    });
  }

  return (
    <div>
      <video
        data-testid="mastheadVideo"
        id={videoPlayerProps?.videoId}
        key={currentRoute}
        ref={videoPlayerProps?.videoProps?.videoElement}
        className={cx('pointer-events-none absolute inset-0 size-full object-cover', {
          'animate-fade-in-slow-delay': !hideAnimations,
          'z-[16]': videoActive,
        })}
        width="100%"
        height="100%"
        autoPlay={videoAutoplay}
        crossOrigin="anonymous"
        muted
        loop
        playsInline
      >
        <source
          src={videoPlayerProps?.videoControlsProps?.activeVideo?.videoUrl}
          type="video/mp4"
        />
      </video>
    </div>
  );
}

export function HeroVideoControls({
  inlineStyles,
  videoMuteChoice,
  videoPlayerProps,
  videoCtaPause,
  videoCtaPlay,
  setVideoActive,
  captionTracks,
  transcriptTracks,
  audioTracks,
}: Pick<
  HeroImageOverlayProps,
  | 'inlineStyles'
  | 'videoMuteChoice'
  | 'videoPlayerProps'
  | 'videoCtaPause'
  | 'videoCtaPlay'
  | 'setVideoActive'
  | 'captionTracks'
  | 'transcriptTracks'
  | 'audioTracks'
>) {
  const { t } = useTranslation();
  const showCaptions = captionTracks?.length ? true : false;
  const showSettings =
    transcriptTracks?.length || audioTracks?.length || showCaptions ? true : false;
  const isMuted: boolean = videoPlayerProps?.videoControlsProps?.playerState?.isMuted;
  const isPlaying: boolean = videoPlayerProps?.videoControlsProps?.playerState?.isPlaying;
  const isShowingCaptions = videoPlayerProps?.videoControlsProps?.playerState?.showCaptions;

  const { enableHHR } = useContext(SharedContext);
  const heroVideoControlBackgroundDefault = enableHHR ? '' : '#F2F2F2';

  return (
    <div
      className={cx(
        'cp-heroImage-videoCta pointer-events-auto relative ml-2 flex h-12 items-center justify-center text-base text-text',
        inlineStyles?.videoCtaShadow || 'shadow-xl',
        inlineStyles?.videoCtaRoundedCorners || 'rounded-sm'
      )}
      style={{
        backgroundColor: inlineStyles?.videoCtaBackgroundColor ?? heroVideoControlBackgroundDefault,
      }}
    >
      <button
        data-testid={isPlaying ? 'pauseVideo' : 'playVideo'}
        aria-live="polite"
        aria-label={isPlaying ? videoCtaPause : videoCtaPlay}
        className="flex size-full items-center justify-center px-[18px]"
        style={{ borderColor: inlineStyles?.videoCtaBorderColor }}
        onClick={() => {
          setVideoActive(true);
          videoPlayerProps?.videoControlsProps?.togglePlayButton();
          adobeEventTracking({
            ctaText: !isPlaying ? videoCtaPlay : videoCtaPause,
            sectionHeading: '',
            actionDetail: 'Video',
            interactionName: 'propertyVideo',
          });
        }}
      >
        {isPlaying ? (
          <PauseControl
            fill={inlineStyles?.videoCtaPlaypauseColor}
            className="mx-0.5 mr-px pr-px"
          />
        ) : (
          <PlayControl fill={inlineStyles?.videoCtaPlaypauseColor} className="pl-px" />
        )}
      </button>
      {!videoMuteChoice && (
        <button
          data-testid={isMuted ? 'unmute' : 'mute'}
          aria-live="polite"
          aria-label={isMuted ? t('unmute') : t('mute')}
          className="flex size-full items-center justify-center border-l-2 px-3.5"
          onClick={() => {
            videoPlayerProps?.videoControlsProps?.toggleMuteButton();
          }}
        >
          {isMuted ? (
            <AudioMuted fill={inlineStyles?.videoCtaMuteColor} />
          ) : (
            <AudioOn fill={inlineStyles?.videoCtaUnmuteColor} />
          )}
        </button>
      )}
      {showCaptions && (
        <button
          data-testid={isShowingCaptions ? 'hideCaptions' : 'showCaptions'}
          aria-live="polite"
          aria-label={isShowingCaptions ? t('hideCaptions') : t('showCaptions')}
          className="flex size-full items-center justify-center border-l-2 px-3.5"
          onClick={() => {
            const currentTrack =
              videoPlayerProps?.videoControlsProps?.playerState.activeCaptionTrack;
            const firstTrack =
              videoPlayerProps?.videoControlsProps?.activeVideo?.captionTracks?.[0];
            currentTrack
              ? videoPlayerProps?.videoControlsProps?.toggleCaptions()
              : videoPlayerProps?.videoControlsProps?.updateCaption(firstTrack);
          }}
        >
          {isShowingCaptions ? (
            <ClosedCaptionsSolid fillColor={inlineStyles?.videoCtaUnmuteColor} className="h-7" />
          ) : (
            <ClosedCaptions fillColor={inlineStyles?.videoCtaUnmuteColor} className="h-7" />
          )}
        </button>
      )}
      {showSettings && (
        <SettingsMenu
          videoControlsProps={videoPlayerProps?.videoControlsProps}
          videoCtaUnmuteColor={inlineStyles?.videoCtaUnmuteColor}
        />
      )}
    </div>
  );
}
