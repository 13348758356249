/**
 * Type of default actions supported by metrics
 *
 * @module ActionTypes
 * @internal
 */
const ActionTypes = {
  PAGE_VIEW: 'pageView', // request page view track
  TRACK: 'track', // request custom link track
};

export default ActionTypes;
