import gql from 'graphql-tag-transform.macro';
import { MediaBlocksFrag, clonedCropSettings, mediaAndCopyOverlaySettings } from './common-vars';

export const MediaAndCopyOverlay = gql`
fragment MediaAndCopyOverlay on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay {
        title
        flipImageHorizontally
        title_noTx: title
        layoutMode
        copy
        fieldGroupName
        image {
          mediaDetails {
            height
            width
          }
          sourceUrl
          altText
        }
        mediaBlock {
          ... on Media_block {
            id
            ${MediaBlocksFrag}
          }
        }
        ${clonedCropSettings}
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        mediaAndCopyOverlayComponentSettings {
          fieldGroupName
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          subtitleColor
          textAlignment
          textColor
          titleColor
          mediaAndCopyPadding
          horizontalDirection
          zoomOnHover
          hideAnimations
          animationDirection
          hideParallax
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          containerMaxWidth
          ${mediaAndCopyOverlaySettings}
        }
      }
}
`;
