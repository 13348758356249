export function ArrowLeftLong({ className, fillColor }: { className?: string; fillColor: string }) {
  return (
    <svg className={className} style={{ fill: fillColor }} viewBox="0 0 216.78 148.29">
      <g data-name="arrow-left-long">
        <path
          d="M210.78 68.22H19.69L78.18 9.74a5.71 5.71 0 00-8.08-8.07L1.67 70.11a5.71 5.71 0 000 8.07l68.43 68.44a5.71 5.71 0 008.08-8.08L19.85 80.22h190.93a6 6 0 000-12z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}
