import { useContext } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { format, getYear } from 'date-fns';
import * as locales from 'date-fns/locale';
import { formatHourDisplay, compareTimes } from './event-calendar-helpers';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { EventCalendarDailyEventsLineItem } from './event-calendar-daily-events-line-item';
import { months } from './lib/event-calendar-constants';
import type { EventCalendarRegularEventProps } from './lib/event-calendar-props';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array } from '../functions/global-instance-styles';

interface DailyEventProps {
  recurringEvents?: Array<EventCalendarRegularEventProps>;
  monthToDisplay?: Date;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}
export const EventCalendarDailyEvents = ({
  recurringEvents,
  monthToDisplay,
  instanceStyles,
  globalStyles,
}: DailyEventProps) => {
  const locale = locales?.enUS;
  const { t } = useTranslation();

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  const hotelInfoContext = useContext(EventCalendarInfoContext);

  // Since the daily events bar can't display a specific day's calendar links, the 1st day of each month is used.
  const dateStr = `${getYear(monthToDisplay)} ${format(monthToDisplay, 'MMM', {
    locale,
  })} 01`;

  if (recurringEvents?.length) {
    // Sort the daily events in order of start time, with all-day events appearing first
    const dailyEventsSorted = [];
    const partialDayRecurringEvents: Array<EventCalendarRegularEventProps> = [];

    recurringEvents?.forEach((i) => {
      const r = i?.node?.RegularlyScheduledEvents?.allDays;
      r?.allDay === true && r?.heldDaily === true && dailyEventsSorted.push(i);
      r?.startTime !== null && r?.allDay !== true && partialDayRecurringEvents.push(i);
    });

    if (partialDayRecurringEvents.length) {
      const partialDayTitleIdAndStartTime = [];

      partialDayRecurringEvents?.forEach((e) => {
        // Since regStart is already in a 24-hour time format, no time conversion is needed
        const obj = {
          title: e?.node?.databaseId,
          databaseId: e?.node?.databaseId,
          start: e?.node?.RegularlyScheduledEvents?.allDays?.startTime,
        };
        partialDayTitleIdAndStartTime.push(obj);
      });

      partialDayTitleIdAndStartTime.length > 1 && partialDayTitleIdAndStartTime.sort(compareTimes);

      // Build new array
      partialDayTitleIdAndStartTime?.forEach((e) => {
        const itemToMatch = partialDayRecurringEvents?.find(
          ({ node }) => node?.databaseId === e?.databaseId
        );
        dailyEventsSorted.push(itemToMatch);
      });
    }

    let background = '';

    if (!hotelInfoContext?.enableHHR) {
      background = inlineStyles?.eventsCalDailyEventsSectionBgColor || '#e9e9e9';
    }

    return (
      <section>
        <div className="my-2 border-y border-neutral-300">
          <div
            className={cx('align-center my-2 block items-center px-2 py-3.5 md:flex md:flex-row')}
            style={{
              backgroundColor: background,
            }}
            data-element-id="event-calendar-daily-events-wrapper"
          >
            <div className="md:align-center py12 block md:flex md:flex-row">
              <h3
                className="mr-2 flex items-center whitespace-nowrap font-bold"
                id="dailyEventsFilter"
                style={{
                  color: inlineStyles?.eventsCalBaseTextColor,
                }}
              >
                <span aria-hidden="true">{t('calendar.dailyEvents')}</span>
                <span className="sr-only">{`${t('calendar.dailyEvents')} ${t(
                  'calendar.filters'
                )}`}</span>
                <span aria-hidden="true">:</span>
              </h3>
              <ul
                role="list"
                aria-labelledby="dailyEventsFilter"
                className="block list-none md:flex md:flex-row md:flex-wrap"
              >
                {dailyEventsSorted?.map((i, e) => {
                  const dateNumberFill = e + 1;
                  const schedule = i.node?.RegularlyScheduledEvents?.allDays;
                  const dateSplit = dateStr.split(' ');
                  const formattedDateStr = `${dateSplit[0]}-${months[dateSplit[1]]}-${
                    dateSplit[2]
                  }`;

                  let timeString: string = schedule?.allDay && t('calendar.openAllDay');
                  if (!schedule?.allDay && schedule?.startTime) {
                    timeString = formatHourDisplay(formattedDateStr, schedule?.startTime, locale);
                    if (schedule?.endTime && schedule?.endTime !== undefined) {
                      timeString += ` ${t('calendar.to')} `;
                      timeString += formatHourDisplay(formattedDateStr, schedule?.endTime, locale);
                    }
                  }

                  // Date string for events buttons should be yyyy-mm-01 (always the first of the month)
                  const btnDateStr = `${getYear(monthToDisplay)}-${format(monthToDisplay, 'MM', {
                    locale,
                  })}-01`;

                  return (
                    <li key={`item${e.toString()}`}>
                      <EventCalendarDailyEventsLineItem
                        eventTitle={i?.node?.title}
                        dateStringNom={btnDateStr}
                        eventTimes={timeString}
                        eventContent={i?.node}
                        dateNumber={dateNumberFill}
                        disabledState={false}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
};
