export function ConnectingRooms({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M12,3.625c-4.6254,0-8.375,3.7496-8.375,8.375S7.3746,20.375,12,20.375s8.375-3.7496,8.375-8.375
            C20.3699,7.3767,16.6233,3.6301,12,3.625z M12,19.625c-4.2112,0-7.625-3.4138-7.625-7.625S7.7888,4.375,12,4.375
            S19.625,7.7888,19.625,12C19.62,16.2091,16.2091,19.62,12,19.625z"
            />
            <path
              d="M12.4209,8.3027L11.3916,9.332c-0.1454,0.148-0.1433,0.3859,0.0047,0.5313c0.1462,0.1436,0.3805,0.1436,0.5266,0
            l1.0292-1.0293c0.6155-0.6072,1.6067-0.6004,2.2139,0.0151c0.6014,0.6096,0.6014,1.5892,0,2.1988l-1.373,1.373
            c-0.6155,0.6013-1.5984,0.6013-2.2139,0c-0.0924-0.094-0.1715-0.2001-0.2353-0.3154c-0.1046-0.1791-0.3346-0.2394-0.5137-0.1348
            c-0.1791,0.1046-0.2394,0.3346-0.1348,0.5137l0,0c0.0975,0.17,0.216,0.3272,0.3526,0.4677
            c0.5865,0.5774,1.4341,0.8028,2.23,0.5928c0.3952-0.101,0.7563-0.3056,1.0459-0.5928l1.3731-1.373
            c0.9098-0.8998,0.9179-2.3666,0.0181-3.2764c-0.8998-0.9098-2.3666-0.9179-3.2764-0.0181
            c-0.0061,0.006-0.0121,0.012-0.0181,0.0181L12.4209,8.3027z"
            />
            <path
              d="M12.0771,14.1367l-1.0292,1.0293c-0.6155,0.6072-1.6067,0.6005-2.2139-0.015c-0.6014-0.6096-0.6014-1.5893,0-2.1989
            l1.373-1.373c0.1954-0.194,0.4392-0.3319,0.7061-0.3994c0.5379-0.1471,1.1133,0.0053,1.5078,0.3994
            c0.0924,0.094,0.1715,0.2001,0.2353,0.3154c0.1046,0.1791,0.3346,0.2394,0.5137,0.1348
            c0.1791-0.1046,0.2394-0.3346,0.1348-0.5137c-0.5143-0.9058-1.5806-1.3434-2.583-1.06c-0.3952,0.101-0.7563,0.3056-1.0459,0.5928
            l-1.3731,1.373c-0.8998,0.9097-0.8917,2.3766,0.018,3.2764c0.9027,0.8928,2.3557,0.8928,3.2584,0l1.0293-1.0293
            c0.1454-0.148,0.1433-0.3859-0.0047-0.5313c-0.1462-0.1436-0.3805-0.1436-0.5266,0V14.1367z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
