/**
 * CANOPY PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  headerClassName: undefined,
  navStyles: undefined,
  textColorOnLoad: '#000',
  bgColorOnLoad: '#fff',
  textColorOnActive: '#000',
  bgColorOnActive: '#fff',
  bgSubmenuColor: '#fff',
  fontWeight: 'medium',
  menuItems: {
    colorOnHover: undefined,
    paddingClasses: 'px-5',
    fontSize: 'text-sm',
    case: 'uppercase',
    underline: {
      active: true,
      color: 'rgb(248, 78, 0)',
      size: undefined,
    },
    itemActive: {
      classes: undefined,
      bgColor: undefined,
      bgText: undefined,
    },
  },
  iconMenu: {
    extraClasses: undefined,
    fontSize: undefined,
    color: 'var(--color-black)',
    iconColor: 'var(--color-black)',
    activeColor: 'var(--color-black)',
    activeIconColor: undefined,
    separatorColor: undefined,
    underline: true,
    underlineHover: false,
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: undefined,
    extraClasses: 'btn-primary',
    backgroundColor: undefined,
    backgroundColorHover: undefined,
    textColor: undefined,
    textColorHover: undefined,
    textCase: undefined,
  },
};

export const brandFooterProps = {
  brandPhoneNumber: '+1-844-MY-CANOPY',
  backgroundColour: 'rgb(255,255,255)',
  textColour: '#000000',
  copyrightBackgroundColour: '#000000',
  copyrightTextColour: '#000000',
};

export const brandBreadcrumbProps = {
  activeTextColour: '#000000',
  backgroundColour: '#F3F3F3',
  textColour: '#000000',
};
