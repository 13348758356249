import type * as React from 'react';
import { useState } from 'react';
import cx from 'classnames';
import type { CropProps } from '../cropped-image/cropped-image';
import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { ACFAnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';
import { CopyTable } from '../copy-table';
import { HeadingStyle } from '../includes/heading-style';
import contentStyles from '../css/contentStyle.module.css';
import { useWindowSize, HandleWYSIWYGContent } from '../functions/helper';

interface ItemProps {
  slideIdx?: number;
  order?: number;
  title?: string;
  image?: string;
  imageAlt?: string;
  copy?: string;
  tableData?: {
    title?: string;
    text?: string;
  }[];
  className?: string;
  width?: number;
  size?: string;
  imageSizeArray?: {
    width?: number;
    height?: number;
  };
  logo?: {
    sourceUrl?: string;
    altText?: string;
  };
  onClick?: (e: React.MouseEvent) => void;
  isClickable?: boolean;
  margin?: number;
  links?: (ACFAnchorProps | undefined)[];
  tabInd?: number;
  headingValue?: string;
  headingClassesOnActiveState?: string;
  cropping?: CropProps;
  styleOptions?: {
    background?: string;
    backgroundLayerTwo?: string;
    flexAlign?: string;
    textAlignment?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
    titleColor?: string;
    textColor?: string;
    titleSize?: string;
    headingStyle?: string;
    tileTitleBackgroundColour?: string;
    tileTitleAlignment?: string;
    tileTitleLayout?: string;
    headingsNoTranslate?: boolean;
  };
  batchNumber?: string;
}

export function HoverTile({
  slideIdx,
  order,
  title,
  image,
  imageAlt,
  logo,
  copy,
  tableData,
  className,
  margin,
  width,
  links,
  styleOptions,
  onClick,
  isClickable,
  tabInd,
  headingValue,
  headingClassesOnActiveState,
  batchNumber,
  cropping,
}: ItemProps) {
  const [hovered, setHovered] = useState(false);
  const bgLayer2 = styleOptions?.backgroundLayerTwo;
  const styles = width
    ? {
        width: width + 'px',
        margin: margin + 'px',
        justifyContent: styleOptions?.flexAlign,
      }
    : {};
  const headingState = hovered ? headingClassesOnActiveState : '';
  const logoWidth = useWindowSize()?.width || 0 <= 1024 ? '100px' : '130px';
  const titleAlignmentStyles =
    styleOptions?.tileTitleAlignment === 'center'
      ? 'left-2/4 transform -translate-x-2/4'
      : styleOptions?.tileTitleAlignment === 'end'
      ? 'right-0 text-right'
      : 'text-left';
  const titleContainerWidth =
    styleOptions?.tileTitleLayout === 'withinAuto' ? (hovered ? 'auto' : 'auto w-min') : 'full';
  const hasGradient = styleOptions?.tileTitleLayout === 'withinGradient';
  const titleContainerWidthTailwind = `w-${titleContainerWidth}`;
  const justifyTitle = `justify-${styleOptions?.tileTitleAlignment}`;

  return (
    <div
      suppressHydrationWarning
      id={`${order?.toString()}-hover_tile${batchNumber && `_${batchNumber.toString()}`}`}
      data-slide-idx={slideIdx}
      tabIndex={tabInd || 0}
      className={cx(
        isClickable ? 'cursor-pointer' : '',
        className,
        'cp-hoverTile relative flex items-end justify-start overflow-hidden'
      )}
      style={styles}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={(e) => {
        const nextTarget = e.relatedTarget as HTMLElement;
        if (nextTarget?.tabIndex === 0) {
          if (!links) {
            setHovered(false);
          } else if (e.target.nodeName === 'A') {
            setHovered(false);
          } else if (nextTarget && nextTarget.nodeName === 'DIV') {
            setHovered(false);
          }
        }
      }}
      onClick={(e) => {
        const element = e.target as HTMLElement;
        if (element.tagName === 'A' || element.tagName === 'BUTTON') return;
        if (!isClickable) {
          e.preventDefault();
          return;
        }
        if (onClick instanceof Function) onClick(e);
      }}
    >
      <CroppedImage
        loader={() => {
          return customLoader({
            src: image || '',
            width: 720,
            height: 1000,
            crop: cropping?.enableCropping,
            cropType: cropping?.cropType,
            cropHeight: cropping?.cropHeight,
            cropWidth: cropping?.cropWidth,
            xPosition: cropping?.xPosition,
            xPositionAdvanced: cropping?.xPositionAdvanced,
            yPosition: cropping?.yPosition,
            yPositionAdvanced: cropping?.yPositionAdvanced,
            autoPosition: cropping?.autoPosition,
          });
        }}
        src={image || ''}
        width={720}
        height={1000}
        alt={imageAlt}
      />

      {/* gradient */}
      {hasGradient ? (
        <div
          data-element-id="hover-tile-gradient"
          className="from-text absolute -bottom-1 left-0 h-1/3 w-full bg-gradient-to-t to-transparent"
        />
      ) : null}
      {/* end gradient */}

      <div
        data-element-id="unhovered-tile-title-wrapper"
        data-hovered={hovered}
        className={cx(
          hovered
            ? 'size-full p-0'
            : `p-2 ${
                !title ? 'hidden' : ''
              } ${titleAlignmentStyles} ${titleContainerWidthTailwind}`,
          'text-bg absolute bottom-0 m-0 text-left shadow-lg'
        )}
      >
        <div
          data-element-id="unhovered-tile-title"
          className={cx(
            hovered
              ? 'h-full p-0 sm:p-2'
              : `${!title ? 'hidden' : ''} ${
                  styleOptions?.tileTitleLayout === 'withinAuto' ? 'p-2' : 'p-4'
                }`,
            'text-bg bottom-0 flex flex-col justify-center text-left shadow-lg'
          )}
          style={{
            background:
              hasGradient && !hovered
                ? undefined
                : title && !hovered && !styleOptions?.tileTitleBackgroundColour
                ? styleOptions?.background
                : title && !hovered && styleOptions?.tileTitleBackgroundColour
                ? styleOptions?.tileTitleBackgroundColour
                : styleOptions?.background,
            textAlign: styleOptions?.textAlignment,
          }}
        >
          <div
            data-element-id="tile-title-content"
            className={hovered && bgLayer2 ? 'mx-1 px-1 py-2 md:mx-8 md:px-2 md:py-8 lg:mx-12' : ''}
            style={{ backgroundColor: hovered ? bgLayer2 : '' }}
          >
            {logo?.sourceUrl && hovered ? (
              <img
                style={{ maxWidth: logoWidth }}
                className="relative mx-auto mb-4 max-h-28 w-auto lg:max-h-36"
                src={logo?.sourceUrl}
                alt={logo?.altText}
              />
            ) : null}
            {title ? (
              <div
                className={
                  !hovered && styleOptions?.tileTitleAlignment ? `flex ${justifyTitle}` : ''
                }
              >
                <HeadingStyle
                  text={title}
                  type={headingValue ?? 'h3'}
                  textColorInline={hovered ? styleOptions?.textColor : styleOptions?.titleColor}
                  className={cx(styleOptions?.titleSize, headingState, {
                    OneLinkNoTx: styleOptions?.headingsNoTranslate,
                  })}
                  styledAs={styleOptions?.headingStyle}
                />
              </div>
            ) : null}
            <div
              data-testid="hoverTileCopy"
              className={cx('duration-300 overflow-hidden py-1 transition ease-in-out', {
                'h-0 hidden': !hovered,
                'h-auto block text-xs mb-1': hovered,
                'mt-4': tableData ? true : false,
              })}
            >
              {copy ? (
                <p
                  className={cx(
                    'py-2 text-base leading-relaxed xl:text-lg',
                    tableData ? 'pb-4' : '',
                    contentStyles.listStyle,
                    contentStyles.paragraphStyle
                  )}
                  style={{ color: styleOptions?.textColor }}
                  dangerouslySetInnerHTML={{ __html: HandleWYSIWYGContent(copy) }}
                />
              ) : null}
              {tableData ? (
                <CopyTable repeater={tableData} textColor={styleOptions?.textColor} />
              ) : null}
              {links?.map((link, key) => {
                return (
                  <AnchorLink
                    key={key}
                    url={link?.link?.url}
                    title={link?.link?.title}
                    target={link?.link?.target}
                    buttonStyle={link?.buttonStyle || ''}
                    className="-ml-0"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
