import { useEffect } from 'react';
import cx from 'classnames';
import type { CropProps } from '../cropped-image/cropped-image';
import { customLoader } from '../cropped-image/cropped-image';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array, GIS_Padder, GIS_TextAlignment } from '../functions/global-instance-styles';
import type { WordpressPageInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/wp';
import { Offset } from '@dx-ui/osc-offset';
import type { OffsetComponentProps } from './offset.types';
import { appliedCloudinaryParams, makeRandomInt } from '@curated-property/utils';
import { AnchorLink } from '../global/anchor-link';

interface CombinedOffsetProps extends CropProps, OffsetComponentProps {}

export function offsetPropMapper(
  componentData: CombinedOffsetProps & {
    offsetComponentSettings?: StyleObject;
  },
  globalData: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    heading: componentData?.heading,
    description: componentData?.description,
    image1: componentData?.image1,
    image2: componentData?.image2,
    image1CaptionImageCaption: componentData?.image1CaptionImageCaption,
    image2CaptionImageCaption: componentData?.image2CaptionImageCaption,
    buttons: componentData?.buttons,
    reverseDirection: componentData?.reverseDirection,
    globalStyles: globalData?.offsetComponentSettings,
    instanceStyles: componentData?.offsetComponentSettings,
    image1CroppingAutoPosition: componentData?.image1CroppingAutoPosition,
    image1CroppingCropHeight: componentData?.image1CroppingCropHeight,
    image1CroppingCropType: componentData?.image1CroppingCropType,
    image1CroppingCropWidth: componentData?.image1CroppingCropWidth,
    image1CroppingEnableCropping: componentData?.image1CroppingEnableCropping,
    image1CroppingXPosition: componentData?.image1CroppingXPosition,
    image1CroppingXPositionAdvanced: componentData?.image1CroppingXPositionAdvanced,
    image1CroppingYPosition: componentData?.image1CroppingYPosition,
    image1CroppingYPositionAdvanced: componentData?.image1CroppingYPositionAdvanced,
    image2CroppingAutoPosition: componentData?.image2CroppingAutoPosition,
    image2CroppingCropHeight: componentData?.image2CroppingCropHeight,
    image2CroppingCropType: componentData?.image2CroppingCropType,
    image2CroppingCropWidth: componentData?.image2CroppingCropWidth,
    image2CroppingEnableCropping: componentData?.image2CroppingEnableCropping,
    image2CroppingXPosition: componentData?.image2CroppingXPosition,
    image2CroppingXPositionAdvanced: componentData?.image2CroppingXPositionAdvanced,
    image2CroppingYPosition: componentData?.image2CroppingYPosition,
    image2CroppingYPositionAdvanced: componentData?.image2CroppingYPositionAdvanced,
  };
}

export function OffsetComponent({
  heading,
  description,
  buttons,
  reverseDirection,
  image1,
  image1CroppingAutoPosition,
  image1CroppingCropHeight,
  image1CroppingCropType,
  image1CroppingCropWidth,
  image1CroppingEnableCropping,
  image1CroppingXPosition,
  image1CroppingXPositionAdvanced,
  image1CroppingYPosition,
  image1CroppingYPositionAdvanced,
  image2,
  image2CroppingAutoPosition,
  image2CroppingCropHeight,
  image2CroppingCropType,
  image2CroppingCropWidth,
  image2CroppingEnableCropping,
  image2CroppingXPosition,
  image2CroppingXPositionAdvanced,
  image2CroppingYPosition,
  image2CroppingYPositionAdvanced,
  image1CaptionImageCaption,
  image2CaptionImageCaption,
  instanceStyles,
  globalStyles,
}: CombinedOffsetProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  let justification = textAlignment;
  if (textAlignment && textAlignment !== 'center') {
    if (justification === 'right') justification = 'end';
    if (justification === 'left') justification = 'start';
  }
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const randomInt = makeRandomInt().toString();
  const styleElementID = `offsetStyle${randomInt}`;
  useEffect(() => {
    let style = '';

    if (inlineStyles?.imageCaptionBackgroundColor || inlineStyles?.imageCaptionTextColor) {
      if (inlineStyles?.imageCaptionBackgroundColor)
        style += `#offset${randomInt} figcaption > div, #offset${randomInt} figcaption > a  { opacity:1; background: ${inlineStyles?.imageCaptionBackgroundColor} !important;}`;

      if (inlineStyles?.imageCaptionTextColor)
        style += `#offset${randomInt} figcaption > div, #offset${randomInt} figcaption > a  {color: ${inlineStyles?.imageCaptionTextColor} !important;}`;

      if (!document.getElementById(styleElementID)) {
        const $style = document.createElement('style');
        $style.setAttribute('id', styleElementID);
        document.head.appendChild($style);
        $style.innerHTML = style;
      }
    }
  }, [
    styleElementID,
    inlineStyles?.imageCaptionBackgroundColor,
    inlineStyles?.imageCaptionTextColor,
    randomInt,
  ]);

  const image1Source = image1CroppingEnableCropping
    ? customLoader({
        src: image1?.sourceUrl,
        width: 1200,
        height: 600,
        crop: image1CroppingEnableCropping,
        cropType: image1CroppingCropType,
        cropHeight: image1CroppingCropHeight,
        cropWidth: image1CroppingCropWidth,
        xPosition: image1CroppingXPosition,
        xPositionAdvanced: image1CroppingXPositionAdvanced,
        yPosition: image1CroppingYPosition,
        yPositionAdvanced: image1CroppingYPositionAdvanced,
        autoPosition: image1CroppingAutoPosition,
      })
    : appliedCloudinaryParams(image1?.sourceUrl);

  const image2Source = image2CroppingEnableCropping
    ? customLoader({
        src: image2?.sourceUrl,
        width: 1200,
        height: 600,
        crop: image2CroppingEnableCropping,
        cropType: image2CroppingCropType,
        cropHeight: image2CroppingCropHeight,
        cropWidth: image2CroppingCropWidth,
        xPosition: image2CroppingXPosition,
        xPositionAdvanced: image2CroppingXPositionAdvanced,
        yPosition: image2CroppingYPosition,
        yPositionAdvanced: image2CroppingYPositionAdvanced,
        autoPosition: image2CroppingAutoPosition,
      })
    : appliedCloudinaryParams(image2?.sourceUrl);

  return (
    <div
      id={`offset${randomInt}`}
      data-element-id="offset-component"
      data-testid="offsetComponent"
      className={cx('cp-offsetComponent', paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div className={cx('[&_figcaption>*]:text-bg container')}>
        <Offset
          headline={
            <h2
              className={cx(
                'font-headline text-2xl font-black leading-none md:text-4xl',
                textAlignment && `text-${textAlignment}`
              )}
              style={{ color: inlineStyles?.titleColor }}
            >
              {heading}
            </h2>
          }
          image={{
            large: {
              alt: image1?.altText || ' ',
              captionData: {
                caption: image1CaptionImageCaption?.captionText,
                captionLink: image1CaptionImageCaption?.captionLink?.url,
                captionLinkTarget: image1CaptionImageCaption?.captionLink?.target,
              },
              src: image1Source,
            },
            small: {
              alt: image2?.altText || ' ',
              captionData: {
                caption: image2CaptionImageCaption?.captionText,
                captionLink: image2CaptionImageCaption?.captionLink?.url,
                captionLinkTarget: image2CaptionImageCaption?.captionLink?.target,
              },
              src: image2Source,
            },
          }}
          isInversePattern={reverseDirection}
        >
          <div>
            {description && (
              <div
                data-testid="offsetDescriptionCopy"
                className={cx(
                  'mb-3 text-sm md:text-base lg:text-lg',
                  textAlignment && `text-${textAlignment}`
                )}
                style={{ color: inlineStyles?.textColor }}
                dangerouslySetInnerHTML={{ __html: description || '' }}
              />
            )}

            {buttons?.length && (
              <div
                data-testid="offsetCustomCtas"
                className={cx('sm:flex md:flex-row', justification && `justify-${justification}`)}
              >
                {buttons?.map((btn, e) => {
                  return (
                    <AnchorLink
                      className={cx(
                        'btn block text-center sm:inline-block',
                        e === 0 && (!justification || justification === 'start') ? 'sm:ml-0' : '',
                        e === buttons.length - 1 && justification === 'end' ? 'sm:mr-0' : ''
                      )}
                      key={`btn${e.toString()}`}
                      url={btn?.link?.url}
                      title={btn?.link?.title}
                      target={btn?.link?.target}
                      buttonStyle={btn?.buttonStyle ? btn?.buttonStyle : 'primary'}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </Offset>
      </div>
    </div>
  );
}
