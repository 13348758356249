import { type SVGAttributes } from 'react';

const ResortIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path d="M15.736 22.946a2.237 2.237 0 0 1 .018-.282c.356-3.383 3.433-5.846 6.894-5.496 3.449.35 5.96 3.377 5.604 6.76-.01.098-.021.195-.041.28M32.807 20.662l5.007-1.234M28.562 14.517l3.027-4.103M21.283 12.484l-.37-5.05M14.375 15.517L10.78 11.88M11.07 22.194l-5.136-.52M2.72 27.483c3.01 0 3.01 2.497 6.02 2.497 3.011 0 3.011-2.497 6.015-2.497 3.011 0 3.011 2.497 6.021 2.497 3.017 0 3.017-2.497 6.028-2.497 3.01 0 3.01 2.497 6.02 2.497 3.011 0 3.011-2.497 6.021-2.497.974 0 1.636.262 2.183.618M2.72 33.73c3.01 0 3.01 2.498 6.02 2.498 3.011 0 3.011-2.497 6.015-2.497 3.011 0 3.011 2.497 6.021 2.497 3.017 0 3.017-2.497 6.028-2.497 3.01 0 3.01 2.497 6.02 2.497 3.011 0 3.011-2.497 6.021-2.497.974 0 1.636.262 2.183.618" />
      </g>
    </svg>
  );
};

export default ResortIcon;
