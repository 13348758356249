import { useState, useEffect } from 'react';
import { EventCalendarMonth } from './event-calendar-month';
import type { FilterSelection } from './event-calendar-type-filters';
import { EventCalendarTypeFilters } from './event-calendar-type-filters';
import { addMonths } from 'date-fns';
import { ArrowLeftLong, ArrowRightLong } from '@curated-property/icons';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { EventCalendarDailyEvents } from './event-calendar-daily-events';
import { useTranslation } from 'next-i18next';
import { categoryArr } from './lib/event-calendar-constants';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from '../functions/global-instance-styles';
import cx from 'classnames';
import type {
  EventCalendarHotelInfoProps,
  EventCalendarSpecialEventProps,
  EventCalendarModalSettings,
  EventCalendarRegularEventProps,
  RegularEventCombinedItemProps,
} from './lib/event-calendar-props';
import { eventCalendarStyleBlock } from './event-calendar-helpers';
import { useRouter } from 'next/router';
import { weekStartsOn } from '@dx-ui/framework-i18n';
import { useLocale } from '@dx-ui/utilities-dates';
import type { WpSettingsFragment } from '@dx-ui/queries-dx-curated-ui/generated/wp';

export function eventCalendarHotelInfoPropMapper(
  hotelName,
  hotelLocale,
  hotelAddress,
  hotelContact
) {
  return {
    hotelName,
    hotelLocale: {
      coordinate: {
        latitude: hotelLocale?.coordinate?.latitude,
        longitude: hotelLocale?.coordinate?.longitude,
      },
      currency: {
        description: hotelLocale?.currency?.description,
      },
      gmtHours: hotelLocale?.gmtHours,
      gmtHoursFmt: hotelLocale?.gmtHoursFmt,
      timeZone: hotelLocale?.timeZone,
      currencyCode: hotelLocale?.currencyCode,
    },
    hotelAddress: {
      addressStacked: hotelAddress?.addressStacked,
      addressLine1: hotelAddress?.addressLine1,
      city: hotelAddress?.city,
      city_noTx: hotelAddress?.city_noTx,
      country: hotelAddress?.country,
      countryName: hotelAddress?.countryName,
      countryName_noTx: hotelAddress?.countryName_noTx,
      state: hotelAddress?.state,
      stateName: hotelAddress?.stateName,
      stateName_noTx: hotelAddress?.stateName_noTx,
    },
    hotelContact: {
      emailAddress1: hotelContact?.emailAddress1,
      faxNumber: hotelContact?.faxNumber,
      phoneNumber: hotelContact?.phoneNumber,
    },
  };
}

export type EventCalendarSpecialEventType = EventCalendarSpecialEventProps;

export type EventCalendarRegularEventType = EventCalendarRegularEventProps;

export type EventCalendarCustomCategories =
  WpSettingsFragment['themeSettings']['SettingsThemeSettings']['customEventCategories'];

export function eventCalSplEventPropMapper(nodes: Array<EventCalendarSpecialEventProps>) {
  return nodes;
}

export function eventCalRegEventPropMapper(nodes: Array<EventCalendarRegularEventProps>) {
  return nodes;
}

export function eventCalCustomCategoriesPropMapper(
  customEventCategories: EventCalendarCustomCategories
) {
  return customEventCategories;
}

export interface EventCalendarProps {
  customEventCategories?: EventCalendarCustomCategories;
  specialEvents?: Array<EventCalendarSpecialEventType>;
  regularEvents?: Array<EventCalendarRegularEventType>;
  hotelInfo?: EventCalendarHotelInfoProps;
  api_key?: string;
  latLng?: {
    lat?: number;
    lng?: number;
  };
  modalSettings?: EventCalendarModalSettings;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  enableHHR?: boolean | null;
}

export function EventCalendar({
  customEventCategories,
  specialEvents,
  regularEvents,
  hotelInfo,
  api_key,
  latLng,
  modalSettings,
  globalStyles,
  instanceStyles,
  enableHHR,
}: EventCalendarProps) {
  const { locale: localeStr = 'en' } = useRouter();
  const locale = useLocale({ language: localeStr });
  const currentDate = new Date();
  const [displayNextArrow, setDisplayNextArrow] = useState(true);
  const [displayPrevArrow, setDisplayPrevArrow] = useState(false);
  const numberOfAvailableMonths = 6;
  const [currentMonthKey, setCurrentMonthKey] = useState(0);
  // Collect which categories and locales (on-site or off-site) to show
  const [selectedLocales, setSelectedLocales] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { t } = useTranslation();
  // Change select accepts a new value from the month selector and sets that value for all child components
  const changeSelect = (update) => {
    // Change integer for selected dropdown select value
    setCurrentMonthKey(update);
  };
  useEffect(() => {
    setDisplayNextArrow(currentMonthKey < numberOfAvailableMonths - 1 ? true : false);

    setDisplayPrevArrow(currentMonthKey === 0 ? false : true);
  }, [currentMonthKey]);

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  // Make styles for <head> injection
  useEffect(() => {
    if (!document.querySelectorAll('[id^="eventCalHeadStyle"]').length) {
      // CMS-controlled Button Inline Values
      const styleString = eventCalendarStyleBlock(inlineStyles);

      const stylingElement = document.createElement('style');
      stylingElement.setAttribute('id', `eventCalHeadStyle`);
      stylingElement.setAttribute('type', 'text/css');
      document.head.appendChild(stylingElement);
      stylingElement.innerHTML = styleString;
    }
  }, [inlineStyles]);

  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const firstDayOfWeek = weekStartsOn(locale);

  const sundayEvents: Array<RegularEventCombinedItemProps> = [];
  const mondayEvents: Array<RegularEventCombinedItemProps> = [];
  const tuesdayEvents: Array<RegularEventCombinedItemProps> = [];
  const wednesdayEvents: Array<RegularEventCombinedItemProps> = [];
  const thursdayEvents: Array<RegularEventCombinedItemProps> = [];
  const fridayEvents: Array<RegularEventCombinedItemProps> = [];
  const saturdayEvents: Array<RegularEventCombinedItemProps> = [];

  let recurringEvents: Array<Array<RegularEventCombinedItemProps>> = [
    sundayEvents,
    mondayEvents,
    tuesdayEvents,
    wednesdayEvents,
    thursdayEvents,
    fridayEvents,
    saturdayEvents,
  ];

  if (firstDayOfWeek > 0) {
    recurringEvents = [
      ...recurringEvents.slice(firstDayOfWeek),
      ...recurringEvents.slice(0, firstDayOfWeek),
    ];
  }

  const dailyEvents = [];

  [...(regularEvents as any)]?.forEach((i) => {
    const schedule = i.node?.RegularlyScheduledEvents;

    if (i.node?.RegularlyScheduledEvents?.allDays?.heldDaily === true) {
      [...recurringEvents].forEach((el) => {
        el.push(i);
      });
      dailyEvents.push(i);
    } else {
      if (!schedule?.allDays?.heldDaily) {
        const dayMap = {
          sunday: firstDayOfWeek === 0 ? 0 : 6,
          monday: (7 + 1 - firstDayOfWeek) % 7,
          tuesday: (7 + 2 - firstDayOfWeek) % 7,
          wednesday: (7 + 3 - firstDayOfWeek) % 7,
          thursday: (7 + 4 - firstDayOfWeek) % 7,
          friday: (7 + 5 - firstDayOfWeek) % 7,
          saturday: (7 + 6 - firstDayOfWeek) % 7,
        };

        schedule?.sundaySchedule?.scheduled && recurringEvents[dayMap.sunday].push(i);
        schedule?.mondaySchedule?.scheduled && recurringEvents[dayMap.monday].push(i);
        schedule?.tuesdaySchedule?.scheduled && recurringEvents[dayMap.tuesday].push(i);
        schedule?.wednesdaySchedule?.scheduled && recurringEvents[dayMap.wednesday].push(i);
        schedule?.thursdaySchedule?.scheduled && recurringEvents[dayMap.thursday].push(i);
        schedule?.fridaySchedule?.scheduled && recurringEvents[dayMap.friday].push(i);
        schedule?.saturdaySchedule?.scheduled && recurringEvents[dayMap.saturday].push(i);
      }
    }
  });

  // This sorts the correct line items to show based on category and locale
  // The updated selectedLocales, selectedCategories and selectedDateIds useState consts are what are passed to the month to show/hide content accordingly
  const whichEntriesToShow = (filterSelection: FilterSelection) => {
    const filterChecked = filterSelection[0];
    const filterValue = filterSelection[1];
    const arrayType: string = filterSelection[2];

    // Empty all useState arrays if the value supplied is reset
    if (filterChecked === 'reset') {
      setSelectedLocales([]);
      setSelectedCategories([]);
    } else {
      if (arrayType === 'locale') {
        const valIndex = selectedLocales.indexOf(filterValue);
        if (filterChecked === true && valIndex === -1) {
          setSelectedLocales((selectedLocales) => [...selectedLocales, filterValue]);
        } else {
          if (filterChecked === false && valIndex !== -1) {
            setSelectedLocales([
              ...selectedLocales.slice(0, valIndex),
              ...selectedLocales.slice(valIndex + 1, selectedLocales.length),
            ]);
          }
        }
      }

      if (arrayType === 'category') {
        const valIndex = selectedCategories.indexOf(filterValue);
        if (filterChecked === true && valIndex === -1) {
          setSelectedCategories((selectedCategories) => [...selectedCategories, filterValue]);
        } else {
          if (filterChecked === false && valIndex !== -1) {
            setSelectedCategories([
              ...selectedCategories.slice(0, valIndex),
              ...selectedCategories.slice(valIndex + 1, selectedCategories.length),
            ]);
          }
        }
      }
    }
  };

  const hotelInfoObj = {
    hotelName: hotelInfo?.hotelName,
    gmtHours: hotelInfo?.hotelLocale?.gmtHours,
    gmtHoursFmt: hotelInfo?.hotelLocale?.gmtHoursFmt,
    timeZone: hotelInfo?.hotelLocale?.timeZone,
    addressStacked: hotelInfo?.hotelAddress?.addressStacked,
    addressFormat: hotelInfo?.hotelAddress?.addressFmt,
    addressLine1: hotelInfo?.hotelAddress?.addressLine1,
    addressLine2: hotelInfo?.hotelAddress?.addressLine2,
    addressLine3: hotelInfo?.hotelAddress?.addressLine3,
    addressLine4: hotelInfo?.hotelAddress?.addressLine4,
    city: hotelInfo?.hotelAddress?.city,
    state: hotelInfo?.hotelAddress?.state,
    country: hotelInfo?.hotelAddress?.country,
    postalCode: hotelInfo?.hotelAddress?.postalCode,
    countryName: hotelInfo?.hotelAddress?.countryName,
    api_key,
    latLng,
    email: hotelInfo?.hotelContact?.emailAddress1,
    phone: hotelInfo?.hotelContact?.phoneNumber,
  };

  const allAvailableCategories = categoryArr.map((category) => ({
    label: t(`calendar.${category}`),
    value: category,
  }));

  // Add custom categories to array of default category filter types
  if (customEventCategories?.length) {
    customEventCategories?.forEach((el) => {
      allAvailableCategories.push({
        label: el?.customCategoryNames_noTx,
        value: el?.customCategoryNames,
      });
    });
  }

  const eventCategoriesFromCMS = [...new Set(allAvailableCategories)];

  const contextObj: EventCalendarInfoContext = {
    hotelInfo: hotelInfoObj,
    recurringEvents,
    specialEvents,
    modalSettings,
    eventCategoriesWithCustom: eventCategoriesFromCMS,
    enableHHR,
    inlineStyles,
    localeLanguage: locale?.language || 'en',
  };

  // Daily events row will return empty if there aren't any regular events scheduled every day of the week.
  const dailyEventsRow = dailyEvents.length ? (
    <div>
      <EventCalendarDailyEvents
        recurringEvents={dailyEvents}
        monthToDisplay={addMonths(currentDate, currentMonthKey)}
      />
    </div>
  ) : (
    ''
  );

  const arrowFillColor = inlineStyles?.eventsCalBaseIconColor || 'inherit';

  return (
    <EventCalendarInfoContext.Provider value={contextObj}>
      <div
        id="eventsCal"
        className={cx('cp-eventCalendar', paddingStyles, inlineStyles?.showHide && 'hidden')}
        style={{
          backgroundColor: inlineStyles?.eventsCalendarComponentBgColor || null,
        }}
      >
        <div
          data-testid="calendar-component"
          className="container px-2 py-4 md:px-6 lg:px-5 2xl:px-0"
        >
          <div className="flex flex-row justify-between ">
            <div className="w-1/3 py-4  md:w-1/4 md:py-0">
              <div className="flex flex-col pb-1.5 md:pb-2">
                <label
                  id="monthSelect"
                  className="block pb-1 text-left"
                  htmlFor="selectMonthDropdown"
                  style={{ color: inlineStyles?.eventsCalBaseTextColor }}
                >
                  {t('calendar.filterByMonth')}
                </label>
                <select
                  aria-labelledby="monthSelect"
                  aria-label={t('monthDropdown', {
                    numMonths: numberOfAvailableMonths,
                  })}
                  className="bg-bg border-primary text-primary w-full rounded border bg-[center_top_1rem] px-4 py-2 text-sm md:w-2/3 lg:w-1/2"
                  value={currentMonthKey}
                  id="selectMonthDropdown"
                  onChange={(ev) => changeSelect(parseInt(ev.target.value))}
                  style={{
                    color: inlineStyles?.eventsCalAdditionalTextFilterColor,
                    borderColor: inlineStyles?.eventsCalAdditionalTextFilterColor,
                  }}
                >
                  {[...Array(numberOfAvailableMonths)]?.map((i, e) => {
                    const month = addMonths(currentDate, e);
                    return (
                      <option
                        className="bg-bg-alt px-2 py-4"
                        key={'dropdown_' + e.toString()}
                        value={e}
                      >
                        {month.toLocaleString(localeStr, {
                          month: 'long',
                          year: 'numeric',
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div
              aria-label={t('calendar.prevOrNextMonthButtons')}
              className="mx-auto hidden grow justify-center pb-2 pt-6 lg:flex"
            >
              <div className="flex w-16 justify-center">
                <button
                  type="button"
                  tabIndex={0}
                  disabled={displayPrevArrow === false ? true : false}
                  className={displayPrevArrow === false ? 'hidden' : ''}
                  onClick={() => {
                    setCurrentMonthKey(currentMonthKey - 1 < 0 ? 0 : currentMonthKey - 1);
                  }}
                  aria-label={t('calendar.viewPrevMonth')}
                >
                  <span className="sr-only">{t('calendar.viewPrevMonth')}</span>
                  <span aria-hidden="true">
                    <ArrowLeftLong className="h-4" fillColor={arrowFillColor} />
                  </span>
                </button>
              </div>
              <div className="mx-12 flex w-24 items-center justify-center text-lg font-bold">
                <h2 aria-live="polite" style={{ color: inlineStyles?.eventsCalBaseTextColor }}>
                  <span>
                    {addMonths(currentDate, currentMonthKey).toLocaleString(localeStr, {
                      month: 'long',
                    })}
                  </span>
                </h2>
              </div>
              <div className="flex w-16 justify-center">
                <button
                  type="button"
                  tabIndex={0}
                  disabled={displayNextArrow === false ? true : false}
                  className={displayNextArrow === false ? 'hidden' : ''}
                  onClick={() => {
                    setCurrentMonthKey(
                      currentMonthKey + 1 >= numberOfAvailableMonths - 1
                        ? numberOfAvailableMonths - 1
                        : currentMonthKey + 1
                    );
                  }}
                  aria-label={t('calendar.viewNextMonth')}
                >
                  <span className="sr-only">{t('calendar.viewNextMonth')}</span>
                  <span aria-hidden="true">
                    <ArrowRightLong className="h-4" fillColor={arrowFillColor} />
                  </span>
                </button>
              </div>
            </div>
            <div className="w-2/3 py-4 md:w-1/4">
              <label
                aria-hidden="true"
                id="typeSelect"
                className="block pb-1 text-right"
                htmlFor="filterTypesBtn"
                style={{ color: inlineStyles?.eventsCalBaseTextColor }}
              >
                {t('calendar.filterByTypeAndLocation')}
              </label>
              <EventCalendarTypeFilters displayFilterInputs={whichEntriesToShow} />
            </div>
          </div>

          {dailyEventsRow}

          <div
            className=" mx-auto flex w-full items-center justify-between sm:w-2/3 lg:hidden"
            aria-hidden="true"
          >
            <div className="w-1/5 pl-2 text-left text-xs font-medium">
              <button
                type="button"
                data-testid="prevMonth"
                disabled={displayPrevArrow === false ? true : false}
                className={displayPrevArrow === false ? 'hidden' : ''}
                onClick={() => {
                  setCurrentMonthKey(currentMonthKey - 1 < 0 ? 0 : currentMonthKey - 1);
                }}
              >
                <span className="sr-only">{t('calendar.viewPrevMonth')}</span>
                <ArrowLeftLong className="h-3.5" fillColor={arrowFillColor} />
              </button>
            </div>
            <h3
              id="mobileMonth"
              tabIndex={0}
              className="block w-3/5 text-center text-sm font-bold"
              style={{ color: inlineStyles?.eventsCalBaseTextColor }}
            >
              {addMonths(currentDate, currentMonthKey).toLocaleString(localeStr, {
                month: 'long',
                year: 'numeric',
              })}
            </h3>
            <div className="w-1/5 pr-2 text-right text-xs font-medium">
              <button
                type="button"
                data-testid="nextMonth"
                disabled={displayNextArrow === false ? true : false}
                className={displayNextArrow === false ? 'hidden' : ''}
                onClick={() => {
                  setCurrentMonthKey(
                    currentMonthKey + 1 >= numberOfAvailableMonths - 1
                      ? numberOfAvailableMonths - 1
                      : currentMonthKey + 1
                  );
                }}
              >
                <span className="sr-only">{t('calendar.viewNextMonth')}</span>
                <ArrowRightLong className="h-3.5" fillColor={arrowFillColor} />
              </button>
            </div>
          </div>
          <div tabIndex={0}>
            <EventCalendarMonth
              monthToDisplay={addMonths(currentDate, currentMonthKey)}
              selectedLocales={selectedLocales}
              selectedCategories={selectedCategories}
            />
          </div>
        </div>
      </div>
    </EventCalendarInfoContext.Provider>
  );
}
