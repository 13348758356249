export function MobileApp({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 3.75C7.08 3.75 6.75 4.08 6.75 4.5C6.75 4.92 7.08 5.25 7.5 5.25H10.5C10.92 5.25 11.25 4.92 11.25 4.5C11.25 4.08 10.92 3.75 10.5 3.75H7.5Z" />
      <path d="M21.975 8.475L10.5 19.935L6.525 15.975C6.225 15.69 5.76 15.69 5.46 15.975C5.16 16.26 5.175 16.74 5.46 17.04L9.96 21.525C10.11 21.675 10.305 21.75 10.485 21.75C10.665 21.75 10.875 21.675 11.01 21.525L23.01 9.54C23.31 9.24 23.31 8.775 23.01 8.475C22.71 8.175 22.245 8.175 21.945 8.475H21.975Z" />
      <path d="M16.5 11.25C16.92 11.25 17.25 10.92 17.25 10.5V4.5C17.25 2.43 15.57 0.75 13.5 0.75H4.5C2.43 0.75 0.75 2.43 0.75 4.5V25.5C0.75 27.57 2.43 29.25 4.5 29.25H13.5C15.57 29.25 17.25 27.57 17.25 25.5V19.5C17.25 19.08 16.92 18.75 16.5 18.75C16.08 18.75 15.75 19.08 15.75 19.5V25.5C15.75 26.745 14.745 27.75 13.5 27.75H4.5C3.255 27.75 2.25 26.745 2.25 25.5V4.5C2.25 3.255 3.255 2.25 4.5 2.25H13.5C14.745 2.25 15.75 3.255 15.75 4.5V10.5C15.75 10.92 16.08 11.25 16.5 11.25Z" />
    </svg>
  );
}
