import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array } from '../functions/global-instance-styles';

interface EventCalendarDayProps {
  dayNameFull?: string;
  dayNameDisplay?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

const EventCalendarDayName = ({
  dayNameDisplay,
  dayNameFull,
  instanceStyles,
  globalStyles,
}: EventCalendarDayProps) => {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  return (
    <th
      key={dayNameDisplay}
      scope="col"
      className="box-border p-2 text-xs uppercase"
      abbr={dayNameFull}
      style={{ color: inlineStyles?.eventsCalBaseTextColor }}
    >
      <span aria-hidden="true">{dayNameDisplay}</span>
      <span className="sr-only">{dayNameFull}</span>
    </th>
  );
};

export default EventCalendarDayName;
