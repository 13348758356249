import { useRef } from 'react';
import { format, getDaysInMonth } from 'date-fns';
import * as locales from 'date-fns/locale';
import cx from 'classnames';
import type { StyleObject } from '../../functions/global-instance-styles';
import { GIS_Array } from '../../functions/global-instance-styles';

interface EventCalendarDatePickerProps {
  dateStringNom?: string;
  dayPickerDateNumber: number | null | undefined;
  dateSelection?: any;
  btnClass?: string | '';
  disabledState?: boolean | false;
  currentMonth: Date;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export default function EventCalendarDatePickerDay({
  dateStringNom,
  dayPickerDateNumber,
  dateSelection,
  btnClass,
  disabledState,
  currentMonth,
  instanceStyles,
  globalStyles,
}: EventCalendarDatePickerProps) {
  const locale = locales?.enUS;
  const daysInMonth = getDaysInMonth(currentMonth);
  const dateStringSafariSafe: string = !dateStringNom ? '' : dateStringNom?.replace(/-/g, '/');
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  function focusTo(tgt: string) {
    setTimeout(() => {
      if (tgt) {
        document.getElementById(tgt)?.focus();
      }
    }, 50);
  }

  // What month is this and what's the current date?
  const today = new Date();
  const lowestAvailableDateInMonth =
    parseInt(format(today, 'M')) === parseInt(format(currentMonth, 'M'))
      ? parseInt(format(today, 'd'))
      : 1;

  let bgInline = '';
  if (btnClass?.includes('bg-primary') && inlineStyles?.eventsCalSelectedDatePickerDateBg) {
    bgInline = inlineStyles?.eventsCalSelectedDatePickerDateBg;
  } else {
    if (
      btnClass?.includes('bg-secondary') &&
      inlineStyles?.eventsCalSelectedDatePickerDateRangeBg
    ) {
      bgInline = inlineStyles?.eventsCalSelectedDatePickerDateRangeBg;
    }
  }

  let textColorInline = '';
  if (!btnClass && inlineStyles?.eventsCalBaseTextColor) {
    textColorInline = inlineStyles?.eventsCalBaseTextColor;
  }
  if (
    (btnClass?.includes('bg-primary') || btnClass?.includes('bg-secondary')) &&
    inlineStyles?.eventsCalSelectedDatepickerDateText
  ) {
    textColorInline = inlineStyles?.eventsCalSelectedDatepickerDateText;
  }

  return (
    dayPickerDateNumber && (
      <button
        id={`date${dayPickerDateNumber}`}
        data-testid={`date${dayPickerDateNumber}`}
        tabIndex={!disabledState ? 0 : -1}
        disabled={disabledState}
        className={cx(
          'leading-0 disabled:text-text-disabled my-0 block w-full appearance-none border-none p-2 font-bold tabular-nums disabled:cursor-default',
          btnClass
        )}
        style={{
          color: textColorInline,
          backgroundColor: bgInline,
        }}
        onClick={(e) => {
          e.preventDefault();
          dateSelection(dayPickerDateNumber);
          focusTo(`date${dayPickerDateNumber}`);
        }}
        onKeyDown={(e) => {
          e.preventDefault();
          if (e.shiftKey && e.key === 'Tab') {
            document.getElementById('mobileMonth')?.focus();
          } else {
            switch (e.key) {
              case 'Tab':
                focusTo('clearEventDatepicker');
                break;
              // Spacebar
              case ' ':
                dateSelection(dayPickerDateNumber);
                focusTo(`date${dayPickerDateNumber}`);
                break;

              case 'ArrowDown':
                focusTo(
                  `date${
                    dayPickerDateNumber + 7 > daysInMonth ? daysInMonth : dayPickerDateNumber + 7
                  }`
                );
                break;
              case 'ArrowUp':
                focusTo(
                  `date${
                    dayPickerDateNumber - 7 < lowestAvailableDateInMonth
                      ? lowestAvailableDateInMonth
                      : dayPickerDateNumber - 7
                  }`
                );
                break;

              case 'ArrowLeft':
                focusTo(
                  `date${
                    dayPickerDateNumber - 1 < lowestAvailableDateInMonth
                      ? lowestAvailableDateInMonth
                      : dayPickerDateNumber - 1
                  }`
                );
                break;
              case 'ArrowRight':
                focusTo(
                  `date${
                    dayPickerDateNumber + 1 > daysInMonth ? daysInMonth : dayPickerDateNumber + 1
                  }`
                );
                break;
            }
          }
        }}
        value={dayPickerDateNumber}
        ref={buttonRef}
      >
        <span aria-hidden="true">{dayPickerDateNumber.toString()}</span>
        <span className="sr-only">
          {format(new Date(dateStringSafariSafe), 'EEEE LLLL d yyyy', {
            locale,
          })}
        </span>
      </button>
    )
  );
}
