export const NUM_ADULT_PARAM_SEARCH_VALUE = 'NumAdults';
export const NUM_CHILD_PARAM_SEARCH_VALUE = 'NumChildren';
export const CHILD_AGE_PARAM_SEARCH_VALUE = 'ChildAges';

export const NUM_ADULT_PARAM_NAME = 'numAdults';
export const NUM_CHILD_PARAM_NAME = 'numChildren';

export const ARRIVAL_DATE_PARAM_NAME = 'arrivalDate';
export const BRAND_CODE_PARAM_NAME = 'brandCode';
export const DEPARTURE_DATE_PARAM_NAME = 'departureDate';
export const FLEXIBLE_DATES_PARAM_NAME = 'flexibleDates';
export const NUM_ROOMS_PARAM_NAME = 'numRooms';
export const ROOM_NUMBER_PARAM_NAME = 'roomNumber';

export const SPECIAL_RATES_AAA_PARAM_NAME = 'aaaRate';
export const SPECIAL_RATES_AARP_PARAM_NAME = 'aarpRate';
export const SPECIAL_RATES_CORPORATE_CODE_PARAM_NAME = 'corporateCode';
export const SPECIAL_RATES_EMPLOYEE_RATE_PARAM_NAME = 'employeeRate';
export const SPECIAL_RATES_GOV_OR_MILITARY_CODE_PARAM_NAME = 'governmentOrMilitaryRate';
export const SPECIAL_RATES_GROUP_CODE_PARAM_NAME = 'groupCode';
export const SPECIAL_RATES_PROMO_CODE_PARAM_NAME = 'promoCode';
export const SPECIAL_RATES_REDEEM_PTS_PARAM_NAME = 'redeemPts';
export const SPECIAL_RATES_SENIOR_RATE_PARAM_NAME = 'seniorRate';
export const SPECIAL_RATES_TRAVEL_AGENT_RATE_PARAM_NAME = 'travelAgentRate';

export const validSpecialRatesKeys = [
  SPECIAL_RATES_AAA_PARAM_NAME,
  SPECIAL_RATES_AARP_PARAM_NAME,
  SPECIAL_RATES_CORPORATE_CODE_PARAM_NAME,
  SPECIAL_RATES_EMPLOYEE_RATE_PARAM_NAME,
  SPECIAL_RATES_GOV_OR_MILITARY_CODE_PARAM_NAME,
  SPECIAL_RATES_GROUP_CODE_PARAM_NAME,
  SPECIAL_RATES_PROMO_CODE_PARAM_NAME,
  SPECIAL_RATES_REDEEM_PTS_PARAM_NAME,
  SPECIAL_RATES_SENIOR_RATE_PARAM_NAME,
  SPECIAL_RATES_TRAVEL_AGENT_RATE_PARAM_NAME,
];

export const ADDITIONAL_QS_ADJOINING_ROOM_STAY_PARAM_NAME = 'adjoiningRoomStay';
export const ADDITIONAL_QS_AMENITIES_PARAM_NAME = 'f-amenityIds';
export const ADDITIONAL_QS_ATTRIBUTE_IDS = 'f-attributeIds';
export const ADDITIONAL_QS_AVAILABLE_HOTELS_ONLY_PARAM_NAME = 'availableHotelsOnly';
export const ADDITIONAL_QS_BRAND_CODE_PARAM_NAME = BRAND_CODE_PARAM_NAME;
export const ADDITIONAL_QS_CID_PARAM_NAME = 'cid';
export const ADDITIONAL_QS_CONTENT_PARAM_NAME = 'content';
export const ADDITIONAL_QS_DISPLAY_CURRENCY_PARAM_NAME = 'displayCurrency';
export const ADDITIONAL_QS_FROM_ID_PARAM_NAME = 'fromId';
export const ADDITIONAL_QS_MAX_POINTS_PARAM_NAME = 'maxPoints';
export const ADDITIONAL_QS_PRICE_RANGE_PARAM_NAME = 'f-price';
export const ADDITIONAL_QS_REDEEM_PTS_PARAM_NAME = SPECIAL_RATES_REDEEM_PTS_PARAM_NAME;
export const ADDITIONAL_QS_REQUESTED_RATES_ONLY_PARAM_NAME = 'requestedRatesOnly';
export const ADDITIONAL_QS_SORT_BY_PARAM_NAME = 'sortBy';
export const ADDITIONAL_QS_SPECIAL_RATES_TOKENS_PARAM_NAME = 'specialRatesTokens';
export const ADDITIONAL_QS_TOKEN_PARAM_NAME = 'token';

export const validAdditionalQsParamNames = [
  ADDITIONAL_QS_ADJOINING_ROOM_STAY_PARAM_NAME,
  ADDITIONAL_QS_AMENITIES_PARAM_NAME,
  ADDITIONAL_QS_AVAILABLE_HOTELS_ONLY_PARAM_NAME,
  ADDITIONAL_QS_BRAND_CODE_PARAM_NAME,
  ADDITIONAL_QS_CID_PARAM_NAME,
  ADDITIONAL_QS_CONTENT_PARAM_NAME,
  ADDITIONAL_QS_DISPLAY_CURRENCY_PARAM_NAME,
  ADDITIONAL_QS_FROM_ID_PARAM_NAME,
  ADDITIONAL_QS_MAX_POINTS_PARAM_NAME,
  ADDITIONAL_QS_REDEEM_PTS_PARAM_NAME,
  ADDITIONAL_QS_REQUESTED_RATES_ONLY_PARAM_NAME,
  ADDITIONAL_QS_SORT_BY_PARAM_NAME,
  // ADDITIONAL_QS_SPEC_PLAN_PARAM_NAME,
  ADDITIONAL_QS_SPECIAL_RATES_TOKENS_PARAM_NAME,
  ADDITIONAL_QS_TOKEN_PARAM_NAME,
];

export const validParamNames = [
  ARRIVAL_DATE_PARAM_NAME,
  BRAND_CODE_PARAM_NAME,
  DEPARTURE_DATE_PARAM_NAME,
  FLEXIBLE_DATES_PARAM_NAME,
  NUM_ROOMS_PARAM_NAME,
  'numAdults',
  'numChildren',
  'room1ChildAges',
  'room1NumAdults',
  'room1NumChildren',
  'room2ChildAges',
  'room2NumAdults',
  'room2NumChildren',
  'room3ChildAges',
  'room3NumAdults',
  'room3NumChildren',
  'room4ChildAges',
  'room4NumAdults',
  'room4NumChildren',
  'room5ChildAges',
  'room5NumAdults',
  'room5NumChildren',
  'room6ChildAges',
  'room6NumAdults',
  'room6NumChildren',
  'room7ChildAges',
  'room7NumAdults',
  'room7NumChildren',
  'room8ChildAges',
  'room8NumAdults',
  'room8NumChildren',
  'room9ChildAges',
  'room9NumAdults',
  'room9NumChildren',
  ROOM_NUMBER_PARAM_NAME,
  'sessionToken',
  ...validSpecialRatesKeys,
  ...validAdditionalQsParamNames,
];
