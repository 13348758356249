import type { MenuProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { VistaTelAvivLogo } from '@curated-property/icons';

const brandLogo = (
  <VistaTelAvivLogo location="header" className="mr-4 hidden w-32 pl-4 sm:block sm:w-44" />
);

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const Header = (props: MenuProps) => {
  return (
    <SharedHeader
      {...props}
      brandLogo={props?.pageSlug === 'the-vista-at-hilton-tel-aviv' ? brandLogo : null}
    />
  );
};
