import * as React from 'react';
import { usePopup } from './popup.context';
import { isRightClick } from './popup.utils';
import { useForkedRef } from '@dx-ui/utilities-use-forked-ref';

type PopupButtonProps = {
  onArrowDownWhenOpen?: () => void;
} & React.HTMLAttributes<HTMLButtonElement>;

const PopupButton = React.forwardRef<HTMLButtonElement, PopupButtonProps>(
  ({ children, onArrowDownWhenOpen, ...rest }, forwardedRef) => {
    const { triggerRef, triggerClickedRef, popupId, isOpen, setOpen } = usePopup();
    const ref = useForkedRef(
      forwardedRef,
      triggerRef as React.MutableRefObject<HTMLButtonElement | null>
    );

    const onKeyDown = (e: React.KeyboardEvent) => {
      switch (e.key) {
        case 'Enter':
        case ' ':
          if (!isOpen) {
            triggerClickedRef.current = true;
          }
          setOpen(!isOpen);
          break;
        case 'ArrowDown':
          e.preventDefault();
          setOpen(true);
          if (isOpen) {
            onArrowDownWhenOpen?.();
          }
          break;
        case 'ArrowUp':
        case 'Escape':
          //we only want to do something when overlay is open otherwise we will trigger parent event handlers
          if (isOpen) {
            //Make sure we dont bubble up to other elements with listeners on escape
            e.stopPropagation();
            setOpen(!isOpen);
          }
          break;
        default:
          break;
      }
    };

    const onMouseDown = (e: React.MouseEvent) => {
      if (!isOpen) {
        triggerClickedRef.current = true;
      }
      if (!isRightClick(e.nativeEvent)) {
        setOpen(!isOpen);
      }
    };

    return (
      <button
        ref={ref}
        type="button"
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={popupId}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

PopupButton.displayName = 'PopupButton';

export { PopupButton };
export default PopupButton;
