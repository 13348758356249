interface GoogleMapPinProps {
  lat: number;
  lng: number;
}

//eslint-disable-next-line
export function GoogleMapPin(props: GoogleMapPinProps) {
  return (
    <div>
      <svg
        className="-translate-x-1/2 -translate-y-full"
        height="38"
        viewBox="0 0 20 34"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="matrix(1.185585 0 0 1.185585 -965.7731 -331.78383)">
          <path
            d="m817.112 282.971c-1.258 1.343-2.046 3.299-2.016 5.139.064 3.845 1.797 5.3 4.568 10.592.998 2.329 2.04 4.792 3.031 8.873.138.602.272 1.161.334 1.209s.196-.513.334-1.115c.991-4.081 2.033-6.543 3.031-8.871 2.771-5.292 4.504-6.747 4.568-10.592.031-1.84-.759-3.798-2.018-5.141-1.437-1.534-3.605-2.669-5.916-2.717s-4.481 1.088-5.918 2.622z"
            fill="#ff4646"
            stroke="#d73534"
          />
          <circle cx="823.031" cy="288.253" fill="#590000" r="3.036" />
        </g>
      </svg>
    </div>
  );
}
