import * as React from 'react';

function setRefs<T>(ref: React.Ref<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    const mutableRef = ref as React.MutableRefObject<T>;
    mutableRef.current = value;
  }
}

export function useForkedRef<ForwardRef, LocalRef extends ForwardRef>(
  forwardedRef: React.Ref<ForwardRef>,
  localRef: React.Ref<LocalRef>
): (instance: LocalRef | null) => void {
  return React.useCallback(
    (value) => {
      setRefs(forwardedRef, value);
      setRefs(localRef, value);
    },
    [forwardedRef, localRef]
  );
}
