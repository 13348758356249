import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Users, AllInclusive, Edit, Meeting } from '@curated-property/icon-list';
import Link from './link-without-prefetch';
import { HandleAnimations } from './functions/helper';
import { makeRandomInt, appliedCloudinaryParams } from '@curated-property/utils';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from './functions/global-instance-styles';
import { format, addDays } from 'date-fns';
import { AnchorLink } from './global/anchor-link';

export function meetingsGroupPropMapper(
  componentData,
  globalData,
  dxGql,
  showMeetingsSimplified,
  ctyhocn,
  brandCode,
  buttonStyles
) {
  return {
    rfpUrl: dxGql?.rfpUrl,
    eEventsUrl: dxGql?.eEventsUrl,
    hiltonLinkUrl: dxGql?.hiltonLinkUrl,
    eEventsUrlOverride: componentData?.eEventsUrlOverride,
    rfpUrlOverride: componentData?.rfpUrlOverride,
    hiltonLinkOverride: componentData?.hiltonLinkOverride,
    ctyhocn,
    brandCode,
    buttonSettings: buttonStyles,
    showMeetingsSimplified,
  };
}

interface MeetingsGroupProps {
  rfpUrl?: string;
  eEventsUrl?: string;
  hiltonLinkUrl?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  eEventsUrlOverride?: string;
  rfpUrlOverride?: string;
  hiltonLinkOverride?: string;
  ctyhocn?: string;
  brandCode?: string;
  showMeetingsSimplified?: boolean;
}

export function MeetingsGroup({
  rfpUrl,
  eEventsUrl,
  hiltonLinkUrl,
  globalStyles,
  instanceStyles,
  eEventsUrlOverride,
  rfpUrlOverride,
  hiltonLinkOverride,
  ctyhocn,
  brandCode,
  showMeetingsSimplified,
}: MeetingsGroupProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const iconColor = inlineStyles?.iconColor;
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const showMeetingsSimplifiedDisplay = showMeetingsSimplified;

  // Set individual links
  const rfpUrlFiltered = !rfpUrlOverride
    ? `https://groups.hilton.com/guest-events?hotel_code=${ctyhocn.slice(
        0,
        5
      )}&tenant_config_id=51e0cf8d-e6c4-4727-8f54-42cde32e48e9`
    : rfpUrlOverride;

  const todayDate = format(new Date(), 'yyyy-MM-dd');
  const tomorrowDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');

  const eEventsUrlFiltered = !eEventsUrlOverride
    ? `https://www.hilton.com/en/group-search/?query=${ctyhocn}&arrivalDate=${todayDate}&departureDate=${tomorrowDate}&brandCode=${
        brandCode.toUpperCase() || 'HI'
      }&numRooms=10`
    : eEventsUrlOverride;

  const hiltonLinkFiltered = !hiltonLinkOverride
    ? 'https://www.hilton.com/en/events/attendee-website/'
    : hiltonLinkOverride;

  // Meetings Simplified
  const meetingsSimplifiedUrl = `https://www.hilton.com/en/group-search/?query=${ctyhocn}&brandCode=${brandCode}&numRooms=10`;

  let textAlignment: string;

  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      textAlignment = 'text-left';
      break;
    case 'center':
      textAlignment = 'text-center';
      break;
    case 'flex-end':
      textAlignment = 'text-right';
      break;
    default:
      textAlignment = 'text-left';
  }

  const cardStyles = {
    iconColor,
    textAlign: textAlignment,
    buttonStyle: inlineStyles?.mtgButtonStyle,
    titleColor: inlineStyles?.titleColor,
    textColor: inlineStyles?.textColor,
  };

  // These will be removed once Design has applied all needed newer button styles.
  const individualButtonStyles = {
    ctaTextColor: inlineStyles?.ctaTextColor,
    ctaTextColorHover: inlineStyles?.ctaTextColorHover,
    ctaBackgroundColor: inlineStyles?.ctaBackgroundColor,
    ctaBackgroundColorHover: inlineStyles?.ctaBackgroundColorHover,
  };

  // Check for at least 3 URLs to determine spacing (this should accommodate more than 3 columns' worth as well)
  const urlArray = [rfpUrl, hiltonLinkUrl, eEventsUrl];
  const urlTest = urlArray.filter((item) => item.length > 0);
  const cardCount = urlTest.length + (showMeetingsSimplifiedDisplay === true ? 1 : 0);

  let spacingClass: string;

  if (cardCount > 3) {
    spacingClass = ' md:grid-cols-2 xl:grid-cols-4 ';
  } else {
    if (cardCount <= 3) {
      spacingClass = cardCount === 3 ? ' md:grid-cols-2 lg:grid-cols-3' : ' md:grid-cols-2';
    }
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });

  return (
    <div
      data-testid="meetings-group-wrapper"
      className={cx('cp-meetingsGroup', paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div ref={animations?.ref} data-osc="meetings-group" className="container">
        <div
          data-testid="meetings-group"
          className={cx('mx-auto mr-4 block md:mr-0 md:grid md:gap-8', 'p-4', spacingClass)}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )}')`
              : '',
            backgroundColor: inlineStyles?.contentBackgroundColor || null,
            backgroundSize: inlineStyles?.contentBackgroundSize || null,
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          }}
        >
          {rfpUrl && (
            <div className={animations?.one}>
              <MeetingCard
                type="rfp"
                cardStyles={cardStyles}
                url={rfpUrlFiltered}
                individualButtonStyles={individualButtonStyles}
              />
            </div>
          )}
          {eEventsUrl && (
            <div className={animations?.two}>
              <MeetingCard
                type="eEvents"
                cardStyles={cardStyles}
                url={eEventsUrlFiltered}
                individualButtonStyles={individualButtonStyles}
              />
            </div>
          )}
          {showMeetingsSimplifiedDisplay && meetingsSimplifiedUrl && (
            <div className={animations?.three}>
              <MeetingCard
                type="meetingsSimplified"
                cardStyles={cardStyles}
                url={meetingsSimplifiedUrl}
                individualButtonStyles={individualButtonStyles}
              />
            </div>
          )}
          {hiltonLinkUrl && (
            <div className={animations?.four}>
              <MeetingCard
                type="hiltonLink"
                cardStyles={cardStyles}
                url={hiltonLinkFiltered}
                individualButtonStyles={individualButtonStyles}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface MeetingCardProps {
  type?: string;
  url?: string;
  cardStyles: {
    buttonStyle?: string;
    iconColor?: string;
    textAlign?: string;
    textColor?: string;
    titleColor?: string;
  };
  individualButtonStyles: {
    ctaBackgroundColor?: string;
    ctaBackgroundColorHover?: string;
    ctaTextColor?: string;
    ctaTextColorHover?: string;
  };
}

export function MeetingCard({ type, url, cardStyles, individualButtonStyles }: MeetingCardProps) {
  const { t } = useTranslation();
  const ctaText = t(`meetingsGroup.${type}CtaText`) || '';
  const iconSize = 'w-12 lg:w-16';

  let icon;
  switch (type) {
    case 'rfp':
      icon = <AllInclusive fillColor={cardStyles?.iconColor} className={iconSize} />;
      break;
    case 'eEvents':
      icon = <Users fillColor={cardStyles?.iconColor} className={iconSize} />;
      break;
    case 'hiltonLink':
      icon = <Edit fillColor={cardStyles?.iconColor} className={iconSize} />;
      break;
    case 'meetingsSimplified':
      icon = <Meeting fillColor={cardStyles?.iconColor} className={iconSize} />;
      break;
  }

  const randomNumberString = !cardStyles?.buttonStyle ? makeRandomInt() : '';

  return (
    <div
      data-testid={`meeting-card-${type.toLowerCase()}`}
      className="mx-auto mb-12 flex  size-full pr-2  sm:w-2/3 md:my-0 md:w-auto "
    >
      <div className="text-text-disabled mr-1 block">{icon}</div>
      <div className="w-full">
        <div className="flex h-full flex-col justify-between">
          <div className="block">
            <h2
              style={{ color: cardStyles?.titleColor }}
              className={cx(
                'mt-2 text-xl font-bold leading-7 md:mt-0 rtl:text-right',
                cardStyles?.textAlign
              )}
            >
              {t(`meetingsGroup.${type}Heading`)}
            </h2>
            <p
              style={{ color: cardStyles?.textColor }}
              className={cx(
                'my-4 text-base leading-6 md:text-lg rtl:text-right',
                cardStyles?.textAlign
              )}
            >
              {t(`meetingsGroup.${type}Description`)}
            </p>
          </div>
          {
            // If a global style type hasn't yet been set, use the existing link styling for any existing CMS style values
            !cardStyles?.buttonStyle ? (
              <Link
                href={url}
                id={`${type}${randomNumberString}`}
                aria-label={`${ctaText}, opens in a new tab`}
                target="_blank"
                rel="noopener noreferrer"
                className={cx(
                  'btn text-text-inverse btn-primary hover:btn-primary-alt flex w-full items-center justify-center py-4 text-center text-lg font-extrabold sm:text-xl lg:py-2'
                )}
                style={{
                  color: individualButtonStyles?.ctaTextColor,
                  backgroundColor: individualButtonStyles?.ctaBackgroundColor,
                }}
                onMouseOver={() => {
                  const btn = document.getElementById(`${type}${randomNumberString}`);
                  btn.style.color = individualButtonStyles?.ctaTextColorHover || null;
                  btn.style.backgroundColor =
                    individualButtonStyles?.ctaBackgroundColorHover || null;
                }}
                onMouseOut={() => {
                  const btn = document.getElementById(`${type}${randomNumberString}`);
                  btn.style.color = individualButtonStyles?.ctaTextColor || null;
                  btn.style.backgroundColor = individualButtonStyles?.ctaBackgroundColor || null;
                }}
                onFocus={() => {
                  const btn = document.getElementById(`${type}${randomNumberString}`);
                  btn.style.color = individualButtonStyles?.ctaTextColorHover || null;
                  btn.style.backgroundColor =
                    individualButtonStyles?.ctaBackgroundColorHover || null;
                }}
                onBlur={() => {
                  const btn = document.getElementById(`${type}${randomNumberString}`);
                  btn.style.color = individualButtonStyles?.ctaTextColor || null;
                  btn.style.backgroundColor = individualButtonStyles?.ctaBackgroundColor || null;
                }}
              >
                {ctaText}
              </Link>
            ) : (
              <AnchorLink
                url={url}
                buttonStyle={cx(cardStyles?.buttonStyle || 'primary')}
                target="_blank"
                title={ctaText}
                className="!sm:text-center flex items-center justify-center !text-center"
                omitIcon={true}
              />
            )
          }
        </div>
      </div>
    </div>
  );
}
