export function Snorkel({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0.00005v24h24v-24L0,0.00005z" />
        </g>
        <g>
          <path
            d="M15,5.625H9C8.07689,5.62943,7.17133,5.87767,6.375,6.3446V4c0-0.20711-0.16789-0.375-0.375-0.375S5.625,3.79289,5.625,4
			v12C5.62649,17.31106,6.68894,18.37351,8,18.375h1.625v1.25H8C5.99885,19.62287,4.37713,18.00115,4.375,16V4
			c0-0.20711-0.16789-0.375-0.375-0.375C3.79289,3.625,3.625,3.79289,3.625,4v12C3.62793,18.41503,5.58497,20.37207,8,20.375h6
			c0.20708-0.00006,0.37494-0.16792,0.375-0.375v-2c-0.00006-0.20708-0.16792-0.37494-0.375-0.375H8
			C7.10283,17.62431,6.37569,16.89717,6.375,16v-0.95575C6.88933,15.26132,7.44174,15.37376,8,15.375h0.5
			c1.71813-0.00207,3.16133-1.29276,3.35449-3h0.291c0.19316,1.70724,1.63636,2.99793,3.35449,3H16
			c2.41503-0.00293,4.37207-1.95997,4.375-4.375C20.37169,8.03284,17.96716,5.62831,15,5.625z M13.625,19.625h-3.25v-1.25h3.25
			V19.625z M16,14.625h-0.5c-1.44902-0.00175-2.62325-1.17598-2.625-2.625c-0.00006-0.20708-0.16792-0.37494-0.375-0.375h-1
			c-0.20708,0.00006-0.37494,0.16792-0.375,0.375c-0.00175,1.44902-1.17598,2.62325-2.625,2.625H8
			c-0.56714-0.0048-1.12469-0.14685-1.625-0.414v-0.98816C6.81307,13.64046,7.39477,13.87391,8,13.875h1.5
			c0.20711,0,0.375-0.16789,0.375-0.375S9.70711,13.125,9.5,13.125H8c-0.89717-0.0007-1.62431-0.72783-1.625-1.625V11
			C6.37675,9.55098,7.55098,8.37675,9,8.375h6c1.44902,0.00175,2.62325,1.17598,2.625,2.625v0.5
			c-0.00069,0.89717-0.72783,1.6243-1.625,1.625h-1.5c-0.20711,0-0.375,0.16789-0.375,0.375s0.16789,0.375,0.375,0.375H16
			c1.31106-0.00149,2.37351-1.06394,2.375-2.375V11C18.37314,9.13681,16.86319,7.62686,15,7.625H9
			C7.97555,7.62854,7.00882,8.09981,6.375,8.90466V7.23358C7.13966,6.68183,8.05709,6.38176,9,6.375h6
			c2.55299,0.0032,4.62181,2.07201,4.625,4.625C19.62287,13.00115,18.00115,14.62287,16,14.625z"
          />
        </g>
      </g>
    </svg>
  );
}
