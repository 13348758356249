import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings, multiColumnTilesWithHoverSettings } from './common-vars';

export const ImageReveal = gql`
fragment ImageReveal on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_ImageReveal {
        fieldGroupName
        header
        columnCount
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          button {
            target
            title
            url
          }
          showHide
        }
        imageRevealComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${multiColumnTilesWithHoverSettings}
        }
      }
}
`;
