export function WaterBottle({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10,5.375A.3751.3751,0,0,0,10.375,5V4A.6256.6256,0,0,1,11,3.375h2A.6256.6256,0,0,1,13.625,4V5a.375.375,0,0,0,.75,0V4A1.377,1.377,0,0,0,13,2.625H11A1.377,1.377,0,0,0,9.625,4V5A.3751.3751,0,0,0,10,5.375Z" />
          <path d="M15.9374,12a1.3679,1.3679,0,0,0,.4376-1c0-1.5668-1.61-3.6038-2.5139-4.625H15a.375.375,0,0,0,0-.75H9a.375.375,0,0,0,0,.75h1.139C9.2349,7.3962,7.625,9.4332,7.625,11a1.3679,1.3679,0,0,0,.4376,1,1.3614,1.3614,0,0,0,0,2,1.3614,1.3614,0,0,0,0,2,1.3679,1.3679,0,0,0-.4376,1v2A2.3777,2.3777,0,0,0,10,21.375h4A2.3777,2.3777,0,0,0,16.375,19V17a1.3679,1.3679,0,0,0-.4376-1,1.3614,1.3614,0,0,0,0-2,1.3614,1.3614,0,0,0,0-2ZM12,12.375h3a.625.625,0,0,1,0,1.25H12a.375.375,0,0,0,0,.75h3a.625.625,0,0,1,0,1.25H12a.375.375,0,0,0,0,.75h3a.6256.6256,0,0,1,.625.625v2A1.6262,1.6262,0,0,1,14,20.625H10A1.6262,1.6262,0,0,1,8.375,19V17A.6256.6256,0,0,1,9,16.375h1a.375.375,0,0,0,0-.75H9a.625.625,0,0,1,0-1.25h1a.375.375,0,0,0,0-.75H9a.625.625,0,0,1,0-1.25h1a.375.375,0,0,0,0-.75H9A.6256.6256,0,0,1,8.375,11c0-1.4557,1.9235-3.7383,2.7854-4.625H12.84c.8619.8867,2.7854,3.1693,2.7854,4.625a.6256.6256,0,0,1-.625.625H12a.375.375,0,0,0,0,.75Z" />
        </g>
      </g>
    </svg>
  );
}
