import { type SVGAttributes } from 'react';

const BusinessCenterIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M21.747 31.022a1.396 1.396 0 1 1 0-2.791 1.396 1.396 0 0 1 0 2.791" />
        <path
          d="M13.374 37.604c0-.769.591-1.395 1.318-1.395h14.11c.727 0 1.319.626 1.319 1.395V39H13.374v-1.396z"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M5.698 32.418A.698.698 0 0 1 5 31.72V6.6c0-.386.313-.699.698-.699h32.098c.386 0 .698.313.698.698v25.12a.698.698 0 0 1-.698.699H5.698z"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M9.439 26.534a.648.648 0 0 1-.648-.648V10.34c0-.357.29-.647.648-.647h24.614c.358 0 .648.29.648.647v15.546c0 .358-.29.648-.648.648H9.44z"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path d="M25 35.209v-2.791M18 35.209v-2.791" strokeWidth="1.5" strokeLinecap="round" />
      </g>
    </svg>
  );
};

export default BusinessCenterIcon;
