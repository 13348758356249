import { Image } from './utils';
import type { CaptionProps } from '@dx-ui/osc-caption';
import type { ReactNode } from 'react';
import cx from 'classnames';

import { ANALYTICS_GLOBAL_CLICK_EVENT, ImageFunction, trackEvent } from '@dx-ui/config-metrics';
import { generateOffsetMetrics } from './utils/offset-analytics';
import { getReactNodeInnerText } from '@dx-ui/utilities-get-react-node-inner-text';

const OFFSET_LARGE_IMAGE_POSITION = 1;
const OFFSET_SMALL_IMAGE_POSITION = 2;

export type OffsetImage = { alt: string; src: string; captionData?: CaptionProps };

export interface TOffset {
  image: { small: OffsetImage; large: OffsetImage };
  headline: ReactNode;
  /**
   * Content and images are displayed in the reverse order
   */
  isInversePattern?: boolean;
  /** Add Tailwind classes to root element */
  className?: string;
}

export const Offset: React.FC<React.PropsWithChildren<TOffset>> = ({
  image,
  headline,
  children,
  isInversePattern = false,
  className = '',
}) => {
  const innerString = getReactNodeInnerText(headline);
  const handleOffsetMetric = (image: OffsetImage, position: number) =>
    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateOffsetMetrics({
        headline: innerString,
        itemTitle: image.alt,
        imageFunction: ImageFunction.Link,
        position,
      })
    );
  return (
    <div
      className={cx('relative', {
        [className]: !!className,
      })}
    >
      <div
        className={cx('grid gap-8 md:grid-rows-3 md:gap-6 lg:gap-8', {
          'md:grid-cols-[2fr_1fr]': isInversePattern,
          'md:grid-cols-[1fr_2fr]': !isInversePattern,
        })}
      >
        <div
          className={cx('self-end md:translate-y-20 lg:translate-y-14 xl:translate-y-0', {
            'md:order-2': isInversePattern,
          })}
        >
          {headline}
        </div>
        <div
          className={cx('md:row-span-2 md:self-end', {
            'md:order-1': isInversePattern,
          })}
        >
          {image?.large ? (
            <Image
              {...image.large}
              aspectRatio="4:2"
              onClick={() => handleOffsetMetric(image.large, OFFSET_LARGE_IMAGE_POSITION)}
            />
          ) : null}{' '}
        </div>
        <div
          className={cx('self-center md:row-span-2', {
            'md:order-3': isInversePattern,
          })}
        >
          {image?.small ? (
            <Image
              {...image.small}
              aspectRatio="4:2"
              onClick={() => handleOffsetMetric(image.small, OFFSET_SMALL_IMAGE_POSITION)}
            />
          ) : null}{' '}
        </div>
        <div className={cx({ 'md:order-4': isInversePattern })}>{children}</div>
      </div>
    </div>
  );
};

export default Offset;
