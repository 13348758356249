import { forwardRef } from 'react';

const FormErrorIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  ({ width = 16, height = 16, ...rest }, ref) => (
    <svg
      ref={ref}
      role="img"
      aria-hidden
      height={height}
      width={width}
      viewBox="0 0 24 24"
      {...rest}
    >
      <g fill="currentcolor">
        <circle cx="12" cy="12" r="12" />
      </g>
      <g transform="translate(8.000000, 8.000000)" stroke="#FFFFFF" strokeLinecap="square">
        <path d="M1,1 L7.00679887,7.00679887" strokeWidth="2" />
        <path d="M1,7 L7.00679887,0.993201131" strokeWidth="2" />
      </g>
    </svg>
  )
);

FormErrorIcon.displayName = 'FormErrorIcon';

export { FormErrorIcon };
export default FormErrorIcon;
