import * as React from 'react';
import * as Tabs from '@radix-ui/react-tabs';

export interface FilterPanelProps {
  children: React.ReactNode;
  className?: string;
  value: string;
}

export const FilterPanel = React.forwardRef<HTMLDivElement, FilterPanelProps>(
  function FilterPannelInner({ className, value, children }, ref) {
    return (
      <Tabs.Content
        tabIndex={-1}
        className={`exclude-focus ${className}`}
        data-testid={`${value}-panel`}
        value={value}
        ref={ref}
      >
        {children}
      </Tabs.Content>
    );
  }
);
