export function RoomService({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15.8867,18.6768l-4.7256-2.83-3.0442-3.42H8.855l2.9145,2.12a2.6585,2.6585,0,0,0,1.1182.2285,3.2257,3.2257,0,0,0,1.7256-.5176.3736.3736,0,0,0,.1562-.1963l.5619-1.6347h.6894L15.75,14.2617A2.4984,2.4984,0,0,0,16.5,16.41a.375.375,0,0,0,.5254-.5352,1.7515,1.7515,0,0,1-.5361-1.4873l.29-1.9609h2.2991a.375.375,0,0,0,0-.75H5a.375.375,0,0,0,0,.75H7.113l3.5257,3.9609a.3721.3721,0,0,0,.0879.0723l4.7754,2.8593a.3744.3744,0,0,0,.3847-.6425Zm-1.3481-6.25-.4331,1.2607a2.2926,2.2926,0,0,1-1.9463.2207v.001l-2.0327-1.4824Z" />
          <path d="M20.1377,16.5078,18.3,15.7832a.3711.3711,0,0,0-.2891.0059.3783.3783,0,0,0-.1992.208l-1.59,4.1435a.3755.3755,0,0,0,.2129.4834l1.8379.7246a.3782.3782,0,0,0,.1377.0264.3749.3749,0,0,0,.1377-.7236l-1.4922-.5889,1.3213-3.4434,1.4853.586a.3749.3749,0,0,0,.2754-.6973Z" />
          <path d="M10.3193,5.53a4.29,4.29,0,0,0-2.1982,2.122.3749.3749,0,0,0,.1846.4971.3688.3688,0,0,0,.1562.0342.3753.3753,0,0,0,.3408-.2188A3.5409,3.5409,0,0,1,10.62,6.2178a.3752.3752,0,1,0-.3008-.6875Z" />
          <path d="M5.5732,10.9023a.375.375,0,0,0,.375-.375,6.2112,6.2112,0,0,1,6.0909-6.0146A6.2111,6.2111,0,0,1,18.13,10.5273a.375.375,0,0,0,.75,0,6.9858,6.9858,0,0,0-6.4192-6.742.6582.6582,0,1,0-.8433,0,6.9858,6.9858,0,0,0-6.4192,6.742A.3751.3751,0,0,0,5.5732,10.9023ZM12.0391,3.2a.09.09,0,0,1,.0918.0869c0,.0967-.1836.0957-.1836,0A.0908.0908,0,0,1,12.0391,3.2Z" />
        </g>
      </g>
    </svg>
  );
}
