import type { StyleObject } from '@curated-property/shared-components';
import { appliedCloudinaryParams } from './helper';

type BgStyles = Omit<StyleObject, 'componentBackgroundImage' | 'contentBackgroundImage'> & {
  componentBackgroundImage?: string;
  contentBackgroundImage?: string;
};

export function inlineBackgroundStyles(styles: BgStyles, type: 'component' | 'content') {
  const backgroundImage =
    type === 'component' ? styles?.componentBackgroundImage : styles?.contentBackgroundImage;
  const backgroundColor =
    type === 'component' ? styles?.componentBackgroundColor : styles?.contentBackgroundColor;
  const backgroundSize =
    type === 'component' ? styles?.componentBackgroundSize : styles?.contentBackgroundSize;
  const backgroundRepeat =
    type === 'component' ? styles?.componentBackgroundRepeat : styles?.contentBackgroundRepeat;
  const backgroundPosition =
    type === 'component' ? styles?.componentBackgroundPosition : styles?.contentBackgroundPosition;

  const applyCloudinaryParams = `url('${appliedCloudinaryParams(backgroundImage ?? '')}')`;

  const obj = {
    backgroundImage: backgroundImage ? applyCloudinaryParams : undefined,
    backgroundColor: backgroundColor ? backgroundColor : undefined,
    backgroundSize: backgroundSize ? backgroundSize : undefined,
    backgroundRepeat: backgroundRepeat ? backgroundRepeat : undefined,
    backgroundPosition: backgroundPosition ? backgroundPosition : undefined,
  };

  return obj;
}
