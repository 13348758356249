import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor: string }>(() =>
  import('./rbacici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#bc9b6a" className="w-32 fill-current text-current antialiased md:w-40" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#000000" className="w-32 fill-current text-current  antialiased md:w-36" />
);
