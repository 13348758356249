import { createElement } from 'react';

export interface TagType extends React.HTMLAttributes<HTMLElement> {
  figureTag: 'figure' | 'div' | 'span';
  children: React.ReactNode;
}

export const ElementSelector: React.FC<TagType> = ({ figureTag, children, ...props }) => {
  return createElement(figureTag, props, children);
};
