import gql from 'graphql-tag-transform.macro';
import { tabbedTableDataSettings } from './common-vars';

export const TabbedTableData = gql`
fragment TabbedTableData on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_OnPageMenus {
        fieldGroupName
        header
        menuRepeater {
          fieldGroupName
          menuName
          gallery {
            altText
            sourceUrl
            mediaDetails {
              height
              width
            }
          }
          contentSelection
          tableHeadingSpan {
            text
            columnSpan
          }
          menuColumn {
            fieldGroupName
            menuHeader
            menuItems
          }
          sectionTableColumns {
            column1
            column2
            column3
            column4
            column5
            column6
            column7
            column8
          }
        }
        onPageMenuSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${tabbedTableDataSettings}
        }
      }
}
`;
