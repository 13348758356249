import { useContext, useState, useEffect } from 'react';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { format, getDay, getDaysInMonth, getMonth, getYear, startOfMonth } from 'date-fns';
import { weekStartsOn } from '@dx-ui/framework-i18n';
import { useLocale } from '@dx-ui/utilities-dates';
import EventCalendarDay from './event-calendar-day';
import EventCalendarDatePicker from './datepicker/event-calendar-date-range-picker';
import EventCalendarDatePickerDay from './datepicker/event-calendar-date-picker-day';
import { EventCalendarDaysOfWeekRow } from './event-calendar-days-of-week-row';
import { useWindowSize } from '../functions/helper';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { makeRandomInt, sanitize } from '@curated-property/utils';
import { breakToColumnWidth } from './lib/event-calendar-props';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array } from '../functions/global-instance-styles';
import { dayKeys } from './lib/event-calendar-constants';
import { multiDateWidth } from './event-calendar-helpers';
import type { Locales, Categories, SpecialEventPropsForType } from './lib/event-calendar-props';
import { useRouter } from 'next/router';

interface EventCalendarMonthProps {
  monthToDisplay: Date;
  selectedLocales?: Array<Locales>;
  selectedCategories?: Array<Categories>;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function EventCalendarMonth({
  monthToDisplay,
  selectedLocales,
  selectedCategories,
  instanceStyles,
  globalStyles,
}: EventCalendarMonthProps) {
  const { locale: localeStr = 'en' } = useRouter();
  const locale = useLocale({ language: localeStr });
  const currentMonth = startOfMonth(monthToDisplay);
  // The first day of week realtive to a 7-day array, e.g. 5 = Friday
  const firstDayOfMonthNumber = getDay(currentMonth);
  // Total days in given month (i.e. 30 or 31)
  const daysInMonth = getDaysInMonth(monthToDisplay);
  const hotelInfoContext: any = useContext(EventCalendarInfoContext);
  const monthWeekList = [];
  const monthWeekListForDatePicker = [];
  let specialEventArray: SpecialEventPropsForType[] = [];
  let individualWeek = [];
  let individualWeekForDatePicker = [];
  // This holds the scheduling info for the mobile/single-column view
  const allDaysCombined = [];
  const specialEvents = hotelInfoContext?.specialEvents;
  const regularlyScheduledEventArray = hotelInfoContext?.recurringEvents;

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  // 0 = start of date range, 1 = end of date range. A range will only display when a beginning and end value are selected.
  const [selStart, setSelStart] = useState<number | null>(null);
  const [selEnd, setSelEnd] = useState<number | null>(null);

  const { t } = useTranslation();
  const device = useWindowSize();

  function clearSelectedMobileDates() {
    setSelStart(null);
    setSelEnd(null);
  }

  function multiDateBtnWidthDebounce(func: UIEvent, timeout: number = 50) {
    let timer: NodeJS.Timeout | undefined;

    device?.width > breakToColumnWidth && multiDateWidth();
    // Close timeout
    return function _() {
      clearTimeout(timer);
      timer = setTimeout(function _() {
        // eslint-disable-next-line
        // @ts-ignore
        func.apply(this);
      }, timeout);
    };
  }

  // Set the multi-date button width sizing control
  useEffect(() => {
    device?.width > breakToColumnWidth && multiDateWidth();
    window.addEventListener('resize', multiDateBtnWidthDebounce);
  });

  useEffect(() => {
    clearSelectedMobileDates();
  }, [monthToDisplay]);

  // Sorts and applies a range of dates to show in the mobile presentation of the calendar

  /* A second date can be added to make a range, but that date must be after the orignal selection, and by the end of the month (since only one month is selected at a time) */
  const whichDaysToShow = (btnVal: number) => {
    if (!selStart) {
      setSelStart(btnVal);
      setSelEnd(null);
    } else {
      // Scenarios

      // Start & End have values (presumably set via earlier selections) but the new val is greater than the highest selected value
      if (selStart && selEnd) {
        if (btnVal < selStart) {
          setSelEnd(null);
          setSelStart(btnVal);
        }
        // Extend date range
        else if (btnVal > selEnd) {
          setSelEnd(btnVal);
        }
      }

      // If one date already selected
      else if (selStart && !selEnd) {
        if (btnVal > selStart) {
          setSelEnd(btnVal);
        } else if (btnVal < selStart) {
          setSelStart(btnVal);
        }
      }
    }
  };

  // Numericfor JS Date array – 0,1,2 = January, February, March
  const dateMonth = getMonth(currentMonth);
  // 01, 02, 03, etc
  const dateMM = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(currentMonth);
  // Year as yyyy ex: 2022
  const dateYYYY = getYear(currentMonth);
  // Establish Month View of Grid
  const gridCount = firstDayOfMonthNumber + daysInMonth <= 35 ? 35 : 42;

  let multDateEventsThisWeek: number[] = [];

  // Get first day of week based on locale using framework utility
  const firstDayOfWeek = weekStartsOn(locale);

  // Adjust firstDayOfMonthNumber based on locale's first day of week
  const adjustedFirstDayOfMonth = (firstDayOfMonthNumber + 7 - firstDayOfWeek) % 7;

  // Build event array(s) by day and date
  for (let i = 0; i < gridCount; i++) {
    // Make date number for display and fill appropriately
    const placePointerMax = daysInMonth + adjustedFirstDayOfMonth;

    const iteratedDateNum = i + 1 - adjustedFirstDayOfMonth;

    const dateNumber =
      adjustedFirstDayOfMonth <= i && i + 1 <= placePointerMax ? iteratedDateNum : null;

    // Full month name for Month selection (April, May, June, etc.)
    const monthName = currentMonth.toLocaleString(locale, { month: 'long' });
    // Make 2-digit date number (01, 02, 03, etc.)
    const dateDD = dateNumber && dateNumber < 10 ? `0${dateNumber}` : dateNumber;

    // Null or yyyymmdd
    const dateString: string | null = dateNumber !== null ? `${dateYYYY}${dateMM}${dateDD}` : null;

    // Complete Date Object (example: Mon Sep 12 2022 00:00:00 GMT-0500 (CDT) )
    const dateObj = dateNumber !== null ? new Date(dateYYYY, dateMonth, dateNumber) : null;

    // JS Date day key (0 to 6, or Sunday to Saturday)
    const dayOfWeekKey = dateObj !== null ? dateObj.getDay() : null;

    // Current date in yyyymmdd format
    const todayDate = format(new Date(), 'yyyyMMdd');

    // Determine whether a date button should be disabled based on whether its displayed date has already passed
    const disableState: boolean =
      dateObj !== null && parseInt(`${dateYYYY}${dateMM}${dateDD}`) < parseInt(todayDate)
        ? true
        : false;

    // Individual special events – test provided dates against given date range to set displayed special events.
    specialEvents?.forEach((el: SpecialEventPropsForType) => {
      const startDateFromDataStr = el?.node?.calendarOfEvents?.startDate;
      const endDateFromDataStr = el?.node?.calendarOfEvents?.endDate;
      // If this is a multi-date event
      if (startDateFromDataStr && endDateFromDataStr && dateString !== null) {
        if (dateString >= startDateFromDataStr && dateString <= endDateFromDataStr) {
          specialEventArray.push(el);
          // Add id to this week's mult-date events
          const idNum = el?.node?.calendarId;
          if (typeof idNum === 'number') {
            !multDateEventsThisWeek.includes(idNum) && multDateEventsThisWeek.push(idNum);
          }
        }
      } else {
        // If this is a single-date event
        if (startDateFromDataStr && !endDateFromDataStr) {
          startDateFromDataStr === dateString && specialEventArray.push(el);
        }
      }
    });
    // Control display classes for mobile datepicker

    //@Set Color status -- selected, in selection range, normal, disabled.
    let btnClass = '';
    // Eval date selection
    if (
      dateNumber &&
      selStart &&
      selStart !== undefined &&
      dateNumber.toString() === selStart.toString()
    )
      btnClass = 'bg-primary text-white';

    if (dateNumber && selEnd && dateNumber.toString() === selEnd.toString())
      btnClass = 'bg-primary text-white';

    // Dates within a selection range
    if (
      dateNumber &&
      selStart &&
      selEnd &&
      selEnd > selStart + 1 &&
      dateNumber > selStart &&
      dateNumber < selEnd
    )
      btnClass = 'bg-secondary text-white';

    // Disabled
    disableState === true && (btnClass = 'text-text-disabled');

    // Date string yyyy-mm-dd
    const dateStringNom = dateNumber === null ? '' : `${dateYYYY}-${dateMM}-${dateDD}`;

    individualWeek.push(
      <EventCalendarDay
        key={`iW${!dateNumber ? makeRandomInt(3).toString() : dateNumber.toString()}`}
        dateStringNoDashes={dateString}
        dateStringNom={dateStringNom}
        monthName={monthName}
        dateNumber={dateNumber}
        specialEventEntryArray={specialEventArray}
        regularEventsArray={regularlyScheduledEventArray}
        dayOfWeekKey={dayOfWeekKey}
        selectedLocales={selectedLocales}
        selectedCategories={selectedCategories}
        disabledState={disableState}
        multiDateEventsInWeek={multDateEventsThisWeek}
      />
    );

    individualWeekForDatePicker.push(
      <EventCalendarDatePickerDay
        key={`dP${!dateNumber ? makeRandomInt(3).toString() : dateNumber.toString()}`}
        dateStringNom={dateStringNom}
        btnClass={btnClass}
        dayPickerDateNumber={dateNumber}
        dateSelection={whichDaysToShow}
        disabledState={disableState}
        currentMonth={monthToDisplay}
      />
    );

    dateStringNom &&
      disableState === false &&
      allDaysCombined.push(
        <EventCalendarDay
          key={`all${i.toString()}${makeRandomInt(3).toString()}`}
          dateStringNoDashes={dateString}
          dateStringNom={dateStringNom}
          monthName={monthName}
          dateNumber={dateNumber}
          specialEventEntryArray={specialEventArray}
          regularEventsArray={regularlyScheduledEventArray}
          dayOfWeekKey={dayOfWeekKey}
          selectedLocales={selectedLocales}
          selectedCategories={selectedCategories}
          disabledState={disableState}
          multiDateEventsInWeek={multDateEventsThisWeek}
        />
      );
    // Empty the given day's array
    specialEventArray = [];
    // Supply entry values for both the Desktop Table and smaller mobile datepicker
    if ((i + 1) % 7 === 0) {
      // Only add the week if it contains at least one date
      const hasAnyDates = individualWeek.some((day) => day.props.dateNumber !== null);

      if (hasAnyDates) {
        monthWeekList.push(
          <EventCalendarWeek
            key={`dskTop${i.toString()}`}
            dateEntries={individualWeek}
            displayType="desktop"
          />
        );

        monthWeekListForDatePicker.push(
          <EventCalendarWeek
            key={`dtPkr${i.toString()}`}
            dateEntries={individualWeekForDatePicker}
            displayType="mobile"
          />
        );
      }
      multDateEventsThisWeek = [];
      individualWeek = [];
      individualWeekForDatePicker = [];
    }
  }

  return device?.width <= breakToColumnWidth ? (
    <>
      <EventCalendarDatePicker dateList={monthWeekListForDatePicker} />
      <div className={cx('mx-auto flex w-full justify-end sm:w-2/3')}>
        <button
          aria-hidden="true"
          id="clearEventDatepicker"
          data-testid="clearEventDatepicker"
          className={cx('btn btn-secondary my-2 border-none px-2 py-1 text-xs')}
          onClick={() => {
            clearSelectedMobileDates();
          }}
          style={{
            color: inlineStyles?.eventsCalDatePickerResetButtonText,
            backgroundColor: inlineStyles?.eventsCalDatePickerResetButtonBg,
          }}
        >
          {t('calendar.clearDateSelections')}
        </button>
      </div>
      <div className="mx-auto w-full sm:w-2/3" tabIndex={0}>
        <h2
          className="sr-only my-1 font-bold"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              selStart
                ? t('calendar.viewSelectedDateEventsForNamedMonth', {
                    nameOfMonth: new Intl.DateTimeFormat(locale, { month: 'long' }).format(
                      currentMonth
                    ),
                  })
                : t('calendar.viewEventsForNamedMonth', {
                    nameOfMonth: new Intl.DateTimeFormat(locale, { month: 'long' }).format(
                      currentMonth
                    ),
                  })
            ),
          }}
        />
        <h2
          aria-hidden="true"
          className="my-1 font-bold"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              selStart ? t('calendar.viewSelectedDateEvents') : t('calendar.viewThisMonthsEvents')
            ),
          }}
        />
      </div>

      <ul
        id="mobileEventDateList"
        data-testid="mobileEventList"
        role="list"
        className="mx-auto w-full sm:w-2/3"
        key={`mobileList${dateMM.toString()}`}
      >
        {allDaysCombined?.map((i, e) => {
          const dateMbl = parseInt(i?.props?.dateNumber);

          let showInMobile = true;
          // Determine date range status. If a date is selected in date picker, then begin evaluation

          // Single date selected
          if (selStart === null && selEnd === null) {
            showInMobile = true;
          } else {
            if (selStart !== null) {
              // If there is 1 date selected
              if (selEnd === null) {
                showInMobile = selStart === dateMbl ? true : false;
              }
              // A range or at least 2 dates are selected
              if (selEnd !== null) {
                showInMobile = dateMbl >= selStart && dateMbl <= selEnd ? true : false;
              }
            }
          }
          const dateCheck: number | null = i?.props?.dateStringNoDashes
            ? parseInt(i?.props?.dateStringNoDashes)
            : null;

          // Check each array against dateCheck to determine if there any entries to show for this particular date. If not, don't include the date in mobile list.
          //const regEventsMobile = i?.props?.regularEventsArray?.some(event => )
          const regEventsToCheck =
            i?.props?.regularEventsArray?.length > 0 ? i?.props?.regularEventsArray : null;
          const splEventsToCheck =
            i?.props?.specialEventEntryArray?.length > 0 ? i?.props?.specialEventEntryArray : null;

          // Regular events

          let isScheduledReg = false;

          regEventsToCheck?.forEach((item) => {
            item?.forEach((subItem) => {
              const daySchedules = subItem?.node?.RegularlyScheduledEvents;

              // Is this within a date range
              let isInDateRange = false;
              if (daySchedules?.eventDisplayStartDate || daySchedules?.eventDisplayEndDate) {
                const start = parseInt(daySchedules?.eventDisplayStartDate) || null;
                const end = parseInt(daySchedules?.eventDisplayEndDate) || null;
                // Validate that the date range is active

                // Scenario: Has a start date but no end date
                if (start && !end && dateCheck !== null && start <= dateCheck) isInDateRange = true;

                // Scenario: Has an end date only
                if (end && !start && end >= dateCheck && dateCheck !== null) isInDateRange = true;

                // Scenario: Has both a start and end date for its run
                if (start && end && dateCheck !== null && start <= dateCheck && end >= dateCheck)
                  isInDateRange = true;
              } else {
                // If date range is infinity then event is valid
                if (!daySchedules?.eventDisplayStartDate && !daySchedules?.eventDisplayEndDate) {
                  isInDateRange = true;
                }
              }

              // If isInDateRange is true, validate to see if date should be showing this day of the week.
              if (isInDateRange) {
                // Event is held daily and therefore this particular date in mobile should be included to display the event
                if (daySchedules?.allDays?.heldDaily) {
                  isScheduledReg = true;
                  return;
                }

                // Check specific days against current day of week to see if the day has an event scheduled

                const todayDayOfWeek = dayKeys?.[i?.props?.dayOfWeekKey].toString();
                if (daySchedules?.[todayDayOfWeek]?.scheduled) {
                  isScheduledReg = true;
                  return;
                }
              }
            });
          });

          // Special, one-off events (including multi-date events)

          let isScheduledSpecial = false;

          splEventsToCheck?.forEach((element) => {
            const start = element?.node?.calendarOfEvents?.startDate;
            const end = element?.node?.calendarOfEvents?.endDate;

            // Conditions that set this to true and can break the loop, since the <li> can show based on this critereia
            // Individual date
            if (parseInt(start) === dateCheck && !end) {
              isScheduledSpecial = true;
              return;
            }

            // Multi-date check
            if (start && end && dateCheck) {
              if (parseInt(start) <= dateCheck && parseInt(end) >= dateCheck) {
                isScheduledSpecial = true;
                return;
              }
            }
          });

          return isScheduledReg || isScheduledSpecial ? (
            <li
              tabIndex={0}
              id={`dayDiv${i?.props?.dateNumber}`}
              className={cx('border  p-2', showInMobile ? 'block' : 'hidden')}
              data-mobile={i?.props?.dateNumber}
              key={`singleList${e}${dateMM.toString()}`}
            >
              {i}
            </li>
          ) : (
            ''
          );
        })}
      </ul>
    </>
  ) : (
    <table
      data-testid="calendar-month"
      className="w-full table-fixed"
      role="grid"
      tabIndex={0}
      style={{
        backgroundColor: inlineStyles?.eventsCalendarDesktopGridBgColor,
      }}
      id="desktopCalendar"
    >
      <EventCalendarDaysOfWeekRow />
      <tbody>
        {monthWeekList?.map((i) => {
          return i;
        })}
      </tbody>
    </table>
  );
}

interface EventCalendarWeekProps {
  dateEntries?: any;
  displayType?: string;
  multiDateEventsInWeek?: number;
}

function EventCalendarWeek({ dateEntries, displayType }: EventCalendarWeekProps) {
  const displayTypeClass = displayType === 'mobile' ? 'p-0 text-center m-0' : 'p-2 align-top h-24';

  return (
    <tr>
      {dateEntries?.map((i, e) => {
        const random = makeRandomInt(4).toString();
        return (
          <EventCalendarWeekday
            key={`${e.toString()}${random}`}
            content={i}
            displayClass={displayTypeClass}
          />
        );
      })}
    </tr>
  );
}

// Event calendar day as a component to account for unique key props
interface EventCalendarWeekdayProps {
  content?: any;
  displayClass?: string;
}

function EventCalendarWeekday({ content, displayClass }: EventCalendarWeekdayProps) {
  return (
    <td className={cx('box-border border border-[#d5d5d5] text-xs', displayClass)}>{content}</td>
  );
}
