import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./olbbrqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-32  fill-current antialiased lg:w-40" fillColor="#4e3629" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-32  fill-current antialiased lg:w-40" fillColor="#4e3629" />
);
