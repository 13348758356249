import type { SearchDetailsDataProps } from '../types/types';
import set from 'lodash/set';

export const createPropertySearchDetailsString = (props: SearchDetailsDataProps): string => {
  const searchDetailsString = `RM:${props.numRooms}|AD:${props.numAdults}|CH:${
    props.numChildren
  }|CC:${props?.currencyCode || 'LOCAL'}${props?.datesFlex ? '|FLX' : ''}${
    props?.isUsePoints ? '|PT' : ''
  }${props?.promoCode ? '|PRO' : ''}${props?.groupCode ? '|GRP' : ''}${
    props?.corporateCode ? '|CO' : ''
  }${props?.isTravelAgents ? '|AG' : ''}${props?.isTripleA ? '|AAA' : ''}${
    props?.isAarp ? '|ARP' : ''
  }${props?.isSenior ? '|SEN' : ''}${props?.isGovernment ? '|GV' : ''}${
    !!props?.isOwner || !!props?.isHGVOwner ? '|ON' : ''
  }${props?.isEmployee ? '|TM' : ''}${props?.isFriendsAndFamily ? '|FF' : ''}${
    props?.isSMB ? '|SMB' : ''
  }${props?.isCBP ? '|CBP' : ''}`;
  return searchDetailsString;
};

export const setDigitalData = (path: string, value: unknown) => {
  window?.digitalData && set(window.digitalData, path, value);
};

export const USER_PACKAGE_GROUPS = {
  GO: ['TMH', 'FF', 'OAS', 'ODM', 'GHFT', 'GHFF', 'HGD', 'TPH'],
  SMB: ['SMBO', 'SMBA', 'SMBC', 'SMBE', 'SMBP'],
};

export type UserPackage = { packageName: string };

/**
 * Retrieves the allowed user packages for a given user package group.
 *
 * @param {UserPackage[]} packages - The list of user packages assigned to the user.
 * @param {keyof typeof USER_PACKAGE_GROUPS} userPackageGroup - The package group to filter by (e.g., 'GO' or 'SMB').
 * @returns {string} - A comma-separated string of allowed package names, or an empty string if no packages match.
 *
 * @example
 * const userPackages = [
 *   { packageName: 'TMH' },
 *   { packageName: 'FF' },
 *   { packageName: 'SMBO' }
 * ];
 *
 * console.log(getAllowedUserPackage(userPackages, 'GO'));
 * // Output: 'TMH, FF'
 *
 * console.log(getAllowedUserPackage(userPackages, 'SMB'));
 * // Output: 'SMBO'
 *
 * console.log(getAllowedUserPackage(userPackages, 'XYZ'));
 * // Output: ''
 */
export const getAllowedUserPackage = (
  packages: UserPackage[],
  userPackageGroup: keyof typeof USER_PACKAGE_GROUPS
): string => {
  const matchingPackages: string[] = [];
  packages.forEach((pack) => {
    if (USER_PACKAGE_GROUPS[userPackageGroup].includes(pack.packageName))
      matchingPackages.push(pack.packageName);
  });
  return matchingPackages.join(', ');
};

/**
 * Concatenates multiple user package lists into a single comma-separated string.
 *
 * @param {...string[]} userPackages - A list of comma-separated package strings (e.g., `['TMH, FF', 'OAS, SMBO']`).
 * @returns {string} - A single string with all unique package names, separated by a comma.
 *
 * @example
 * console.log(concatUserPackages('TMH, FF', 'OAS, SMBO'));
 * // Output: 'TMH, FF, OAS, SMBO'
 *
 * console.log(concatUserPackages('TPH', 'GHFT, GHFF', 'SMBP, HGD'));
 * // Output: 'TPH, GHFT, GHFF, SMBP, HGD'
 *
 * console.log(concatUserPackages());
 * // Output: ''
 */
export const concatUserPackages = (...userPackages: string[]): string =>
  userPackages
    .flatMap((pkg) => pkg?.split(', ').filter(Boolean) || []) // Split by ", ", remove falsy values
    .join(', '); // Join back into a single string

/**
 * Retrieves and concatenates the allowed user packages for a given set of package groups.
 *
 * @param {UserPackage[]} userPackages - An array of user packages returned from an API.
 * @param {Array<keyof typeof USER_PACKAGE_GROUPS>} allowedPackageGroups - An array of package group keys (e.g., `['GO', 'SMB']`).
 * @returns {string} - A comma-separated string of allowed user packages, or an empty string if none match.
 *
 * @example
 * const userPackages = [
 *   { packageName: 'TMH' },
 *   { packageName: 'FF' },
 *   { packageName: 'SMBO' },
 *   { packageName: 'SMBA' }
 * ];
 *
 * console.log(getAllowedUserPackagesForUser(userPackages, ['GO', 'SMB']));
 * // Output: 'TMH, FF, SMBO, SMBA'
 *
 * console.log(getAllowedUserPackagesForUser(userPackages, ['GO']));
 * // Output: 'TMH, FF'
 *
 * console.log(getAllowedUserPackagesForUser(userPackages, ['XYZ']));
 * // Output: ''
 */
export const getAllowedUserPackagesForUser = (
  userPackages: UserPackage[],
  allowedPackageGroups: (keyof typeof USER_PACKAGE_GROUPS)[]
): string => {
  const matchedPackages = allowedPackageGroups.map((group) =>
    getAllowedUserPackage(userPackages, group)
  );
  return concatUserPackages(...matchedPackages);
};
