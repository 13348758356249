import gql from 'graphql-tag-transform.macro';

export const ContactBar = gql`
  fragment ContactBar on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_AddressBar {
      addressLine1
      addressLine2
      checkInText
      arrivalTitleMobile
      arrivalTitle
      addressUrl
      addressTitleMobile
      addressTitle
      addressText
      checkInTitle
      checkOutText
      checkOutTitle
      fieldGroupName
      line2Icon
      line2Link
      line2Text
      line3Icon
      line3Link
      line3Text
      telephoneIcon
      telephoneLine2
      telephoneLine1
      telephoneLink
      telephoneNumber
      telephoneTitle
      telephoneTitleMobile
      tripAdvisorTitle
      tripAdvisorTitleMobile
      tripAdvisorToggle
      hideArrivalDeparture
      hideAddress
      hideContactInfo
      addressBarSettings {
        borderBottomColour
        borderTopColour
        seperatorColor
        componentBackgroundColor
        componentBackgroundImage {
          sourceUrl
        }
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundImage {
          sourceUrl
        }
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        fieldGroupName
        subtitleColor
        textAlignment
        textColor
        titleColor
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
      }
    }
  }
`;
