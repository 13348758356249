export function ArrowHeadSquare({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15.7344,9.7344,12,13.47,8.2656,9.7344a.3756.3756,0,0,0-.5312.5312l4,4a.3771.3771,0,0,0,.5312,0l4-4a.3756.3756,0,0,0-.5312-.5312Z" />
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V7A2.3777,2.3777,0,0,0,17,4.625ZM18.625,17A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7Z" />
        </g>
      </g>
    </svg>
  );
}
