import cx from 'classnames';
import type * as React from 'react';

type LinkNewWindow = React.SVGProps<SVGSVGElement>;

const LinkNewWindow: React.FC<React.PropsWithChildren<LinkNewWindow>> = ({
  className,
  width = 14,
  height = 14,
  ...rest
}) => (
  <svg
    role="img"
    aria-hidden
    data-testid="link-new-window"
    viewBox="0 0 10 10"
    width={width}
    height={height}
    className={cx('ms-1 inline-block stroke-current align-[-2px]', className)}
    {...rest}
  >
    <g fill="none">
      <path d="M3.6 1.3h5.3v5.3H3.6z" />
      <path d="M1.3 8.8V4.6h2.3v2h1.9v2.2z" />
    </g>
  </svg>
);

export { LinkNewWindow };
export default LinkNewWindow;
