import { useRouter } from 'next/router';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import type { AgentsSelection } from '../conductrics-hooks';
import { shouldRenderExperiment } from './should-render-experiment';

/**
 * Returns a function that determines if an element should be rendered based on:
 * 1. The presence of a `CmsExperimentationConfiguration` property
 * 2. The active locale (via `useRouter`)
 *
 * @note For unsupported locales, the control variant (A) is rendered by default.
 * To enable experiments in other languages, include ALL locales in the supportedAgentLocales array.
 */
export function useShouldRenderExperiment(
  supportedAgentLocales: Array<SupportedLanguage> = ['en']
) {
  const router = useRouter();
  // OneLink uses `?content=<locale>` to override the app locale
  const locale = (router.query?.content as string | undefined) || router.locale || 'en';

  return (agents: AgentsSelection, config?: CmsExperimentationConfiguration) => {
    return shouldRenderExperiment({
      agents,
      config,
      locale,
      supportedAgentLocales,
    });
  };
}
