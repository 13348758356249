/* eslint-disable no-console */
import * as React from 'react';
import type { LocationState } from './location-context';
import { LocationContext } from './location-context';
import { initialContext, sessionKey } from './utils/constants';
import { saveToSession, retrieveFromSession } from './utils/session';
import type { UserLocation } from './location-util';
import { getCoordinateBias } from './location-util';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export const LocationProviderConsumer = LocationContext.Consumer;

export const LocationProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useState<LocationState>({
    ...initialContext,
    isLoading: true,
  });

  const clear = () => {
    window.sessionStorage.removeItem(sessionKey);
    dispatch({ ...initialContext, isLoading: true });
    console.info('cleared userLocation');
  };

  const CLIENT_LOCATION_URI = '/en/clientLocation/';

  React.useEffect(() => {
    if (!isBrowser) return;

    let userLocation: UserLocation | null = null;

    try {
      const session = retrieveFromSession<UserLocation>(sessionKey);
      if (session) {
        userLocation = session;
      }
    } catch (err: any) {
      console.info('Unable to find session info with key:', sessionKey, err?.message);
    }

    // if in local then dispatch local
    if (userLocation) {
      dispatch({ ...userLocation, isLoading: false, clear });
    } else {
      fetch(CLIENT_LOCATION_URI)
        .then((response: Response) => response.json())
        .then((data) => {
          userLocation = data.userLocation as UserLocation;
          if (userLocation.coordinate) {
            // Default the search bias degrees to 10 if not provided, then calculate the search bias coordinate
            userLocation.searchBiasDegrees = userLocation.searchBiasDegrees || 10;
            userLocation.searchBiasCoordinate = getCoordinateBias(
              userLocation.coordinate,
              userLocation.searchBiasDegrees
            );
            // Default the address bias degrees to 10 if not provided, then calculate the address bias coordinate
            userLocation.addressBiasDegrees = userLocation.addressBiasDegrees || 1;
            userLocation.addressBiasCoordinate = getCoordinateBias(
              userLocation.coordinate,
              userLocation.addressBiasDegrees
            );
            // Default the marketing bias degrees to 4 if not provided, then calculate the marketing bias coordinate
            userLocation.marketingBiasDegrees = userLocation.marketingBiasDegrees || 4;
            userLocation.marketingBiasCoordinate = getCoordinateBias(
              userLocation.coordinate,
              userLocation.marketingBiasDegrees
            );
          }
          // Default the refresh TTL to 1 hour if not provided, and set the user location into session storage
          userLocation.refreshTTL = userLocation.refreshTTL || 3600;
          saveToSession(userLocation, sessionKey, userLocation.refreshTTL * 1000);
          dispatch({ ...userLocation, isLoading: false, clear });
        })
        .catch((error) => {
          console.info('Unable to fetch location information:', error?.message);
          dispatch({ ...initialContext, clear });
        });
    }
  }, [CLIENT_LOCATION_URI]);

  return <LocationContext.Provider value={state}>{children}</LocationContext.Provider>;
};

export function useLocation() {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error(`useLocation must be used within a LocationProvider`);
  }
  return context;
}
