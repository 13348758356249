import gql from 'graphql-tag-transform.macro';

export const LogoGrid = gql`
  fragment LogoGrid on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_LogoGrid {
      fieldGroupName
      heading
      headingCopy
      columnCount
      logoRepeater {
        label
        maxWidth
        tailwindClasses
        logo {
          sourceUrl
        }
      }
      button {
        buttonStyle
        link {
          target
          title
          url
        }
      }
      logoGridComponentSettings {
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        animationDirection
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
