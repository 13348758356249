import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./jhmgwwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-40 fill-current antialiased sm:w-60 sm:pl-2 lg:w-64 lg:pr-2"
    fillColor="#20212E"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-40 fill-current antialiased sm:w-60 lg:w-64" fillColor="#FFFFFF" />
);
