import type gql from 'graphql-tag-transform.macro';
import type { logger as sharedLogger } from '@dx-shared/logger';

interface Options {
  additionalHeaders?: Record<string, string>;
  addOpertionName?: boolean;
  skipCache?: boolean;
  logger?: typeof sharedLogger;
}

export interface Query {
  query: string;
  operationName: string;
  originalOpName: string;
}

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

function createFetchFn() {
  if (typeof window === 'undefined') {
    return fetch;
  }
  return window.fetch;
}

function createCache() {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
    const LRU = require('lru-cache');
    return new LRU({
      // 10 minutes
      // maxAge: 1000 * 60 * 10,
      //
      // 1 minute
      maxAge: 1000 * 60 * 1,
      max: 50,
    });
  }
  return undefined;
}
const fetchImpl = createFetchFn();

export function createFetch(url: string, options?: Options) {
  const logger = options?.logger;
  const cache = options?.skipCache ? undefined : createCache();
  return async function gqlFetch<TData, TVariables = unknown>(
    query: ReturnType<typeof gql> | Query,
    { variables, authHeader }: { variables?: TVariables; authHeader?: string } = {}
  ): Promise<TData | undefined> {
    try {
      const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'x-hilton-build': 'curated-rackspace',
        ...options?.additionalHeaders,
      };
      if (authHeader) {
        headers.Authorization = authHeader;
        headers['dx-trusted-app'] = 'true';
        headers['dx-platform'] = 'web';
      }
      const finalUrl = options?.addOpertionName
        ? `${url}?operationName=${query.operationName}`
        : url;

      const requestBody = {
        query:
          (query as Query & { query: string }).query ||
          (query as Query & { operationString: string }).operationString,
        variables,
        ...(options?.addOpertionName ? { operationName: query.operationName } : {}),
      };
      const cacheKey = JSON.stringify(requestBody);
      if (cache) {
        const cacheResult = cache.get(cacheKey);
        if (cacheResult) {
          return Promise.resolve(cacheResult);
        }
      }
      logger?.info(`sending request to ${finalUrl}`);

      const res = await fetchImpl(finalUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
        // added to disable keepalive as fetch in node 16 has keepalive enabled by default
        keepalive: false,
      });
      logger?.info(
        `got response from ${finalUrl} statusCode: ${res.status}, statusText: ${res.statusText}`
      );

      if (res.status === 200) {
        const json = await res.json();
        // expect errors on dx-brands-gql for Resorts World Las Vegas
        // Virtual Hotel is not a "real" brand.
        if (json.errors && json.errors[0].context !== 'dx-brands-gql') {
          // eslint-disable-next-line no-console
          logger?.error(json, 'GQL Error Response');
          json?.errors?.forEach((errObj: Error) => {
            logger?.error(errObj);
          });
          throw new Error('Failed to fetch from API');

          /**
           * POTENTIALLY BETTER ERROR LOGGING BUT CURRENTLY LOGS A LOT OF THINGS
           */
          // const error = json.errors[0];
          // const errorMessaging = `(${error.context}). Responded with a ${error.code} ${error.message}`;

          // switch(json.errors[0].context){
          //   case "dx-brands-gql":
          //     // eslint-disable-next-line no-console
          //     console.warn('Failed to fetch brand from dx-brands-gql. Allowed to fail because Resorts World has no "official" brand (Virtual Hotel)');
          //     break;
          //   case "dx-offers-gql":
          //     // eslint-disable-next-line no-console
          //     logger?.error(json, 'GQL Error Response');
          //     throw new Error(`Failed to fetch from Offers GraphQL ${errorMessaging}`);
          //   case "dx-hotels-gql":
          //     // eslint-disable-next-line no-console
          //     logger?.error(json, 'GQL Error Response');
          //     throw new Error(`Failed to fetch from Hotel GraphQL ${errorMessaging}`);
          //   default:
          //     // eslint-disable-next-line no-console
          //     logger?.error(json, 'GQL Error Response');
          //     throw new Error('Failed to fetch from API');
          // }
        }

        if (cache) {
          cache.set(cacheKey, json.data);
        }

        return json.data;
      } else {
        throw new Error(`HTTP Error: ${res.status} on ${res.url}`);
      }
    } catch (e) {
      if (e instanceof Error) logger?.error(e, `Failed to fetch`);
    }
  };
}
