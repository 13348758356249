import gql from 'graphql-tag-transform.macro';
import { meetingGroupOverrides, meetingGroupSettings } from './common-vars';

export const MeetingsGroup = gql`
fragment MeetingsGroup on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_MeetingsGroup {
        fieldGroupName
        ${meetingGroupOverrides}
        meetingGroupComponentSettings {
          fieldGroupName
          showHide
          ${meetingGroupSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
}
`;
