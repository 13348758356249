import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColor?: string;
  fillColor2?: string;
}>(() => import('./agprhqq/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo className="h-24 w-40 fill-bg antialiased" fillColor2="#00A7E1" />
);

export const hotelLogoFooter = <HotelLogo className="h-24 w-48 fill-bg antialiased" />;
