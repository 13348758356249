import type { LegacyRef } from 'react';
import { useState, useEffect, useCallback } from 'react';
import toSlug from 'slugify';
import * as Icons from '@curated-property/icons';
import * as NewIcons from '@curated-property/icon-list';
import Route from 'next/router';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { isReducedMotion, sanitize } from '@curated-property/utils';
import { getFormattedTime } from '@dx-ui/framework-i18n';

/**
 * Window resize event
 * Usage: useWindowSize().width OR useWindowSize().height
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    const debounceResize = debounce(handleResize, 50);
    window.addEventListener('resize', debounceResize);
    handleResize();
    return () => window.removeEventListener('resize', debounceResize);
  }, []);

  return windowSize;
}

/**
 * Debounce event
 * Usage: debounce(myFunction, 50);
 */
export function debounce(fn: () => void, ms: number) {
  let timer: NodeJS.Timeout | undefined;
  return function _() {
    clearTimeout(timer);
    timer = setTimeout(function _(...args) {
      timer = undefined;
      // eslint-disable-next-line
      // @ts-ignore
      fn.apply(this, args);
    }, ms);
  };
}

/**
 * Custom hook to track window scroll position and direction.
 *
 * @param {number} debounceDelay - Sets the debounce delay.
 * @returns {Object} - An object containing scroll positions and direction.
 * @returns {number} [scrollY] - The vertical scroll position.
 * @returns {number} [scrollX] - The horizontal scroll position.
 * @returns {'up' | 'down'} [scrollDirection] - The scroll direction.
 *
 * Usage:
 * const { scrollY, scrollX, scrollDirection } = useWindowScroll();
 */

export function useWindowScroll(debounceDelay?: number) {
  const [windowScroll, setWindowScroll] = useState<{
    scrollY?: number;
    scrollX?: number;
    scrollDirection?: 'up' | 'down';
  }>({
    scrollY: undefined,
    scrollX: undefined,
    scrollDirection: undefined,
  });

  useEffect(() => {
    let lastScrollY = window?.scrollY;

    function handleScroll() {
      const scrollY = window?.scrollY;
      let scrollDirection: 'up' | 'down' | undefined;

      if (scrollY > lastScrollY) {
        scrollDirection = 'down';
      } else if (scrollY < lastScrollY) {
        scrollDirection = 'up';
      }

      lastScrollY = scrollY;

      setWindowScroll({
        scrollY,
        scrollX: window?.scrollX,
        scrollDirection,
      });
    }

    const debounceScroll = debounce(handleScroll, debounceDelay || 50);
    window?.addEventListener('scroll', debounceScroll);
    handleScroll();
    return () => window?.removeEventListener('scroll', debounceScroll);
  }, [debounceDelay]);

  return windowScroll;
}

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: MediaQueryListEvent) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (window.matchMedia) {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);

      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }
  }, [width, updateTarget]);

  return targetReached;
};

/**
 * Linear transform of a single value from one range to another
 * @param value Original value
 * @param inputMin Original range minimum
 * @param inputMax Original range maximum
 * @param outputMin New range minimum value
 * @param outputMax New range maximum value
 * @returns Scales value relative to the new output range
 */
export const scaleValue = (
  value: number,
  inputMin: number,
  inputMax: number,
  outputMin = 0,
  outputMax = 1
) => ((outputMax - outputMin) * (value - inputMin)) / (inputMax - inputMin);

/**
 * test if url is relative
 */
export function isRelativeUrl(url: string | undefined | null) {
  return !/(^https?:\/\/)|(^mailto:)|(^tel:)|(^sms:)/i.test(url || '');
}

/**
 * @returns true/false
 */
export function elementExceedsWindowHeight(elHeight: number, extraHeight = 0) {
  if (typeof window !== 'undefined' && elHeight + extraHeight > window.innerHeight) {
    return true;
  } else {
    return false;
  }
}

/**
 * @returns true/false
 */
export function elementWidthExceedsViewportBoundary(
  elWidth: number,
  elOffset: number,
  windowWidth: number
) {
  const combinedWidth = elOffset + elWidth;
  if (combinedWidth > windowWidth) {
    return true;
  } else {
    return false;
  }
}

/**
 * test if url has buried absolute keywords, the isRelativeUrl only checks beginning of url
 */
export function containsAbsoluteUrl(url: string) {
  return /(https?:\/\/)|(mailto:)|(tel:)|(sms:)/i.test(url);
}

export function stripInternalUrl(url: string | undefined, internalHosts: (string | undefined)[]) {
  // relative do no processing
  if (isRelativeUrl(url)) {
    return url;
  }
  // for each host check for match
  const matchingHost = internalHosts?.find(
    (internalHost) => internalHost && url?.includes(internalHost)
  );
  // host is not known, treat this as external
  if (!matchingHost) {
    return url;
  }

  // otherwise replace the matching host
  return url?.replace(matchingHost, '');
}

/**
 * hexToRgb
 */
export function hexToRgb(hex: string | undefined) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex ?? '');
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: 1,
      }
    : null;
}

/**
 * hexToRgba
 */
export function hexToRgba(hex: string, alpha: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: alpha,
      }
    : null;
}

/**
 * hex2rgbaConst
 */
export function hex2rgbaConst(hex: string, alpha: string) {
  const rgb_values = hex.match(/\w\w/g);

  try {
    if (!rgb_values) {
      throw new Error('No RGB values found');
    }

    if (rgb_values.length !== 3) {
      throw new Error('Invalid hex color');
    }

    const [r, g, b] = rgb_values.map((x) => parseInt(x, 16));

    if (isNaN(r) || isNaN(g) || isNaN(b)) {
      throw new Error('Invalid hex color');
    }

    return `rgba(${r},${g},${b},${alpha})`;
  } catch {
    return `rgba(0,0,0,${alpha})`;
  }
}

/**
 * slugify
 */
export function slugify(
  str: string | undefined,
  optionsOrReplacement: Parameters<typeof toSlug>[1] = {}
) {
  if (!str) {
    return null;
  }
  const defaultOptions = {
    trim: true,
    lower: true,
    remove: /[$%*_+~.()'"!\-:@]/g,
  };
  const options =
    typeof optionsOrReplacement === 'string'
      ? { ...defaultOptions, replacement: optionsOrReplacement }
      : { ...defaultOptions, ...optionsOrReplacement };

  toSlug.extend({ '%': '', $: '' });
  return toSlug(str, options);
}

/**
 * iconmapper
 */
export function iconmapper() {
  return {
    BridalRegistry: Icons.BridalRegistryIcon,
    ChampagneIcon: Icons.ChampagneIcon,
    CombinedHeartsIcon: Icons.CombinedHeartsIcon,
    CoupleRoomUpgradeIcon: Icons.CoupleRoomUpgradeIcon,
    CoupleTurndownIcon: Icons.CoupleTurndownIcon,
    DinnerIcon: Icons.DinnerIcon,
    FloralBanquetIcon: Icons.FloralBanquetIcon,
    InRoomBreakfastIcon: Icons.InRoomBreakfastIcon,
    WeddingCakeIcon: Icons.WeddingCakeIcon,
    WeddingGiftIcon: Icons.WeddingGiftIcon,
    Breakfast: Icons.Breakfast,
    BusinessCenter: Icons.BusinessCenter,
    Cancellation: Icons.Cancellation,
    DoubleTreeCookie: Icons.DoubleTreeCookie,
    EveningReception: Icons.EveningReception,
    Firearms: Icons.Firearms,
    Fitness: Icons.Fitness,
    Golf: Icons.Golf,
    GuestService: Icons.GuestService,
    IndoorPool: Icons.IndoorPool,
    Internet: Icons.Internet,
    Key: Icons.Key,
    KeyDispenser: Icons.KeyDispenser,
    Lounge: Icons.Lounge,
    MeetingRoom: Icons.MeetingRoom,
    NonSmoking: Icons.NonSmoking,
    NoPets: Icons.NoPets,
    OutdoorPool: Icons.OutdoorPool,
    Parking: Icons.Parking,
    Pets: Icons.Pets,
    Resort: Icons.Resort,
    Restaurant: Icons.Restaurant,
    RoomService: Icons.RoomService,
    Shuttle: Icons.Shuttle,
    Spa: Icons.Spa,
    Tennis: Icons.Tennis,
    add: NewIcons.Add,
    addCircle: NewIcons.AddCircle,
    addSquare: NewIcons.AddSquare,
    alertCircle: NewIcons.AlertCircle,
    alertTriangle: NewIcons.AlertTriangle,
    amazon: NewIcons.Amazon,
    app: NewIcons.App,
    arrow: NewIcons.Arrow,
    arrowCircle: NewIcons.ArrowCircle,
    arrowHead: NewIcons.ArrowHead,
    arrowHeadCircle: NewIcons.ArrowHeadCircleSimple,
    arrowHeadSquare: NewIcons.ArrowHeadSquare,
    arrowSmall: NewIcons.ArrowSmall,
    arrowSquare: NewIcons.ArrowSquare,
    av: NewIcons.AV,
    award: NewIcons.Award,
    bath: NewIcons.Bath,
    beach: NewIcons.Beach,
    bed: NewIcons.Bed,
    breakfast: NewIcons.Breakfast,
    businessCentre: NewIcons.BusinessCentre,
    care: NewIcons.Care,
    champagne: NewIcons.Champagne,
    check: NewIcons.Check,
    checkCircle: NewIcons.CheckCircle,
    checkIn: NewIcons.CheckIn,
    checkSquare: NewIcons.CheckSquare,
    clock: NewIcons.Clock,
    close: NewIcons.Close,
    closeCircle: NewIcons.CloseCircle,
    closeSquare: NewIcons.CloseSquare,
    cocktails: NewIcons.Cocktails,
    coffee: NewIcons.Coffee,
    concierge: NewIcons.Concierge,
    contact: NewIcons.Contact,
    cookie: NewIcons.Cookie,
    creditCard: NewIcons.CreditCard,
    currency: NewIcons.Currency,
    diamond: NewIcons.Diamond,
    digitalKey: NewIcons.DigitalKey,
    dining: NewIcons.Dining,
    edit: NewIcons.Edit,
    entertainment: NewIcons.Entertainment,
    eveningReception: NewIcons.EveningReception,
    event: NewIcons.Event,
    family: NewIcons.Family,
    filters: NewIcons.Filters,
    flower: NewIcons.Flower,
    freeNights: NewIcons.FreeNights,
    gallery: NewIcons.Gallery,
    gift: NewIcons.Gift,
    golf: NewIcons.Golf,
    group: NewIcons.Group,
    gym: NewIcons.Gym,
    help: NewIcons.Help,
    helpCircle: NewIcons.HelpCircle,
    helpSquare: NewIcons.HelpSquare,
    home: NewIcons.Home,
    hotTub: NewIcons.HotTub,
    hotel: NewIcons.Hotel,
    indoorPool: NewIcons.IndoorPool,
    information: NewIcons.Information,
    iron: NewIcons.Iron,
    kidsClub: NewIcons.KidsClub,
    kitchen: NewIcons.Kitchen,
    language: NewIcons.Language,
    lift: NewIcons.Lift,
    link: NewIcons.Link,
    location: NewIcons.Location,
    lock: NewIcons.Lock,
    lounge: NewIcons.Lounge,
    luxury: NewIcons.Luxury,
    mail: NewIcons.Mail,
    massageTreatments: NewIcons.MassageTreatments,
    meeting: NewIcons.Meeting,
    menu: NewIcons.Menu,
    miniBar: NewIcons.MiniBar,
    mobile: NewIcons.Mobile,
    movies: NewIcons.Movies,
    music: NewIcons.Music,
    newWindow: NewIcons.NewWindow,
    night: NewIcons.Night,
    noPets: NewIcons.NoPets,
    noSmoking: NewIcons.NoSmoking,
    outdoorPool: NewIcons.OutdoorPool,
    parking: NewIcons.Parking,
    petFriendly: NewIcons.PetFriendly,
    policies: NewIcons.Policies,
    pool: NewIcons.Pool,
    price: NewIcons.Price,
    refresh: NewIcons.Refresh,
    refrigerator: NewIcons.Refrigerator,
    remote: NewIcons.Remote,
    remoteCircle: NewIcons.RemoteCircle,
    remoteSquare: NewIcons.RemoteSquare,
    resort: NewIcons.Resort,
    restaurant: NewIcons.Restaurant,
    roomService: NewIcons.RoomService,
    safe: NewIcons.Safe,
    search: NewIcons.Search,
    settings: NewIcons.Settings,
    shopping: NewIcons.Shopping,
    shower: NewIcons.Shower,
    shuttleService: NewIcons.ShuttleService,
    snacks: NewIcons.Snacks,
    spa: NewIcons.Spa,
    spin: NewIcons.Spin,
    star: NewIcons.Star,
    starCircle: NewIcons.StarCircle,
    telephone: NewIcons.Telephone,
    tennis: NewIcons.Tennis,
    terrace: NewIcons.Terrace,
    towels: NewIcons.Towels,
    translation: NewIcons.Translation,
    tv: NewIcons.TV,
    userCircle: NewIcons.UserCircle,
    userInfo: NewIcons.UserInfo,
    userSquare: NewIcons.UserSquare,
    user: NewIcons.User,
    users: NewIcons.Users,
    waterBottle: NewIcons.WaterBottle,
    weddingBird: NewIcons.WeddingBird,
    weddingCake: NewIcons.WeddingCake,
    wedding: NewIcons.Wedding,
    wifi: NewIcons.WiFi,
    yoga: NewIcons.Yoga,
    threeSixtyTour: NewIcons.ThreeSixtyTour,
    addHotel: NewIcons.AddHotel,
    airport: NewIcons.Airport,
    allInclusive: NewIcons.AllInclusive,
    balcony: NewIcons.Balcony,
    bellhopTrolley: NewIcons.BellhopTrolley,
    boutiqueHotels: NewIcons.BoutiqueHotels,
    cancellation: NewIcons.Cancellation,
    casino: NewIcons.Casino,
    city: NewIcons.City,
    connectingRooms: NewIcons.ConnectingRooms,
    evCharging: NewIcons.EvCharging,
    favouriteHotel: NewIcons.FavouriteHotel,
    firearms: NewIcons.Firearms,
    gardenView: NewIcons.GardenView,
    hotelLocation: NewIcons.HotelLocation,
    keyDispenser: NewIcons.KeyDispenser,
    keys: NewIcons.Keys,
    kidFriendly: NewIcons.KidFriendly,
    luggageTrolley: NewIcons.LuggageTrolley,
    luggage: NewIcons.Luggage,
    newHotels: NewIcons.NewHotels,
    oceanView: NewIcons.OceanView,
    renovatedHotels: NewIcons.RenovatedHotels,
    roomPlan: NewIcons.RoomPlan,
    ski: NewIcons.Ski,
    suites: NewIcons.Suites,
    unavailable: NewIcons.Unavailable,
    pause: NewIcons.Pause,
    play: NewIcons.Play,
    sailing: NewIcons.Sailing,
    cleaningEquipment: NewIcons.CleaningEquipment,
    faceCovering: NewIcons.FaceCovering,
    sprayBottleAndFaceCovering: NewIcons.SprayBottleAndFaceCovering,
    sprayBottle: NewIcons.SprayBottle,
    streamingTV: NewIcons.StreamingTV,
    turndownService: NewIcons.TurndownService,
    snorkel: NewIcons.Snorkel,
    surfboard: NewIcons.Surfboard,
    waterpark: NewIcons.Waterpark,
    hotelResidence: NewIcons.Keys,
    fireplace: NewIcons.Fireplace,
    slippers: NewIcons.Slippers,
    wellness: NewIcons.Wellness,
    thermal: NewIcons.Thermal,
    temperature: NewIcons.Temperature,
    steam: NewIcons.Steam,
    sauna: NewIcons.Sauna,
    salon: NewIcons.Salon,
    relaxationLounge: NewIcons.RelaxationLounge,
    juiceBar: NewIcons.JuiceBar,
    whatsApp: NewIcons.WhatsApp,
    instagram: NewIcons.Instagram,
    facebook: NewIcons.FaceBook,
    twitter: NewIcons.Twitter,
    youtube: NewIcons.YouTube,
  };
}

/**
 * Mobile Device detect
 * Usage: useWindowSize().isMobile
 */

export function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
    );

    setMobile(mobile);
  }, []);

  return { isMobile };
}

export function hyphenate(str: string) {
  if (!str) {
    return null;
  }
  return str
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^A-Z0-9-]/gi, '')
    .toLowerCase();
}

/**
 * Format Review Date
 * Usage: formatReviewDate(Date String)
 */

export function formatReviewDate(strDate: string) {
  const date = new Date(strDate);
  return format(date, 'LLL yyyy');
}

export function formatTime(time: string, locale: string) {
  if (locale === 'en') {
    return time;
  }
  return getFormattedTime(time, locale);
}

/**
 * Check to see if string matches hex or rgba
 *  - Rgb needs a bit of refinement
 *  - rgb(1234567,9,88) is valid, which it probably shouldnt be
 */
export function isHexOrRgb(str: string) {
  if (str === '') {
    return false;
  }
  const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  // const rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
  const rgbRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
  const val = hexRegex.test(str) || rgbRegex.test(str);
  return val;
}

/**
 * Converts and styles WYSIWIG links
 * @param text to be handled
 * @param inlineColor to be applied
 * @returns formatted and styled WYSIWYG content
 */
export function HandleWYSIWYGContent(text: string | undefined, inlineColor?: string) {
  const strMatch = /href="/gi,
    anchors: Array<string> = [];
  let anchor: RegExpExecArray | null;

  if (text && inlineColor) {
    text = text.replace(new RegExp('<p', 'gi'), '<p style="color:inherit"');
  }
  while ((anchor = strMatch.exec(text || ''))) {
    const anchorTag: string = text?.substring(anchor.index).split('<a ')[0] || '';
    anchors.push(anchorTag);
  }
  const newWindowIcon =
    '<svg role="img" aria-hidden="true" viewBox="0 0 10 10" width="14" height="14" class="stroke-current inline-block ml-1"><g fill="none"><path d="M3.6 1.3h5.3v5.3H3.6z"></path><path d="M1.3 8.8V4.6h2.3v2h1.9v2.2z"></path></g></svg></a>';

  anchors.forEach((a) => {
    const anchorEnd: string = text?.substring(text?.indexOf(a)).split('</a>')[0] || '';
    const anchorAttributes: string = text?.substring(text?.indexOf(a)).split('>')[0] || '';
    if (a?.includes('tel:')) {
      const anchorStart = anchorAttributes + '>';
      const anchorIdx = text?.indexOf(anchorStart) + anchorStart?.length;
      const srPhoneContext = '<span class="sr-only">Phone: </span>';
      text = text?.slice(0, anchorIdx) + srPhoneContext + text?.slice(anchorIdx);
    }
    const href: string = anchorAttributes.split(' ')[0];
    const hrefRe = new RegExp(href, 'gi');
    const nextAttribute: string = text?.substring(text?.indexOf(a)).split(' ')[1] || '';
    if (href?.includes('-cms.')) {
      const locale = Route?.router?.locale || 'en';
      const hrefString = `${locale}${Route?.router?.asPath?.replace(
        Route?.router?.query?.slug?.[0] ? `${Route?.router?.query?.slug?.[0]}/` : '',
        ''
      )}${href.split('hilton.com')[1].substring(1)}`;
      const updatedAnchorAttributes = anchorAttributes?.replace(
        href,
        `href="/${removeSingleCmsSiteId(hrefString)}"`
      );
      if (nextAttribute === 'target="_blank"') {
        const updatedAnchorEnd = anchorEnd.replace(anchorAttributes, updatedAnchorAttributes);
        text = text?.replace(`${anchorEnd}</a>`, `${updatedAnchorEnd} ${newWindowIcon}`);
      } else {
        text = text?.replace(anchorAttributes, updatedAnchorAttributes);
      }
    } else if (nextAttribute === 'target="_blank"') {
      const prefixedAnchor = anchorEnd;
      text = text?.replace(`${anchorEnd}</a>`, `${prefixedAnchor} ${newWindowIcon}`);
    } else text = text?.replace(hrefRe, href);
  });
  return sanitize(text || '');
}

/**
 *
 * @param num
 * @returns An integer as a comma-formatted string
 */
export function numberFormatter(num: number) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 *
 * @param val
 * @returns Square feet value to rounded square meter value
 */
export function sqFeetToSqMeter(val: number) {
  const converted = Math.round(val * 0.09290304);
  return converted;
}

/**
 *
 * @param url The url to be passed in
 */

export function liveToStgLink(url: string) {
  if (url && url?.startsWith('https://www.hilton.com')) {
    // const splitUrl = url.split('www');
    // const makeStgUrl = splitUrl[0] + 'www.stg' + splitUrl[1];
    // return makeStgUrl;
    return url;
  } else {
    return url;
  }
}

/**
 * @param alignment The alignment value to be passed
 */

export function matchValuesToFlexAlignment(alignment?: string) {
  switch (alignment) {
    case 'bottom':
    case 'Bottom':
    case 'end':
    case 'End':
      return 'end';
    case 'top':
    case 'Top':
    case 'start':
    case 'Start':
      return 'start';
    case 'center':
    case 'Center':
    case 'Middle':
    case 'middle':
      return 'center';
    default:
      return '';
  }
}

interface AnimationInput {
  hideAnimation: boolean | undefined;
  start?: string;
  end?: string;
  delayOne?: string;
  delayTwo?: string;
  delayThree?: string;
  delayFour?: string;
  delayFive?: string;
  delaySix?: string;
}

export interface AnimationOutput {
  one?: string;
  two?: string;
  three?: string;
  four?: string;
  five?: string;
  six?: string;
  inView: boolean;
  ref?: LegacyRef<HTMLDivElement>;
  hideAnimations: boolean;
}

/**
 * @param hideAnimation pulled from inlinestyles to toggle animation
 * @param start tailwind class to direct start of animation
 * @param end tailwind class to direct where animation should end
 * @param delayOne first delay which signals an animation is needed
 * @param delayTwo second delay which signals an animation is needed
 * @param delayThree third delay which signals an animation is needed
 * @param delayFour fourth delay which signals an animation is needed
 * @param delayFive fifth delay which signals an animation is needed
 * @param delaySix sixth delay which signals an animation is needed
 */

export function HandleAnimations({
  hideAnimation,
  start,
  end,
  delayOne,
  delayTwo,
  delayThree,
  delayFour,
  delayFive,
  delaySix,
}: AnimationInput): AnimationOutput {
  const hideAnimations: boolean = (hideAnimation ?? false) || isReducedMotion;
  const { ref, inView } = useInView({
    threshold: [0],
    skip: hideAnimations,
    fallbackInView: true,
  });
  const animationStyles = (delay?: string): string =>
    delay
      ? `transition-[transform,opacity] transform duration-1000 ${delay} ${
          inView || hideAnimations
            ? `opacity-100 ${end || 'translate-0'}`
            : `opacity-[.001] ${start || 'translate-x-8'}`
        }`
      : '';
  const one: string = animationStyles(delayOne);
  const two: string = animationStyles(delayTwo);
  const three: string = animationStyles(delayThree);
  const four: string = animationStyles(delayFour);
  const five: string = animationStyles(delayFive);
  const six: string = animationStyles(delaySix);

  return {
    one,
    two,
    three,
    four,
    five,
    six,
    inView,
    ref,
    hideAnimations,
  };
}
// DON'T DELETE THE NEXT LINE
// -translate-x-8 -translate-y-8 translate-x-8 translate-y-8 sm:-translate-x-8 sm:-translate-y-8 sm:translate-x-8 sm:translate-y-8 lg:-translate-x-8 lg:-translate-y-8 lg:translate-x-8 lg:translate-y-8

/**
 * Adds iteration "animation" to numbers from 0 up to the target number
 *
 * @param targetOne number to increment towards
 * @param targetTwo number to increment towards
 * @param targetThree number to increment towards
 * @param targetFour number to increment towards
 */

interface CountUpInput {
  targetOne: string | number;
  targetTwo: string | number;
  targetThree: string | number;
  targetFour: string | number;
}

interface CountUpOutput {
  0?: string | number;
  1?: string | number;
  2?: string | number;
  3?: string | number;
  ref?: (node?: Element) => void;
}

export function CountUp(props: CountUpInput): CountUpOutput | undefined {
  const targets: Array<number> = Object.values(props).map((v) =>
    typeof v === 'string' ? Number(v.replace(/,/g, '')) : v
  );
  const curNums: Array<number> = new Array(targets.length).fill(0);
  const [curNumbers, setCurNumbers] = useState<Array<number>>(curNums);
  const [isClient, setIsClient] = useState<boolean>(false);
  const { ref, inView } = useInView({
    threshold: [0],
    triggerOnce: true,
    skip: false,
    fallbackInView: true,
  });

  useEffect(() => {
    setIsClient(true);
  }, [setIsClient]);

  useEffect(() => {
    if (Math.max(...curNumbers) < Math.max(...targets) && inView) {
      setTimeout(() => {
        const newNums: Array<number> = [...curNumbers];
        curNumbers.forEach((v, i) => {
          const incr: number = Math.ceil(targets[i] / 500);
          if (v < targets[i] && v + incr < targets[i]) {
            newNums[i] += incr;
          } else if (v < targets[i]) newNums[i] = targets[i];
        });
        setCurNumbers(newNums);
      }, 2);
    }
  }, [curNumbers, targets, setCurNumbers, inView]);

  const returnObj: CountUpOutput = { ref };
  curNumbers.forEach(
    (v, i) => (returnObj[i as keyof Omit<CountUpOutput, 'ref'>] = v.toLocaleString())
  );

  if (isClient) return returnObj;
}

export function removeSingleCmsSiteId(urlOrPath?: string) {
  if (!urlOrPath) {
    return '';
  }
  const theUrlArray = urlOrPath.split('/');

  // single-cms url adjustment
  // would probably be better explicitly removing the 'dx-ctyhocn'
  // [s-cms]

  if (theUrlArray[3]?.startsWith('dx-') && theUrlArray[1] === 'hotels') {
    theUrlArray.splice(3, 1);
  } else if (theUrlArray[3]?.startsWith('dx-') && theUrlArray[2].includes('dx-curated-cms.')) {
    theUrlArray.splice(3, 1);
  } else if (theUrlArray[1]?.startsWith('dx-')) {
    theUrlArray.splice(1, 1);
  }
  const theUrlNew = theUrlArray.join('/');
  return theUrlNew;
}

/**
 * Calculates luminance of color to determine if it's light or not
 *
 * @param color rgb value
 * @returns boolean
 */

export function isColorLight(color?: string): boolean {
  const rgb = color.match(/\d+/g);
  const brightness = Math.round(
    (parseInt(rgb?.[0]) * 299 + parseInt(rgb?.[1]) * 587 + parseInt(rgb?.[2]) * 114) / 1000
  );
  return brightness > 155;
}

/**
 * Adjusts color to make it darker or lighter
 *
 * @param color rgb value
 * @param percent value between 0 and 1
 * @param lighten boolean to determine whether to lighten or darken color
 * @returns adjusted rgb string
 */

export function adjustColor(color: string, percent: number, lighten = false): string {
  const rgb = color.match(/\d+/g);
  const adjustment = lighten ? 1 : -1;
  const r = Math.min(
    255,
    Math.max(0, parseInt(rgb[0]) + adjustment * (255 - parseInt(rgb[0])) * percent)
  );
  const g = Math.min(
    255,
    Math.max(0, parseInt(rgb[1]) + adjustment * (255 - parseInt(rgb[1])) * percent)
  );
  const b = Math.min(
    255,
    Math.max(0, parseInt(rgb[2]) + adjustment * (255 - parseInt(rgb[2])) * percent)
  );

  const minDarken = 20;
  const darkenedR = lighten ? r : Math.max(0, r - minDarken);
  const darkenedG = lighten ? g : Math.max(0, g - minDarken);
  const darkenedB = lighten ? b : Math.max(0, b - minDarken);

  return `rgb(${darkenedR}, ${darkenedG}, ${darkenedB})`;
}

/**
 * Reduces the opacity of an RGB or RGBA color by 30%.
 *
 * @param {string} color - The RGB or RGBA color value.
 * @param {number} percent - The percentage (as a decimal) to reduce the opacity by.
 * @returns {string} The adjusted RGBA color string with reduced opacity.
 */

export function reduceOpacity(color: string, percent: number): string {
  const rgb = color?.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/);
  if (rgb) {
    const r = rgb[1];
    const g = rgb[2];
    const b = rgb[3];
    let a = rgb[4] !== undefined ? parseFloat(rgb[4]) : 1;

    a = Math.max(0, a - percent);

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }

  return color;
}

/**
 * Image Border width string maker returns 3 or a value from the CMS as a string.
 * Used in: Media & Copy, Full Width Media & Copy Overlay, Media & Copy Overlay
 * @param borderValue
 * @returns
 */
export function makeBorderWidthValue(borderValue: string) {
  const stringBorderValue = !borderValue ? '1' : borderValue;

  const intBorderValue =
    !stringBorderValue || stringBorderValue === '0' ? 0 : parseInt(stringBorderValue);
  // CMS setting to padding/border int - CMS settings & their corresponding Tailwind px value:
  const borderConversion = {
    0: 0,
    1: 4,
    2: 8,
    3: 12,
    4: 16,
    6: 24,
    8: 32,
  };

  const convertedBorderWidth = borderConversion[intBorderValue];

  // Since this is the inset border, divide value by 2 place border evenly between images
  const calculatedBorderWidth = convertedBorderWidth / 2;

  return calculatedBorderWidth.toString();
}

/**
 * Gets dynamic height of header element regardless of elements within it
 * or current screen size and returns height string
 *
 * @returns height of header element in '<height>px' string format
 */

export function GetHeaderHeight(): string {
  const [headerHeight, setHeaderHeight] = useState('');
  const windowSize = useWindowSize();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const header = document.getElementsByTagName('header')[0];
      if (header) {
        const observer = new MutationObserver(() => {
          setHeaderHeight(`${header.offsetHeight}px`);
        });

        observer.observe(header, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        setHeaderHeight(`${header.offsetHeight}px`);

        return () => {
          observer.disconnect();
        };
      }
    }
  }, [windowSize]);

  return headerHeight;
}

/**
 * Checks if a property alert exists in the header.
 *
 * This function uses a React hook to determine if any elements with the class name 'propertyAlert'
 * are present in the document. It returns a boolean value indicating the presence of such elements.
 *
 * @returns {boolean} True if a property alert exists, false otherwise.
 */

export function HasPropertyAlert(): boolean {
  const [hasPropertyAlert, setHasPropertyAlert] = useState<boolean>(false);
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const propertyAlerts = document.getElementsByClassName('propertyAlert') || [];
      setHasPropertyAlert(propertyAlerts?.length > 0);
    }
  }, []);

  return hasPropertyAlert;
}
