export function Waterpark({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0.00005v24h24v-24L0,0.00005z" />
        </g>
        <g>
          <g>
            <path
              d="M20,18.625c-0.54399,0.05025-1.06608,0.23884-1.5166,0.54785c-0.34278,0.2387-0.73682,0.39357-1.1504,0.45215
				c-0.41322-0.05877-0.8069-0.21363-1.14942-0.45215c-0.45044-0.30915-0.97256-0.49776-1.51658-0.54785
				c-0.54478,0.05013-1.0676,0.23909-1.51855,0.54883C12.8061,19.41181,12.41279,19.56632,12,19.625
				c-0.41278-0.05868-0.80609-0.21319-1.14844-0.45117c-0.45095-0.30974-0.97378-0.4987-1.51856-0.54883
				c-0.54403,0.05009-1.06616,0.2387-1.5166,0.54785C7.47389,19.41136,7.08022,19.56623,6.667,19.625
				c-0.41357-0.05858-0.80761-0.21345-1.15039-0.45215C5.06609,18.86384,4.544,18.67525,4,18.625
				c-0.20711,0-0.375,0.16789-0.375,0.375S3.79289,19.375,4,19.375c0.41278,0.05868,0.80609,0.21319,1.14844,0.45117
				c0.45095,0.30974,0.97378,0.4987,1.51856,0.54883c0.54403-0.05009,1.06616-0.2387,1.5166-0.54785
				c0.34251-0.23851,0.73618-0.39338,1.1494-0.45215c0.41357,0.05858,0.80761,0.21345,1.15039,0.45215
				C10.93391,20.13616,11.45601,20.32475,12,20.375c0.54399-0.05025,1.06608-0.23884,1.5166-0.54785
				c0.34278-0.2387,0.73682-0.39357,1.1504-0.45215c0.41322,0.05877,0.8069,0.21363,1.14942,0.45215
				c0.45044,0.30915,0.97257,0.49776,1.5166,0.54785c0.54478-0.05013,1.0676-0.23909,1.51855-0.54883
				C19.19391,19.5882,19.58722,19.43368,20,19.375c0.20711,0,0.375-0.16789,0.375-0.375S20.20711,18.625,20,18.625z"
            />
            <path
              d="M20,15.625c-0.54399,0.05025-1.06608,0.23884-1.5166,0.54785c-0.34278,0.2387-0.73682,0.39357-1.1504,0.45215
				c-0.41322-0.05877-0.8069-0.21363-1.14942-0.45215c-0.45044-0.30915-0.97256-0.49776-1.51658-0.54785
				c-0.54478,0.05013-1.0676,0.23909-1.51855,0.54883C12.8061,16.41181,12.41279,16.56632,12,16.625
				c-0.20711,0-0.375,0.16789-0.375,0.375s0.16789,0.375,0.375,0.375c0.54399-0.05025,1.06608-0.23884,1.5166-0.54785
				c0.34278-0.2387,0.73682-0.39357,1.1504-0.45215c0.41322,0.05877,0.8069,0.21363,1.14942,0.45215
				c0.45044,0.30915,0.97257,0.49776,1.5166,0.54785c0.54478-0.05013,1.0676-0.23909,1.51855-0.54883
				C19.19391,16.5882,19.58722,16.43368,20,16.375c0.20711,0,0.375-0.16789,0.375-0.375C20.375,15.79289,20.20711,15.625,20,15.625z
				"
            />
            <path
              d="M7,16.375c1.51172,0,2.48047-1.54883,2.75781-2.05762c1.81015-0.52106,3.52878-1.31905,5.09491-2.36566
				c-1.42819,1.38845-3.12145,2.47499-4.97872,3.19476c-0.19507,0.07008-0.2964,0.28502-0.22633,0.48009
				C9.7011,15.7753,9.84197,15.87463,10,15.875c0.04291,0.00001,0.08552-0.00725,0.126-0.02148
				C10.4219,15.74707,17.375,13.2041,17.375,8c0-2.21881-2.09534-3.77472-5.11774-3.86145
				c-0.07373-0.006-0.13372-0.0097-0.17675-0.01129C12.05267,4.127,12.028,4.12207,12,4.12207
				C8.00977,4.12207,5.625,6.09375,5.625,8c0,1.33423,1.26141,2.6048,2.86578,3.12384
				c-1.33202,0.41302-2.68664,0.74924-4.05719,1.007C4.25492,12.16296,4.12493,12.31847,4.125,12.5
				c0.01442,0.63877,0.12707,1.27151,0.334,1.876C4.74769,15.54599,5.79494,16.36985,7,16.375z M6.375,8
				c0-1.29749,1.74243-3.1236,5.614-3.12726L12,4.875c0.012,0,0.058,0.00085,0.124,0.00452l0.03083,0.00183
				C12.72107,4.91833,14.625,5.17883,14.625,7c0,1.47705-2.19714,2.71649-4.65118,3.62268C8.18652,10.60986,6.375,9.29346,6.375,8z
				 M15.375,7c0.00782-0.51244-0.13152-1.01637-0.40149-1.452C15.94605,5.98412,16.58639,6.93485,16.625,8
				c0,3.666-7.14941,5.61816-7.22168,5.6377c-0.10613,0.02866-0.19454,0.1021-0.24219,0.20117
				C9.15234,13.85645,8.294,15.625,7,15.625c-1.7207,0-2.04785-2.02344-2.11035-2.81641C6.90332,12.418,15.375,10.56445,15.375,7z"
            />
            <path
              d="M16.88086,4.35547C16.9082,4.36523,19.625,5.30469,19.625,7.5c-0.06365,1.1601-0.76278,2.19019-1.81738,2.67773
				c-0.17798,0.10625-0.23614,0.33667-0.12989,0.51465c0.10625,0.17798,0.33667,0.23614,0.51465,0.12989l0,0
				C19.47451,10.19935,20.31233,8.92407,20.375,7.5c0-2.73926-3.123-3.81152-3.25684-3.85547
				c-0.19342-0.06684-0.4044,0.03577-0.47124,0.22919c-0.00082,0.00237-0.00162,0.00475-0.00239,0.00714
				C16.57884,4.07717,16.68461,4.28959,16.88086,4.35547z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
