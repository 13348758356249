import { useRef, useCallback, useEffect } from 'react';
import { getVideoSrc } from '@dx-ui/utilities-get-video-src';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import cx from 'classnames';

declare global {
  interface Window {
    YT: {
      Player: { new (arg0: string, arg1: Record<string, unknown>): void };
    };
    onYouTubeIframeAPIReady: () => void;
  }
}

export type TYouTubeVideoProps = {
  id?: string;
  name?: string;
  /**
   * A11y requirement, iframe title for screen readers
   */
  title: string;
  /**
   * for example "youtube"
   */
  source: string;
  /**
   * Url of the video
   */
  url: string;
  /**
   * When present, the video will automatically start playing.
   */
  isAutoPlay?: boolean;
  /**
   * Specifies that the video will start over again, every time it is finished
   */
  isAutoLoop?: boolean;
  /**
   * Specified video aspect ratio
   */
  isVertical?: boolean;
  /**
   * Adds rounded corners
   */
  isRounded?: boolean;
  /**
   * Can be used to add styling to the wrapper div
   */
  wrapperClassName?: string;
  /**
   * This event fires whenever the player's state changes. https://developers.google.com/youtube/iframe_api_reference
   */
  onStateChange?: (e: unknown) => void;
};

/**
 * Renders a video player for YouTube supported videos and urls.
 *
 * Refer to the `getVideoSrc` util to see other supported source types.
 */
export const YouTubeVideo = ({
  title,
  source,
  url,
  id = 'video',
  isAutoPlay = false,
  isAutoLoop = true,
  wrapperClassName,
  isRounded,
  onStateChange,
  isVertical = source === 'youtube-short',
}: TYouTubeVideoProps) => {
  const player = useRef<unknown>();
  const aspectRatio: AspectRatio = isVertical ? '9:16' : '16:9';
  const videoSrc = getVideoSrc(url || '', source || '', isAutoPlay, isAutoLoop);

  const onPlayerReady = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (isAutoPlay) {
        event.target.mute();
        event.target.playVideo();
      }
    },
    [isAutoPlay]
  );

  const loadVideo = useCallback(() => {
    // The Player object is created uniquely based on the id in props
    const { YT } = window;

    player.current = new YT.Player(`${id}-youtube-video`, {
      events: {
        onReady: onPlayerReady,
        onStateChange,
      },
    });
  }, [id, onPlayerReady, onStateChange]);

  useEffect(() => {
    const ytHasLoaded = !!window.YT?.Player;

    if (!ytHasLoaded) {
      // If not, load the script asynchronously
      const tag = document.createElement('script');

      tag.src = 'https://www.youtube.com/iframe_api';

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      const previous = window.onYouTubeIframeAPIReady;

      window.onYouTubeIframeAPIReady = () => {
        if (previous) {
          previous();
        }

        loadVideo();
      };

      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag &&
        firstScriptTag.parentNode &&
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      // If script is already there, load the video directly
      loadVideo();
    }
  }, [loadVideo]);

  return (
    <div
      id={id}
      className={cx(
        'relative flex w-full items-center justify-center brand-qq:max-w-full brand-qq:items-start brand-qq:justify-start brand-qq:p-0',
        wrapperClassName
      )}
      style={{ aspectRatio: aspectRatio.replace(':', '/') }}
    >
      <div className="flex size-full justify-center">
        <iframe
          className={cx({
            'left-0 top-0 h-full w-full': !isVertical,
            'aspect-[9/16]': isVertical,
            'rounded-xl': isRounded,
          })}
          data-e2e={`${id}Video`}
          data-testid={`${id}Video`}
          id={`${id}-youtube-video`}
          src={videoSrc}
          allow="encrypted-media; autoplay"
          allowFullScreen
          title={title || 'No video available'}
          // The "sandbox" attribute is from Checkmarx; see NHCBP-4137
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  );
};

export default YouTubeVideo;
