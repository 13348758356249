import { addDays, startOfWeek } from 'date-fns';

const weekStartsOnLocale: Record<string, 0 | 1> = {
  en: 0,
  ar: 0,
  bg: 1,
  cs: 1,
  da: 1,
  fi: 1,
  fr: 1,
  id: 1,
  nl: 1,
  de: 1,
  it: 1,
  ja: 1,
  ko: 0,
  no: 1,
  pl: 1,
  pt: 1,
  ro: 1,
  ru: 1,
  es: 1,
  sv: 1,
  th: 0,
  tr: 1,
  'zh-hans': 1,
  'zh-hant': 1,
};

export const fullDateFormat = (date: Date, locale: Intl.Locale) =>
  date.toLocaleDateString(locale, { dateStyle: 'full' });

export const weekStartsOn = (locale: Intl.Locale) =>
  locale.baseName ? weekStartsOnLocale[locale.baseName] : 0;

export const getDaysOfWeek = (locale: Intl.Locale) => {
  const startDate = startOfWeek(new Date(), { weekStartsOn: weekStartsOn(locale) });
  const days = [...Array(7)].map((n, i) => {
    const day = addDays(startDate, i);
    return {
      short: day.toLocaleDateString(locale, { weekday: 'short' }),
      long: day.toLocaleDateString(locale, { weekday: 'long' }),
    };
  });
  return days;
};

/**
 * Converts 12-hour time format string (e.g., "4:00 PM") to 24-hour time format (e.g., "16:00")
 *
 * @param time - Time string in 12-hour format with AM/PM modifier (e.g., "4:00 PM")
 * @returns Time string in 24-hour format (e.g., "16:00")
 *
 * @example
 * convertTimeTo24HourFormat("4:00 PM") // returns "16:00"
 * convertTimeTo24HourFormat("4 pm") // returns "16:00"
 * convertTimeTo24HourFormat("12:00 AM") // returns "00:00"
 * convertTimeTo24HourFormat("12 am") // returns "00:00"
 * convertTimeTo24HourFormat("11:30 AM") // returns "11:30"
 * convertTimeTo24HourFormat("11:30 am") // returns "11:30"
 */
export function convertTimeTo24HourFormat(time: string) {
  const [timePart, modifier] = time.split(' ');
  const [hoursStr, minutes = '00'] = timePart?.split(':') || [];
  let hours = hoursStr;
  if (hours === '12') {
    hours = '00';
  }
  if (hours && !hours.startsWith('0') && parseInt(hours, 10) < 10) {
    hours = `0${hours}`;
  }
  if (hours && modifier?.toLowerCase() === 'pm') {
    hours = String(parseInt(hours, 10) + 12);
  }
  return `${hours}:${minutes}`;
}

const localeDays = {
  sunday: '2023-01-01T00:00:00',
  monday: '2023-01-02T00:00:00',
  tuesday: '2023-01-03T00:00:00',
  wednesday: '2023-01-04T00:00:00',
  thursday: '2023-01-05T00:00:00',
  friday: '2023-01-06T00:00:00',
  saturday: '2023-01-07T00:00:00',
};

export type LocaleDayNameKey = keyof typeof localeDays;

export function getLocaleDayName(locale: Intl.Locale, dayNameKey: LocaleDayNameKey) {
  return new Date(localeDays[dayNameKey]).toLocaleDateString(locale, { weekday: 'long' });
}

type TimeOptions = Intl.DateTimeFormatOptions & {
  format: (hour: string, minutes: string) => string;
};

function get12hrTime(hour: string) {
  if (hour === '00') {
    return '12';
  }
  if (hour.startsWith('0')) {
    return hour.slice(1);
  }
  if (parseInt(hour, 10) > 12) {
    return String(parseInt(hour, 10) - 12);
  }
  return hour;
}

function getTimeOptions(locale: string): TimeOptions | undefined {
  switch (locale) {
    case 'en':
      return {
        format: (hour, min) =>
          `${get12hrTime(hour)}${min === '00' ? '' : `:${min}`} ${
            parseInt(hour, 10) < 12 ? 'am' : 'pm'
          }`,
      };
    case 'ar':
    case 'cs':
    case 'it':
    case 'ja':
    case 'nl':
    case 'pl':
    case 'ro':
    case 'ru':
    case 'tr':
    case 'vi':
      return {
        format: (hour, min) => `${hour}:${min}`,
      };
    case 'bg':
      return {
        format: (hour, min) => `${hour}:${min} ч.`,
      };
    case 'de':
      return {
        format: (hour, min) => `${hour}:${min} Uhr`,
      };
    case 'es':
      return {
        format: (hour, min) => `${hour}:${min} h`,
      };
    case 'fi':
      return {
        format: (hour, min) => `klo ${hour}.${min}`,
      };
    case 'fr':
      return {
        format: (hour, min) => `${hour}h${min}`,
      };
    case 'id':
      return {
        format: (hour, min) => `Pukul ${hour}.${min}`,
      };
    case 'sv':
      return {
        format: (hour, min) => `kl ${hour}.${min}`,
      };
    case 'th':
      return {
        format: (hour, min) => `${hour}.${min} น.`,
      };
    case 'zh-hans':
    case 'zh-hant':
      return {
        format: (hour, min) =>
          `${parseInt(hour, 10) < 12 ? '上午' : '下午'} ${get12hrTime(hour)}:${min}`,
      };
    case 'ko':
      return {
        format: (hour, min) =>
          `${parseInt(hour, 10) < 12 ? '오전' : '오후'} ${get12hrTime(hour)}:${min}`,
      };
    case 'pt':
      return {
        format: (hour, min) => `${hour}h${min === '00' ? '' : min}`,
      };
    default:
      return undefined;
  }
}

export function getFormattedTime(time: string, locale: string) {
  const hour24Time = convertTimeTo24HourFormat(time);
  const [hour, minutes] = hour24Time.split(':');
  const options = getTimeOptions(locale);
  if (options && hour && minutes) {
    return options.format(hour, minutes);
  }
  return time;
}
