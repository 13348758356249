import { createContext } from 'react';
import type { AuthClient } from './auth-client';
import type { GuestQuery } from './gql/types';
import type { LoginResponse } from './types';

export type AuthInfo = Record<string, unknown>;

export type AuthState = {
  authClient: AuthClient | null;
  /** whether or not the current session is authenticated */
  isAuthenticated: boolean;
  authInfo?: AuthInfo;
  login: (response: LoginResponse) => Promise<void>;
  logout: () => Promise<void>;
  refreshGuestInfo: () => Promise<void>;
  guestInfo: GuestQuery['guest'] | null;
  guestId: number | null;
  isLoading: boolean;
  isCorpUser: boolean;
};

export const initialContext: AuthState = {
  authClient: null,
  isAuthenticated: false,
  logout: () => Promise.resolve(),
  login: () => Promise.resolve(),
  refreshGuestInfo: () => Promise.resolve(),
  guestInfo: null,
  guestId: null,
  isLoading: false,
  isCorpUser: false,
};

const AuthContext = createContext<AuthState>(initialContext);
AuthContext.displayName = 'AuthContext';

export { AuthContext };
