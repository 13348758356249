import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./indcici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-current antialiased lg:w-36 lg:pr-2" fillColor="#000000" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current antialiased lg:w-40" fillColor="#FFFFFF" />
);
