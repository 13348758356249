export function StarCircle({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
          <path d="M17.0068,10.0625,13.5,9.9951l-1.1484-3.124a.3745.3745,0,1,0-.7032.2578l1.2364,3.3652a.3733.3733,0,0,0,.3447.2461l2.6348.0508-2.0831,1.4942a.3762.3762,0,0,0-.1386.4189l.7666,2.3984-2.2031-1.4511a.3757.3757,0,0,0-.4122,0L9.5908,15.1025l.7666-2.3984a.3763.3763,0,0,0-.1387-.4189L8.1357,10.791l2.6348-.0508a.375.375,0,0,0-.0068-.75h-.0069l-3.7636.0723a.375.375,0,0,0-.212.68L9.56,12.7354l-1.0069,3.15a.3753.3753,0,0,0,.5635.4278L12,14.4141l2.8838,1.8994a.3766.3766,0,0,0,.206.0615.3753.3753,0,0,0,.3575-.4893l-1.0069-3.15,2.7783-1.9932a.375.375,0,0,0-.2119-.68Z" />
        </g>
      </g>
    </svg>
  );
}
