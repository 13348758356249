import { hexToRgb } from '../functions/helper';
/**
 * These are styling selectors for the OSC Shop Form and the Rooms pages booking widgets
 * The global button style option as seen in the OSC Shop Form is rendered through the Button Styles component.
 * */

interface ShopFormAndBookProps {
  buttons?: Array<{
    backgroundColour?: string;
    backgroundColourHover?: string;
    borderColour?: string;
    borderColourHover?: string;
    borderRadius?: string;
    buttonType?: string;
    fieldGroupName?: string;
    fontFamily?: string;
    hoverStyle?: string;
    textColour?: string;
    textColourHover?: string;
    textTransform?: string;
    buttonHeight?: string;
    buttonPadding?: string;
    fontSize?: string;
    fontWeight?: string;
    pageRestrictions?: string;
    lineHeight?: string;
  }>;
  shopFormAndBookStyles: {
    activeBookButtons?: {
      backgroundColor?: string;
      backgroundColorOnHover?: string;
      borderColor?: string;
      borderColorOnHover?: string;
      textColor?: string;
      textColorOnHover?: string;
    };
    activeBookButtonsClasses?: {
      buttonStyle?: string;
    };
    activeSearchButtons?: {
      backgroundColor?: string;
      backgroundColorOnHover?: string;
      borderColor?: string;
      borderColorOnHover?: string;
      textColor?: string;
      textColorOnHover?: string;
    };
    activeSearchButtonsClasses?: {
      buttonStyle?: string;
    };
    activeShopFormStyles?: {
      dateTextColor?: string;
      dividingLineColor?: string;
      dayOfTheWeekColor?: string;
      monthColor?: string;
    };
    modalDone?: {
      backgroundColor?: string;
      backgroundColorOnHover?: string;
      borderColor?: string;
      borderColorOnHover?: string;
      textColor?: string;
      textColorOnHover?: string;
    };
    modalCancel?: {
      backgroundColor?: string;
      backgroundColorOnHover?: string;
      borderColor?: string;
      borderColorOnHover?: string;
      textColor?: string;
      textColorOnHover?: string;
    };
    activeHorizontalDividingLineColor?: string;
    pastDatesText?: string;
    todaysDateText?: string;
    todaysDateBackground?: string;
    checkboxBackgroundColor?: string | null;
    checkboxInactiveBackgroundColor?: string | null;
    checkboxOutlineCheckmarkColor?: string | null;
    dateNumberAndRoomIconColor?: string | null;
    dateTextColor?: string | null;
    largeDateNumbersLineHeight?: string | null;
    dayOfTheWeekColor?: string | null;
    dividingLineColor?: string | null;
    horizontalDividingLineColor?: string | null;
    inputBorderColor?: string | null;
    mainTextColor?: string | null;
    modalBackgroundColor?: string | null;
    modalDividingLineColor?: string | null;
    modalHyperlinkColor?: string | null;
    modalHyperlinkColorCopy?: string | null;
    modalTextColor?: string | null;
    monthColor?: string | null;
    oscBookButtonBackgroundColor?: string | null;
    oscBookButtonBackgroundColorOnHover?: string | null;
    oscBookButtonBorderColor?: string | null;
    oscBookButtonBorderColorOnHover?: string | null;
    oscBookButtonTextColor?: string | null;
    oscBookButtonTextColorOnHover?: string | null;
    searchAndFilterIcons?: string | null;
    searchButtonBackgroundColor?: string | null;
    searchButtonBackgroundColorOnHover?: string | null;
    searchButtonBorderColor?: string | null;
    searchButtonBorderColorOnHover?: string | null;
    searchButtonTextColor?: string | null;
    searchButtonTextColorOnHover?: string | null;
    selectedBookDatePickerDateBackground?: string | null;
    selectedBookDatePickerDateText?: string | null;
    selectedBookPickerDateRangeBackground?: string | null;
    selectedBookPickerDateRangeText?: string | null;
    shopFormBgColor?: string | null;
    specialRatesNumberBackground?: string | null;
    specialRatesTextBackground?: string | null;
    bookButtonStyleFromGlobal: {
      shopFormBookGlobalButtonStyleButtonStyle?: string | null;
    };
    searchButtonStyleFromGlobal: {
      globalButtonStyleSettingButtonStyle?: string | null;
    };
  };
  pageSlug?: string;
}

const SHOP_FORM_PREFIX = '.osc-search-content-wrapper button[type="submit"]';

// OSC Shop Form Global Button Styling

/**
 * Produces class selectors associated with OSC Shop Form styling
 * // String: Outputs selector types based on supplied value: 'search' or 'book'
 * @param bookOrSearch
 * // Boolean: Outputs :focus,:hover state selectors if true
 * @param hasPseudoClasses
 * // String: used for outputting correct background settings on the OSC Book Button
 * @param globalSetting
 */
function oscSelectorHandler(bookOrSearch, hasPseudoClasses, globalSetting = '', isActive = false) {
  const active = isActive === true ? 'header[data-active="true"] ' : '';
  const searchArray = [
    `${active}div[data-osc-product="search-rates-button"] > button`,
    `${active}div[data-osc-product="search-rooms-button"] > button`,
  ];

  // OSC Book array is just one value
  const bookArray = [
    `${active}${SHOP_FORM_PREFIX}${
      bookOrSearch === 'book' && globalSetting ? `.btn-${globalSetting}` : '.btn-primary'
    }`,
  ];

  const pseudoClassArray = [':hover', ':focus'];

  const selectorArray = bookOrSearch === 'search' ? searchArray : bookArray;

  const combinedSelectorArray = [];

  let string = '';
  // Update result array based on settings
  if (hasPseudoClasses) {
    pseudoClassArray.forEach((pseudoClass) => {
      selectorArray.forEach((selector) => {
        combinedSelectorArray.push(`${selector}${pseudoClass}`);
      });
    });
    string = combinedSelectorArray.join(',');
  } else {
    if (!hasPseudoClasses) {
      string = selectorArray.join(',');
    }
  }

  return string;
}

/**
 * Book button or search buttons in OSC Shop Form
 * @param selectorType
 * Style settings for global button setting type
 * @param globalBtn
 */
function styleOscButtonByType(selectorType, globalBtn, activeStyles) {
  let styles = '';

  // Default states
  styles += `${oscSelectorHandler(selectorType, false)} {`;

  // Button height
  if (globalBtn?.buttonHeight) styles += `min-height: ${globalBtn?.buttonHeight};`;

  // Line height
  if (globalBtn?.lineHeight) styles += `line-height: ${globalBtn?.lineHeight} !important;`;

  // Border radius
  if (globalBtn?.borderRadius) styles += `border-radius: ${globalBtn?.borderRadius};`;

  // Text transform
  if (globalBtn?.textTransform) styles += `text-transform: ${globalBtn?.textTransform};`;

  // Button padding
  if (globalBtn?.buttonPadding)
    styles += `padding-left: ${globalBtn?.buttonPadding};padding-right: ${globalBtn?.buttonPadding};`;

  // Font weight
  if (globalBtn?.fontWeight) styles += `font-weight: ${globalBtn?.fontWeight};`;

  // Font size
  if (globalBtn?.fontSize) styles += `font-size: ${globalBtn?.fontSize};`;

  // Font family
  if (globalBtn?.fontFamily) styles += `font-family: ${globalBtn?.fontFamily};`;

  // Text Color (default state only)
  if (globalBtn?.textColour) styles += `color: ${globalBtn?.textColour};`;

  // Border Color (default state only)
  if (globalBtn?.borderColour) styles += `border-color: ${globalBtn?.borderColour};`;

  styles += `}`;

  // Default states - HEADER ACTIVE STATE
  styles += `${oscSelectorHandler(selectorType, false, '', true)} {`;

  if (activeStyles?.textColor) styles += `color: ${activeStyles?.textColor} !important;`;
  if (activeStyles?.borderColor) styles += `border-color: ${activeStyles?.borderColor} !important;`;

  styles += `}`;

  // Preserve the default numerals inside the Special Rates button badge icon (.875rem)
  styles += `div[data-osc-product="search-rates-button"] > button > div.rounded-full {
    font-size:0.875rem !important;
  }`;

  // Hover/focus states for text and border colors
  styles += `${oscSelectorHandler(selectorType, true)} {`;
  if (globalBtn?.textColourHover) styles += `color: ${globalBtn?.textColourHover};`;
  if (globalBtn?.borderColourHover) styles += `border-color: ${globalBtn?.borderColourHover};`;
  styles += `}`;

  // Hover/focus states for text and border colors - HEADER ACTIVE STATE
  styles += `${oscSelectorHandler(selectorType, true, '', true)} {`;
  if (activeStyles?.textColorOnHover)
    styles += `color: ${activeStyles?.textColorOnHover} !important;`;
  if (activeStyles?.borderColorOnHover)
    styles += `border-color: ${activeStyles?.borderColorOnHover} !important;`;
  styles += `}`;

  // Background color (both states and button types)

  // Search
  // Default state
  if (selectorType === 'search') {
    if (globalBtn?.backgroundColour) {
      styles += `${oscSelectorHandler(selectorType, false)} {
        background-color: ${globalBtn?.backgroundColour};
      }`;
    }
    if (activeStyles?.backgroundColor) {
      styles += `${oscSelectorHandler(selectorType, false, '', true)} {
        background-color: ${activeStyles?.backgroundColor};
      }`;
    }

    // Hover/focus state
    if (globalBtn?.backgroundColourHover) {
      styles += `${oscSelectorHandler(selectorType, true)} {
        background-color: ${globalBtn?.backgroundColourHover};
      }`;
    }
    // Hover/focus state - header active state
    if (activeStyles?.backgroundColorOnHover) {
      styles += `
        header[data-active="true"] div[data-osc-product="search-rates-button"] button:hover,
        header[data-active="true"] div[data-osc-product="search-rates-button"] button:focus,
        header[data-active="true"] div[data-osc-product="search-rooms-button"] button:hover,
        header[data-active="true"] div[data-osc-product="search-rooms-button"] button:focus {
          background-color: ${activeStyles?.backgroundColorOnHover} !important;
        }
      `;
    }
  }

  // Book
  if (selectorType === 'book') {
    // Default state
    if (globalBtn?.backgroundColour) {
      styles += `
      ${oscSelectorHandler(selectorType, false)},
      ${oscSelectorHandler(selectorType, false)}:after {
        background-color: ${globalBtn?.backgroundColour};
      }
      `;
    }
    // header active state
    if (activeStyles?.backgroundColor) {
      styles += `
      ${oscSelectorHandler(selectorType, false, '', true)},
      ${oscSelectorHandler(selectorType, false, '', true)}:after {
        background-color: ${activeStyles?.backgroundColor};
      }
      `;
    }
    // Hover/focus
    if (globalBtn?.backgroundColourHover) {
      styles += `
      ${oscSelectorHandler(selectorType, false)}:hover,
      ${oscSelectorHandler(selectorType, false)}:hover:after,
      ${oscSelectorHandler(selectorType, false)}:focus,
      ${oscSelectorHandler(selectorType, false)}:focus:after {
        background-color: ${globalBtn?.backgroundColourHover};
      }
      `;
    }
    // Hover/focus - header active state
    if (activeStyles?.backgroundColorOnHover) {
      styles += `
      ${oscSelectorHandler(selectorType, false, '', true)}:hover,
      ${oscSelectorHandler(selectorType, false, '', true)}:hover:after,
      ${oscSelectorHandler(selectorType, false, '', true)}:focus,
      ${oscSelectorHandler(selectorType, false, '', true)}:focus:after {
        background-color: ${activeStyles?.backgroundColorOnHover};
      }
      `;
    }
  }

  return styles;
}

export function ShopFormAndBookStyles({ buttons, shopFormAndBookStyles }: ShopFormAndBookProps) {
  const props = shopFormAndBookStyles;
  // Custom button settings in lieu of a global button selection.
  const searchBtnCustomStyle = {
    bgColor: props?.searchButtonBackgroundColor,
    bgColorOnHover: props?.searchButtonBackgroundColorOnHover,
    borderColor: props?.searchButtonBorderColor,
    borderColorOnHover: props?.searchButtonBorderColorOnHover,
    textColor: props?.searchButtonTextColor,
    textColorOnHover: props?.searchButtonTextColorOnHover,
  };

  const oscBookBtnCustomStyle = {
    bgColor: props?.oscBookButtonBackgroundColor,
    bgColorOnHover: props?.oscBookButtonBackgroundColorOnHover,
    borderColor: props?.oscBookButtonBorderColor,
    borderColorOnHover: props?.oscBookButtonBorderColorOnHover,
    textColor: props?.oscBookButtonTextColor,
    textColorOnHover: props?.oscBookButtonTextColorOnHover,
  };

  let styleString = '';

  function matchButtonStyle(buttonTypeFromProps) {
    return buttons?.find((buttonStyle) => buttonStyle?.buttonType === buttonTypeFromProps);
  }

  /**
   * Override Global style text color, border color and background color on interactive states
   * selectorType is 'search' or 'book'
   * @param selectorType
   * The style overrides set on the individual Shop Form theme settings panels (not the global button styles)
   * @param styleArray
   * @returns
   */
  function overrideGlobalStyle(selectorType, styleArray, globalSetting = '', activeStyles) {
    let styles = '';
    // Global setting is included to force an overwrite on the OSC Shop Form's book button

    if (selectorType) {
      // Default state -- Background color override for the OSC Book Button handled separately
      styles += `
      ${oscSelectorHandler(selectorType, false, globalSetting)}${
        globalSetting ? `.btn-${globalSetting}` : ''
      } `;

      styles += `{`;

      if (styleArray?.borderColor) styles += `border-color: ${styleArray?.borderColor} !important;`;

      if (styleArray?.textColor) styles += `color: ${styleArray?.textColor} !important;`;
      // Search buttons only
      if (styleArray?.bgColor && selectorType === 'search')
        styles += `background-color: ${styleArray?.bgColor} !important; background: ${styleArray?.bgColor} !important; `;

      styles += `}`;

      // Book button in modal
      if (selectorType === 'book') {
        styles += `${SHOP_FORM_PREFIX} {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${styleArray?.borderColor} !important;`;

        if (styleArray?.textColor) styles += `color: ${styleArray?.textColor} !important;`;
        styles += `}`;
      }

      // Book button - HEADER ACTIVE
      if (selectorType === 'book') {
        styles += `header[data-active="true"] ${SHOP_FORM_PREFIX} {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${activeStyles?.borderColor} !important;`;

        if (styleArray?.textColor) styles += `color: ${activeStyles?.textColor} !important;`;

        if (styleArray?.textColor)
          styles += `background-color: ${activeStyles?.backgroundColor} !important;`;
        styles += `}`;
      }

      // Book button - HOVER / FOCUS STATE - HEADER ACTIVE
      if (selectorType === 'book') {
        styles += `header[data-active="true"] ${SHOP_FORM_PREFIX}:hover, 
        header[data-active="true"] ${SHOP_FORM_PREFIX}:focus {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${activeStyles?.borderColorOnHover} !important;`;

        if (styleArray?.textColor) styles += `color: ${activeStyles?.textColorOnHover} !important;`;

        if (styleArray?.textColor)
          styles += `background-color: ${activeStyles?.backgroundColorOnHover} !important;`;
        styles += `}`;
      }

      // Hover/focus state -- Background color hover override for OSC Shop Form's book button handled separately.

      styles += `${oscSelectorHandler(selectorType, true, globalSetting)}${
        globalSetting ? `.btn-${globalSetting}` : ''
      } {`;

      if (styleArray?.borderColorOnHover)
        styles += `border-color: ${styleArray?.borderColorOnHover} !important;`;

      if (styleArray?.textColorOnHover)
        styles += `color: ${styleArray?.textColorOnHover} !important;`;
      // Search buttons only
      if (styleArray?.bgColorOnHover && selectorType === 'search')
        styles += `background-color: ${styleArray?.bgColorOnHover} !important;background: ${styleArray?.bgColorOnHover} !important; `;

      styles += `}`;

      // Book button in modal hover/focus state
      if (selectorType === 'book') {
        styles += `${SHOP_FORM_PREFIX}:hover,${SHOP_FORM_PREFIX}:focus  {`;
        if (styleArray?.borderColor)
          styles += `border-color: ${styleArray?.borderColorOnHover} !important;`;

        if (styleArray?.textColor) styles += `color: ${styleArray?.textColorOnHover} !important;`;
        styles += `}`;
      }

      // Book button background override handling. If no global setting type is provided, the default is primary
      if (selectorType === 'book' && (styleArray?.bgColor || styleArray?.bgColorOnHover)) {
        const globalToUse = !globalSetting ? 'primary' : globalSetting;

        // Determine whether button global setting has animation or not

        const appliedBookGlobalStyle = buttons?.find((el) => el?.buttonType === globalToUse);

        const animationType = appliedBookGlobalStyle?.hoverStyle || 'default';

        if (styleArray?.bgColor) {
          const oscBgDefault = `
          [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX},
          body > ${SHOP_FORM_PREFIX}.btn-${globalToUse},
          ${SHOP_FORM_PREFIX}.btn-${globalToUse} {
            background-color: ${styleArray?.bgColor} !important;
            background: ${styleArray?.bgColor} !important;
        }`;

          styles += oscBgDefault;
        }

        // Hover/focus
        if (styleArray?.bgColorOnHover) {
          let oscBgInteract = '';

          if (animationType === 'default') {
            oscBgInteract += `
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:hover,
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:focus,
          ${SHOP_FORM_PREFIX}.btn-${globalToUse}:hover,
          ${SHOP_FORM_PREFIX}.btn-${globalToUse}:focus
          `;
          } else {
            if (animationType !== 'default') {
              oscBgInteract += `
        
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:hover:after,
            [data-modal-id="booking-widget"] ${SHOP_FORM_PREFIX}:focus:after,
            ${SHOP_FORM_PREFIX}.btn-${globalToUse}:hover:after,
            ${SHOP_FORM_PREFIX}.btn-${globalToUse}:focus:after
            `;
            }
          }

          oscBgInteract += `{
          background-color: ${styleArray?.bgColorOnHover} !important;
          background: ${styleArray?.bgColorOnHover} !important;}`;

          styles += oscBgInteract;
        }
      }
    }

    return styles;
  }

  // Room Search and Special Rates Buttons for properties other than Waldorf Astoria

  // Use the global button style, if selected, and then add any custom overrides
  if (shopFormAndBookStyles?.searchButtonStyleFromGlobal?.globalButtonStyleSettingButtonStyle) {
    // Check for a possible match among any available global site button styles and the OSC button setting.
    const globalBtnMatch = matchButtonStyle(
      shopFormAndBookStyles?.searchButtonStyleFromGlobal?.globalButtonStyleSettingButtonStyle
    );

    if (globalBtnMatch && globalBtnMatch !== undefined)
      styleString += styleOscButtonByType('search', globalBtnMatch, props?.activeSearchButtons);
  }

  // OSC Book Button
  if (shopFormAndBookStyles?.bookButtonStyleFromGlobal?.shopFormBookGlobalButtonStyleButtonStyle) {
    // Check for a possible match among any available global site button styles and the OSC button setting.
    const globalBtnMatch = matchButtonStyle(
      shopFormAndBookStyles?.bookButtonStyleFromGlobal?.shopFormBookGlobalButtonStyleButtonStyle
    );

    if (globalBtnMatch && globalBtnMatch !== undefined)
      styleString += styleOscButtonByType('book', globalBtnMatch, props?.activeBookButtons);
  }

  // Apply Shop Form Button Specific CMS settings
  let hasCustomSearchBtnSettings = false;

  Object.values(searchBtnCustomStyle).forEach((setting) => {
    if (setting && !hasCustomSearchBtnSettings) {
      hasCustomSearchBtnSettings = true;
      return false;
    }
  });

  // If here are custom button settings, those should override the global settings
  if (hasCustomSearchBtnSettings) {
    styleString += overrideGlobalStyle(
      'search',
      searchBtnCustomStyle,
      '',
      props?.activeBookButtons
    );
  }

  // Booking button in OSC Shop Form (separate settings from the Book/Make A Reservation button in the upper-right part of the header)
  // Book button appears in two possible places
  let bookButtonHasStyling = false;
  if (oscBookBtnCustomStyle) {
    Object.values(oscBookBtnCustomStyle).forEach((el) => {
      if (el) {
        bookButtonHasStyling = true;
        return false;
      }
    });
  }

  if (bookButtonHasStyling) {
    styleString += overrideGlobalStyle(
      'book',
      oscBookBtnCustomStyle,
      shopFormAndBookStyles?.bookButtonStyleFromGlobal?.shopFormBookGlobalButtonStyleButtonStyle,
      props?.activeBookButtons
    );
  }

  // all icons in modals
  if (props?.searchAndFilterIcons) {
    styleString += `
      [data-osc-product='search-dates'] > div  > div:first-of-type > button svg:not(.injected-svg) path,
      .dialog-content-v2 > div > h2 + button:not([data-element-id="image-carousel-control"]) svg path,
      .dialog-content-v2 button:not([data-element-id="image-carousel-control"]) svg:not(.injected-svg),
      .dialog-content-v2 button:not([data-element-id="image-carousel-control"]) svg:not(.injected-svg) path,
      .cp-calendar-monthArrows:not(:disabled) svg path  { 
        stroke: ${props?.searchAndFilterIcons} !important;
      }

      button[id^='radix-'] span.accordion-indicator-wrapper > span > span > svg {
        stroke: none !important;
      }

      .dialog-content-v2 button[disabled] svg {
        opacity: 0.5;
      }
    `;
  }

  // Large Date Numbers OR Large date numbers & Plus Icons (+) in OSC room selection dialog and the sub-link in same panel ("Book 10 or more rooms...")
  if (
    props?.dateTextColor ||
    props?.dateNumberAndRoomIconColor ||
    props?.largeDateNumbersLineHeight
  ) {
    styleString += `
  .rooms-widget-wrapper > button > span > span.font-bold,
  [data-osc-product="shop-form-dates"] > span > span.text-primary {
      color: ${props?.dateTextColor || props?.dateNumberAndRoomIconColor};
      ${props?.largeDateNumbersLineHeight && `line-height: ${props?.largeDateNumbersLineHeight};`}
    }
  header[data-active="true"] .rooms-widget-wrapper > button > span > span.font-bold,
  header[data-active="true"] [data-osc-product="shop-form-dates"] > span > span.text-primary {
      color: ${props?.activeShopFormStyles?.dateTextColor};
    }

    [data-osc-product="rooms"] button:not(:disabled) svg,
    [data-osc-product="rooms"] + div button svg {
      path: ${props?.dateNumberAndRoomIconColor};
      stroke: ${props?.dateNumberAndRoomIconColor};
    }
    `;
  }

  // Main text – most labels and non-selected, non-expired date numbers in calendar date pickers
  if (props?.modalTextColor || props?.mainTextColor) {
    styleString += `
      [data-osc-product="shop-form-dates"] > span > span + span span,
      .rooms-widget-wrapper > button > div > div span.tabular-nums,
      [data-osc-product="osc-dialog-title"],
      .dialog-content-v2 label span,
      [data-osc-date-picker] label span,
      [data-osc-shop-form-special-rates] label span,
      .dialog-content-v2 div > div > div > span,
      [data-osc-product="rooms"] + div button,
      [data-osc-product="search-dates"] h2,
      [data-osc-product="search-dates"] table thead,
      [data-osc-product="search-dates"] table tbody td button:not(:disabled,.text-text-inverse) span,
      [data-osc-product="shop-form-dates"] > span > span > span,
      span[id^="calendar-month"],
      span[id^="calendar-month"] + table  {
        color: ${props?.modalTextColor || props?.mainTextColor};
      }
    `;
  }

  // Today text/bg color
  if (props?.todaysDateBackground || props?.todaysDateText) {
    styleString += `
      [data-osc-product="search-dates"] table tbody tr button.font-bold {
        background-color: ${props?.todaysDateBackground};
      }
      [data-osc-product="search-dates"] table tbody tr button.font-bold > span {
        color: ${props?.todaysDateText};
      }
    `;
  }

  // Past dates text color
  if (props?.pastDatesText) {
    styleString += `
      [data-osc-product="search-dates"] table tbody button[disabled] {
        color: ${props?.pastDatesText}
      }
    `;
  }

  // Month Text
  if (props?.dayOfTheWeekColor) {
    styleString += `
      [data-osc-product="shop-form-dates"] > span > span + span > span:first-of-type {
        color: ${props?.dayOfTheWeekColor};
      }
      header[data-active="true"] [data-osc-product="shop-form-dates"] > span > span + span > span:first-of-type {
        color: ${props?.activeShopFormStyles?.dayOfTheWeekColor};
      }
    `;
  }

  // Day of the Week Text
  if (props?.monthColor) {
    styleString += `
      [data-osc-product="shop-form-dates"] > span > span + span > span:last-of-type {
        color: ${props?.monthColor};
      }
  header[data-active="true"] [data-osc-product="shop-form-dates"] > span > span + span > span:last-of-type {
        color: ${props?.activeShopFormStyles?.monthColor};
      }
    `;
  }

  // Dividing Line
  if (props?.dividingLineColor) {
    styleString += `
      .cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > span:last-of-type,
      [data-osc-product="shop-form-dates"] > span:last-of-type {
        border-color: ${props?.dividingLineColor} !important;
      }
      header[data-active="true"] .cp-header-shop-form .osc-search-content-wrapper > fieldset > div > button > span:last-of-type {
        border-color: ${props?.activeShopFormStyles?.dividingLineColor} !important;
      }
    `;
  }

  // Special rates BACKGROUND color
  if (props?.specialRatesNumberBackground) {
    styleString += `
      .shop-form-btn > span.bg-primary,
      .shop-form-btn > div.rounded-full {
        background-color: ${props?.specialRatesNumberBackground};
      }
    `;
  }

  // Special rates TEXT color (i messed up the db names)
  if (props?.specialRatesTextBackground) {
    styleString += `
      .shop-form-btn > span.bg-primary,
      .shop-form-btn > div.rounded-full > span {
        color: ${props?.specialRatesTextBackground};
      }
    `;
  }

  // Calendar datepicker styling for table cells
  if (props?.selectedBookDatePickerDateText || props?.selectedBookDatePickerDateBackground) {
    styleString += `
      [data-osc-product="search-dates"] table tbody td button.text-text-inverse {
        ${
          props?.selectedBookDatePickerDateBackground
            ? `background: ${props?.selectedBookDatePickerDateBackground};`
            : ''
        }
      }
      
      [data-osc-product="search-dates"] table tbody td button.text-text-inverse > span {
       ${
         props?.selectedBookDatePickerDateText
           ? `color: ${props?.selectedBookDatePickerDateText};`
           : ''
       }
      }
    `;
  }

  // OSC Modal background color
  if (props?.modalBackgroundColor) {
    styleString += `
      .dialog-content-v2 {
        background-color: ${props?.modalBackgroundColor} !important;
      }
      [data-osc-product='search-dates'] table tbody tr td button {
        border-style: none;
      }
    `;
  }

  // OSC Modal Dividing Line border color
  if (props?.modalDividingLineColor) {
    styleString += `
      .dialog-content-v2 .border-border {
        border-color: ${props?.modalDividingLineColor} !important;
      }
    `;
  }

  // OSC Modal hyperlink color
  if (props?.modalHyperlinkColor) {
    styleString += `
      .dialog-content-v2 a:not(.btn) span {
        color: ${props?.modalHyperlinkColor};
      }
      .dialog-content-v2 a:not(.btn):hover span {
        color: ${props?.modalHyperlinkColorCopy};
      }
    `;
  }

  // OSC Done btn
  if (
    props?.modalDone?.textColor ||
    props?.modalDone?.backgroundColor ||
    props?.modalDone?.borderColor
  ) {
    styleString += `
      /* Done */
      .dialog-overlay [id*="radix"].dialog-content-v2 > div:last-of-type button.btn.btn-lg.btn-primary,
      .dialog-overlay [id*="radix"].dialog-content-v2 > div:last-of-type button.btn.btn-lg.btn-primary:after {
        color: ${props?.modalDone?.textColor};
        background-color: ${props?.modalDone?.backgroundColor};
        border-color: ${props?.modalDone?.borderColor};
        transition:none;
      }
      .dialog-overlay [id*="radix"].dialog-content-v2 > div:last-of-type .btn.btn-lg.btn-primary:hover,
      .dialog-overlay [id*="radix"].dialog-content-v2 > div:last-of-type .btn.btn-lg.btn-primary:hover:after {
        color: ${props?.modalDone?.textColorOnHover};
        background-color: ${props?.modalDone?.backgroundColorOnHover};
        border-color: ${props?.modalDone?.borderColorOnHover};
      }
    `;
  }
  // OSC Cancel btn
  if (
    props?.modalCancel?.textColor ||
    props?.modalCancel?.backgroundColor ||
    props?.modalCancel?.borderColor
  ) {
    styleString += `
      .dialog-overlay .dialog-content-v2 > div:last-of-type button.btn-lg.btn-primary-outline {
        color: ${props?.modalCancel?.textColor};
        background-color: ${props?.modalCancel?.backgroundColor};
        border-color: ${props?.modalCancel?.borderColor};
        transition:none;
      }
      .dialog-overlay .dialog-content-v2 > div:last-of-type button.btn-lg.btn-primary-outline:hover {
        color: ${props?.modalCancel?.textColorOnHover};
        background-color: ${props?.modalCancel?.backgroundColorOnHover};
        border-color: ${props?.modalCancel?.borderColorOnHover};
        transition:none;
      }
      .dialog-overlay .dialog-content-v2 > div:last-of-type button.btn-lg.btn-primary-outline::after {
        color: ${props?.modalCancel?.textColorOnHover};
        background-color: ${props?.modalCancel?.backgroundColorOnHover};
        border-color: ${props?.modalCancel?.borderColorOnHover};
        transition:none;
      }
      .dialog-overlay .dialog-content-v2 > div:last-of-type button.btn-lg.btn-primary-outline:focus {
        color: ${props?.modalCancel?.textColorOnHover};
        background-color: ${props?.modalCancel?.backgroundColorOnHover};
        border-color: ${props?.modalCancel?.borderColorOnHover};
        transition:none;
      }
    `;
  }

  // CheckBox inactive background color
  if (props?.checkboxInactiveBackgroundColor) {
    styleString += `
        .dialog-content-v2 input[type="checkbox"],
        .dialog-content-v2 input[type="radio"] {
          background-color: ${props?.checkboxInactiveBackgroundColor};
        }
      `;
  }

  // CheckBox active background color
  if (props?.checkboxBackgroundColor) {
    styleString += `
      .dialog-content-v2 input[type="checkbox"]:checked,
      .dialog-content-v2 input[type="radio"]:checked {
        background-color: ${props?.checkboxBackgroundColor};
      }
    `;
  }

  // CheckBox checkmark/outline color
  if (props?.checkboxOutlineCheckmarkColor) {
    const rgb = hexToRgb(props?.checkboxOutlineCheckmarkColor);
    styleString += `
      .dialog-content-v2 input {
        outline-color: ${props?.checkboxOutlineCheckmarkColor} !important;
      }
      .dialog-content-v2 input[type="checkbox"].form-checkbox:checked,
      .dialog-content-v2 input[type="radio"].form-checkbox:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='rgba(${rgb.r},${rgb.g},${rgb.b})' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
      }
    `;
  }

  // Input border color
  if (props?.inputBorderColor) {
    styleString += `
      .dialog-content-v2 input[type="checkbox"],
      .dialog-content-v2 input[type="radio"],
      .dialog-content-v2 input[type="text"] {
        border: 1px solid ${props?.inputBorderColor};
      }
    `;
  }

  // Horizontal dividing line when shop form is attached to Header
  if (props?.horizontalDividingLineColor) {
    styleString += `
      .cp-header-shop-form.border-t {
        border-color: ${props?.horizontalDividingLineColor} !important;
      }
      [data-active="true"] .cp-header-shop-form.border-t {
        border-color: ${props?.activeHorizontalDividingLineColor} !important;
      }
    `;
  }

  // Date ranges (anything in between two selected dates)
  if (props?.selectedBookPickerDateRangeBackground || props?.selectedBookPickerDateRangeText) {
    styleString += `
      [data-osc-product="search-dates"] table tbody td button.text-text,
      span[id^="calendar-month"] + table tbody td button.bg-secondary {
        ${
          props?.selectedBookPickerDateRangeBackground
            ? `background: ${props?.selectedBookPickerDateRangeBackground};`
            : ''
        }    
      }
      [data-osc-product="search-dates"] table tbody td button.text-text span,
      span[id^="calendar-month"] + table tbody td button.bg-secondary span,
      span[id^="calendar-month"] + table tbody td button.bg-secondary {
        ${
          props?.selectedBookPickerDateRangeText
            ? `color: ${props?.selectedBookPickerDateRangeText};`
            : 'color:#ffffff;'
        }
      }
    `;
  }

  if (props?.shopFormBgColor) {
    styleString += `
    div.generic-modal-booking-widget,
    [data-element-id="osc-composable-hero-div-wrapper"] {
      background: ${props?.shopFormBgColor} !important;
    }
    `;
  }

  return (
    <style
      data-style-type="osc-shop-form"
      data-testid="shop-form-style-tag"
      suppressHydrationWarning
    >
      {styleString}
    </style>
  );
}
