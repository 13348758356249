import { useMemo } from 'react';
import type { SupportedLanguage } from '@dx-ui/framework-i18n';
import { DEFAULT_LANGUAGE, DEFAULT_LOCALE_OPTIONS, LOCALE_OPTIONS } from './utils';

type UseLocale = {
  /** The supported language code to use */
  language: StringWithAutocompleteOptions<SupportedLanguage>;
  /** Any date format options to use. In addition to the default setting, The numbering system for the `ar` language code is set to `latn` */
  options?: Intl.DateTimeFormatOptions;
};

/**
 * Used to obtain the [Intl.Locale](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale).
 */
export const useLocale = ({
  language = DEFAULT_LANGUAGE,
  options = DEFAULT_LOCALE_OPTIONS,
}: UseLocale): Intl.Locale =>
  useMemo(
    () =>
      new Intl.Locale(language, {
        ...DEFAULT_LOCALE_OPTIONS,
        ...LOCALE_OPTIONS[language],
        ...options,
      }),
    [language, options]
  );

export default useLocale;
