import gql from 'graphql-tag-transform.macro';

export const MeetingCapacityCharts = gql`
  fragment MeetingCapacityCharts on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts {
      fieldGroupName
      title
      subtitle
      copy
      meetingCapacityChartsSettings {
        fieldGroupName
        showHide
        tableHeadings {
          backgroundColour
          fieldGroupName
          textColour
        }
        tableBody {
          rowTextColour2
          rowTextColour
          rowBackgroundColour
          fieldGroupName
          alternatingRowTextColour2
          alternatingRowTextColour
          alternatingRowBackgroundColour
        }
        unitTypeSwitch {
          toggleSwitchColour
          toggleOutlineColour
          toggleBackgroundColour
          textColourOfUnitTypeLabel
          textColourOfMeasurementType
          fieldGroupName
        }
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
