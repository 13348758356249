/**
 * Clean up image url by removing PATH set in next config
 *
 * This may make more sense to do on the API (WP) side
 */
interface imageOptsProps {
  deviceSizes?: number[];
  imageSizes?: number[];
  domains?: string[];
  path?: string;
  loader?: string;
}

const path = (process.env.__NEXT_IMAGE_OPTS as imageOptsProps)?.path || '';
export function imageUrl(url?: string) {
  if (!url) {
    return '';
  }
  return url.replace(path, '');
}
