import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./hubwhhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-48 fill-current antialiased xl:w-64" fillColor="#796646" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current antialiased lg:w-40" fillColor="#FFF" />
);
