export function EveningReception({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15,19.625H9a.375.375,0,0,0,0,.75h6a.375.375,0,0,0,0-.75Z" />
          <path d="M16.375,8a.349.349,0,0,0-.01-.0508,9.114,9.114,0,0,0-1.0434-4.1426A.375.375,0,0,0,15,3.625H9a.375.375,0,0,0-.3213.1816A9.114,9.114,0,0,0,7.6353,7.9492.349.349,0,0,0,7.625,8c0,.0112.0054.0207.0064.0316-.0007.0466-.0064.0882-.0064.1354,0,3.1382,3.21,5.5005,4,6.0351V18a.375.375,0,0,0,.75,0V14.2021c.79-.5346,4-2.8969,4-6.0351,0-.0472-.0057-.0888-.0064-.1354C16.37,8.0207,16.375,8.0112,16.375,8Zm-7.15-3.625h5.5508a8.7254,8.7254,0,0,1,.8287,3.25H8.3959A8.7254,8.7254,0,0,1,9.2246,4.375ZM12,13.5488c-.7655-.5325-3.47-2.5955-3.6116-5.1738h7.2231C15.4694,10.949,12.7645,13.0154,12,13.5488Z" />
        </g>
      </g>
    </svg>
  );
}
