import type { Track, VideoTrack } from '../video-player.controls';
import { getTrackId, trackKind } from './track';

type FindArgs = {
  activeTrack: Track;
  selectedVideoTrack: VideoTrack;
};

/**
 * @see findTrack
 */
export function findCaption({ selectedVideoTrack, activeTrack }: FindArgs) {
  return findTrack(selectedVideoTrack?.captionTracks || [], activeTrack);
}

/**
 * @see findTrack
 */
export function findTranscript({ selectedVideoTrack, activeTrack }: FindArgs) {
  return findTrack(selectedVideoTrack?.transcriptTracks || [], activeTrack);
}

/**
 * Attempts to find the related track based on the active track.
 */
function findTrack(tracks: Track[], activeTrack: Track) {
  return tracks.find((track) => track.language === activeTrack.language) || tracks[0] || null;
}

/**
 * Finds the selected caption HTMLTrackElement in the video element.
 */
export function findSelectedCaptionTrack(
  videoElement: HTMLVideoElement | null,
  activeTrack: Track
) {
  return (
    queryTracks(videoElement, trackKind.captionTrack).find(
      (track) => track.id === getTrackId(activeTrack)
    ) || null
  );
}

/**
 * Finds all caption tracks in the video element.
 */
export function findAllCaptionTracks(videoElement: HTMLVideoElement | null): HTMLTrackElement[] {
  return queryTracks(videoElement, trackKind.captionTrack);
}

/**
 * Finds the selected transcript HTMLTrackElement in the video element.
 */
export function findSelectedTranscriptTrack(
  videoElement: HTMLVideoElement | null,
  activeTrack: Track
) {
  return (
    queryTracks(videoElement, trackKind.transcriptTrack).find(
      (track) => track.id === getTrackId(activeTrack)
    ) || null
  );
}

function queryTracks(
  videoElement: HTMLVideoElement | null,
  kind: ValuesOf<typeof trackKind>
): HTMLTrackElement[] {
  return Array.from(videoElement?.querySelectorAll(`track[kind="${kind}"]`) || []);
}
