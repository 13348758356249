import { useIsClient } from 'usehooks-ts';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';

type UseAutoPlayOptions = { isAutoPlay: boolean };

export function useAutoPlay({ isAutoPlay = true }: UseAutoPlayOptions = { isAutoPlay: true }) {
  const isClient = useIsClient();
  const isReducedMotion = getIsReducedMotion();
  return isClient && isAutoPlay && !isReducedMotion;
}
