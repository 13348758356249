export function Checkmark({
  fill,
  className,
  aria,
}: {
  fill?: string;
  className?: string;
  aria?: boolean;
}) {
  const useFill = fill ? fill : '#1d1d1d';

  return (
    <div role={aria ? 'figure' : undefined} title={aria ? 'check' : undefined}>
      <svg className={className} viewBox="0 0 18 14" fill={useFill}>
        <g transform="translate(-1551.000000, -3261.000000)" fill={useFill}>
          <g transform="translate(831.000000, 3261.000000)">
            <polygon points="725.6 10.6 721.4 6.4 720 7.8 725.6 13.4 737.6 1.4 736.2 0" />
          </g>
        </g>
      </svg>
    </div>
  );
}
