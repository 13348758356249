import { useEffect, useState } from 'react';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_TextAlignment, GIS_Padder } from './functions/global-instance-styles';
import {
  WrappedHeading,
  inlineBackgroundStyles,
  sanitize,
  useHeadingLevel,
} from '@curated-property/utils';
import styles from './css/contentStyle.module.css';
import { HandleWYSIWYGContent, useWindowSize, HandleAnimations } from './functions/helper';
import type { FlexComponents } from 'apps/dx-curated-ui';

interface Props {
  componentIndex?: number;
  allComponents?: FlexComponents;
  corePlusHeadline?: string;
  corePlusShortDesc?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function HotelTitle({
  componentIndex,
  allComponents,
  corePlusHeadline,
  corePlusShortDesc,
  globalStyles,
  instanceStyles,
}: Props) {
  const [headerHeight, setHeaderHeight] = useState('0px');

  const headingLevel = useHeadingLevel(componentIndex, allComponents);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (typeof document !== 'undefined' && componentIndex === 0) {
      setHeaderHeight(`${document?.getElementsByTagName('header')?.[0]?.offsetHeight}px`);
    }
  }, [componentIndex, headerHeight, windowSize]);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const flexDirection =
    inlineStyles?.layoutDirection === 'col'
      ? 'lg:flex-col'
      : 'lg:flex-row md:px-16 md:py-8 xl:px-16 xl:py-16';
  const titleWidth = inlineStyles?.layoutDirection === 'col' ? 'w-full' : 'lg:w-5/12';
  const copyWidth = inlineStyles?.layoutDirection === 'col' ? 'w-full max-w-4xl' : 'lg:w-7/12';
  const headerStyles = inlineStyles?.layoutDirection === 'col' ? 'mb-6' : '';
  const copyStyles = inlineStyles?.layoutDirection === 'col' ? '' : 'lg:pl-2';
  const copyContainer = 'container';
  const image = instanceStyles?.image;

  let alignItems: string, imageAlignStyles: string;

  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
      break;
    case 'center':
      alignItems = 'items-center';
      imageAlignStyles = 'm-auto';
      break;
    case 'flex-end':
      alignItems = 'items-end';
      imageAlignStyles = 'ml-auto';
      break;
    default:
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : alignItems === 'items-center'
      ? '-translate-y-12'
      : alignItems === 'items-end'
      ? 'translate-x-8'
      : '-translate-x-8',
    end: 'translate-x-0 translate-y-0',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  return (
    <div
      data-testid="hotel-title"
      className={cx(inlineStyles?.showHide && 'hidden')}
      style={{
        transition: 'padding-top .5s ease',
      }}
    >
      <div
        className={cx('copy-block', paddingStyles)}
        style={{
          ...inlineBackgroundStyles(inlineStyles, 'component'),
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        }}
      >
        <div
          ref={animations?.ref}
          data-testid="offsetAndDirectionContainer"
          className={cx(
            'relative flex flex-col bg-right bg-no-repeat px-4',
            alignItems,
            copyContainer,
            flexDirection
          )}
          style={{
            ...inlineBackgroundStyles(inlineStyles, 'content'),
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
            textAlign: textAlignment,
            justifyContent: inlineStyles?.textAlignment,
          }}
        >
          <div className={cx('pr-2', titleWidth, animations?.one)}>
            <WrappedHeading header={headingLevel}>
              <p
                className={cx(
                  'font-headline text-4xl font-black leading-none md:text-5xl',
                  headerStyles
                )}
              >
                <span
                  className="block"
                  style={{ color: inlineStyles?.titleColor }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(corePlusHeadline),
                  }}
                />
              </p>
            </WrappedHeading>
          </div>
          <div
            className={cx(
              'mt-4 text-lg lg:mt-0',
              copyWidth,
              copyStyles,
              styles.listStyle,
              styles.paragraphStyle
            )}
          >
            <div
              className={animations?.two}
              data-testid="hotel-title-paragraph"
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(corePlusShortDesc, inlineStyles?.textColor),
              }}
            />
            {image?.sourceUrl ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                className={cx('my-8 block h-16 w-auto', imageAlignStyles, animations?.three)}
                src={image?.sourceUrl}
                alt={image?.altText}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
