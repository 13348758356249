import cx from 'classnames';
import Link from '../link-without-prefetch';
import Route from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import {
  slugify,
  iconmapper,
  isRelativeUrl,
  stripInternalUrl,
  removeSingleCmsSiteId,
} from '../functions/helper';
import { adobeEventTracking } from './adobe-analytics';
import { sanitize, useLanguage, useLinkContext } from '@curated-property/utils';
export interface ACFAnchorProps {
  fieldGroupName?: string;
  buttonStyle?: string | null;
  className?: string | null;
  link?: AnchorProps;
}

export interface AnchorProps {
  url?: string | null;
  title?: string | null;
  sectionHeading?: string | null;
  srContext?: string | null;
  target?: string | null;
  className?: string | null;
  buttonStyle?: string | null;
  tabIndex?: number;
  dataTestId?: string | null;
  omitIcon?: boolean;
  noHorizontalMargin?: boolean;
}

/*
  Some of these button classes dont exist on any component, and JIT has a hard time recognising them
  I've added them here so that they appear to be "used"
  btn-primary primaryOutline btn-primary-outline secondary btn-secondary secondaryOutline btn-secondary-outline tertiary btn-tertiary tertiaryOutline btn-tertiary-outline quarternary btn-quarternary quarternaryOutline btn-quarternary-outline white btn-white white btn-white-outline white btn-black white btn-black-outline
*/

export function AnchorLink({
  url,
  title,
  sectionHeading,
  srContext,
  target,
  className,
  buttonStyle,
  tabIndex,
  dataTestId,
  omitIcon,
  noHorizontalMargin,
}: AnchorProps) {
  const [isWA, setIsWA] = useState(false);
  const { itemPrefix = '' } = useLinkContext?.() || {};
  const language = useLanguage();
  const currentRoute = Route?.router?.asPath;
  const linkKey = currentRoute + '-' + slugify(title || '');
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof document !== 'undefined' && document?.body?.className?.includes('theme-wa')) {
      setIsWA(true);
    }
  }, [setIsWA]);

  const adobe = () => {
    adobeEventTracking({
      ctaText: title,
      sectionHeading: sectionHeading || srContext,
      actionDetail: 'Button',
      interactionName: 'propertyButton',
    });
  };

  if (!url || !title) {
    return null;
  }

  const iconMap = iconmapper();
  let iconKey = 'newWindow';
  const lowerUrl = url?.toLowerCase();
  if (title?.replace(/\s+/g, '')?.toLowerCase()?.includes('whatsapp')) {
    iconKey = 'whatsApp';
  } else if (lowerUrl?.includes('mailto:')) {
    iconKey = 'mail';
  } else if (lowerUrl?.includes('tel:')) {
    iconKey = 'telephone';
  } else if (lowerUrl?.includes('sms:')) {
    iconKey = 'mobile';
  }
  const Icon = iconMap[iconKey as keyof typeof iconMap];

  const urlWithoutSingleCmsSiteId = removeSingleCmsSiteId(url.trim());
  let processedUrl = stripInternalUrl(
    urlWithoutSingleCmsSiteId?.trim(),
    (process.env.NEXT_PUBLIC_INTERNAL_URLS || '').split(',')
  );

  if (processedUrl?.includes('-cms.dig.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig.hhc.hilton.com')[1];
  } else if (processedUrl?.includes('-cms.dig-s.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig-s.hhc.hilton.com')[1];
  } else if (processedUrl?.includes('-cms.dig-t.hhc.hilton.com')) {
    processedUrl = processedUrl?.split('-cms.dig-t.hhc.hilton.com')[1];
  }
  const basebuttonStyles = cx('btn text mt-2 overflow-hidden px-6 py-2 text-lg sm:text-xl', {
    'mx-1': !noHorizontalMargin,
  });

  const classes = cx(
    buttonStyle !== 'plain' ? basebuttonStyles : 'text-lg text-current',
    className
  );
  const isNotPlain = buttonStyle !== 'plain';
  const button = `btn-${buttonStyle}`;

  const handleExternalClick = () => {
    adobe();
  };

  if (isRelativeUrl(processedUrl)) {
    // prepend language and use it
    let finalUrl = processedUrl?.startsWith(`/${language}/`)
      ? processedUrl?.replace(`${language}/`, '')
      : processedUrl;
    finalUrl = `/${language}${itemPrefix}${finalUrl}`;
    return (
      /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
      <Link href={finalUrl} key={linkKey} legacyBehavior>
        <a
          href={finalUrl}
          data-testid={dataTestId}
          data-element-id="internal-anchor-link"
          tabIndex={tabIndex}
          className={`${classes} ${button}`}
          target={target || undefined}
          aria-label={`${
            srContext && !title?.toLowerCase().includes(srContext?.toLowerCase())
              ? title + ' for ' + srContext
              : title
          }${target === '_blank' ? `, ${t('opensInNewWindow')}` : ''}`}
          onClick={() => adobe()}
          dangerouslySetInnerHTML={{ __html: sanitize(title || '') }}
        />
      </Link>
    );
  }
  // full url should return standard a link
  return (
    <span
      className={cx('text-nowrap', {
        'me-9': isNotPlain && isWA,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        data-testid={dataTestId}
        data-element-id="external-anchor-link"
        key={linkKey}
        onClick={handleExternalClick}
        tabIndex={tabIndex}
        data-external-link
        href={removeSingleCmsSiteId(url)}
        className={`${classes} btn-${buttonStyle}`}
        target={target || undefined}
        aria-label={`${
          srContext && !title?.toLowerCase().includes(srContext?.toLowerCase())
            ? title + ' for ' + srContext
            : title
        }, ${t('opensInNewWindow')}`}
      >
        <span className="pointer-events-none flex items-center">
          <span dangerouslySetInnerHTML={{ __html: sanitize(title || '') }} />
          {!omitIcon && (
            <span
              className={cx('rtl:order-last', {
                'absolute start-full': isNotPlain && isWA,
              })}
            >
              <Icon fillColor="currentColor" className="ms-2 h-[1.25em]" />
            </span>
          )}
        </span>
      </a>
    </span>
  );
}
