import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./phxtcpr/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-36 fill-bg antialiased sm:w-40 md:mr-5" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-2.5 w-36 fill-bg antialiased sm:mr-0 sm:w-40" fillColor="#ffffff" />
);
