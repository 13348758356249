import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./sfofhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="w-24 antialiased" fillColor="#FFFFFF" />;

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 w-24 antialiased sm:mr-0" fillColor="#FFFFFF" />
);
