export type Dates = {
  arrivalDate?: Date | null;
  flexibleDates?: boolean;
  departureDate?: Date | null;
};

export type BaseUriBuilderParams<T1, T2> = {
  urlParams?: T1;
  relative?: boolean;
  locale?: string;
  path?: T2;
  brandCode?: keyof BrandCode;
  baseUrl?: string;
  brandSlug?: string;
  primarySlug?: string;
} & ({ brandCode?: never } | { brandSlug?: never });

export type BrandCode = {
  CH: 'CH';
  DT: 'DT';
  ES: 'ES';
  EY: 'EY';
  GI: 'GI';
  GU: 'GU';
  GV: 'GV';
  HH: 'HH';
  HI: 'HI';
  HP: 'HP';
  HT: 'HT';
  HW: 'HW';
  LX: 'LX';
  ND: 'ND';
  OL: 'OL';
  OU: 'OU';
  PE: 'PE';
  PO: 'PO';
  PY: 'PY';
  QQ: 'QQ';
  RU: 'RU';
  SA: 'SA';
  UA: 'UA';
  UP: 'UP';
  WA: 'WA';
  WW: 'WW';
};

export type Locale = {
  ar: 'ar';
  cs: 'cs';
  da: 'da';
  de: 'de';
  en: 'en';
  es: 'es';
  fi: 'fi';
  fr: 'fr';
  it: 'it';
  ja: 'ja';
  ko: 'ko';
  nl: 'nl';
  no: 'no';
  pl: 'pl';
  pt: 'pt';
  ro: 'ro';
  ru: 'ru';
  sv: 'sv';
  th: 'th';
  tr: 'tr';
  zh: 'zh';
};

export type Person = { age: number | null };
export type Adult = Partial<Person>;
export type Child = Person;

export type Room = {
  adults: Adult[] | number;
  children: Child[] | number;
};

export type CBPRoom = {
  adults: Adult[];
  children: Child[];
};

export type Rates = {
  aarp?: boolean;
  corporateAccount?: string;
  employeeRate?: boolean;
  friendsAndFamilyRate?: boolean;
  government?: boolean;
  groupCode?: string;
  offerId?: number | null;
  ownerHGVRate?: boolean;
  ownerVIPRate?: boolean;
  pnd?: string | null;
  promoCode?: string;
  requestedRatesOnly?: boolean;
  senior?: boolean;
  smbRate?: boolean | null;
  travelAgents?: boolean;
  tripleA?: boolean;
  usePoints?: boolean;
  [prop: string]: string | boolean | number | null | undefined;
};

export const RatePlanCodes = {
  AAA: 'HCAAA',
  AARP: 'HCARP',
  GOVERNMENT_MILITARY: 'GDSGOV',
  SENIOR: 'GDSSNR',
  USE_POINTS: 'HCSMR',
} as const;

export const RatePlanParamKeys = {
  aarp: 'aarpRate',
  corporateAccount: 'corporateCode',
  employeeRate: 'employeeRate',
  friendsAndFamilyRate: 'friendsAndFamilyRate',
  fromId: 'fromId',
  government: 'governmentOrMilitaryRate',
  groupCode: 'groupCode',
  offerId: 'offerId',
  ownerHGVRate: 'ownerHGVRate',
  ownerVIPRate: 'ownerVIPRate',
  pnd: 'pnd',
  promoCode: 'promotionCode',
  requestedRatesOnly: 'requestedRatesOnly',
  senior: 'seniorRate',
  smbRate: 'smbRate',
  spec_plan: 'spec_plan',
  travelAgentId: 'travelAgentId',
  travelAgents: 'travelAgentRate',
  tripleA: 'aaaRate',
  usePoints: 'redeemPts',
} as const;

export const sortByValues = [
  'distance',
  'points_ascending',
  'points_descending',
  'price_ascending',
  'price_descending',
  'brand',
] as const;
export type SortByValues = (typeof sortByValues)[number];
export function isSortByValue(someString: string): someString is SortByValues {
  return Boolean(sortByValues.find((value) => someString === value));
}

export const tripAdvisorRatings = [
  'fiveAndUp',
  'fourAndUp',
  'threeAndUp',
  'twoAndUp',
  'allRatings',
] as const;
export type TripAdvisorRatings = (typeof tripAdvisorRatings)[number];
export function isTripAdvisorRating(someString: string): someString is TripAdvisorRatings {
  return Boolean(tripAdvisorRatings.find((rating) => rating === someString));
}
