import type { DXExecutionResult, QueryDebugInfo } from '@dx-ui/types-graphql';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export type IncompleteQueryEvent = QueryDebugInfo & {
  message: string;
};

export function triggerInCompleteEvent({
  requestBody,
}: {
  requestBody: Partial<DXExecutionResult['__info__']>;
}) {
  if (isBrowser) {
    const event = new CustomEvent<IncompleteQueryEvent>('dx-incomplete', {
      detail: {
        message: 'Incomplete data received from server',
        operationName: requestBody?.operationName || '',
        originalOpName: requestBody?.originalOpName || '',
        dxCompleteness: '0',
        isSSR: false,
      },
    });
    window.dispatchEvent(event);
  }
}

export function isQueryIncomplete(response: Response) {
  return response.headers.get('dx-completeness') === '0';
}
