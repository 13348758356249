export function SprayBottle({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <circle cx="17" cy="4" r="0.625" />
            <circle cx="17" cy="6" r="0.625" />
            <circle cx="19" cy="5" r="0.625" />
            <circle cx="19" cy="3" r="0.625" />
            <circle cx="19" cy="7" r="0.625" />
            <path
              d="M15,3.625H7C6.8745,3.6252,6.7574,3.6878,6.6875,3.792l-2,3c-0.0986,0.149-0.079,0.3468,0.0469,0.4736l1,1
                    C5.8049,8.3358,5.9005,8.3752,6,8.375h0.62c0.0975,1.5129-0.0891,3.0307-0.55,4.475C5.7123,13.8596,5.561,14.9308,5.625,16v5
                    C5.6251,21.2071,5.7929,21.3749,6,21.375h8c0.2071-0.0001,0.3749-0.1679,0.375-0.375v-5c0.1009-1.4823-0.8032-2.8485-2.207-3.335
                    l-0.5235-0.2558C10.1031,11.6685,9.4313,11.3368,9.38,8.375H10C10.2071,8.3749,10.3749,8.2071,10.375,8
                    C10.3757,7.1028,11.1028,6.3757,12,6.375h3C15.2071,6.3749,15.3749,6.2071,15.375,6V4C15.3749,3.7929,15.2071,3.6251,15,3.625z
                     M11.3193,13.085l0.5127,0.25c1.0056,0.3251,1.708,1.2348,1.768,2.29H8c-0.2071,0-0.375,0.1679-0.375,0.375
                    c0,0.2071,0.1679,0.375,0.375,0.375h5.625v4.25h-7.25V16c-0.0629-0.9861,0.0746-1.9747,0.4043-2.9062
                    C7.2736,11.5725,7.475,9.9713,7.3727,8.375H8.631C8.6923,11.6631,9.566,12.2427,11.3193,13.085z M9.6543,7.625h-3.499
                    L5.4824,6.9521L7.2012,4.375h5.4238v1.25H12C10.8337,5.6268,9.8405,6.4736,9.6543,7.625z M14.625,5.625h-1.25v-1.25h1.25V5.625z"
            />
            <path
              d="M12.375,8c0-0.2071-0.1679-0.375-0.375-0.375S11.625,7.7929,11.625,8c0.0015,1.3111,1.0639,2.3735,2.375,2.375
                    c0.2071,0,0.375-0.1679,0.375-0.375S14.2071,9.625,14,9.625C13.1028,9.6243,12.3757,8.8972,12.375,8z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
