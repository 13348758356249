import type { Cookie, CookieSetOptions } from 'universal-cookie';
import Cookies from 'universal-cookie';

/**
 * Returns a cookie for a given name. Takes an optional server cookie param if it's from server side.
 * @param name - name of cookie being looked up
 * @param serverCookies - cookie (typically from req.headers)
 */
export function getCookie(name: string, serverCookies?: string) {
  const cookie = new Cookies(serverCookies);
  return cookie.get(name);
}

/**
 * Sets a cookie for a given name/value. Takes an optional cookie options.
 * @param name - name of cookie being set
 * @param value - Cookie
 * @param options - CookieSetOptions
 */
export function setCookie(name: string, value: Cookie, options?: CookieSetOptions) {
  const cookie = new Cookies();
  cookie.set(name, value, {
    domain: '.hilton.com',
    sameSite: 'lax',
    path: '/',
    ...options,
  });
}

/**
 * Deletes a cookie with a given name. Takes an optional cookie options.
 * @param name - name of cookie being deleted
 * @param options - CookieSetOptions
 */
export function deleteCookie(name: string, options?: CookieSetOptions) {
  const cookie = new Cookies();
  cookie.remove(name, {
    domain: '.hilton.com',
    path: '/',
    ...options,
  });
}
