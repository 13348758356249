import * as React from 'react';
import { PopupContext } from './popup.context';
import { focus } from './popup.utils';

/**
 * Presents more content in an expanded state after the user interacts with a trigger. Use `<Popup>` as a container around `<PopupButton>` and `<PopupContent>`.
 * Placement rules for the `<PopupContent>` are determined by its collision with the current viewport. The following rules determine the its position.
 *
 * | Collision                | Position |
 * | ------------------------ | ------ |
 * | bottom, top, right, left | middle, max-width |
 * | bottom, top, right       | middle, no-max-width |
 * | bottom, top, left        | middle, no-max-width |
 * | bottom, top              | middle, no-max-width |
 * | bottom                   | target-top |
 * | bottom, left             | target-top |
 * | bottom, right            | target-top |
 * | top                      | target-bottom |
 * | top, right, left         | middle, max-width |
 * | top, right               | target-bottom |
 * | top, left                | target-bottom |
 * | right, left              | target-bottom, max-width |
 * | right                    | target-bottom |
 * | left                     | target-bottom |
 */
export const Popup: React.FC<{ isOpen?: boolean; children: React.ReactNode }> = ({
  isOpen: isInitiallyOpen = false,
  children,
}) => {
  const id = React.useId();
  const [isOpen, setOpen] = React.useState<boolean>(isInitiallyOpen);
  const triggerRef = React.useRef<HTMLButtonElement | HTMLInputElement>(null);
  const triggerClickedRef = React.useRef(false);
  const popoverRef = React.useRef<HTMLSpanElement>(null);
  const closePopup = () => {
    setOpen(false);
    focus(triggerRef.current);
  };

  return (
    <PopupContext.Provider
      value={{
        triggerRef,
        triggerClickedRef,
        popoverRef,
        popupId: id,
        isOpen,
        setOpen,
        closePopup,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};

export default Popup;
