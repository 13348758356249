const MakaiLogo = ({ className, fill }: { className?: string; fill?: string }) => {
  return (
    <svg
      fill={fill}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="44"
      viewBox="0 0 150 44"
    >
      <path d="M20.59 39.425V41.045H18.77V39.425H17.87V43.475H18.77V41.875H20.59V43.475H21.48V39.425H20.59Z" />
      <path d="M24.73 39.415H23.83V43.465H24.73V39.415Z" />
      <path d="M27.08 39.415V43.465H30.01V42.645H27.98V39.415H27.08Z" />
      <path d="M31.15 39.415V40.215H32.39V43.465H33.28V40.215H34.52V39.415H31.15Z" />
      <path d="M38.41 40.125C37.64 40.125 37.11 40.705 37.11 41.435C37.11 42.195 37.64 42.745 38.41 42.745C39.2 42.745 39.71 42.185 39.71 41.435C39.71 40.705 39.2 40.125 38.41 40.125ZM38.41 43.565C37.11 43.565 36.22 42.755 36.22 41.435C36.22 40.115 37.11 39.305 38.41 39.305C39.71 39.305 40.6 40.105 40.6 41.435C40.6 42.765 39.71 43.565 38.41 43.565Z" />
      <path d="M45.48 39.415V40.775L45.54 42.055L43.5 39.415H42.72V43.465H43.63V42.205L43.6 40.875L45.62 43.465H46.39V39.415H45.48Z" />
      <path d="M56.1 39.415L55.62 40.805L55.27 42.105L54.32 39.415H53.73L52.82 42.105L52.46 40.795L51.95 39.415H50.94L52.45 43.465H53.19L54.03 41.195L54.9 43.465H55.64L57.11 39.415H56.1Z" />
      <path d="M60.61 40.325L61.31 41.975H59.91L60.61 40.325ZM60.12 39.415L58.3 43.465H59.27L59.57 42.765H61.65L61.95 43.465H62.93L61.11 39.415H60.12Z" />
      <path d="M65.7 39.415H64.8V43.465H65.7V39.415Z" />
      <path d="M70.3 39.415L68.95 41.085V39.415H68.05V43.465H68.95V41.735L70.45 43.465H71.54V43.415L69.75 41.385L71.4 39.435V39.415H70.3Z" />
      <path d="M75.12 40.125C74.35 40.125 73.82 40.705 73.82 41.435C73.82 42.195 74.35 42.745 75.12 42.745C75.91 42.745 76.42 42.185 76.42 41.435C76.43 40.705 75.91 40.125 75.12 40.125ZM75.12 43.565C73.82 43.565 72.93 42.755 72.93 41.435C72.93 40.115 73.82 39.305 75.12 39.305C76.42 39.305 77.31 40.105 77.31 41.435C77.31 42.765 76.42 43.565 75.12 43.565Z" />
      <path d="M79.43 39.415V43.465H82.36V42.645H80.34V39.415H79.43Z" />
      <path d="M86.25 40.125C85.48 40.125 84.95 40.705 84.95 41.435C84.95 42.195 85.48 42.745 86.25 42.745C87.04 42.745 87.55 42.185 87.55 41.435C87.55 40.705 87.04 40.125 86.25 40.125ZM86.25 43.565C84.95 43.565 84.06 42.755 84.06 41.435C84.06 40.115 84.95 39.305 86.25 39.305C87.55 39.305 88.44 40.105 88.44 41.435C88.44 42.765 87.55 43.565 86.25 43.565Z" />
      <path d="M92.25 40.325L92.94 41.975H91.54L92.25 40.325ZM91.76 39.415L89.94 43.465H90.91L91.21 42.765H93.29L93.59 43.465H94.57L92.75 39.415H91.76Z" />
      <path d="M101.7 39.415L100.56 42.405L99.42 39.415H98.41L100.1 43.485H101.03L102.72 39.415H101.7Z" />
      <path d="M105.5 39.415H104.6V43.465H105.5V39.415Z" />
      <path d="M107.85 39.415V43.465H110.78V42.645H108.75V39.415H107.85Z" />
      <path d="M112.77 39.415V43.465H115.71V42.645H113.68V39.415H112.77Z" />
      <path d="M119.53 40.325L120.22 41.975H118.82L119.53 40.325ZM119.04 39.415L117.22 43.465H118.19L118.49 42.765H120.57L120.87 43.465H121.85L120.03 39.415H119.04Z" />
      <path d="M126.46 40.485C126.21 40.245 125.84 40.125 125.51 40.125C124.74 40.125 124.24 40.565 124.24 41.455C124.24 42.035 124.56 42.755 125.51 42.755C125.79 42.755 126.06 42.705 126.37 42.475V41.825H125.4V41.075H127.15V42.845C126.8 43.345 126.25 43.595 125.5 43.595C123.94 43.595 123.35 42.515 123.35 41.455C123.35 40.315 124.02 39.305 125.51 39.305C126.06 39.305 126.56 39.455 127.02 39.935L126.46 40.485Z" />
      <path d="M129.32 39.415V43.465H132.41V42.635H130.21V41.815H132.21V41.025H130.21V40.245H132.33V39.415H129.32Z" />
      <path d="M58.98 26.935H61.05L58.22 20.375H44.25L41.25 26.935H43.45L45.53 22.355H56.94L58.98 26.935Z" />
      <path d="M61.41 27.175H58.83L56.78 22.595H45.68L43.6 27.175H40.88L44.1 20.145H58.38L61.41 27.175ZM59.13 26.705H60.69L58.06 20.605H44.4L41.61 26.705H43.3L45.38 22.125H57.09L59.13 26.705Z" />
      <path d="M52.19 0.675007L63.85 26.935H66.15L54.38 0.675007H52.19Z" />
      <path d="M66.52 27.175H63.7L63.64 27.035L51.83 0.445007H54.53L66.52 27.175ZM64 26.705H65.79L54.23 0.915007H52.56L64 26.705Z" />
      <path d="M54.22 16.105H48.24L51.23 9.25501L54.22 16.105ZM45.21 18.095H57.26L51.24 4.28501L45.21 18.095Z" />
      <path d="M57.62 18.325H44.85L51.23 3.69501L57.62 18.325ZM45.57 17.865H56.9L51.24 4.87501L45.57 17.865ZM54.58 16.345H47.89L51.24 8.67501L54.58 16.345ZM48.61 15.875H53.87L51.24 9.84501L48.61 15.875Z" />
      <path d="M48.1 0.675007L36.32 26.935H38.46L50.24 0.675007H48.1Z" />
      <path d="M38.61 27.175H35.95L47.95 0.445007H50.61L38.61 27.175ZM36.68 26.705H38.31L49.88 0.915007H48.25L36.68 26.705Z" />
      <path d="M128.32 26.935H130.39L127.56 20.375H113.59L110.58 26.935H112.79L114.87 22.355H126.27L128.32 26.935Z" />
      <path d="M130.75 27.175H128.17L126.13 22.595H115.03L112.95 27.175H110.23L113.45 20.145H127.73L130.75 27.175ZM128.47 26.705H130.03L127.4 20.605H113.73L110.94 26.705H112.63L114.71 22.125H126.42L128.47 26.705Z" />
      <path d="M121.53 0.675007L133.19 26.935H135.49L123.71 0.675007H121.53Z" />
      <path d="M135.85 27.175H133.03L121.17 0.445007H123.87L123.93 0.585007L135.85 27.175ZM133.34 26.705H135.13L123.56 0.915007H121.89L133.34 26.705Z" />
      <path d="M123.56 16.105H117.59L120.58 9.25501L123.56 16.105ZM114.55 18.095H126.6L120.57 4.28501L114.55 18.095Z" />
      <path d="M126.96 18.325H114.19L120.57 3.69501L126.96 18.325ZM114.91 17.865H126.24L120.57 4.87501L114.91 17.865ZM123.92 16.345H117.23L120.58 8.67501L123.92 16.345ZM117.94 15.875H123.2L120.57 9.84501L117.94 15.875Z" />
      <path d="M117.43 0.675007L105.66 26.935H107.8L119.58 0.675007H117.43Z" />
      <path d="M107.95 27.175H105.29L105.44 26.845L117.28 0.445007H119.94L107.95 27.175ZM106.02 26.705H107.65L119.22 0.915007H117.59L106.02 26.705Z" />
      <path d="M90.01 0.675007L82.23 9.34501L82.01 9.57501V12.435L92.59 0.675007H90.01Z" />
      <path d="M81.78 13.055V9.48501L82.06 9.18501L89.91 0.445007H93.12L81.78 13.055ZM82.25 9.66501V11.825L92.07 0.915007H90.12L82.25 9.66501Z" />
      <path d="M82.01 17.455L82.23 17.695L90.96 26.935H93.6L82.01 14.685V17.455Z" />
      <path d="M94.15 27.175H90.86L81.78 17.555V14.095L94.15 27.175ZM91.06 26.705H93.06L82.25 15.275V17.365L82.4 17.535L91.06 26.705Z" />
      <path d="M95.87 0.675007L84.21 13.435L96.97 26.935H99.66L86.72 13.415L98.63 0.675007H95.87Z" />
      <path d="M100.21 27.175H96.87L96.8 27.105L83.89 13.435L95.76 0.445007H99.17L87.05 13.415L100.21 27.175ZM97.07 26.705H99.11L86.4 13.415L98.09 0.915007H95.97L84.53 13.435L97.07 26.705Z" />
      <path d="M145.23 0.675007H143.24V26.935H145.23V0.675007Z" />
      <path d="M145.46 27.175H143V0.445007H145.46V27.175ZM143.47 26.705H144.99V0.915007H143.47V26.705Z" />
      <path d="M149.77 0.675007H147.78V26.935H149.77V0.675007Z" />
      <path d="M150 27.175H147.54V0.445007H150V27.175ZM148.01 26.705H149.53V0.915007H148.01V26.705Z" />
      <path d="M4.81 26.965H6.8V11.415L14.07 20.875L21.34 11.375V26.985H23.33V5.38501L13.99 17.845L4.81 5.28501V26.965ZM0.229996 26.965H2.22V0.715006H0.229996V26.965ZM21.18 0.715006L14.09 10.455L7.01 0.715006H4.6L14.09 13.835L23.68 0.715006H21.18ZM25.92 26.965H27.91V0.715006H25.92V26.965Z" />
      <path d="M28.15 27.205H25.69V0.475006H28.15V27.205ZM26.16 26.735H27.68V0.945006H26.16V26.735ZM23.57 27.205H21.11V12.055L14.07 21.255L7.03 12.105V27.205H4.58V4.56501L13.99 17.445L23.57 4.67501V27.205ZM21.58 26.735H23.1V6.08501L13.99 18.235L5.05 6.00501V26.735H6.57V10.715L14.08 20.475L21.59 10.665V26.735H21.58ZM2.46 27.205H0V0.475006H2.46V27.205ZM0.47 26.735H1.99V0.945006H0.47V26.735ZM14.09 14.235L4.14 0.475006H7.13L14.1 10.045L21.07 0.475006H24.16L14.09 14.235ZM5.06 0.945006L14.09 13.425L23.22 0.945006H21.3L14.09 10.845L6.89 0.945006H5.06Z" />
      <path d="M77.04 0.675007H75.05V26.935H77.04V0.675007Z" />
      <path d="M77.27 27.175H74.81V0.445007H77.27V27.175ZM75.28 26.705H76.8V0.915007H75.28V26.705Z" />
      <path d="M81.58 0.675007H79.59V26.935H81.58V0.675007Z" />
      <path d="M81.81 27.175H79.35V0.445007H81.81V27.175ZM79.82 26.705H81.34V0.915007H79.82V26.705Z" />
    </svg>
  );
};

export default MakaiLogo;
