import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./snamowa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-36 fill-current text-current antialiased sm:w-40 md:w-48"
    fillColor="#000000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="mr-2.5 w-36 fill-current text-current antialiased sm:mr-0 sm:w-40 md:w-48"
    fillColor="#FFFFFF"
  />
);
