import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sealxol/hotel-logo').then((i) => i.HotelLogo)
);

const HotelLogoLXR = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sealxol/hotel-logo-lxr').then((i) => i.HotelLogoLXR)
);
export const hotelLogoHeader = (
  <HotelLogoLXR className="w-32 py-4 antialiased xl:w-[19rem]" fillColor="#000" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-32 px-4 antialiased md:w-56" fillColor="#FFF" />
);
