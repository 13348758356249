import type { Icon } from '@dx-ui/osc-icon';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import type { MouseEvent } from 'react';
import { forwardRef } from 'react';
import { BrandIcon } from './brand-icon';
import type { Variant } from './util/button';
import { getClassnames } from './util/button';

export interface BrandLinkProps extends Omit<Link, 'label'> {
  label: React.ReactNode;
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
  /** OnClick callback */
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  metricsOnClick?: () => void;
  /** Icon alignment, "before" | "after" */
  iconAlign?: 'before' | 'after';
}

export const BrandLink = forwardRef<HTMLAnchorElement, BrandLinkProps>(
  (
    {
      label,
      iconProps,
      brandComponentTheme,
      variant,
      onClick,
      metricsOnClick,
      iconAlign = 'before',
      ...linkProps
    },
    ref
  ) => (
    <Link
      {...linkProps}
      ref={ref}
      anchorClassName={cx(
        linkProps.anchorClassName,
        getClassnames({ brandComponentTheme, variant })
      )}
      className={cx('gap-2', linkProps.className)}
      isNewWindow={linkProps.isNewWindow || false}
      url={linkProps.url}
      showNewWindowIcon={(linkProps.showNewWindowIcon && linkProps.isNewWindow) || false}
      underline={false}
      data-testid="CTALinkE2ETest"
      onClick={(e) => {
        onClick?.(e);
        metricsOnClick?.();
      }}
    >
      {iconAlign === 'before' && iconProps ? <BrandIcon {...iconProps} /> : null}
      {label}
      {iconAlign === 'after' && iconProps ? <BrandIcon {...iconProps} /> : null}
    </Link>
  )
);

BrandLink.displayName = 'BrandLink';

export default BrandLink;
