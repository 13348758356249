export function Surfboard({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <path
            d="M20.37305,3.96582c-0.01603-0.18009-0.15878-0.32284-0.33887-0.33887c-2.17535-0.07677-4.33803,0.36193-6.31152,1.28028
                    C10.57617,6.30566,6.27441,9.5166,3.64648,16.874c-0.06938,0.19536,0.03275,0.40998,0.22812,0.47936
                    C3.91486,17.36766,3.95727,17.37498,4,17.375h2.625V20c0.00041,0.20752,0.16897,0.37542,0.37649,0.37501
                    c0.04242-0.00008,0.08452-0.00735,0.12451-0.02149C21.23633,15.31348,20.38379,4.0791,20.37305,3.96582z M18.58069,9.64319
                    c-0.31703-0.81524-0.91742-1.48881-1.691-1.8971c-0.95896-0.4216-2.04391-0.45555-3.02735-0.09472
                    c-0.19256,0.07724-0.28605,0.29596-0.20881,0.48852c0.03362,0.08382,0.09631,0.15272,0.17659,0.19409
                    C14.41315,8.68287,14.7606,9.32096,14.7373,10c0,0.96875-0.4746,2.124-2.7373,2.124
                    c-1.05623,0.00452-2.05269-0.48954-2.68848-1.333c-0.11645-0.17117-0.34961-0.21552-0.52078-0.09907
                    C8.6214,10.80714,8.57588,11.03694,8.68852,11.208C9.4642,12.25882,10.6939,12.87748,12,12.874
                    c3.14746,0,3.4873-2.00976,3.4873-2.874c0.01338-0.66735-0.22591-1.3151-0.66992-1.81348
                    c0.59239-0.09716,1.2003-0.01556,1.7461,0.23438c0.81393,0.46801,1.38789,1.2628,1.57617,2.18262l0.00079,0.00152
                    c-1.536,3.06592-4.61413,6.53858-10.76544,8.85785V17C7.37494,16.79292,7.20708,16.62506,7,16.625H4.53711
                    C9.02051,4.73145,17.85254,4.31152,19.64258,4.35742C19.65033,6.17308,19.28905,7.97139,18.58069,9.64319z"
          />
        </g>
      </g>
    </svg>
  );
}
