import gql from 'graphql-tag-transform.macro';

export const ResortMap = gql`
  fragment ResortMap on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_ResortMapV2 {
      fieldGroupName
      mapCategoryDescription
      mapCategoryMobileButtonPosition
      mapCategoryTitle
      mapOverlayPosition
      mapImage {
        sourceUrl
        altText
        mediaDetails {
          height
          width
        }
      }
      mapPins {
        pinTitle
        pinX
        pinY
        pinCategory
        pinModal
        pinModalDescription
        pinModalTitle
        pinModalImage {
          sourceUrl(size: _540X390)
          altText
          mediaDetails {
            height
            width
          }
        }
        modalLinkText
        modalLink2 {
          url
          target
        }
        modalLink {
          ... on Page {
            id
            slug
            link
          }
        }
      }
      resortMapV2Settings {
        fieldGroupName
        showHide
        mapControlsColor
        mapControlsBackgroundColor
        tabBackgroundColor
        tabTextColor
        selectedTabBackgroundColor
        selectedTabTextColor
        mobileFilterDropdownBackgroundColor
        mapControlsPlacement
        mobileFilterDropdownTextColor
        pinAccentColor
        pinColor
        subtitleColor
        textAlignment
        titleColor
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
