import cx from 'classnames';
import { imageUrl } from '@curated-property/utils';
import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { HeroImageProps } from './hero-image.types';
import type { StyleObject } from '../functions/global-instance-styles';

export default function ImageTag(
  props: HeroImageProps & {
    showMobileCropping?: boolean;
    hideAnimations?: boolean;
    inlineStyles?: StyleObject;
  }
) {
  const imgUrl = imageUrl(props?.imgSrc);

  return imgUrl ? (
    <div className="cp-heroImageWrapper">
      <CroppedImage
        loader={() => {
          return customLoader({
            src: imgUrl,
            width: 1920,
            height: 1080,
            crop: props?.enableCropping,
            cropType: props?.cropType,
            cropHeight: props?.showMobileCropping
              ? props?.mobileCroppingCropHeight
              : props?.cropHeight,
            cropWidth: props?.showMobileCropping
              ? props?.mobileCroppingCropWidth
              : props?.cropWidth,
            xPosition: props?.showMobileCropping
              ? props?.mobileCroppingXPosition
              : props?.xPosition,
            yPosition: props?.showMobileCropping
              ? props?.mobileCroppingYPosition
              : props?.yPosition,
            xPositionAdvanced: props?.showMobileCropping
              ? props?.mobileCroppingXPositionAdvanced
              : props?.xPositionAdvanced,
            yPositionAdvanced: props?.showMobileCropping
              ? props?.mobileCroppingYPositionAdvanced
              : props?.yPositionAdvanced,
            autoPosition: props?.showMobileCropping
              ? props?.mobileCroppingAutoPosition
              : props?.autoPosition,
            quality: 70,
          });
        }}
        src={imgUrl}
        className={cx('z-[15]', !props?.hideAnimations ? 'animate-fade-in-slow' : '')}
        objectFit="cover"
        objectPosition={props?.imgPositioning || props?.inlineStyles?.contentBackgroundPosition}
        alt={props?.imgAlt}
        layout="fill"
        priority
      />
    </div>
  ) : null;
}
