export function Lounge({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18,9.625h-.625V7A2.3777,2.3777,0,0,0,15,4.625H9A2.3777,2.3777,0,0,0,6.625,7V9.625H6A1.377,1.377,0,0,0,4.625,11v4A1.377,1.377,0,0,0,6,16.375H18A1.377,1.377,0,0,0,19.375,15V11A1.377,1.377,0,0,0,18,9.625ZM7.375,7A1.6262,1.6262,0,0,1,9,5.375h6A1.6262,1.6262,0,0,1,16.625,7V9.625H16A1.377,1.377,0,0,0,14.625,11v1.625H9.375V11A1.377,1.377,0,0,0,8,9.625H7.375Zm11.25,8a.6256.6256,0,0,1-.625.625H6A.6256.6256,0,0,1,5.375,15V11A.6256.6256,0,0,1,6,10.375H8A.6256.6256,0,0,1,8.625,11v2A.3751.3751,0,0,0,9,13.375h6A.3751.3751,0,0,0,15.375,13V11A.6256.6256,0,0,1,16,10.375h2a.6256.6256,0,0,1,.625.625Z" />
          <path d="M7,16.625A.3751.3751,0,0,0,6.625,17v2a.375.375,0,0,0,.75,0V17A.3751.3751,0,0,0,7,16.625Z" />
          <path d="M17,16.625a.3751.3751,0,0,0-.375.375v2a.375.375,0,0,0,.75,0V17A.3751.3751,0,0,0,17,16.625Z" />
        </g>
      </g>
    </svg>
  );
}
