import type { Rule } from '@dx-shared/rule-engine';
import { RuleEngine } from '@dx-shared/rule-engine';
import type { QueryClient } from '@tanstack/react-query';
import { serverSideFeatureConfigsQuery } from '../generated/queries';
import type { SupportedLanguage } from './types';

export type TranslatedUrlProps = {
  /** path of the url to evaluate in the RuleEngine. */
  path: string;
  /** Langauge to be used for evaluating whether the `path` is translated or not. */
  locale: SupportedLanguage;
  /** name of the feature config rule used to obtain the rule conditions. */
  rule: string;
  /** The default language. if the `locale` and the `defaultLocale` are the same returns true. */
  defaultLocale?: SupportedLanguage;
  /** react-query client used to perform the query to get the conditions */
  queryClient: QueryClient;
};

export type LegacyTranslatedUrlProps = Omit<TranslatedUrlProps, 'queryClient'> & {
  endpoint: string;
  appName: string;
};

/**
 * Determines if a url has been translated. This determination is performed by the [RuleEngine](https://gitlab.svc-m.hhc.hilton.com/pac/hiw/dx-shared/-/tree/develop/packages/rule-engine) and is run with two facts:
 * - url - @see path
 * - language - @see locale
 *
 * @returns boolean - whether the `path` has been translated for `locale`
 */
export async function isTranslatedUrl({
  path,
  locale,
  rule,
  queryClient,
  defaultLocale = 'en',
}: TranslatedUrlProps) {
  if (locale === defaultLocale) {
    return true;
  }
  // Prevent errant 404s on client-side transitions. See https://jira.hilton.com/confluence/x/CYiECg for details
  if (/^\/_next\/data\//.test(path)) {
    return true;
  }

  const { featureConfigs } = await serverSideFeatureConfigsQuery(queryClient, { names: [rule] });
  const engine = new RuleEngine(featureConfigs[0] as Rule);
  const facts = {
    url: path,
    language: locale,
  };
  const isTranslated = engine.run(facts);
  return isTranslated;
}

/**
 * @deprecated - Performs same function as `isTranslatedUrl` but with a straight `fetch` and not `react-query`
 * @returns boolean - whether the `path` has been translated for `locale`
 */
export async function isLegacyTranslatedUrl({
  path,
  locale,
  rule,
  endpoint,
  appName,
  defaultLocale = 'en',
}: LegacyTranslatedUrlProps) {
  if (locale === defaultLocale) {
    return true;
  }
  // Prevent errant 404s on client-side transitions. See https://jira.hilton.com/confluence/x/CYiECg for details
  if (/^\/_next\/data\//.test(path)) {
    return true;
  }

  const modifiedEndpoint = `${endpoint}?appName=${appName}&operationName=featureConfigs`;
  const query = `query featureConfigs { 
    featureConfigs(names: ${JSON.stringify([rule])}) {
      name
      conditions
      config
    } 
  }`;
  const options: RequestInit = {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'featureConfigs',
      query,
    }),
  };
  const response = await fetch(modifiedEndpoint, options);

  const { data } = await response.json();
  const engine = new RuleEngine(data?.featureConfigs[0] as Rule);
  const facts = {
    url: path,
    language: locale,
  };
  const isTranslated = engine.run(facts);
  return isTranslated;
}

export default isTranslatedUrl;
