import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./vcehihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-current text-current antialiased sm:w-32" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 w-24 fill-current text-bg antialiased md:w-28" />
);
