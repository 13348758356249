import { LanguageSelector } from '@dx-ui/osc-language-selector';
import cx from 'classnames';

type Props = {
  locale: string;
  CTYHOCN?: string;
  languageSelectorStyles?: LangSelectorStyleProps;
  cmsTranslationOverrides?: Array<TranslationOverrideProps | undefined | null> | null;
};

export interface LangSelectorStyleProps {
  textColour?: string | null;
  textCase?: 'uppercase' | 'lowercase' | 'capitalize' | 'none';
  backgroundColour?: string | null;
  textColourHover?: string | null;
  backgroundColourHover?: string | null;
  defaultTextColour?: string | null;
  labelTextColour?: string | null;
  labelTextCase?: string | null;
  accentColour?: string | null;
}

export interface TranslationOverrideProps {
  __typename?: string | null;
  languageCode?: string | null;
  reservationsOnly?: boolean | null;
  urlOverride?: string | null;
}

type languageOverride =
  | {
      [key: string]: string | undefined;
    }
  | undefined;

export function OSCLanguageSelector({
  locale,
  CTYHOCN,
  languageSelectorStyles,
  cmsTranslationOverrides,
}: Props) {
  // Reduce the translation array from the CMS to an object with language code as key and url as value
  // Also remove Res Only languages
  const FullSiteTranslationObj: languageOverride = cmsTranslationOverrides?.reduce((acc, item) => {
    if (item?.languageCode && item?.urlOverride && !item?.reservationsOnly) {
      if (!acc) acc = {};
      acc[item?.languageCode?.toLowerCase()] = item?.urlOverride;
    }
    return acc;
  }, {} as languageOverride);

  // Filter the translation array and reduce to items which contain resOnly = true
  const FullSiteTranslationObjFiltered = cmsTranslationOverrides?.filter(
    (item) => item?.reservationsOnly
  );

  // Reduce the filtered array to an object with language code as key and url as value
  const resOnlyTranslationObj: languageOverride = FullSiteTranslationObjFiltered?.reduce(
    (acc, item) => {
      if (item?.languageCode && item?.urlOverride) {
        if (!acc) acc = {};
        acc[item?.languageCode.toLowerCase()] = item?.urlOverride;
      }
      return acc;
    },
    {} as languageOverride
  );

  return (
    <div id="language-selector" data-element-id="language-wrapper">
      <LanguageSelector
        appName="dx-curated-ui"
        locale={locale}
        ctyhocn={CTYHOCN}
        popupOptions={{ includeTitles: true }}
        wrapperClassName="sm:!text-sm py-1 ps-2 hover:[&_a_span]:no-underline language-selector-menu-wrapper"
        buttonOptions={{
          className: 'flex justify-around items-center h-7 py-4 scale-100 transition-all ps-3',
          styles: {
            color: languageSelectorStyles?.textColour || languageSelectorStyles?.defaultTextColour,
            textTransform: languageSelectorStyles?.textCase || 'none',
            backgroundColor: languageSelectorStyles?.backgroundColour,
          },
          hoverStyles: {
            color:
              languageSelectorStyles?.textColourHover || languageSelectorStyles?.defaultTextColour,
            backgroundColor:
              languageSelectorStyles?.backgroundColourHover ||
              languageSelectorStyles?.accentColour ||
              'rgba(235, 234, 234, 0.35)',
          },
        }}
        labelOptions={{
          className: cx('font-bold', languageSelectorStyles?.labelTextCase),
          styles: { color: languageSelectorStyles?.labelTextColour },
        }}
        urlOverrides={{
          fullSite: {
            ...FullSiteTranslationObj,
          },
          resOnly: {
            ...resOnlyTranslationObj,
          },
        }}
      />
    </div>
  );
}
