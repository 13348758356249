import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./bnaleci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    fillColor="#000000"
    className="h-24 w-44 fill-current text-current antialiased md:w-48"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    fillColor="#ffffff"
    className="h-12 w-40 fill-current text-current antialiased md:w-48 "
  />
);
