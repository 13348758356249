// actually belongs to the demo site - hilton in a box logo
import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./eywcmqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="size-24 antialiased" />;

export const hotelLogoFooter = <HotelLogo className="h-30 w-44 antialiased" />;
