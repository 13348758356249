import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sezhihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-24 antialiased md:w-28" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 w-20 antialiased md:mr-0 md:w-28" fillColor="#ffffff" />
);
