import gql from 'graphql-tag-transform.macro';

export const Offset = gql`
  fragment Offset on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_Offset {
      description
      fieldGroupName
      heading
      image1 {
        altText
        slug
        sourceUrl
        title
        caption
      }
      image1CroppingAutoPosition
      image1Cropping
      image1CroppingCropHeight
      image1CroppingCropType
      image1CroppingCropWidth
      image1CroppingEnableCropping
      image1CroppingOrientation
      image1CroppingXPosition
      image1CroppingXPositionAdvanced
      image1CroppingYPosition
      image1CroppingYPositionAdvanced
      image2 {
        altText
        sourceUrl
        slug
        caption
      }
      image2CroppingYPositionAdvanced
      image2CroppingYPosition
      image2CroppingXPositionAdvanced
      image2CroppingXPosition
      image2CroppingOrientation
      image2CroppingEnableCropping
      image2CroppingCropWidth
      image2CroppingCropType
      image2CroppingCropHeight
      image2Cropping
      image2CroppingAutoPosition
      image2CaptionImageCaption {
        captionLink {
          url
          title
          target
        }
        captionText
        fieldGroupName
      }
      image1CaptionImageCaption {
        captionLink {
          target
          title
          url
        }
        captionText
        fieldGroupName
      }
      reverseDirection
      buttons {
        buttonStyle
        fieldGroupName
        link {
          target
          title
          url
        }
      }
      offsetComponentSettings {
        imageCaptionTextColor
        imageCaptionBackgroundColor
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        animationDirection
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
