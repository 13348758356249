import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sinodci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-30 h-20 fill-black text-bg antialiased md:mr-5 md:w-32"
    fillColor="#A98B5F"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-20 w-40 fill-black text-bg antialiased md:w-32" fillColor="#000" />
);
