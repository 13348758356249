export function NewHotels({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M20.3555,14.9355c-0.051-0.1495-0.1898-0.2512-0.3477-0.2548l-2.7461-0.0586l-0.9053-2.74
            c-0.0668-0.1963-0.2801-0.3014-0.4764-0.2346c-0.1942,0.0661-0.2995,0.2758-0.2364,0.471l0.9892,2.9912
            c0.0504,0.1506,0.1898,0.2533,0.3486,0.2568l1.9747,0.042l-1.59,1.2676c-0.1188,0.0942-0.169,0.2508-0.1269,0.3965l0.5683,1.9775
            l-1.5859-1.1611c-0.1323-0.0957-0.3111-0.0957-0.4434,0l-1.5858,1.161l0.5683-1.9775c0.0421-0.1457-0.0081-0.3023-0.1269-0.3965
            l-1.59-1.2676l1.9747-0.042c0.2069-0.0048,0.371-0.1759,0.3671-0.3828c-0.0136-0.2029-0.1795-0.3621-0.3828-0.3672
            l-3.0107,0.0645c-0.2068,0.0041-0.3712,0.175-0.3672,0.3819c0.0022,0.1117,0.0541,0.2166,0.1416,0.286l2.2041,1.7569
            l-0.8027,2.791c-0.0573,0.199,0.0576,0.4068,0.2567,0.4641c0.1116,0.0321,0.2317,0.0107,0.3253-0.0579L16,18.6562l2.25,1.6465
            c0.1671,0.1224,0.4017,0.0862,0.5241-0.0809c0.0686-0.0936,0.09-0.2138,0.0579-0.3253l-0.8027-2.791l2.2041-1.7569
            C20.3574,15.2506,20.4063,15.0852,20.3555,14.9355z"
            />
            <path
              d="M7.375,10C7.3749,9.7929,7.2071,9.6251,7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v5
            c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
            C7.2071,10.3749,7.3749,10.2071,7.375,10z"
            />
            <path
              d="M16.625,5v5c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V5C17.3739,4.2411,16.7589,3.6261,16,3.625h-6
            C9.2411,3.6261,8.6261,4.2411,8.625,5v11c0,0.2071,0.1679,0.375,0.375,0.375S9.375,16.2071,9.375,16V5
            C9.3753,4.655,9.655,4.3753,10,4.375h6C16.345,4.3753,16.6247,4.655,16.625,5z"
            />
            <path
              d="M13.375,12c-0.0001-0.2071-0.1679-0.3749-0.375-0.375h-2c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h2
            C13.2071,12.3749,13.3749,12.2071,13.375,12z"
            />
            <path
              d="M15,9.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,9.625,15,9.625z"
            />
            <path
              d="M15,7.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,8.375,11,8.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,7.625,15,7.625z"
            />
            <path
              d="M15,5.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,6.375,11,6.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,5.625,15,5.625z"
            />
            <path
              d="M7.0068,13.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,14.375,6,14.375h1.0068c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2139,13.625,7.0068,13.625z"
            />
            <path
              d="M7,11.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,12.375,6,12.375h1c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,11.625,7,11.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
