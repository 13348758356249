import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { DtrumApi } from '@dynatrace/dtrum-api-types';

declare global {
  interface Window {
    dtrum?: DtrumApi;
  }
}

/**
 * Simple wrapper around the Dynatrace RUM API.
 * {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html}
 */
export const dynatrace = isBrowser ? window.dtrum : undefined;
