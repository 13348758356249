import { type SVGAttributes } from 'react';

export function Info(props: SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="none" fill={props?.style?.stroke} fillRule="evenodd">
        <g transform="translate(-16.000000, -14.000000)">
          <g transform="translate(16.000000, 14.000000)">
            <g className="g-stroke-fill" stroke="currentColor" strokeWidth="2">
              <path d="M12,23 C17.6721901,23 23,17.8578989 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,17.8578989 6.32780988,23 12,23 Z" />
            </g>
            <circle fill="currentColor" cx="12" cy="7.71428571" r="1" />
            <rect
              fill="currentColor"
              x="11.1428571"
              y="9.71428571"
              width="1.71428571"
              height="7.42857143"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
