import type { LocationTypes } from '@dx-ui/framework-places-autocomplete';
import type { IconNames } from '@dx-ui/osc-icon';

export const LocationIcons: { [key in LocationTypes]: (typeof IconNames)[number] } = {
  address: 'location',
  airport: 'airport',
  geocode: 'location',
  pointOfInterest: 'point-of-interest',
  property: 'hotel',
};
