import type { OutgoingMessage } from 'http';
export function mergeCacheControl(
  resp: Response,
  serverResp?: OutgoingMessage & {
    mergeHttpHeaders?: (headers: Record<string, string>) => void;
  }
) {
  if (serverResp && serverResp.mergeHttpHeaders) {
    const headers: Record<string, string> = {};
    resp.headers.forEach((val: string, key: string) => {
      headers[key] = val;
    });
    serverResp.mergeHttpHeaders(headers);
  }
}
