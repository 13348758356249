export const twoColumnIconListSettings = `
  twoColumnIconColour
  twoColumnLabelColour
`;

export const tabbedTableDataSettings = `
  activeTabAccentColour
  activeTabTextColour
  tableAltRowBackgroundColour
  tableAltRowTextColour
  tableHeaderBackgroundColour
  tableHeaderTextColour
  tableRowBackgroundColour
  tableRowTextColour
`;

export const galleryWallSettings = `
  containerMaxWidth
  galleryWallCaptionColour
  galleryWallBackgroundColour
  galleryWallBackgroundOpacity
  galleryWallControlsBackgroundColour
  galleryWallControlsIconColour
`;

export const imageGallerySettings = `
galleryTilesBackgroundColour
galleryTilesBackgroundOpacity
galleryTilesControlsBackgroundColour
galleryTilesControlsCaptionColour
galleryTilesControlsIconColour
hideImageCount
`;

export const googleMapSettings = `
  layout
  contentAlignment
  buttonStyle
  enableSatelliteView
`;

export const htmlMenuSettings = `
  tabBackgroundColour
  tabTextColour
  tabUnderlineColour
  activeTabBackgroundColour
  activeTabTextColour
  activeTabUnderlineColour
  menuItemLine1Colour
  menuItemLine2Colour
  menuSpotlightItemColour
  titleWeight
  subtitleWeight
  line1Weight
  line2Weight
  priceWeight
  disclaimerWeight
  menuItemsLine1
  menuItemsLine2
  menuItemsPrice
  menuItemsDisclaimer
  sectionDisclaimerText
  sectionDisclaimerTitle
  sectionTitle
  sectionSubtitle
  tabTitle
`;

export const keyInfoGridSettings = `
  containerMaxWidth
  gridContentColor
  gridSubtitleColor
  gridTitleColor
  columnBoxBackgroundColor
  columnBoxTextColor
  columnBoxIconColor
  columnTitleColor
  gradientPosition
  backgroundGradientOpacity
  backgroundGradientColor
  columnBoxIconAlignment
  contentBackgroundOpacity
  layoutOptions
`;

export const hotelPoliciesSettings = `
  iconIndicatorColour
  iconIndicatorColourExpanded
  openPanelBackgroundColour
  borderColour
`;

export const mediaAndCopySettings = `
  firstItemAlignment
  altRowContentBackgroundColor
  altRowContentBackgroundSize
  altRowContentBackgroundRepeat
  altRowContentBackgroundPosition
  altRowContentBackgroundImage {
    sourceUrl
  }
  titleLogoiconBottomMargin
  altRowSubtitleColor
  altRowTextAlignment
  altRowTextColor
  altRowTitleColor
  mediaAndCopyPadding
  mediaAndCopyRowPadding
  uiBackgroundColour
  uiControlsColour
  paginationSeperatorColour
  paginationTextColour
  paginationBackgroundColour
  containerMaxWidth
  imageInsetBorderColour
  imageInsetBorderWidth
`;

export const mediaAndCopyCarouselSettings = `
  mediaAndCopyCarouselHeaderTitleColour
  mediaAndCopyCarouselHeaderCopyColour
  uiPrimaryColour
  uiSecondaryColour
`;

export const twoOneMediaCopySettings = `
  horizontalDirection
  verticalDirection
  smallImageBorderColour
  smallImageBorderWidth
`;

export const offersCuratedComponentSettings = `
  additionalDataAccentColor
  arrowDataAccentColor
  tagBackgroundColor
  tagDisabledTextColor
  tagDisabledBackgroundColor
  tileBackgroundColor
  tileTextColor
  tagTextColor
  buttonStyles {
    bookButtonStyle
    termsBookButtonStyle
    termsButtonStyle
  }
  tileTypeSort
`;

export const iconGridCorePlusSettings = `
  iconCoreColour
  labelCoreColour
  modalBackgroundColor
  modalTextColor
  modalTitleColor
  modalTitleUnderlineColor
`;

export const iconGridSettings = `
  iconBlockColour
  labelBlockColour
`;

export const multiColumnTilesWithHoverSettings = `
  nonHoverPadding
  nonHoverBackgroundColor
  hoverBackgroundColor
  imageOverlayGradient
`;

export const copyBlockSettings = `
  layoutDirection
  padding
`;

export const hotelTitleSettings = `
  layoutDirection
  image {
    altText
    sourceUrl
  }
`;

export const fullWidthMediaComponentSettings = `
  contentBoxBackgroundOpacity
  contentBoxHorizontal
  contentBoxVertical
  contentBoxPadding
  gradientPosition
  gradientToggle
  uiPrimaryColour
  uiSecondaryColour
  imageInsetBorderColour
  imageInsetBorderWidth
`;

export const roomsComponentSettings = `
  rateFromColor
  rateFigureColor
  rateErrorColor
  rateCurrencyColor
  rateBackgroundColor
  modalAccentColor
  modalBackgroundColor
  modalIconColor
  modalTextColor
  modalHeadingColor
  modalActiveBookCtaBackgroundColor
  modalActiveBookCtaBorderColor
  modalActiveBookCtaTextColor
  modalBookCtaBackgroundColor
  modalBookCtaBorderColor
  modalBookCtaTextColor
  paginationTextColor
  paginationSeperatorColor
  paginationBackgroundColor
  filterTitleColor
  filterRuleColor
  filterResultsColor
  filterBackgroundColor
  fybrDisabledColor
  fybrDividerColor
  fybrNavigationColor
  fybrStepperColor
  fybrSubheadingColor
  fybrTextColor
  filterTagButtonStyle
  clearAllButtonStyle
  controlsIconColor
  controlsBackgroundColor
  activeBookCtaBackgroundColor
  activeBookCtaBorderColor
  activeBookCtaTextColor
  activeViewDetailsBackgroundColor
  activeViewDetailsBorderColor
  activeViewDetailsTextColor
  viewDetailsTextColor
  viewDetailsBorderColor
  viewDetailsBackgroundColor
  bookCtaBackgroundColor
  bookCtaBorderColor
  bookCtaTextColor
  disableDropShadow
  feeTransparencyTextInExpandedDropdown
  feeTransparencyDefaultColor
  dateTextColor
  dayOfTheWeekColor
  monthColor
  dividingLineColor
  dropdownAndAccessibleCheckboxBackground
  dropdownAndAccessibleCheckboxOutline
  dropdownAndAccessibleCheckboxTextIcon
  checkboxActiveBackground
  checkboxActiveOutlineAndCheckmark
  selectActiveBackground
  selectActiveText
  selectDividingLine
  selectActiveCheckboxCheckmarkAndOutline
  selectActiveCheckboxBackground
`;

export const meetingGroupSettings = `
  iconColor
  buttonStyle
  ctaBackgroundColor
  ctaBackgroundColorHover
  ctaTextColor
  ctaTextColorHover
`;

export const meetingGroupOverrides = `
  eEventsUrlOverride
  rfpUrlOverride
  hiltonLinkOverride
`;

export const meetingCapacitySettings = `
  statisticBlockTextAlignment
  statisticNumber
  subtitleColor
  textAlignment
  textColor
  titleAlignment
  titleColor
  separatorColor
  statLabelNumber
  contentBoxVertical
  contentBoxHorizontal
  dimensionColor
`;

export const accordionSettings = `
  iconIndicatorColour
  iconIndicatorColourExpanded
  openPanelBackgroundColour
  borderColour
`;

export const buttonClone = `
  buttons {
    buttonStyle
    link {
      target
      title
      url
    }
  }
`;

export const clonedCropSettings = `
  enableCropping
  orientation
  cropType
  cropHeight
  cropWidth
  xPosition
  xPositionAdvanced
  yPosition
  yPositionAdvanced
  autoPosition
`;

export const meetingCapacityCharts = `
  tableHeadings {
    backgroundColour
    fieldGroupName
    textColour
  }
  tableBody {
    rowTextColour2
    rowTextColour
    rowBackgroundColour
    fieldGroupName
    alternatingRowTextColour2
    alternatingRowTextColour
    alternatingRowBackgroundColour
  }
  unitTypeSwitch {
    toggleSwitchColour
    toggleOutlineColour
    toggleBackgroundColour
    textColourOfUnitTypeLabel
    textColourOfMeasurementType
    fieldGroupName
  }
`;

export const eventsCalendarSettings = `
  paddingTop {
    fieldGroupName
    paddingTopDesktop
    paddingTopMobile
    paddingTopTablet
  }
  paddingBottom {
    fieldGroupName
    paddingBottomDesktop
    paddingBottomMobile
    paddingBottomTablet
  }
  componentAndContentBackgroundColors {
    calendarComponentBgColor
    calendarDatePickerBgColor
    calendarDesktopGridBgColor
    fieldGroupName
  }
  calendarUiSettings {
    additionalCalendarButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    regularEventButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    specialEventButtons {
      backgroundColor
      backgroundColorOnHover
      borderColor
      borderColorOnHover
      fieldGroupName
      textColor
      textColorOnHover
    }
    usesRegularEventButtonColors
    selectedDatepickerDateText
    selectedDatePickerDateRangeBg
    selectedDatePickerDateBg
    filterCheckboxColor
    fieldGroupName
    datePickerResetButtonText
    datePickerResetButtonBg
    dailyEventsSectionBgColor
    baseTextColor
    baseIconColor
    additionalTextFilterColor
  }
`;

export const MediaBlockCropFrag = (strings: TemplateStringsArray) => `
  image${strings[0]} {
    fieldGroupName
    imageCrop11AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPositionAdvanced
      yPosition
    }
    imageCrop12AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPosition
      yPositionAdvanced
    }
    imageCrop21AddCrop {
      autoPosition
      cropHeight
      cropType
      cropWidth
      enableCropping
      fieldGroupName
      xPosition
      xPositionAdvanced
      yPosition
      yPositionAdvanced
    }    
  }
`;

export const MediaBlocksFrag = `
  mediaBlockLayouts {
    mediaLayout {
      ... on Media_block_Mediablocklayouts_MediaLayout_SingleImage {
        fieldGroupName
        image {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`CropSingle`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_TwoImageHoz {
        fieldGroupName
        imageTop {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopCrop`}
        imageBottom {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_TwoImageVert {
        fieldGroupName
        imageLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`LeftCrop`}
        imageRight {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`RightCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneTopTwoBottom {
        fieldGroupName
        imageTop {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopCrop`}
        imageBottomLeft {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomLeftCrop`}
        imageBottomRight {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`BottomRightCrop`}
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoTopOneBottom {
        fieldGroupName
        imageBottom {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomCrop`}
        imageTopLeft {
          sourceUrl
          altText
        }        
        ${MediaBlockCropFrag`TopLeftCrop`}
        imageTopRight {
          sourceUrl
          altText
        }       
        ${MediaBlockCropFrag`TopRightCrop`} 
      }  
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneLeftAndTwoRight {
        fieldGroupName
        imageLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`LeftCrop`}
        imageTopRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopRightCrop`}       
        imageBottomRight {
          sourceUrl
          altText
        } 
        ${MediaBlockCropFrag`BottomRightCrop`}        
      }
      ... on Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoLeftAndOneRight {
        fieldGroupName
        imageTopLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopLeftCrop`}
        imageBottomLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomLeftCrop`}        
        imageRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`RightCrop`}       
      } 
      ... on Media_block_Mediablocklayouts_MediaLayout_FourImage {
        fieldGroupName
        imageTopLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopLeftCrop`}       
        imageBottomLeft {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomLeftCrop`}               
        imageTopRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`TopRightCrop`}               
        imageBottomRight {
          sourceUrl
          altText
        }
        ${MediaBlockCropFrag`BottomRightCrop`}              
      }                   
      ... on Media_block_Mediablocklayouts_MediaLayout_Video {
        fieldGroupName
        videoUrl
      }
    }
  }
`;

export const gallerySliderComponentSettings = `
  imageCounterTextColour
  imageCounterBackgroundColour
  carouselControlsArrowColour
  carouselControlsBackgroundColour
`;

export const informationBarComponentSettings = `
borderBottomColor
borderTopColor
contactBackgroundColor
contactBorderColor
iconColor
dividerColor
`;

export const videoMediaCopyComponentSettings = `
  horizontalDirection
`;

export const multiColumnListComponentSettings = `
  dividerColor
  listItemColor
  columnHeadingColor
`;

export const mediaAndCopyOverlaySettings = `
  imageInsetBorderColour
  imageInsetBorderWidth
`;
