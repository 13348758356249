export function PauseControl({ className, fill }: { className?: string; fill?: string }) {
  return (
    <svg
      className={className}
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill={fill ?? '#121212'}
      stroke={fill ?? '#121212'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="2" y1="8.74229e-08" x2="2" y2="14" strokeWidth="4" />
      <line x1="9" y1="8.74229e-08" x2="9" y2="14" strokeWidth="4" />
    </svg>
  );
}
