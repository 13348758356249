export function Menu({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,11.625H5a.375.375,0,0,0,0,.75H19a.375.375,0,0,0,0-.75Z" />
          <path d="M5,8.375H19a.375.375,0,0,0,0-.75H5a.375.375,0,0,0,0,.75Z" />
          <path d="M19,15.625H5a.375.375,0,0,0,0,.75H19a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
