import { useEffect } from 'react';
import {
  sanitize,
  makeRandomInt,
  WrappedSubtitle,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Accordion } from '@dx-ui/osc-accordion';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_TextAlignment, GIS_Padder } from './functions/global-instance-styles';
import { HandleWYSIWYGContent } from './functions/helper';
import { HeadingStyle } from './includes/heading-style';

interface SectionRowProps {
  rowLabel?: string;
  rowText?: string;
}

function SectionRow({ rowLabel, rowText }: SectionRowProps) {
  return (
    <li className={cx('block w-full py-2', { 'sm:flex': rowLabel !== null })}>
      {rowLabel && (
        <div
          className={cx('w-full font-extrabold', {
            'sm:w-1/2 lg:w-1/3': rowText,
          })}
        >
          {rowLabel}
        </div>
      )}
      {rowText && (
        <div
          className={cx('w-full', { 'sm:w-1/2 lg:w-2/3': rowLabel !== null })}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(rowText),
          }}
        />
      )}
    </li>
  );
}

interface SectionProps {
  panelLabel?: string;
  panelRepeater?: SectionRowProps[];
}

function AccordionSection({ panelRepeater }: SectionProps) {
  return (
    <div className="px-2 text-lg" data-testid="accordion-section">
      <ul>
        {panelRepeater?.map((i) => (
          <SectionRow key={i?.rowLabel} rowLabel={i?.rowLabel} rowText={i?.rowText} />
        ))}
      </ul>
    </div>
  );
}

export interface AccordionProps {
  content?: string;
  heading?: string;
  subtitle?: string;
  panel?: {
    panelLabel?: string;
    panelRepeater?: SectionRowProps[];
  }[];
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function AccordionComponent({
  content,
  heading,
  subtitle,
  panel,
  instanceStyles,
  globalStyles,
}: AccordionProps) {
  const { t } = useTranslation();

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'accordionComponent';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `#${componentStyleID} [data-osc="accordion-component"]`;

  // This will be injected into <head> on an instance-by-instance bases
  let styleString = '';

  const accordionBorderColour = inlineStyles?.accordionBorderColour || '';

  accordionBorderColour &&
    (styleString += `${mainSelector} button.accordion-stacked { border-color:${accordionBorderColour} !important; } `);

  styleString += `
    ${mainSelector} li ul li {
      margin-inline-start: 2rem;
    }

    ${mainSelector} ul { list-style-type: disc; }
    ${mainSelector} ol { list-style-type: decimal; }
  `;

  inlineStyles?.textColor &&
    (styleString += `${mainSelector} button.accordion-stacked,${mainSelector} button.accordion-stacked + section div {color: ${inlineStyles?.textColor}; }`);

  const accordionOpenPanelBackgroundColour = inlineStyles?.accordionOpenPanelBackgroundColour || '';
  accordionOpenPanelBackgroundColour &&
    (styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] + section  {background-color:${accordionOpenPanelBackgroundColour};} `);

  // Default icon color is black.
  const accordionIconIndicatorColour = inlineStyles?.accordionIconIndicatorColour || '#000';

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] span svg { fill:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] span svg { color:${accordionIconIndicatorColour} !important; }`;

  // The expanded state setting uses the unexpanded state setting absent a specific setting of its own.
  const accordionIconIndicatorColourExpanded = !inlineStyles?.accordionIconIndicatorColourExpanded
    ? accordionIconIndicatorColour
    : inlineStyles?.accordionIconIndicatorColourExpanded;

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] span svg { fill:${accordionIconIndicatorColourExpanded} !important; }`;

  // Adds style to <head>
  useEffect(() => {
    const $style = document.createElement('style');
    $style.setAttribute('id', styleElementID);
    document.head.appendChild($style);
    $style.innerHTML = styleString;
  }, [styleElementID, styleString]);

  const accordionItems = panel?.map(({ panelLabel, panelRepeater }) => {
    const filteredPanelRepeater = panelRepeater
      .map((item) => {
        if (item.rowText === null && item.rowLabel === null) {
          return null;
        } else return item;
      })
      .filter(Boolean);

    const formattedKey = panelLabel.toLowerCase().replace(' ', '-');
    return {
      collapsedButtonLabel: panelLabel,
      expandedButtonLabel: panelLabel,
      key: formattedKey,
      content: <AccordionSection panelLabel={panelLabel} panelRepeater={filteredPanelRepeater} />,
    };
  });

  return (
    <div id={componentStyleID} className={inlineStyles?.showHide && 'hidden'}>
      <div
        data-osc="accordion-component"
        className={paddingStyles}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : null,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
          backgroundColor: inlineStyles?.componentBackgroundColor || null,
        }}
      >
        <div
          className="container py-8"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : null,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || null,
            backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
            backgroundColor: inlineStyles?.contentBackgroundColor || null,
          }}
        >
          {heading && (
            <h2
              style={{
                color: inlineStyles?.titleColor || null,
                textAlign: textAlignment || null,
              }}
              className="mb-4 font-headline text-2xl font-black leading-none md:text-4xl"
              dangerouslySetInnerHTML={{
                __html: sanitize(t(heading)),
              }}
            />
          )}

          {subtitle && (
            <WrappedSubtitle>
              <HeadingStyle
                text={subtitle}
                style={{ textAlign: textAlignment || null }}
                type={!heading ? 'h2' : 'p'}
                className="my-4 font-sans text-xl font-normal md:text-xl"
                textColorInline={inlineStyles?.subtitleColor}
              />
            </WrappedSubtitle>
          )}

          {content && (
            <div
              className="mb-8"
              style={{
                color: inlineStyles?.textColor || null,
                textAlign: textAlignment || null,
              }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(content, inlineStyles?.textColor),
              }}
            />
          )}
          <Accordion
            className="accordion-stacked flex w-full justify-between border-t border-text px-2 py-4 text-2xl font-extrabold aria-expanded:border-b brand-ch:tracking-[3px] [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&_span]:flex [&_span]:items-center"
            type="multiple"
            variant="stacked"
            items={accordionItems}
            headingLevel="h3"
          />
        </div>
      </div>
    </div>
  );
}
