import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';

export const GetTranslateAutocompleteConfigDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs {
  featureConfigs(names: ["config-ui-translate-autocomplete"]) {
    config
    name
  }
}`,
  originalOpName: 'getTranslateAutocompleteConfig',
};
export function useGetTranslateAutocompleteConfigQuery<
  TData = Types.GetTranslateAutocompleteConfigQuery,
  TError = DXError
>(
  variables?: Types.GetTranslateAutocompleteConfigQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.GetTranslateAutocompleteConfigQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.GetTranslateAutocompleteConfigQuery, TError, TData>({
    queryKey: [GetTranslateAutocompleteConfigDocument, variables],
    ...options,
  });
}
export function serverSideGetTranslateAutocompleteConfigQuery(
  queryClient: QueryClient,
  variables?: Types.GetTranslateAutocompleteConfigQueryVariables,
  options?: FetchQueryOptions<Types.GetTranslateAutocompleteConfigQuery>
) {
  return queryClient.fetchQuery<Types.GetTranslateAutocompleteConfigQuery>({
    queryKey: [GetTranslateAutocompleteConfigDocument, variables],
    ...options,
  });
}

export const GeocodeCoordinateDocument = {
  operationName: 'geocode',
  operationString: `query geocode($location: GeocodeCoordinateInput, $language: String!, $sessionToken: String) {
  geocode(language: $language, location: $location, sessionToken: $sessionToken) {
    match {
      address {
        city
        country
        stateName
      }
    }
  }
}`,
  originalOpName: 'geocodeCoordinate',
};
export function useGeocodeCoordinateQuery<TData = Types.GeocodeCoordinateQuery, TError = DXError>(
  variables: Types.GeocodeCoordinateQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodeCoordinateQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodeCoordinateQuery, TError, TData>({
    queryKey: [GeocodeCoordinateDocument, variables],
    ...options,
  });
}
export function serverSideGeocodeCoordinateQuery(
  queryClient: QueryClient,
  variables: Types.GeocodeCoordinateQueryVariables,
  options?: FetchQueryOptions<Types.GeocodeCoordinateQuery>
) {
  return queryClient.fetchQuery<Types.GeocodeCoordinateQuery>({
    queryKey: [GeocodeCoordinateDocument, variables],
    ...options,
  });
}
