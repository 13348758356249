export function Pause({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <g>
            <path d="M12,3.625c-4.6254,0-8.375,3.7496-8.375,8.375S7.3746,20.375,12,20.375s8.375-3.7496,8.375-8.375     C20.3699,7.3767,16.6233,3.6301,12,3.625z M12,19.625c-4.2112,0-7.625-3.4138-7.625-7.625S7.7888,4.375,12,4.375     S19.625,7.7888,19.625,12C19.62,16.2091,16.2091,19.62,12,19.625z" />
            <path d="M10.5,8.625C10.2929,8.6251,10.1251,8.7929,10.125,9v6c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V9     C10.8749,8.7929,10.7071,8.6251,10.5,8.625z" />
            <path d="M13.5,8.625C13.2929,8.6251,13.1251,8.7929,13.125,9v6c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V9     C13.8749,8.7929,13.7071,8.6251,13.5,8.625z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
