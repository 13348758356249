import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./laxavci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#a98b5f" className="h-24 w-44 text-bg antialiased md:w-48" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#a98b5f" className="h-12 w-40 antialiased md:w-48" />
);
