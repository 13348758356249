import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./miambhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-24 antialiased md:w-28" fillColor="#104c97" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-2.5 w-32 antialiased sm:mr-0" fillColor="#104c97" />
);
