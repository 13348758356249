import { useEffect, useState } from 'react';
import { Add, Subtract } from '@curated-property/icon-list';
import type { MapControl } from './interactive-map.types';
import cx from 'classnames';

export function MapControls({
  mapOverlayPosition,
  mapControlsPlacement,
  iconColor,
  backgroundColor,
  mapState,
  setMapState,
  mapHeight,
  mapWidth,
}: MapControl) {
  const [disabled, setDisabled] = useState<string>('out');

  useEffect(() => {
    setDisabled('out');
  }, [mapHeight, mapWidth]);

  let mapControlsPosition: string;
  switch (mapControlsPlacement) {
    case 'left-top':
      mapControlsPosition = 'left-3 bottom-5 lg:top-3';
      break;
    case 'left-bottom':
      mapControlsPosition = 'left-3 bottom-5';
      break;
    case 'right-top':
      mapControlsPosition = 'right-3 bottom-5 lg:top-3';
      break;
    case 'right-bottom':
      mapControlsPosition = 'right-3 bottom-5';
      break;
    default:
      mapControlsPosition = 'right-3 bottom-5';
  }

  const handleZoom = (dir: boolean) => {
    const zoomIn: number = mapState.scale + 0.25;
    const zoomOut: number = mapState.scale - 0.25;
    const widthLimit: number = mapWidth * zoomOut - mapWidth;
    const heightLimit: number = mapHeight * zoomOut - mapHeight;
    const constrainPanX: number =
      mapState?.translation?.x < -widthLimit ? -widthLimit : mapState?.translation?.x;
    const constrainPanY: number =
      mapState?.translation?.y < -heightLimit ? -heightLimit : mapState?.translation?.y;

    if (dir && zoomIn <= 2) {
      setMapState({ ...mapState, scale: zoomIn });
      setDisabled(zoomIn === 2 ? 'in' : '');
    } else if (!dir && zoomOut >= 1) {
      setMapState({
        scale: zoomOut,
        translation: { x: constrainPanX, y: constrainPanY },
      });
      setDisabled(zoomOut === 1 ? 'out' : '');
    }
  };

  return (
    <div
      data-testid="map-controls"
      data-element-id="map-controls-wrapper"
      className={cx('absolute', mapControlsPosition, {
        'left-3 right-auto': mapOverlayPosition === 'right' && !mapControlsPlacement,
      })}
    >
      <div className="bg-bg-alt flex flex-col rounded-sm" style={{ backgroundColor }}>
        <button
          data-testid="zoom-in"
          aria-label="Zoom in"
          onClick={() => handleZoom(true)}
          disabled={disabled === 'in'}
        >
          <Add
            bold={true}
            className="h-7"
            fillColor={disabled === 'in' ? '#1d1d1d4d' : iconColor || '#000'}
          />
        </button>
        <div className="bg-overlay h-px" style={{ backgroundColor: iconColor }} />
        <button
          data-testid="zoom-out"
          aria-label="Zoom out"
          onClick={() => handleZoom(false)}
          disabled={disabled === 'out'}
        >
          <Subtract
            bold={true}
            className="h-7"
            fillColor={disabled === 'out' ? '#1d1d1d4d' : iconColor || '#000'}
          />
        </button>
      </div>
    </div>
  );
}
