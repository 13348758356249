/* eslint-disable no-console */

import { dynatrace } from './logger';
import * as React from 'react';
import { logToTestAccumulator, useTestLogBuffer } from './testLogAccumulator';

import { log, LOG_LEVELS } from './log';
import type { LogArgs } from './log';

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies client-side `LOG_LEVEL` of `ERROR` which extends 'WARN' and also enables alerting in Dynatrace if the error rate is high. @see {@link https://docs.dynatrace.com/docs/platform/davis-ai/basics/terminology#alert | Davis AI Basics - Alerting}
 * For server-side, it will leverage `console.error`.
 *
 * @param key           The key of the error. For example: 'Booking Error'
 * @param value         The error value. For example: 'Payment failed to process.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited.  For example: `dx-res-ui:234327|hint`
 */
export function logError(key: LogArgs[0], value: LogArgs[1], hint = '') {
  log(key, value, LOG_LEVELS.ERROR, hint);
}

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies client-side `LOG_LEVEL` of `WARN` which extends 'INFO' and also measures "Apdex" performance in Dynatrace.  @see {@link https://docs.dynatrace.com/docs/shortlink/apdex | Apdex Ratings}
 * For server-side, it will leverage `console.warn`.
 *
 * @param key           The key of the error. For example: 'Hotel Not Found'
 * @param value         The error value. For example: 'ctyhocn or propCode not found.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited.  For example: `dx-res-ui:234327|hint`
 */
export function logWarning(key: LogArgs[0], value: LogArgs[1], hint = '') {
  if (useTestLogBuffer) {
    logToTestAccumulator(key, value, LOG_LEVELS.WARN, hint);
  } else {
    log(key, value, LOG_LEVELS.WARN, hint);
  }
}

/**
 * Wrapper for {@link https://www.dynatrace.com/support/doc/javascriptapi/interfaces/dtrum_types.DtrumApi.html#reportCustomError | reportCustomError}.
 *
 * Specifies client-side `LOG_LEVEL` of `INFO` which captures the error in Dynatrace.  @see {@link https://docs.dynatrace.com/docs/shortlink/application-performance#top-errors | Top Errors} and {@link https://docs.dynatrace.com/docs/shortlink/configure-application-errors | Configure Application Errors}
 * For server-side, it will leverage `console.info`.
 *
 * @param key           The key of the error. For example: 'Validation Error'
 * @param value         The error value. For example: 'First name does not match the regular expression.'
 * @param hint          A hint to pinpoint the problem.
 *                        - By default this includes the app version.
 *                        - Anything over 100 characters will be truncated.
 *                        - The hint will appear as pipe delimited.  For example: `dx-res-ui:234327|hint`
 */
export function logInfo(key: LogArgs[0], value: LogArgs[1], hint = '') {
  if (useTestLogBuffer) {
    logToTestAccumulator(key, value, LOG_LEVELS.INFO, hint);
  } else {
    log(key, value, LOG_LEVELS.INFO, hint);
  }
}

/**
 * Custom hook to enable manual page detection.
 *
 * @param {string} name - New view name. Usually it matches the location.pathname.
 * @param {string} group - The group associated with the page. The app name.
 */
export const useManualPageLogger = (name: string, group: string): void => {
  React.useEffect(() => {
    if (!dynatrace) {
      return console.warn('Dynatrace is not available.');
    }
    dynatrace.enableManualPageDetection();
    const modifiedGroup = window.location.pathname.startsWith('/en/') ? group : `${group}-NonEN`;
    const pageResult = dynatrace.setPage({ name, group: modifiedGroup });
    if (pageResult < 0) {
      console.warn('Error setting page group in Dynatrace');
    }
  });
};

export { LOG_LEVELS };
