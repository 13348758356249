import type { MenuProps, FooterProps, BreadcrumbProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const Header = (props: MenuProps) => {
  const { t } = useTranslation();
  return (
    <SharedHeader
      {...props}
      bookingButtonText={t('book')}
      styleOptions={{
        bgColorOnLoad:
          'linear-gradient(rgba(0,0,0,.6) 0%, rgba(0,0,0,.5) 45%, rgba(0,0,0,.4) 60%, rgba(0,0,0,.3) 70%, rgba(0,0,0,.2) 80%, rgba(0,0,0,.1) 90%, rgba(0,0,0,0) 100%)',
      }}
    />
  );
};

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  return <SharedBreadCrumbs {...props} delimiter={<span className="m-2 text-primary">/</span>} />;
};

// this override footer
const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      styleOptions={{
        backgroundColor: 'bg-bg-alt',
        backgroundImage:
          'https://assets.hiltonstatic.com/images/v1622114524/dx/wp/mangohouse/media-library/bg-fabric-pattern/bg-fabric-pattern.jpg',
        textColor: 'text-primary',
      }}
    />
  );
};
