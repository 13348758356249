import gql from 'graphql-tag-transform.macro';
import { htmlMenuSettings } from './common-vars';

export const HtmlMenu = gql`
fragment HtmlMenu on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_HtmlMenu {
        header
        header_noTx: header
        menu {
          ... on Html_menu {
            id
          }
        }
        tabTypeButtonStyle
        menuRepeater {
          menuSections {
            fieldGroupName
            pricePosition
            sectionBackgroundColour
            sectionBorderColour
            itemDescriptionColour
            itemDisclaimerColour
            itemPriceColour
            itemTitleColour
            sectionAlignment
            sectionDisclaimer
            sectionDisclaimerLine1
            sectionDisclaimerLine1_noTx: sectionDisclaimerLine1
            sectionDisclaimerLine2
            sectionDisclaimerLine2_noTx: sectionDisclaimerLine2
            sectionDisclaimerColour
            spotlightBorderColour
            sectionItems {
              fieldGroupName
              itemDisclaimer
              itemDisclaimer_noTx: itemDisclaimer
              itemLine1
              itemLine1_noTx: itemLine1
              itemLine2
              itemLine2_noTx: itemLine2
              itemPrice
              itemPrice_noTx: itemPrice
              highlightItem
            }
            sectionLayout
            sectionSubtitle
            sectionSubtitle_noTx: sectionSubtitle
            sectionTitle
            sectionTitle_noTx: sectionTitle
          }
          fieldGroupName
          tabTitle
          tabTitle_noTx: tabTitle
        }
        htmlMenuSettings {
          fieldGroupName
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${htmlMenuSettings}
        }
      }
}
`;
