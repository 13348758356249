import { useEffect, useState } from 'react';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from './functions/global-instance-styles';
import { InstagramIcon } from '@curated-property/icons';
import { appliedCloudinaryParams } from '@curated-property/utils';

/* istanbul ignore next  */
export function SocialMediaFeedPropMapper(
  data?: Props & {
    feedId?: string;
    socialLinkUrl?: string;
    socialMediaFeedSettings?: StyleObject;
  },
  global?: StyleObject
) {
  return {
    socialTitle: data?.socialTitle,
    feedID: data?.feedId,
    socialLinkTitle: data?.socialLinkTitle,
    socialLinkURL: data?.socialLinkUrl,
    globalStyles: global?.socialMediaFeedSettings,
    instanceStyles: data?.socialMediaFeedSettings,
  };
}

interface Props {
  socialTitle?: string;
  feedID?: string;
  socialLinkTitle?: string;
  socialLinkURL?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function SocialMediaFeed({
  socialTitle,
  feedID,
  socialLinkTitle,
  socialLinkURL,
  globalStyles,
  instanceStyles,
}: Props) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const [buttonsCount, setButtonsCount] = useState(0);

  function setAriaOnButtons() {
    const b = document.querySelectorAll('.slick-arrow');
    b.forEach((e) => {
      e.setAttribute('tab-index', '-1');
      e.setAttribute('aria-hidden', 'true');
    });
  }

  const interval = setInterval(checkForButtons, 2000);

  function checkForButtons() {
    if (typeof window !== 'undefined') {
      const btns = document.querySelectorAll('.slick-arrow');
      if (btns) {
        setButtonsCount(btns.length);
      }
    }
  }

  useEffect(() => {
    if (buttonsCount >= 2) {
      setAriaOnButtons();
      const groups = document.querySelectorAll('.juicer-feed');
      groups.forEach((e) => {
        e.setAttribute('tab-index', '-1');
      });
      clearInterval(interval);
    }
  }, [buttonsCount, interval]);

  useEffect(() => {
    const juicerResourcesWrapper = document.createElement('div');
    juicerResourcesWrapper.setAttribute('class', 'juicer-io-resources-wrapper');

    const script = document.createElement('script');
    script.src = 'https://assets.juicer.io/embed.js';
    script.type = 'text/javascript';

    const link = document.createElement('link');
    link.media = 'all';
    link.rel = 'stylesheet';
    link.href = 'https://assets.juicer.io/embed.css';
    link.type = 'text/css';

    juicerResourcesWrapper.appendChild(script);
    juicerResourcesWrapper.appendChild(link);

    document.body.appendChild(juicerResourcesWrapper);
  }, []);

  return (
    <div
      data-testid="socialMediaFeed"
      className="[&_div:focus]:!ring-0 [&_div:focus]:!ring-offset-0 [&_div:focus]:!duration-0 [&_h3:focus]:!ring-0 [&_h3:focus]:!ring-offset-0 [&_h3:focus]:!duration-0"
    >
      <div
        className={cx('cp-socialMedia', paddingStyles, inlineStyles?.showHide && 'hidden')}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : undefined,
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        }}
        tabIndex={-1}
      >
        <div
          className="container px-2 lg:px-0"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          }}
          tabIndex={-1}
        >
          <div
            tabIndex={-1}
            aria-hidden="true"
            className={
              'flex flex-col items-center md:flex-row lg:mx-4 2xl:mx-0 ' +
              (!socialTitle ? 'md:justify-end' : 'md:justify-between')
            }
          >
            {socialTitle && (
              <h3
                className="font-headline  text-xl uppercase tracking-wider"
                style={{ color: inlineStyles?.titleColor }}
                tabIndex={-1}
                aria-hidden="true"
              >
                {socialTitle}
              </h3>
            )}
            <div>
              {socialLinkURL ? (
                <a
                  data-testid="socialLink"
                  tabIndex={-1}
                  aria-hidden="true"
                  href={socialLinkURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-primary flex leading-none"
                  style={{ color: inlineStyles?.subtitleColor }}
                >
                  <InstagramIcon
                    fillColor={inlineStyles?.socialMediaIconColor}
                    className="text-primary mr-2 w-6 fill-current"
                  />
                  <span className="text-lg">{socialLinkTitle}</span>
                </a>
              ) : (
                <div
                  tabIndex={-1}
                  aria-hidden="true"
                  className="text-primary flex leading-none"
                  style={{ color: inlineStyles?.subtitleColor }}
                >
                  <InstagramIcon className="text-primary mr-2 w-6 fill-current" />
                  <span className="text-lg">{socialLinkTitle}</span>
                </div>
              )}
            </div>
          </div>

          <div
            data-testid="social-feed"
            className="cp-SocialFeed z-1 relative pt-3"
            aria-hidden={true}
            id={feedID}
            tabIndex={-1}
          >
            <ul
              className="juicer-feed"
              tabIndex={-1}
              data-feed-id={feedID}
              data-columns="4"
              data-style="slider"
              data-gutter="0"
              data-width="350"
              data-height="350"
              data-interval="0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
