export function JuiceBar({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m21.447 7.776-1-2c-.085-.169-.258-.276-.447-.276h-4.226l.803-1.779 1.164.707c.237.143.543.067.687-.168.144-.236.068-.544-.168-.688l-1.647-1c-.121-.074-.27-.091-.407-.05-.137.042-.249.142-.308.272l-1.22 2.705h-3.677c-.189 0-.362.107-.447.276l-1 2c-.078.155-.069.339.022.486s.252.237.425.237h.551l.112 1.053c.027.257.244.447.497.447.018 0 .036 0 .054-.003.274-.029.473-.275.444-.55l-.101-.947h7.904l-.923 12h-4.537c-.276 0-.5.224-.5.5s.224.5.5.5h5c.261 0 .479-.201.499-.462l.964-12.538h.537c.173 0 .334-.09.425-.237s.1-.331.022-.486zm-10.138-1.276h8.382l.5 1h-9.382z" />
      <path d="m14.34 11.634c-1.142-1.059-3.018-1.212-4.782-.468.013-.926-.186-2.039-1.334-2.613-.247-.124-.547-.022-.671.224-.124.247-.023.547.224.671.66.33.797 1.012.778 1.773-1.831-.812-3.806-.674-4.908.425-1.502 1.497-.67 3.916-.12 5.518.541 1.57 2.041 4.476 3.866 4.335.546-.048.898-.355 1.181-.602.289-.253.468-.396.773-.396.123.014.456.256.655.4.41.299.832.606 1.272.606.055 0 .109-.005.165-.015 1.484-.271 2.855-2.446 3.392-4.358.42-1.496 1.124-4.002-.491-5.5zm-.472 5.23c-.453 1.613-1.609 3.462-2.591 3.643-.128-.008-.477-.263-.686-.414-.418-.305-.813-.593-1.241-.593-.697 0-1.122.372-1.433.643-.248.217-.399.341-.611.359-.934.098-2.201-1.829-2.833-3.666-.47-1.364-1.179-3.427-.12-4.481.911-.91 2.799-.876 4.391.075.002.001.005 0 .008.002.059.034.125.058.197.065.018.002.035.003.052.003.083 0 .159-.027.229-.063.009-.005.02-.003.028-.008 1.572-.944 3.423-.971 4.403-.062 1.167 1.083.59 3.138.208 4.498z" />
      <path d="m12.279 15.505c-.273.039-.463.292-.424.565.041.288.041.576 0 .857-.117.798-.536 1.38-.867 1.728-.19.199-.183.516.017.707.097.092.221.138.345.138.132 0 .264-.052.362-.155.432-.452.978-1.214 1.133-2.272.055-.376.055-.761 0-1.143-.039-.274-.292-.459-.566-.425z" />
    </svg>
  );
}
