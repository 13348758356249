import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./dohqtwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="h-20 text-bg antialiased" fillColor="#000" />;

export const hotelLogoFooter = (
  <HotelLogo className="h-24 fill-current text-bg antialiased" fillColor="#fff" />
);
