import {
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickID,
  ImageFunction,
  ImageType,
} from '@dx-ui/config-metrics';
import type { PatchworkGridMetrics } from '../types/patchwork-grid-analytics';

type GeneratePatchworkGridMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  position: number;
  count?: number;
  itemTitle?: string;
  headline?: string;
  imageType?: (typeof ImageType)['Patchwork'];
};

/**
 * Utility function to generate Patchwork Grid metrics
 */
export function generatePatchworkGridMetrics({
  metrics,
  position,
  count = 0,
  itemTitle = ANALYTICS_NO_VALUE,
  headline = ANALYTICS_NO_VALUE,
  imageType = ImageType.Patchwork,
}: GeneratePatchworkGridMetricsParams): PatchworkGridMetrics {
  const patchworkGridMetrics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      ...metrics?.osc,
      image: {
        ...metrics?.osc?.image,
        count,
        itemTitle,
        type: imageType,
        headline,
        position,
        function: ImageFunction.Link,
      },
    },
  };

  return patchworkGridMetrics;
}
