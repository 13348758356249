import { useState, useContext } from 'react';
import cx from 'classnames';
import { EventCalendarModalContentEvent } from './event-calendar-modal-content-event';
import { GenericModal } from '../global/generic-modal';
import { useTranslation } from 'next-i18next';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import type { LineItemProps } from './lib/event-calendar-props';
import { eventCalendarLocalesSundayStart } from './lib/event-calendar-constants';

export function EventCalendarLineItem({
  regularOrSpecialEvent,
  dateStringNom,
  dateStringNoDashes,
  eventDateStr,
  eventTitle,
  eventTimes,
  eventContent,
  eventLineItemLocale,
  eventLineItemCategory,
  dateNumber,
  showHide,
  disabledState,
  dayOfWeek,
  multiDateDaysRemaning,
}: LineItemProps) {
  const [modalIsActive, setModalIsActive] = useState(false);
  const [entryContentForModal, setEntryContentForModal] = useState<any | null>(null);

  // Set individual event button ID tags
  const eventId = !eventContent?.databaseId ? eventContent?.calendarId : eventContent?.databaseId;
  const buttonId = `${eventId}${dateNumber}`;

  const { t } = useTranslation();
  const hotelInfoContext = useContext(EventCalendarInfoContext);
  const modalSettings = hotelInfoContext?.modalSettings;

  // Determine button tailwind classes based on event type (regular, special, multi)
  let buttonClassesFromEventType = '';
  switch (regularOrSpecialEvent) {
    case 'multidate':
      buttonClassesFromEventType = cx(
        'calendar-special-date calendar-special-event block border-none px-2.5 font-bold',
        multiDateDaysRemaning === 1
          ? 'w-content md:mb-2 lg:w-full'
          : 'w-content absolute lg:w-full lg:pr-0',
        disabledState
          ? `text-text-disabled cursor-not-allowed bg-[#f5f5f5]`
          : `bg-primary z-10 text-white`
      );

      break;
    case 'special':
      buttonClassesFromEventType = 'calendar-special-date calendar-special-event border px-2.5';
      break;
    case 'regular':
      buttonClassesFromEventType = 'calendar-regular-event  bg-[#f5f5f5] border px-2.5';
      break;
  }

  const startDate = eventContent?.calendarOfEvents?.startDate;

  const lang = hotelInfoContext?.localeLanguage;

  const weekStartsOnInt = eventCalendarLocalesSundayStart.indexOf(lang) !== -1 ? 0 : 1;

  const isMultidate: boolean = regularOrSpecialEvent === 'multidate';
  const multiDateDisplayClasses =
    isMultidate && startDate !== dateStringNoDashes && dayOfWeek > weekStartsOnInt && dateNumber > 1
      ? 'lg:hidden'
      : '';

  // If this item's category is not (or no longer) in the category list, set it to "Attractions"
  const selectedCategory = hotelInfoContext?.eventCategoriesWithCustom?.some(
    (category) => category.value === eventLineItemCategory
  )
    ? eventLineItemCategory
    : 'Attractions';

  const eventTimesAdj = eventTimes.replace(/a.m./g, 'am').replace(/p.m./g, 'pm');
  return (
    <>
      <button
        type="button"
        id={buttonId}
        data-testid={`${eventDateStr}-button`}
        aria-label={`${t('calendar.viewEventInfo')}  ${eventTitle} ${eventDateStr}, ${eventTimes}`}
        aria-hidden={showHide === true ? false : true}
        data-locale={eventLineItemLocale}
        data-category={selectedCategory}
        disabled={disabledState}
        data-event-type={regularOrSpecialEvent}
        data-days-to-end={multiDateDaysRemaning}
        className={cx(
          'my-1.5 rounded-md  py-1 text-left  text-xs leading-4',
          !disabledState
            ? 'border-secondary hover:bg-primary hover:border-primary cursor-pointer hover:text-white'
            : 'border-text-disabled text-text-disabled cursor-not-allowed',
          buttonClassesFromEventType,
          {
            invisible: showHide === true ? false : true,
          },
          isMultidate && multiDateDisplayClasses
        )}
        tabIndex={!disabledState ? 0 : -1}
        onClick={() => {
          setEntryContentForModal(eventContent);
          setModalIsActive(true);
        }}
      >
        {!isMultidate && (
          <span className={cx('inline-block font-bold')} aria-hidden="true">
            {eventTimesAdj}
          </span>
        )}{' '}
        {isMultidate && eventTitle}
        {(regularOrSpecialEvent === 'special' || regularOrSpecialEvent === 'regular') && (
          <span className={!eventTimes ? 'font-bold' : ''}>{eventTitle}</span>
        )}
      </button>
      {modalIsActive && (
        <GenericModal
          modalChildWrapperStyles="w-5/6 sm:w-3/4 lg:w-1/2 flex justify-center items-center"
          modalChildStyles="w-full h-auto"
          modalSettings={modalSettings}
          setModalActive={setModalIsActive}
          modalActive={modalIsActive}
          modalChild={
            <EventCalendarModalContentEvent
              title={eventTitle}
              eventDateNumber={dateNumber}
              dateStringNom={dateStringNom}
              content={entryContentForModal}
              eventType={regularOrSpecialEvent}
              hideICS={false}
            />
          }
        />
      )}
    </>
  );
}
