import cx from 'classnames';
import type * as React from 'react';
import { FormLabelHelper } from './form.label-helper';
import type { FormLabelBase } from './form.types';

type FormLabel = FormLabelBase<HTMLLabelElement>;

const FormLabel: React.FC<React.PropsWithChildren<FormLabel>> = ({
  label,
  required,
  optional,
  hasError,
  className,
  children,
  ...rest
}) => {
  return (
    <label
      className={cx('label', className, {
        'text-danger': hasError,
      })}
      {...rest}
    >
      <span>
        {label}
        <FormLabelHelper required={required} optional={optional} />
      </span>
      {children}
    </label>
  );
};

export { FormLabel };
export default FormLabel;
