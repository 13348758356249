import type { BrandCode } from './types';

type BrandCodeToBrandNameMapper = {
  [key in keyof BrandCode]: string | null;
};
export const brandCodeToBrandNameMapper: BrandCodeToBrandNameMapper = {
  CH: 'conrad-hotels',
  DT: 'doubletree-by-hilton',
  ES: 'embassy-suites',
  EY: 'livsmart-studios',
  GI: 'hilton-garden-inn',
  GU: 'graduate-hotels',
  GV: 'hilton-grand-vacations',
  HH: null,
  HI: 'hilton-hotels',
  HP: 'hampton-by-hilton',
  HT: 'home2-suites',
  HW: 'homewood-suites',
  LX: 'small-luxury-hotels-slh',
  ND: 'nomad-hotels',
  OL: 'lxr-hotels',
  OU: 'autocamp-stays',
  PE: 'spark-by-hilton',
  PO: 'tempo-by-hilton',
  PY: 'canopy-by-hilton',
  QQ: 'curio-collection',
  RU: 'tru-by-hilton',
  SA: 'signia-hilton',
  UA: 'motto-by-hilton',
  UP: 'tapestry-collection',
  WA: 'waldorf-astoria',
  WW: null,
};
