import Breakfast from './Breakfast';
import BusinessCenter from './BusinessCenter';
import Cancellation from './Cancellation';
import DoubleTreeCookie from './DoubleTreeCookie';
import EveningReception from './EveningReception';
import Firearms from './Firearms';
import Fitness from './Fitness';
import Golf from './Golf';
import GuestService from './GuestService';
import IndoorPool from './IndoorPool';
import Internet from './Internet';
import Key from './Key';
import KeyDispenser from './KeyDispenser';
import Lounge from './Lounge';
import MeetingRoom from './MeetingRoom';
import NonSmoking from './NonSmoking';
import NoPets from './NoPets';
import OutdoorPool from './OutdoorPool';
import Parking from './Parking';
import Pets from './Pets';
import Resort from './Resort';
import Restaurant from './Restaurant';
import RoomService from './RoomService';
import Shuttle from './Shuttle';
import Spa from './Spa';
import Tennis from './Tennis';
import AllInclusive from './AllInclusive';
import BeachAlt from './BeachAlt';

export interface IconProps {
  className?: string;
  color?: string;
  height?: string;
  opacity?: string;
  stroke?: string;
  style?: {
    [key: string]: any;
  };
  width?: string;
}

export {
  BeachAlt,
  Breakfast,
  BusinessCenter,
  Cancellation,
  DoubleTreeCookie,
  EveningReception,
  Firearms,
  Fitness,
  Golf,
  GuestService,
  IndoorPool,
  Internet,
  Key,
  KeyDispenser,
  Lounge,
  MeetingRoom,
  NonSmoking,
  NoPets,
  OutdoorPool,
  Parking,
  Pets,
  Resort,
  Restaurant,
  RoomService,
  Shuttle,
  Spa,
  Tennis,
  AllInclusive,
};
