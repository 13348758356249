import { type SVGAttributes } from 'react';

const TennisIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path
          d="M14.66 31.687a.973.973 0 0 1 0 1.372l-4.727 4.725c-.377.377-1.377.2-2.16-.583-.782-.782-.96-1.782-.582-2.16l4.725-4.725a.973.973 0 0 1 1.372 0l1.372 1.371z"
          strokeWidth="1.5"
        />
        <path d="M13.452 30.187l.504-.504c1.279-1.285 1.828-4.43 1.892-6.284M18.348 27.861c-.518.076-1.044.18-1.555.322.15-.547.26-1.11.336-1.661M14.789 31.524l.504-.504c1.298-1.298 4.508-1.828 6.348-1.88" />
        <path
          d="M33.588 25.008c-4.907 4.907-11.935 5.835-15.697 2.073-3.762-3.762-2.834-10.79 2.073-15.697C24.87 6.477 31.898 5.55 35.66 9.312c3.762 3.762 2.835 10.79-2.072 15.696z"
          strokeWidth="1.5"
        />
        <path d="M20.174 24.797c-2.455-2.455-1.505-7.55 2.073-11.13 3.578-3.578 8.675-4.527 11.13-2.073 2.455 2.456 1.506 7.552-2.072 11.13-3.579 3.58-8.676 4.528-11.13 2.073zM33.377 11.595L20.174 24.797" />
        <path d="M34.739 15.087l-2.26 2.26-3.359 3.359-3.268 3.268-2.176 2.183M29.708 10.224l-2.364 2.364-3.358 3.358-3.275 3.275-1.905 1.905M21.914 14.023l9.39 8.702" />
        <path d="M25.807 11.16l1.537 1.428 2.616 2.422 2.518 2.338 1.518 1.401M20.13 17.296l1.298 1.208 2.616 2.422 2.525 2.331 1.53 1.414" />
      </g>
    </svg>
  );
};

export default TennisIcon;
