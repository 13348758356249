/* The Caledonian Edinburgh, Curio Collection by Hilton -  ednchqq */

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/vmu0gcr.css" />
      <style id="caledonianStyles">
        {`
              :root {
                --font-headline: "azo-sans-web", sans-serif !important;
                --font-button: clarendon-text-pro, serif !important; 
                --font-sans:  "azo-sans-web", sans-serif !important;
              }

              
              h1,h2,h3,
              h1 p,
              h2 p,
              h3 p,
              h1 p span,
              h2 p span,
              h3 p span,
              .font-headline,
              .font-black {
                font-family: "azo-sans-web", sans-serif !important;
                font-style: normal;
                font-weight: 300 !important;
              }

    
                [data-element-id="nav-desktop"],
              [data-element-id="nav-desktop"] button,
              .font-bold,
              div,p,ul,ul li, blockquote,em, ul a, ol, ol li,label, label span {
                  font-family: "azo-sans-web", sans-serif !important;
                  font-weight: 400 !important;
                  font-style: normal;
              }

              .btn {
                font-family: clarendon-text-pro, serif !important; 
                font-style: normal;
                font-weight: 400 !important;
              }

         
  

    
            `}
      </style>
    </>
  );
};
