import { RadioChecked, RadioDisabled, RadioUnchecked } from '@curated-property/icons';
import type { ChangeEventHandler, ReactNode, FC, CSSProperties, MutableRefObject } from 'react';
import { forwardRef } from 'react';
import { slugify } from '../functions/helper';
import type { WACEmailData, WACRadioStyles } from './interfaces';
import cx from 'classnames';

interface WACStyledSelectProps {
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  children: ReactNode;
  borderColor: string;
  testId?: string;
  id?: string;
}

// Use forwardRef to handle refs properly and type the component
export const WACStyledSelect = forwardRef<HTMLSelectElement, WACStyledSelectProps>(
  function WACStyledSelectInner({ id, testId, onChange, borderColor, children }, ref) {
    return (
      <select
        id={id}
        data-testid={testId}
        onChange={onChange}
        ref={ref}
        className="form-select mb-4 appearance-none rounded-lg border p-2"
        style={{
          borderColor,
        }}
      >
        {children}
      </select>
    );
  }
);

export const WACContainer: FC<{
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  bgColor: string;
  dataElementId?: string;
}> = ({ className = '', style, children, bgColor, dataElementId }) => (
  <div
    data-element-id={dataElementId}
    className={`${className} px-4 py-6`}
    style={{
      ...style,
      background: bgColor,
    }}
  >
    {children}
  </div>
);

export const WACStyledRadio = function ({
  value,
  handler,
  checked,
  disabled,
  styles,
  label,
  name,
  autoFocus,
}: {
  value: string;
  label?: string;
  handler: (val: string) => void;
  checked?: boolean;
  disabled?: boolean;
  styles?: WACRadioStyles;
  name?: string;
  ref?: MutableRefObject<HTMLInputElement>;
  autoFocus?: boolean;
}) {
  let icon;
  let textColor = checked ? styles?.checked : styles?.unchecked;
  let scoreText = false;
  if (disabled) {
    scoreText = true;
    textColor = styles?.disabled;
    icon = <RadioDisabled className="relative top-1 mx-1" fillColor={styles?.disabled} />;
  } else {
    icon = checked ? (
      <RadioChecked className="relative top-1" fillColor={styles?.checked} />
    ) : (
      <RadioUnchecked className="relative top-1 mx-1" fillColor={styles?.unchecked} />
    );
  }
  return (
    <div
      className={cx('relative my-1 h-6', checked ? 'checked' : '')}
      style={{
        textDecoration: scoreText ? 'line-through' : undefined,
        color: textColor,
      }}
    >
      <label className="justify-left flex" htmlFor={`wac-option-${slugify(value)}`}>
        {icon}
        <span className="ml-2">{label ?? value}</span>
      </label>
      <input
        className="absolute left-1 top-1 size-4 appearance-none"
        disabled={disabled}
        checked={!disabled && checked}
        type="radio"
        value={value}
        id={`wac-option-${slugify(value)}`}
        name={`wac-option-${slugify(name)}`}
        onClick={(_e) => handler(value)}
        required
        autoFocus={autoFocus}
      />
    </div>
  );
};

export function generateEmailLink(
  emailData: WACEmailData,
  selectedDate: Date | null,
  selectedVenue: string,
  selectedTimeSlot: string
) {
  const subject = generateEmailLinkPlaceholderReplace(
    emailData?.title || '',
    selectedVenue,
    selectedTimeSlot,
    selectedDate
  );
  const body = generateEmailLinkPlaceholderReplace(
    emailData?.copy || '',
    selectedVenue,
    selectedTimeSlot,
    selectedDate
  );

  return `mailto:${emailData.address}?subject=${subject}&body=${body}`;
}

function generateEmailLinkPlaceholderReplace(
  s: string,
  selectedVenue: string,
  selectedTimeSlot: string,
  selectedDate?: Date | null
) {
  const dateString =
    selectedDate?.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }) ?? '';
  return encodeURIComponent(
    s
      ?.replace('{selectedDate}', dateString)
      ?.replace('{selectedVenue}', selectedVenue)
      ?.replace('{selectedTimeSlot}', selectedTimeSlot)
  );
}
