import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./chichhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-white text-current antialiased lg:mr-5" />
);

export const hotelLogoFooter = <HotelLogo className="w-28 fill-white text-current antialiased" />;
