import gql from 'graphql-tag-transform.macro';
import { MediaBlocksFrag, clonedCropSettings, mediaAndCopySettings } from './common-vars';

export const MediaAndCopy = gql`
fragment MediaAndCopy on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_HalfAndHalf {
        fieldGroupName
        repeater {
          accordionPanel {
            panelLabel
            panelRepeater {
              fieldGroupName
                rowLabel
                rowText
              }
          }
          copy
          titleIcon {
            altText
            sourceUrl
          }
          title
          title_noTx: title
          subtitle
          subtitle_noTx: subtitle
          iconListIconList
          image {
            altText
            sourceUrl
          }
          imageSize
          imagePositioning
          layoutMode
          mediaBlock {
            ... on Media_block {
              id
              ${MediaBlocksFrag}
            }
          }          
          mediaCopyIconList: iconList {
            iconColor
            iconList
            text
          }
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          tableData {
            title
            text
            fieldGroupName
          }
            
          ${clonedCropSettings}
          imageGallery {
            sourceUrl
            altText
          }
          componentVersion
        }
        halfAndHalfComponentSettings {
          fieldGroupName
          showHide
          accordionBorderColor
          accordionIconColor
          accordionIconColorActive
          accordionPanelBackgroundColor
          accordionPanelLabelColor
          accordionRowLabelColor
          accordionRowTextColor
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${mediaAndCopySettings}
        }
      }
}
`;
