import cx from 'classnames';
import type { HeroImageSliderProps } from './hero-image.types';
import { CarouselTouchSwipe } from '@curated-property/utils';
import { useAdobeCarouselEvent } from '../global/adobe-analytics';
import { customLoader, CroppedImage } from '../cropped-image/cropped-image';

export function HeroImageSlider({
  images,
  imageSize,
  styleOptions,
  componentInstance,
  hideAnimations,
  slideIndex,
  next,
  prev,
  navAnimationDir,
  nextTimeout,
  nextImage,
}: HeroImageSliderProps) {
  const slideAnimation: string = styleOptions?.sliderAnimations || 'fade';
  const sliderId = `hero-slider-${componentInstance}`;
  const currentImage = images[slideIndex];
  const count = images ? images.length : 0;
  const getNextIndex = () => (slideIndex + 1 > count - 1 ? 0 : slideIndex + 1);
  const getPrevIndex = () => (slideIndex - 1 < 0 ? count - 1 : slideIndex - 1);

  CarouselTouchSwipe({ next, prev, targetId: sliderId });

  useAdobeCarouselEvent({
    sectionHeading: 'Hero Image Carousel',
    currentIndex: slideIndex + 1,
    totalCount: images?.length,
  });

  const width = imageSize ? imageSize.w : 2560;
  const height = imageSize ? imageSize.h : 1808;
  const src = currentImage?.sourceUrl;
  const srcUrl = customLoader({ src, width, height });
  const nextImgSrcUrl = customLoader({
    src: nextImage?.image?.sourceUrl,
    width,
    height,
  });

  const getPrevSlideInd = () => {
    const ind = slideAnimation === 'slide-ltr' && navAnimationDir ? getNextIndex() : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  return slideAnimation !== 'fade' ? (
    <div
      id={`hero-slider-${componentInstance}`}
      className="absolute flex size-full flex-row justify-center overflow-hidden"
    >
      <div
        className={cx('absolute size-full transition-all duration-500 ease-in-out', {
          'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
          '-translate-x-full': !nextTimeout || slideAnimation === 'slide-rtl',
          'transition-none': !nextTimeout,
        })}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={images[getPrevSlideInd()]?.sourceUrl}
          alt={`${images[getPrevSlideInd()]?.altText}-previous-transition`}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
      <div
        className={cx('absolute order-3 size-full transition-all duration-500 ease-in-out', {
          'translate-x-0':
            (slideAnimation === 'slide-rtl' && nextTimeout) ||
            (nextTimeout && slideAnimation !== 'slide-ltr' && navAnimationDir),
          'translate-x-full':
            !nextTimeout ||
            (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
            slideAnimation === 'slide-ltr',
          'transition-none': !nextTimeout,
        })}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={nextImgSrcUrl}
          alt={`${nextImage?.image?.altText}-next-transition`}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
      <div
        className={cx(
          'order-2 w-full transition-all duration-500 ease-in-out',
          {
            'transition-none': !nextTimeout,
          },
          slideStyles
        )}
      >
        <CroppedImage
          width={1920}
          height={1080}
          quality={70}
          src={srcUrl}
          alt={currentImage?.altText}
          objectPosition={styleOptions?.imagePositioning || null}
          objectFit="cover"
          layout="fill"
          draggable={false}
        />
      </div>
    </div>
  ) : (
    <div id={`hero-slider-${componentInstance}`}>
      <CroppedImage
        width={1920}
        height={1080}
        quality={70}
        src={nextImgSrcUrl}
        alt={`${nextImage?.image?.altText}-transition`}
        objectPosition={styleOptions?.imagePositioning || null}
        objectFit="cover"
        layout="fill"
        className={cx('z-10 transition-all duration-500 ease-in-out', {
          'animate-fade-in-slow': !hideAnimations,
        })}
        draggable={false}
      />
      <CroppedImage
        data-testid="sliderImage"
        width={1920}
        height={1080}
        quality={70}
        src={srcUrl}
        alt={currentImage?.altText}
        objectPosition={styleOptions?.imagePositioning || null}
        objectFit="cover"
        layout="fill"
        className={cx('z-10 transition-all duration-500 ease-in-out', {
          'animate-fade-in-slow': !hideAnimations,
          'opacity-0': nextTimeout ? true : false,
          'opacity-100': nextTimeout ? false : true,
        })}
        draggable={false}
      />
    </div>
  );
}
