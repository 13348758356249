import { useRect } from '@dx-ui/utilities-use-rect';
import { useRef } from 'react';
import type { AspectRatio } from '@dx-ui/osc-responsive-image';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import type { OffsetImage } from './offset';
import cx from 'classnames';

type ImageProps = OffsetImage & {
  aspectRatio: AspectRatio;
  className?: string;
  onClick?: () => void;
};
export const Image = ({ src, alt, captionData, aspectRatio, className, onClick }: ImageProps) => {
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });

  return src && alt ? (
    <div ref={ref}>
      <ResponsiveImage
        altText={alt}
        imageUrl={src}
        aspectRatio={aspectRatio}
        width={rect?.width ?? 0}
        captionData={captionData}
        className={cx('image-corner-radius', className)}
        onClick={onClick}
      />
    </div>
  ) : null;
};
