import gql from 'graphql-tag-transform.macro';

export type {
  Hotel_ShopAvailOptions_ShopPropAvailQuery,
  Hotel_ShopAvailOptions_ShopPropAvailQueryVariables,
  HotelQuery,
  HotelQueryVariables,
} from '../../generated/dx-gql-rooms';

export type Maybe<T> = T | undefined;

export const getAvailQuery = gql`
  query hotel_shopAvailOptions_shopPropAvail(
    $ctyhocn: String!
    $arrivalDate: String!
    $departureDate: String!
    $language: String!
    $cacheId: String
    $numAdults: Int!
    $guestLocationCountry: String!
  ) {
    hotel(ctyhocn: $ctyhocn, language: $language) {
      shopAvail(
        cacheId: $cacheId
        input: {
          arrivalDate: $arrivalDate
          departureDate: $departureDate
          numAdults: $numAdults
          numChildren: 0
          guestLocationCountry: $guestLocationCountry
        }
      ) {
        currency {
          description
          currencySymbol
          currencyCode
        }
        roomTypes {
          roomTypeCode
          moreRatesFromRate {
            ratePlanCode
            amountAfterTaxFmt(decimal: 0, strategy: ceiling)
            rateAmountFmt(decimal: 0, strategy: ceiling)
            feeTransparencyIndicator
            serviceChargeDetails
          }
        }
      }
    }
  }
`;

export const getRoomTypesQuery = gql`
  query hotel(
    $ctyhocn: String!
    $language: String!
    $roomTypeFeatures: [BigInt!]!
    $enhance: Boolean!
  ) {
    hotel(ctyhocn: $ctyhocn, language: $language, enhance: $enhance) {
      brand {
        name
        formalName
      }
      roomTypes {
        roomTypeCode
        adaAccessibleRoom
        numBeds
        suite
        maxOccupancy
        bedType
        roomTypeName
        desc
        accommodationCode
        virtualMedia {
          _id
          altText
          ariaLabel
          assetType
          assetUrl
          description
          mediaItemId
          mediaItemIdFmt
          placeId
          roomTypeCode
          sceneDefaultView
          sceneDescription
          supportedUrl
        }
        sleeps: features(filter: { id_in: [1002] }) {
          name
          id
        }

        # bulleted list of features
        roomAmenities: features(first: 12, filter: { id_in: $roomTypeFeatures }) {
          id
          name
        }

        showerTubAmenities: features(first: 12, filter: { id_in: [146] }) {
          id
          name
        }

        features {
          id
          name
          category
        }

        premium
        carousel {
          altText
          url(width: 1280, height: 768)
          variants {
            size
            url
          }
        }
      }
    }
  }
`;

export const ROOM_TYPE_FEATURES = [
  1,
  // 1002,
  // 176,
  34, 151, 32, 71, 146, 153, 5, 168, 169, 7, 6, 152, 54, 116, 170, 167, 148, 178, 23, 154,
  // 13,
  121, 90, 223, 29, 135, 79, 204, 205, 120, 78, 187, 80, 81, 188, 189, 190, 221, 197, 191, 198, 192,
  193, 194, 195, 196, 8, 16, 19, 122, 89, 63, 3, 82, 31, 53, 14, 11, 12,
];
