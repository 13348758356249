import { type SVGAttributes } from 'react';

const KeyDispenserIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <defs>
        <path d="M33.859.496H0v1.488H33.86V.496z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <g transform="translate(5 2.198)">
          <mask fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M33.115 1.984H.745a.744.744 0 0 1 0-1.488h32.37a.744.744 0 0 1 0 1.488"
            mask="url(#b)"
          />
        </g>
        <path d="M30.115 34.071H13.744a4.098 4.098 0 0 1-4.093-4.093V7.159a.745.745 0 0 1 1.488 0v22.819a2.608 2.608 0 0 0 2.605 2.605h16.371a2.608 2.608 0 0 0 2.605-2.605V7.159a.744.744 0 1 1 1.488 0v22.819a4.098 4.098 0 0 1-4.093 4.093" />
        <path d="M18.398 27.436c-.373 0-.746-.16-1.025-.439l-.882-.88a.559.559 0 0 1 .79-.79l.882.88a.35.35 0 0 0 .235.112c.017 0 .05-.002.065-.019l1.503-1.501c.036-.038.03-.18-.092-.303l-.882-.882a.558.558 0 1 1 .789-.789l.883.882c.542.545.583 1.388.091 1.88l-1.501 1.502a1.198 1.198 0 0 1-.855.347zM22.406 18.3a3.883 3.883 0 0 0 2.762 1.144A3.881 3.881 0 0 0 27.93 18.3a3.883 3.883 0 0 0 1.144-2.762 3.883 3.883 0 0 0-1.144-2.763 3.88 3.88 0 0 0-2.762-1.144 3.88 3.88 0 0 0-2.762 1.144 3.912 3.912 0 0 0 0 5.525m2.762 2.26a4.988 4.988 0 0 1-3.551-1.471 5.029 5.029 0 0 1 0-7.103 4.989 4.989 0 0 1 3.55-1.471c1.343 0 2.604.522 3.552 1.47a4.99 4.99 0 0 1 1.472 3.553 4.988 4.988 0 0 1-1.472 3.551 4.987 4.987 0 0 1-3.551 1.472" />
        <path d="M15.58 27.024c-.51 0-.99-.2-1.351-.561a1.898 1.898 0 0 1-.561-1.352c0-.51.2-.99.56-1.352l6.693-6.693.789.79-6.692 6.692a.793.793 0 0 0 0 1.126c.3.3.824.302 1.126 0l6.691-6.693.79.789-6.692 6.693a1.9 1.9 0 0 1-1.352.56" />
        <path d="M22.406 18.3a3.883 3.883 0 0 0 2.762 1.144A3.881 3.881 0 0 0 27.93 18.3a3.883 3.883 0 0 0 1.144-2.762 3.883 3.883 0 0 0-1.144-2.763 3.88 3.88 0 0 0-2.762-1.144 3.88 3.88 0 0 0-2.762 1.144 3.912 3.912 0 0 0 0 5.525m2.762 2.26a4.988 4.988 0 0 1-3.551-1.471 5.029 5.029 0 0 1 0-7.103 4.989 4.989 0 0 1 3.55-1.471c1.343 0 2.604.522 3.552 1.47a4.99 4.99 0 0 1 1.472 3.553 4.988 4.988 0 0 1-1.472 3.551 4.987 4.987 0 0 1-3.551 1.472" />
        <path d="M25.272 15.227c.321.32.842.32 1.163 0a.824.824 0 0 0 0-1.162.842.842 0 0 0-1.163 0 .824.824 0 0 0 0 1.162m.581 1.356a1.93 1.93 0 0 1-1.37-.567 1.94 1.94 0 0 1 0-2.74 1.929 1.929 0 0 1 1.37-.568 1.94 1.94 0 0 1 0 3.875M21.882 41.055a.742.742 0 0 1-.526-.218l-3.545-3.545a.745.745 0 0 1 1.052-1.052l3.545 3.544a.744.744 0 0 1-.526 1.27" />
        <path d="M21.882 41.055a.742.742 0 0 1-.526-1.27l3.64-3.64a.743.743 0 1 1 1.052 1.052l-3.64 3.64a.742.742 0 0 1-.526.218" />
      </g>
    </svg>
  );
};

export default KeyDispenserIcon;
