export function Price({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M5.2656,5.7344a.3756.3756,0,0,0-.5312.5312l2,2a.3756.3756,0,1,0,.5312-.5312Z" />
          <path d="M19.2656,5.7344a.376.376,0,0,0-.5312,0l-2,2a.3756.3756,0,0,0,.5312.5312l2-2A.3778.3778,0,0,0,19.2656,5.7344Z" />
          <path d="M12,6.375A.3751.3751,0,0,0,12.375,6V3a.375.375,0,0,0-.75,0V6A.3751.3751,0,0,0,12,6.375Z" />
          <path d="M14.625,12.502a.3751.3751,0,0,0,.375.372h.002a.3744.3744,0,0,0,.373-.374,2.9758,2.9758,0,0,0-3-2.8546V9a.375.375,0,0,0-.75,0v.6454a2.9758,2.9758,0,0,0-3,2.8546,2.9758,2.9758,0,0,0,3,2.8546v4.2523a2.1748,2.1748,0,0,1-2.25-2.1089A.3751.3751,0,0,0,9,17.126H8.998a.3744.3744,0,0,0-.373.374,2.9758,2.9758,0,0,0,3,2.8546V21a.375.375,0,0,0,.75,0v-.6454a2.9758,2.9758,0,0,0,3-2.8546,2.9758,2.9758,0,0,0-3-2.8546V10.3931A2.1748,2.1748,0,0,1,14.625,12.502Zm-3,2.1049a2.1743,2.1743,0,0,1-2.25-2.1049,2.1748,2.1748,0,0,1,2.25-2.1089Zm3,2.8911a2.1748,2.1748,0,0,1-2.25,2.1089V15.3931A2.1743,2.1743,0,0,1,14.625,17.498Z" />
        </g>
      </g>
    </svg>
  );
}
