export function Slippers({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m20 7.5h-.05c-.25-2.24-2.14-4-4.45-4-1.38 0-2.5 1.12-2.5 2.5v1.5c-.28 0-.5.22-.5.5v4c0 .28.22.5.5.5v5c0 1.65 1.35 3 3 3s3-1.35 3-2.95l.53-5.05h.47c.28 0 .5-.22.5-.5v-4c0-.28-.22-.5-.5-.5zm-.5 4h-6v-3h6zm-4-7c1.76 0 3.2 1.31 3.45 3h-4.95v-1.5c0-.83.67-1.5 1.5-1.5zm2.5 13c0 1.1-.9 2-2 2s-2-.9-2-2v-5h4.52z" />
      <path d="m4 12.5h.47l.53 5c0 1.65 1.35 3 3 3s3-1.35 3-3v-5c.28 0 .5-.22.5-.5v-4c0-.28-.22-.5-.5-.5v-1.5c0-1.38-1.12-2.5-2.5-2.5-2.31 0-4.2 1.76-4.45 4h-.05c-.28 0-.5.22-.5.5v4c0 .28.22.5.5.5zm6.5-4v3h-6v-3zm-2.5 11c-1.1 0-2-.9-2-2.05l-.52-4.95h4.52v5c0 1.1-.9 2-2 2zm.5-15c.83 0 1.5.67 1.5 1.5v1.5h-4.95c.25-1.69 1.69-3 3.45-3z" />
    </svg>
  );
}
