import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { HalfAndHalfCombinedProps, HalfAndHalfItemInterface } from '.';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_merge, GIS_TextAlignment } from '../functions/global-instance-styles';
import {
  HandleAnimations,
  HandleWYSIWYGContent,
  iconmapper,
  useWindowSize,
  makeBorderWidthValue,
} from '../functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { ImageSlider } from '../image-slider/image-slider';
import cx from 'classnames';
import { HeadingStyle } from '../includes/heading-style';
import { AnchorLink } from '../global/anchor-link';
import styles from '../css/contentStyle.module.css';
import type { MediaBlocksBorderInterface } from '../media-blocks';
import { mediaBlocksFactory } from '../media-blocks';
import { AccordionAddOn } from '../accordion-add-on/accordion-add-on';
import { WrappedSubtitle } from '@curated-property/utils';

interface IRow {
  item: HalfAndHalfCombinedProps;
  inlineStyles: StyleObject;
  id: number;
}

interface Image {
  url: string;
  alt: string;
}

export function MediaAndCopyRow({ item, inlineStyles, id }: IRow) {
  const wWidth = useWindowSize().width || 0;
  const isPortraitImg = item?.orientation === 'portrait' ? true : false;

  if (!item) {
    return null;
  }

  const iconMap = iconmapper();
  const Icon = iconMap[(item?.iconListIconList as keyof typeof iconMap) || ''];

  let mediaSize: string, copySize: string, wrapperClass: string, tableClasses: string;
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);

  switch (item?.layoutMode) {
    case '50 / 50':
      wrapperClass = 'fiftyFifty';
      mediaSize = 'md:w-1/2';
      copySize = 'md:w-1/2';
      tableClasses = 'md:w-fit min-w-1/2';
      break;
    case '60 / 40':
      wrapperClass = 'sixtyForty';
      mediaSize = 'md:w-3/5';
      copySize = 'md:w-2/5';
      tableClasses = 'md:w-fit min-w-2/3 md:text-sm xl:text-md';
      break;
    case '70 / 30':
      wrapperClass = 'seventyThirty';
      mediaSize = 'md:w-3/5 lg:w-8/12';
      copySize = 'md:w-2/5 lg:w-4/12';
      tableClasses = 'min-w-full text-sm sm:text-sm lg:text-md';
      break;
    default:
      wrapperClass = 'fiftyFifty';
      mediaSize = 'md:w-1/2';
      copySize = 'md:w-1/2';
      tableClasses = 'md:w-fit min-w-2/3';
  }

  const layoutDirection =
    (id + (inlineStyles?.firstItemAlignment === 'start_image_right' ? 1 : 0)) % 2;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : layoutDirection
      ? 'lg:-translate-x-8'
      : 'lg:translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
  });

  const imagesArrayMap: Image[] = [];

  item?.imageGallery?.forEach((row) => {
    imagesArrayMap.push({
      url: row?.sourceUrl || '',
      alt: row?.altText || '',
    });
  });

  const insetBorderColour = inlineStyles?.imageInsetBorderColour || '#ffffff';
  const insetBorderWidth = makeBorderWidthValue(inlineStyles?.imageInsetBorderWidth);

  const borderSettings: MediaBlocksBorderInterface = {
    imageInsetBorderWidth: insetBorderWidth,
    imageInsetBorderColour: insetBorderColour,
  };

  let imageryDisplay = null;
  if (item?.mediaBlock && item?.componentVersion === 'media-block') {
    //will return null if mediaBlock name is invalid
    imageryDisplay = (
      <div className="aspect-[16/9] md:aspect-square">
        {mediaBlocksFactory(item?.mediaBlock, 1200, borderSettings)}
      </div>
    );
  }

  if (imageryDisplay == null) {
    imageryDisplay =
      !imagesArrayMap.length || imagesArrayMap.length === 1
        ? createSingleImage(wWidth, item, inlineStyles, isPortraitImg)
        : createImageSlider(item, imagesArrayMap, inlineStyles, isPortraitImg);
  }

  const rowStyles = GIS_merge(
    inlineStyles,
    (id - 1) % 2
      ? {}
      : {
          contentBackgroundImage: inlineStyles?.altRowContentBackgroundImage,
          contentBackgroundColor: inlineStyles?.altRowContentBackgroundColor,
          contentBackgroundSize: inlineStyles?.altRowContentBackgroundSize,
          contentBackgroundRepeat: inlineStyles?.altRowContentBackgroundRepeat,
          contentBackgroundPosition: inlineStyles?.altRowContentBackgroundPosition,
          subtitleColor: inlineStyles?.altRowSubtitleColor,
          textAlignment: inlineStyles?.altRowTextAlignment,
          textColor: inlineStyles?.altRowTextColor,
          titleColor: inlineStyles?.altRowTitleColor,
        }
  );

  let tableAlignment = 'justify-start';
  let tableTextAlignment = 'text-left';
  switch (rowStyles?.textAlignment) {
    case 'flex-start':
      tableAlignment = 'justify-start';
      break;
    case 'center':
      tableAlignment = 'justify-center';

      tableTextAlignment = 'text-center';
      break;
    case 'flex-end':
      tableAlignment = 'justify-end';
      tableTextAlignment = 'text-right';
      break;
    default:
      tableAlignment = 'justify-start';
      tableTextAlignment = 'text-left';
  }

  const accordionAddOnStyles = {
    accordionBorderColor: inlineStyles.accordionBorderColor,
    accordionIconColor: inlineStyles.accordionIconColor,
    accordionIconColorActive: inlineStyles.accordionIconColorActive,
    accordionPanelBackgroundColor: inlineStyles.accordionPanelBackgroundColor,
    accordionPanelLabelColor: inlineStyles.accordionPanelLabelColor,
    accordionRowLabelColor: inlineStyles.accordionRowLabelColor,
    accordionRowTextColor: inlineStyles.accordionRowTextColor,
  };

  return (
    <div
      ref={animations?.ref}
      data-testid="mediaCopyWrapper"
      data-element-id="media-and-copy-wrapper"
      key={id}
      className={cx(
        inlineStyles?.showHide && 'hidden',
        wrapperClass,
        'flex flex-wrap items-stretch',
        isPortraitImg && 'mx-auto w-full max-w-[1080px]'
      )}
      style={{ backgroundColor: rowStyles?.contentBackgroundColor }}
    >
      <div
        data-testid="mediaSection"
        className={cx(
          'relative w-full',
          {
            'md:min-h-[720px]':
              (!imagesArrayMap.length || imagesArrayMap.length === 1) &&
              item?.mediaBlock?.mediaBlockLayouts?.mediaLayout?.[0]?.fieldGroupName !==
                'Media_block_Mediablocklayouts_MediaLayout_Video'
                ? true
                : false,
            'md:order-2': layoutDirection,
          },
          mediaSize,
          animations?.one
        )}
      >
        {imageryDisplay}
      </div>
      <div
        data-testid="copySection"
        data-element-id="media-and-copy-copy-section"
        className={cx(
          'flex w-full flex-col justify-center px-6 pt-6 sm:px-8 sm:pt-8 lg:px-16 lg:py-12',
          copySize
        )}
        style={{
          backgroundImage: rowStyles?.contentBackgroundImage?.sourceUrl
            ? 'url(' +
              appliedCloudinaryParams(
                rowStyles?.contentBackgroundImage?.sourceUrl,
                rowStyles?.contentBackgroundRepeat
              ) +
              ')'
            : undefined,
          backgroundSize: rowStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: rowStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: rowStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: rowStyles?.contentBackgroundColor,
        }}
      >
        <div
          className={cx('flex flex-col py-5', animations?.two)}
          style={{
            alignItems: rowStyles?.textAlignment || 'flex-start',
            justifyContent: rowStyles?.textAlignment,
            textAlign: textAlignment,
          }}
        >
          {item?.titleIcon?.sourceUrl ? (
            <img
              className="max-h-32 max-w-32"
              src={item?.titleIcon?.sourceUrl}
              alt={item?.titleIcon?.altText}
              style={{
                marginBottom: inlineStyles?.titleLogoiconBottomMargin
                  ? `${inlineStyles?.titleLogoiconBottomMargin}px`
                  : undefined,
              }}
            />
          ) : null}
          {item?.title && (
            <HeadingStyle
              text={inlineStyles?.headingsNoTranslate ? item?.title_noTx : item?.title}
              type="h2"
              className={cx(
                'text-inverse font-headline text-2xl font-black leading-none md:text-4xl',
                { 'mb-2': item?.subtitle, 'mb-6': !item?.subtitle },
                { OneLinkNoTx: inlineStyles?.headingsNoTranslate }
              )}
              textColorInline={rowStyles?.titleColor}
            />
          )}

          {item?.subtitle && (
            <WrappedSubtitle>
              <div className="flex items-center mb-6">
                {Icon && (
                  <Icon className="size-8 me-2 fill-primary" fillColor={rowStyles?.subtitleColor} />
                )}
                <HeadingStyle
                  text={inlineStyles?.headingsNoTranslate ? item?.subtitle_noTx : item?.subtitle}
                  type={!item?.title ? 'h2' : 'h3'}
                  className={cx('font-sans font-bold text-xl md:text-xl', animations?.two, {
                    OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                  })}
                  textColorInline={rowStyles?.subtitleColor || inlineStyles?.subtitleColor}
                />
              </div>
            </WrappedSubtitle>
          )}

          <div
            className={cx('mb-6 text-lg', styles.listStyle, styles.paragraphStyle)}
            style={{ color: rowStyles?.textColor }}
            dangerouslySetInnerHTML={{
              __html: HandleWYSIWYGContent(item.copy, rowStyles?.textColor),
            }}
          />
          {createIconList(item?.mediaCopyIconList, rowStyles?.textColor)}
          {item?.tableData?.length && (
            <div
              className={cx('mb-8 mt-2 flex w-full  flex-row', tableAlignment)}
              data-testid="media-copy-table-wrapper"
            >
              <table
                role="table"
                data-testid="media-copy-table"
                className={cx('w-full table-auto', tableClasses)}
              >
                <tbody>
                  {item?.tableData?.map((i, e) => {
                    return (
                      <tr
                        key={i.title + e}
                        className="border-b-primary first:border-t-primary border-b align-top first:border-t"
                      >
                        <th className={cx('px-1 py-2', tableTextAlignment)}>{i?.title}</th>
                        <td className={cx('px-1 py-2', tableTextAlignment)}>{i?.text}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {item.buttons ? (
            <div className="mt-0 flex flex-wrap">
              {item.buttons?.map((link, key) => {
                if (!link || !link.link) {
                  return null;
                }
                return (
                  <AnchorLink
                    key={key}
                    url={link.link?.url || ''}
                    title={link.link?.title || ''}
                    srContext={item?.title}
                    target={link.link?.target || ''}
                    buttonStyle={link?.buttonStyle ?? 'primary'}
                  />
                );
              })}
            </div>
          ) : null}
          {item.accordionPanel && item.accordionPanel[0].panelLabel ? (
            <AccordionAddOn
              accordionPanel={item.accordionPanel}
              accordionAddOnStyles={accordionAddOnStyles}
              className="pt-8"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function createIconList(items: HalfAndHalfItemInterface['mediaCopyIconList'], textColor) {
  const iconMap = iconmapper();
  if (!items || items?.length <= 0) return null;
  const iconsRows = [];
  for (let i = 0; i < items?.length; i++) {
    if (!items[i]?.iconList) continue;
    const Icon = iconMap[(items[i]?.iconList as keyof typeof iconMap) || ''];
    iconsRows.push(
      <div key={items[i].iconList} className="my-10 flex items-center">
        <Icon className="mr-4 w-10 shrink-0" fillColor={items[i]?.iconColor} />
        <div style={{ color: textColor }}>{items[i].text}</div>
      </div>
    );
  }
  return <div className="w-full text-left text-lg">{iconsRows}</div>;
}

function createSingleImage(
  wWidth: number,
  item: IRow['item'],
  inlineStyles: StyleObject,
  isPortraitImg: boolean
) {
  if (item?.imageElementOverride) {
    return <span data-testid="singleImage">{item?.imageElementOverride}</span>;
  }
  let singleImageItem: HalfAndHalfItemInterface['image'];
  if (item?.imageGallery?.length === 1) {
    singleImageItem = item?.imageGallery[0];
  } else {
    if (item?.image && !item?.imageGallery) {
      singleImageItem = item?.image;
    }
  }
  if (wWidth > 768) {
    return (
      <CroppedImage
        data-testid="singleImage"
        loader={() => {
          return customLoader({
            src: singleImageItem?.sourceUrl,
            crop: item?.enableCropping,
            cropType: item?.cropType,
            cropHeight: item?.cropHeight,
            cropWidth: item?.cropWidth,
            xPosition: item?.xPosition,
            xPositionAdvanced: item?.xPositionAdvanced,
            yPosition: item?.yPosition,
            yPositionAdvanced: item?.yPositionAdvanced,
            autoPosition: item?.autoPosition,
          });
        }}
        src={singleImageItem?.sourceUrl || ''}
        alt={singleImageItem?.altText || ''}
        layout="fill"
        objectPosition={
          item?.imagePositioning || inlineStyles?.contentBackgroundPosition || undefined
        }
        objectFit="cover"
        className="!max-h-[calc(100%+1px)] !min-h-[calc(100%+1px)]"
        width={isPortraitImg ? 825 : undefined}
        height={isPortraitImg ? 1100 : undefined}
      />
    );
  } else {
    return (
      <CroppedImage
        data-testid="singleImage"
        src={singleImageItem?.sourceUrl || ''}
        alt={singleImageItem?.altText || ''}
        layout="responsive"
        width={isPortraitImg ? 825 : 720}
        height={isPortraitImg ? 1100 : 540}
      />
    );
  }
}

function createImageSlider(
  item: IRow['item'],
  imagesArrayMap: Image[],
  inlineStyles: StyleObject,
  isPortraitImg: boolean
) {
  const galleryImgSize = item?.imageSize?.split('x');
  return (
    <ImageSlider
      sectionHeading={item?.title}
      images={imagesArrayMap}
      objectFit="fill"
      objectPosition={
        item?.imagePositioning || inlineStyles?.contentBackgroundPosition || undefined
      }
      imageSize={{
        w: isPortraitImg ? 825 : parseInt(galleryImgSize?.[0] || ''),
        h: isPortraitImg ? 1100 : parseInt(galleryImgSize?.[1] || ''),
      }}
      styleOptions={{
        uiBackgroundColour: inlineStyles?.uiBackgroundColour,
        uiControlsColour: inlineStyles?.uiControlsColour,
        paginationBackgroundColour: inlineStyles?.paginationBackgroundColour,
        paginationTextColour: inlineStyles?.paginationTextColour,
        paginationSeperatorColour: inlineStyles?.paginationSeperatorColour,
        sliderAnimations: inlineStyles?.sliderAnimations,
      }}
    />
  );
}
