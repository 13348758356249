import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sluhcqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-30 h-24 fill-current text-bg antialiased md:mr-5 md:w-32" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#ffffff" className="size-24 fill-current text-bg antialiased md:w-auto" />
);
