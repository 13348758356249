import { useState } from 'react';
import { HeadingStyle } from './includes/heading-style';
import { customLoader } from './cropped-image/cropped-image';
import { imageUrl } from '@curated-property/utils';
import styles from './css/tabbed-table-data.module.css';
import cx from 'classnames';
import Img from 'next/legacy/image';
import { MagnifyingGlass } from '@curated-property/icons';
import { GenericModal, DialogTitle } from './global/generic-modal';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from './functions/global-instance-styles';
import * as Tabs from '@radix-ui/react-tabs';
import { HandleWYSIWYGContent } from './functions/helper';
import { useTranslation } from 'next-i18next';

export interface TabbedSectionProps {
  header?: string;
  menuRepeater?: {
    menuName?: string;
    gallery?: {
      altText?: string;
      sourceUrl?: string;
      mediaDetails?: {
        height?: number;
        width?: number;
      };
    }[];
    contentSelection?: string;
    menuColumn?: {
      menuHeader?: string;
      menuItems?: string;
    }[];
    sectionTableColumns?: {
      column1?: string;
      column2?: string;
      column3?: string;
      column4?: string;
      column5?: string;
      column6?: string;
      column7?: string;
      column8?: string;
    }[];
    tableHeadingSpan?: {
      columnSpan?: number;
      text?: string;
    }[];
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  modalSettings?: any;
}

export function TabbedSection({
  header,
  menuRepeater,
  globalStyles,
  instanceStyles,
  modalSettings,
}: TabbedSectionProps) {
  const [clicked, setClicked] = useState<number>(0);
  const [activeImage, setActiveImage] = useState({
    url: null,
    alt: null,
  });
  const [modalActive, setModalActive] = useState(false);
  const tabbedSectionID = header?.replace(/ /g, '-').toLowerCase();
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  return (
    <div
      data-testid="tabTableSection"
      className={cx(
        'bg-bg-alt cp-tabbedTableData',
        styles.cpTabbedStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${inlineStyles?.componentBackgroundImage}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${inlineStyles?.contentBackgroundImage}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: inlineStyles?.contentBackgroundColor,
        }}
      >
        {header && (
          <HeadingStyle
            id={tabbedSectionID}
            text={header}
            type="h2"
            className="text-inverse pb-12 text-center"
            textColorInline={inlineStyles?.titleColor}
          />
        )}
        <div>
          <Tabs.Root
            defaultValue="0"
            orientation="horizontal"
            onValueChange={(v) => {
              setClicked(parseInt(v));
            }}
          >
            {(menuRepeater?.length || 0) > 1 ? (
              <Tabs.List className="border-text-disabled flex w-full flex-wrap justify-center border-b-2 md:flex-nowrap">
                {menuRepeater?.map((item, key) => {
                  return (
                    <MenuTab
                      key={key}
                      id={key.toString()}
                      title={item?.menuName}
                      textColor={inlineStyles?.subtitleColor}
                      isClicked={clicked === key ? true : false}
                      activeTabAccentColour={
                        clicked === key ? inlineStyles?.activeTabAccentColour : null
                      }
                      activeTabTextColour={
                        clicked === key ? inlineStyles?.activeTabTextColour : null
                      }
                      className={`border-b-4 px-6 py-3 text-xl sm:-mb-1 sm:px-8 sm:py-6 ${
                        clicked === key ? 'border-primary' : 'border-transparent'
                      }`}
                    />
                  );
                })}
              </Tabs.List>
            ) : null}
            {(menuRepeater?.length || 0) > 0
              ? menuRepeater?.map((item, key) => {
                  return (
                    <Tabs.Content
                      key={key}
                      value={key.toString()}
                      className="w-full overflow-scroll md:overflow-auto"
                    >
                      <div className="flex flex-wrap justify-between gap-6 py-4">
                        <div className={cx('flex w-full flex-wrap justify-center lg:flex-nowrap')}>
                          {menuRepeater?.[key]?.gallery?.map((item, key) => {
                            return (
                              <TabbedGalleryImages
                                key={key}
                                image={item?.sourceUrl}
                                altText={item?.altText}
                                setModalActive={setModalActive}
                                setActiveImage={setActiveImage}
                              />
                            );
                          })}
                        </div>

                        {menuRepeater?.[key]?.contentSelection === 'WISYWIG' ? (
                          menuRepeater?.[key]?.menuColumn?.map((col, key) => {
                            return (
                              <MenuPanel
                                key={key}
                                colTitle={col?.menuHeader}
                                colItems={col?.menuItems}
                              />
                            );
                          })
                        ) : (
                          <TableSection
                            repeater={menuRepeater?.[key]?.sectionTableColumns}
                            headingSpan={menuRepeater?.[key]?.tableHeadingSpan}
                            textColor={inlineStyles?.textColor}
                            textAlignment={inlineStyles?.textAlignment}
                            headerBackground={inlineStyles?.tableHeaderBackgroundColour}
                            headerTextColor={inlineStyles?.tableHeaderTextColour}
                            rowTextColor={inlineStyles?.tableRowTextColour}
                            rowBackgroundColor={inlineStyles?.tableRowBackgroundColour}
                            altRowTextColor={inlineStyles?.tableAltRowTextColour}
                            altRowBackgroundColor={inlineStyles?.tableAltRowBackgroundColour}
                          />
                        )}
                      </div>
                    </Tabs.Content>
                  );
                })
              : false}
            {modalActive ? (
              <GenericModal
                modalStyles="justify-center lg:justify-start align-center py-12 overflow-auto"
                modalChild={
                  <>
                    <DialogTitle className="sr-only">
                      {menuRepeater?.[clicked]?.menuName || ''}
                    </DialogTitle>
                    <img src={activeImage?.url || ''} className="" alt={activeImage?.alt || ''} />
                  </>
                }
                modalChildStyles="max-w-screen-lg"
                setModalActive={setModalActive}
                modalActive={modalActive}
                modalSettings={modalSettings}
              />
            ) : (
              false
            )}
          </Tabs.Root>
        </div>
      </div>
    </div>
  );
}

interface TabProps {
  id: string;
  title?: string;
  textColor?: string;
  isClicked?: boolean;
  activeTabAccentColour?: string;
  activeTabTextColour?: string;
  className?: string;
}

function MenuTab({
  id,
  title,
  textColor,
  isClicked,
  activeTabAccentColour,
  activeTabTextColour,
  className,
}: TabProps) {
  return (
    <Tabs.Trigger
      value={id}
      className={className}
      style={{
        color: activeTabTextColour || textColor || undefined,
        borderBottom: isClicked ? `4px solid ${activeTabAccentColour}` : undefined,
      }}
    >
      {title}
    </Tabs.Trigger>
  );
}

interface PanelProps {
  colTitle?: string;
  colItems?: string;
}

function MenuPanel({ colTitle, colItems }: PanelProps) {
  return (
    <div className="w-full sm:w-5/12 lg:w-full lg:max-w-xs">
      {colTitle ? (
        <HeadingStyle text={colTitle} type="h3" className="text-inverse text pb-12 text-center" />
      ) : (
        ''
      )}
      <div
        className={cx(styles.menuList, {
          'pt-0 lg:pt-12': !colTitle,
          'pt-4': colTitle,
        })}
        dangerouslySetInnerHTML={{
          __html: HandleWYSIWYGContent(colItems),
        }}
      />
    </div>
  );
}

interface TableProps {
  repeater?: any;
  headingSpan?: {
    columnSpan?: number;
    text?: string;
  }[];
  textColor?: string;
  textAlignment?: any;
  headerBackground?: string;
  headerTextColor?: string;
  rowTextColor?: string;
  rowBackgroundColor?: string;
  altRowTextColor?: string;
  altRowBackgroundColor?: string;
}

function TableSection({
  repeater,
  textColor,
  rowTextColor,
  rowBackgroundColor,
  altRowTextColor,
  altRowBackgroundColor,
  textAlignment,
  headerBackground,
  headerTextColor,
}: TableProps) {
  let bgAlt = '';

  const tableRows = repeater?.map(
    (
      item: NonNullable<
        NonNullable<TabbedSectionProps['menuRepeater']>[number]['sectionTableColumns']
      >[number],
      key: number
    ) => {
      if (key % 2 === 0) {
        bgAlt = 'bg-border-alt';
      } else {
        bgAlt = '';
      }
      const rowtextColourDecider = key % 2 === 0 ? altRowTextColor : rowTextColor;
      if (key === 0) {
        return (
          <thead key={key}>
            <tr
              className="bg-primary-alt text-bg text-center font-bold"
              style={{ textAlign: textAlignment, background: headerBackground }}
            >
              {item.column1 ? (
                <th scope="col" className="p-2" style={{ color: headerTextColor }}>
                  {item.column1}
                </th>
              ) : null}
              {item.column2 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column2}
                </th>
              ) : null}
              {item.column3 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column3}
                </th>
              ) : null}
              {item.column4 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column4}
                </th>
              ) : null}
              {item.column5 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column5}
                </th>
              ) : null}
              {item.column6 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column6}
                </th>
              ) : null}
              {item.column7 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column7}
                </th>
              ) : null}
              {item.column8 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column8}
                </th>
              ) : null}
            </tr>
          </thead>
        );
      } else {
        return (
          <tbody key={key} className="text-center">
            <tr
              className={bgAlt}
              style={{
                textAlign: textAlignment,
                background: key % 2 === 0 ? altRowBackgroundColor : rowBackgroundColor,
                color: key % 2 === 0 ? altRowTextColor : rowTextColor,
              }}
            >
              {item.column1 ? (
                <th
                  scope="row"
                  className="p-2"
                  style={{ color: rowtextColourDecider || textColor }}
                >
                  {item.column1}
                </th>
              ) : null}
              {item.column2 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column2}</td>
              ) : null}
              {item.column3 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column3}</td>
              ) : null}
              {item.column4 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column4}</td>
              ) : null}
              {item.column5 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column5}</td>
              ) : null}
              {item.column6 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column6}</td>
              ) : null}
              {item.column7 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column7}</td>
              ) : null}
              {item.column8 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>{item.column8}</td>
              ) : null}
            </tr>
          </tbody>
        );
      }
    }
  );

  return <table className={cx('mt-16 w-full', styles.mobileWidth1024)}>{tableRows}</table>;
}

interface GalleryProps {
  image?: string;
  altText?: string;
  setModalActive?: any;
  setActiveImage?: any;
}

function TabbedGalleryImages({ image, setActiveImage, setModalActive, altText }: GalleryProps) {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();

  return (
    <button
      data-testid="gallery-img-btn"
      className="relative w-full overflow-hidden md:w-1/2 lg:w-1/3"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onClick={() => {
        setModalActive(true);
        setActiveImage({
          url: image,
          alt: altText,
        });
      }}
      type="button"
      aria-label={`${t('openModal')} ${altText || t('image')}`}
    >
      <Img
        loader={customLoader}
        src={imageUrl(image || undefined)}
        alt={altText}
        width={700}
        height={560}
        layout="responsive"
        className="w-full"
      />
      <span
        className={cx(
          'bg-text absolute left-0 top-0 flex size-full items-center justify-center opacity-80 transition-all duration-200 ease-in-out',
          {
            '-translate-y-full': !hovered,
            '-translate-y-0': hovered,
          }
        )}
      >
        <MagnifyingGlass
          className={cx(
            'text-bg w-12 fill-current transition-all delay-100 duration-300 ease-in-out',
            {
              'opacity-0 -rotate-180 scale-0': !hovered,
              'opacity-100 rotate-0 scale-100': hovered,
            }
          )}
        />
      </span>
    </button>
  );
}
