import cx from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import type { HeroImageOverlayProps } from './hero-image.types';
import { HeroVideo, HeroVideoControls } from './video/video';
import { ArrowRight, ArrowLeft } from '@curated-property/icons';
import { VideoCaptions, VideoTranscript } from '@dx-ui/osc-video-player';
import { SharedContext } from '@curated-property/utils';

export function HeroImageOverlay({
  hideAnimations,
  animations,
  videoAutoplay,
  videoCtaPlay,
  videoCtaPause,
  videoActive,
  captionTracks,
  transcriptTracks,
  audioTracks,
  setVideoActive,
  videoUrl,
  videoPlayerProps,
  videoMuteChoice,
  videoQualityOverride,
  videoQualitySlider,
  videoChoice,
  imageCarousel,
  slideIndex,
  next,
  prev,
  copyContent,
  isFullWidthOverlay,
  isTabletSize,
  inlineStyles,
}: HeroImageOverlayProps) {
  const { t } = useTranslation();
  const areControlsPlacedRight = inlineStyles?.videoMastheadCtaPosition === 'right';
  const videoControlsPosition =
    isFullWidthOverlay || isTabletSize
      ? ''
      : areControlsPlacedRight
      ? 'right-2.5 2xl:right-0'
      : 'left-1 2xl:left-0';

  const { enableHHR } = useContext(SharedContext);
  const heroCarouselBackgroundDefault = enableHHR ? '' : '#F2F2F2';

  return (
    <div className="h-full">
      <div
        ref={animations?.ref}
        data-testid="contentOverlay"
        className={cx(
          'font-headline pointer-events-none relative flex size-full flex-col flex-wrap items-center justify-end leading-none',
          {
            'lg:justify-center z-30 max-w-screen-2xl mx-auto': !isFullWidthOverlay && !isTabletSize,
          }
        )}
      >
        {copyContent}
        {(videoChoice || imageCarousel?.length) && (
          <div
            className={cx('z-30 mx-auto flex w-full px-2.5 lg:px-0', {
              'justify-end': areControlsPlacedRight && (isFullWidthOverlay || isTabletSize),
              'max-w-screen-lg': isFullWidthOverlay || isTabletSize,
            })}
          >
            <div
              className={cx('flex items-center', videoControlsPosition, animations?.three, {
                'absolute bottom-2.5 md:bottom-5 px-2.5': !isFullWidthOverlay && !isTabletSize,
                'mb-2.5 md:mb-5': isFullWidthOverlay || isTabletSize,
              })}
            >
              {imageCarousel?.length && (
                <div
                  className={cx(
                    'text-text cp-heroImage-carouselControls pointer-events-auto flex h-12 items-center justify-center text-base',
                    inlineStyles?.videoCtaShadow || 'shadow-xl',
                    inlineStyles?.heroCarouselRoundedCorners ||
                      inlineStyles?.videoCtaRoundedCorners ||
                      'rounded-sm'
                  )}
                  style={{
                    backgroundColor:
                      inlineStyles?.heroCarouselBackgroundColour ?? heroCarouselBackgroundDefault,
                  }}
                >
                  <button
                    data-testid="heroSliderPrevious"
                    aria-label={`${t('goToPrevious')}, ${t('slideAnnounce', {
                      currentSlideNum: slideIndex + 1,
                      totalSlides: imageCarousel?.length,
                      slideTitle: imageCarousel?.[slideIndex]?.altText,
                    })}`}
                    onClick={prev}
                    className="flex size-full items-center justify-center px-4"
                  >
                    <ArrowLeft
                      className="mr-0.5 size-4"
                      fillColor={inlineStyles?.heroCarouselArrowColour}
                    />
                  </button>
                  <button
                    data-testid="heroSliderNext"
                    aria-label={`${t('goToNext')}, ${t('slideAnnounce', {
                      currentSlideNum: slideIndex + 1,
                      totalSlides: imageCarousel?.length,
                      slideTitle: imageCarousel?.[slideIndex]?.altText,
                    })}`}
                    onClick={next}
                    className="flex size-full items-center justify-center border-x-2 px-4"
                    style={{
                      borderColor:
                        inlineStyles?.heroCarouselDividerColour ||
                        inlineStyles?.videoCtaBorderColor,
                    }}
                  >
                    <ArrowRight
                      className="ml-0.5 size-4"
                      fillColor={inlineStyles?.heroCarouselArrowColour}
                    />
                  </button>
                  <div
                    className="flex size-full min-w-[52px] items-center justify-center px-3"
                    style={{ color: inlineStyles?.heroCarouselArrowColour }}
                  >
                    {`${slideIndex + 1}/${imageCarousel.length}`}
                  </div>
                  <span data-testid="heroSliderPosition" aria-live="polite" className="sr-only">{`${
                    slideIndex + 1
                  } ${t('of')} ${imageCarousel.length}`}</span>
                </div>
              )}
              {videoChoice && (
                <HeroVideoControls
                  inlineStyles={inlineStyles}
                  videoMuteChoice={videoMuteChoice}
                  videoPlayerProps={videoPlayerProps}
                  videoCtaPause={videoCtaPause}
                  videoCtaPlay={videoCtaPlay}
                  setVideoActive={setVideoActive}
                  captionTracks={captionTracks}
                  transcriptTracks={transcriptTracks}
                  audioTracks={audioTracks}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {videoChoice && (
        <div>
          <HeroVideo
            videoUrl={videoUrl}
            videoQualityOverride={videoQualityOverride}
            videoQualitySlider={videoQualitySlider}
            videoAutoplay={videoAutoplay}
            hideAnimations={hideAnimations}
            videoPlayerProps={videoPlayerProps}
            videoActive={videoActive}
          />
          <VideoCaptions
            {...videoPlayerProps?.videoCaptionProps}
            className={cx(
              'absolute inset-x-0 bottom-20 z-20 mx-auto w-[calc(100%-2rem)] sm:max-w-fit',
              {
                'bottom-44': isFullWidthOverlay || isTabletSize,
              }
            )}
          />
          <VideoTranscript
            className="container z-30 mb-5 shadow-lg"
            {...videoPlayerProps?.videoTranscriptProps}
          />
        </div>
      )}
    </div>
  );
}
