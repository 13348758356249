import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sinorhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-30 h-20 fill-current text-bg antialiased md:mr-5 md:w-32"
    fillColor="#fff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-20 w-32 fill-current text-bg antialiased" fillColor="#fff" />
);
