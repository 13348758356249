import type * as Types from './dx-gql.ts';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { DXError } from '@dx-ui/types-graphql';
export const AmenitiesFeaturesFragmentFragmentDoc = `
    fragment AmenitiesFeaturesFragment on Hotel {
  hotelAmenities: amenities(filter: {groups_includes: [hotel]}) {
    id
    name
  }
  accessibleFeatures: features(
    filter: {type_in: [adaaccessible, allamenities, allhotelsafety, allhotelservices, allhotelattributes]}
  ) {
    name
  }
  nonAccessibleFeatures: features(filter: {type: non_accessible}) {
    name
  }
}
    `;
export const HotelPoliciesFragmentFragmentDoc = `
    fragment HotelPoliciesFragment on Hotel {
  creditCardTypes {
    name
  }
  pets {
    description
    kennelServiceDesc
    kennelServiceOffered
    otherServiceDesc
    otherServiceOffered
    petCharge
    petChargeFmt
    petChargeRefundable
    petMaxSize
    petMaxWeightFmt
    petsAllowed
    servicePetsAllowed
    servicePetsDesc
  }
  overview {
    nor1Enabled
    resortFeeDisclosureDesc
  }
  policy {
    wifi {
      freeWlanMsg
      wlanInRoom
      wlanInRoomDesc
      wlanInRoomDisclaimer
      wlanInRoomFee
      wlanInRoomFeeFmt
      wlanPublic
      wlanPublicFee
      wlanPublicFeeFmt
    }
    smoking {
      nonSmokingDesc
      smokingDesc
    }
    fees {
      desc
    }
    additionalPolicies(filter: {headline: "Face Coverings"}) {
      headline
      type
      link {
        adaDescription
        isNewWindow
        label
        url
      }
      terms {
        heading {
          value
        }
        description {
          value_noTx: value
          value
        }
      }
    }
  }
  policyOptions(filter: {name: cancellationPolicy}) {
    name
    value
    label
    options {
      name
      value
      label
    }
  }
  safetyAndSecurity {
    smokingPolicyDesc
  }
  foodAndBeverage {
    hasFreeBreakfast
    freeBreakfastMsg
  }
  internetAccess {
    freeWlanMsg
    hasWlanInRoom
    wlanInRoomDesc
    wlanInRoomFee
    wlanInRoomFeeFmt
  }
  parking {
    hasFreeParking
    hasSelfParking
    hasValetParking
    hasOnSiteParking
    hasInOutParkingPrivilege
    inOutPrivileges
    hasSecuredParking
    hasParkingFacility
    hasCoveredParking
    chargedSelf
    chargedSelfAccess
    valetDescs
    selfDesc
    selfParkingComments
    valetDesc
    valetOnlyParking
    valetParkingComments
    selfParkingCharge {
      chargeAmount
      chargeAmountFmt
      parkingComments
    }
    valetParkingCharge {
      chargeAmount
      chargeAmountFmt
      chargeAmount
    }
    chargedValet
    hasAccessibleParking
    accessibleParkingInSelfParkingFacility
    otherParkingInfo
    otherParkingInformation
    selfDesc
    selfDescs
  }
}
    `;
export const MeetingsFragmentFragmentDoc = `
    fragment MeetingsFragment on Hotel {
  showMeetingsSimplified
  meetings {
    desc
    eEventsUrl
    headline
    hiltonLinkUrl
    largestExhibitSpaceArea
    largestExhibitSpaceAreaFmt
    largestMeetingRoom
    largestMeetingRoomArea
    largestMeetingRoomAreaFmt
    largestMeetingRoomFmt
    numberOfMeetingRooms
    numberOfMeetingRoomsFmt
    rfpUrl
    totalArea
    totalAreaFmt
    totalExhibitSpaceArea
    totalExhibitSpaceAreaFmt
    totalMeetingRoomsArea
    totalMeetingRoomsAreaFmt
    meetingRooms {
      _id
      area
      areaFmt
      banquet
      boothEightByTen
      boothTenByTen
      configurationCapacities {
        _id
        banquet
        booth10x10
        booth8x10
        classroom
        conference
        hollowSquare
        reception
        tabletopBooths
        theater
        uShaped
      }
      capacityBanquet
      capacityConference
      capacityHollowSquare
      capacityReception
      capacitySchoolRoom
      capacityTheatre
      capacityUShaped
      ceilingHeight
      ceilingHeightFmt
      classroom
      conference
      desc
      floorId
      floorIdDesc
      floorName
      hollowSquare
      includeInTtlArea
      isForExhibitUse
      isOffSite
      length
      lengthFmt
      locationDesc
      name
      name_noTx: name
      reception
      roomId
      roomIdDesc
      roomRank
      sqrFootage
      sqrFootageFmt
      tabletopBooths
      theater
      typeDesc
      uShaped
      width
      widthFmt
    }
  }
}
    `;
export const PropertyAlertsFragmentFragmentDoc = `
    fragment PropertyAlertsFragment on Hotel {
  alerts {
    description(format: md)
    headline
    type
    link {
      _id
      adaDescription
      isNewWindow
      label
      url
    }
  }
}
    `;
export const RestaurantsFragmentFragmentDoc = `
    fragment RestaurantsFragment on Hotel {
  restaurantOverview {
    restaurants(enhance: true, filter: {onSite: true}) {
      id
      awards {
        name
      }
      contactDetails {
        information
        type
      }
      images {
        altText
        url
      }
      expandedImage: image(variant: diningExpanded) {
        altText
        url
      }
      halfAndHalfImage: image(variant: halfAndHalf) {
        altText
        url
      }
      overviewImage: image(variant: diningOverview) {
        altText
        variants(filter: {size: sm}) {
          size
          url
        }
      }
      attire
      averagePrice
      breakfast
      coverCharge
      description
      dinner
      headline
      link {
        _id
        adaDescription
        isNewWindow
        label
        url
      }
      lunch
      name_noTx: name
      onSite
      operatingHours {
        _id
        headline
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
      remarks
    }
    desc
    headline
  }
}
    `;
export const OfferListingFragmentDoc = `
    fragment OfferListing on StaticOfferOptions {
  offers {
    id
    headline
    shortDescription
    longDescription
    name
    type
    attributes {
      name
      id
    }
    externalLink {
      adaDescription
      isNewWindow
      label
      url
    }
    images(imageVariant: offerListing) {
      ogImage: url(width: 400, height: 400)
      altText
      variants {
        url
        size
      }
    }
    bookStart
    bookEnd
    stayStart
    stayEnd
    policy {
      _id
      name
      terms {
        _id
        description {
          value
        }
      }
    }
  }
}
    `;
export const AssociatedHotelInfoDocument = {
  operationName: 'associatedHotelInfo',
  operationString: `query associatedHotelInfo($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
    name
    ctyhocn
    shopAvailOptions {
      maxNumOccupants
      ageBasedPricing
      adultAge
      maxNumChildren
    }
    facilityOverview {
      headline
      shortDesc
      creditCardOnly
    }
    display {
      open
      preOpenMsg
      resEnabled
      resEnabledDate
      openDateFmt(format: "long")
    }
    localization {
      gmtHours
      gmtHoursFmt
    }
    ...PropertyAlertsFragment
    ...MeetingsFragment
    ...RestaurantsFragment
    ...HotelPoliciesFragment
    ...AmenitiesFeaturesFragment
  }
}

      ${PropertyAlertsFragmentFragmentDoc}
${MeetingsFragmentFragmentDoc}
${RestaurantsFragmentFragmentDoc}
${HotelPoliciesFragmentFragmentDoc}
${AmenitiesFeaturesFragmentFragmentDoc}`,
  originalOpName: 'associatedHotelInfo',
};
export function useAssociatedHotelInfoQuery<
  TData = Types.AssociatedHotelInfoQuery,
  TError = DXError
>(
  variables: Types.AssociatedHotelInfoQueryVariables,
  options?: Omit<UseQueryOptions<Types.AssociatedHotelInfoQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.AssociatedHotelInfoQuery, TError, TData>({
    queryKey: [AssociatedHotelInfoDocument, variables],
    ...options,
  });
}
export function serverSideAssociatedHotelInfoQuery(
  queryClient: QueryClient,
  variables: Types.AssociatedHotelInfoQueryVariables,
  options?: FetchQueryOptions<Types.AssociatedHotelInfoQuery>
) {
  return queryClient.fetchQuery<Types.AssociatedHotelInfoQuery>({
    queryKey: [AssociatedHotelInfoDocument, variables],
    ...options,
  });
}

export const Brand_Hotel_ShopAvailOptionsDocument = {
  operationName: 'brand_hotel_shopAvailOptions',
  operationString: `query brand_hotel_shopAvailOptions($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
    ...MeetingsFragment
    ...PropertyAlertsFragment
    ...RestaurantsFragment
    ...HotelPoliciesFragment
    ...AmenitiesFeaturesFragment
    shopAvailOptions {
      maxNumOccupants
      ageBasedPricing
      adultAge
      maxNumChildren
    }
    associatedHotels
    name
    brandCode
    totalRooms
    brand {
      name
      formalName
    }
    facilityOverview {
      headline
      shortDesc
      creditCardOnly
      isWorldwideResort
      allowAdultsOnly
    }
    images {
      master {
        url
      }
    }
    address {
      addressStacked: addressFmt(format: "stacked")
      addressLine1
      city
      city_noTx: city
      country
      countryName
      countryName_noTx: countryName
      state
      stateName
      stateName_noTx: stateName
    }
    registration {
      checkinTime
      checkinTimeFmt
      checkoutTime
      checkoutTimeFmt
      earlyCheckinText
      earlyDepartureFee
      earlyDepartureFeeFmt
      earlyDepartureFeeType
      earlyDepartureText
      eCheckin
      lateCheckoutFee
      lateCheckoutFeeFmt
      lateCheckoutText
      minAgeToRegister
    }
    contactInfo {
      emailAddress1
      faxNumber
      phoneNumber
      networkDisclaimer
    }
    localization {
      coordinate {
        latitude
        longitude
      }
      currency {
        description
      }
      gmtHours
      gmtHoursFmt
      timeZone
      currencyCode
    }
    display {
      open
      preOpenMsg
      resEnabled
      resEnabledDate
      openDateFmt(format: "long")
    }
    tripAdvisorLocationSummary {
      rating
      ratingImageUrl
      numReviews
      reviews {
        id
        lang
        locationId
        publishedDate
        rating
        helpfulVotes
        ratingImageUrl
        url
        text
        travelDate
        user {
          username
        }
        title
      }
    }
  }
  brand(brandCode: "HI", language: "en") {
    footer {
      sections {
        links {
          url
          label
        }
      }
    }
  }
  bannerOffers(name: "wifi", language: $language) {
    image(ratios: [threeByTwo, fourByThree]) {
      altText
      ratios {
        url
        size
      }
    }
    link {
      adaDescription
      campaignId
      isNewWindow
      label
      url
    }
    label
    headline
    segmentIds
    shortDescription
  }
}

      ${MeetingsFragmentFragmentDoc}
${PropertyAlertsFragmentFragmentDoc}
${RestaurantsFragmentFragmentDoc}
${HotelPoliciesFragmentFragmentDoc}
${AmenitiesFeaturesFragmentFragmentDoc}`,
  originalOpName: 'brand_hotel_shopAvailOptions',
};
export function useBrand_Hotel_ShopAvailOptionsQuery<
  TData = Types.Brand_Hotel_ShopAvailOptionsQuery,
  TError = DXError
>(
  variables: Types.Brand_Hotel_ShopAvailOptionsQueryVariables,
  options?: Omit<
    UseQueryOptions<Types.Brand_Hotel_ShopAvailOptionsQuery, TError, TData>,
    'queryKey'
  >
) {
  return useQuery<Types.Brand_Hotel_ShopAvailOptionsQuery, TError, TData>({
    queryKey: [Brand_Hotel_ShopAvailOptionsDocument, variables],
    ...options,
  });
}
export function serverSideBrand_Hotel_ShopAvailOptionsQuery(
  queryClient: QueryClient,
  variables: Types.Brand_Hotel_ShopAvailOptionsQueryVariables,
  options?: FetchQueryOptions<Types.Brand_Hotel_ShopAvailOptionsQuery>
) {
  return queryClient.fetchQuery<Types.Brand_Hotel_ShopAvailOptionsQuery>({
    queryKey: [Brand_Hotel_ShopAvailOptionsDocument, variables],
    ...options,
  });
}

export const FrenchLegaleseDocument = {
  operationName: 'frenchLegalese',
  operationString: `query frenchLegalese($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
    policy {
      additionalPolicies(filter: {subType: "french-tourism-mediator-fr"}) {
        headline
        type
        link {
          adaDescription
          isNewWindow
          label
          url
        }
        terms {
          heading {
            value
          }
          description {
            value_noTx: value
            value
          }
        }
      }
    }
  }
}`,
  originalOpName: 'frenchLegalese',
};
export function useFrenchLegaleseQuery<TData = Types.FrenchLegaleseQuery, TError = DXError>(
  variables: Types.FrenchLegaleseQueryVariables,
  options?: Omit<UseQueryOptions<Types.FrenchLegaleseQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FrenchLegaleseQuery, TError, TData>({
    queryKey: [FrenchLegaleseDocument, variables],
    ...options,
  });
}
export function serverSideFrenchLegaleseQuery(
  queryClient: QueryClient,
  variables: Types.FrenchLegaleseQueryVariables,
  options?: FetchQueryOptions<Types.FrenchLegaleseQuery>
) {
  return queryClient.fetchQuery<Types.FrenchLegaleseQuery>({
    queryKey: [FrenchLegaleseDocument, variables],
    ...options,
  });
}

export const PropertyAlertsDocument = {
  operationName: 'propertyAlerts',
  operationString: `query propertyAlerts($ctyhocn: String!, $language: String!) {
  hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
    ...PropertyAlertsFragment
  }
}

      ${PropertyAlertsFragmentFragmentDoc}`,
  originalOpName: 'propertyAlerts',
};
export function usePropertyAlertsQuery<TData = Types.PropertyAlertsQuery, TError = DXError>(
  variables: Types.PropertyAlertsQueryVariables,
  options?: Omit<UseQueryOptions<Types.PropertyAlertsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.PropertyAlertsQuery, TError, TData>({
    queryKey: [PropertyAlertsDocument, variables],
    ...options,
  });
}
export function serverSidePropertyAlertsQuery(
  queryClient: QueryClient,
  variables: Types.PropertyAlertsQueryVariables,
  options?: FetchQueryOptions<Types.PropertyAlertsQuery>
) {
  return queryClient.fetchQuery<Types.PropertyAlertsQuery>({
    queryKey: [PropertyAlertsDocument, variables],
    ...options,
  });
}

export const HotelOfferListingDocument = {
  operationName: 'hotelOfferListing',
  operationString: `query hotelOfferListing($language: String!, $ctyhocn: String!) {
  hotelStaticOfferOptions(language: $language, ctyhocn: $ctyhocn) {
    ...OfferListing
  }
}

      ${OfferListingFragmentDoc}`,
  originalOpName: 'hotelOfferListing',
};
export function useHotelOfferListingQuery<TData = Types.HotelOfferListingQuery, TError = DXError>(
  variables: Types.HotelOfferListingQueryVariables,
  options?: Omit<UseQueryOptions<Types.HotelOfferListingQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.HotelOfferListingQuery, TError, TData>({
    queryKey: [HotelOfferListingDocument, variables],
    ...options,
  });
}
export function serverSideHotelOfferListingQuery(
  queryClient: QueryClient,
  variables: Types.HotelOfferListingQueryVariables,
  options?: FetchQueryOptions<Types.HotelOfferListingQuery>
) {
  return queryClient.fetchQuery<Types.HotelOfferListingQuery>({
    queryKey: [HotelOfferListingDocument, variables],
    ...options,
  });
}
