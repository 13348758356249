export function CloseIcon({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      data-testid="closeIcon"
      className={className}
      version="1.1"
      width="24"
      height="24"
      viewBox="0 0 1024 1024"
      style={{ fill: fillColor }}
    >
      <path d="M1009.778 114.489l-100.267-100.267-397.511 397.511-397.511-397.511-100.267 100.267 397.511 397.511-397.511 397.511 100.267 100.267 397.511-397.511 397.511 397.511 100.267-100.267-397.511-397.511z" />
    </svg>
  );
}
