export function ShuttleService({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M13,6.625H5a.375.375,0,0,0,0,.75h8c.3041,0,7.2595.0836,7.5935,5.25H16A.59.59,0,0,1,15.375,12V9a.375.375,0,0,0-.75,0v3A1.3364,1.3364,0,0,0,16,13.375h4.625v2.6123A.5965.5965,0,0,1,20,16.625h-.8521a1.8578,1.8578,0,0,0-3.2958,0H10.1479a1.8578,1.8578,0,0,0-3.2958,0H6A.6256.6256,0,0,1,5.375,16V13a.375.375,0,0,0-.75,0v3A1.377,1.377,0,0,0,6,17.375h.6378c-.0029.0424-.0128.0828-.0128.126a1.875,1.875,0,0,0,3.75,0c0-.0432-.01-.0836-.0128-.126h5.2756c-.0029.0424-.0128.0828-.0128.126a1.875,1.875,0,0,0,3.75,0c0-.0432-.01-.0836-.0128-.126H20A1.3417,1.3417,0,0,0,21.375,15.99V13C21.375,6.6982,13.084,6.625,13,6.625Zm-4.5,12a1.125,1.125,0,1,1,1.125-1.124A1.1257,1.1257,0,0,1,8.5,18.625Zm9,0a1.125,1.125,0,1,1,1.125-1.124A1.1257,1.1257,0,0,1,17.5,18.625Z" />
          <path d="M9.375,9A.3751.3751,0,0,0,9,8.625H3a.375.375,0,0,0,0,.75H9A.3751.3751,0,0,0,9.375,9Z" />
          <path d="M4.625,11A.3751.3751,0,0,0,5,11.375h7a.375.375,0,0,0,0-.75H5A.3751.3751,0,0,0,4.625,11Z" />
        </g>
      </g>
    </svg>
  );
}
