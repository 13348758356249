import gql from 'graphql-tag-transform.macro';
import { MediaBlocksFrag, fullWidthMediaComponentSettings } from './common-vars';

export const FullWidthImage = gql`
fragment FullWidthImage on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_FullWidthImageContent {
        copy
        fieldGroupName
        styles
        title
        title_noTx: title
        flipImageHorizontally
        subtitle
        subtitle_noTx: subtitle
        image {
          altText
          sourceUrl
        }
        mediaBlock {
          ... on Media_block {
            id
            ${MediaBlocksFrag}
          }
        }
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        enableCarousel
        enableCropping
        cropType
        cropHeight
        cropWidth
        xPosition
        xPositionAdvanced
        yPosition
        yPositionAdvanced
        autoPosition
        carouselItems {
          styles
          title
          title_noTx: title
          subtitle
          subtitle_noTx: subtitle
          image {
            altText
            sourceUrl
          }
          copy
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          showHide
        }
        fullWidthImageComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${fullWidthMediaComponentSettings}
        }
      }
}
`;
