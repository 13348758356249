import * as React from 'react';
import cx from 'classnames';

const InfoCircle: React.FC<{ error: boolean; info: string | null; 'data-testid'?: string }> = ({
  error,
  info,
  ...rest
}) => {
  if (error || info !== null) {
    return (
      <div
        className={cx(
          'absolute -top-2 inline-block size-5 rounded-full text-text-inverse ltr:-right-2 rtl:-left-2',
          {
            'bg-danger': !!error,
            'bg-primary': !!info && !error,
          }
        )}
        {...(rest?.['data-testid'] && { 'data-testid': `${rest?.['data-testid']}-info` })}
      >
        <span aria-hidden="true">{error ? '!' : info !== null ? info : null}</span>
      </div>
    );
  }

  return null;
};

export type ShopFormButtonProps = React.ComponentProps<'button'> & {
  label: string;
  product: string;
  hasError: boolean;
  info?: string | null;
  'data-testid'?: string;
};

export const ShopFormButton = React.forwardRef<HTMLButtonElement, ShopFormButtonProps>(
  ({ product, children, label, hasError, info = null, ...props }, ref) => {
    return (
      <div data-osc-product={product} className="flex flex-1">
        <button
          data-osc-product="shop-form-selection-button"
          className={cx(
            'btn btn-lg shop-form-btn relative w-full px-2 motion-safe:transition lg:px-4',
            props.className
          )}
          ref={ref}
          type="button"
          {...props}
        >
          <span>{label}</span>
          <InfoCircle error={hasError} info={info} data-testid={props?.['data-testid']} />
        </button>
        {children}
      </div>
    );
  }
);

ShopFormButton.displayName = 'ShopFormButton';

export default ShopFormButton;
