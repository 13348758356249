import type { UserLocation } from '../location-util';

export const sessionKey = 'userLocation';

export const initialUserSession: UserLocation = {
  city: undefined,
  continent: undefined,
  country: undefined,
  coordinate: undefined,
  region: undefined,
  zipCode: undefined,
};

export const initialContext = {
  ...initialUserSession,
  isLoading: false,
  clear: () => {},
};
