import gql from 'graphql-tag-transform.macro';
import { multiColumnListComponentSettings } from './common-vars';

export const MultiColumnList = gql`
fragment MultiColumnList on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_MultiColumnList {
          fieldGroupName
          title
          copy
          column {
            columnHeading
            list {
              listItem
            }
          }
          multiColumnListComponentSettings {
            ${multiColumnListComponentSettings}
            #ClonedComponentSettingsStart
            componentBackgroundColor
            componentBackgroundSize
            componentBackgroundRepeat
            componentBackgroundPosition
            contentBackgroundColor
            contentBackgroundSize
            contentBackgroundRepeat
            contentBackgroundPosition
            contentBackgroundColorLayerTwo
            subtitleColor
            textAlignment
            textColor
            titleColor
            componentBackgroundImage {
              sourceUrl
            }
            contentBackgroundImage {
              sourceUrl
            }
            paddingTop {
              paddingTopDesktop
              paddingTopTablet
              paddingTopMobile
            }
            paddingBottom {
              paddingBottomDesktop
              paddingBottomTablet
              paddingBottomMobile
            }
            showHide
            animationDirection
            hideAnimations
            animationDirection
            hideParallax
            sliderAnimations
            zoomOnHover
            containerMaxWidth
            headingsNoTranslate
            #ClonedComponentSettingsEnd
          }
        } 
}
`;
