import { createContext } from 'react';
import type {
  EventCalendarHotelContextInfoProps,
  EventCalendarInlineStyleProps,
  EventCalendarModalSettingsProps,
  RegularEventCombinedItemProps,
  SpecialEventPropsForType,
} from './lib/event-calendar-props';

export interface EventCalendarInfoContext {
  hotelInfo?: EventCalendarHotelContextInfoProps;
  recurringEvents?: Array<RegularEventCombinedItemProps>[];
  specialEvents?: Array<SpecialEventPropsForType>;
  modalSettings?: EventCalendarModalSettingsProps;
  eventCategoriesWithCustom?: Array<{ label: string; value: string }>;
  enableHHR?: boolean | null;
  inlineStyles?: EventCalendarInlineStyleProps;
  localeLanguage?: string;
}

const EventCalendarInfoContext = createContext<EventCalendarInfoContext>(null);

export { EventCalendarInfoContext };
