import { type SVGAttributes } from 'react';

const RestaurantIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path
          d="M9.756 15.193a1.96 1.96 0 0 0 0 2.763l7.301 7.3c.76.76 2.002.76 2.762 0l7.925 7.926a.98.98 0 0 0 1.381 0 .98.98 0 0 0 0-1.38l-9.306-9.308-.23-.23-7.07-7.07a1.96 1.96 0 0 0-2.763 0zM18.033 8.715l3.618 3.618c.865.886.968 2.257.308 3.254.073.043.145.093.208.155L33.674 27.25a.98.98 0 0 1 .002 1.38.978.978 0 0 1-1.383 0L20.786 17.123a1.007 1.007 0 0 1-.148-.194 2.615 2.615 0 0 1-3.328-.255l-3.617-3.618"
          strokeWidth="1.2"
        />
        <path
          d="M15.25 11.587l3.571 3.573M16.63 10.206l3.572 3.572M39.552 22.132c0 9.62-7.8 17.42-17.42 17.42-9.62 0-17.42-7.8-17.42-17.42 0-9.62 7.8-17.42 17.42-17.42 9.62 0 17.42 7.8 17.42 17.42z"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default RestaurantIcon;
