import Head from 'next/head';

type Schema = {
  name: string;
  logo: string;
  description: string;
  thumbnailUrl: string;
  uploadDate: string;
  duration: string;
  embedUrl: string;
};

export type VideoPlayerMarkupProps = {
  markupSchemas?: Schema[] | null;
};

export const VideoPlayerMarkup: React.FC<VideoPlayerMarkupProps> = ({ markupSchemas }) => {
  return markupSchemas?.map(
    ({ name, logo, description, thumbnailUrl, uploadDate, duration, embedUrl }) => (
      <Head key={name}>
        <script
          data-testid="video-markup-schema"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'VideoObject',
              name,
              logo,
              description,
              thumbnailUrl,
              uploadDate,
              duration,
              embedUrl,
            }),
          }}
        />
      </Head>
    )
  );
};
