// Now referred to as Multi-Column Compare
import { useState } from 'react';
import cx from 'classnames';
import type { ACFAnchorProps, AnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from './functions/global-instance-styles';
import type { CropProps } from './cropped-image/cropped-image';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import { WrappedSubtitle, appliedCloudinaryParams } from '@curated-property/utils';
import contentStyles from './css/contentStyle.module.css';
import { HandleAnimations, HandleWYSIWYGContent } from './functions/helper';

export function twoColumnComparePropMapper(
  componentData: TwoColumnCompareProps & {
    twoColumnCompareComponentSettings?: StyleObject;
  },
  key: number,
  globalData: StyleObject
) {
  return {
    repeater: componentData?.repeater,
    globalStyles: globalData?.twoColumnCompareComponentSettings,
    instanceStyles: componentData?.twoColumnCompareComponentSettings,
  };
}

interface iTwoColumnCompareTile {
  title?: string;
  title_noTx?: string;
  subtitle?: string;
  subtitle_noTx?: string;
  copy?: string;
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  logo?: {
    sourceUrl?: string;
    altText?: string;
    mediaDetails?: {
      height?: string | number;
      width?: string | number;
    };
  };
  buttons?: Array<{
    link?: AnchorProps;
  }>;
  backgroundPosition?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundImage?: {
    sourceUrl?: string;
    altText?: string;
  };
}

type CombinedProps = CropProps & iTwoColumnCompareTile;

export interface TwoColumnCompareProps {
  repeater?: Array<CombinedProps>;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function TwoColumnCompare({
  repeater,
  globalStyles,
  instanceStyles,
}: TwoColumnCompareProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const repeaterItems = repeater?.map((item, key) => {
    return (
      <div
        className={cx(
          inlineStyles?.showHide && 'hidden',
          'flex w-full lg:w-1/2 lg:px-1.5 lg:first:pl-0 lg:last:pr-0 '
        )}
        key={key}
      >
        <HoverTileCenter
          index={key}
          title={inlineStyles?.headingsNoTranslate ? item?.title_noTx : item?.title}
          subtitle={inlineStyles?.headingsNoTranslate ? item?.subtitle_noTx : item?.subtitle}
          image={item?.image?.sourceUrl}
          cropping={{
            enableCropping: item?.enableCropping,
            cropType: item?.cropType,
            xPosition: item?.xPosition,
            xPositionAdvanced: item?.xPositionAdvanced,
            yPosition: item?.yPosition,
            yPositionAdvanced: item?.yPositionAdvanced,
            cropWidth: item?.cropWidth,
            cropHeight: item?.cropHeight,
            autoPosition: item?.autoPosition,
          }}
          imageAlt={item?.image?.altText}
          logo={item?.logo?.sourceUrl}
          logoWidth={item?.logo?.mediaDetails?.width?.toString() || ''}
          logoHeight={item?.logo?.mediaDetails?.height?.toString() || ''}
          logoAlt={item?.logo?.altText}
          copy={item?.copy}
          buttons={item?.buttons}
          repeaterLength={repeater?.length || 0}
          backgroundImage={item?.backgroundImage?.sourceUrl}
          backgroundRepeat={item?.backgroundRepeat}
          backgroundSize={item?.backgroundSize}
          backgroundPosition={item?.backgroundPosition}
          inlineStyles={inlineStyles}
          smallerTiles={repeater?.length === 3 ? true : false}
        />
      </div>
    );
  });
  return (
    <div
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || '',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
      data-element-id="two-column-compare-wrapper"
    >
      <div
        className={cx('container flex flex-col px-2 lg:flex-row lg:px-0', paddingStyles)}
        key="TwoColumnCompare"
      >
        {repeaterItems}
      </div>
    </div>
  );
}

interface HoverProps {
  index: number;
  title?: string;
  subtitle?: string;
  image?: string;
  cropping?: CropProps;
  imageAlt?: string;
  logo?: string;
  logoWidth: string;
  logoHeight: string;
  logoAlt?: string;
  copy?: string;
  repeaterLength: number;
  buttons?: (ACFAnchorProps | undefined)[];
  backgroundImage?: string;
  backgroundRepeat?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  inlineStyles?: any;
  smallerTiles?: boolean;
}

function HoverTileCenter({
  index,
  title,
  subtitle,
  image,
  cropping,
  imageAlt,
  logo,
  logoWidth,
  logoHeight,
  logoAlt,
  copy,
  repeaterLength,
  buttons,
  backgroundImage,
  backgroundRepeat,
  backgroundSize,
  backgroundPosition,
  inlineStyles,
  smallerTiles,
}: HoverProps) {
  const [hovered, setHovered] = useState(false);

  const imgTag =
    image && image !== '' ? (
      <CroppedImage
        className="w-full"
        src={image}
        loader={() => {
          return customLoader({
            src: image || '',
            width: 1024,
            height: 1024,
            crop: cropping?.enableCropping || false,
            cropType: cropping?.cropType || '',
            cropHeight: cropping?.cropHeight || '',
            cropWidth: cropping?.cropWidth || '',
            xPosition: cropping?.xPosition || '',
            xPositionAdvanced: cropping?.xPositionAdvanced || '',
            yPosition: cropping?.yPosition || '',
            yPositionAdvanced: cropping?.yPositionAdvanced || '',
          });
        }}
        width={1024}
        height={1024}
        alt={imageAlt}
      />
    ) : null;

  const logoUrl = logo;
  const LogoTag =
    logoUrl && logoUrl !== '' ? (
      <div className="pb-6">
        <CroppedImage
          src={logoUrl}
          width={parseInt(logoWidth)}
          height={parseInt(logoHeight)}
          className="object-cover pb-5"
          alt={logoAlt}
          priority
        />
      </div>
    ) : (
      subtitle && (
        <WrappedSubtitle>
          <h2
            className={cx(
              'text-inverse font-headline mb-4 text-2xl font-black leading-none',
              smallerTiles ? 'md:text-3xl' : 'md:text-4xl',
              {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              }
            )}
            style={{ color: inlineStyles?.subtitleColor }}
          >
            {subtitle}
          </h2>
        </WrappedSubtitle>
      )
    );
  const titleTag = title ? (
    <p
      data-testid={`compare-title-${index}`}
      aria-hidden="true"
      className={cx(
        'two-column-compare-title text-bg-inverse bg-bg absolute bottom-5 left-5 px-4 py-2 text-xl transition',
        {
          'opacity-0 translate-y-full': hovered,
          'opacity-100 delay-150 -translate-y-0': !hovered,
          OneLinkNoTx: inlineStyles?.headingsNoTranslate,
        }
      )}
    >
      {title}
    </p>
  ) : null;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : `${
          index === 0 && repeaterLength <= 2
            ? 'lg:-translate-x-8 lg:translate-y-0'
            : repeaterLength <= 2
            ? 'lg:translate-x-8 lg:translate-y-0'
            : ''
        } -translate-y-12`,
    delayOne: 'delay-300',
  });

  return (
    <div
      data-testid={`compare-column-${index}`}
      className={cx('relative overflow-hidden', animations?.one)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      style={{ transitionDelay: `${index + 3}00ms` }}
    >
      {imgTag}
      <div
        ref={animations?.ref}
        data-testid={`compare-panel-${index}`}
        className={cx(
          'from-text absolute inset-0 flex w-full items-end align-top opacity-0 transition duration-300 ease-in-out',
          {
            'bg-bg-inverse opacity-50 delay-150': hovered,
          }
        )}
        style={{ marginBottom: 4 + 'px' }}
      />
      {titleTag}
      <div
        data-testid={`reveal-panel-${index}`}
        className={cx(
          'bg-bg text-bg-inverse absolute left-1/2 top-1/2 z-10 flex h-auto w-11/12 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center pb-6 transition duration-300 ease-in-out sm:w-2/3 md:pb-12',
          'px-5 text-center md:px-10',
          {
            'opacity-0 translate-y-full': !hovered,
            'opacity-100 delay-150': hovered,
          },
          logoUrl ? 'pt-10 md:pt-16' : 'pt-6 md:pt-10',
          smallerTiles ? 'sm:w-3/4' : 'sm:w-2/3'
        )}
        style={{
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
          backgroundImage: backgroundImage
            ? `url('${appliedCloudinaryParams(backgroundImage)}')`
            : undefined,
          backgroundSize: backgroundSize || 'cover',
          backgroundRepeat: backgroundRepeat || 'no-repeat',
          backgroundPosition: backgroundPosition || 'left center',
        }}
      >
        {LogoTag}
        <div
          className={cx(
            'mb-1 max-h-full text-lg leading-[26px]',
            contentStyles.paragraphStyle,
            contentStyles.listStyle
          )}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
          }}
          style={{
            color: inlineStyles?.textColor || null,
          }}
        />
        {buttons ? (
          <div className="mt-1">
            {buttons?.map((link, key) => {
              return (
                <AnchorLink
                  key={key}
                  url={link?.link?.url}
                  title={link?.link?.title}
                  target={link?.link?.target}
                  buttonStyle={link?.buttonStyle || 'btn-secondary'}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
