export function OutdoorPool({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16.5,12.375a1.875,1.875,0,1,0-1.875-1.876A1.8776,1.8776,0,0,0,16.5,12.375Zm0-3a1.125,1.125,0,1,1-1.125,1.124A1.1257,1.1257,0,0,1,16.5,9.375Z" />
          <path d="M21.24,16.7119a8.53,8.53,0,0,0-4.9687-1.8164c-.2622,0-.5191.0205-.7757.0421a1.8544,1.8544,0,0,0-.3142-1.6593c-.1962-.3281-.8173-1.2558-1.4746-2.2392-.7177-1.0733-1.46-2.1836-1.7675-2.6866a1.26,1.26,0,0,0-1.85-.5175c-.0937.0478-.3154.1552-.6171.3017C8.55,8.583,6.834,9.4141,5.8057,9.9873a1.1752,1.1752,0,0,0-.6846,1.4854,1.1446,1.1446,0,0,0,1.5273.4521c.1875-.0684.6592-.2881,1.4121-.64a21.1128,21.1128,0,0,1,2.1426-.9258.4781.4781,0,0,1,.1377.247l.5518,1.1661L6.8477,14.0732a.3753.3753,0,1,0,.3711.6524l4.3466-2.4727a.3758.3758,0,0,0,.1534-.4863l-.6749-1.4111a1.1,1.1,0,0,0-.4746-.6514A.8483.8483,0,0,0,9.89,9.65c-.3.0957-1.0791.456-2.1464.9551-.583.2724-1.1856.5537-1.3526.6142-.3144.1153-.5342.0586-.5879-.0595-.0566-.1241.0508-.3418.3682-.5186,1.0088-.5625,2.7129-1.3867,3.6279-1.83.31-.1494.5371-.26.6328-.3086.38-.1943.5948-.206.8672.24.3164.5166,1.0625,1.6328,1.7852,2.7129.6514.9746,1.2676,1.8955,1.4531,2.206.3559.5985.46.9246-.0207,1.3989-.7076.12-1.3987.2853-2.075.4536a16.4326,16.4326,0,0,1-4.043.6436,8.7505,8.7505,0,0,1-5.163-1.4541.375.375,0,0,0-.4717.583,9.3057,9.3057,0,0,0,5.6347,1.6211,17.0342,17.0342,0,0,0,4.2237-.666,14.9983,14.9983,0,0,1,3.6494-.5967,7.9156,7.9156,0,0,1,4.4892,1.6426.3734.3734,0,0,0,.5274-.0488A.3738.3738,0,0,0,21.24,16.7119Z" />
        </g>
      </g>
    </svg>
  );
}
