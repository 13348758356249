import cx from 'classnames';
import { useState } from 'react';
import type { CropProps } from './cropped-image/cropped-image';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import {
  useLanguage,
  WrappedSubtitle,
  isReducedMotion,
  appliedCloudinaryParams,
  sanitize,
} from '@curated-property/utils';
import type { StyleObject } from './functions/global-instance-styles';
import {
  GIS_merge,
  GIS_TextAlignment,
  GIS_Array,
  GIS_Padder,
} from './functions/global-instance-styles';
import {
  stripInternalUrl,
  isRelativeUrl,
  HandleWYSIWYGContent,
  useDeviceDetect,
  HandleAnimations,
} from './functions/helper';

export interface IMultiColumnWithHoverStyles {
  componentBackgroundImage?: { altText?: string; sourceUrl?: string };
  componentBackgroundSize?: string;
  componentBackgroundRepeat?: string;
  componentBackgroundPosition?: string;
  componentBackgroundColor?: string;
  textAlignment?: string;
  titleColor?: string;
  subtitleColor?: string;
  textColor?: string;
  nonHoverPadding?: string;
  nonHoverBackgroundColor?: string;
  hoverBackgroundColor?: string;
  imageOverlayGradient?:
    | 'none'
    | 'gradient-to-r'
    | 'gradient-to-l'
    | 'gradient-to-t'
    | 'gradient-to-b'
    | string;
}

export interface MultiColumnWithHoverProps {
  header?: string;
  columnCount?: number;
  repeater?: CombinedProps[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function MultiColumnWithHover({
  header,
  columnCount,
  repeater,
  globalStyles,
  instanceStyles,
}: MultiColumnWithHoverProps) {
  repeater = repeater?.filter((item) => !item?.showHide);
  const styles = GIS_merge(globalStyles, instanceStyles) as IMultiColumnWithHoverStyles;
  const textAlignment = GIS_TextAlignment(styles?.textAlignment);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const colCount = columnCount;
  const xlGridColCount = `xl:grid-cols-${colCount}`;
  const hideAnimations = inlineStyles?.hideAnimations !== 'show' || isReducedMotion;

  return (
    <div
      className={cx('cp-multiColumnTilesWithHover', paddingStyles)}
      style={{
        backgroundColor: styles?.componentBackgroundColor,
        backgroundImage: styles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              styles?.componentBackgroundImage?.sourceUrl,
              styles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: styles?.componentBackgroundSize || undefined,
        backgroundRepeat: styles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: styles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div className="container">
        {header ? (
          <h2
            data-testid="header"
            className="text-inverse font-headline mb-6 text-center text-2xl font-black leading-none md:text-5xl"
            style={{
              textAlign: textAlignment,
              color: styles?.titleColor,
            }}
          >
            {header}
          </h2>
        ) : null}
        <div
          key={header}
          data-testid="imageRevealContainer"
          // DON'T DELETE THE NEXT LINE
          //className="grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 xl:grid-cols-3 xl:grid-cols-4 xl:grid-cols-2"
          className={`${xlGridColCount} grid grid-cols-1 gap-2 overflow-hidden md:grid-cols-2`}
        >
          {repeater?.map((item, key) => {
            return (
              <ImageReveal
                key={key}
                ind={key}
                button={item?.button}
                image={item?.image}
                cropping={{
                  enableCropping: item?.enableCropping,
                  cropType: item?.cropType,
                  xPosition: item?.xPosition,
                  xPositionAdvanced: item?.xPositionAdvanced,
                  yPosition: item?.yPosition,
                  yPositionAdvanced: item?.yPositionAdvanced,
                  cropWidth: item?.cropWidth,
                  cropHeight: item?.cropHeight,
                  autoPosition: item?.autoPosition,
                }}
                title={inlineStyles?.headingsNoTranslate ? item?.title_noTx : item?.title}
                hideAnimations={hideAnimations}
                animationDirection={inlineStyles?.animationDirection}
                copy={item?.copy}
                styles={{
                  imageOverlayGradient: styles?.imageOverlayGradient,
                  nonHoverPadding: styles?.nonHoverPadding,
                  nonHoverBackgroundColor: styles?.nonHoverBackgroundColor,
                  hoverBackgroundColor: styles?.hoverBackgroundColor,
                  titleColor: styles?.subtitleColor,
                  copyColor: styles?.textColor,
                  headerType: header ? 'h3' : 'p',
                  headingsNoTranslate: inlineStyles?.headingsNoTranslate,
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface ImageRevealProps {
  ind?: number;
  copy?: string;
  title?: string;
  title_noTx?: string;
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  styles?: {
    imageOverlayGradient?: string;
    nonHoverPadding?: string;
    nonHoverBackgroundColor?: string;
    hoverBackgroundColor?: string;
    titleColor?: string;
    copyColor?: string;
    headerType?: 'h1' | 'h2' | 'h3' | 'p';
    headingsNoTranslate?: boolean;
  };
  button?: {
    url?: string;
    title?: string;
    target?: string;
  };
  cropping?: CropProps;
  showHide?: boolean;
  hideAnimations?: boolean;
  animationDirection?: string;
}

interface CombinedProps extends CropProps, ImageRevealProps {}

export function ImageReveal({
  ind,
  copy,
  title,
  image,
  styles,
  button,
  cropping,
  hideAnimations,
  animationDirection,
}: CombinedProps) {
  const [hovered, setHovered] = useState(false);
  const language = useLanguage();
  let processedUrl = stripInternalUrl(
    button?.url,
    (process.env.NEXT_PUBLIC_INTERNAL_URLS || '').split(',')
  );
  if (isRelativeUrl(processedUrl) && processedUrl !== undefined) {
    processedUrl = processedUrl?.startsWith(`/${language}/`)
      ? processedUrl.replace(`${language}/`, '')
      : processedUrl;
    processedUrl = `/${language}${processedUrl}`;
  }
  const hoverEnabled = typeof copy === 'string' && copy !== '';

  const hoverTitlePadding = `p-${styles?.nonHoverPadding ?? 8}`;

  const device = useDeviceDetect();

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: animationDirection
      ? `${animationDirection}-8`
      : `${
          (ind || 0) % 2 === 0 ? 'translate-x-8' : '-translate-x-8'
        } lg:-translate-y-12 lg:translate-x-0`,
    delayOne: 'delay-300',
  });

  return (
    <div
      ref={animations?.ref}
      className={cx(animations?.one, 'relative w-full overflow-hidden')}
      style={{ transitionDelay: `${(ind || 0) + 1}00ms` }}
    >
      <CroppedImage
        src={image?.sourceUrl || ''}
        loader={() => {
          return customLoader({
            src: image?.sourceUrl || '',
            width: '640',
            height: '720',
            crop: cropping?.enableCropping || false,
            cropType: cropping?.cropType || '',
            cropHeight: cropping?.cropHeight || '',
            cropWidth: cropping?.cropWidth || '',
            xPosition: cropping?.xPosition || '',
            xPositionAdvanced: cropping?.xPositionAdvanced || '',
            yPosition: cropping?.yPosition || '',
            yPositionAdvanced: cropping?.yPositionAdvanced || '',
          });
        }}
        className="w-full"
        layout="responsive"
        alt={image?.altText}
        width="640"
        height="720"
      />
      <ImageRevealAnchor
        tabIndex={0}
        className={`display-block bg- absolute inset-0 flex w-full items-end text-left${
          styles?.imageOverlayGradient ?? 'gradient-to-t'
        } from-text`}
        onMouseEnter={() => setHovered(hoverEnabled)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(hoverEnabled)}
        onBlur={() => setHovered(false)}
        target={button?.target}
        processedUrl={processedUrl}
        hoverEnabled={hoverEnabled}
      >
        <HoverTitle
          data-testid="hover-title"
          className={cx(
            `multi-column-tiles-title text-bg ${hoverTitlePadding} w-full text-center text-xl font-bold leading-none transition duration-300 ease-in-out md:text-2xl`,
            {
              'opacity-0 translate-y-full': hovered,
              'opacity-100': !hovered,
              OneLinkNoTx: styles?.headingsNoTranslate,
            }
          )}
          style={{
            backgroundColor: styles?.nonHoverBackgroundColor,
            color: styles?.titleColor,
          }}
          headerType={styles?.headerType}
          title={title}
        />
        {hoverEnabled && (
          <div
            data-testid="hover-content"
            data-element-id="hover-tile-content-hovered"
            className={cx(
              'multi-column-tiles-hovered primary-overlay absolute inset-0 transition delay-200 duration-300 ease-in-out',
              {
                '-translate-y-full opacity-0': !hovered,
                'opacity-100': hovered,
              }
            )}
            style={{
              backgroundColor: styles?.hoverBackgroundColor,
            }}
          >
            <div
              tabIndex={0}
              className="border-bg text-bg absolute inset-0 m-4 flex flex-col justify-start overflow-y-auto border-4 p-4"
              style={{
                color: styles?.copyColor,
                borderColor: styles?.copyColor,
              }}
            >
              <WrappedSubtitle>
                <div
                  aria-hidden="true"
                  className="multi-column-tiles-subtitle mb-2 text-xl font-bold leading-none md:text-xl rtl:text-right"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(title || ''),
                  }}
                />
              </WrappedSubtitle>
              <div
                className="text-lg leading-tight xl:leading-normal rtl:text-right"
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(copy, styles?.copyColor),
                }}
              />
              {device.isMobile && processedUrl && button?.title && (
                <a
                  href={processedUrl}
                  target={button?.target}
                  className="btn btn-secondary mx-auto mt-2 inline-block px-4 py-2 text-base md:hidden"
                >
                  {button?.title}
                </a>
              )}
            </div>
          </div>
        )}
      </ImageRevealAnchor>
    </div>
  );
}

interface ImageRevealAnchorProps extends React.AllHTMLAttributes<HTMLElement> {
  hoverEnabled?: boolean;
  processedUrl?: string;
}

function ImageRevealAnchor({
  hoverEnabled,
  processedUrl,
  children,
  ...otherProps
}: ImageRevealAnchorProps) {
  const device = useDeviceDetect();

  return (device.isMobile && hoverEnabled) || !processedUrl ? (
    <div role="none" data-testid="image-reveal-div" {...otherProps}>
      {children}
    </div>
  ) : (
    <a data-testid="image-reveal-anchor" href={processedUrl} role="button" {...otherProps}>
      {children}
    </a>
  );
}

interface HoverTitleProps extends React.AllHTMLAttributes<HTMLElement> {
  headerType?: 'h1' | 'h2' | 'h3' | 'p';
  title?: string;
}

function HoverTitle({ headerType, title, ...otherProps }: HoverTitleProps) {
  const TitleTag = headerType || 'p';
  const titleTagProps = { ...otherProps };

  return (
    <TitleTag {...titleTagProps} dangerouslySetInnerHTML={{ __html: sanitize(title || '') }} />
  );
}
