import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fill?: string }>(() =>
  import('./fllcici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-16 fill-current text-bg antialiased sm:w-24 lg:w-40" fill="#B08D59" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-40 fill-current text-bg antialiased" fill="#000000" />
);
