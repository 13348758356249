import type { ACFAnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { CropProps } from './cropped-image/cropped-image';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import { HeadingStyle } from './includes/heading-style';
import { HandleWYSIWYGContent, useMediaQuery, HandleAnimations } from './functions/helper';
import { appliedCloudinaryParams, ParallaxLayer } from '@curated-property/utils';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_TextAlignment, GIS_Padder } from './functions/global-instance-styles';
import contentStyles from './css/contentStyle.module.css';

interface ITwoTwoMediaAndCopyStyles {
  componentBackgroundColor?: string;
  componentBackgroundSize?: any;
  componentBackgroundRepeat?: string;
  componentBackgroundPosition?: string;
  contentBackgroundImage?: any;
  contentBackgroundColor?: string;
  contentBackgroundSize?: any;
  contentBackgroundRepeat?: string;
  contentBackgroundPosition?: string;
  titleColor?: any;
  subtitleColor?: any;
  textColor?: string;
  smallImageBorderColour?: string;
  smallImageBorderWidth?: number;
  textAlignment?: string;
  horizontalDirection?: string;
  verticalDirection?: string;
  componentBackgroundImage?: any;
  showHide?: boolean;
  hideAnimations?: string;
  hideParallax?: string;
  animationDirection?: string;
  headingsNoTranslate?: boolean;
}

interface ImageProps {
  sourceUrl?: string;
  altText?: string;
}

export interface TwoTwoMediaCopyProps {
  title1?: string;
  title1_noTx?: string;
  title2?: string;
  title2_noTx?: string;
  copy1?: string;
  copy2?: string;
  image1?: ImageProps;
  image2?: ImageProps;
  buttons1?: Array<ACFAnchorProps>;
  buttons2?: Array<ACFAnchorProps>;
  reverse?: boolean;
  imageColumn1AutoPosition?: boolean;
  imageColumn1CropHeight?: string;
  imageColumn1CropType?: string;
  imageColumn1CropWidth?: string;
  imageColumn1EnableCropping?: boolean;
  imageColumn1XPosition?: string;
  imageColumn1XPositionAdvanced?: string;
  imageColumn1YPosition?: string;
  imageColumn1YPositionAdvanced?: string;
  imageColumn2AutoPosition?: boolean;
  imageColumn2CropHeight?: string;
  imageColumn2CropType?: string;
  imageColumn2CropWidth?: string;
  imageColumn2EnableCropping?: boolean;
  imageColumn2XPosition?: string;
  imageColumn2XPositionAdvanced?: string;
  imageColumn2YPosition?: string;
  imageColumn2YPositionAdvanced?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function TwoTwoMediaCopy(props: TwoTwoMediaCopyProps) {
  const inlineStyles = GIS_Array(props.globalStyles, props.instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  return (
    <section
      className={cx(inlineStyles?.showHide && 'hidden', paddingStyles)}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
      }}
      data-element-id="two-two-media-and-copy-wrapper"
    >
      <div className="container flex w-full flex-col gap-2 md:flex-row md:gap-4 lg:items-center lg:justify-between">
        {[1, 2].map((col, idx) => {
          return (
            <MediaCopySection
              isFirst={idx === 0}
              title={
                inlineStyles?.headingsNoTranslate
                  ? props[`title${col}_noTx` as keyof TwoTwoMediaCopyProps['title1_noTx']]
                  : props[`title${col}` as keyof TwoTwoMediaCopyProps['title1']]
              }
              image={props[`image${col}` as keyof TwoTwoMediaCopyProps['image1']]}
              cropping={{
                enableCropping:
                  props[
                    `imageColumn${col}EnableCropping` as keyof NonNullable<
                      TwoTwoMediaCopyProps['imageColumn1EnableCropping']
                    >['valueOf']
                  ],
                cropType:
                  props[
                    `imageColumn${col}CropType` as keyof TwoTwoMediaCopyProps['imageColumn1CropType']
                  ],
                xPosition:
                  props[
                    `imageColumn${col}XPosition` as keyof TwoTwoMediaCopyProps['imageColumn1XPosition']
                  ],
                xPositionAdvanced:
                  props[
                    `imageColumn${col}XPositionAdvanced` as keyof TwoTwoMediaCopyProps['imageColumn1XPositionAdvanced']
                  ],
                yPosition:
                  props[
                    `imageColumn${col}YPosition` as keyof TwoTwoMediaCopyProps['imageColumn1YPosition']
                  ],
                yPositionAdvanced:
                  props[
                    `imageColumn${col}YPositionAdvanced` as keyof TwoTwoMediaCopyProps['imageColumn1YPositionAdvanced']
                  ],
                cropWidth:
                  props[
                    `imageColumn${col}CropWidth` as keyof TwoTwoMediaCopyProps['imageColumn1CropWidth']
                  ],
                cropHeight:
                  props[
                    `imageColumn${col}CropHeight` as keyof TwoTwoMediaCopyProps['imageColumn1CropHeight']
                  ],
                autoPosition:
                  props[
                    `imageColumn${col}AutoPosition` as keyof NonNullable<
                      TwoTwoMediaCopyProps['imageColumn1AutoPosition']
                    >['valueOf']
                  ],
              }}
              copy={props[`copy${col}` as keyof TwoTwoMediaCopyProps['copy1']]}
              copyPosition={idx === 0 ? 'bottom' : 'top'}
              reverse={props?.reverse}
              buttons={props[`buttons${col}` as keyof TwoTwoMediaCopyProps['buttons1']]}
              inlineStyles={inlineStyles}
            />
          );
        })}
      </div>
    </section>
  );
}

interface ContentProps {
  isFirst?: boolean;
  title?: string;
  image?: ImageProps;
  cropping?: CropProps;
  copy?: string;
  copyPosition?: 'top' | 'bottom';
  reverse?: boolean;
  buttons?: Array<ACFAnchorProps>;
  className?: string;
  inlineStyles?: ITwoTwoMediaAndCopyStyles;
}

export function MediaCopySection({
  isFirst,
  title,
  image,
  cropping,
  copy,
  copyPosition,
  reverse,
  buttons,
  className,
  inlineStyles,
}: ContentProps) {
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const copyAlignment = `items-${inlineStyles?.textAlignment?.replace('flex-', '')}`;
  // if reverse / horizontal direction is set, change copypostion=top to bottom and vice versa
  // dont run this if horizontal direction is set to regular
  let reverseClass = null;
  if (
    (reverse === true || inlineStyles?.horizontalDirection === 'reverse') &&
    inlineStyles?.horizontalDirection !== 'regular'
  ) {
    reverseClass = 'reversed';
    if (copyPosition === 'top') {
      copyPosition = 'bottom';
    } else {
      copyPosition = 'top';
    }
  }
  //
  const marginWidth = 16;
  // lg:order-1 lg:order-2 order-1 order-2
  const copyStyles = copyPosition === 'bottom' ? 'md:order-2' : 'md:order-1';
  const imageStyles = copyPosition === 'bottom' ? 'md:order-1 md:-mb-16' : 'md:order-2 md:-mt-16';

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : (reverseClass && !isFirst) || !isFirst
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });
  const parallaxSpeed = copyPosition === 'top' ? 55 : -55;

  return (
    <div
      data-testid="mediaCopySection"
      className={`flex w-full flex-col px-2 md:px-0 ${className} ${reverseClass} ${copyPosition}`}
    >
      <div className={imageStyles}>
        <ParallaxLayer
          hideAnimations={animations?.hideAnimations || inlineStyles?.hideParallax === 'hide'}
          inView={animations?.inView}
          parallaxSpeed={parallaxSpeed}
        >
          <CroppedImage
            className=""
            src={image?.sourceUrl || ''}
            loader={() => {
              return customLoader({
                src: image?.sourceUrl || '',
                width: '695',
                height: '695',
                crop: cropping?.enableCropping || false,
                cropType: cropping?.cropType || '',
                cropHeight: cropping?.cropHeight || '',
                cropWidth: cropping?.cropWidth || '',
                xPosition: cropping?.xPosition || '',
                xPositionAdvanced: cropping?.xPositionAdvanced || '',
                yPosition: cropping?.yPosition || '',
                yPositionAdvanced: cropping?.yPositionAdvanced || '',
              });
            }}
            width={695}
            height={695}
            alt={image?.altText}
            layout="responsive"
          />
        </ParallaxLayer>
      </div>
      <div
        ref={animations?.ref}
        className={`bg-bg-alt z-1 relative mx-auto w-full p-4 lg:px-24 lg:py-10 ${copyStyles}`}
        style={{
          width: useMediaQuery(767) ? '' : `calc(100% - ${marginWidth * 2}px)`,
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
          textAlign: textAlignment,
          alignItems: inlineStyles?.textAlignment,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
        }}
      >
        <HeadingStyle
          text={title}
          type="h2"
          className={cx('mb-4', animations?.one, {
            OneLinkNoTx: inlineStyles?.headingsNoTranslate,
          })}
          textColorInline={inlineStyles?.titleColor}
        />
        <div
          className={cx(
            `${copyAlignment} flex flex-col text-lg`,
            contentStyles.paragraphStyle,
            contentStyles.listStyle,
            animations?.two
          )}
          style={{ color: inlineStyles?.textColor }}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
          }}
        />
        {/* buttons */}
        {buttons ? (
          <div className={cx('mt-4', animations?.three)}>
            {buttons?.map((link, key) => {
              return (
                <AnchorLink
                  key={key}
                  url={link?.link?.url}
                  title={link?.link?.title}
                  target={link?.link?.target}
                  buttonStyle={link?.buttonStyle ?? 'primary'}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
