export function AlertTriangle({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,13.875a.3751.3751,0,0,0,.375-.375V9a.375.375,0,0,0-.75,0v4.5A.3751.3751,0,0,0,12,13.875Z" />
          <path d="M12,15.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,15.375Z" />
          <path d="M19.78,15.3691,14.3643,6.248A2.834,2.834,0,0,0,12,4.6455,2.834,2.834,0,0,0,9.6357,6.248L4.22,15.3691a2.7541,2.7541,0,0,0-.27,2.8057A2.753,2.753,0,0,0,6.5,19.375h11a2.753,2.753,0,0,0,2.5508-1.2A2.7541,2.7541,0,0,0,19.78,15.3691Zm-.3809,2.4346A2.0566,2.0566,0,0,1,17.5,18.625H6.5a2.0566,2.0566,0,0,1-1.8994-.8213,2.0558,2.0558,0,0,1,.2637-2.0517L10.28,6.6309A2.1281,2.1281,0,0,1,12,5.3955a2.1281,2.1281,0,0,1,1.72,1.2354l5.416,9.1211A2.0558,2.0558,0,0,1,19.3994,17.8037Z" />
        </g>
      </g>
    </svg>
  );
}
