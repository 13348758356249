import dynamic from 'next/dynamic';
import { TapestryLogo } from '@curated-property/icons';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./czmpdup/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current pr-4 text-bg subpixel-antialiased" />
);

export const brandLogo = (
  <TapestryLogo className="hidden w-36 fill-current pl-4 text-current antialiased sm:block" />
);

export const hotelLogoFooter = <HotelLogo className="fill-current text-bg antialiased" />;
