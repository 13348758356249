import type { useId } from 'react';

export type EventType = ValuesOf<typeof VIDEO_EVENTS>;

export type VideoEventDetail = {
  type: EventType;
  id: ReturnType<typeof useId>;
};

export const VIDEO_EVENTS = {
  PAUSE_OTHERS: 'pause-others',
  PAUSE_SELF: 'pause-self',
} as const;

export const VIDEO_UPDATE_EVENT = 'video-player-update';

export const dispatchVideoEvent = (detail: VideoEventDetail) => {
  window.dispatchEvent(new CustomEvent<VideoEventDetail>(VIDEO_UPDATE_EVENT, { detail }));
};
