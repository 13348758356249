import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import type { FormLabelBase } from './form.types';

type FormLabelHelper = Pick<FormLabelBase, 'required' | 'optional'>;

const FormLabelHelper: React.FC<React.PropsWithChildren<FormLabelHelper>> = ({
  required,
  optional,
}) => {
  const [t] = useTranslation('osc-form');

  const requiredText = required && t('required');
  const optionalText = optional && t('optional');

  const text = requiredText || optionalText;

  return text ? (
    <>
      {' '}
      <span className="font-normal text-text-alt">({text})</span>
    </>
  ) : null;
};

export { FormLabelHelper };
export default FormLabelHelper;
