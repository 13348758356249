import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Location } from '@dx-ui/osc-location';

/**
 * Use the `<ShopFormLocation/>` component inside of the ShopForm in order to provide the OSC experience for querying the location used to search.
 */
export const ShopFormLocation: React.FC<
  React.PropsWithChildren<Partial<Location>> & Pick<Location, 'language'>
> = ({ label, language, placeholder, ...rest }) => {
  const [t] = useTranslation('osc-location');
  return (
    <div className="relative flex flex-basis-full">
      <Location
        label={label ?? t('label')}
        language={language}
        placeholder={placeholder ?? t('placeholder')}
        name="query"
        {...rest}
      />
    </div>
  );
};
