import { HeadingStyle } from '@curated-property/shared-components';
import cx from 'classnames';
import { MenuItemPrice } from './menu-item-price';

export interface MenuSectionProps {
  item?: any;
  inlineStyles?: any;
}

export function MenuSection({ item, inlineStyles }: MenuSectionProps) {
  const baseAlign = item?.sectionAlignment ?? 'center';
  // Detect first highlight item (section may only show one highlighted item)
  const highlightIndex = item?.sectionItems?.findIndex(
    (subItem) => subItem?.highlightItem === true
  );

  const textAlignClass = `text-${baseAlign}`; //TAILWIND_HINT: text-center text-left text-right
  return (
    <div
      data-testid="menu-section"
      style={{
        backgroundColor: item?.sectionBackgroundColour,
        borderColor: item?.sectionBorderColour,
        textAlign: inlineStyles?.sectionAlignment || 'left',
      }}
      className={cx(
        `flex flex-col items-center justify-center border-2 border-transparent pb-5 pt-10`
      )}
    >
      {item?.sectionTitle && (
        <HeadingStyle
          text={inlineStyles?.sectionTitleNoTx ? item?.sectionTitle_noTx : item?.sectionTitle}
          type="h3"
          styledAs="h3"
          className={cx(
            'htmlMenuTitle text-inverse pb-3 text-center !text-[34px]',
            {
              OneLinkNoTx: inlineStyles?.sectionTitleNoTx,
            },
            inlineStyles?.htmlMenuTitleWeight !== 'bold' ? 'font-normal' : 'font-extrabold'
          )}
          textColorInline={inlineStyles?.titleColor}
        />
      )}

      {item?.sectionSubtitle && (
        <HeadingStyle
          text={
            inlineStyles?.sectionSubtitleNoTx ? item?.sectionSubtitle_noTx : item?.sectionSubtitle
          }
          type="p"
          styledAs="p"
          className={cx(
            'htmlMenuSubtitle text-inverse pb-10 text-center text-lg',
            inlineStyles?.htmlMenuSubtitleWeight !== 'bold' ? 'font-normal' : 'font-extrabold',
            {
              OneLinkNoTx: inlineStyles?.sectionSubtitleNoTx,
            }
          )}
          textColorInline={inlineStyles?.subtitleColor}
        />
      )}
      <div
        data-testid="html-menu-item-wrapper"
        className={cx(
          item?.sectionLayout === 'Two Columns'
            ? 'two-columns mx-auto block w-[96%] justify-center sm:flex sm:w-full sm:flex-row sm:flex-wrap md:w-4/5'
            : 'one-column w-[96%] md:w-[500px]'
        )}
      >
        {item?.sectionItems?.map((menuItem, key) => {
          const borderColor = item?.spotlightBorderColour
            ? item?.spotlightBorderColour
            : inlineStyles?.htmlMenuSpotlightBorderColour;

          return (
            <div
              data-testid="html-menu-item"
              key={key.toString()}
              className={cx(
                'html-menu-item',
                item?.sectionLayout === 'Two Columns' ? 'w-full sm:w-1/2' : 'w-full',
                'mb-6 flex flex-col px-4 py-2'
              )}
            >
              <div
                data-element-id="border-highlight"
                className={cx(
                  'p-4',
                  menuItem?.highlightItem && highlightIndex === key ? `border` : '',
                  menuItem?.highlightItem && highlightIndex === key && !borderColor
                    ? `border-primary`
                    : ''
                )}
                style={{
                  borderColor: menuItem?.highlightItem && borderColor ? `${borderColor}` : '',
                }}
              >
                {(menuItem?.itemLine1_noTx || menuItem?.itemLine1) && (
                  <div
                    data-html-menu="item-title"
                    style={{
                      color: item?.itemTitleColour || inlineStyles?.htmlMenuItemLine1Colour,
                    }}
                    className={cx('relative text-[28px]', textAlignClass)}
                  >
                    <HeadingStyle
                      text={
                        inlineStyles?.itemLine1NoTx ? menuItem?.itemLine1_noTx : menuItem?.itemLine1
                      }
                      type={!item?.sectionTitle ? 'h3' : 'h4'}
                      styledAs="p"
                      className={cx(
                        'htmlMenuLine1 text-[28px]',
                        inlineStyles?.htmlMenuItemLine1Weight !== 'bold'
                          ? 'font-normal'
                          : '!font-bold',
                        {
                          OneLinkNoTx: inlineStyles?.itemLine1NoTx,
                        }
                      )}
                    />
                  </div>
                )}
                <div
                  data-html-menu="item-subtitle"
                  style={{
                    color: item?.itemDescriptionColour ?? inlineStyles?.htmlMenuItemLine2Colour,
                  }}
                  className={cx(
                    'htmlMenuLine2 pt-4 text-lg',
                    textAlignClass,
                    inlineStyles?.htmlMenuItemLine2Weight !== 'bold' ? 'font-normal' : 'font-bold',
                    {
                      OneLinkNoTx: inlineStyles?.itemLine2NoTx,
                    }
                  )}
                >
                  {inlineStyles?.itemLine2NoTx ? menuItem?.itemLine2_noTx : menuItem?.itemLine2}
                </div>
                {menuItem?.itemPrice || menuItem?.itemPrice_noTx ? (
                  <MenuItemPrice
                    inlineStyles={inlineStyles}
                    textAlignClass={textAlignClass}
                    color={item?.itemPriceColour || inlineStyles?.htmlMenuItemLine1Colour}
                    price={
                      inlineStyles?.itemPriceNoTx ? menuItem?.itemPrice_noTx : menuItem?.itemPrice
                    }
                  />
                ) : (
                  ''
                )}
                {menuItem?.itemPrices?.length
                  ? menuItem?.itemPrices?.map((itemPrice, priceKey) => {
                      return (
                        <MenuItemPrice
                          inlineStyles={inlineStyles}
                          textAlignClass={textAlignClass}
                          color={item?.itemPriceColour || inlineStyles?.htmlMenuItemLine1Colour}
                          key={priceKey.toString()}
                          price={
                            inlineStyles?.itemPriceNoTx
                              ? itemPrice?.itemPrice_noTx
                              : itemPrice?.itemPrice
                          }
                        />
                      );
                    })
                  : ''}
                {menuItem?.itemDisclaimer || menuItem?.itemDisclaimer_noTx ? (
                  <div
                    data-html-menu="disclaimer"
                    style={{
                      color: item?.itemDisclaimerColour || inlineStyles?.htmlMenuItemLine1Colour,
                    }}
                    className={cx(
                      'htmlMenuDisclaimer pt-4 text-sm italic',
                      textAlignClass,
                      inlineStyles?.htmlMenuItemDisclaimerWeight !== 'bold'
                        ? 'font-normal'
                        : 'font-bold',
                      {
                        OneLinkNoTx: inlineStyles?.itemDisclaimerNoTx,
                      }
                    )}
                  >
                    {inlineStyles?.itemDisclaimerNoTx
                      ? menuItem?.itemDisclaimer_noTx
                      : menuItem?.itemDisclaimer}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
      {item?.sectionDisclaimer && (
        <>
          <div
            style={{
              color: inlineStyles?.htmlMenuItemLine1Colour,
            }}
            className={cx(
              item?.sectionLayout !== 'Two Column'
                ? 'w-full px-10 md:w-4/5'
                : 'w-[96%] lg:w-[500px]'
            )}
          >
            {(item?.sectionDisclaimerLine1_noTx || item?.sectionDisclaimerLine1) && (
              <HeadingStyle
                text={
                  inlineStyles?.sectionDisclaimerLine1_noTx
                    ? item?.sectionDisclaimerLine1_noTx
                    : item?.sectionDisclaimerLine1
                }
                type="h4"
                textColorInline={item?.sectionDisclaimerColour}
                className={cx(
                  'pb-5 text-2xl',
                  'text-center',
                  inlineStyles?.htmlMenuTitleWeight === 'bold' ? 'font-bold' : 'font-normal',
                  {
                    OneLinkNoTx: inlineStyles?.sectionDisclaimerLine1NoTx,
                  }
                )}
              />
            )}
          </div>
          <div
            style={{
              color: item?.sectionDisclaimerColour || inlineStyles?.htmlMenuItemLine1Colour,
            }}
            className={cx(
              'text-sm',
              'text-center',
              inlineStyles?.htmlMenuItemDisclaimerWeight === 'bold' ? 'font-bold' : 'font-normal',
              {
                OneLinkNoTx: inlineStyles?.sectionDisclaimerLine2NoTx,
              },
              item?.sectionLayout !== 'Two Column'
                ? 'w-full px-10 md:w-4/5'
                : 'w-[96%] md:w-[500px]'
            )}
          >
            {inlineStyles?.sectionDisclaimerLine2NoTx
              ? item?.sectionDisclaimerLine2_noTx
              : item?.sectionDisclaimerLine2}
          </div>
        </>
      )}
    </div>
  );
}
