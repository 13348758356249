import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./cuncici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-gold h-24 w-40 text-bg antialiased md:w-48" />
);

export const hotelLogoFooter = <HotelLogo className="h-12 w-40 fill-bg antialiased md:w-48" />;
