import { WACContext, WACStages } from '../';
import { getOrdinal } from '@curated-property/utils';
import { isAfter, isBefore, isValid } from 'date-fns';
import type { FormEvent, RefObject } from 'react';
import { useContext, useRef, useState } from 'react';
import cx from 'classnames';

import { WACCalendar } from '../calendar';
import { WACContainer, WACStyledSelect } from '../utils';
import { GIS_TextAlignment } from '../../functions/global-instance-styles';
import type { WACStyles } from '../interfaces';

interface WACSelectDates {
  styles: WACStyles;
  startFocusElementRef: RefObject<HTMLSelectElement>;
}

export const WACSelectDatesSection = function ({ styles, startFocusElementRef }: WACSelectDates) {
  const { stage, minDate, maxDate, selectedDate, setSelectedYearMonth } = useContext(WACContext);
  const yearSelectRef = useRef<HTMLSelectElement>(null);
  const monthSelectRef = startFocusElementRef;
  const [dropDownYearMonth, setDropDownYearMonth] = useState(minDate);
  /* date selection */
  const activeYears = [];

  function onDropDownDateChange() {
    let newDate = new Date(
      parseInt(yearSelectRef?.current?.value || ''),
      parseInt(monthSelectRef?.current?.value || ''),
      1
    );
    if (isBefore(newDate, minDate)) {
      newDate = new Date(minDate);
    }
    if (isAfter(newDate, maxDate)) {
      newDate = new Date(maxDate);
    }
    if (dropDownYearMonth !== newDate) setDropDownYearMonth(newDate);
  }

  function onDropDownSelectDate(e: FormEvent) {
    onDropDownDateChange();
    e.preventDefault();
    setSelectedYearMonth?.(dropDownYearMonth);
  }

  for (let i = 0; i < maxDate.getFullYear() - minDate.getFullYear(); i++) {
    const year = minDate.getFullYear() + i;
    const option = (
      <option value={year} key={year} selected={dropDownYearMonth?.getFullYear() === year}>
        {year}
      </option>
    );
    activeYears.push(option);
  }

  const maxMonth =
    dropDownYearMonth.getFullYear() === maxDate.getFullYear() ? maxDate.getMonth() : 11;
  const minMonth =
    dropDownYearMonth.getFullYear() === minDate.getFullYear() ? minDate.getMonth() : 0;
  const activeYearMonths = Array.from({ length: maxMonth - minMonth + 1 }, (_, i) => {
    const monthIndex = minMonth + i;
    const tmpDate = new Date(dropDownYearMonth.getFullYear(), monthIndex, 1);
    const monthTitle = tmpDate.toLocaleDateString('en-US', { month: 'long' });
    return (
      <option
        value={monthIndex}
        key={monthIndex}
        selected={dropDownYearMonth?.getMonth() === monthIndex}
      >
        {monthTitle}
      </option>
    );
  });
  return (
    <div
      className={`${
        stage !== WACStages.DATE && 'hidden'
      } grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3`}
    >
      <WACContainer bgColor={styles?.background?.primary}>
        <h3
          className="mb-6 text-3xl"
          style={{
            textAlign: GIS_TextAlignment(styles?.subheadingAlign),
            color: styles?.subheadingColor,
          }}
        >
          Choose a Date
        </h3>
        <p>When will the wedding take place? We’ll show you what dates are available.</p>
        <form className="my-6 flex flex-col" method="POST">
          <label htmlFor="wac-month-select" className="mb-2 ml-2 font-bold">
            Month
          </label>
          <WACStyledSelect
            aria-label="Month"
            id="wac-month-select"
            testId="wac-month-select"
            ref={monthSelectRef}
            onChange={onDropDownDateChange}
            borderColor={styles?.background?.secondary}
          >
            {activeYearMonths}
          </WACStyledSelect>
          <label htmlFor="wac-year-select" className="mb-2 ml-2 font-bold">
            Year
          </label>
          <WACStyledSelect
            aria-label="Year"
            id="wac-year-select"
            testId="wac-year-select"
            ref={yearSelectRef}
            onChange={onDropDownDateChange}
            borderColor={styles?.background?.secondary}
          >
            {activeYears}
          </WACStyledSelect>
          <div>
            <input
              data-testid="submitDate"
              type="submit"
              value="Submit a Date"
              className="btn btn-primary text hover:text-hover mx-1 mt-2 cursor-pointer px-6 py-2 text-center text-lg sm:w-auto sm:text-left sm:text-xl"
              onClick={onDropDownSelectDate}
            />
          </div>
        </form>
      </WACContainer>
      <WACContainer bgColor={styles?.background?.primary}>
        <WACCalendar styles={styles} />
      </WACContainer>
      <WACContainer
        dataElementId="wac-wedding-details"
        bgColor={
          isValid(selectedDate)
            ? styles?.thirdPanel?.active.backgroundColor
            : styles?.thirdPanel?.inactive.backgroundColor
        }
        style={{
          color: isValid(selectedDate)
            ? styles?.thirdPanel?.active.textColor
            : styles?.thirdPanel?.inactive.textColor,
        }}
        className={cx('col-span-1 md:col-span-2 xl:col-span-1', {
          'wac-wedding-details--active': isValid(selectedDate),
        })}
      >
        <div className="flex h-full flex-col justify-between text-center">
          <h3
            className="mb-6 text-3xl"
            style={{
              textAlign: GIS_TextAlignment(styles?.subheadingAlign),
              color: isValid(selectedDate)
                ? styles?.thirdPanel?.active.textColor
                : styles?.thirdPanel?.inactive.textColor,
            }}
          >
            Wedding Details
          </h3>
          {isValid(selectedDate) ? (
            <div className="align-center flex flex-col text-3xl">
              <div className="mb-4" data-testid="selectedWeekday">
                <strong>
                  {selectedDate?.toLocaleDateString('en-US', {
                    weekday: 'long',
                  })}
                </strong>
              </div>
              <div className="mb-4" data-testid="selectedDayofMonth">
                <strong>
                  {selectedDate?.getDate()}
                  {getOrdinal(selectedDate?.getDate() || 0)}
                </strong>
              </div>
              <div className="text-2xl" data-testid="selectedMonthAndYear">
                {selectedDate?.toLocaleDateString('en-US', {
                  month: 'long',
                })}{' '}
                {selectedDate?.getFullYear()}
              </div>
            </div>
          ) : (
            <p className="text-sm">It looks like you haven&apos;t picked that special day yet.</p>
          )}
          <div className="mx-auto w-48">
            {isValid(selectedDate) ? "Now let's see what venues are available on this date." : null}
          </div>
        </div>
      </WACContainer>
    </div>
  );
};
