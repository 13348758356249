import type { FormInputBase } from '@dx-ui/osc-form';
import type * as React from 'react';
import { useRecentSearches } from './location-options-utils';
import { PlacesAutoCompleteSuggestionOptions } from '../autoComplete-suggestion-options';
import { useTranslation } from 'next-i18next';

type LocationRecentSearchesOption = FormInputBase<
  Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onSelect'>
>;

const LocationRecentSearchesOption = ({ isNearByEnabled }: { isNearByEnabled?: boolean }) => {
  const { t } = useTranslation('osc-location');

  const [recentSearchOptions] = useRecentSearches();
  const displayCount = isNearByEnabled ? 3 : 5;
  const recentSearches = recentSearchOptions?.slice(0, displayCount);

  return (
    <PlacesAutoCompleteSuggestionOptions
      header={t('optionsHeader.recentSearches')}
      suggestions={recentSearches}
    />
  );
};

export { LocationRecentSearchesOption };
export default LocationRecentSearchesOption;
