import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColor?: string;
}>(() => import('./denirhh/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo className="h-24 w-40 fill-bg antialiased" fillColor="#fffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-24 w-48 fill-bg antialiased" fillColor="#fffff" />
);
