import { useEffect, useState } from 'react';
import classnames from 'classnames';
import type { VideoControlsProps } from './video-player.controls';
import type { useVideoPlayer } from './hooks/use-video-player';
import { getActiveCues, getCueText } from './util/cue';
import { findSelectedCaptionTrack, findAllCaptionTracks } from './util/matchers';

type VideoCaptionsProps = ReturnType<typeof useVideoPlayer>['videoCaptionProps'] &
  Pick<VideoControlsProps, 'brandComponentTheme'> &
  React.ComponentProps<'div'>;

export function VideoCaptions({
  activeCaptionTrack,
  videoElement,
  brandComponentTheme: _brandComponentTheme,
  ...elementProps
}: VideoCaptionsProps) {
  const [activeCues, setActiveCues] = useState<VTTCue[]>([]);

  useEffect(() => {
    const htmlTrack = activeCaptionTrack
      ? findSelectedCaptionTrack(videoElement.current, activeCaptionTrack)
      : null;

    if (htmlTrack) {
      setActiveCues(getActiveCues(htmlTrack.track));
      htmlTrack.track.oncuechange = (event) => {
        setActiveCues(getActiveCues(event.target as TextTrack));
      };
    } else {
      setActiveCues([]);
      findAllCaptionTracks(videoElement.current).forEach((htmlTrack) => {
        htmlTrack.track.oncuechange = null;
      });
    }
  }, [activeCaptionTrack, videoElement]);

  if (activeCues.length) {
    return (
      <div
        {...elementProps}
        className={classnames(
          'flex flex-col items-center justify-center overflow-auto bg-text/75 p-2 text-text-inverse lg:text-lg',
          elementProps.className
        )}
      >
        {activeCues.map((cue) => (
          <div
            key={cue.text}
            dangerouslySetInnerHTML={{
              __html: getCueText(cue).html,
            }}
          />
        ))}
      </div>
    );
  }

  return null;
}
