import { format, isAfter } from 'date-fns';

/*
 *
 * takes in the hotels timezone offset and opening time
 * and calculates the correct starting date.
 */

export function parseBookingDate(gmtHours?: number, arrivalDate?: string) {
  let startDate = new Date();
  let formattedTimezoneOffset = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  formattedTimezoneOffset = formattedTimezoneOffset.slice(-6);
  const offsetHours =
    gmtHours === undefined || gmtHours === null
      ? 0
      : gmtHours - Number(formattedTimezoneOffset?.split(':')[0]);
  startDate.setHours(startDate.getHours() + offsetHours);
  if (arrivalDate) {
    const manualStartDate = new Date(arrivalDate);
    const splitDate = arrivalDate?.split('-');
    if (splitDate.length === 3) {
      const yearString = splitDate[0] || '0';
      const monthString = splitDate[1] || '1';
      const dayString = splitDate[2] || '1';

      manualStartDate.setDate(parseInt(dayString, 10));
      manualStartDate.setMonth(parseInt(monthString, 10) - 1);
      manualStartDate.setFullYear(parseInt(yearString, 10));

      if (isAfter(manualStartDate, startDate)) {
        startDate = manualStartDate;
      }
    }
  }
  return startDate;
}
