import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

const LinkContext = createContext({ itemPrefix: '' });

export function useLinkContext() {
  return useContext(LinkContext) || { itemPrefix: '' };
}

export function LinkContextProvider({
  children,
  itemPrefix,
}: {
  children: ReactNode;
  itemPrefix: string;
}) {
  return <LinkContext.Provider value={{ itemPrefix }}>{children}</LinkContext.Provider>;
}
