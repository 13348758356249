import { AuthClient } from './auth-client';
import { isBrowser } from '@dx-ui/utilities-is-browser';

export interface GqlEndpoints {
  browser: string;
  server: string;
}

export interface AuthEndpoints {
  browser: string;
  server: string;
}

export type MakeAuthClientArgs = Omit<
  ConstructorParameters<typeof AuthClient>[0],
  'authEndpoint' | 'gqlEndpoint' | 'ssr'
> & {
  authEndpoints: AuthEndpoints;
  gqlEndpoints: GqlEndpoints;
  ssrMode?: boolean;
};

export function makeAuthClient({ authEndpoints, gqlEndpoints, ...args }: MakeAuthClientArgs) {
  const authClient = new AuthClient({
    authEndpoint: isBrowser ? authEndpoints?.browser : authEndpoints?.server,
    gqlEndpoint: isBrowser ? gqlEndpoints?.browser : gqlEndpoints?.server,
    ...args,
  });

  if (isBrowser && !window.authClient) {
    window.authClient = authClient;
  }

  return authClient;
}
