export function Casino({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M15.75,13.375c-0.3452,0-0.625,0.2798-0.625,0.625s0.2798,0.625,0.625,0.625c0.3452,0,0.625-0.2798,0.625-0.625
            C16.3747,13.655,16.095,13.3753,15.75,13.375z"
            />
            <path
              d="M12,7.2061c0.3452,0,0.625-0.2798,0.625-0.625S12.3452,5.9561,12,5.9561s-0.625,0.2798-0.625,0.625
            C11.3753,6.9261,11.655,7.2058,12,7.2061z"
            />
            <path
              d="M14.75,8.625c0.3452,0,0.625-0.2798,0.625-0.625c0-0.3452-0.2798-0.625-0.625-0.625S14.125,7.6548,14.125,8
            C14.1253,8.345,14.405,8.6247,14.75,8.625z"
            />
            <path
              d="M9.75,12.625c-0.3452,0-0.625,0.2798-0.625,0.625s0.2798,0.625,0.625,0.625s0.625-0.2798,0.625-0.625
            C10.3747,12.905,10.095,12.6253,9.75,12.625z"
            />
            <path
              d="M7.25,14.375c-0.3452,0-0.625,0.2798-0.625,0.625s0.2798,0.625,0.625,0.625S7.875,15.3452,7.875,15
            C7.8747,14.655,7.595,14.3753,7.25,14.375z"
            />
            <path
              d="M12.625,9.25c0-0.3452-0.2798-0.625-0.625-0.625s-0.625,0.2798-0.625,0.625S11.6548,9.875,12,9.875
            C12.345,9.8747,12.6247,9.595,12.625,9.25z"
            />
            <path
              d="M9.25,7.375C8.9048,7.375,8.625,7.6548,8.625,8c0,0.3452,0.2798,0.625,0.625,0.625S9.875,8.3452,9.875,8
            C9.8747,7.655,9.595,7.3753,9.25,7.375z"
            />
            <path
              d="M19.3292,7.83c-0.0081-0.0139-0.0172-0.0273-0.0271-0.04c-0.0253-0.0364-0.057-0.0678-0.0935-0.0929
            c-0.0088-0.006-0.0128-0.0166-0.0221-0.0219l-7-4c-0.1155-0.0664-0.2575-0.0664-0.373,0l-7,4
            C4.8042,7.6805,4.8002,7.6911,4.7914,7.6971C4.7549,7.7222,4.7232,7.7536,4.6979,7.79C4.688,7.8027,4.6789,7.8161,4.6708,7.83
            C4.6419,7.8821,4.6262,7.9404,4.625,8v8c0,0.1344,0.0719,0.2584,0.1885,0.3252l7,4c0.0054,0.003,0.0118,0.0018,0.0172,0.0046
            c0.1048,0.0602,0.2338,0.0602,0.3386,0c0.0054-0.0028,0.0118-0.0016,0.0172-0.0046l7-4C19.3031,16.2584,19.375,16.1344,19.375,16
            V8C19.3738,7.9404,19.3581,7.8821,19.3292,7.83z M12,4.4316L18.2441,8L12,11.5684L5.7559,8L12,4.4316z M5.375,8.6461l6.25,3.5714
            v7.1366l-6.25-3.5719V8.6461z M12.375,19.3541v-7.1366l6.25-3.5714v7.1361L12.375,19.3541z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
