export function NewWindow({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="2 4 20 16" style={{ fill: fillColor }}>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17.25,11.625a.3751.3751,0,0,0-.375.375v5.25A1.377,1.377,0,0,1,15.5,18.625H6.75A1.377,1.377,0,0,1,5.375,17.25V8.5A1.377,1.377,0,0,1,6.75,7.125H12a.375.375,0,0,0,0-.75H6.75A2.1272,2.1272,0,0,0,4.625,8.5v8.75A2.1272,2.1272,0,0,0,6.75,19.375H15.5a2.1272,2.1272,0,0,0,2.125-2.125V12A.3751.3751,0,0,0,17.25,11.625Z" />
          <path d="M19.2669,4.7375l-.0013-.0031-.0031-.0013a.3733.3733,0,0,0-.26-.1076l-.0035,0H13.75a.375.375,0,0,0,0,.75h4.3438L8.2344,15.2344a.3756.3756,0,0,0,.5312.5312L18.625,5.9062V10.25a.375.375,0,0,0,.75,0V5A.3735.3735,0,0,0,19.2669,4.7375Z" />
        </g>
      </g>
    </svg>
  );
}
