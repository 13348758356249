import cx from 'classnames';
import type * as React from 'react';
import { FormLabelHelper } from './form.label-helper';
import type { FormLabelBase } from './form.types';

type FormLegend = FormLabelBase<HTMLLegendElement>;

const FormLegend: React.FC<React.PropsWithChildren<FormLegend>> = ({
  label,
  required,
  optional,
  hasError,
  className,
  ...rest
}) => {
  return (
    <legend
      className={cx('label', className, {
        'text-danger': hasError,
      })}
      {...rest}
    >
      <span>
        {label}
        <FormLabelHelper required={required} optional={optional} />
      </span>
    </legend>
  );
};

export { FormLegend };
export default FormLegend;
