export function ClosedCaptions({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m20.791 6.79c-.23-.689-.935-1.342-1.64-1.518-.126-.031-3.136-.772-7.151-.772s-7.025.741-7.15.772c-.705.176-1.41.828-1.64 1.518-.029.087-.709 2.164-.709 5.21s.68 5.123.709 5.21c.23.689.936 1.342 1.64 1.518.126.031 3.135.772 7.151.772s7.025-.741 7.151-.772c.705-.176 1.409-.828 1.64-1.518.028-.087.709-2.164.709-5.21s-.681-5.123-.709-5.21zm-.949 10.104c-.12.36-.564.771-.933.863-.03.008-3.023.743-6.909.743s-6.879-.735-6.909-.743c-.368-.092-.812-.503-.933-.862-.007-.021-.658-2.021-.658-4.895s.651-4.874.658-4.895c.121-.36.565-.771.933-.862.03-.008 3.023-.743 6.909-.743s6.879.735 6.909.743c.368.092.812.503.933.862.007.021.658 2.021.658 4.895s-.651 4.874-.658 4.894z" />
      <path d="m9 9.5c.586 0 1.122.348 1.365.886.113.251.408.361.661.25.252-.114.364-.41.25-.662-.404-.896-1.297-1.474-2.276-1.474-1.378 0-2.5 1.121-2.5 2.5v2c0 1.379 1.122 2.5 2.5 2.5.979 0 1.872-.578 2.276-1.474.114-.252.002-.548-.25-.662-.251-.115-.547 0-.661.25-.243.538-.779.886-1.365.886-.827 0-1.5-.673-1.5-1.5v-2c0-.827.673-1.5 1.5-1.5z" />
      <path d="m15 9.5c.586 0 1.122.348 1.364.886.114.252.409.365.662.25.251-.114.363-.41.25-.662-.404-.896-1.298-1.474-2.276-1.474-1.379 0-2.5 1.121-2.5 2.5v2c0 1.379 1.121 2.5 2.5 2.5.979 0 1.872-.578 2.276-1.474.113-.252 0-.548-.25-.662-.253-.114-.548-.002-.662.25-.242.538-.778.886-1.364.886-.827 0-1.5-.673-1.5-1.5v-2c0-.827.673-1.5 1.5-1.5z" />
    </svg>
  );
}
