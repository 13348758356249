import { useState } from 'react';
import cx from 'classnames';
import { ChevronRight, ChevronLeft } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import { useLanguage } from '@curated-property/utils';
import dynamic from 'next/dynamic';

const AlertModal: any = dynamic(() => import('./modal'), { ssr: false });

export interface IalertsData {
  headline?: string;
  type?: string;
  description?: any;
  link?: {
    adaDescription?: string;
    isNewWindow?: boolean;
    label?: string;
    url?: string;
  };
}

interface Props {
  hotelOpen: boolean;
  preOpenMsg?: string;
  resEnabled: boolean;
  openDate: string;
  resEnabledDate: string;
  alerts: Array<IalertsData> | undefined;
  alertBarSettings?: {
    backgroundColor?: string;
    linkColor?: string;
    textColor?: string;
  };
  modalSettings?: any;
}

// add logic for presell alerts https://jira.hilton.com/stash/projects/HIW/repos/dx-props-ui/browse/src/components/alerts/PreSellOpenAlert/PreSellOpenAlert.tsx
function generatePresellMessage(
  { hotelOpen, preOpenMsg, resEnabled, openDate, resEnabledDate }: Props,
  t: (key: string, options?: Record<string, any>) => string
) {
  if (hotelOpen) {
    return null;
  }

  if (preOpenMsg) {
    return {
      headline: preOpenMsg,
      type: 'presell',
      description: null,
    };
  }

  if (resEnabled && resEnabledDate) {
    return {
      headline: t('resEnabledPresellDate', { date: resEnabledDate }),
      type: 'presell',
      description: null,
    };
  }

  return {
    headline: openDate ? t('preOpenMessageDate', { date: openDate }) : t('preOpenMessage'),
    type: 'presell',
    description: null,
  };
}

export function PropertyAlert(props: Props) {
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const lang = useLanguage();
  let alerts = props.alerts;

  const presellAlert = generatePresellMessage(props, t);
  if (presellAlert && alerts) {
    alerts = [presellAlert, ...alerts];
  }

  const currentAlert = alerts?.[currentAlertIndex];

  if (!currentAlert) {
    return null;
  }
  const emergency = currentAlert.type === 'emergency';
  const presell = currentAlert.type === 'presell';
  const numAlerts = alerts?.length || 0;
  const multiple = numAlerts > 1;

  function next() {
    setCurrentAlertIndex((s) => (s + 1 > numAlerts - 1 ? 0 : s + 1));
  }

  function prev() {
    setCurrentAlertIndex((s) => (s - 1 < 0 ? numAlerts - 1 : s - 1));
  }

  // If there's an alert, language is set to 'ar', but translations match, then the Arabic translation hasn't been provided
  const languageDirection =
    lang === 'ar' && currentAlert?.headline !== t(currentAlert?.headline || '') ? 'rtl' : 'ltr';

  return (
    <>
      <div
        className={cx(
          'propertyAlert w-full py-2',
          emergency && 'bg-danger-alt text-danger',
          presell && 'bg-primary text-bg',
          !presell && !emergency && 'bg-warn-alt'
        )}
        style={{ background: props?.alertBarSettings?.backgroundColor }}
      >
        <div
          data-testid="propertyAlert"
          className="container flex items-center justify-center text-center leading-tight"
        >
          {multiple && (
            <button onClick={prev} style={{ color: props?.alertBarSettings?.textColor }}>
              <ChevronLeft className="mx-0 size-4 sm:mx-2 rtl:rotate-180" />
              <span className="sr-only">
                {t('previousAlert', {
                  prevCount: currentAlertIndex,
                  total: numAlerts,
                })}
              </span>
            </button>
          )}
          <div style={{ color: props?.alertBarSettings?.textColor }} dir={languageDirection}>
            {currentAlert.headline}{' '}
            {currentAlert?.description && (
              <button
                id="learn-more-alert-button"
                className="text-primary underline hover:no-underline"
                style={{ color: props?.alertBarSettings?.linkColor }}
                onClick={() => setModalOpen(true)}
              >
                {t('learnMore')}
              </button>
            )}
          </div>
          {multiple && (
            <button
              id="next-alert-button"
              onClick={next}
              style={{ color: props?.alertBarSettings?.textColor }}
            >
              <ChevronRight className="mx-0 size-4 sm:mx-2 rtl:rotate-180" />
              <span className="sr-only">
                {t('nextAlert', {
                  nextCount: currentAlertIndex + 2,
                  total: numAlerts,
                })}
              </span>
            </button>
          )}
        </div>
      </div>
      {modalOpen && (
        <AlertModal
          languageDirection={languageDirection}
          alert={currentAlert}
          onClose={() => setModalOpen(false)}
          modalSettings={props.modalSettings}
          modalActive={modalOpen}
        />
      )}
    </>
  );
}
