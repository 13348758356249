import type * as React from 'react';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useFormContext, useWatch, type FieldError } from 'react-hook-form';

import { FormError } from '@dx-ui/osc-form';
import { InfoPopup } from '@dx-ui/osc-info-popup';

const GROUP_LIMIT = 10;

const InputGroup: React.FC<{
  id: string;
  label?: string;
  info?: React.ReactNode;
  isCentered?: boolean;
  error: Partial<FieldError> | undefined;
  children: React.ReactNode;
}> = ({ id, label, info, error, isCentered, children }) => {
  return (
    <div className="flex-1">
      <div
        className={cx('label flex flex-1 items-start md:grow-0', {
          'items-start': !isCentered,
          'sm:items-center': isCentered,
          'text-danger': error,
          'flex-col': !!label,
          'flex-row gap-2': !label,
        })}
      >
        <div className="flex items-center">
          {label ? (
            <label
              data-osc-product="shop-input-label"
              htmlFor={id}
              className="whitespace-nowrap pr-1"
            >
              {label}
            </label>
          ) : null}
          {info ? <InfoPopup>{info}</InfoPopup> : null}
        </div>
        {children}
      </div>
    </div>
  );
};

export type ShopFormGroupProps = {
  showNumAttendees?: boolean;
};
export const ShopFormGroup = (props: ShopFormGroupProps) => {
  const { t } = useTranslation('osc-rooms');
  const {
    register,
    setValue,
    formState: { errors },
    trigger,
    setError,
    clearErrors,
  } = useFormContext();
  const numRooms = useWatch({ name: 'numRooms' });
  const numAttendees = useWatch({ name: 'numAttendees' });
  const shouldFocusRoomsInput = useWatch({ name: 'shouldFocusRoomsInput' });
  const roomsInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (shouldFocusRoomsInput && roomsInputRef.current) roomsInputRef.current.focus();
  }, [shouldFocusRoomsInput]);

  const getNumRoomsAttendeesValidation = (numAttendees: string, numRooms: string) => {
    const isNumRoomsAndNumAttendeesFalsey =
      ((parseInt(numAttendees) === 0 || isNaN(parseInt(numAttendees))) &&
        parseInt(numRooms) === 0) ||
      isNaN(parseInt(numRooms));
    if (isNumRoomsAndNumAttendeesFalsey) {
      setError('shop-form-numRooms', {
        type: 'manual',
        message: t('roomsAttendees.numRoomsAttendeeValueError'),
      });
      setError('shop-form-numAttendees', {
        type: 'manual',
        message: t('roomsAttendees.numRoomsAttendeeValueError'),
      });
      return t('roomsAttendees.numRoomsAttendeeValueError');
    }
    const isFormInErrorStateAndNumAttendeesOrNumRoomsGreaterThanZero =
      (errors?.numAttendees || errors?.numRooms) &&
      (!!parseInt(numAttendees) || !!parseInt(numRooms));

    if (isFormInErrorStateAndNumAttendeesOrNumRoomsGreaterThanZero) {
      clearErrors(['numRooms', 'numAttendees', 'shop-form-numRooms', 'shop-form-numAttendees']);
    }
    return true;
  };

  return (
    <div>
      <div className="flex flex-auto flex-col gap-2 sm:flex-row lg:gap-3">
        <InputGroup
          id="shop-form-numRooms"
          label={t('roomsAttendees.numRooms')}
          error={errors?.numRooms || errors?.numAttendees}
        >
          <input
            id="shop-form-numRooms"
            type="number"
            min={0}
            className={cx('form-input w-full flex-1', {
              'form-error': errors?.numRooms || errors?.numAttendees,
            })}
            {...register('numRooms', {
              value: GROUP_LIMIT,
              onChange: () => trigger('numRooms'),
              validate: {
                numRoomsValidation: (numRooms) =>
                  getNumRoomsAttendeesValidation(numAttendees, numRooms),
              },
              valueAsNumber: true,
            })}
            ref={(e) => {
              register('numRooms').ref(e);
              roomsInputRef.current = e;
            }}
            onFocus={() => {
              if (shouldFocusRoomsInput) setValue('shouldFocusRoomsInput', false);
            }}
          />
        </InputGroup>
        {props.showNumAttendees ? (
          <InputGroup
            id="shop-form-numAttendees"
            error={errors?.numAttendees || errors?.numRooms}
            label={t('roomsAttendees.numAttendees')}
          >
            <input
              id="shop-form-numAttendees"
              type="number"
              className={cx('form-input w-full flex-1', {
                'form-error': errors?.numAttendees || errors?.numRooms,
              })}
              min={0}
              {...register('numAttendees', {
                onChange: () => trigger('numAttendees'),
                validate: {
                  numAttendeesValidation: (numAttendees) =>
                    getNumRoomsAttendeesValidation(numAttendees, numRooms),
                },
              })}
            />
          </InputGroup>
        ) : null}
      </div>
      <FormError error={errors?.numRooms || errors?.numAttendees} className=" lg:absolute" />
    </div>
  );
};
