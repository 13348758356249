import { useState, useEffect } from 'react';
import { AnchorLink } from './global/anchor-link';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge } from './functions/global-instance-styles';
import cx from 'classnames';
import styles from './css/retail-flyout.module.css';
import { CloseIcon } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import { GetHeaderHeight, HandleWYSIWYGContent } from './functions/helper';
import { appliedCloudinaryParams, useHeadingLevel, WrappedHeading } from '@curated-property/utils';
import type { FlexComponents } from 'apps/dx-curated-ui';

export interface RetailFlyoutProps {
  componentIndex?: number;
  allComponents?: FlexComponents;
  openByDefault?: boolean;
  pagePosition?: string;
  buttonStyle?: string;
  brandCode?: string | 'HI';
  enableHHR?: boolean;
  copy?: string;
  title?: string;
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function RetailFlyout({
  componentIndex,
  allComponents,
  openByDefault,
  pagePosition,
  buttonStyle,
  copy,
  title,
  link,
  brandCode,
  enableHHR,
  instanceStyles,
  globalStyles,
}: RetailFlyoutProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const [isToggled, setIsToggled] = useState(false);
  const [delayedToggle, setDelayedToggle] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [transitionDir, setTransitionDir] = useState('opacity-0 -translate-x-96 block hidden');
  const { t } = useTranslation();
  const headingLevel = useHeadingLevel(componentIndex, allComponents);
  const headerHeight = GetHeaderHeight();
  const verticalPlacement = pagePosition?.substring(pagePosition?.length - 1);
  const horizontalPlacement = pagePosition?.substring(0, 5);
  const ariaTitle = title?.replace(':', ',');
  let anchorBorder = inlineStyles?.textColor ? `border-[${inlineStyles?.textColor}]` : ``;
  if (brandCode === 'CH' && !inlineStyles?.textColor) anchorBorder = `border-black`;

  useEffect(() => {
    if (horizontalPlacement === 'right') {
      setTransitionDir('opacity-0 translate-x-96 block hidden');
    }
  }, [horizontalPlacement]);

  useEffect(() => {
    if (openByDefault) {
      setIsToggled(true);
      setDelayedToggle(true);
    }
  }, [openByDefault]);

  const handleClick = (trigger?: string) => {
    if (trigger === 'close') {
      const button = document.getElementById('flyout-toggle-button');
      button?.focus();
    }
    if (!disabled) {
      if (isToggled) {
        setDisabled(true);
        setTimeout(() => {
          setDelayedToggle(false);
          setDisabled(false);
          setTransitionDir(transitionDir + ' hidden');
        }, 200);
      } else {
        setDelayedToggle(!delayedToggle);
      }
      if (horizontalPlacement === 'right') {
        setTransitionDir('opacity-0 translate-x-96 block');
      } else {
        setTransitionDir('opacity-0 -translate-x-96 block');
      }

      setTimeout(() => {
        setIsToggled(!isToggled);
      }, 100);
    } else return null;
  };

  return (
    <section
      data-element-id="retail-flyout"
      data-testid="retail-flyout"
      className={cx(
        inlineStyles?.showHide ? 'hidden' : 'max-sm:hidden',
        `cp-retailFlyout fixed z-40 flex items-stretch ${
          horizontalPlacement === 'right' ? 'right-0 justify-end rtl:left-auto' : 'left-0'
        } text-bg ${
          verticalPlacement === 'p'
            ? ''
            : verticalPlacement === 'r'
            ? 'bottom-1/3'
            : verticalPlacement === 'm'
            ? 'bottom-5'
            : 'bottom-5'
        }`
      )}
      style={{ top: verticalPlacement === 'p' && headerHeight }}
    >
      <button
        data-testid={isToggled === true ? 'flyoutIsOpen' : 'flyoutIsClosed'}
        id="flyout-toggle-button"
        aria-label={ariaTitle}
        aria-expanded={isToggled ? true : false}
        className={cx(
          `${
            horizontalPlacement === 'right' ? 'order-last -mr-1 rtl:order-first' : 'rtl:order-last'
          } z-30 flex w-7 items-center justify-center p-4 shadow-lg hover:cursor-pointer md:w-12`,
          brandCode !== 'CH' ? `bg-primary` : ''
        )}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage?.sourceUrl ?? '',
                inlineStyles?.componentBackgroundRepeat
              )}')`
            : '',
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        }}
        onClick={() => handleClick()}
      >
        <WrappedHeading className="flex" header={headingLevel}>
          <span
            className={cx(
              styles.verticalText,
              'text-bg font-headline isolate whitespace-nowrap p-2 uppercase',
              (title?.length ?? 0) > 30 ? 'md:text-lg' : 'md:text-xl'
            )}
            style={{
              color: inlineStyles?.titleColor,
            }}
          >
            {title}
          </span>
        </WrappedHeading>
      </button>
      <div
        data-testid="flyout-body"
        aria-hidden={isToggled ? false : true}
        className={cx(
          `${
            horizontalPlacement === 'right'
              ? 'border-current/20 border-r'
              : 'border-current/20 border-l'
          } ${
            isToggled ? 'isolate opacity-100 transition duration-300 ease-in-out' : transitionDir
          } text-bg border-current/20 flex  w-10/12 flex-col items-center justify-between overflow-hidden pb-5 shadow-lg transition duration-300 ease-in-out sm:w-96`,
          brandCode !== 'CH' ? `bg-primary` : ''
        )}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl ?? '',
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundColor:
            inlineStyles?.contentBackgroundColor ||
            inlineStyles?.componentBackgroundColor ||
            undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
        }}
      >
        <div
          className={cx(
            `${
              horizontalPlacement === 'right'
                ? 'ml-2 justify-start rtl:justify-end'
                : 'mr-4 justify-end rtl:justify-start'
            } mt-3 flex w-full`
          )}
          style={{
            color: inlineStyles?.textColor,
          }}
        >
          <button
            data-element-id="retail-flyout-close"
            aria-hidden={isToggled ? false : true}
            className={cx(
              `z-30 flex w-16 items-center justify-around text-xs font-extrabold hover:cursor-pointer sm:w-20 sm:text-sm ${
                delayedToggle ? '' : 'hidden'
              }`
            )}
            onClick={() => handleClick('close')}
          >
            {t('close')}
            <CloseIcon
              fillColor={
                (inlineStyles?.textColor || brandCode === 'CH') && !enableHHR
                  ? '#000000'
                  : '#FFFFFF'
              }
              className={cx(
                `retail-close-icon`,
                horizontalPlacement === 'right' ? 'order-first rtl:order-last' : 'rtl:order-first'
              )}
            />
          </button>
        </div>
        <div
          className={cx(
            styles.flyoutContent,
            `${
              inlineStyles?.textAlignment ? `text-${inlineStyles?.textAlignment}` : 'text-center'
            } z-30 mb-2 mr-0.5 mt-5 max-h-72 overflow-auto px-5 text-sm leading-5 sm:max-h-80 sm:px-12 sm:text-base md:leading-normal`
          )}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
          }}
          style={{
            color: inlineStyles?.textColor,
          }}
        />
        <div
          className={cx(`z-30 mt-0 ${delayedToggle ? '' : 'hidden'}`)}
          style={{
            color: inlineStyles?.textColor,
          }}
        >
          <AnchorLink
            url={link?.url}
            title={link?.title}
            target={link?.target}
            className={cx(`btn btn-bg-outline px-10 py-0 md:px-6 md:py-[.5rem]`, anchorBorder)}
            buttonStyle={buttonStyle || ''}
          />
        </div>
      </div>
    </section>
  );
}
