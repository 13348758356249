export function CheckSquare({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V7A2.3777,2.3777,0,0,0,17,4.625ZM18.625,17A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7Z" />
          <path d="M15.7256,8.7441,10.4,14.45l-2.126-2.2773a.3752.3752,0,1,0-.5488.5117l2.4,2.5713a.3757.3757,0,0,0,.5488,0l5.6-6a.3752.3752,0,1,0-.5488-.5118Z" />
        </g>
      </g>
    </svg>
  );
}
