import { isValid, lastDayOfMonth } from 'date-fns';
import type { RefObject } from 'react';
import { createContext, useEffect, useRef, useState } from 'react';
import { GIS_merge, GIS_TextAlignment } from '../..';
import { WACBreadcrumbs } from './breadcrumbs';
import { defaultStyles } from './default-styling';
import type { FullAvailability, WACEmailData, WACProps, WACStyles } from './interfaces';
import { WACSelectDatesSection } from './sections/select-dates';
import { WAVenueDateSection } from './sections/view-venues-for-date';
import { generateEmailLink } from './utils';
import { sanitize } from '@curated-property/utils';

interface WACContextProps {
  stage?: WACStages;
  setStage?: React.Dispatch<React.SetStateAction<WACStages>>;
  selectedDate: Date | null; //selected date for the wedding
  setSelectedDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedYearMonth: Date; //the selected Month to view in the calendar
  setSelectedYearMonth?: React.Dispatch<React.SetStateAction<Date>>;
  minDate: Date; //min date that can be selected / viewed
  maxDate: Date; //max date that can be selected /viewed
  fullAvailability?: FullAvailability;
  venues?: string[];
  timeSlots?: string[];
  selectedTimeSlot: string;
  setSelectedTimeSlot?: React.Dispatch<React.SetStateAction<string>>;
  selectedVenue: string;
  setSelectedVenue?: React.Dispatch<React.SetStateAction<string>>;
  emailData: WACEmailData;
}

export { defaultStyles as WeddingAvailabilityCalendarDefaultStyles };
const initDate = new Date();
export const WACContext = createContext<WACContextProps>({
  minDate: initDate,
  maxDate: initDate,
  selectedDate: null,
  selectedYearMonth: initDate,
  selectedTimeSlot: '',
  selectedVenue: '',
  emailData: { address: '', title: '', copy: '' },
});

export enum WACStages {
  DATE,
  VENUE,
  SEND_EMAIL,
}

export function WeddingAvailableCalendar({
  title,
  copy,
  styles,
  bookings,
  venues,
  timeSlots,
  emailData,
}: WACProps) {
  const [elementToRefocus, setElementToRefocus] = useState<RefObject<HTMLSelectElement> | null>(
    null
  );
  const [stage, setStage] = useState(WACStages.DATE);
  const minDate = new Date();
  let maxDate = new Date(minDate.getFullYear() + 5, minDate.getMonth(), 1);
  maxDate = lastDayOfMonth(maxDate);
  //selectedYearMonth is the selected Year & Month currently in view for the calendar.
  const [selectedYearMonth, setSelectedYearMonth] = useState(minDate);
  /*
  selectedDate is the selected date (day) which the customer wants to see the
  availably for possibly wants to book.
  */
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>('');
  const [selectedVenue, setSelectedVenue] = useState<string>('');

  styles = GIS_merge(defaultStyles, styles) as WACStyles;

  /* merge all bookings into
    Array is Year[Month[Day[bookings]]]
  */
  const fullAvailability: FullAvailability = [];

  bookings?.forEach((item) => {
    const UTCDate = new Date(parseInt(item?.weddingCalendar?.timestamp || '') * 1000);

    const {
      ceremonyTime = '',
      receptionVenue = '',
      timestamp = '',
      status = '',
    } = item?.weddingCalendar || {};

    const year = UTCDate.getUTCFullYear();
    const month = UTCDate.getUTCMonth();
    const day = UTCDate.getUTCDate();

    fullAvailability[year] ??= [];
    fullAvailability[year][month] ??= [];
    fullAvailability[year][month][day] ??= {
      ceremonyTimes: timeSlots
        ? timeSlots?.reduce((a, x) => ({ ...a, [String(x)]: null }), {})
        : {},
      receptionVenues: venues ? venues?.reduce((a, x) => ({ ...a, [String(x)]: null }), {}) : {},
      timestamps: [],
    };
    fullAvailability[year][month][day].ceremonyTimes[ceremonyTime] = status || true;
    fullAvailability[year][month][day].receptionVenues[receptionVenue] = status || true;
    fullAvailability[year][month][day].timestamps.push(parseInt(timestamp));
  });
  const selectDateStartFocusElement = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    elementToRefocus?.current?.focus();
  }, [elementToRefocus]);

  return (
    <WACContext.Provider
      value={{
        stage,
        setStage,
        minDate,
        maxDate,
        selectedDate,
        selectedYearMonth, //selectedYearMonth is the current year/month in view.
        setSelectedDate,
        setSelectedYearMonth,
        fullAvailability,
        timeSlots,
        venues,
        selectedTimeSlot,
        setSelectedTimeSlot,
        selectedVenue,
        setSelectedVenue,
        emailData,
      }}
    >
      <section
        className="container mb-8 w-full lg:justify-between"
        data-element-id="wedding-availability-calendar-wrapper"
      >
        {title?.trim() ? (
          <h2
            className="mb-16 text-center text-5xl"
            style={{
              textAlign: GIS_TextAlignment(styles?.headerTitleAlign),
              color: styles?.headerTitleColor,
            }}
          >
            {title}
          </h2>
        ) : null}
        {copy && (
          <div
            className="mb-16"
            dangerouslySetInnerHTML={{ __html: sanitize(copy) }}
            style={{
              textAlign: GIS_TextAlignment(styles?.headerCopyAlign),
              color: styles?.headerCopyColor,
            }}
          />
        )}
        <div
          style={{
            color: styles?.mainTextColor,
          }}
        >
          <WACBreadcrumbs
            activeColor={styles?.progress?.activeColor}
            inactiveColor={styles?.progress?.inactiveColor}
          />
          <WACSelectDatesSection
            startFocusElementRef={selectDateStartFocusElement}
            styles={styles}
          />
          <WAVenueDateSection styles={styles} />
          <div className="mt-12 flex w-full">
            <div className="w-1/3">
              {stage !== WACStages.DATE && (
                <button
                  data-testid="wac-select-date-btn"
                  className="btn btn-primary text hover:text-hover mt-2 px-6 py-2 text-center text-lg sm:w-auto sm:text-left sm:text-xl"
                  onClick={() => {
                    setElementToRefocus(selectDateStartFocusElement);
                    setStage(WACStages.DATE);
                  }}
                >
                  Start Over
                </button>
              )}
            </div>
            <div className="w-1/3 text-center text-3xl">
              {/*
            Step <strong>{stage + 1}</strong> of{' '}
            {Object.keys(WACStages).length / 2}*/}
            </div>
            <div className="w-1/3 text-right">
              {stage === WACStages.DATE && (
                <button
                  data-testid="wac-select-date-btn"
                  className="btn btn-primary text hover:text-hover mt-2 px-6 py-2 text-center text-lg sm:w-auto sm:text-left sm:text-xl"
                  disabled={!isValid(selectedDate)}
                  onClick={() => {
                    setStage(WACStages.VENUE);
                    setElementToRefocus(null);
                  }}
                >
                  Choose Venue
                </button>
              )}
              {(stage === WACStages.VENUE || stage === WACStages.SEND_EMAIL) && (
                <button
                  data-testid="wac-select-date-btn"
                  className="btn btn-primary text hover:text-hover mt-2 px-6 py-2 text-center text-lg sm:w-auto sm:text-left sm:text-xl"
                  disabled={!(selectedTimeSlot && selectedVenue)}
                  onClick={() => {
                    window.location.href = generateEmailLink(
                      emailData,
                      selectedDate,
                      selectedVenue,
                      selectedTimeSlot
                    );
                    setStage(WACStages.SEND_EMAIL);
                  }}
                >
                  Send Email
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    </WACContext.Provider>
  );
}
