import NextLink from 'next/link';
import { type LinkProps } from 'next/link';
import { type ReactNode, type RefAttributes, type AnchorHTMLAttributes } from 'react';
import { env } from '@dx-ui/framework-env';

/**
 * @description conditionally do not use prefetch based on env var
 */
export default function Link(
  props: Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: ReactNode;
    } & RefAttributes<HTMLAnchorElement>
) {
  const disablePrefetch = env('DISABLE_PREFETCH') === 'true';
  return <NextLink {...props} prefetch={disablePrefetch ? false : true} />;
}
