import * as React from 'react';
import type { FormDataValues, ShopForm } from '../shop-form';
import { useTranslationsConfig } from '@dx-ui/osc-speedbump';
import type { Rule, Facts } from '@dx-shared/rule-engine';
import { RuleEngine } from '@dx-shared/rule-engine';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const DEFAULT_LANGUAGE = 'en';

export function useShopFormSpeedBump({
  locale,
  onSubmit,
  targetOHWPage,
}: Pick<React.ComponentProps<typeof ShopForm>, 'onSubmit' | 'targetOHWPage'> & {
  locale: string;
}) {
  const { asPath } = useRouter();
  const { t } = useTranslation(['osc-speedbump', 'osc-rooms']);

  const [speedBumpData, setSpeedBumpData] = React.useState<{
    formData: FormDataValues;
    url: string | null;
  } | null>(null);
  const [speedBumpType, setSpeedBumpType] = React.useState<'language' | 'ten-plus' | null>(null);
  const { translateRules } = useTranslationsConfig({ isEnabled: !!targetOHWPage });

  const isSpeedBumpVisible = speedBumpData !== null;
  const closeSpeedBump = () => {
    setSpeedBumpData(null);
  };

  const handleSpeedBumpClose = () => {
    closeSpeedBump();
  };
  const handleSpeedBumpContinue = () => {
    let url = speedBumpData?.url ?? null;
    if (speedBumpType === 'language') {
      url = speedBumpData?.url?.replace(`/${locale}/`, `/${DEFAULT_LANGUAGE}/`) || null;
    }
    if (
      speedBumpData?.formData &&
      speedBumpType !== 'language' &&
      shouldShowLanguageSpeedBump(url)
    ) {
      setSpeedBumpData({ formData: speedBumpData?.formData, url });
      setSpeedBumpType('language');
      return;
    }
    speedBumpData &&
      onSubmit?.({
        formData: speedBumpData.formData,
        url,
        locale: speedBumpType === 'language' ? DEFAULT_LANGUAGE : locale ?? DEFAULT_LANGUAGE,
      });
    closeSpeedBump();
  };

  const shouldShowLanguageSpeedBump = (url?: string | null) => {
    if (url && locale && locale !== DEFAULT_LANGUAGE) {
      try {
        const ruleEngine = new RuleEngine(translateRules as Rule);

        return ruleEngine?.run({ language: locale, url } as Facts) === false;
      } catch (e) {
        console.warn('Error while running rule engine in shouldShowSpeedBump', e); // eslint-disable-line no-console
        return true;
      }
    }
  };
  const shouldShowSpeedBump = (url?: string | null, formData?: FormDataValues) => {
    const isGMELessThanTenRoomsAndNoAttendees =
      asPath.includes('/events/') &&
      formData?.numRooms &&
      Number(formData.numRooms) < 10 &&
      (!formData?.numAttendees || Number(formData?.numAttendees) === 0);

    if (isGMELessThanTenRoomsAndNoAttendees) {
      setSpeedBumpType('ten-plus');
      return true;
    }
    if (shouldShowLanguageSpeedBump(url)) {
      setSpeedBumpType('language');
      return true;
    }
    return false;
  };

  const getSpeedBumpOptions = () => {
    switch (speedBumpType) {
      case 'language':
        return {
          title: t('osc-speedbump:title'),
        };
      case 'ten-plus':
        return {
          title: t('osc-rooms:roomsAttendees.groupSpeedbump.speedbumpTitle'),
          message: t('osc-rooms:roomsAttendees.groupSpeedbump.speedbumpMessage'),
          confirmButtonLabel: t('osc-rooms:roomsAttendees.groupSpeedbump.continueSelections'),
          cancelButtonLabel: t('osc-rooms:roomsAttendees.groupSpeedbump.changeSelections'),
        };

      default:
        break;
    }
  };
  return {
    isSpeedBumpVisible,
    handleSpeedBumpClose,
    handleSpeedBumpContinue,
    shouldShowSpeedBump,
    setSpeedBumpData,
    speedBumpType,
    getSpeedBumpOptions,
  };
}
