import { useLocale } from '@dx-ui/utilities-dates';
import { useTranslation } from 'next-i18next';
import { differenceInDays } from 'date-fns';

export function DateRangeScreenReaderText({
  fromDate,
  language,
  toDate,
}: {
  fromDate: Date | null | undefined;
  language: string;
  toDate: Date | null | undefined;
}) {
  const { t } = useTranslation('osc-date-picker');
  const locale = useLocale({ language });
  const fromDateString = fromDate ? fromDate.toLocaleDateString(locale, { dateStyle: 'long' }) : '';
  const toDateString = toDate ? toDate.toLocaleDateString(locale, { dateStyle: 'long' }) : '';
  const nights = fromDate && toDate ? differenceInDays(toDate, fromDate) : 0;
  return (
    <span className="sr-only">
      {t('dateRange', { fromDate: fromDateString, toDate: toDateString, count: nights })}
    </span>
  );
}
