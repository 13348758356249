export function BoutiqueHotels({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M20,19.625h-1.625V14c0-0.2071-0.1679-0.375-0.375-0.375S17.625,13.7929,17.625,14v5.625h-3.541v-3.4443
            c0.0003-1.151-0.9326-2.0842-2.0835-2.0845c-1.151-0.0003-2.0842,0.9326-2.0845,2.0835c0,0.0003,0,0.0006,0,0.0009v3.4443H6.375
            V14c0-0.2071-0.1679-0.375-0.375-0.375S5.625,13.7929,5.625,14v5.625H4c-0.2071,0-0.375,0.1679-0.375,0.375
            S3.7929,20.375,4,20.375h16c0.2071,0,0.375-0.1679,0.375-0.375S20.2071,19.625,20,19.625z M10.666,16.1807
            c0-0.7367,0.5973-1.334,1.334-1.334c0.7367,0,1.334,0.5973,1.334,1.334v3.4443h-2.668V16.1807z"
            />
            <path
              d="M5,12.375h14c0.2071,0,0.375-0.1679,0.375-0.375S19.2071,11.625,19,11.625h-0.644
            c-0.1925-3.2201-2.7609-5.7885-5.981-5.981V4c0-0.2071-0.1679-0.375-0.375-0.375S11.625,3.7929,11.625,4v1.644
            c-3.2201,0.1925-5.7885,2.7609-5.981,5.981H5c-0.2071,0-0.375,0.1679-0.375,0.375S4.7929,12.375,5,12.375z M14.6158,11.625
            H12.375V6.4422C13.57,6.8282,14.5307,9.0125,14.6158,11.625z M11.625,6.4422v5.1828H9.3842
            C9.4693,9.0125,10.43,6.8282,11.625,6.4422z M17.6123,11.625h-2.247c0.0237-1.7406-0.4802-3.4476-1.4453-4.8964
            C16.0106,7.4871,17.4579,9.4064,17.6123,11.625z M10.08,6.7286C9.115,8.1774,8.6112,9.8844,8.635,11.625H6.3877
            C6.5421,9.4064,7.9894,7.4871,10.08,6.7286z"
            />
            <path
              d="M7.625,14v2c0,0.2071,0.1679,0.375,0.375,0.375S8.375,16.2071,8.375,16v-2c0-0.2071-0.1679-0.375-0.375-0.375
            S7.625,13.7929,7.625,14z"
            />
            <path
              d="M15.625,14v2c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-2
            c0-0.2071-0.1679-0.375-0.375-0.375C15.7929,13.625,15.625,13.7929,15.625,14z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
