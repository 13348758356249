export function Refrigerator({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15,2.625H9A2.3777,2.3777,0,0,0,6.625,5V17A2.3777,2.3777,0,0,0,9,19.375h6A2.3777,2.3777,0,0,0,17.375,17V5A2.3777,2.3777,0,0,0,15,2.625ZM16.625,17A1.6262,1.6262,0,0,1,15,18.625H9A1.6262,1.6262,0,0,1,7.375,17V9.375h9.25Zm0-8.375H7.375V5A1.6262,1.6262,0,0,1,9,3.375h6A1.6262,1.6262,0,0,1,16.625,5Z" />
          <path d="M16,20.625H8a.375.375,0,0,0,0,.75h8a.375.375,0,0,0,0-.75Z" />
          <path d="M9,4.625A.3751.3751,0,0,0,8.625,5V7a.375.375,0,0,0,.75,0V5A.3751.3751,0,0,0,9,4.625Z" />
          <path d="M9,14.375A.3751.3751,0,0,0,9.375,14V11a.375.375,0,0,0-.75,0v3A.3751.3751,0,0,0,9,14.375Z" />
        </g>
      </g>
    </svg>
  );
}
