import { useEffect, useState } from 'react';
/**
 * Helps to prevent Hydration issues by only running bits code on the client-side
 * ONLY TO BE USED SPARINGLY, IN SPECIFIC SITUATIONS
 * DO NOT WRAP AN ENTIRE COMPONENT WITH THIS CHECK
 * @returns clientReady as a boolean value
 */
export const useIsClient = () => {
  const [clientReady, setClientReady] = useState(false);

  useEffect(() => {
    setClientReady(true);
  }, []);

  return clientReady;
};
