import gql from 'graphql-tag-transform.macro';
import { keyInfoGridSettings } from './common-vars';

export const KeyInfoGrid = gql`
fragment KeyInfoGrid on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_KeyInfoGrid {
        columnTitles {
          title1
          title2
          title3
        }
        gridContent
        gridSubtitle
        gridTitle
        repeater {
          title
          contentBackground
          content
          backgroundOpacity
          columnSelect
          iconList
        }
        keyInfoGridSettings {
          fieldGroupName
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          ${keyInfoGridSettings}
        }
      }
}
`;
