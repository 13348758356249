import cx from 'classnames';
import Img from 'next/legacy/image';
import { imageUrl } from '@curated-property/utils';
import type { HeroImageOverlayProps } from './hero-image.types';
import { customLoader } from '../cropped-image/cropped-image';

export function HeroImageLogo({
  logoSrc,
  logoSize,
  logoHeight,
  logoWidth,
  logoAlt,
  logoOverlayColour,
  logoOverlayOpacity,
  hasSpacer,
  isShopFormInHeader,
  inlineStyles,
  hideAnimations,
}: Pick<
  HeroImageOverlayProps,
  | 'logoSrc'
  | 'logoSize'
  | 'logoAlt'
  | 'logoWidth'
  | 'logoHeight'
  | 'logoOverlayColour'
  | 'logoOverlayOpacity'
  | 'inlineStyles'
  | 'hideAnimations'
  | 'hasSpacer'
  | 'isShopFormInHeader'
>) {
  let logoYPositionStyles = '';
  let logoXPositionStyles = '';

  switch (inlineStyles.logoXPosition) {
    case 'left':
      logoXPositionStyles = 'md:justify-start md:text-left';
      break;
    case 'right':
      logoXPositionStyles = 'md:justify-end md:text-right';
      break;
    case 'middle':
      logoXPositionStyles = 'md:justify-center md:text-center';
      break;
    default:
      logoXPositionStyles = 'md:justify-center md:text-center';
  }

  const paddingTop = hasSpacer
    ? 'pt-3'
    : isShopFormInHeader
    ? 'sm:pt-3 xl:pt-60'
    : 'sm:pt-3 xl:pt-44';

  switch (inlineStyles.logoYPosition) {
    case 'top':
      logoYPositionStyles = `md:items-start md:content-start ${paddingTop}`;
      break;
    case 'middle':
      logoYPositionStyles = 'md:items-center md:content-center pt-40 md:pt-0';
      break;
    case 'bottom':
      logoYPositionStyles = 'md:items-end md:content-end';
      break;
    default:
      logoYPositionStyles = 'md:items-center md:content-center pt-16 md:pt-0';
  }

  const logoUrl = imageUrl(logoSrc);
  // If width set in CMS, set height to auto && vice-versa
  const wpLogoWidth = logoSize?.width;
  const wpLogoHeight = logoSize?.height;
  const logoWidthRatio = wpLogoWidth / wpLogoHeight;
  const logoHeightRatio = wpLogoHeight / wpLogoWidth;
  const logoWidthLogic =
    logoHeight !== undefined && logoWidth == null ? logoHeight * logoWidthRatio : logoWidth;
  const logoHeightLogic =
    logoWidth !== undefined && logoHeight == null ? logoWidth * logoHeightRatio : logoHeight;
  const LogoTag =
    logoUrl && logoUrl !== '' ? (
      <Img
        loader={customLoader}
        src={logoUrl}
        className={cx('z-10', {
          'animate-fade-in-slow': !hideAnimations,
        })}
        alt={logoAlt}
        height={logoHeightLogic || wpLogoHeight}
        width={logoWidthLogic || wpLogoWidth}
        layout="intrinsic"
        priority
      />
    ) : null;

  return (
    <div className={cx('pointer-events-none absolute inset-0 z-20 h-full')}>
      <div
        className="pointer-events-none absolute inset-0 h-full"
        style={{
          backgroundColor: logoOverlayColour,
          opacity: logoOverlayOpacity / 10,
        }}
      />
      <div
        data-testid="logoContainer"
        className={cx(
          'container flex h-full content-center items-center justify-center px-4 pb-5 text-center',
          logoXPositionStyles,
          logoYPositionStyles
        )}
      >
        {LogoTag}
      </div>
    </div>
  );
}
