import cx from 'classnames';
import { hexToRgb } from '../functions/helper';
import type { OverlayCopyProps } from './hero-image.types';
import { WrappedSubtitle } from '@curated-property/utils';
import type { ACFAnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';

export default function OverlayCopy(props: OverlayCopyProps) {
  const headerDisplacement: number =
    !props?.isFullWidthOverlay &&
    props?.isLargeDesktopSize &&
    !props?.inlineStyles?.mastheadSpacer &&
    !props?.componentInstance
      ? 145
      : 0;

  const opacity =
    ((props?.isTabletSize && props?.inlineStyles?.overlayBackgroundOpacity) || 100) / 100;
  const bgRGB = hexToRgb(props?.inlineStyles?.overlayBackgroundColour);
  const overlayBg = bgRGB ? `rgba(${bgRGB?.r},${bgRGB?.g},${bgRGB?.b},${opacity})` : '';
  const defaultBgRGB = props?.enableHHR ? '' : `rgba(38,38,38,${opacity})`;
  const bgRGBStyles =
    (!props?.isTabletSize && props?.inlineStyles?.overlayMobileBackgroundColour) ||
    overlayBg ||
    defaultBgRGB;

  let titleSizeStyles = '',
    subtitleSizeStyles = '';
  switch (props?.titleSize) {
    case 'Large':
      titleSizeStyles = 'text-4xl';
      break;
    case 'Normal':
      titleSizeStyles = 'text-2xl';
      break;
    default:
      titleSizeStyles = 'text-xl';
  }
  switch (props?.subtitleSize) {
    case 'Large':
      subtitleSizeStyles = 'text-2xl';
      break;
    case 'Normal':
      subtitleSizeStyles = 'text-xl';
      break;
    default:
      subtitleSizeStyles = 'text-lg';
  }

  let textAlignment = '',
    buttonAlignment = '';
  switch (props?.inlineStyles?.textAlignment) {
    case 'flex-start':
      textAlignment = 'items-start text-start';
      buttonAlignment = 'justify-start';
      break;
    case 'flex-end':
      textAlignment = 'items-end text-end';
      buttonAlignment = 'justify-end';
      break;
    default:
      textAlignment = 'items-center text-center';
      buttonAlignment = 'justify-center';
  }

  const titleStyle = props?.title && (
    <p
      className={cx('text-bg heroImage-mainTitle inline-block font-bold', titleSizeStyles)}
      style={{
        color:
          (!props?.isTabletSize && props?.inlineStyles?.mobileTitleColour) ||
          props?.inlineStyles?.titleColor,
      }}
    >
      {props?.title}
    </p>
  );

  const subtitleStyle = props?.subtitle && (
    <WrappedSubtitle>
      <p
        className={cx('text-bg heroImage-mainSubtitle inline-block', subtitleSizeStyles)}
        style={{
          color:
            (!props?.isTabletSize && props?.inlineStyles?.mobileSubtitleColour) ||
            props?.inlineStyles?.subtitleColor,
        }}
      >
        {props?.subtitle}
      </p>
    </WrappedSubtitle>
  );

  return (
    (props?.title || props?.subtitle || props?.buttons?.some((b: ACFAnchorProps) => b?.link)) && (
      <div
        id={`overlay-content-${props?.componentInstance}`}
        className={cx('heroImage-Overlay z-20 order-2 w-full p-4 max-[320px]:hidden lg:p-8', {
          'lg:w-auto': !props?.isFullWidthOverlay,
        })}
        style={{
          backgroundColor: bgRGBStyles,
          marginTop: headerDisplacement,
          backdropFilter: `blur(${
            (props?.isTabletSize && props?.inlineStyles?.overlayBlur) || 0
          }px)`,
        }}
      >
        <div
          className={cx('flex flex-col', !props?.isFullWidthOverlay ? textAlignment : '', {
            'lg:w-auto': !props?.isFullWidthOverlay,
            'max-w-screen-lg mx-auto': props?.isFullWidthOverlay,
          })}
        >
          {props?.title && (
            <div
              className={cx('heroImage-title relative mb-2 flex flex-col', props?.animations?.one)}
            >
              {props?.title && <div>{titleStyle}</div>}
            </div>
          )}
          <div
            className={cx(props?.animations?.three, {
              'flex flex-wrap xl:flex-nowrap items-center': props?.isFullWidthOverlay,
            })}
          >
            {props?.subtitle && (
              <div
                className={cx('heroImage-title', {
                  'mr-3': props?.isFullWidthOverlay && props?.buttons,
                })}
              >
                {subtitleStyle}
              </div>
            )}
            {props?.buttons ? (
              <div className={cx('pointer-events-auto relative z-20 flex', buttonAlignment)}>
                {props?.buttons?.map((link: ACFAnchorProps, key: number) => {
                  if (!link || !link.link) {
                    return null;
                  }
                  return (
                    <AnchorLink
                      key={key}
                      url={link.link?.url || ''}
                      title={link.link?.title || ''}
                      target={link.link?.target || ''}
                      buttonStyle={link?.buttonStyle ?? 'primary'}
                      sectionHeading={props?.title}
                      className={`text-nowrap ${key === 0 ? 'ml-0' : ''}`}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  );
}
