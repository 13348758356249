import { type SVGAttributes } from 'react';

const IndoorPoolIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="currentColor"
      >
        <path d="M4.714 17.85l17.1-10.024 17.143 9.95.044 19.976-34.244.074z" />
        <path
          d="M31.8 23.823a2.355 2.355 0 1 1-4.709.002 2.355 2.355 0 0 1 4.71-.002zM34.417 31.35s-2.897-2.528-6.599-2.528c-3.704 0-6.9 1.845-10.986 1.845-4.89 0-7.534-2.247-7.534-2.247"
          strokeLinejoin="round"
        />
        <path
          d="M12.81 29.991l8.922-5.317-.976-2.164s-.212-.884-.945-.64c-.731.244-4.268 2.059-4.861 2.287-1.504.578-2.197-.99-.742-1.838 1.884-1.101 5.424-2.849 5.962-3.137.68-.366 1.321-.432 1.897.553.836 1.434 3.438 5.367 3.992 6.34.554.974.854 1.796-.205 2.815"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IndoorPoolIcon;
