import { useEffect } from 'react';
import type { AccordionAddOnProps } from './accordion-add-on.types';
import { AccordionAddOnSectionRow } from './accordion-add-on-section-row';
import { Accordion } from '@dx-ui/osc-accordion';
import cx from 'classnames';
import { makeRandomInt } from '@curated-property/utils';

export function AccordionAddOn({
  accordionPanel,
  accordionAddOnStyles,
  className,
}: AccordionAddOnProps) {
  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'accordionAddOn';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `div#${componentStyleID}`;
  // This will be injected into <head> instance-by-instance
  let styleString = '';

  const accordionBorderColour = accordionAddOnStyles?.accordionBorderColor || '';

  accordionBorderColour &&
    (styleString += `${mainSelector} button.accordion-stacked { border-color:${accordionBorderColour} !important; } `);

  accordionAddOnStyles?.accordionRowTextColor &&
    (styleString += `${mainSelector} button.accordion-stacked,${mainSelector} button.accordion-stacked + section div {color: ${accordionAddOnStyles?.accordionRowTextColor}; }`);

  const accordionOpenPanelBackgroundColour =
    accordionAddOnStyles?.accordionPanelBackgroundColor || '';
  accordionOpenPanelBackgroundColour &&
    (styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] + section  {background-color:${accordionOpenPanelBackgroundColour};} `);

  // Default icon color is black.
  const accordionIconIndicatorColour = accordionAddOnStyles?.accordionIconColor || '#000';

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg { fill:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg { color:${accordionIconIndicatorColour} !important; }`;

  // The expanded state setting uses the unexpanded state setting absent a specific setting of its own.
  const accordionIconIndicatorColourExpanded = !accordionAddOnStyles?.accordionIconColorActive
    ? accordionIconIndicatorColour
    : accordionAddOnStyles?.accordionIconColorActive;

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg { fill:${accordionIconIndicatorColourExpanded} !important; }`;

  // Adds style to <head>
  useEffect(() => {
    const $style = document.createElement('style');
    $style.setAttribute('id', styleElementID);
    document.head.appendChild($style);
    $style.innerHTML = styleString;
  }, [styleElementID, styleString]);

  //limit to 3 accordion panels
  if (accordionPanel && accordionPanel?.length > 3) {
    accordionPanel = accordionPanel.slice(0, 3);
  } else if (accordionPanel && accordionPanel?.length <= 3) {
    accordionPanel = accordionPanel.slice(0, accordionPanel.length);
  }

  const getAccordionPanelItems = (panel) =>
    panel.panelRepeater
      ?.map((p, i) => {
        return p?.rowLabel || p?.rowText ? (
          <AccordionAddOnSectionRow
            key={p.rowLabel + i.toString()}
            rowLabel={p?.rowLabel}
            rowText={p?.rowText}
          />
        ) : null;
      })
      .filter(Boolean);

  const accordionItems = accordionPanel?.map((panel, i) => ({
    key: `accordionAddOn` + i,
    collapsedButtonLabel: panel.panelLabel,
    expandedButtonLabel: panel.panelLabel,
    content: (
      <div className="accordion-section p-2 text-lg" data-testid="accordion-add-on-section">
        <ul>{getAccordionPanelItems(panel)}</ul>
      </div>
    ),
  }));

  return (
    <div className={cx('w-full', className)} id={componentStyleID}>
      <Accordion
        className={cx(
          'accordion-stacked align-center border-text flex w-full justify-between border-b px-2 py-4 text-lg font-extrabold aria-expanded:border-b [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&_span]:flex [&_span]:items-center'
        )}
        items={accordionItems}
        type="multiple"
        variant="stacked"
        headingLevel="h3"
      />
    </div>
  );
}
