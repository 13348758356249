import gql from 'graphql-tag-transform.macro';
import { gallerySliderComponentSettings } from './common-vars';

export const GallerySlider = gql`
fragment GallerySlider on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_GallerySlider {
        columnCount
        fieldGroupName
        tileHeight
        tilePadding
        repeater {
          image {
            altText
            sourceUrl
          }
        }
        gallerySliderComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${gallerySliderComponentSettings}
        }
      }
}
`;
