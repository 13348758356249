export function Parking({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V7A2.3777,2.3777,0,0,0,17,4.625ZM18.625,17A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7Z" />
          <path d="M12.5,7.625H9A.3751.3751,0,0,0,8.625,8v8a.375.375,0,0,0,.75,0V13.375H12.5a2.875,2.875,0,0,0,0-5.75Zm0,5H9.375V8.375H12.5a2.125,2.125,0,0,1,0,4.25Z" />
        </g>
      </g>
    </svg>
  );
}
