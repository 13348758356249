import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import type { ArrayElement, GuestsState, mergeRooms } from '../utils';
import { guestsPerRoom, parseDate, removeCtyhocnPrefixFromRoomCode } from '../utils';
import cx from 'classnames';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { useRouter } from 'next/router';
import type { Maybe } from '@dx-ui/gql-types';

export interface RoomsCTAProps {
  room: ArrayElement<ReturnType<typeof mergeRooms>>;
  hasPrices: boolean;
  rate: string;
  ctyhocn: string;
  arrivalDate: string;
  departureDate: Maybe<string>;
  guests?: GuestsState;
  className?: string;
  selectedRoom?: boolean;
  inlineStyles?: any;
  currency?: any;
  onClick?: () => void;
  isInModal?: boolean;
}

export function RoomsCTA({
  room,
  hasPrices,
  rate,
  guests,
  ctyhocn,
  arrivalDate,
  departureDate,
  className,
  inlineStyles,
  currency,
  onClick,
  isInModal,
}: RoomsCTAProps) {
  const { t } = useTranslation();
  const [bookButtonHover, setBookButtonHover] = useState(false);
  const ctaText = !hasPrices ? t('checkAvailability') : t('exploreFrom', { price: rate });
  const { locale } = useRouter();
  const adultsCount = guestsPerRoom(guests?.adults);
  const kidsCount = guestsPerRoom(guests.kids, guests.kidsAges);
  const arrival = parseDate(arrivalDate);
  const departure = departureDate && parseDate(departureDate);
  const dates =
    arrival && departure
      ? {
          arrivalDate: arrival,
          departureDate: departure,
        }
      : undefined;

  const bookUrl = bookUriBuilder({
    baseUrl: 'https://www.hilton.com',
    locale: locale as any,
    page: 'rates',
    urlParams: {
      ctyhocn: room?.hotelCtyhocn || ctyhocn,
      dates,
      rates: {
        roomTypeCode: removeCtyhocnPrefixFromRoomCode(room?.roomTypeCode),
      },
      rooms: [{ adults: [...adultsCount], children: [...kidsCount] }],
    },
  });

  const getCtaColor = (
    defaultColor?: string,
    activeColor?: string,
    modalDefaultColor?: string,
    modalActiveColor?: string
  ): string | undefined => {
    if (bookButtonHover) {
      return isInModal ? modalActiveColor || activeColor : activeColor;
    }
    return isInModal ? modalDefaultColor || defaultColor : defaultColor;
  };

  const ctaStyle = {
    backgroundColor: getCtaColor(
      inlineStyles?.bookCtaBackgroundColor,
      inlineStyles?.activeBookCtaBackgroundColor,
      inlineStyles?.modalBookCtaBackgroundColor,
      inlineStyles?.modalActiveBookCtaBackgroundColor
    ),
    color: getCtaColor(
      inlineStyles?.bookCtaTextColor,
      inlineStyles?.activeBookCtaTextColor,
      inlineStyles?.modalBookCtaTextColor,
      inlineStyles?.modalActiveBookCtaTextColor
    ),
    borderColor: getCtaColor(
      inlineStyles?.bookCtaBorderColor,
      inlineStyles?.activeBookCtaBorderColor,
      inlineStyles?.modalBookCtaBorderColor,
      inlineStyles?.modalActiveBookCtaBorderColor
    ),
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(!hasPrices || room?.moreRatesFromRate) && (
        <a
          data-testid="bookBtn"
          aria-label={`${ctaText?.replace(currency?.currencyCode, currency?.description)} (${t(
            'opensInNewWindow'
          )})`}
          className={cx(
            'popunder-cta btn btn-primary rounded-md py-2 text-center font-bold',
            className
          )}
          style={ctaStyle}
          href={bookUrl}
          rel="noopener noreferrer"
          onClick={onClick}
          target="_blank"
          suppressHydrationWarning
          onMouseOver={() => setBookButtonHover(true)}
          onMouseOut={() => setBookButtonHover(false)}
        >
          {ctaText}
          <span className="sr-only">{` ${room?.roomTypeName}, `}</span>
        </a>
      )}
    </>
  );
}
