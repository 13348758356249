export function Suites({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M18.5,19.625h-1.125V10c0-0.2071-0.1679-0.375-0.375-0.375S16.625,9.7929,16.625,10v9.625H15.5
            c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375S18.7071,19.625,18.5,19.625z
            "
            />
            <path
              d="M20.3516,7.8682l-1.5-4C18.7963,3.7219,18.6564,3.6251,18.5,3.625h-3c-0.1564,0.0001-0.2963,0.0969-0.3516,0.2432l-1.5,4
            c-0.0728,0.1938,0.0252,0.41,0.219,0.4828C13.9098,8.367,13.9547,8.3751,14,8.375h6c0.2071,0.0004,0.3752-0.1672,0.3756-0.3742
            C20.3757,7.9555,20.3675,7.9106,20.3516,7.8682z M14.541,7.625l1.219-3.25h2.48l1.2188,3.25H14.541z"
            />
            <path
              d="M15.375,13c-0.0011-0.7589-0.6161-1.3739-1.375-1.375c-0.218,0.0015-0.4323,0.0555-0.625,0.1575V11
            C13.3735,9.6889,12.3111,8.6265,11,8.625H8C6.6889,8.6265,5.6265,9.6889,5.625,11v0.7825C5.4323,11.6805,5.218,11.6265,5,11.625
            C4.2411,11.6261,3.6261,12.2411,3.625,13v4C3.6261,17.7589,4.2411,18.3739,5,18.375h0.625V20c0,0.2071,0.1679,0.375,0.375,0.375
            S6.375,20.2071,6.375,20v-1.625h6.25V20c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-1.625H14
            c0.7589-0.0011,1.3739-0.6161,1.375-1.375V13z M6.375,11C6.3757,10.1028,7.1028,9.3757,8,9.375h3
            c0.8972,0.0007,1.6243,0.7278,1.625,1.625v4.625h-6.25V11z M14.625,17c-0.0003,0.345-0.28,0.6247-0.625,0.625H5
            C4.655,17.6247,4.3753,17.345,4.375,17v-4c0-0.3452,0.2798-0.625,0.625-0.625S5.625,12.6548,5.625,13v3
            C5.6251,16.2071,5.7929,16.3749,6,16.375h7c0.2071-0.0001,0.3749-0.1679,0.375-0.375v-3c0-0.3452,0.2798-0.625,0.625-0.625
            s0.625,0.2798,0.625,0.625V17z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
