import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./kwiwawa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-current text-bg antialiased lg:w-40" fillColor="#000000" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="lg:w-46 w-28 fill-current text-bg antialiased md:w-32"
    fillColor="#ffffff"
  />
);
