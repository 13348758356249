import { type SVGAttributes } from 'react';

const NoPetsIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="currentColor">
        <path
          d="M27.917 12.367c.365-.35.871-.576 1.59-.576 2.137 0 2.193 2.366 3.361 2.707.577.168 1.639.475 1.95.475 0 0 .422-.05-.014.597 0 .238-.139 1.219-.994 1.486-.632.55-.733.552-1.37.552-.637 0-1.115-.534-1.844-.534-.728 0-1.795 1.052-1.795 2.833 0 4.663-1.656 5.174-2.2 5.618-.544.444-1.202 2.828-1.202 4.499 0 1.584.172 3.134.587 3.408.74.32.511 1.08.054 1.08h-.689c-.788 0-1.826-1.63-1.826-4.647 0-1.679.368-4.366.32-4.741-4.05 0-4.735-1.749-6.455-1.749-.201 3.962-4.027 4.182-4.027 5.891 0 4.147 1.618 3.344 1.618 4.533 0 .308-.321.712-.825.712-1.094 0-1.484-.253-1.853-1.033-.562-1.186-.946-3.2-.946-4.324 0-1.81 1.071-1.374 1.057-3.06-.007-.956-1.057-1.249-1.057-4.134 0-1.911.653-3.283.653-3.283s.164-.785-.484-1.988c-.648-1.202-2.167-1.72-1.639-2.586.376-.615 2.22.465 2.911 1.453.529.755 1.123 2.138 1.123 2.138s.55-.04 1.203-.04c1.363 0 1.958.516 3.595.516 3.147 0 5.393-.409 6.72-1.236"
          strokeWidth="1.5"
        />
        <path
          d="M18.6 23.73c-.393 1.95-1.01 2.67-1.01 3.95 0 4.148 1.618 3.344 1.618 4.534 0 .307-.321.713-.825.713-1.094 0-1.484-.254-1.854-1.035-.56-1.185-.945-3.198-.945-4.323 0-.257.022-.47.06-.649M28.644 22.064c-.028 2.957.302 4.81.46 6.564.155 1.704.283 2.68.746 3.208.858.297.496 1.092-.112 1.092-.53 0-1.534.245-2.403-4.35-.305-1.61-.573-2.586-.62-2.961"
          strokeLinejoin="round"
        />
        <path
          d="M27.818 12.448c-1.096 1.12-3.858 3.504-1.867 4.749 1.308.817 2.842-1.17 2.842-3.292"
          strokeWidth="1.5"
        />
        <path
          d="M41.468 22.665c0 10.67-8.65 19.32-19.32 19.32S2.83 33.334 2.83 22.664s8.65-19.32 19.32-19.32 19.319 8.65 19.319 19.32zM8.488 9.004l27.321 27.322"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default NoPetsIcon;
