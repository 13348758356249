import type { Icon } from '@dx-ui/osc-icon';
import cx from 'classnames';
import { forwardRef } from 'react';
import { BrandIcon } from './brand-icon';
import type { Variant } from './util/button';
import { getClassnames } from './util/button';

export interface BrandButtonProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'> {
  /** Button label */
  label?: React.ReactNode;
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
  /** Icon alignment, "before" | "after" */
  iconAlign?: 'before' | 'after';
}

/** This is a one stop shop for all brand button needs, to add style exceptions so all buttons stay up to day. Check out some of the exceptions on Curio and Waldorf. */
export const BrandButton = forwardRef<HTMLButtonElement, BrandButtonProps>(
  (
    { label, variant, iconProps, brandComponentTheme, iconAlign = 'before', ...buttonProps },
    ref
  ) => (
    <button
      type="button"
      {...buttonProps}
      ref={ref}
      className={cx(buttonProps.className, getClassnames({ brandComponentTheme, variant }))}
      data-testid="CTAButtonE2ETest"
    >
      <span className="flex gap-2" data-testid="buttonContentParent">
        {iconAlign === 'before' && iconProps ? <BrandIcon {...iconProps} /> : null}
        {label}
        {iconAlign === 'after' && iconProps ? <BrandIcon {...iconProps} /> : null}
      </span>
    </button>
  )
);

BrandButton.displayName = 'BrandButton';

export default BrandButton;
