import { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import type { ACFAnchorProps, AnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder, GIS_TextAlignment } from './functions/global-instance-styles';
import type { CropProps } from './cropped-image/cropped-image';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { HandleWYSIWYGContent, HandleAnimations } from './functions/helper';

export interface IfourStaggeredTilesTile {
  title?: string;
  title_noTx?: string;
  copy?: string;
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  enableCropping?: boolean;
  cropType?: string;
  xPosition?: string;
  xPositionAdvanced?: string;
  yPosition?: string;
  yPositionAdvanced?: string;
  cropWidth?: string;
  cropHeight?: string;
  autoPosition?: boolean;
  imagePositioning?: string;
  tileSize?: string;
  buttons?: Array<{
    link?: AnchorProps;
  }>;
}

type CombinedProps = CropProps & IfourStaggeredTilesTile;

export interface IfourStaggeredTiles {
  repeater: Array<CombinedProps> | undefined;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function FourStaggeredTiles({
  repeater,
  instanceStyles,
  globalStyles,
}: IfourStaggeredTiles) {
  const chunks = groupBy2(repeater);

  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  return (
    <section
      data-testid="twoColumnHoverTiles"
      data-element-id="two-column-hover-tiles"
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
      }}
    >
      <div
        className={paddingStyles}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage?.sourceUrl,
                inlineStyles?.componentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        }}
      >
        <div className="container flex flex-wrap gap-2">
          {chunks?.map((group, chunkIdx) => (
            <div className="w-full grow lg:w-auto" key={JSON.stringify(group).substring(0, 10)}>
              {group?.map((item, groupIdx) => (
                <HoverTileCenter
                  key={item?.title || item?.image?.altText}
                  idx={groupIdx}
                  chunkIdx={chunkIdx}
                  title={inlineStyles?.headingsNoTranslate ? item?.title_noTx : item?.title}
                  image={
                    item?.image?.sourceUrl || inlineStyles?.contentBackgroundImage?.sourceUrl || ''
                  }
                  cropping={{
                    enableCropping: item?.enableCropping,
                    cropType: item?.cropType,
                    xPosition: item?.xPosition,
                    xPositionAdvanced: item?.xPositionAdvanced,
                    yPosition: item?.yPosition,
                    yPositionAdvanced: item?.yPositionAdvanced,
                    cropWidth: item?.cropWidth,
                    cropHeight: item?.cropHeight,
                    autoPosition: item?.autoPosition,
                  }}
                  imageAlt={item?.image?.altText}
                  copy={item?.copy}
                  size={item?.tileSize}
                  buttons={item?.buttons}
                  inlineStyles={inlineStyles}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

interface HoverProps {
  idx: number;
  chunkIdx?: number;
  title?: string;
  image: string;
  imageAlt?: string;
  cropping?: CropProps;
  copy?: string;
  size?: string;
  buttons?: (ACFAnchorProps | undefined)[];
  inlineStyles?: StyleObject;
}

function HoverTileCenter({
  idx,
  chunkIdx,
  title,
  image,
  imageAlt,
  cropping,
  copy,
  size,
  buttons,
  inlineStyles,
}: HoverProps) {
  const [hovered, setHovered] = useState(false);
  const [isLagerReveal, setIsLagerReveal] = useState<boolean>(false);

  const imgWidth = 680;
  const imgHeight = size === 'large' ? 715 : 459;

  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);

  const imgWrapRef = useRef<HTMLDivElement>(null);
  const revealRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (imgWrapRef?.current && revealRef?.current) {
      setIsLagerReveal(revealRef.current.clientHeight > imgWrapRef.current.clientHeight);
    }
  }, []);

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : `${idx % 2 === 0 ? 'translate-x-8' : '-translate-x-8'} ${
          chunkIdx ? 'lg:translate-x-8' : 'lg:-translate-x-8'
        }`,
    delayOne: chunkIdx ? 'delay-500' : 'delay-300',
  });

  return (
    <div ref={animations?.ref}>
      <div
        data-testid="visiblePanel"
        className={cx('relative mb-2 overflow-hidden lg:mb-4', animations?.one)}
        onMouseEnter={() => setHovered(!!(title || copy))}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(!!(title || copy))}
        onBlur={() => setHovered(false)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
        }}
        ref={imgWrapRef}
      >
        <CroppedImage
          src={image}
          loader={() => {
            return customLoader({
              src: image,
              width: imgWidth,
              height: imgHeight,
              crop: cropping?.enableCropping || false,
              cropType: cropping?.cropType || '',
              cropHeight: cropping?.cropHeight || '',
              cropWidth: cropping?.cropWidth || '',
              xPosition: cropping?.xPosition || '',
              xPositionAdvanced: cropping?.xPositionAdvanced || '',
              yPosition: cropping?.yPosition || '',
              yPositionAdvanced: cropping?.yPositionAdvanced || '',
            });
          }}
          width={imgWidth}
          height={imgHeight}
          alt={imageAlt}
          layout="responsive"
          className="block"
        />
        {title ? (
          <>
            <div className="absolute bottom-0 flex h-1/3 w-full bg-gradient-to-t from-text to-transparent" />
            <h2
              aria-hidden={true}
              className={cx(
                'absolute bottom-0 left-0 w-full p-8 text-center text-xl font-bold leading-none text-bg transition duration-300 ease-in-out md:text-2xl',
                {
                  'opacity-0 translate-y-full': hovered,
                  'opacity-100': !hovered,
                  OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                }
              )}
              style={{ color: inlineStyles?.titleColor }}
            >
              {title}
            </h2>
          </>
        ) : null}

        <div
          data-testid="reveal-panel"
          data-element-id="reveal-panel-wrapper"
          className={cx(
            'absolute left-0 top-0 flex size-full flex-col items-center bg-[#00000099] text-bg transition duration-300 ease-in-out',
            'px-4 text-center md:px-8 lg:px-16',
            isLagerReveal ? 'justify-start overflow-y-auto' : 'justify-center',
            {
              'opacity-0 translate-y-full': !hovered,
              'opacity-100': hovered,
            }
          )}
          style={{
            textAlign: textAlignment,
            alignItems: inlineStyles?.textAlignment,
          }}
        >
          <div tabIndex={0} ref={revealRef}>
            <h2
              className={cx('my-4 font-headline text-xl font-bold leading-tight', {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              style={{ color: inlineStyles?.subtitleColor }}
            >
              {title}
            </h2>
            <div
              className="mb-4 text-lg"
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
              style={{ color: inlineStyles?.textColor }}
            />
          </div>
          {buttons ? (
            <div className="mb-4 mt-2">
              {buttons?.map((link) => {
                return (
                  <AnchorLink
                    key={link?.link?.title}
                    url={link?.link?.url}
                    title={link?.link?.title}
                    srContext={title}
                    target={link?.link?.target}
                    buttonStyle={link?.buttonStyle ?? 'primary-outline'}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

/**
 * group repeater items with even/odd (e.g. [[0,2], [1, 3]])
 */
function groupBy2(itemArray: Array<IfourStaggeredTilesTile> | undefined) {
  if (!itemArray) {
    return null;
  }
  const chunks: Array<IfourStaggeredTilesTile | undefined>[] = [];
  const leftCol = [];
  const rightCol = [];
  for (let i = 0; i < itemArray.length; i++) {
    if (i % 2 === 0) {
      leftCol.push(itemArray[i]);
    } else {
      rightCol.push(itemArray[i]);
    }
  }
  chunks.push(leftCol, rightCol);
  return chunks;
}
