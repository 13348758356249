import cx from 'classnames';
import type { AccordionAddOnRowProps } from './accordion-add-on.types';

export function AccordionAddOnSectionRow({ rowLabel, rowText }: AccordionAddOnRowProps) {
  return (
    <li className={cx('block w-full p-2', { 'sm:flex': rowLabel !== null })}>
      {rowLabel && rowText && (
        <div className="block w-full font-extrabold sm:w-1/2 lg:w-1/3">{rowLabel}</div>
      )}
      {rowText && (
        <div
          className={cx('block w-full', {
            'sm:w-1/2 lg:w-2/3': rowLabel !== null,
          })}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: rowText ? rowText : rowText || '',
            }}
          />
        </div>
      )}
    </li>
  );
}
