import classnames from 'classnames';

export type Variant = 'solid' | 'outline';

export type ClassNamesArgs = Partial<{
  brandComponentTheme: CmsBrandComponentTheme;
  variant: Variant;
}>;

export function getClassnames({ brandComponentTheme: theme, variant }: ClassNamesArgs) {
  const isDark = theme === 'dark';
  const isLight = theme === 'light';
  const isSolid = variant === 'solid';
  const isOutline = variant === 'outline';
  return classnames('btn-brand motion-safe:transition', {
    'btn-brand-solid': isSolid && !isLight && !isDark,
    'btn-brand-outline': isOutline && !isLight && !isDark,
    'btn-brand-dark': isDark && !isSolid && !isOutline,
    'btn-brand-dark-outline': isDark && isOutline,
    'btn-brand-dark-solid': isDark && isSolid,
    'btn-brand-light': isLight && !isSolid && !isOutline,
    'btn-brand-light-outline': isLight && isOutline,
    'btn-brand-light-solid': isLight && isSolid,
  });
}
