import type { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return <SharedFooter {...props} brandPhoneNumber="+1-702-676-7000" />;
};
