import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksSingleImageInterface } from './interfaces';

export function MediaBlocksSingleImage(props: MediaBlocksSingleImageInterface) {
  return (
    <div className="relative size-full">
      <CroppedImage
        data-testid="media-blocks-single-image"
        layout="fill"
        src={props?.image?.sourceUrl || ''}
        alt={props?.image?.altText}
        loader={() => {
          return customLoader({
            width: props?.maxImageWidth || undefined,
            src: props?.image?.sourceUrl,
            crop: props?.imageCropSingle?.imageCrop11AddCrop && true,
            cropType: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.cropType,
            cropHeight: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.cropHeight,
            cropWidth: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.cropWidth,
            xPosition: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.xPosition,
            xPositionAdvanced: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
            yPosition: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.yPosition,
            yPositionAdvanced: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
            autoPosition: props?.imageCropSingle?.imageCrop11AddCrop?.[0]?.autoPosition,
          });
        }}
        objectFit="cover"
      />
    </div>
  );
}
