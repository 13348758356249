export function Sailing({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <g>
            <path d="M5,15.375h5.625v1.735c-1.5231-0.0131-3.0414-0.1719-4.5342-0.4742c-0.1281-0.0299-0.2626,0.0078-0.3564,0.1     c-0.0927,0.0931-0.1302,0.2278-0.0987,0.3554l0.5,2C6.1779,19.258,6.3279,19.3749,6.5,19.375     c0.0306-0.0002,0.061-0.0038,0.0908-0.0107c0.201-0.0504,0.3233-0.254,0.2735-0.4551l-0.3565-1.4229     c1.4812,0.2465,2.9797,0.3745,4.4813,0.3826L11,17.8711l0.01-0.002c0.1628,0.0022,0.32,0.0059,0.49,0.0059     c1.6722,0.0166,3.3425-0.1139,4.9918-0.39l-0.3561,1.4241c-0.0498,0.2011,0.0725,0.4047,0.2735,0.4551     c0.0298,0.0069,0.0602,0.0105,0.0908,0.0107c0.1722-0.0001,0.3223-0.1172,0.3643-0.2842l0.5-2     c0.0015-0.0061,0-0.0119,0.0008-0.018c0.0029-0.0218,0.0039-0.0438,0.0029-0.0658c0.0018-0.0281,0.0005-0.0562-0.004-0.084     c-0.001-0.0047,0-0.0091-0.0007-0.0138c-0.0054-0.0135-0.0117-0.0267-0.0188-0.0394c-0.0093-0.0279-0.0219-0.0545-0.0376-0.0793     c-0.0117-0.0154-0.0246-0.0298-0.0387-0.043c-0.0194-0.0221-0.0415-0.0418-0.0657-0.0585     c-0.0143-0.008-0.0291-0.015-0.0443-0.0211c-0.0213-0.013-0.0439-0.0238-0.0674-0.0322c-0.0077-0.0018-0.0154,0-0.0232-0.0008     c-0.0202-0.0022-0.0404-0.0029-0.0607-0.002c-0.0251-0.0018-0.0504-0.001-0.0754,0.0022     c-0.0075,0.0012-0.0148-0.0011-0.0223,0.0006c-1.7799,0.3594-3.5937,0.5235-5.4092,0.4894c-0.044,0-0.0815-0.002-0.125-0.0021     V15.375H17c0.2071,0,0.375-0.1679,0.375-0.375S17.2071,14.625,17,14.625h-0.7546l-4.87-9.7131V3.375h0.47l0.89,0.8906     C12.8057,4.3356,12.9008,4.3749,13,4.375h1c0.2071,0,0.375-0.1679,0.375-0.375c0-0.2071-0.1679-0.375-0.375-0.375h-0.8447     l-0.89-0.8906C12.1948,2.6642,12.0994,2.6249,12,2.625h-1C10.7929,2.6251,10.6251,2.7929,10.625,3v0.75     c-1.1656,0.5164-6,3.1485-6,11.25C4.6251,15.2071,4.7929,15.3749,5,15.375z M15.407,14.625h-4.032V6.5839L15.407,14.625z      M10.625,4.57v10.055H5.3789C5.51,7.7062,9.2569,5.2421,10.625,4.57z" />
            <path d="M19,19.625c-0.5004,0.0523-0.9755,0.2466-1.3691,0.56c-0.2802,0.2238-0.6113,0.375-0.9639,0.44     c-0.3527-0.0648-0.6839-0.216-0.9639-0.44c-0.3941-0.3132-0.8693-0.5075-1.37-0.56c-0.5004,0.0523-0.9755,0.2466-1.3691,0.56     c-0.2802,0.2238-0.6113,0.375-0.9639,0.44c-0.3526-0.065-0.6837-0.2162-0.9639-0.44c-0.3936-0.3134-0.8687-0.5077-1.3691-0.56     c-0.5007,0.0525-0.9759,0.2468-1.37,0.56c-0.28,0.224-0.6112,0.3752-0.9639,0.44c-0.3526-0.065-0.6837-0.2162-0.9639-0.44     C5.9756,19.8716,5.5005,19.6773,5,19.625c-0.2071,0-0.375,0.1679-0.375,0.375S4.7929,20.375,5,20.375     c0.3526,0.065,0.6837,0.2162,0.9639,0.44c0.3936,0.3134,0.8687,0.5077,1.3691,0.56c0.5007-0.0525,0.9759-0.2468,1.37-0.56     c0.28-0.224,0.6112-0.3752,0.9639-0.44c0.3526,0.065,0.6837,0.2162,0.9639,0.44c0.3937,0.3134,0.8687,0.5077,1.3692,0.56     c0.5004-0.0523,0.9755-0.2466,1.3691-0.56c0.2802-0.2238,0.6113-0.375,0.9639-0.44c0.3527,0.0648,0.6839,0.216,0.9639,0.44     c0.3941,0.3132,0.8693,0.5075,1.37,0.56c0.5004-0.0523,0.9755-0.2466,1.3691-0.56c0.2802-0.2238,0.6113-0.375,0.964-0.44     c0.2071,0,0.375-0.1679,0.375-0.375S19.2071,19.625,19,19.625z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
