import * as React from 'react';
import cx from 'classnames';
import Icon from '@dx-ui/osc-icon';

export type ControlCloseProps = {
  /** used for screen readers and other assistive devices */
  label: string;
  /** the size of the control */
  size?: React.ComponentProps<typeof Icon>['size'];
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * A simple button to be used any time you need an interaction to close something. like a popup.
 */
export const ControlClose = React.forwardRef<HTMLButtonElement, ControlCloseProps>(
  ({ className, label, disabled, size = 'md', ...rest }, forwardedRef) => (
    <button
      ref={forwardedRef}
      type="button"
      className={cx('btn btn-lg btn-primary-text', className)}
      disabled={disabled}
      {...rest}
    >
      <span className="sr-only">{label}</span>
      <Icon name="close" size={size} />
    </button>
  )
);

ControlClose.displayName = 'ControlClose';

export default ControlClose;
