import cx from 'classnames';
import * as React from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { FormError } from './form.error';
import { FormHelpMessage } from './form.help-message';
import { FormLabel } from './form.label';
import type { FormInputBase } from './form.types';

/**
 * This component is to be used in place of all `<select>` elements including checkbox and radio
 */
const FormSelect: React.FC<
  React.PropsWithChildren<FormInputBase<React.SelectHTMLAttributes<HTMLSelectElement>>>
> = ({
  label,
  name,
  optional,
  required,
  registerOptions,
  labelClassName,
  helpMessage,
  loading,
  className,
  containerClassName,
  children,
  ...rest
}) => {
  const id = React.useId();
  const errorId = `input-error-${id}`;
  const helperId = `input-helper-${id}`;
  const [t] = useTranslation('osc-form');
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const fieldError = get(errors, name);
  const hasError = !!fieldError;

  return (
    <div className={containerClassName}>
      <FormLabel
        label={label}
        required={required}
        optional={optional}
        hasError={hasError}
        className={cx('self-start', labelClassName)}
      >
        <select
          {...rest}
          className={cx(className, {
            'form-select': !rest.multiple,
            'form-multiselect': rest.multiple,
            'form-error': hasError,
          })}
          required={required}
          aria-invalid={hasError}
          aria-describedby={`${errorId} ${helperId}`}
          {...register(name, {
            required: required ? t('requiredError') : false,
            ...registerOptions,
          })}
        >
          {children}
        </select>
      </FormLabel>
      <FormError id={errorId} error={fieldError} />
      <FormHelpMessage id={helperId} message={helpMessage} loading={loading} />
    </div>
  );
};

export { FormSelect };
export default FormSelect;
