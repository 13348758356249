import { Login } from '@dx-ui/osc-login';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { UserCircle } from '@curated-property/icon-list';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface HonorsSignIn {
  hideBorder?: boolean;
  fontSize?: string;
  underline?: boolean;
  underlineHover?: boolean;
  textColor?: string;
  iconColor?: string;
  ddTextColor?: string;
  ddBackgroundColor?: string;
  ddBackgroundHighlightColor?: string;
  ddTextHighlightColor?: string;
}

export function HonorsSignIn(props: HonorsSignIn) {
  const { login, guestInfo, isLoading, logout } = useAuth();
  const locale = useRouter()?.locale || 'en';
  const authLocale = locale?.startsWith('zh-')
    ? locale.split('-').slice(0, 2).join('-')
    : locale.split('-')[0];
  const fontStyles = props?.fontSize || 'text-sm';
  const iframeSrc = `https://www.hilton.com/${authLocale}/auth2/guest/login/`;
  const { t } = useTranslation();

  useEffect(() => {
    /*
      Ok, yes the following is kind of hacky.
      But, having the .btn class applied by default to the Honors Sign In
        -really messes up how it looks on lots of different sites
    */
    const honorsButton = document?.querySelector('[data-element-id="honors-header-login"] .btn');
    honorsButton?.classList?.remove('btn');
  });

  return (
    <div
      data-element-id="honors-wrapper"
      className={`flex items-center justify-center px-2 py-1 ${fontStyles}`}
    >
      <span
        className={`hidden pr-3 text-sm ${props?.hideBorder ? '' : 'lg:flex'}`}
        style={{
          color: props?.textColor,
        }}
      >
        |
      </span>
      <UserCircle className="mr-1 h-6" fillColor={props?.iconColor} />
      {!guestInfo && !isLoading ? (
        <div
          className={`inline-block ${
            props?.underline ? `underline hover:no-underline` : `no-underline hover:underline`
          }`}
          data-element-id="honors-header-login"
          style={{
            color: props?.textColor,
          }}
        >
          <Login
            title={t('signIn')}
            buttonName={t('signIn')}
            frameSrc={iframeSrc}
            onLoginAttempt={async ({ data }) => {
              if (data) {
                await login({ data });
              }
            }}
          />
        </div>
      ) : (
        <HonorsAccountButton
          isLoading={isLoading}
          guestInfo={guestInfo}
          logout={logout}
          styleOptions={props}
        />
      )}
    </div>
  );
}

interface HonorsAccountProps {
  isLoading: boolean;
  guestInfo: any;
  logout: () => Promise<void>;
  styleOptions?: HonorsSignIn;
}

function HonorsAccountButton({ isLoading, guestInfo, logout, styleOptions }: HonorsAccountProps) {
  const { locale = 'en' } = useRouter();
  const fontStyles = styleOptions?.fontSize || 'font-sm';
  const itemStyles = `${fontStyles} block text-text px-2 py-3 !no-underline`;
  const itemHoverStyles =
    'hover:text-text-alt hover:bg-bg-alt highlighted:bg-bg-alt cursor-pointer border-b';
  const { t } = useTranslation();
  const colorProps = {
    textColor: styleOptions?.ddTextColor,
    textColorHover: styleOptions?.ddTextHighlightColor,
    bgColorHover: styleOptions?.ddBackgroundHighlightColor,
  };
  return (
    <div>
      {isLoading ? (
        <p className={`${fontStyles} animate-pulse`}>{t('loading')}...</p>
      ) : (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button
              className={`${fontStyles} flex items-center`}
              style={{ color: styleOptions?.textColor }}
            >
              {t('hi')}, {guestInfo?.personalinfo?.name?.firstName}{' '}
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className={`${fontStyles} bg-bg border-border divide-border z-50 w-56 divide-y rounded border border-solid p-2 shadow-lg outline-none`}
              style={{ backgroundColor: styleOptions?.ddBackgroundColor }}
            >
              <div className={itemStyles} style={{ color: styleOptions?.ddTextColor }}>
                <strong>{guestInfo?.hhonors?.summary?.tierName}</strong>
                <p>
                  {guestInfo?.hhonors?.summary?.points} {t('points')}
                </p>
              </div>
              <HonorsDropdownMenuItem
                type="link"
                className={`${itemStyles} ${itemHoverStyles}`}
                href={`https://www.hilton.com/${locale}/hilton-honors/guest/my-account/`}
                target="_blank"
                aria-label={`${t('myAccount')} ${t('opensInNewWindow')}`}
                rel="noopener noreferrer"
                {...colorProps}
              >
                {t('myAccount')}
              </HonorsDropdownMenuItem>
              <HonorsDropdownMenuItem
                type="item"
                className={`${itemStyles} ${itemHoverStyles}`}
                onSelect={async () => {
                  await logout();
                }}
                {...colorProps}
              >
                {t('signOut')}
              </HonorsDropdownMenuItem>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </div>
  );
}

function HonorsDropdownMenuItem({
  textColor = '',
  textColorHover = '',
  bgColorHover = '',
  ...props
}) {
  const [itemHover, setItemHover] = useState(false);
  const colorProps = {
    color: itemHover ? textColorHover : textColor,
    backgroundColor: itemHover ? bgColorHover : 'transparent',
  };

  const userEvents = {
    onMouseEnter: () => setItemHover(true),
    onMouseLeave: () => setItemHover(false),
    onFocus: () => setItemHover(true),
    onBlur: () => setItemHover(false),
  };

  switch (props?.type) {
    case 'link':
      return (
        <DropdownMenu.Item {...userEvents}>
          <a {...props} style={colorProps}>
            {props?.children}
          </a>
        </DropdownMenu.Item>
      );
    case 'item':
      return (
        <DropdownMenu.Item onSelect={props?.onSelect} style={colorProps} {...userEvents} {...props}>
          {props?.children}
        </DropdownMenu.Item>
      );
    default:
      return null;
  }
}
