import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./shawsci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-32 fill-black text-bg antialiased md:w-44" fillColor="#bc9b6a" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-32 fill-black text-bg antialiased md:w-40" fillColor="#000" />
);
