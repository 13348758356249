export function User({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M13.9091,12.9177a4.3752,4.3752,0,1,0-3.8181,0A6.3854,6.3854,0,0,0,5.625,19a.375.375,0,0,0,.75,0,5.625,5.625,0,0,1,11.25,0,.375.375,0,0,0,.75,0A6.3852,6.3852,0,0,0,13.9091,12.9177ZM8.375,9A3.625,3.625,0,1,1,12,12.625,3.6289,3.6289,0,0,1,8.375,9Z" />
      </g>
    </svg>
  );
}
