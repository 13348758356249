import type { GenericModalProps } from '../../global/generic-modal';
import { GenericModal } from '../../global/generic-modal';
import type { RoomsCTAProps } from './rooms-cta';
import { RoomsCTA } from './rooms-cta';

export interface RoomVirtualTourModalProps extends GenericModalProps {
  assetUrl?: string;
  ctaData?: RoomsCTAProps;
}

export const RoomVirtualTourModal = ({
  assetUrl,
  ctaData,
  modalActive,
  setModalActive,
  dialogTrigger,
}: RoomVirtualTourModalProps) => {
  return (
    <GenericModal
      modalActive={modalActive}
      setModalActive={setModalActive}
      dialogTrigger={dialogTrigger}
      modalChild={
        <div className="flex flex-col p-2">
          <div className="bg-bg-primary mb-2 p-4 text-sm font-bold">
            Tour is a representation. Property details may change without notice.
          </div>
          <iframe
            data-testid="virtual-tour-iframe"
            className="mb-2 max-h-[80vh] max-w-[90vh] lg:w-[100vh] portrait:aspect-[2/3] landscape:mx-auto landscape:aspect-video"
            src={assetUrl}
            title="Virtual Tour"
          />
          <div className="flex justify-end">
            <RoomsCTA {...ctaData} />
          </div>
        </div>
      }
    />
  );
};
