import { useIsomorphicLayoutEffect } from 'usehooks-ts';
import { useState, useCallback } from 'react';

const emptyRect = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
} as DOMRect;

/**
 * This custom useResizeObserver hook fixes an issue with label alignment for the slider in Safari.
 * @param initialRect partial DOMRect to be used before measure. defaults to empty rect
 * @todo add debounce or raf throttle
 */
export const useResizeObserver = (initialRect: Partial<DOMRect> = emptyRect) => {
  const [element, setElement] = useState<HTMLElement | null>();
  const [rect, setRect] = useState<DOMRect>(initialRect as Readonly<DOMRect>);

  const ref = useCallback((e: HTMLElement | null) => {
    setElement(e);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const ro = new ResizeObserver((entries) => {
      setRect(entries[0] ? entries[0].contentRect : (initialRect as Readonly<DOMRect>));
    });
    if (element) {
      ro.observe(element);
    }
    return () => {
      element && ro.unobserve(element);
      setRect(emptyRect);
    };
  }, [element, initialRect]);

  return [ref, rect] as const;
};
