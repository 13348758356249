import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColor?: string;
}>(() => import('./dcawhhh/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = <HotelLogo className="w-32 antialiased" fillColor="#FFFFFF" />;

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 w-32 antialiased sm:mr-0" fillColor="#104C97" />
);
