export function Mail({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="3 4 18 15" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.0345,6.6285l-.0116-.0012L19,6.625H5l-.0233.0024-.0116.0011A1.3752,1.3752,0,0,0,3.625,8v8A1.377,1.377,0,0,0,5,17.375H19A1.377,1.377,0,0,0,20.375,16V8A1.3751,1.3751,0,0,0,19.0345,6.6285Zm-1.0488.7465L12,12.5056,6.0143,7.375ZM19.625,16a.6168.6168,0,0,1-.4951.5987l-3.8643-3.8643a.3756.3756,0,0,0-.5312.5312l3.3593,3.3594H5.9062l3.3594-3.3594a.3756.3756,0,0,0-.5312-.5312L4.87,16.5987A.6168.6168,0,0,1,4.375,16V8a.6171.6171,0,0,1,.5123-.6022l6.8686,5.8874c.0078.0067.0188.004.027.01a.3349.3349,0,0,0,.4342,0c.0082-.0059.0192-.0032.027-.01l6.8686-5.8874A.6171.6171,0,0,1,19.625,8Z" />
      </g>
    </svg>
  );
}
