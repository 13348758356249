import { useState } from 'react';
import type { HeroImageProps } from '../hero-image.types';

export function ImageChangeHandler({
  imageCarousel,
  showImageGallery,
}: {
  imageCarousel?: HeroImageProps['imageCarousel'];
  showImageGallery: () => void;
}) {
  const [slideIndex, setSlideIndex] = useState(0);
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  const [nextTimeout, setNextTimeout] = useState(null);
  const [nextImage, setNextImage] = useState({
    image: imageCarousel?.[slideIndex],
    ind: slideIndex,
  });

  const handleImageChange = (isNext?: boolean) => {
    const count = imageCarousel ? imageCarousel.length : 0;
    const getNextIndex = () => (slideIndex + 1 > count - 1 ? 0 : slideIndex + 1);
    const getPrevIndex = () => (slideIndex - 1 < 0 ? count - 1 : slideIndex - 1);
    if (!imageCarousel || imageCarousel.length <= 1) {
      return;
    }
    showImageGallery();
    if (isNext && !navAnimationDir) {
      setNavAnimationDir(true);
    } else if (!isNext && navAnimationDir) setNavAnimationDir(false);
    if (!nextTimeout) {
      const nextInd = isNext ? getNextIndex() : getPrevIndex();
      setNextImage({
        image: imageCarousel[nextInd],
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setSlideIndex(isNext ? getNextIndex() : getPrevIndex());
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      let skipTransInd =
        slideIndex === count - 1 ? 1 : slideIndex + 2 > count - 1 ? 0 : slideIndex + 2;
      if (!isNext)
        skipTransInd = slideIndex === 0 ? 1 : slideIndex - 2 < 0 ? count - 1 : slideIndex - 2;
      setSlideIndex(skipTransInd);
      setNextImage({
        image: imageCarousel[skipTransInd],
        ind: skipTransInd,
      });
    }
  };

  return {
    next: () => handleImageChange(true),
    prev: () => handleImageChange(),
    slideIndex,
    navAnimationDir,
    nextTimeout,
    nextImage,
  };
}
