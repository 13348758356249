import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string }>(() =>
  import('./msyrhwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-48 fill-black text-current antialiased md:w-52" />
);

export const hotelLogoFooter = <HotelLogo className="w-48 fill-white text-current antialiased" />;
