export function ArrowSmall({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M16.8574,9.0664A.8829.8829,0,0,0,16,8.625H8a.8829.8829,0,0,0-.8574.4414.8848.8848,0,0,0,.21.9424l3.7188,4.4629a1.18,1.18,0,0,0,1.8574,0l3.7188-4.4629A.8848.8848,0,0,0,16.8574,9.0664Zm-.7861.4619-3.7188,4.4629a.4413.4413,0,0,1-.705,0L7.9287,9.5283c-.0576-.0693-.084-.1211-.1045-.1289A.5.5,0,0,1,8,9.375h8a.8448.8448,0,0,0,.165.0029A.507.507,0,0,1,16.0713,9.5283Z" />
      </g>
    </svg>
  );
}
