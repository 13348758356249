import type { Side } from '@dx-ui/osc-popup';
import { PopupButton, PopupContent, Popup, usePopup } from '@dx-ui/osc-popup';
import { ControlClose } from '@dx-ui/osc-controls';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { Icon } from '@dx-ui/osc-icon';

export type InfoPopup = {
  label?: string;
  testId?: string;
  onClick?: () => void;
  side?: Side;
  isOpen?: boolean;
};

export const InfoPopupContent: React.FC<React.PropsWithChildren<InfoPopup>> = ({
  children,
  testId,
  label: labelProp,
  onClick,
  side,
}) => {
  const { closePopup } = usePopup();
  const { t } = useTranslation('osc-info-popup');
  const infoIconRef = useRef<HTMLButtonElement>(null);
  const label = labelProp || t('moreInfo');

  return (
    <>
      <PopupButton
        className="group btn btn-primary-text"
        data-testid={`popup-button${testId ? `-${testId}` : ''}`}
        ref={infoIconRef}
        onClick={onClick}
      >
        <span className="sr-only">{label}</span>
        <Icon
          name="info-circle"
          variant="regular"
          size="md"
          className="size-6 group-focus-within:hidden group-hover:hidden"
        />
        <Icon
          name="info-circle"
          variant="solid"
          size="md"
          className="hidden size-6 group-focus-within:block group-hover:block"
        />
      </PopupButton>
      <PopupContent
        className="w-fit max-w-prose"
        data-testid={`popup-content${testId ? `-${testId}` : ''}`}
        side={side}
      >
        <div className="flex justify-end">
          <ControlClose label={t('close', { label })} onClick={closePopup} size="sm" />
        </div>
        <div className="mt-1 text-left text-sm font-normal text-text">{children}</div>
      </PopupContent>
    </>
  );
};

export const InfoPopup: React.FC<React.PropsWithChildren<InfoPopup>> = ({
  testId,
  children,
  label,
  onClick,
  side,
  ...rest
}) => (
  <Popup {...rest}>
    <InfoPopupContent label={label} testId={testId} onClick={onClick} side={side}>
      {children}
    </InfoPopupContent>
  </Popup>
);

export default InfoPopup;
