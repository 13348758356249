import * as React from 'react';
import { get, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import cardValidator from 'card-validator';
import cx from 'classnames';

import type { FormInputBase } from './form.types';
import FormError from './form.error';
import FormLabel from './form.label';

type Validator = typeof cardValidator;

const formatCardNumber = (cardNumber: string, cardValidator: Validator) => {
  const cardData = cardValidator.number(cardNumber);
  if (!cardData.isPotentiallyValid || !cardData?.card) return cardNumber;
  const cardNumberArray = [...cardNumber];
  if (cardData?.isPotentiallyValid && cardData?.card?.type) {
    const validGapIndices = cardData?.card?.gaps?.filter(
      (gapIndex: number) => cardNumber?.length > gapIndex
    );
    validGapIndices?.forEach((gap: number, index: number) => {
      if (cardNumberArray[gap + index] !== ' ') {
        cardNumberArray.splice(gap + index, 0, ' ');
      }
    });
  }
  return cardNumberArray.join('').trim();
};

const FormCardNumberInput: React.FC<FormInputBase<React.InputHTMLAttributes<HTMLInputElement>>> = ({
  className,
  containerClassName,
  label,
  labelClassName,
  name,
  registerOptions,
  required = true,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const [cardType, setCardType] = React.useState<string | null>(null);
  const [t] = useTranslation('osc-form');
  const id = React.useId();
  const errorId = `input-error-${id}`;
  const errorMessage = get(errors, name);
  const MAX_NUMBER_OF_DIGITS = 19;
  const MAX_NUMBER_OF_GAPS = 3;
  return (
    <div className={containerClassName}>
      <FormLabel label={label} required={required} className={cx('self-start', labelClassName)}>
        <div
          className={cx(
            'form-input flex before:mr-3 before:inline-block before:h-auto before:w-9 before:bg-cover before:rtl:ml-3',
            className,
            {
              'form-error': !!errorMessage,
              'before:content-none': !cardType,
              'before:bg-card-amex': cardType === 'american-express',
              'before:bg-card-diners-club': cardType === 'diners-club',
              'before:bg-card-discover': cardType === 'discover' || cardType === 'elo',
              'before:bg-card-jcb': cardType === 'jcb',
              'before:bg-card-maestro': cardType === 'maestro',
              'before:bg-card-mastercard': cardType === 'mastercard',
              'before:bg-card-unionpay': cardType === 'unionpay',
              'before:bg-card-visa': cardType === 'visa',
            }
          )}
        >
          <input
            {...rest}
            aria-invalid={!!errorMessage}
            aria-describedby={errorId}
            autoComplete="cc-number"
            className="outline-none"
            inputMode="numeric"
            {...register(name, {
              onChange: ({ target: { value: cardNumber } }) => {
                setValue(name, formatCardNumber(cardNumber, cardValidator));
                setCardType(cardValidator.number(cardNumber)?.card?.type ?? null);
              },
              required: required ? t('invalidCardNumberError') : false,
              setValueAs: (value) => value?.replace(/\s/g, ''),
              validate: (cardNumber) =>
                !cardNumber ||
                cardValidator.number(cardNumber).isValid ||
                t('invalidCardNumberError'),
              ...registerOptions,
            })}
            required={required}
            size={MAX_NUMBER_OF_DIGITS + MAX_NUMBER_OF_GAPS}
          />
        </div>
      </FormLabel>
      {errorMessage ? <FormError id={errorId} error={errorMessage} /> : null}
    </div>
  );
};

export { FormCardNumberInput };
export default FormCardNumberInput;
