import { Icon } from './lib/osc-icon';
import type { IconNames } from './lib/osc-icon-map';

export * from './lib/osc-icon';
export * from './lib/osc-icon-map';
export * from './lib/osc-brand-icon-map';
export * from './lib/osc-amenity-icon-map';

export type IconName = (typeof IconNames)[number];

export default Icon;
