export const slugStaticMockProps = {
  dxGqlProps: {
    hotel: {
      roomTypes: [{ hotelCtyhocn: '', brandName: '' }],
      showMeetingsSimplified: false,
      meetings: {
        desc: null,
        eEventsUrl:
          'https://www3-stg.hilton.com/en_US/hi/doxch.htm?dst=https://ATG-HI/en/hi/hotels/search/groups/gm/index3.jhtml&ctyhocn=QUIHIHF',
        headline: null,
        hiltonLinkUrl: 'https://www.stg.hilton.com/en/events/attendee-website/',
        largestExhibitSpaceArea: 0,
        largestExhibitSpaceAreaFmt: '0.00',
        largestMeetingRoom: 8568,
        largestMeetingRoomArea: 8568,
        largestMeetingRoomAreaFmt: '8,568.00',
        largestMeetingRoomFmt: '8,568.00',
        numberOfMeetingRooms: 13,
        numberOfMeetingRoomsFmt: '13',
        rfpUrl:
          'https://hiltonuat3.developersync.com/guest-events/guest-events?tenant_config_id=51e0cf8d-e6c4-4727-8f54-42cde32e48e9&hotel_code=QUIHI',
        totalArea: 30746,
        totalAreaFmt: '30,746.00',
        totalExhibitSpaceArea: 0,
        totalExhibitSpaceAreaFmt: '0.00',
        totalMeetingRoomsArea: 30746,
        totalMeetingRoomsAreaFmt: '30,746.00',
        meetingRooms: [
          {
            _id: 'e4a0c733ca0bbc3ea59bb54451cdae32ce6d054bf56cd584983d89c8fd5b1459',
            area: 8568,
            areaFmt: '8,568.00',
            banquet: 840,
            boothEightByTen: 0,
            boothTenByTen: 0,
            configurationCapacities: {
              _id: 'b7dd5bb849fbf63997c3aff60a8fa33c8e02240e2de22fff02d7f5ecf3a170ca',
              banquet: 840,
              booth10x10: 0,
              booth8x10: 0,
              classroom: 850,
              conference: 0,
              hollowSquare: 850,
              reception: 1300,
              tabletopBooths: 0,
              theater: 1440,
              uShaped: 70,
            },
            capacityBanquet: 840,
            capacityConference: 0,
            capacityHollowSquare: 850,
            capacityReception: 1300,
            capacitySchoolRoom: 850,
            capacityTheatre: 1440,
            capacityUShaped: 70,
            ceilingHeight: 13,
            ceilingHeightFmt: '13.00',
            classroom: 850,
            conference: 0,
            desc: null,
            floorId: null,
            floorIdDesc: null,
            floorName: null,
            hollowSquare: 850,
            includeInTtlArea: true,
            isForExhibitUse: false,
            isOffSite: false,
            length: 72,
            lengthFmt: '72.00',
            locationDesc: 'Mezzanine',
            name: 'Los Shyris Grand Ballroom',
            reception: 1300,
            roomId: null,
            roomIdDesc: null,
            roomRank: null,
            sqrFootage: 8568,
            sqrFootageFmt: '8,568.00',
            tabletopBooths: 0,
            theater: 1440,
            typeDesc: 'Ballroom',
            uShaped: 70,
            width: 119,
            widthFmt: '119.00',
          },
          {
            _id: 'bc761b7e6aae93b2f752787fe2e11fdfe122c4089149f1a420b3cd43ad82eb35',
            area: 0,
            areaFmt: '0.00',
            banquet: 0,
            boothEightByTen: 5,
            boothTenByTen: 0,
            configurationCapacities: {
              _id: 'e9eebbe4bc63030f54c408eeb6bc4d45e7a6df5e367a5c68d255ff8e72a94d4e',
              banquet: 0,
              booth10x10: 0,
              booth8x10: 5,
              classroom: 0,
              conference: 0,
              hollowSquare: 0,
              reception: 150,
              tabletopBooths: 9,
              theater: 0,
              uShaped: 0,
            },
            capacityBanquet: 0,
            capacityConference: 0,
            capacityHollowSquare: 0,
            capacityReception: 150,
            capacitySchoolRoom: 0,
            capacityTheatre: 0,
            capacityUShaped: 0,
            ceilingHeight: 14,
            ceilingHeightFmt: '14.00',
            classroom: 0,
            conference: 0,
            desc: null,
            floorId: null,
            floorIdDesc: null,
            floorName: null,
            hollowSquare: 0,
            includeInTtlArea: false,
            isForExhibitUse: true,
            isOffSite: false,
            length: 0,
            lengthFmt: '0.00',
            locationDesc: 'Mezzanine',
            name: 'Foyer Los Shyris',
            reception: 150,
            roomId: null,
            roomIdDesc: null,
            roomRank: null,
            sqrFootage: 0,
            sqrFootageFmt: '0.00',
            tabletopBooths: 9,
            theater: 0,
            typeDesc: 'Exhibit',
            uShaped: 0,
            width: 0,
            widthFmt: '0.00',
          },
        ],
      },
      alerts: [],
      restaurantOverview: {
        restaurants: [
          {
            id: 'DIN1',
            awards: [],
            contactDetails: [
              {
                information: '',
                type: '',
              },
            ],
            images: [],
            expandedImage: {
              altText: 'Cafe Colon',
              url: 'https://www.stg.hilton.com/im/en/QUIHIHF/5154631/hilton-quito-cafe-colon-965392.jpg',
            },
            halfAndHalfImage: {
              altText: 'Cafe Colon',
              url: 'https://www.stg.hilton.com/im/en/QUIHIHF/5154631/hilton-quito-cafe-colon-965392.jpg',
            },
            overviewImage: {
              altText: 'Cafe Colon',
              variants: [
                {
                  size: 'sm',
                  url: 'https://www.stg.hilton.com/im/en/QUIHIHF/5154631/hilton-quito-cafe-colon-965392.jpg?impolicy=crop&cw=4500&ch=2578&gravity=NorthWest&xposition=0&yposition=210&rw=768&rh=440',
                },
              ],
            },
            attire: 'Casual',
            averagePrice: 20,
            breakfast: true,
            coverCharge: null,
            description:
              'Caf&#233; Colon Restaurant is your one-stop-shop for a first-class dining experience. Opened in 1968, the restaurant has garnered a reputation for its exemplary Ecuadorian cuisine and is popular with locals of all generations, many who come for the famous traditional Ecuadorian ceviche alone. With a buffet available at breakfast and lunch, and a menu throughout the day, the TripAdvisor Certificate of Excellence-winning restaurant also serves international classics, including charcuterie and grilled dishes. On Sundays an indulgent brunch runs until 4 pm.',
            dinner: true,
            headline: null,
            link: null,
            lunch: true,
            name_noTx: 'Cafe Colon Restaurant',
            onSite: true,
            operatingHours: [],
            remarks: 'Timing can be adapted to groups requests.',
          },
        ],
        desc: 'Bar La Pinta serves craft beers and casual dishes, and the loved-by-locals Caf? Colon offers Ecuadorian cuisine and Sunday brunch. Join us in Kioto Sushi Bar for Japanese cuisine or stop by Sal y Pimienta bakery, located next to the hotel, for all-day deli favorites.',
        headline: 'Dining & Drinks',
      },
      creditCardTypes: [],
      pets: {
        description:
          'Pets allowed, $90.00 non-refundable fee, 75 lbs maximum, Non refundable $90 USD pet fee required',
        kennelServiceDesc: 'Bed & Bowls limited',
        kennelServiceOffered: true,
        otherServiceDesc: 'Non refundable $90 USD pet fee required',
        otherServiceOffered: true,
        petCharge: 90,
        petChargeFmt: '$90.00',
        petChargeRefundable: false,
        petMaxSize: 'large',
        petMaxWeightFmt: '75 lbs',
        petsAllowed: true,
        servicePetsAllowed: true,
        servicePetsDesc:
          'When your reservation is submitted, the hotel will be notified that you are traveling with a service animal.',
      },
      overview: {
        nor1Enabled: true,
        resortFeeDisclosureDesc: null,
      },
      policy: {
        pets: {
          deposit: 90,
          depositFmt: '$90.00',
          description:
            'Pets allowed, $90.00 non-refundable fee, 75 lbs maximum, Non refundable $90 USD pet fee required',
          feeType: 'N',
          kennelText: 'Bed & Bowls limited',
          maxSize: 'large',
          maxWeight: '75',
          maxWeightFmt: '75 lbs',
          maxWeightUnit: 'lbs',
          policy: null,
          services: 'Non refundable $90 USD pet fee required',
        },
        fees: null,
        additionalPolicies: [
          {
            headline: 'Face Coverings',
            type: 'propertyPolicy',
            link: null,
            terms: {
              heading: [],
              description: [
                {
                  value:
                    'We ask that you follow all local guidelines and laws with respect to practicing social distancing and wearing a mask in public areas. Please check with local public health and government authorities regarding  guidelines or requirements that may be in place for the location of your stay.',
                },
              ],
            },
          },
        ],
      },
      policyOptions: [
        {
          name: 'cancellationPolicy',
          value: null,
          label: 'Cancellation',
          options: [
            {
              name: 'description',
              value:
                'Cancellation policies may vary depending on the rate or dates of your reservation. Please refer to your reservation confirmation to verify your cancellation policy. If you need further assistance, call the hotel directly or contact <a href=https://help.hilton.com/s/>customer service</a>. Alternatively, you can <a href=https://www.hilton.com/en/book/reservation/find/>cancel your reservation online</a>.',
              label: 'Description',
            },
          ],
        },
      ],
      safetyAndSecurity: {
        smokingPolicyDesc:
          'In case a guest smokes inside the rooms or into the building, we will apply the local law of $250 of penalty. Guests can smoke outside the property in public spaces only.',
      },
      foodAndBeverage: {
        hasFreeBreakfast: false,
        freeBreakfastMsg: null,
      },
      hotelAmenities: [
        {
          id: 'freeParking',
          name: 'Free parking',
        },
        {
          name: 'Wheelchair ramp for lobby/reception access',
        },
      ],
      nonAccessibleFeatures: [
        {
          name: 'Closed captioning on televisions or closed captioning decoders',
        },
        {
          name: 'TTY for guest use',
        },
        {
          name: 'Valet only parking',
        },
      ],
      associatedHotels: ['LASHHHH'],
      name: 'Hilton Colon Quito',
      brandCode: 'HI',
      totalRooms: 255,
      brand: {
        name: 'Hilton',
        formalName: 'Hilton Hotels & Resorts',
      },
      facilityOverview: {
        headline: 'Near old town Quito and El Ejido Park',
        shortDesc:
          'We’re across from El Ejido Park — home to a nightly craft market, and Casa de la Cultura Museum is streets away.  Experience local art, culture, and Quito old town, a UNESCO World Heritage Site peppered with colonial architecture, near our hotel. Perks include our spa, outdoor pool, and bonsai garden.',
      },
      images: {
        master: {
          url: 'https://www.stg.hilton.com/im/en/QUIHIHF/6130368/quihi-exterior.jpg',
        },
      },
      address: {
        addressFmt: 'Amazonas N 1914 Y Patria Avenida, Quito, Ecuador',
        addressLine1: 'Amazonas N 1914 Y Patria Avenida',
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        city: 'Quito',
        country: 'EC',
        countryName: 'Ecuador',
        postalCode: null,
        state: null,
        stateName: null,
      },
      registration: {
        checkinTime: '14:00',
        checkinTimeFmt: '2:00 PM',
        checkoutTime: '12:00',
        checkoutTimeFmt: '12:00 PM',
        earlyCheckinText:
          'Early check-in cannot be guaranteed. Contact the hotel to inquire about early check-in or late check-out.',
        earlyDepartureFee: 100,
        earlyDepartureFeeFmt: '$100.00',
        earlyDepartureFeeType: 'F',
        earlyDepartureText: 'It only applies for pre-paid reservations',
        eCheckin: false,
        lateCheckoutFee: 100,
        lateCheckoutFeeFmt: '$100.00',
        lateCheckoutText: 'The fee applies until 6pm, after that it charges the full rate.',
        minAgeToRegister: 18,
      },
      contactInfo: {
        emailAddress1: 'Ruchita.Pathak.contractor@hilton.com',
        faxNumber: '+593 2-256-3903',
        phoneNumber: '+593 2-382-8300',
      },
      localization: {
        coordinate: {
          latitude: -0.207925,
          longitude: -78.496486,
        },
        currency: {
          description: 'US Dollar',
        },
        gmtHours: -5,
        gmtHoursFmt: '-5.00',
        timeZone: 'GMT-05:00',
        currencyCode: 'USD',
      },
      display: {
        open: true,
        preOpenMsg: null,
        resEnabled: true,
        openDateFmt: '',
        resEnabledDate: '',
      },
      tripAdvisorLocationSummary: {
        rating: 4,
        ratingImageUrl:
          'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/4.0-26025-5.svg',
        numReviews: 1873,
        reviews: [
          {
            id: 906842249,
            lang: 'en',
            locationId: 301753,
            publishedDate: '2023-07-30T17:40:09-0400',
            rating: 3,
            helpfulVotes: 0,
            ratingImageUrl:
              'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s3.0-26025-5.svg',
            url: 'https://www.tripadvisor.com/ShowUserReviews-g294308-d301753-r906842249-Hilton_Colon_Quito-Quito_Pichincha_Province.html?m=26025#review906842249',
            text: 'First of all, the Hilton Colon - like most Hiltons outside the U.S. - is far superior to equivalent Hilton properties in the States. Relatively clean rooms (no stray hairs anywhere!), comfortable...',
            travelDate: '2023-07',
            user: {
              username: 'Jake K',
            },
            title: 'Average Hotel, but Outstanding for a Hilton',
          },
        ],
      },
    },
    brand: {
      footer: {
        sections: [
          {
            links: [
              {
                url: 'https://www.buyhiltongiftcards.com/',
                label: 'Hilton Gift Card',
              },
            ],
          },
          {
            links: [
              {
                url: 'https://www.hilton.com/en/p/global-privacy-statement/',
                label: 'Global Privacy Statement',
              },
            ],
          },
        ],
      },
    },
  },
  dxGqlPropsReduced: {
    hotel: {
      roomTypes: [],
      associatedHotels: null,
    },
  },
  wpProps: {
    allSettings: {
      generalSettingsUrl: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com',
      generalSettingsTitle: 'DX Curated Demo',
      generalSettingsDescription: 'Just another WordPress site',
    },
    page: {
      slug: 'sample-page-updated',
      title: 'Home',
      pageId: 2,
      isFrontPage: true,
      parent: null,
      metaDataFields: {
        fieldGroupName: 'metaDataFields',
        metaTitle: null,
        metaDescription: null,
        adobeDigitaldataOverwriteList: null,
        adobeDigitaldataOverwriteText: null,
        adobeDigitaldataOverwriteBrand: null,
        adobeDigitaldataOverwriteCtyhocn: null,
        adobeDigitaldataPageDetail1: null,
        adobeDigitaldataPageDetail2: null,
        adobeDigitaldataPageDetail3: null,
        openGraphDescription: 'og desc',
        openGraphTitle: 'og title',
        openGraphImage: null,
      },
      acfFlexible: {
        fieldGroupName: 'acfFlexible',
        flexibleComponents: [
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_RoomTypes',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_RoomTypes',
            ctyhocn: 'QUIHIHF',
          },
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_HotelAmenities',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_HotelAmenities',
            ctyhocn: 'QUIHIHF',
          },
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_HtmlMenu',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_HtmlMenu',
          },
          {
            __typename:
              'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid',
            fieldGroupName:
              'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid',
          },
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar',
          },
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_Offers',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_Offers',
          },
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_OffersCurated',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_OffersCurated',
          },
        ],
      },
    },
    propertyInfo: {
      propertyInfo: {
        liveUrl: null,
        brand: 'HI',
        ctyhocn: 'quihihf',
        faxNumber: null,
        openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
        phoneNumber: null,
        address: {
          addressCountry: null,
          addressLocality: null,
          addressRegion: null,
          postalCode: null,
          streetAddress: null,
        },
        geo: {
          latitude: null,
          longitude: null,
        },
      },
    },
    socialLink: {
      pageSlug: 'acf-options-social-links',
      pageTitle: 'Social Link',
      socialLinks: {
        fieldGroupName: 'socialLinks',
        facebookLink: 'https://www.facebook.com/hiltonnewsroom/',
        instagramLink: 'https://www.instagram.com/hiltonnewsroom/',
        twitterLink: 'https://twitter.com/hiltonnewsroom',
        linkedinLink: 'https://www.google.com',
      },
    },
    modalSettings: {
      modalSettings: {
        modalBackgroundColour: null,
        modalCloseButtonBackgroundColour: null,
        modalCloseButtonIconColour: null,
      },
    },
    mainNav: {
      edges: [
        {
          node: {
            label: 'Components 5',
            databaseId: 4588,
            path: '/components/',
            url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/components/',
            connectedNode: {
              node: {
                databaseId: 89,
              },
            },
            childItems: {
              edges: [
                {
                  node: {
                    databaseId: 4810,
                    label: '[Component] – Hotel Title',
                    path: '/component-hotel-title/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/component-hotel-title/',
                    connectedNode: {
                      node: {
                        databaseId: 3246,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 4160,
                    label: '[Component] – Event Calendar',
                    path: '/component-event-calendar/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/component-event-calendar/',
                    connectedNode: {
                      node: {
                        databaseId: 162,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 4589,
                    label: '[Component] – Meeting Capacity Charts',
                    path: '/component-meeting-capacity-charts/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/component-meeting-capacity-charts/',
                    connectedNode: {
                      node: {
                        databaseId: 4471,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 5462,
                    label: '[Component] – Site Map',
                    path: '/component-site-map/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/component-site-map/',
                    connectedNode: {
                      node: {
                        databaseId: 5460,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 290,
                    label: '[Component] – Tabbed Table Data',
                    path: '/component-tabbed-table-data/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/component-tabbed-table-data/',
                    connectedNode: {
                      node: {
                        databaseId: 279,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 649,
                    label: '[Component] – Offers',
                    path: '/offers/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/offers/',
                    connectedNode: {
                      node: {
                        databaseId: 647,
                      },
                    },
                    childItems: {
                      edges: [],
                    },
                  },
                },
                {
                  node: {
                    databaseId: 5738,
                    label: 'Parent Page Test',
                    path: '/parent-page-test/',
                    url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/parent-page-test/',
                    connectedNode: {
                      node: {
                        databaseId: 5590,
                      },
                    },
                    childItems: {
                      edges: [
                        {
                          node: {
                            databaseId: 5739,
                            label: 'Child Page Test',
                            path: '/parent-page-test/child-page-test/',
                            url: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com/parent-page-test/child-page-test/',
                            connectedNode: {
                              node: {
                                databaseId: 5736,
                              },
                            },
                          },
                        },
                      ],
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
    iconNav: null,
    languageNavStyles: {
      name: 'Language Menu',
      languageSelector: {
        languageNavAccentColour: '#b6cadb',
        languageNavTextColour: '#4c8299',
      },
    },
    footerNav: {
      edges: [
        {
          node: {
            label: '简体中文',
            databaseId: 573,
            path: 'https://www.hilton.com.cn/zh-cn/hotel/Hawaii/hilton-waikoloa-village-KOAHWHH/',
            menu: {
              node: {
                locations: ['LANGUAGE_NAVIGATION'],
                count: 9,
              },
            },
          },
        },
      ],
    },
    allWPPages: {
      nodes: [
        {
          uri: '/component-spacer/?lang=ar',
          title: '[Component] – Spacer',
        },
        {
          uri: '/component-event-calendar/',
          title: '[Component] – Event Calendar',
        },
        {
          uri: '/component-two-column-hover-tiles/',
          title: '[Component] – Two Column Hover Tiles',
        },
        {
          uri: '/component-two-column-hover-tiles/?lang=es',
          title: '[Component] – Two Column Hover Tiles',
        },
        {
          uri: '/component-two-column-hover-tiles/?lang=ar',
          title: '[Component] – Two Column Hover Tiles',
        },
        {
          uri: '/component-media-copy-carousel/',
          title: '[Component] – Media & Copy Carousel',
        },
        {
          uri: '/component-media-copy-carousel/?lang=es',
          title: '[Component] – Media & Copy Carousel',
        },
        {
          uri: '/component-media-copy-carousel/?lang=ar',
          title: '[Component] – Media & Copy Carousel',
        },
        {
          uri: '/component-media-copy-overlay/',
          title: '[Component] – Media & Copy Overlay',
        },
        {
          uri: '/component-media-copy-overlay/?lang=es',
          title: '[Component] – Media & Copy Overlay',
        },
        {
          uri: '/component-media-copy-overlay/?lang=ar',
          title: '[Component] – Media & Copy Overlay',
        },
        {
          uri: '/component-contact-bar/',
          title: '[Component] – Contact Bar',
        },
        {
          uri: '/',
          title: 'Home',
        },
        {
          uri: '/sample-page/?lang=ar',
          title: 'Home Arabic',
        },
      ],
    },
  },
  wpPropsReduced: {
    allSettings: {
      generalSettingsUrl: 'https://dx-hilton-demo-site-cms.dig.hhc.hilton.com',
      generalSettingsTitle: 'DX Curated Demo',
      generalSettingsDescription: 'Just another WordPress site',
    },
    page: {
      slug: 'sample-page-updated',
      title: 'Home',
      pageId: 2,
      isFrontPage: true,
      parent: null,
      translations: [{ trans: { locale: 'de' } }],
      metaDataFields: {
        fieldGroupName: 'metaDataFields',
        metaTitle: null,
        metaDescription: null,
        adobeDigitaldataOverwriteList: null,
        adobeDigitaldataOverwriteText: null,
        adobeDigitaldataOverwriteBrand: null,
        adobeDigitaldataOverwriteCtyhocn: null,
        adobeDigitaldataPageDetail1: null,
        adobeDigitaldataPageDetail2: null,
        adobeDigitaldataPageDetail3: null,
        openGraphDescription: 'og desc',
        openGraphTitle: 'og title',
        openGraphImage: null,
        ctyhocnOverride: 'chiwawa',
      },
      acfFlexible: {
        fieldGroupName: 'acfFlexible',
        flexibleComponents: [
          {
            __typename: 'Page_Acfflexible_FlexibleComponents_HotelAmenities',
            fieldGroupName: 'Page_Acfflexible_FlexibleComponents_HotelAmenities',
            ctyhocn: 'QUIHIHF',
          },
        ],
      },
    },
    propertyInfo: {
      propertyInfo: {
        liveUrl: null,
        brand: 'HI',
        ctyhocn: 'quihihf',
        faxNumber: null,
        openingHours: 'Mo, Tu, We, Th, Fr, Sa, Su',
        phoneNumber: null,
        address: {
          addressCountry: null,
          addressLocality: null,
          addressRegion: null,
          postalCode: null,
          streetAddress: null,
        },
        geo: {
          latitude: null,
          longitude: null,
        },
      },
    },
    mainNav: {
      edges: [],
    },
    iconNav: {
      edges: [],
    },
    languageNavStyles: {
      name: 'Language Menu',
      languageSelector: {
        languageNavAccentColour: '#b6cadb',
        languageNavTextColour: '#4c8299',
      },
    },
    allWPPages: {
      nodes: [
        {
          uri: '/',
          title: 'Home',
        },
        {
          uri: '/sample-page/?lang=ar',
          title: 'Home Arabic',
        },
      ],
    },
  },
};
