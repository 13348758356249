import { useState } from 'react';
import { CroppedImage } from '../cropped-image/cropped-image';
import { ArrowHeadCircle, ArrowHead } from '@curated-property/icon-list';
import { useAdobeCarouselEvent } from '../global/adobe-analytics';
import cx from 'classnames';

interface StyleOpts {
  uiControlsColour?: string;
  uiBackgroundColour?: string;
  paginationBackgroundColour?: string;
  paginationTextColour?: string;
  paginationSeperatorColour?: string;
  sliderAnimations?: string;
  zoomOnHover?: boolean;
}
interface Props {
  images: { url: string; alt: string }[];
  controlLayout?: '' | 'overlayed' | 'compact';
  compactWidth?: string;
  objectPosition?: string;
  sectionHeading?: string;
  objectFit?: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none' | 'initial' | 'inherit';
  imageSize?: { h: number; w: number };
  styleOptions: StyleOpts;
}
export function ImageSlider({
  images,
  controlLayout,
  compactWidth,
  objectFit,
  objectPosition,
  imageSize,
  sectionHeading,
  styleOptions,
}: Props) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const currentImage = images[currentIndex];
  const [nextImage, setNextImage] = useState({
    image: images[currentIndex],
    ind: currentIndex,
  });
  const [nextTimeout, setNextTimeout] = useState<NodeJS.Timeout | null>(null);
  const [animationCoolDown, setAnimationCooldown] = useState<NodeJS.Timeout | null>(null);
  const slideAnimation: string = styleOptions?.sliderAnimations || 'fade';
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  const count = images ? images.length : 0;
  const getNextIndex = () => (currentIndex + 1 > count - 1 ? 0 : currentIndex + 1);
  const getPrevIndex = () => (currentIndex - 1 < 0 ? count - 1 : currentIndex - 1);
  const controlsProps = {
    currentIndex: nextImage?.ind,
    images,
    prev,
    next,
    compactWidth,
    objectFit,
    styleOptions,
  };
  const baseUrl = 'https://assets.hiltonstatic.com/';
  let controls;
  switch (controlLayout) {
    case 'compact':
      controls = <ControlsCompact {...controlsProps} />;
      break;
    default:
      controls = <ControlsOverlayed {...controlsProps} />;
  }

  function next() {
    if (!navAnimationDir) setNavAnimationDir(true);
    const coolDown = setTimeout(() => {
      setAnimationCooldown(null);
    }, 600);
    setAnimationCooldown(coolDown);
    if (!nextTimeout) {
      const nextInd = getNextIndex();
      setNextImage({
        image: images[nextInd],
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(nextInd);
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === count - 1 ? 1 : currentIndex + 2 > count - 1 ? 0 : currentIndex + 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: images[skipTransInd],
        ind: skipTransInd,
      });
    }
  }

  function prev() {
    if (navAnimationDir) setNavAnimationDir(false);
    const coolDown = setTimeout(() => {
      setAnimationCooldown(null);
    }, 600);
    setAnimationCooldown(coolDown);
    if (!nextTimeout) {
      const nextInd = getPrevIndex();
      setNextImage({
        image: images[nextInd],
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(nextInd);
          setNextTimeout(null);
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === 0 ? 1 : currentIndex - 2 < 0 ? count - 1 : currentIndex - 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: images[skipTransInd],
        ind: skipTransInd,
      });
    }
  }

  useAdobeCarouselEvent({
    sectionHeading,
    currentIndex: currentIndex + 1,
    totalCount: images?.length,
  });

  const getPrevSlideInd = () => {
    const ind = slideAnimation === 'slide-ltr' && navAnimationDir ? getNextIndex() : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  if (images && images.length) {
    return (
      <div
        data-testid="imageSlider"
        data-image-count={images.length}
        className={slideAnimation === 'fade' ? 'relative' : 'h-full'}
      >
        {currentImage?.url.startsWith(baseUrl) && slideAnimation !== 'fade' ? (
          <div className="relative flex size-full flex-row justify-center overflow-hidden">
            <div
              className={cx('absolute size-full transition-all duration-500 ease-in-out', {
                'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
                '-translate-x-full': !nextTimeout || slideAnimation === 'slide-rtl',
                'transition-none': !nextTimeout,
              })}
            >
              <CroppedImage
                src={images[getPrevSlideInd()]?.url}
                alt={`${images[getPrevSlideInd()]?.alt}-previous-transition`}
                height={imageSize ? imageSize.h : 650}
                width={imageSize ? imageSize.w : 900}
                objectFit={objectFit ?? 'cover'}
                objectPosition={objectPosition || ''}
                layout="responsive"
                draggable={false}
              />
            </div>
            <div
              className={cx('absolute order-3 size-full transition-all duration-500 ease-in-out', {
                'translate-x-0':
                  (slideAnimation === 'slide-rtl' && nextTimeout) ||
                  (nextTimeout && slideAnimation !== 'slide-ltr' && navAnimationDir),
                'translate-x-full':
                  !nextTimeout ||
                  (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
                  slideAnimation === 'slide-ltr',
                'transition-none': !nextTimeout,
              })}
            >
              <CroppedImage
                src={nextImage?.image?.url}
                alt={`${nextImage?.image?.alt}-next-transition`}
                height={imageSize ? imageSize.h : 650}
                width={imageSize ? imageSize.w : 900}
                objectFit={objectFit ?? 'cover'}
                objectPosition={objectPosition || ''}
                layout="responsive"
                draggable={false}
              />
            </div>
            <div
              className={cx(
                'order-2 w-full transition-all duration-500 ease-in-out',
                {
                  'transition-none': !nextTimeout && animationCoolDown,
                  'hover:scale-125': styleOptions?.zoomOnHover && !animationCoolDown,
                },
                slideStyles
              )}
            >
              <CroppedImage
                src={currentImage?.url}
                alt={currentImage?.alt}
                height={imageSize ? imageSize.h : 650}
                width={imageSize ? imageSize.w : 900}
                objectFit={objectFit ?? 'cover'}
                objectPosition={objectPosition || ''}
                layout="responsive"
                draggable={false}
              />
            </div>
          </div>
        ) : currentImage?.url.startsWith(baseUrl) ? (
          <div className="relative">
            <CroppedImage
              src={nextImage?.image?.url}
              alt={`${nextImage?.image?.alt}-transition`}
              height={imageSize ? imageSize.h : 650}
              width={imageSize ? imageSize.w : 900}
              objectFit={objectFit ?? 'cover'}
              objectPosition={objectPosition || ''}
              layout="responsive"
              draggable={false}
              className="absolute block transition-all duration-500 ease-in-out"
            />
            <CroppedImage
              src={currentImage?.url}
              alt={currentImage?.alt}
              height={imageSize ? imageSize.h : 650}
              width={imageSize ? imageSize.w : 900}
              objectFit={objectFit ?? 'cover'}
              objectPosition={objectPosition || ''}
              layout="fill"
              draggable={false}
              className={cx('relative block transition-all duration-500 ease-in-out', {
                'opacity-0': nextTimeout,
                'opacity-100': !nextTimeout,
                'hover:scale-125': styleOptions?.zoomOnHover && !animationCoolDown,
              })}
            />
          </div>
        ) : (
          <div
            className={cx({
              'flex flex-row overflow-hidden justify-center': slideAnimation !== 'fade',
            })}
          >
            <img
              src={images[getPrevSlideInd()]?.url}
              alt={`${images[getPrevSlideInd()]?.alt}-previous-transition`}
              className={cx(
                'w-fit transition-all duration-500 ease-in-out',
                {
                  hidden: slideAnimation === 'fade',
                },
                slideStyles
              )}
            />
            <img
              src={nextImage?.image?.url}
              alt={`${nextImage?.image?.alt}-next-transition`}
              className={cx(
                'order-3 w-fit transition-all duration-500 ease-in-out',
                {
                  absolute: slideAnimation === 'fade',
                  'transition-none': !nextTimeout && slideAnimation !== 'fade',
                },
                slideStyles
              )}
            />
            <img
              src={currentImage?.url}
              alt={currentImage?.alt}
              className={cx(
                'relative w-fit transition-all duration-500 ease-in-out',
                {
                  'opacity-0': nextTimeout && slideAnimation === 'fade',
                  'opacity-100': !nextTimeout,
                  'order-2': slideAnimation !== 'fade',
                  'transition-none': !nextTimeout && slideAnimation !== 'fade',
                  'hover:scale-125': styleOptions?.zoomOnHover && !animationCoolDown,
                },
                slideStyles
              )}
            />
          </div>
        )}
        {images?.length > 1 && controls}
      </div>
    );
  }
}

interface ControlsProps {
  currentIndex: number;
  images: Array<{
    url?: string;
    alt?: string;
  }>;
  prev?: () => void;
  next?: () => void;
  compactWidth?: string;
  styleOptions: StyleOpts;
}

function ControlsOverlayed({ currentIndex, images, prev, next, styleOptions }: ControlsProps) {
  return (
    <>
      <ol
        data-testid="controls-overlayed"
        aria-live="polite"
        aria-label={`${currentIndex + 1} of ${images?.length}`}
        className="bg-text text-bg absolute bottom-0 right-0 flex w-20 justify-center p-3 text-lg"
        style={{
          backgroundColor: styleOptions?.paginationBackgroundColour,
          margin: 0,
          paddingLeft: '12px',
        }}
      >
        <li className="list-none">
          <span
            data-testid="currentIndex"
            aria-hidden="true"
            style={{
              color: styleOptions?.paginationTextColour,
              borderColor: styleOptions?.paginationSeperatorColour,
            }}
          >
            {currentIndex + 1}
          </span>
        </li>
        <li className="list-none">
          <span className="px-1" style={{ color: styleOptions?.paginationSeperatorColour }}>
            /
          </span>
        </li>
        <li className="list-none">
          <span
            data-testid="slideCount"
            aria-hidden="true"
            style={{
              color: styleOptions?.paginationTextColour,
            }}
          >
            {images.length}
          </span>
        </li>
      </ol>
      <button
        data-element-id="image-carousel-control"
        aria-label="Previous image"
        onClick={prev}
        className="absolute left-2 top-1/2 size-12 -translate-y-1/2"
      >
        <ArrowHeadCircle
          className="rotate-90"
          bgColor={styleOptions?.uiBackgroundColour}
          arrowColor={styleOptions?.uiControlsColour}
          fillBg={true}
        />
      </button>
      <button
        data-element-id="image-carousel-control"
        aria-label="Next image"
        onClick={next}
        className="absolute right-2 top-1/2 size-12 -translate-y-1/2"
      >
        <ArrowHeadCircle
          className="-rotate-90"
          bgColor={styleOptions?.uiBackgroundColour}
          arrowColor={styleOptions?.uiControlsColour}
          fillBg={true}
        />
      </button>
    </>
  );
}

function ControlsCompact({
  currentIndex,
  images,
  prev,
  next,
  compactWidth,
  styleOptions,
}: ControlsProps) {
  return (
    <ol
      data-testid="controls-compact"
      data-element-id="controls-compact"
      className={`bg-primary text-bg absolute bottom-0 flex justify-end px-2 ${
        compactWidth ?? 'w-full'
      }`}
      style={{
        backgroundColor: styleOptions?.uiBackgroundColour,
        margin: 0,
        paddingLeft: '8px',
      }}
      aria-live="polite"
      aria-label={`${currentIndex + 1} of ${images.length}`}
    >
      <li className="flex">
        <button aria-label="Previous image" onClick={prev}>
          <ArrowHead
            className="text-bg h-8 rotate-90 fill-current"
            color={styleOptions?.uiControlsColour}
          />
        </button>
      </li>
      <li className="flex">
        <div
          className="p-2 text-lg"
          style={{
            color: styleOptions?.paginationTextColour ?? styleOptions?.uiControlsColour,
            background: styleOptions?.paginationBackgroundColour,
          }}
        >
          <span data-testid="currentIndex" aria-hidden={true}>
            {currentIndex + 1}
          </span>
          <span data-testid="slideCount" style={{ color: styleOptions?.paginationSeperatorColour }}>
            {' '}
            /{' '}
          </span>
          <span>{images.length}</span>
        </div>
      </li>
      <li className="flex">
        <button aria-label="Next image" onClick={next}>
          <ArrowHead
            className="text-bg h-8 -rotate-90 fill-current"
            color={styleOptions?.uiControlsColour}
          />
        </button>
      </li>
    </ol>
  );
}
