import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./mbjrhhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-24 w-28 fill-current text-bg antialiased sm:w-36 md:mr-5 md:w-40" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-3 h-24 w-32 fill-current text-bg antialiased md:mr-0" />
);
