export function ArrowCircle({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16.4443,11.625H8.4611L11.377,8.71a.3757.3757,0,1,0-.5313-.5313L7.29,11.7344l-.0012.0031a.3728.3728,0,0,0,0,.525l.0012.0031,3.5557,3.5557a.3757.3757,0,1,0,.5313-.5313L8.4611,12.375h7.9832a.375.375,0,0,0,0-.75Z" />
          <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
        </g>
      </g>
    </svg>
  );
}
