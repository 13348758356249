import { forwardRef } from 'react';

const AccountIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  ({ width = 20, height = 20, ...rest }, ref) => (
    <svg
      ref={ref}
      role="img"
      aria-hidden
      height={height}
      width={width}
      viewBox="2 3 20 17"
      {...rest}
    >
      <g fill="#666" className="fill-current">
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
        <path d="M0 0h24v24H0z" fill="none" />
      </g>
    </svg>
  )
);

AccountIcon.displayName = 'AccountIcon';

export { AccountIcon };
export default AccountIcon;
