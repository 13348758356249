export function Gift({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19,6.625H14.9907A1.8573,1.8573,0,0,0,15.375,5.5,1.8694,1.8694,0,0,0,12,4.3863,1.868,1.868,0,1,0,9.0093,6.625H5A.3751.3751,0,0,0,4.625,7V9A2.3777,2.3777,0,0,0,7,11.375h3.125v6.25H6.375V12.5a.375.375,0,0,0-.75,0V18A.3751.3751,0,0,0,6,18.375H18A.3751.3751,0,0,0,18.375,18V12.5a.375.375,0,0,0-.75,0v5.125h-3.75v-6.25H17A2.3777,2.3777,0,0,0,19.375,9V7A.3751.3751,0,0,0,19,6.625Zm-8.875,4H7A1.6262,1.6262,0,0,1,5.375,9V7.375h4.75ZM9.375,5.5a1.125,1.125,0,0,1,2.25,0V6.625H10.5A1.1266,1.1266,0,0,1,9.375,5.5Zm3.75,12.125h-2.25v-6.25h2.25Zm0-7h-2.25V7.375h2.25Zm.375-4H12.375V5.5A1.125,1.125,0,1,1,13.5,6.625ZM18.625,9A1.6262,1.6262,0,0,1,17,10.625H13.875V7.375h4.75Z" />
      </g>
    </svg>
  );
}
