import type { VideoPlayerProps } from '../hooks/use-video-player';
import type { Track } from '../video-player.controls';

export function mapPropsToTracks(props: VideoPlayerProps) {
  return [
    {
      ...props,
      captionTracks: dedupeTracks(props.captionTracks || []),
      transcriptTracks: dedupeTracks(props.transcriptTracks || []),
    },
    ...(props.audioTracks?.map((audioTrack) => ({
      ...audioTrack,
      captionTracks: dedupeTracks(audioTrack.captionTracks || []),
      transcriptTracks: dedupeTracks(audioTrack.transcriptTracks || []),
    })) || []),
  ];
}

export function dedupeTracks(tracks: Track[]) {
  const tracksMap = new Map([...tracks].map((item) => [Object.values(item).join('-'), item]));
  return Array.from(tracksMap.values());
}

export function getTrackId(track: Track) {
  return [track.language, track.label, track.url]
    .filter(Boolean)
    .join('-')
    .replace(/ /g, '')
    .toLowerCase();
}

export const trackKind = {
  audioTrack: 'metadata',
  captionTrack: 'subtitles',
  transcriptTrack: 'metadata',
} as const satisfies Record<Track['type'], TextTrackKind>;
