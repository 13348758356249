import gql from 'graphql-tag-transform.macro';
import { eventsCalendarSettings } from './common-vars';

export const EventsCalendarRegularAndSpecialEventsGrid = gql`
fragment EventsCalendarRegularAndSpecialEventsGrid on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid {
        fieldGroupName
        eventsCalendarComponentSettings {
          fieldGroupName
          eventsCalendarSettings {
            fieldGroupName
            showHide
            ${eventsCalendarSettings}
          }
        }
      }
}
`;
