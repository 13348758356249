export function ArrowRight({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 79.85 148.29" style={{ fill: fillColor }}>
      <g data-name="arrow-right">
        <path
          d="M79.85 74.14a5.73 5.73 0 01-1.67 4L9.74 146.62a5.71 5.71 0 01-8.07-8.08l64.41-64.4L1.67 9.74a5.71 5.71 0 018.07-8.07l68.44 68.44a5.69 5.69 0 011.67 4"
          data-name="Arrow Right"
        />
      </g>
    </svg>
  );
}
