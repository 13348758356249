import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings, buttonClone } from './common-vars';

export const TwoColumnHoverTiles = gql`
fragment TwoColumnHoverTiles on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_FourStaggeredTiles {
        fieldGroupName
        repeater {
          copy
          tileSize
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          ${buttonClone}
        }
        fourStaggeredTilesComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
}
`;
