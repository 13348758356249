import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksTwoVerticalInterface } from './interfaces';

export function MediaBlocksTwoVertical(props: MediaBlocksTwoVerticalInterface) {
  return (
    <div className="grid size-full grid-cols-2" data-testid="media-blocks-two-vert">
      <div
        className="relative flex items-center justify-center overflow-hidden bg-blue-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderRightWidth: `${props?.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageLeft?.sourceUrl}
          alt={props?.imageLeft?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageLeft?.sourceUrl,
              crop: props?.imageLeftCrop?.imageCrop12AddCrop && true,
              cropType: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
      <div
        className="relative flex items-center justify-center overflow-hidden bg-red-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderLeftWidth: `${props?.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageRight?.sourceUrl}
          alt={props?.imageRight?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageRight?.sourceUrl,
              crop: props?.imageRightCrop?.imageCrop12AddCrop && true,
              cropType: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
    </div>
  );
}
