export function Clock({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.0625C4.02823 0.0625 0 4.09073 0 9.0625C0 14.0343 4.02823 18.0625 9 18.0625C13.9718 18.0625 18 14.0343 18 9.0625C18 4.09073 13.9718 0.0625 9 0.0625ZM12.3387 11.4214L11.6129 12.3286C11.504 12.4738 11.3589 12.5827 11.1411 12.5827C11.0323 12.5827 10.8871 12.5101 10.8145 12.4375L8.38306 10.623C8.02016 10.369 7.83871 9.96976 7.83871 9.49798V3.83669C7.83871 3.54637 8.09274 3.25605 8.41935 3.25605H9.58064C9.87097 3.25605 10.1613 3.54637 10.1613 3.83669V9.0625L12.2661 10.623C12.375 10.7319 12.4839 10.877 12.4839 11.0585C12.4839 11.2036 12.4113 11.3488 12.3387 11.4214Z"
        fill={fillColor}
      />
    </svg>
  );
}
