import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings } from './common-vars';

export const TwoColumnCompare = gql`
fragment TwoColumnCompare on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_TwoColumnCompare {
    repeater {
          copy
          title
          title_noTx: title
          subtitle
          subtitle_noTx: subtitle
          backgroundPosition
          backgroundRepeat
          backgroundSize
          backgroundImage {
            altText
            sourceUrl
          }
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
          image {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          logo {
            altText
            sourceUrl
            mediaDetails {
              height
              width
            }
          }
        }
        twoColumnCompareComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
}
    }
}
`;
