import { useEffect, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'next-i18next';
import { PopupContent } from '../../includes/popover-content';
import type { Criteria } from '../buildFilters';
import cx from 'classnames';
import { CloseIcon } from '@curated-property/icons';

interface FilterFlyoutProps {
  label: string;
  items: Criteria[];
  onChange: (item: string | number | boolean, value: boolean) => void;
  className?: string;
  checkedItems: (string | number | boolean)[];
  labelButtonID: string;
  labelColor?: string;
}

export function FilterFlyout({
  label,
  items,
  onChange,
  className,
  checkedItems,
  labelButtonID,
  labelColor,
}: FilterFlyoutProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [currentCheckbox, setCurrentCheckbox] = useState('');

  function setNextFocus(id) {
    if (id) {
      document.getElementById(id).focus();
    }
  }
  useEffect(() => {
    function nextInput(dir) {
      const itemIdArray = [];
      const items = document.querySelectorAll('[data-testid="popoverContent"] .form-checkbox');

      for (let i = 0; i < items.length; i++) {
        if (items[i].id) {
          itemIdArray.push(items[i].id);
        }
      }
      const place = itemIdArray.indexOf(currentCheckbox);

      if (dir === 'down') {
        place + 1 > itemIdArray.length - 1
          ? setNextFocus(itemIdArray[0])
          : setNextFocus(itemIdArray[place + 1]);
      } else if (dir === 'up') {
        place === 0
          ? setNextFocus(itemIdArray[itemIdArray.length - 1])
          : setNextFocus(itemIdArray[place - 1]);
      }
    }
    function modalKeypress(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('down');
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('down');
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('up');
        }
      }
    }

    if (open === true) {
      window.addEventListener('keydown', modalKeypress);
      return () => {
        window.removeEventListener('keydown', modalKeypress);
      };
    }
  }, [open, currentCheckbox]);

  const { t } = useTranslation();
  if (!items.length || items.length === 1) {
    return null;
  }

  const gridCols = (numFilters: number): string => {
    return numFilters <= 1
      ? 'lg:grid-cols-1'
      : numFilters === 2
      ? 'lg:grid-cols-2'
      : numFilters === 3
      ? 'lg:grid-cols-3'
      : 'lg:grid-cols-4';
  };
  const gridColClass = gridCols(items?.filter((f: Criteria) => f?.label)?.length);

  return (
    <Popover.Root>
      <div
        className={className}
        data-testid="filterFlyout"
        style={{
          color: labelColor || undefined,
        }}
      >
        <div className="filter-title mb-1 text-sm">
          {t(`roomFilters.header.${label}`) !== `roomFilters.header.${label}`
            ? t(`roomFilters.header.${label}`)
            : label}
        </div>
        <Popover.Trigger asChild>
          <button
            className="form-select w-32 truncate text-left"
            ref={buttonRef}
            onClick={() => setOpen((s) => !s)}
            aria-label={t('filtersFor', { filter: label })}
            aria-expanded={open}
            id={labelButtonID}
            data-cy="flyoutBtn"
            data-testid={labelButtonID}
          >
            {checkedItems?.length === 0
              ? t('any')
              : checkedItems?.length === 1
              ? items.find((i) => i.value === checkedItems?.[0])?.label
              : t('multiple')}
          </button>
        </Popover.Trigger>
        {open && (
          <PopupContent setOpen={setOpen} className={`${labelButtonID}-flyout`}>
            <div className="p-5">
              <div className="mb-4 flex border-b pb-2">
                <header className="font-headline text-lg font-bold">
                  {t(`roomFilters.header.${label}`) !== `roomFilters.header.${label}`
                    ? t(`roomFilters.header.${label}`)
                    : label}
                </header>
              </div>
              <div
                role="application"
                className={`grid gap-4 ${gridColClass} flex flex-wrap lg:px-4`}
              >
                {items.map((item, i) => {
                  if (item.label && item.value) {
                    const idName = item.label.replace(/\s/g, '') + i.toString();
                    return (
                      <label className="label label-inline text-sm font-normal" key={idName}>
                        <input
                          id={idName}
                          key={idName}
                          data-testid={`${labelButtonID}-${i}`}
                          type={item?.type || 'checkbox'}
                          aria-checked={checkedItems.includes(item.value)}
                          checked={checkedItems.includes(item.value)}
                          className="form-checkbox mr-2"
                          onChange={(e) => {
                            onChange(item.value, e.target.checked);
                          }}
                          onFocus={() => {
                            setCurrentCheckbox(idName);
                          }}
                        />
                        {t(`roomFilters.${labelButtonID}.${item.label}`) !==
                        `roomFilters.${labelButtonID}.${item.label}`
                          ? t(`roomFilters.${labelButtonID}.${item.label}`)
                          : item.label}
                      </label>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </PopupContent>
        )}
      </div>
    </Popover.Root>
  );
}

interface FilterCheckboxProps {
  label: string;
  items: Criteria[];
  onChange: (item: string | number | boolean, value: boolean) => void;
  className?: string;
  checkedItems: (string | number | boolean)[];
  labelButtonID: string;
  labelColor?: string;
}

export function FilterCheckbox({
  label,
  items,
  onChange,
  className,
  checkedItems,
  labelButtonID,
  labelColor,
}: FilterCheckboxProps) {
  const { t } = useTranslation();
  if (!items.length || items.length === 1) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        color: labelColor || undefined,
      }}
    >
      <div className="filter-title mb-1 text-sm">{label}</div>
      {items.map((item, key) => {
        if (item.label && item.value) {
          return (
            <label className="label label-inline-reverse h-10 lg:pr-4" key={item.label}>
              <span className="filter-title text-sm font-normal">{t('yes')}</span>
              <input
                data-testid={`${labelButtonID}-${key}`}
                type={item?.type || 'checkbox'}
                checked={checkedItems.includes(item.value)}
                className="form-checkbox mr-2"
                aria-label={item.label}
                onChange={(e) => {
                  onChange(item.value, e.target.checked);
                }}
              />
            </label>
          );
        }
        return null;
      })}
    </div>
  );
}

interface FilterTagProps {
  className?: string;
  selectedFilters: {
    value?: Criteria[];
    label: string;
    remove: () => void;
  }[];
  clearAll: () => void;
  clearPriceSort?: () => void;
  clearShowerState?: () => void;
  styles?: {
    filterTagButtonStyle?: string;
    clearAllButtonStyle?: string;
  };
}

export function FilterTags({
  className,
  selectedFilters,
  clearAll,
  clearPriceSort,
  clearShowerState,
  styles,
}: FilterTagProps) {
  const [t] = useTranslation();

  return (
    <div
      data-testid="filterTags"
      className={cx(
        'filter-tags mx-2 flex flex-wrap items-end justify-center gap-2 space-x-2 pt-4',
        className
      )}
    >
      {selectedFilters.map((f, id) => {
        const nestedValue = f?.value?.find((filter) => filter?.label === f?.label)?.value;
        if (f.label) {
          return (
            <button
              key={id}
              className={`filter-results btn md:mb-2 ${
                styles?.filterTagButtonStyle
                  ? `btn-${styles?.filterTagButtonStyle}`
                  : 'btn-secondary-outline border-bg-disabled text-text flex items-center rounded-none pr-2 font-normal'
              }`}
              onClick={
                f.label === t('highToLow') || f.label === t('lowToHigh')
                  ? clearPriceSort
                  : f.label === t('showerLabel')
                  ? clearShowerState
                  : f.remove
              }
              aria-label={t('remove', { label: f.label })}
            >
              {typeof nestedValue === 'string' && nestedValue?.includes('Guests')
                ? nestedValue
                : f.label}{' '}
              <CloseIcon className="ml-3 inline-block size-2.5 fill-current" />
            </button>
          );
        } else return null;
      })}
      <button
        className={`clear-results btn md:mb-2 ${
          styles?.clearAllButtonStyle
            ? `btn-${styles?.clearAllButtonStyle}`
            : 'btn-primary-outline flex items-center rounded-none pr-2'
        }`}
        onClick={clearAll}
        data-testid="filter-tags-clear-all"
      >
        {t('clearAll')} <CloseIcon className="ml-3 inline-block size-2.5 fill-current" />
      </button>
    </div>
  );
}
