import get from 'lodash/get';
import set from 'lodash/set';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { env } from '@dx-ui/framework-env';

export const ANALYTICS_GLOBAL_CLICK_EVENT = 'global_click';
export const ANALYTICS_NO_VALUE = 'no_value';

const isAnalyticsImageTrackingEnabled = env('ANALYTICS_OSC_TRACKING_ENABLED') === 'true';

/**
 * Track an event with the given name and metrics
 */
export function trackEvent(eventName: string, metrics: Record<string, unknown>) {
  if (!isAnalyticsImageTrackingEnabled) return; // Early return if tracking is disabled

  if (isBrowser && window.digitalData && window._satellite?.track) {
    const { digitalData } = window;
    const satellite = window._satellite;

    Object.entries(metrics).forEach(([key, value]) => {
      // Handle array values
      if (Array.isArray(value)) {
        const existingValue = get(digitalData, key);
        if (Array.isArray(existingValue)) {
          // Merge arrays for exisitng values
          set(digitalData, key, [...existingValue, ...value]);
        } else {
          // Set the array directly
          set(digitalData, key, value);
        }
      } else {
        // Set the value as usual for non-array values
        set(digitalData, key, value);
      }
    });

    satellite?.track?.(eventName);
  } else {
    /* eslint-disable no-console */
    console.warn('digitalData or _satellite not found');
  }
}
