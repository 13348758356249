import { dynatrace } from '@dx-ui/framework-logger';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import type { AutocompletePrediction } from './use-suggestions';

export type Predictions = AutocompletePrediction[] | null;

export type PredictionStatusTypes = 'OK' | 'SELECTION_REQUIRED';

export type AutoCompleteResponse = {
  predictions: Predictions;
  status: PredictionStatusTypes | null;
};

export type Coordinate = {
  latitude: number;
  longitude: number;
};

export type LocationTypes = 'geocode' | 'airport' | 'pointOfInterest' | 'property' | 'address';

export interface PredictionService {
  /** The auto-complete service url */
  uri: string;
  /** The input text to use for searching locations */
  input: string;
  /** The language param to use for the search */
  language: string;
  /** The type of location to search for.
   * Options: `'geocode' | 'airport' | 'pointOfInterest' | 'property' | 'address'`
   * Can be provided in an array
   */
  type?: LocationTypes | LocationTypes[];
  /** The number of results to limit the search to. * Can be provided in an array with each value corresponding to the `type` option. */
  count?: number | number[];
  /** The coordinates to use when making a search. This is used to bias the results based on location */
  coordinate?: Coordinate;
  /** Unique id to use when making a search. If one is not supplied one is created using crypto.randomUUID(). @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID} */
  sessionId?: string;
  /** Toggle exclusion rules by country */
  applyExclusions?: boolean;
}

export type UsePlacesAutocompleteProps = {
  /** Enabled autocomplete fetch */
  isEnabled?: boolean;
  /** Minimum charters needed to be entered before a search is attempted */
  minLength?: number;
  /** Query options that are used when making a search for locations */
  serviceOptions: PredictionService;
};

const mapParamToString = (param: unknown) => {
  if (Array.isArray(param)) return param.join(',');
  if (Number.isFinite(param)) return String(param);
  return String(param);
};

const getPredictions = async ({
  sessionId = crypto.randomUUID(),
  coordinate,
  uri,
  ...params
}: PredictionService): Promise<AutoCompleteResponse | null> => {
  const url = new URL(uri, window.location.origin);

  const urlSearchParams = new URLSearchParams({
    ...(params?.input && { input: params.input }),
    ...(params?.language && { language: params.language }),
    ...(params?.type && { type: mapParamToString(params?.type) }),
    ...(params?.count && { count: mapParamToString(params.count) }),
    ...(params?.applyExclusions && { applyExclusions: mapParamToString(params.applyExclusions) }),
    ...(coordinate && {
      location: mapParamToString(`${coordinate.latitude},${coordinate.longitude}`),
    }),
  });

  try {
    const response = await fetch(url.href + `?${urlSearchParams.toString()}`, {
      method: 'GET',
      headers: {
        'dx-map-session-token': sessionId,
      },
    });
    const autocompleteResults = await response.json();

    if (autocompleteResults?.status === 'SELECTION_REQUIRED') {
      dynatrace?.sendSessionProperties({ shortString: { searchdrawbridge: 'Up' } });
    }

    return autocompleteResults;
  } catch {
    return null;
  }
};

/**
 * A search as you type hook that provides a list of predictions.
 */
export const usePlacesAutocomplete = ({
  isEnabled = true,
  minLength = 2,
  serviceOptions,
}: UsePlacesAutocompleteProps) => {
  const hasSearchTerms = !!serviceOptions?.input && serviceOptions.input?.length >= minLength;

  const { isFetching, data: autocompleteResponse } = useQuery<AutoCompleteResponse | null>({
    queryKey: ['autocomplete', serviceOptions],
    queryFn: () => getPredictions(serviceOptions),
    enabled: isEnabled && hasSearchTerms,
    placeholderData: keepPreviousData,
  });

  return {
    predictions: autocompleteResponse?.predictions || null,
    loading: isFetching,
    status: autocompleteResponse?.status || null,
  };
};
