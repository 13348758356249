import { isBrowser } from '@dx-ui/utilities-is-browser';
import { logWarning } from '@dx-ui/framework-logger';
import type { DigitalData } from '../types/types';

export function clearImageAnalyticsData() {
  if (!isBrowser) return;

  if (!window.digitalData) {
    logWarning('clearImageAnalyticsData()', new Error('window.digitalData is not defined.'));
    return;
  }

  const imageKeysToDelete: (keyof DigitalData)[] = ['click', 'osc'];

  imageKeysToDelete.forEach((key) => {
    if (window.digitalData && key in window.digitalData) {
      delete window.digitalData[key];
    }
  });
}
