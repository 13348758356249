import type { FieldValues, UseFormReturn } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

type FormConnector<T extends FieldValues> = {
  children: JSX.Element | ((props: UseFormReturn<T>) => JSX.Element);
};

const FormConnector = <T extends FieldValues>({ children }: FormConnector<T>) => {
  const methods = useFormContext<T>();
  return typeof children === 'function' ? children(methods) : children;
};

export { FormConnector };
export default FormConnector;
