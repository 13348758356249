export function ArrowRightLong({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} style={{ fill: fillColor }} viewBox="0 0 216.78 148.29">
      <g data-name="arrow-right-long">
        <path
          d="M215.11 70.11L146.68 1.67a5.71 5.71 0 10-8.08 8.07l58.33 58.33H6a6 6 0 000 12h191.09l-58.49 58.47a5.71 5.71 0 008.08 8.08l68.43-68.44a5.71 5.71 0 000-8.07z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}
