import { useEventListener, useOnClickOutside, useToggle } from 'usehooks-ts';
import { useId, useRef } from 'react';
import { getKeyDownNavigation } from '@dx-ui/utilities-accessibility';

/**
 * Hook to manage the state of a popup menu.
 */
export function useMenu() {
  const menuId = useId();
  const popupButtonRef = useRef<React.ElementRef<'button'>>(null);
  const popupMenuRef = useRef<React.ElementRef<'div'>>(null);
  const [isOpen, toggleMenu, setIsOpen] = useToggle();
  const closeMenu = () => setIsOpen(false);

  return {
    menuId,
    isOpen,
    toggleMenu,
    closeMenu,
    popupButtonRef,
    popupMenuRef,
  };
}

type UseEventListenersOptions = Pick<
  ReturnType<typeof useMenu>,
  'popupButtonRef' | 'popupMenuRef'
> & {
  resetMenu: VoidFunction;
};

/**
 * Hook to manage event listeners for a popup menu.
 */
export function useEventListeners(options: UseEventListenersOptions) {
  const { popupButtonRef, popupMenuRef, resetMenu } = options;
  const focusButton = () => popupButtonRef.current?.focus();

  useOnClickOutside(popupMenuRef, (event) => {
    if (!popupButtonRef.current?.contains(event.target as HTMLElement)) {
      resetMenu();
    }
  });

  useEventListener(
    'keydown',
    (event) => {
      event.stopPropagation();
      switch (event.key) {
        case 'Tab':
          window.requestAnimationFrame(() => {
            if (!popupMenuRef.current?.contains(document.activeElement)) {
              resetMenu();
              focusButton();
            }
          });
          break;
        case 'Escape':
          resetMenu();
          focusButton();
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          {
            const activeList = popupMenuRef.current?.querySelector('ul');
            const elements = Array.from(activeList?.querySelectorAll('button') || []);
            const onKeyDownNavigation = getKeyDownNavigation({ elements });
            onKeyDownNavigation(event);
          }
          break;
        default:
          break;
      }
    },
    popupMenuRef
  );
}
