// Hotel Policies Component
import { useContext, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import {
  SharedContext,
  WrappedSubtitle,
  appliedCloudinaryParams,
  makeRandomInt,
  sanitize,
  capitalizeFirstLetter,
} from '@curated-property/utils';
import cx from 'classnames';
import { GIS_Array, GIS_Padder } from '../functions/global-instance-styles';

import contentStyles from '../css/contentStyle.module.css';
import { HandleWYSIWYGContent } from '../functions/helper';
import type { SectionContent, HotelPoliciesProps } from './hotel-policies.types';
import { AccordionSection } from './accordion-section';
import { Accordion } from '@dx-ui/osc-accordion';
import Markdown from 'markdown-to-jsx';

// Creates a new style component to share inline styles among sub-components
function hasValuesToDisplay(arrayToCheck) {
  return arrayToCheck?.some(
    (item) =>
      (item?.lineValue !== null && item?.lineValue !== undefined) ||
      item?.lineCopy ||
      item?.lineList?.length > 0 ||
      item?.lineUrl
  );
}

export function hotelPolicyPropMapper(componentData, dxGql) {
  return {
    corePlus: {
      checkinTimeFmt: dxGql?.registration?.checkinTimeFmt,
      checkoutTimeFmt: dxGql?.registration?.checkoutTimeFmt,
      // Parking
      hasSelfParking: dxGql?.parking?.hasSelfParking,
      hasValetParking: dxGql?.parking?.hasValetParking,
      selfParkingCharge: dxGql?.parking?.selfParkingCharge?.chargeAmount,
      selfParkingChargeFmt: dxGql?.parking?.selfParkingCharge?.chargeAmountFmt,
      selfParkingComments: dxGql?.parking?.selfParkingCharge?.parkingComments,
      valetParkingCharge: dxGql?.parking?.valetParkingCharge?.chargeAmount,
      valetParkingChargeFmt: dxGql?.parking?.valetParkingCharge?.chargeAmountFmt,
      valetParkingComments: dxGql?.parking?.valetParkingCharge?.parkingComments,
      hasSecuredParking: dxGql?.parking?.hasSecuredParking,
      hasCoveredParking: dxGql?.parking?.hasCoveredParking,
      inOutPrivileges: dxGql?.parking?.inOutPrivileges,
      otherParkingInfo: dxGql?.parking?.otherParkingInfo,
      pets: dxGql?.pets,
      // Fee transparency
      resortFeeDisclosureDesc: dxGql?.overview?.resortFeeDisclosureDesc,
      // Smoking
      smokingPolicySafety: dxGql?.safetyAndSecurity?.smokingPolicyDesc,
      smokingPolicy: dxGql?.policy?.smoking,
      // Payment
      payment: dxGql?.policy?.payment?.bankValidationMsg,
      currency: dxGql?.localization?.currency?.description,
      creditCardTypes: dxGql?.creditCardTypes,
      creditCardOnly: dxGql?.facilityOverview?.creditCardOnly,
      // Checkin/checkout
      earlyDepartureFee: dxGql?.registration?.earlyDepartureFee,
      earlyDepartureFeeFmt: dxGql?.registration?.earlyDepartureFeeFmt,
      earlyDepartureFeeType: dxGql?.registration?.earlyDepartureFeeType,
      earlyDepartureText: dxGql?.registration?.earlyDepartureText,
      eCheckin: dxGql?.registration?.eCheckin,
      lateCheckoutFee: dxGql?.registration?.lateCheckoutFee,
      lateCheckoutFeeFmt: dxGql?.registration?.lateCheckoutFeeFmt,
      lateCheckoutText: dxGql?.registration?.lateCheckoutText,
      minAgeToRegister: dxGql?.registration?.minAgeToRegister,
      // Additional
      additionalPolicies: dxGql?.policy?.additionalPolicies,
      policyOptions: dxGql?.policyOptions,
      // Internet
      wifiAccess: dxGql?.policy?.wifi,
    },
  };
}

export function HotelPolicies({
  title,
  subtitle,
  copy,
  displayHotelAccessibilityLink,
  hotelAccessibilityGuideUrl,
  corePlus,
  instanceStyles,
  globalStyles,
}: HotelPoliciesProps) {
  const { locale } = useContext(SharedContext);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const { t } = useTranslation();

  const panelStyles = {
    hotelOpenPanelBackgroundColour: inlineStyles?.hotelOpenPanelBackgroundColour,
  };

  const hotelPoliciesBorderColour = inlineStyles?.hotelPoliciesBorderColour;
  const hotelPoliciesIconIndicatorColour = inlineStyles?.hotelPoliciesIconIndicatorColour;
  const hotelPoliciesIconIndicatorColourExpanded =
    inlineStyles?.hotelPoliciesIconIndicatorColourExpanded;

  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = `hotelPolicy`;
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `div#${componentStyleID}`;

  const borderStyleString = hotelPoliciesBorderColour
    ? `${mainSelector} button.accordion-stacked {border-color:${hotelPoliciesBorderColour};}`
    : '';

  let styleString = '';

  inlineStyles?.textColor &&
    (styleString += `${mainSelector} button.accordion-stacked, ${mainSelector} div.accordion-section {color: ${inlineStyles?.textColor}; }`);

  if (hotelPoliciesIconIndicatorColour || hotelPoliciesIconIndicatorColourExpanded) {
    styleString += `${mainSelector} button.accordion-stacked > span svg > g { fill: none !important; }`;
    styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg g path { stroke:${hotelPoliciesIconIndicatorColour} !important; }`;
    styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg > g polyline { color:${hotelPoliciesIconIndicatorColour} !important; }`;

    const accordionIconIndicatorColourExpanded = !hotelPoliciesIconIndicatorColourExpanded
      ? hotelPoliciesIconIndicatorColour
      : hotelPoliciesIconIndicatorColourExpanded;
    styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg g path { stroke:${accordionIconIndicatorColourExpanded} !important; }`;
    styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg > g polyline { stroke:${accordionIconIndicatorColourExpanded} !important; }`;
  }

  useEffect(() => {
    if (!document.getElementById(styleElementID)) {
      const $style = document.createElement('style');
      $style.setAttribute('id', styleElementID);
      document.head.appendChild($style);
      $style.innerHTML = '';
      if (borderStyleString) $style.innerHTML += borderStyleString;
      if (styleString) $style.innerHTML += styleString;
    }
  }, [styleElementID, borderStyleString, styleString]);

  const selfParking = !corePlus?.hasSelfParking
    ? t('policies.unavailable')
    : corePlus?.selfParkingCharge === 0
    ? t('policies.complimentary')
    : corePlus?.selfParkingChargeFmt;
  const valetParking = !corePlus?.hasValetParking
    ? t('policies.unavailable')
    : corePlus?.valetParkingCharge === 0
    ? t('policies.complimentary')
    : corePlus?.valetParkingChargeFmt;

  const secureParking =
    corePlus?.hasSecuredParking === true ? t('policies.available') : t('policies.unavailable');
  const coveredParking =
    corePlus?.hasCoveredParking === true ? t('policies.available') : t('policies.unavailable');
  const inOut =
    corePlus?.inOutPrivileges === true ? t('policies.available') : t('policies.unavailable');

  const currency = corePlus?.currency;
  const creditCardTypes: Array<string> = corePlus?.creditCardTypes?.map((i) => {
    return i.name;
  });

  const creditCardTypesFiltered = creditCardTypes.length ? [...new Set(creditCardTypes)] : null;

  const earlyDepartureFee = corePlus?.earlyDepartureFee;
  const earlyDepartureFeeFmt = corePlus?.earlyDepartureFeeFmt;
  const earlyDepartureText = corePlus?.earlyDepartureText;

  const lateCheckoutFee = corePlus?.lateCheckoutFee;
  const lateCheckoutFeeFmt = corePlus?.lateCheckoutFeeFmt;
  const lateCheckoutText = corePlus?.lateCheckoutText;

  const checkinTimeFmt = corePlus?.checkinTimeFmt;
  const checkoutTimeFmt = corePlus?.checkoutTimeFmt;
  const minAgeToRegister = corePlus?.minAgeToRegister;

  const frenchMediatorPolicy = corePlus?.additionalPolicies?.find(
    (policyItem) => policyItem?.headline === 'Médiateur de voyage et de tourisme'
  );

  const faceCoveringPolicy = corePlus?.additionalPolicies?.find(
    (policyItem) => policyItem?.headline === 'Face Coverings'
  );
  const faceCoveringHeadline = faceCoveringPolicy?.headline;
  const faceCoveringText = faceCoveringPolicy?.terms?.[0]?.description?.[0]?.value;

  const cancellationPolicyText = corePlus?.policyOptions?.[0]?.options[0]?.value;

  const resortFeeDisclosureDesc = corePlus?.resortFeeDisclosureDesc;

  const creditCardOnly = corePlus?.creditCardOnly;

  let earlyDepartureString = '';
  if ((earlyDepartureFee > 0 && earlyDepartureFee !== null) || earlyDepartureText !== null) {
    earlyDepartureFee > 0 &&
      earlyDepartureFee !== null &&
      (earlyDepartureString = !earlyDepartureText ? earlyDepartureFeeFmt : earlyDepartureText);
  }

  let lateCheckoutString = '';
  if ((lateCheckoutFee > 0 && lateCheckoutFee !== null) || lateCheckoutText !== null) {
    lateCheckoutFee > 0 &&
      lateCheckoutFee !== null &&
      (lateCheckoutString = !lateCheckoutText ? lateCheckoutFeeFmt : lateCheckoutText);
  }

  const wifi = corePlus?.wifiAccess;

  const { freeWlanMsg, wlanInRoom, wlanInRoomFee, wlanInRoomFeeFmt } = wifi || {};
  let wifiString;
  if (!freeWlanMsg) {
    const isFreeWifi = wlanInRoom && !isNaN(wlanInRoomFee) && wlanInRoomFee === 0;
    !isFreeWifi
      ? (wifiString = `${t('policies.wifiStandard')} ${wlanInRoomFeeFmt}`)
      : (wifiString = t('policies.wifiFree'));
  } else {
    wifiString = freeWlanMsg;
  }

  const { pets } = corePlus;

  // Pet fee text
  const petChargeTextValue = `${pets?.petChargeFmt ?? `${pets?.petChargeFmt} `} ${
    !pets?.petChargeRefundable ? t('policies.petChargeNonrefundable') : ''
  }`;

  let smokingString = corePlus?.smokingPolicySafety;
  const smokingPolicy = corePlus?.smokingPolicy;

  if (!smokingString) {
    !smokingPolicy?.nonSmokingDesc
      ? (smokingString = smokingPolicy?.smokingDesc)
      : (smokingString = smokingPolicy?.nonSmokingDesc);
  }

  // Section display value arrays

  // Parking
  const parkingDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.parking'),
    sectionPanelContent: [
      // Self-parking
      { lineLabel: 'policies.selfParking', lineValue: selfParking },
      // Valet
      { lineLabel: 'policies.valetParking', lineValue: valetParking },
      // Secure parking
      { lineLabel: 'policies.secureParking', lineValue: secureParking },
      // Covered parking
      { lineLabel: 'policies.coveredParking', lineValue: coveredParking },
      // In/out parking
      { lineLabel: 'policies.inOutParking', lineValue: inOut },
      // Other parking info
      {
        lineLabel: 'policies.otherParkingInformation',
        lineValue: corePlus?.otherParkingInfo,
      },
    ],
  };

  // Check in Checkout
  const checkInCheckOutDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.checkinCheckout'),
    sectionPanelContent: [
      // Check-in
      { lineLabel: 'policies.checkIn', lineValue: checkinTimeFmt },
      // Check-out
      { lineLabel: 'policies.checkOut', lineValue: checkoutTimeFmt },
      // Min. age
      { lineLabel: 'policies.minimumAge', lineValue: minAgeToRegister },
      // Early departure
      {
        lineLabel: 'policies.earlyCheckoutFee',
        lineValue: earlyDepartureString,
      },
      // Late check-out
      { lineLabel: 'policies.lateCheckoutFee', lineValue: lateCheckoutString },
    ],
  };

  const wifiContentDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.wifi'),
    sectionPanelContent: [{ lineLabel: 'policies.wifi', lineCopy: wifiString }],
  };

  // Pets has two presentation types: service animals allowed and both service animals and non-service pets allowed.
  const petPolicyDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.pets'),
    sectionPanelContent: [
      // Pets allowed
      {
        lineLabel: 'policies.petsAllowed',
        lineValue: !pets?.petsAllowed ? 'policies.no' : 'policies.yes',
      },

      // Policy description
      { lineLabel: 'policies.petPolicy', lineValue: pets?.description },

      // Max size (small, medium, etc.)
      {
        lineLabel: 'policies.maxSize',
        lineValue: capitalizeFirstLetter(pets?.petMaxSize),
      },
      // Max weight
      { lineLabel: 'policies.maxWeight', lineValue: pets?.petMaxWeightFmt },
      // Pet fee
      {
        lineLabel: 'policies.petCharge',
        lineValue: pets?.petChargeFmt ? petChargeTextValue : '',
      },
    ],
  };

  // Smoking
  const smokingPolicyDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.smoking'),
    sectionPanelContent: [{ lineLabel: 'policies.smoking', lineCopy: smokingString }],
  };

  // Cancellation
  const cancellationDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.cancellation'),
    sectionPanelContent: [{ lineLabel: 'policies.cancellation', lineCopy: cancellationPolicyText }],
  };

  // Payment
  const paymentPolicyDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.payment'),
    sectionPanelContent: [
      { lineLabel: 'policies.currency', lineValue: currency },
      creditCardOnly
        ? {
            lineLabel: 'policies.paymentMethods',
            lineValue: 'policies.creditCardsOnly',
          }
        : null,
      {
        lineLabel: 'policies.acceptedCreditCards',
        lineList: creditCardTypesFiltered,
      },
    ],
  };

  // Face Covering
  const faceCoveringDescriptions: SectionContent = {
    sectionPanelLabel: !faceCoveringHeadline ? t('policies.faceCoverings') : faceCoveringHeadline,
    sectionPanelContent: [{ lineCopy: faceCoveringText }],
  };

  const frenchMediatorPolicyDescriptions: SectionContent[] =
    locale === 'fr' && frenchMediatorPolicy
      ? [
          {
            sectionPanelLabel: frenchMediatorPolicy?.headline,
            sectionPanelContent: [
              {
                lineLabel: frenchMediatorPolicy?.headline,

                lineCopy: (
                  <div data-element-id="french-mediator-policy-copy">
                    <style>{`
                    [data-element-id="french-mediator-policy-copy"] p { margin: 1rem 0; }
                  `}</style>
                    <Markdown className="OneLinkNoTx">
                      {
                        //throws a type error about object structure - not sure why yet.
                        // eslint-disable-next-line
                        //@ts-ignore
                        frenchMediatorPolicy?.terms?.description?.[0]?.value_noTx
                      }
                    </Markdown>
                  </div>
                ),
              },
            ],
          },
        ]
      : [];

  // Resort Fees
  const resortFeeDescriptions: SectionContent = {
    sectionPanelLabel: t('policies.resortFees'),
    sectionPanelContent: [{ lineLabel: 'policies.resortFees', lineCopy: resortFeeDisclosureDesc }],
  };

  // (UK only) A11y Links
  const hotelA11yDescriptions: SectionContent = displayHotelAccessibilityLink
    ? {
        sectionPanelLabel: t('policies.accessibility'),
        sectionPanelContent: [
          {
            lineLabel: 'policies.accessibility',
            lineCopy: 'policies.accessibilityCopy',
            lineUrl: hotelAccessibilityGuideUrl,
          },
        ],
      }
    : {};

  // All the section arrays above combined, to be rendered in UI
  const allPanelRows = [
    parkingDescriptions,
    petPolicyDescriptions,
    wifiContentDescriptions,
    smokingPolicyDescriptions,
    cancellationDescriptions,
    checkInCheckOutDescriptions,
    paymentPolicyDescriptions,
    faceCoveringDescriptions,
    ...frenchMediatorPolicyDescriptions,
    resortFeeDescriptions,
    hotelA11yDescriptions,
  ];

  const accordionItems = allPanelRows
    ?.map((item, index) => {
      if (typeof item === 'undefined') {
        return null;
      } else {
        return (
          hasValuesToDisplay(item?.sectionPanelContent) && {
            collapsedButtonLabel: item?.sectionPanelLabel,
            expandedButtonLabel: item?.sectionPanelLabel,
            key: `accordion${index}`,
            content: (
              <AccordionSection
                key={item?.sectionPanelLabel}
                panelKey={`${index}`}
                panelLabel={item?.sectionPanelLabel}
                panelRepeater={item?.sectionPanelContent}
                panelStyles={panelStyles}
              />
            ),
          }
        );
      }
    })
    .filter(Boolean);

  return (
    <div data-testid="hotelPolicies" className={inlineStyles?.showHide && 'hidden'}>
      <div
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || null,
          backgroundPosition: inlineStyles?.componentBackgroundPosition || null,
          backgroundColor: inlineStyles?.componentBackgroundColor || null,
        }}
      >
        <div
          className={cx('container', paddingStyles)}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )}')`
              : null,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || null,
            backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
            backgroundColor: inlineStyles?.contentBackgroundColor || null,
          }}
        >
          <h2
            style={{ color: inlineStyles?.titleColor || null }}
            className="mb-4 font-headline text-2xl font-black leading-none md:text-4xl"
            dangerouslySetInnerHTML={{
              __html: sanitize(!title ? t('policies.resortPolicies') : title),
            }}
          />
          {subtitle && (
            <WrappedSubtitle>
              <p
                style={{ color: inlineStyles?.subtitleColor || null }}
                className="my-4 text-xl"
                dangerouslySetInnerHTML={{
                  __html: sanitize(subtitle),
                }}
              />
            </WrappedSubtitle>
          )}
          {copy && (
            <div
              className={cx(contentStyles.paragraphStyle, contentStyles.listStyle, 'mb-2')}
              style={{ color: inlineStyles?.textColor || null }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
            />
          )}
          {accordionItems ? (
            <div id={componentStyleID}>
              <Accordion
                headingLevel="h3"
                className="accordion-stacked flex w-full justify-between border-t border-text px-2 py-4 text-2xl font-extrabold aria-expanded:border-b brand-ch:tracking-[3px] [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&_span]:flex [&_span]:items-center"
                items={accordionItems}
                type="multiple"
                variant="stacked"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HotelPolicies;
