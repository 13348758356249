import type { GuestQuery } from '@dx-ui/framework-auth-provider';
import type SpecialRates from './special-rates';

export type Hhonors = NonNullable<GuestQuery['guest']>['hhonors'];

export const isTeamMember = (hhonors?: Hhonors) =>
  Boolean(hhonors?.isTeamMember || hhonors?.isTeamMemberSpouse || hhonors?.isLongTenure20);

export const isFriendsAndFamily = (hhonors?: Hhonors) =>
  Boolean(hhonors?.isFamilyAndFriends || hhonors?.isLongTenure10);

export const isOwner = (hhonors?: Hhonors) => Boolean(hhonors?.isOwner || hhonors?.isOwnerHGV);

export const isHGVMax = (hhonors?: Hhonors) => Boolean(hhonors?.isOwnerHGVNew);

export const isTMTPProgramMember = (hhonors?: Hhonors) =>
  isTeamMember(hhonors) || isFriendsAndFamily(hhonors) || isOwner(hhonors);

export const isSmbMember = (hhonors?: Hhonors) => Boolean(hhonors?.isSMBMember);

export const isTMTPRateSelected = (specialRates?: SpecialRates) =>
  Boolean(specialRates?.employeeRate) ||
  Boolean(specialRates?.friendsAndFamilyRate) ||
  Boolean(specialRates?.ownerVIPRate);

export const isHGVMaxRateSelected = (specialRates?: SpecialRates) =>
  Boolean(specialRates?.ownerHGVRate);

export const isSMBRateSelected = (specialRates?: SpecialRates) => Boolean(specialRates?.smbRate);
