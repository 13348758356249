export function Gym({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M14,10.625H10a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M14,12.625H10a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M7,5.625A1.377,1.377,0,0,0,5.625,7V8.7825A1.3563,1.3563,0,0,0,5,8.625,1.377,1.377,0,0,0,3.625,10v4A1.377,1.377,0,0,0,5,15.375a1.3563,1.3563,0,0,0,.625-.1575V17a1.375,1.375,0,0,0,2.75,0V7A1.377,1.377,0,0,0,7,5.625ZM5.625,14a.625.625,0,0,1-1.25,0V10a.625.625,0,0,1,1.25,0Zm2,3a.625.625,0,0,1-1.25,0V7a.625.625,0,0,1,1.25,0Z" />
          <path d="M19,8.625a1.3563,1.3563,0,0,0-.625.1575V7a1.375,1.375,0,0,0-2.75,0V17a1.375,1.375,0,0,0,2.75,0V15.2175A1.3563,1.3563,0,0,0,19,15.375,1.377,1.377,0,0,0,20.375,14V10A1.377,1.377,0,0,0,19,8.625ZM17.625,10v7a.625.625,0,0,1-1.25,0V7a.625.625,0,0,1,1.25,0Zm2,4a.625.625,0,0,1-1.25,0V10a.625.625,0,0,1,1.25,0Z" />
        </g>
      </g>
    </svg>
  );
}
