import { useEffect, useState } from 'react';
import { CroppedImage } from './cropped-image/cropped-image';
import { isReducedMotion, appliedCloudinaryParams, sanitize } from '@curated-property/utils';
import cx from 'classnames';
import type { ImageModalImage } from './includes/image-modal';
import { ImageModal } from './includes/image-modal';
import { HandleAnimations } from './functions/helper';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder } from './functions/global-instance-styles';
import { useTranslation } from 'next-i18next';
import { adobeEventTracking } from './global/adobe-analytics';
import { GenericModal } from './global/generic-modal';

export interface ImageGalleryProps {
  galleries:
    | {
        galleryTitle?: string;
        galleryThumbnail?: {
          sourceUrl?: string;
          altText?: string;
        };
        galleryImages?: ImageModalImage[];
      }[]
    | undefined;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
  modalSettings?: {
    modalBackgroundOpacity?: number;
    modalBackgroundColour?: string;
    modalCloseButtonBackgroundColour?: string;
    modalCloseButtonIconColour?: string;
  };
}

interface ActiveGalleryState {
  id: number;
  lastFocusedElement: HTMLDivElement | null;
}

export function ImageGallery({
  galleries,
  instanceStyles,
  globalStyles,
  modalSettings,
}: ImageGalleryProps) {
  const [modalActive, setModalActive] = useState(false);
  const [activeGallery, setActiveGallery] = useState<ActiveGalleryState>({
    id: 0,
    lastFocusedElement: null,
  });
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const slideAnimation: string = inlineStyles?.sliderAnimations || 'fade';
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;
  modalSettings = {
    ...modalSettings,
    modalBackgroundColour:
      inlineStyles?.galleryTilesBackgroundColour || modalSettings?.modalBackgroundColour,
    modalCloseButtonBackgroundColour:
      inlineStyles?.galleryTilesControlsBackgroundColour ||
      modalSettings?.modalCloseButtonBackgroundColour,
    modalCloseButtonIconColour:
      inlineStyles?.galleryTilesControlsIconColour || modalSettings?.modalCloseButtonIconColour,
  };

  if (galleries) {
    galleries[-1] = {
      galleryImages: Array(6).fill({ sourceUrl: '' }),
    };
  }

  function open(e, id) {
    if (!galleries) {
      return;
    }
    setModalActive(true);
    setActiveGallery({
      id,
      lastFocusedElement: e?.target,
    });
  }

  function close() {
    if (!galleries) {
      return;
    }

    setModalActive(false);
  }

  useEffect(() => {
    if (!modalActive) {
      activeGallery?.lastFocusedElement?.focus();
    }
  }, [activeGallery, modalActive]);

  return (
    <div
      className={cx(paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
      data-element-id="gallery-tiles-and-fullscreen-carousel-wrapper"
    >
      <div className="container mx-auto grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {galleries?.map((gallery, key) => {
          return (
            <GalleryItem
              key={key}
              id={key}
              thumbImg={gallery?.galleryThumbnail?.sourceUrl || ''}
              thumbAlt={gallery?.galleryThumbnail?.altText}
              galleryTitle={gallery?.galleryTitle}
              galleryImages={gallery?.galleryImages}
              open={(e) => open(e, key)}
              modalActive={modalActive}
              inlineStyles={inlineStyles}
              zoomOnHover={zoomOnHover}
            />
          );
        })}
      </div>
      {modalActive && (
        <GenericModal
          setModalActive={close}
          modalActive={modalActive}
          modalChildWrapperStyles="w-full lg:px-32 pt-8 bg-transparent shadow-none"
          modalStyles="w-full"
          modalSettings={modalSettings}
          modalChild={
            <ImageModal
              images={galleries?.[activeGallery.id]?.galleryImages}
              title={galleries?.[activeGallery.id]?.galleryTitle}
              active={modalActive}
              slideAnimation={slideAnimation}
              captionColour={inlineStyles?.galleryTilesControlsCaptionColour}
              controlsBackgroundColour={modalSettings?.modalCloseButtonBackgroundColour}
              controlsIconColour={modalSettings?.modalCloseButtonIconColour}
            />
          }
        />
      )}
    </div>
  );
}

interface GalleryItemProps {
  id: number;
  thumbImg: string;
  thumbAlt?: string;
  galleryTitle?: string;
  galleryImages?: any;
  open?: any;
  active?: any;
  modalActive?: boolean;
  inlineStyles?: StyleObject;
  zoomOnHover?: boolean;
}

const GalleryItem = ({
  id,
  thumbImg,
  thumbAlt,
  galleryTitle,
  galleryImages,
  open,
  modalActive,
  inlineStyles,
  zoomOnHover,
}: GalleryItemProps) => {
  const { t } = useTranslation();

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-200',
  });

  const handleOpenModal = (e) => {
    if ((e.type === 'keydown' && (e as KeyboardEvent).code === 'Enter') || e.type === 'click') {
      e.preventDefault();
      open(e, id);
      adobeEventTracking({
        carouselNumber: `1:${galleryImages?.length}`,
        sectionHeading: galleryTitle,
        actionDetail: 'Carousel',
        interactionName: 'propertyGalleryCarousel',
      });
    }
  };
  return (
    <div
      ref={animations?.ref}
      className={cx('relative rounded text-center shadow', animations?.one)}
      style={{
        backgroundImage: inlineStyles?.contentBackgroundImage
          ? `url('${appliedCloudinaryParams(inlineStyles?.contentBackgroundImage?.sourceUrl)}')`
          : undefined,
        backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
        transitionDelay: `${id + 1}00ms`,
      }}
    >
      <div
        className="size-full"
        role="button"
        tabIndex={0}
        data-testid="gallery-modal-trigger"
        aria-label={t('openGallery') + (galleryTitle || '')}
        onClick={(e) => handleOpenModal(e)}
        onKeyDown={(e) => handleOpenModal(e)}
        aria-expanded={modalActive ? true : false}
      >
        <div className="pointer-events-none relative overflow-hidden">
          <CroppedImage
            src={thumbImg}
            alt={thumbAlt}
            width={540}
            height={390}
            title={galleryTitle}
            className={cx('transition-all duration-500', {
              'hover:scale-125': zoomOnHover,
            })}
          />
        </div>
        <div className="pointer-events-none">
          <h2
            data-element-id="gallery-item-heading"
            className="font-headline pt-4 text-xl font-black leading-none md:text-3xl"
            style={{ color: inlineStyles?.titleColor }}
            dangerouslySetInnerHTML={{
              __html: sanitize(galleryTitle || ''),
            }}
          />
          <div className="text-md pb-4 pt-2 uppercase" style={{ color: inlineStyles?.titleColor }}>
            {!inlineStyles?.hideImageCount && (
              <span>
                <b>{!galleryImages ? '0' : galleryImages.length}</b> {t('photos')}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
