import { useTranslation } from 'next-i18next';
import * as locales from 'date-fns/locale';
import { EventCalendarModalContentBlock } from './event-calendar-modal-content-block';
import { formatDateTime } from './event-calendar-helpers';
import { format, parseISO } from 'date-fns';
import { dayKeys } from './lib/event-calendar-constants';
import type { EventCalendarModalProps } from './lib/event-calendar-props';

export function EventCalendarModalContentEvent({
  title,
  eventDateNumber,
  dateStringNom,
  content,
  eventType,
  hideICS,
}: EventCalendarModalProps) {
  const locale = locales?.enUS;
  const { t } = useTranslation();

  let scheduleNode;
  let operationTimes = '';
  let multiDateString = '';
  // These are only used for regularly scheduled events:
  let startTime = '';
  let startDate = '';
  let endDate = '';
  let endTime = '';
  let allDay = false;

  // Special Event Content
  if (eventType === 'special' || eventType === 'multidate') {
    scheduleNode = content?.calendarOfEvents;
    if (scheduleNode?.startTime) {
      operationTimes = scheduleNode?.startTime;
    } else {
      // If there's no start time provided, then event becomes an all day event.
      if (!scheduleNode?.startTime) {
        allDay = true;
        operationTimes = t('calendar.openAllDay');
      }
    }
    // Determine end time or all day based on whether a start time is added.
    if (scheduleNode?.endTime && scheduleNode?.startTime) {
      operationTimes += ` ${t('calendar.to')} ${scheduleNode?.endTime}`;
    }

    // Add dates for multi-date events
    if (eventType === 'multidate') {
      multiDateString = format(parseISO(scheduleNode?.startDate), 'MMM d');
      multiDateString += scheduleNode?.endDate
        ? ` ${t('calendar.to')} ${format(parseISO(scheduleNode?.endDate), 'MMM d')}`
        : '';
    }
  }

  // Regular Event Content

  if (eventType === 'regular') {
    scheduleNode = content?.RegularlyScheduledEvents;

    let daySchedule;

    // Make a different, more precise version of a date object for regular events (evaluate whether this should be used throughout the script)
    // Convert date to yyyy/mm/dd
    const dateWithSlashes = `${dateStringNom.slice(0, 4)}/${dateStringNom.slice(
      5,
      7
    )}/${eventDateNumber.toString().padStart(2, '0')}`;
    const dayForReg = new Date(dateWithSlashes);

    !scheduleNode?.allDays?.heldDaily
      ? (daySchedule = scheduleNode[dayKeys[dayForReg.getDay()]])
      : (daySchedule = scheduleNode?.allDays);

    if (!daySchedule?.allDay) {
      if (daySchedule?.startTime) {
        operationTimes = formatDateTime(dateStringNom, daySchedule?.startTime, locale);

        startTime = formatDateTime(dateStringNom, daySchedule?.startTime, locale);

        daySchedule?.endTime && formatDateTime(dateStringNom, daySchedule?.endTime, locale);

        startDate = dateStringNom.replace(/-/g, '');

        if (daySchedule?.endTime) {
          operationTimes +=
            ` ${t('calendar.to')} ` + formatDateTime(dateStringNom, daySchedule?.endTime, locale);

          endTime = formatDateTime(dateStringNom, daySchedule?.endTime, locale);
        }
        endDate = startDate;
      }
    } else {
      allDay = true;
      operationTimes = t('calendar.openAllDay');
    }

    const timeObj = {
      startDate,
      endDate,
      startTime,
      endTime,
    };

    // Add values to both types of time structures
    scheduleNode.startDate = startDate;
    scheduleNode.endDate = endDate;
    scheduleNode.startTime = startTime;
    scheduleNode.endTime = endTime;
    scheduleNode.timeObj = timeObj;
  }

  return (
    <EventCalendarModalContentBlock
      title={title}
      dateStringFmt={eventType === 'multidate' && multiDateString}
      operationTimeString={eventType === 'multidate' ? '' : operationTimes}
      eventContent={scheduleNode}
      eventType={eventType}
      allDay={allDay}
      hideICS={hideICS}
    />
  );
}
