/* Numo Ierapetra Beach Resort Crete, Curio Collection */

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/xei7vjg.css" />
      <style>
        {`
            :root {
              --font-headline: ivymode, sans-serif !important;
              --font-button: barlow, sans-serif !important;
              --font-sans: barlow, sans-serif !important;
            }
            
            h1,h2,h3,h2 p,h1 p, h3 p,
            .font-headline,.font-black {
                font-family: "ivymode", sans-serif !important;
                font-weight: 300;
                font-style: normal;
            }
  
            div,p,ul,ul li, blockquote,em,
            .btn,  button, ul a, ol, ol li {
                font-family: "barlow", sans-serif !important;
                font-weight: 300;
                font-style: normal;
            }
          `}
      </style>
    </>
  );
};
