import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./orlcici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-28 fill-current text-current antialiased lg:mr-5 lg:w-40"
    fillColor="#BC9B6A"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current text-current antialiased lg:w-40" fillColor="#000000" />
);
