import { useCallback, useMemo, useState } from 'react';

export interface useURLParamStateProps {
  paramOptions?: (string | undefined)[] | null;
  defaultParamOption?: string;
  urlQueryParamName: string;
}

export function useURLParamState({
  paramOptions,
  urlQueryParamName,
  defaultParamOption,
}: useURLParamStateProps) {
  const params = useMemo(() => {
    return typeof window !== 'undefined' ? new URLSearchParams(window?.location?.search) : null;
  }, []);

  const [val, setVal] = useState<string | string[]>(params?.get(urlQueryParamName) || '');

  const changeParamValue = useCallback(
    (newParamValue: string | number | null) => {
      newParamValue = String(newParamValue);
      if (val === newParamValue) {
        return;
      }
      if (paramOptions && paramOptions?.length > 0) {
        newParamValue = paramOptions?.find((item) => item === newParamValue)
          ? newParamValue
          : defaultParamOption || '';
      }
      if (newParamValue !== '') {
        params?.set(urlQueryParamName, newParamValue);
      } else {
        params?.delete(urlQueryParamName);
      }
      if (String(newParamValue).split(',').length > 1) {
        setVal(String(newParamValue).split(','));
      } else {
        setVal(newParamValue);
      }
      window.history.replaceState('', '', `?${params?.toString()}${window.location.hash}`);
    },
    [val, paramOptions, params, defaultParamOption, urlQueryParamName]
  );

  return {
    val,
    changeParamValue,
  };
}
