import gql from 'graphql-tag-transform.macro';

export const RetailFlyout = gql`
  fragment RetailFlyout on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_RetailFlyout {
      copy
      fieldGroupName
      openByDefault
      pagePosition
      buttonStyle
      title
      link {
        target
        title
        url
      }
      retailFlyoutComponentSettings {
        fieldGroupName
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
