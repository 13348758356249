export function HotTub({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,7.375A1.875,1.875,0,1,0,10.125,5.5,1.8767,1.8767,0,0,0,12,7.375Zm0-3A1.125,1.125,0,1,1,10.875,5.5,1.1266,1.1266,0,0,1,12,4.375Z" />
          <path d="M9,11.375A.3751.3751,0,0,0,9.375,11V10A1.6262,1.6262,0,0,1,11,8.375h2A1.6262,1.6262,0,0,1,14.625,10v1a.375.375,0,0,0,.75,0V10A2.3777,2.3777,0,0,0,13,7.625H11A2.3777,2.3777,0,0,0,8.625,10v1A.3751.3751,0,0,0,9,11.375Z" />
          <path d="M17.0908,10.1865a.3745.3745,0,1,0-.1816.7266c2.0049.5029,2.7158,1.1689,2.7158,1.5869,0,.8877-2.9,2.125-7.625,2.125S4.375,13.3877,4.375,12.5c0-.418.7109-1.084,2.7158-1.5869a.3745.3745,0,1,0-.1816-.7266C4.791,10.7168,3.625,11.5391,3.625,12.5v5c0,1.8672,4.3154,2.875,8.375,2.875s8.375-1.0078,8.375-2.875v-5C20.375,11.5391,19.209,10.7168,17.0908,10.1865ZM19.625,17.5c0,.8877-2.9,2.125-7.625,2.125S4.375,18.3877,4.375,17.5V13.7451c1.41,1.0657,4.5884,1.63,7.625,1.63s6.2153-.5642,7.625-1.63Z" />
          <path d="M6.625,13A.3751.3751,0,0,0,7,13.375a2.449,2.449,0,0,0,.9873-.2832,1.8759,1.8759,0,0,1,.68-.2168,1.8658,1.8658,0,0,1,.6787.2168,2.449,2.449,0,0,0,.9873.2832,2.449,2.449,0,0,0,.9873-.2832A1.8759,1.8759,0,0,1,12,12.875a1.8759,1.8759,0,0,1,.68.2168,2.449,2.449,0,0,0,.9873.2832,2.449,2.449,0,0,0,.9873-.2832,1.8658,1.8658,0,0,1,.6787-.2168,1.8759,1.8759,0,0,1,.68.2168A2.449,2.449,0,0,0,17,13.375a.375.375,0,0,0,0-.75,1.8759,1.8759,0,0,1-.68-.2168,2.449,2.449,0,0,0-.9873-.2832,2.449,2.449,0,0,0-.9873.2832,1.8658,1.8658,0,0,1-.6787.2168,1.8759,1.8759,0,0,1-.68-.2168A2.449,2.449,0,0,0,12,12.125a2.449,2.449,0,0,0-.9873.2832,1.8759,1.8759,0,0,1-.68.2168,1.8658,1.8658,0,0,1-.6787-.2168,2.449,2.449,0,0,0-.9873-.2832,2.449,2.449,0,0,0-.9873.2832A1.8759,1.8759,0,0,1,7,12.625.3751.3751,0,0,0,6.625,13Z" />
        </g>
      </g>
    </svg>
  );
}
