import type { ImageFunction } from '@dx-ui/config-metrics';
import { ANALYTICS_NO_VALUE, ImageClickID, ImageType } from '@dx-ui/config-metrics';
import type { OffsetMetrics } from '../types/offset-analytics';

export const DEFAULT_OFFSET_IMAGE_POSITION = 1;
export const OFFSET_IMAGE_COUNT = 2;

type GenerateOffsetMetricsParam = {
  headline?: string;
  itemTitle?: string;
  position?: number;
  imageFunction?: (typeof ImageFunction)['Link'];
};

export const generateOffsetMetrics = ({
  headline,
  itemTitle,
  position,
  imageFunction,
}: GenerateOffsetMetricsParam): OffsetMetrics => {
  const offsetMetrics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        // pass in innerText from headline
        //itemTitle: pass in alt from image.small OR image.large
        headline: headline || ANALYTICS_NO_VALUE,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        type: ImageType.Offset,
        function: imageFunction,
        count: OFFSET_IMAGE_COUNT,
        position,
      },
    },
  } as const;

  return offsetMetrics;
};
