import gql from 'graphql-tag-transform.macro';
import { galleryWallSettings } from './common-vars';

export const ImageGalleryWall = gql`
fragment ImageGalleryWall on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_ImageGalleryWall {
        fieldGroupName
        gallerySubtitle
        columnCount
        imageVerticalAlignment
        repeater {
          title
          selectOrientation
          faceDetection
          portraitModal
          imageHeight
          image {
            sourceUrl
            altText
            mediaDetails {
              height
              width
            }
          }
          fieldGroupName
          description
        }
        galleryTitle
        galleryWallComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${galleryWallSettings}
        }
      }
}
`;
