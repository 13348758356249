export function Group({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M13.001,13.625h-2A2.3777,2.3777,0,0,0,8.626,16v2a.375.375,0,0,0,.75,0V16a1.6262,1.6262,0,0,1,1.625-1.625h2A1.6262,1.6262,0,0,1,14.626,16v2a.375.375,0,0,0,.75,0V16A2.3777,2.3777,0,0,0,13.001,13.625Z" />
          <path d="M9.501,8.375A1.875,1.875,0,1,0,7.626,6.5,1.8767,1.8767,0,0,0,9.501,8.375Zm0-3A1.125,1.125,0,1,1,8.376,6.5,1.1266,1.1266,0,0,1,9.501,5.375Z" />
          <path d="M14.501,8.375A1.875,1.875,0,1,0,12.626,6.5,1.8767,1.8767,0,0,0,14.501,8.375Zm0-3A1.125,1.125,0,1,1,13.376,6.5,1.1266,1.1266,0,0,1,14.501,5.375Z" />
          <path d="M6.501,9.125A1.875,1.875,0,1,0,8.376,11,1.8767,1.8767,0,0,0,6.501,9.125Zm0,3A1.125,1.125,0,1,1,7.626,11,1.1266,1.1266,0,0,1,6.501,12.125Z" />
          <path d="M10.126,11a1.875,1.875,0,1,0,1.875-1.875A1.8767,1.8767,0,0,0,10.126,11Zm1.875-1.125A1.125,1.125,0,1,1,10.876,11,1.1266,1.1266,0,0,1,12.001,9.875Z" />
          <path d="M19.376,11a1.875,1.875,0,1,0-1.875,1.875A1.8767,1.8767,0,0,0,19.376,11Zm-3,0a1.125,1.125,0,1,1,1.125,1.125A1.1266,1.1266,0,0,1,16.376,11Z" />
          <path d="M19,13.625H17a.375.375,0,0,0,0,.75h2A1.6262,1.6262,0,0,1,20.625,16v2a.375.375,0,0,0,.75,0V16A2.3777,2.3777,0,0,0,19,13.625Z" />
          <path d="M7,13.625H5A2.3777,2.3777,0,0,0,2.625,16v2a.375.375,0,0,0,.75,0V16A1.6262,1.6262,0,0,1,5,14.375H7a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
