import type { AccordionSectionProps } from './hotel-policies.types';
import { SectionRow } from './section-row';

export function AccordionSection({ panelKey, panelRepeater, panelStyles }: AccordionSectionProps) {
  const accordionPanelItems = panelRepeater
    ?.map((i, sectionIndex) => {
      return i?.lineLabel || i?.lineValue || i?.lineCopy ? (
        <SectionRow
          key={`liSectionRow${sectionIndex.toString()}`}
          keyNumber={`sectionRow${sectionIndex.toString()}`}
          rowLabel={i?.lineLabel}
          rowText={i?.lineValue?.toString()}
          rowCopy={i?.lineCopy}
          rowUrl={i?.lineUrl}
          rowMultiLine={i?.lineList}
        />
      ) : null;
    })
    .filter(Boolean);

  return (
    <div
      key={`accordionSection${panelKey}`}
      className="accordion-section p-2 text-lg"
      data-testid="accordion-section"
      style={{ backgroundColor: panelStyles?.hotelOpenPanelBackgroundColour }}
    >
      <ul key={`panelUl${panelKey}`}>{accordionPanelItems}</ul>
    </div>
  );
}
