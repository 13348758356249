import { BREAKPOINTS } from '../responsive-image';
import type { AspectRatio } from '../responsive-image.types';

type AspectRatioandUrlArgs = {
  width: number;
  imageUrlMobile?: string;
  imageUrlTablet?: string;
  imageUrlDesktop?: string;
  aspectRatioMobile?: AspectRatio;
  aspectRatioTablet?: AspectRatio;
  aspectRatioDesktop?: AspectRatio;
};

/**
 * Returns the URL and the Aspect Ratio for the current screen size(mobile, desktop or tablet)
 *
 * @param width
 * @param imageUrlMobile
 * @param imageUrlTablet
 * @param imageUrlDesktop
 * @param imageUrlAllScreens
 * @param aspectRatioMobile
 * @param aspectRatioTablet
 * @param aspectRatioDesktop
 * @param aspectRatioAllScreens
 * @returns
 */

export const getCurrentAspectRatioAndUrl = ({
  width,
  imageUrlMobile,
  imageUrlTablet,
  imageUrlDesktop,
  aspectRatioMobile,
  aspectRatioTablet,
  aspectRatioDesktop,
}: AspectRatioandUrlArgs): { imageUrl: string; aspectRatio: AspectRatio } => {
  const isMobile = width < BREAKPOINTS['sm'];

  const isDesktop = width > BREAKPOINTS['xl'];

  if (isMobile && imageUrlMobile && aspectRatioMobile) {
    return { imageUrl: imageUrlMobile, aspectRatio: aspectRatioMobile };
  }

  if (isDesktop && imageUrlDesktop && aspectRatioDesktop) {
    return { imageUrl: imageUrlDesktop, aspectRatio: aspectRatioDesktop };
  }

  if (!isDesktop && !isMobile && imageUrlTablet && aspectRatioTablet) {
    return { imageUrl: imageUrlTablet, aspectRatio: aspectRatioTablet };
  }

  return { imageUrl: '', aspectRatio: '16:9' };
};
