import { useContext } from 'react';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { format, addHours, intervalToDuration } from 'date-fns';
//https://www.npmjs.com/package/calendar-link
import { google, office365 } from 'calendar-link';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { eventDefaultDurationInHours } from './lib/event-calendar-constants';
import type { CalendarICSProps } from './lib/event-calendar-props';
import { GIS_Array } from '../functions/global-instance-styles';

function runCreateDownloadFile(eventICS, title, confirmMsg) {
  if (window.confirm(confirmMsg)) {
    const aLink = document.createElement('a');
    aLink.innerHTML = 'ICS Download';

    // Make downloadable object
    aLink.href = window.URL.createObjectURL(
      new Blob([eventICS], { type: 'text/calendar;charset=utf-8' })
    );

    let cleanTitle = title.replace(/[^\w\s]/gi, '');
    cleanTitle = cleanTitle.replaceAll(' ', '-');

    aLink.download = cleanTitle.toLowerCase() + '.ics';
    aLink.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(aLink.getAttribute('href'));
    }, 50);
  }
}

export function EventCalendarICS({
  title,
  content,
  instanceStyles,
  globalStyles,
}: CalendarICSProps) {
  const { t } = useTranslation();
  const hotelInfoContext = useContext(EventCalendarInfoContext);

  // Return base hotel string "https://www.hilton.com/en/hotels/ctyhocn-hotel-slug-name" or null
  let hotelUrl;
  if (typeof window !== 'undefined') {
    const baseUrl = `${window.location.protocol}//${window.location.host}/`;

    const urlRegEx = /[a-z]{2}\/(hotels)\/[a-z|-]*\//gi;
    const pageString = window.location.pathname.match(urlRegEx);

    hotelUrl =
      baseUrl && baseUrl !== undefined && pageString && pageString !== undefined
        ? baseUrl + pageString
        : null;
  }

  // Reference: https://pretagteam.com/question/create-ics-file-on-the-fly-using-javascript-or-jquery

  const eventAddress =
    content?.attractionEventLocation || hotelInfoContext?.hotelInfo?.addressStacked;

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  const startDate = content?.startDate;
  const startTime = content?.startTime || '12:00 pm';
  const endDate = content?.endDate || content?.startDate;

  // If there's no start time, the end time has to come after the start time, so 2 hours by default
  // It's also ok to have the end time be null if there's not
  let endTime = '';
  if (content?.endTime) endTime = content?.endTime;
  if (!content?.startTime && !content?.endTime) endTime = '2:00 pm';

  if (eventAddress && eventAddress !== undefined && startDate) {
    // Example: start: [2018, 5, 30, 6, 30,-9], duration: { hours: 6, minutes: 30 },
    const startYear = startDate.slice(0, 4);
    const startMonth = startDate.slice(4, 6);
    const startDayNumber = startDate.slice(6, 8);
    const startMonthDayYear = `${startMonth}/${startDayNumber}/${startYear}`;

    const hotelCorePlusTimeZone = hotelInfoContext?.hotelInfo?.timeZone;
    const timeZoneFormatted =
      hotelCorePlusTimeZone?.indexOf('-') === -1
        ? `${hotelCorePlusTimeZone?.slice(0, 3)}+${hotelCorePlusTimeZone?.slice(3)}`
        : hotelCorePlusTimeZone;
    const startDateTimeObj = new Date(`${startMonthDayYear} ${startTime} ${timeZoneFormatted}`);

    let endMonthDayYear;
    if (endDate && endDate !== startDate) {
      endMonthDayYear = `${endDate.slice(0, 4)}/${endDate.slice(4, 6)}/${endDate.slice(6, 8)}`;
    } else {
      endMonthDayYear = startMonthDayYear;
    }

    // If there is no end time supplied, then the hour value has 2 (eventDefaultDurationInHours) hours added to it.

    const endHourlyTimeAdjusted = !endTime
      ? format(addHours(startDateTimeObj, eventDefaultDurationInHours), 'H:mm')
      : endTime;

    // Make end time object
    const endDateTimeObj = new Date(
      `${endMonthDayYear} ${endHourlyTimeAdjusted} ${timeZoneFormatted}`
    );

    // Factor duration
    const durationObj = intervalToDuration({
      start: startDateTimeObj,
      end: endDateTimeObj,
    });

    const eventDescription = content?.description?.replace(/(<([^>]+)>)/gi, '');

    // ICS download link data - "Tentative" and "Busy" status required for ICS package
    const newEvent = {
      // [Year, Month,Date,Hour, Minutes, GMT]
      start: [
        parseInt(startYear),
        parseInt(format(startDateTimeObj, 'M')),
        parseInt(format(startDateTimeObj, 'd')),
        parseInt(format(startDateTimeObj, 'H')),
        parseInt(format(startDateTimeObj, 'mm')),
      ],

      duration: {
        days: durationObj?.days,
        hours: !endTime ? eventDefaultDurationInHours : durationObj?.hours,
        minutes: durationObj?.minutes,
      },
      productId: hotelInfoContext?.hotelInfo?.hotelName + ' Event Calendar',
      title,
      description: eventDescription,
      location: eventAddress,
      url: hotelUrl || 'https://www.hilton.com',
      geo: {
        lat: hotelInfoContext?.hotelInfo?.latLng?.lat,
        lon: hotelInfoContext?.hotelInfo?.latLng?.lng,
      },
      categories: [content?.category],
      status: 'TENTATIVE',
      busyStatus: 'BUSY',
      organizer: {
        name: hotelInfoContext?.hotelInfo?.hotelName,
        email: hotelInfoContext?.hotelInfo?.email,
      },
    };

    /* eslint-disable */
    const ics = require('ics');

    const eventICS = ics.createEvent(newEvent, (error, value) => {
      if (error) {
        console.error('There was an error creating event.');
        return;
      }

      return value;
    });

    // Make duration in minutes
    const durationInMinutes =
      durationObj?.days * 1440 + durationObj?.hours * 60 + durationObj?.minutes;

    const eventLinkObj: any = {
      title: title,
      description: eventDescription,
      start: startDateTimeObj,
      allDay: false,
      duration: [durationInMinutes, 'minute'],
    };
    const office365Link = office365(eventLinkObj);
    const googleLink = google(eventLinkObj);

    const btnClasses =
      'btn-primary border border-primary mx-0 sm:me-2 mt-1 mb-4 px-0 sm:px-4 py-2 text-center cursor-pointer rounded text-sm font-bold  calendar-additional-button';

    return (
      <div className="mt-4">
        <hr />
        <div>
          <p
            style={{
              color: inlineStyles?.eventsCalBaseTextColor,
            }}
            className="text-sm px-0 py-1"
          >
            {t('calendar.addToCalendar')}
          </p>
        </div>
        <div
          data-testid="addToCalendar"
          data-element-id="add-to-calendar-buttons"
          className="sm:flex sm:justify-start w-full md:w-2/3 mx-auto sm:ml-0"
        >
          <button
            onClick={() => {
              runCreateDownloadFile(eventICS, title, t('calendar.confirmDownload'));
            }}
            id="locationTarget"
            className={cx(btnClasses, 'w-full sm:w-fit text-nowrap underline')}
          >
            iOS/MacOS (.ics)
          </button>

          <a
            href={office365Link}
            rel="noopener noreferrer"
            target="_blank"
            className={cx(btnClasses, 'block sm:w-fit no-underline')}
          >
            Office365
          </a>
          <a
            href={googleLink}
            rel="noopener noreferrer"
            target="_blank"
            className={cx(btnClasses, 'block sm:w-fit no-underline')}
          >
            Google
          </a>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
