import type { DatePickerProps } from '@dx-ui/osc-date-picker';
import { DatePicker } from '@dx-ui/osc-date-picker';
/**
 *
 * @param props
 * @returns Soft wrapper of <DatePicker /> component to maintain compatibility. Once all apps are in monorepo work to deprecate this.
 */

export type ShopFormDatesProps = {
  arrivalDate: Date | null;
  departureDate: Date | null;
  datesFlex: boolean;
};

export const ShopFormDates = (props: DatePickerProps) => <DatePicker {...props} />;
