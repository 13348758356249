export function Play({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <g>
          <g>
            <path d="M12,3.625c-4.6254,0-8.375,3.7496-8.375,8.375S7.3746,20.375,12,20.375s8.375-3.7496,8.375-8.375     C20.3699,7.3767,16.6233,3.6301,12,3.625z M12,19.625c-4.2112,0-7.625-3.4138-7.625-7.625S7.7888,4.375,12,4.375     S19.625,7.7888,19.625,12C19.62,16.2091,16.2091,19.62,12,19.625z" />
            <path d="M10.7246,8.7c-0.1655-0.1244-0.4004-0.0911-0.5248,0.0744C10.1509,8.8394,10.1245,8.9186,10.1246,9v6     c0.0001,0.1418,0.0802,0.2715,0.207,0.335c0.1269,0.064,0.2791,0.0504,0.3926-0.0352l4-3c0.1657-0.1233,0.2-0.3576,0.0767-0.5233     c-0.0217-0.0291-0.0475-0.055-0.0767-0.0767L10.7246,8.7z M10.8746,14.25v-4.5l3,2.25L10.8746,14.25z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
