import cx from 'classnames';

export interface CMSLogoProps {
  id?: string;
  logoUrl?: string;
  classes?: string;
  colour?: string;
  activeColour?: string;
}

export function CMSLogo(props: CMSLogoProps) {
  const cmsClasses = props?.classes?.split(' ');
  const defaultWidthSearch = cmsClasses?.find((el) => el.substring(0, 2) === 'w-');

  const logoStripped =
    props?.logoUrl && props?.logoUrl.includes('/c_fill,w_300,h_300,q_90,f_auto')
      ? props?.logoUrl.replace('/c_fill,w_300,h_300,q_90,f_auto', '')
      : props?.logoUrl;

  return (
    <div
      id={props?.id || undefined}
      className={cx(
        'transition-all duration-300',
        defaultWidthSearch === undefined && 'w-28',
        props?.classes || ''
      )}
      style={{
        backgroundColor: props?.activeColour || props?.colour,
        backgroundSize: 'contain',
        backgroundImage: !props?.colour ? `url('${logoStripped}')` : undefined,
        backgroundPosition: 'center center',
        mask: `url('${logoStripped}')`,
        maskRepeat: 'no-repeat',
        backgroundRepeat: 'no-repeat',
        maskSize: 'contain',
        maskPosition: 'center center',
        WebkitMask: `url('${logoStripped}')`,
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: 'contain',
        WebkitMaskPosition: 'center center',
        height: `90px`,
      }}
    />
  );
}
