export function FaceCovering({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M14.7285,13.2412c-0.6898,0.8087-1.6751,1.3072-2.7353,1.3838c-1.054-0.0751-2.0343-0.5683-2.7227-1.37
                    c-0.1436-0.1494-0.3811-0.1541-0.5305-0.0105c-0.1494,0.1436-0.1541,0.3811-0.0105,0.5305l0,0
                    c0.8278,0.9516,2.0046,1.5287,3.2637,1.6006c1.2663-0.0734,2.4489-0.6565,3.2783-1.6162
                    c0.1417-0.1512,0.1339-0.3885-0.0172-0.5302c-0.1493-0.1399-0.3833-0.1343-0.5258,0.0126V13.2412z"
            />
            <path
              d="M8.877,11.8594c2.0256,0.6891,4.2225,0.6874,6.247-0.0049c0.1948-0.0713,0.2949-0.287,0.2236-0.4818
                    c-0.0699-0.1908-0.2788-0.2915-0.4716-0.2272c-1.8644,0.6377-3.8875,0.6394-5.753,0.0049
                    c-0.1958-0.0679-0.4096,0.0357-0.4775,0.2315S8.6812,11.7915,8.877,11.8594z"
            />
            <path
              d="M9.2139,9.7988c0.8115-0.5823,1.7807-0.9045,2.7793-0.9238c1.0039,0.0196,1.9779,0.3449,2.792,0.9326
                    c0.1699,0.1187,0.4039,0.0773,0.5226-0.0926s0.0773-0.4039-0.0926-0.5226c-0.9402-0.6757-2.0643-1.0482-3.222-1.0674
                    c-1.1513,0.0196-2.2696,0.388-3.2071,1.0566C8.6168,9.3014,8.5767,9.5357,8.6964,9.705C8.8146,9.872,9.0447,9.9137,9.2139,9.7988
                    z"
            />
            <path
              d="M21.375,9.5c0-1.1914-0.8652-1.875-2.375-1.875c-0.2071,0-0.375,0.1679-0.375,0.375S18.7929,8.375,19,8.375
                    c1.0781,0,1.625,0.3789,1.625,1.125c0,0.5546-1.2683,1.8562-2.38,2.81l-0.8766-4.3828c-0.0054-0.0157-0.0119-0.031-0.0194-0.0458
                    c-0.0278-0.0613-0.0644-0.1183-0.1086-0.1691C15.7949,6.4195,13.9385,5.68,12,5.625c-1.9356,0.068-3.7876,0.8056-5.24,2.0869
                    c-0.0138,0.0144-0.0264,0.03-0.0376,0.0465c-0.0367,0.0523-0.0669,0.1089-0.09,0.1684L5.7552,12.31
                    c-1.1119-0.9534-2.38-2.255-2.38-2.81c0-0.7461,0.5469-1.125,1.625-1.125c0.2071,0,0.375-0.1679,0.375-0.375
                    s-0.1679-0.375-0.375-0.375c-1.51,0-2.375,0.6836-2.375,1.875c0,1.175,2.4111,3.2034,3.0648,3.7318
                    C5.998,13.9507,8.0526,18.375,12,18.375s6.0022-4.4243,6.31-5.1432C18.9639,12.7034,21.375,10.675,21.375,9.5z M12,17.625
                    c-3.4086,0-5.3174-4.005-5.6091-4.6653l0.95-4.7511C8.6399,7.0848,10.2835,6.4379,12,6.375
                    c1.7204,0.0454,3.3698,0.6951,4.6591,1.835l0.95,4.7492C17.32,13.6168,15.4171,17.625,12,17.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
