import { useLocalStorage } from 'usehooks-ts';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useEffect } from 'react';

export type SMBContextType = 'personal' | 'business';

export const useSMBContextLocalStorage = (enabled: boolean) => {
  const { guestInfo } = useAuth();
  const [smbContext, setSMBContext] = useLocalStorage<SMBContextType | null>('smbContext', null);

  useEffect(() => {
    const isSMBMemberButNoContextSet = Boolean(
      guestInfo?.hhonors?.isSMBMember && !smbContext && enabled
    );

    if (isSMBMemberButNoContextSet) {
      setSMBContext('personal');
    }
  }, [enabled, guestInfo?.hhonors?.isSMBMember, setSMBContext, smbContext]);

  return { isSMBContextSet: Boolean(smbContext), smbContext, setSMBContext };
};
