import gql from 'graphql-tag-transform.macro';

export const SocialMediaFeed = gql`
  fragment SocialMediaFeed on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_SocialMediaFeed {
      feedId
      fieldGroupName
      socialLinkTitle
      socialLinkUrl
      socialTitle
      socialMediaFeedSettings {
        fieldGroupName
        socialMediaIconColor
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
