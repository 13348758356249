import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./laxbhhh/hotel-logo').then((i) => i.HotelLogo)
);
const HotelLogoVertical = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./laxbhhh/hotel-logo-vertical').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-gold w-44 text-bg antialiased md:w-48 lg:w-44" fillColor="#BA0C2F" />
);

export const hotelLogoFooter = (
  <HotelLogoVertical className="fill-gold h-20 w-28 antialiased md:w-auto" fillColor="#FFFFFF" />
);
