/**
 * HILTON HOTEL & RESORTS (NEW) BRAND PROPS
 */
const primaryColor = '#002f61';
const secondaryColor = '#f0e9e6';

export const brandHeaderProps = {
  separatorColor: '#000000',
  textColorOnLoad: '#000000',
  bgColorOnLoad: '#ffffff',
  textColorOnActive: '#000000',
  bgColorOnActive: '#ffffff',
  bgSubmenuColor: '#ffffff',
  dividerColourActive: '#000000',
  dividerColour: '#000000',
  menuItems: {
    colorOnHover: primaryColor,
    paddingClasses: undefined,
    case: undefined,
    underline: {
      active: true,
      color: primaryColor,
    },
    itemActive: {
      bgColor: undefined,
      bgText: 'rgba(9, 25, 78, 1)',
      classes: 'text-secondary',
    },
  },
  iconMenu: {
    extraClasses: undefined,
    fontSize: undefined,
    separatorColor: '#000000',
    underline: true,
    color: '#000000',
    activeColor: '#000000',
    iconColor: '#000000',
    activeIconColor: '#000000',
  },
  honorsSignIn: {
    backgroundColor: secondaryColor,
    backgroundHighlightColor: secondaryColor,
    textColor: '#000000',
    textHighlightColor: '#000000',
  },
  bookingButton: {
    mainClasses: undefined,
    extraClasses: undefined,
    backgroundColor: primaryColor,
    backgroundColorHover: '#000000',
    textColor: '#ffffff',
    textColorHover: '#ffffff',
    textCase: undefined,
    borderColor: primaryColor,
    borderColorHover: '#000000',
  },
  languageSwitchTitleStyles: {
    textCase: null,
    textColour: '#000000',
    textColourActive: '#000000',
    textColourActiveHover: '#000000',
    textColourHover: '#000000',
  },
  languageSwitchDropdownStyles: {
    textColourHover: '#000000',
    textColour: '#000000',
    dividerColour: '#000000',
    backgroundColourHover: secondaryColor,
    backgroundColour: '#ffffff',
  },
  languageSwitchButtonStyles: {
    backgroundColour: '#ffffff',
    backgroundColourHover: '#ffffff',
    textCase: null,
    textColour: '#000000',
    textColourHover: '#000000',
  },
  languageSwitchButtonStylesActive: {
    textColourHover: '#000000',
    textColour: '#000000',
    textCase: null,
    backgroundColourHover: '#ffffff',
    backgroundColour: '#ffffff',
  },
};

export const brandLogoProps = {
  hotelLogoColourActive: primaryColor,
  hotelLogoColourInactive: primaryColor,
  brandLogoColourActive: primaryColor,
  brandLogoColourInactive: primaryColor,
  brandLogoColourFooter: '#ffffff',
  hotelLogoColourFooter: '#ffffff',
};

export const brandFooterProps = {
  backgroundColour: primaryColor,
  textColour: '#ffffff',
  dividerColour: '#ffffff',
  copyrightBackgroundColour: primaryColor,
  copyrightTextColour: '#ffffff',
};

export const brandModalProps = {
  modalCloseButtonIconColour: '#ffffff',
  modalCloseButtonBackgroundColour: primaryColor,
  modalBackgroundOpacity: 0.9,
  modalBackgroundColour: '#ffffff',
  hideBoxShadow: true,
  contentBackgroundColour: '#ffffff',
};

export const brandBreadcrumbProps = {
  activeTextColour: '#000000',
  backgroundColour: secondaryColor,
  delimiter: null,
  delimiterColour: '#000000',
  delimiterPadding: null,
  textColour: '#000000',
};
