import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksThreeOneTopTwoBottomInterface } from './interfaces';

export function MediaBlocksThreeOneTopTwoBottom(props: MediaBlocksThreeOneTopTwoBottomInterface) {
  return (
    <div className="grid size-full grid-rows-2" data-testid="media-blocks-three-one-top-two-bottom">
      <div
        className="relative flex items-center justify-center overflow-hidden bg-blue-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderBottomWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageTop?.sourceUrl}
          alt={props?.imageTop?.altText}
          loader={() => {
            return customLoader({
              width: props?.maxImageWidth || undefined,
              src: props?.imageTop?.sourceUrl,
              crop: props?.imageTopCrop?.imageCrop21AddCrop && true,
              cropType: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropType,
              cropHeight: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
      <div
        className="grid grid-cols-2 bg-green-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderTopWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <div
          className="relative flex items-center justify-center overflow-hidden bg-red-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderRightWidth: `${props.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageBottomLeft?.sourceUrl}
            alt={props?.imageBottomLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomLeft?.sourceUrl,
                crop: props?.imageBottomLeftCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
        <div
          className="relative flex items-center justify-center overflow-hidden bg-yellow-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderLeftWidth: `${props.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageBottomRight?.sourceUrl}
            alt={props?.imageBottomRight?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomRight?.sourceUrl,
                crop: props?.imageBottomRightCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageBottomRightCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  );
}
