import { type SVGAttributes } from 'react';

const CancellationIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <g strokeWidth="1.5">
          <path d="M27.042 14.653l-5.6 5.605-5.523-5.528-2.232 2.227 5.565 5.563-5.549 5.547 2.268 2.268 5.58-5.58 5.501 5.503 2.265-2.27-5.58-5.579 5.531-5.53-2.226-2.226zM21.492 38.5c-8.81-.012-16.028-7.245-15.992-16.028C5.536 13.66 12.722 6.49 21.508 6.5c8.81.011 16.027 7.245 15.992 16.027-.037 8.814-7.223 15.984-16.009 15.973z" />
          <path
            fill="currentColor"
            d="M27.042 14.653l2.226 2.226-5.532 5.53 5.581 5.58-2.265 2.27-5.502-5.504c-1.884 1.884-3.74 3.743-5.579 5.58l-2.268-2.268 5.55-5.547-5.566-5.563 2.232-2.227 5.523 5.528 5.6-5.605z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CancellationIcon;
