export function FreeNights({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M13.7972,12.375H16a.3754.3754,0,0,0,.2656-.1094l4-4a.3778.3778,0,0,0,0-.5312l-4-4a.376.376,0,0,0-.5312,0l-4,4A.3754.3754,0,0,0,11.625,8v2.2a5.3549,5.3549,0,0,0-2.0716,1.8467,7.5624,7.5624,0,0,1-.4977-7.1786A.375.375,0,0,0,8.56,4.3721,8.37,8.37,0,1,0,19.6279,15.44a.375.375,0,0,0-.4961-.4961,7.56,7.56,0,0,1-7.1471-.4785A5.3957,5.3957,0,0,0,13.7972,12.375ZM12.375,10.4169V8.1553L16,4.53,19.47,8l-3.625,3.625h-1.748A5.3033,5.3033,0,0,0,14.375,10a.375.375,0,0,0-.75,0,4.5768,4.5768,0,0,1-.31,1.625h-.94Zm3.627,5.9552a8.2755,8.2755,0,0,0,2.4863-.3809A7.6194,7.6194,0,1,1,8.0088,5.5117,8.3083,8.3083,0,0,0,9.2642,12.95a.3524.3524,0,0,0,.026.0347,8.4466,8.4466,0,0,0,1.2578,1.3546A4.5688,4.5688,0,0,1,9,14.625a.375.375,0,0,0,0,.75,5.321,5.321,0,0,0,2.2346-.5015A8.3215,8.3215,0,0,0,16.002,16.3721Zm-4.7014-2.3842A7.68,7.68,0,0,1,10.02,12.71,4.647,4.647,0,0,1,11.625,11.052V12a.3751.3751,0,0,0,.375.375h.9475A4.628,4.628,0,0,1,11.3006,13.9879Z" />
      </g>
    </svg>
  );
}
