interface MinLOS {
  [key: string]: number;
}

const minLOS: MinLOS = {
  orlemqq: 2,
};

export const getMinLengthOfStay = (ctyhocn: string): number => {
  return minLOS[ctyhocn?.toLowerCase() || ''] ?? 1;
};
