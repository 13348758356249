import type { StyleObject } from '../../functions/global-instance-styles';

// Breakpoint for switch between mobile and desktop calendar view
export const breakToColumnWidth = 1024;

export interface EventCalendarModalSettings {
  modalBackgroundColour?: string;
  modalBackgroundOpacity?: number;
  modalCloseButtonBackgroundColour?: string;
  modalCloseButtonIconColour?: string;
}

export interface EventCalendarDayEventInfo {
  allDay?: boolean;
  endTime?: string;
  fieldGroupName?: string;
  scheduled?: boolean;
  startTime?: string;
}

export interface EventCalendarHotelInfoProps {
  hotelName?: string;
  hotelLocale?: {
    coordinate?: {
      latitude?: number;
      longitude?: number;
    };
    currency?: {
      description?: string;
    };
    gmtHours?: number;
    gmtHoursFmt?: string;
    timeZone: string;
    currencyCode?: string;
  };
  hotelAddress?: {
    addressFmt?: string;
    addressStacked?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    city?: string;
    country?: string;
    countryName?: string;
    postalCode?: string;
    state?: string;
    stateName?: string;
    primeCity?: string;
  };
  hotelContact?: {
    emailAddress1?: string;
    faxNumber?: string;
    phoneNumber?: string;
  };
}

export interface EventCalendarHotelContextInfoProps {
  hotelName?: string;
  gmtHours?: number;
  gmtHoursFmt?: string;
  timeZone?: string;
  addressStacked?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  country?: string;
  countryName?: string;
  latLng: {
    lat?: number;
    lng?: number;
  };
  email?: string;
  phone?: string;
}

export interface CalendarButtonProps {
  buttonStyle?: string;
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
}

export interface EventImageProps {
  sourceUrl?: string;
  altText?: string;
  title?: string;
  translated?: {
    id?: string;
  };
}

export interface SpecialEventsContentProps {
  attractionEventLocation?: string;
  category?: string;
  description?: string;
  distanceFromResort?: string;
  endDate?: string;
  endTime?: string;
  fieldGroupName?: string;
  locationAtResort?: string;
  onSiteOrOffSite?: string;
  startDate?: string;
  startTime?: string;
  image?: EventImageProps;
  buttons?: Array<CalendarButtonProps>;
}

export interface SpecialEventProps {
  title?: string;
  calendarId?: number;
  calendarOfEvents?: SpecialEventsContentProps;
}

export interface SpecialEventPropsForType extends SpecialEventProps {
  node?: SpecialEventProps;
}

export interface EventCalendarSpecialEventProps {
  node?: SpecialEventProps;
}

export interface RegularlyScheduledEventContentProps {
  attractionEventLocation?: string;
  category?: string;
  description?: string;
  distanceFromResort?: string;
  fieldGroupName?: string;
  locationAtResort?: string;
  onSiteOrOffSite?: string;
  eventDisplayEndDate?: string;
  eventDisplayStartDate?: string;
  image?: EventImageProps;
  buttons?: Array<CalendarButtonProps>;
  allDays?: {
    allDay?: boolean;
    endTime?: string;
    fieldGroupName?: string;
    scheduled?: boolean;
    startTime?: string;
    heldDaily?: boolean;
  };
  fridaySchedule?: EventCalendarDayEventInfo;
  mondaySchedule?: EventCalendarDayEventInfo;
  saturdaySchedule?: EventCalendarDayEventInfo;
  sundaySchedule?: EventCalendarDayEventInfo;
  thursdaySchedule?: EventCalendarDayEventInfo;
  tuesdaySchedule?: EventCalendarDayEventInfo;
  wednesdaySchedule?: EventCalendarDayEventInfo;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  timeObj?: {
    startDate?: string;
    endDate?: string;
    startTime?: string;
    endTime?: string;
  };
}

export interface EventCalendarModalSettingsProps {
  modalBackgroundOpacity?: number;
  modalBackgroundColour?: string;
  modalCloseButtonBackgroundColour?: string;
  modalCloseButtonIconColour?: string;
  contentBackgroundColour?: string;
  hideBoxShadow?: boolean | null;
}

export interface RegularEventCombinedItemProps {
  title?: string;
  id?: string;
  databaseId?: number;
  slug?: string;
  RegularlyScheduledEvents?: RegularlyScheduledEventContentProps;
}

export interface EventCalendarRegularEventProps {
  node?: RegularEventCombinedItemProps;
}

export interface RegularSpecialEventCombinedProps
  extends RegularEventCombinedItemProps,
    SpecialEventProps {}

export interface LineItemProps {
  regularOrSpecialEvent: string;
  dateStringNom?: string;
  dateStringNoDashes?: string;
  eventDateStr?: string;
  eventTitle?: string;
  eventTimes?: string;
  eventContent?: RegularSpecialEventCombinedProps;
  eventLineItemLocale?: string;
  eventLineItemCategory?: string;
  dateNumber: number;
  showHide?: boolean;
  disabledState?: boolean;
  dayOfWeek?: number | undefined;
  multiDateDaysRemaning?: number | null;
}

export interface Locales {
  [index: number]: string;
}

export type Categories = {
  [index: number]: string;
};

export interface EventCalendarDayProps {
  dateStringNoDashes?: string | null;
  dateStringNom?: string;
  monthName?: string;
  dateNumber?: number;
  specialEventEntryArray?: any | null;
  regularEventsArray?: any | null;
  dayOfWeekKey?: number | null;
  selectedLocales?: Array<Locales>; // New
  selectedCategories?: Array<Categories>;
  disabledState?: boolean;
  multiDateEventsInWeek?: any;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export interface EventCalendarModalProps {
  title?: string;
  eventDateNumber: number;
  dateStringNom: string;
  content?: RegularSpecialEventCombinedProps;
  eventType?: string; // recurring or special
  hideICS?: boolean | false;
}

export interface CombinedEventContentProps
  extends RegularlyScheduledEventContentProps,
    SpecialEventsContentProps {
  node?: RegularEventCombinedItemProps | SpecialEventProps;
}

export interface CalendarICSProps {
  title?: string;
  content?: CombinedEventContentProps;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export interface EventCalendarInlineStyleProps {
  eventsCalendarComponentBgColor?: string;
  eventsCalendarDesktopGridBgColor?: string;
  eventsCalendarDatePickerBgColor?: string;
  eventsCalAdditionalBtnBg?: string;
  eventsCalAdditionalBtnBgHover?: string;
  eventsCalAdditionalBtnBorderColor?: string;
  eventsCalAdditionalBtnBorderColorHover?: string;
  eventsCalAdditionalBtnText?: string;
  eventsCalAdditionalBtnTextHover?: string;
  eventsCalRegularBtnBg?: string;
  eventsCalRegularBtnBgHover?: string;
  eventsCalRegularBtnBorderColor?: string;
  eventsCalRegularBtnBorderColorHover?: string;
  eventsCalRegularBtnText?: string;
  eventsCalRegularBtnTextHover?: string;
  eventsCalSpecialBtnBg?: string;
  eventsCalSpecialBtnBgHover?: string;
  eventsCalSpecialBtnBorderColor?: string;
  eventsCalSpecialBtnBorderColorHover?: string;
  eventsCalSpecialBtnText?: string;
  eventsCalSpecialBtnTextHover?: string;
  eventsCalDailyEventsSectionBgColor?: string;
  eventsCalAdditionalTextFilterColor?: string;
  eventsCalBaseIconColor?: string;
  eventsCalBaseTextColor?: string;
  eventsCalDatePickerResetButtonBg?: string;
  eventsCalDatePickerResetButtonText?: string;
  eventsCalfilterCheckboxColor?: string;
  eventsCalSelectedDatePickerDateBg?: string;
  eventsCalSelectedDatePickerDateRangeBg?: string;
  eventsCalSelectedDatepickerDateText?: string;
  eventsCalUsesRegularEventButtonColors?: boolean;
}
