import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';

interface PopupContentProps {
  children: any;
  className?: string;
  setOpen: (val: boolean) => void;
}

export function PopupContent({ children, className, setOpen }: PopupContentProps) {
  return (
    <Popover.Portal>
      <Popover.Content
        side="bottom"
        align="start"
        className={cx(
          'bg-bg border-border z-30 border border-solid p-2 shadow-lg focus:outline-none',
          className
        )}
        onCloseAutoFocus={() => {
          setOpen(false);
        }}
      >
        <div data-testid="popoverContent" className="size-full">
          {children}
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
}
