import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./chiphhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-20 w-40 fill-current text-bg antialiased md:mr-2 md:w-48" />
);

export const hotelLogoFooter = <HotelLogo className="h-20 w-32 fill-current text-bg antialiased" />;
