import type { Maybe } from '@dx-ui/gql-types';
import { Accordion } from '@dx-ui/osc-accordion';
import { useTranslation } from 'next-i18next';
import type { GenericModalProps } from '../../global/generic-modal';
import { GenericModal, DialogTitle } from '../../global/generic-modal';
import { ImageSlider } from '../../image-slider/image-slider';
import { HeadingStyle } from '../../includes/heading-style';
import contentStyles from '../../css/contentStyle.module.css';
import cx from 'classnames';
import type { ArrayElement, GuestsState, Icon, mergeRooms } from '../utils';
import { getIcon } from '../utils';
import type { RoomsCTAProps } from './rooms-cta';
import { RoomsCTA } from './rooms-cta';
import { HandleWYSIWYGContent } from '../../functions/helper';
import { sanitize } from '@curated-property/utils';
import { sendReward } from '@dx-ui/framework-conductrics';
import { AnchorLink } from '../../global/anchor-link';
import type { MergedWpRoomType } from '../buildFilters';

export interface RoomDetailsModalProps extends GenericModalProps {
  room?: ArrayElement<ReturnType<typeof mergeRooms>>;
  className?: string;
  hasPrices?: boolean;
  rate?: string;
  arrivalDate?: string;
  departureDate?: Maybe<string>;
  ctyhocn?: string;
  textAlignment?: any;
  currency?: any;
  resEnabled?: boolean;
  associatedHotels?: string[];
  guests?: GuestsState;
  icons?: Icon[];
  ctaData?: RoomsCTAProps;
  inlineStyles?: {
    [name: string]: any;
    roomDetailsModalBackgroundColor?: string;
    roomDetailsModalTextColor?: string;
    roomDetailsModalHeadingColor?: string;
    roomDetailsModalAccentColor?: string;
    roomDetailsModalIconColor?: string;
    modalActiveBookCtaBackgroundColor?: string;
    modalActiveBookCtaBorderColor?: string;
    modalActiveBookCtaTextColor?: string;
    modalBookCtaBackgroundColor?: string;
    modalBookCtaBorderColor?: string;
    modalBookCtaTextColor?: string;
  };
  enableHHR?: boolean;
}

export const RoomDetailsModal = (props: RoomDetailsModalProps) => {
  const {
    room,
    ctaData,
    resEnabled,
    associatedHotels,
    inlineStyles,
    icons,
    dialogTrigger,
    modalActive,
  } = props;

  const { t } = useTranslation();

  if (!room) return null;

  const wpImages = room?.carouselImages?.map((i) => ({
    url: i?.image?.sourceUrl || '',
    alt: i?.image?.altText || '',
  }));

  const corePlusImages = room?.carousel?.map((img) => {
    const mediumVariant = img?.variants?.find((innerImg) => innerImg?.size === 'md');
    return {
      url: mediumVariant?.url.replace('rw=528', 'rw=807').replace('rh=316', 'rh=484') || '',
      alt: img?.altText || '',
    };
  });

  const featureGroups = {
    comfort: {
      label: t('rooms.forYourComfort'),
      features: room?.features?.filter((feat) => feat?.category === 'comfort'),
    },
    convenience: {
      label: t('rooms.forYourConvenience'),
      features: room?.features?.filter((feat) => feat?.category === 'convenience'),
    },
    confidence: {
      label: t('rooms.forYourConfidence'),
      features: room?.features?.filter((feat) => feat?.category === 'confidence'),
    },
  } as const;

  const accordionItems = Object.entries(featureGroups)?.map(([featGroup]) => ({
    expandedButtonLabel: featureGroups[featGroup]?.label,
    collapsedButtonLabel: featureGroups[featGroup]?.label,
    key: featureGroups[featGroup]?.label,
    content: (
      <ul className="list-disc p-4">
        {featureGroups[featGroup]?.features?.map((feat) => (
          <li
            className="my-1 ml-8"
            style={{
              color: inlineStyles?.roomDetailsModalTextColor || undefined,
            }}
          >
            {feat.name}
          </li>
        ))}
      </ul>
    ),
  }));

  const iconColorStyling = inlineStyles?.roomDetailsModalIconColor
    ? `.rooms-modal-accordion svg {
              fill: ${inlineStyles?.roomDetailsModalIconColor};
            }`
    : '';

  const accordionDividerStyling = inlineStyles?.roomDetailsModalAccentColor
    ? `[data-osc="accordion-item"] .border-b-border-alt,
                          [data-osc="accordion-item"] button.rooms-modal-accordion {
                            border-color: ${inlineStyles?.roomDetailsModalAccentColor} !important; }  `
    : '';

  const accordionPanelLabelColor = inlineStyles?.roomDetailsModalTextColor
    ? `
    [data-osc="accordion-item"] button.rooms-modal-accordion {
      color: ${inlineStyles?.roomDetailsModalTextColor} !important;
    }`
    : '';

  return (
    <GenericModal
      {...props}
      modalActive={modalActive}
      dialogTrigger={dialogTrigger}
      modalChild={
        <div
          className="container w-fit max-w-screen-2xl px-4 py-16"
          data-element-id="room-details-modal-content"
          style={{
            backgroundColor: inlineStyles?.roomDetailsModalBackgroundColor || undefined,
          }}
        >
          {iconColorStyling || accordionDividerStyling || accordionPanelLabelColor ? (
            <style>
              {`
            ${iconColorStyling}
            ${accordionDividerStyling}
            ${accordionPanelLabelColor}
          `}
            </style>
          ) : (
            ''
          )}
          <div className="flex flex-col lg:flex-row">
            {/* Copy */}
            <div className="w-full lg:w-1/2 lg:px-8">
              <DialogTitle asChild>
                <HeadingStyle
                  styledAs="h3"
                  style={{ color: inlineStyles?.roomDetailsModalHeadingColor }}
                  text={props?.room?.roomTypeName}
                />
              </DialogTitle>

              <div
                className={cx(
                  'wp-content mt-8 text-base',
                  contentStyles.listStyle,
                  contentStyles.paragraphStyle
                )}
                style={{
                  color: inlineStyles?.roomDetailsModalTextColor || undefined,
                }}
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(props?.room?.desc || ''),
                }}
              />
              <div className="mt-8">
                {props?.hasPrices && !props?.room?.moreRatesFromRate && (
                  <button
                    disabled={true}
                    className="popunder-cta btn btn-primary w-full py-3  text-center font-bold rtl:ml-3 rtl:mr-0"
                  >
                    {t('roomNotAvailable')}
                  </button>
                )}
                {/* If none of the rooms have prices we don't really know if this room is available or not, show a button that just says "check check availability" */}
                {/* todo: OHW link builder to go to rooms page (or rates page) */}
                {resEnabled || associatedHotels?.length > 0 ? (
                  <RoomsCTA
                    {...ctaData}
                    inlineStyles={props?.inlineStyles}
                    onClick={() => sendReward('EngageCheckRatesCTAOnModal')}
                    isInModal={true}
                  />
                ) : null}
              </div>
              <div>
                {room?.moreRatesFromRate?.serviceChargeDetails && (
                  <div
                    className="text-primary my-0 mt-4 pb-2 text-left text-xs lg:text-sm"
                    data-testid="feeTransparencyInPopunder"
                    data-element-id="fee-transparency-popunder"
                    style={{
                      color: inlineStyles?.feeTransparencyTextInExpandedDropdown
                        ? inlineStyles?.feeTransparencyTextInExpandedDropdown
                        : null,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(room?.moreRatesFromRate?.serviceChargeDetails || ''),
                    }}
                  />
                )}
              </div>
            </div>
            {/* Image Slider */}
            <div className="mt-8 flex w-full flex-col justify-center lg:mt-0 lg:w-1/2">
              {!corePlusImages.length && (!wpImages || wpImages === undefined) ? (
                ''
              ) : (
                <div
                  data-element-id="room-gallery"
                  className="relative w-full max-w-3xl self-start"
                >
                  <ImageSlider
                    images={wpImages ? wpImages : corePlusImages}
                    sectionHeading={room?.roomTypeName}
                    controlLayout="overlayed"
                    styleOptions={{
                      uiBackgroundColour: inlineStyles?.controlsBackgroundColor,
                      uiControlsColour: inlineStyles?.controlsIconColor,
                      paginationBackgroundColour: inlineStyles?.paginationBackgroundColor,
                      paginationTextColour: inlineStyles?.paginationTextColor,
                      paginationSeperatorColour: inlineStyles?.paginationSeperatorColor,
                      sliderAnimations: inlineStyles?.sliderAnimations,
                    }}
                  />
                </div>
              )}
              <div className="flex justify-end">
                {(room as MergedWpRoomType)?.floorPlanCtaUrl && (
                  <AnchorLink
                    title={
                      (room as MergedWpRoomType)?.floorPlanCtaLabel || t('rooms.viewFloorPlan')
                    }
                    url={(room as MergedWpRoomType)?.floorPlanCtaUrl}
                    buttonStyle={(room as MergedWpRoomType)?.buttonStyle || 'secondary'}
                    target="_blank"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col justify-between sm:flex-row">
            {/* bottom left */}
            <div className="w-full sm:w-1/2">
              <div className="px-8">
                <HeadingStyle
                  styledAs="h6"
                  className="font-bold"
                  text={t('rooms.roomHighlights')}
                />
                <div className="bg-pink mt-4">
                  <ul className="list-none">
                    {icons
                      .filter((icon) => getIcon(icon).name)
                      .map((icon) => {
                        const iconData = getIcon(icon);
                        return (
                          <li
                            key={iconData.name}
                            className="border-b-border-alt flex h-12 border-b"
                            style={{
                              color: inlineStyles?.roomDetailsModalTextColor || undefined,
                              borderBottomColor:
                                inlineStyles?.roomDetailsModalAccentColor || undefined,
                            }}
                          >
                            <div className="justify-left flex items-center">
                              {iconData.Icon ? (
                                <iconData.Icon
                                  fillColor={inlineStyles?.roomDetailsModalIconColor || undefined}
                                  className="mr-4 size-7"
                                />
                              ) : undefined}
                              <span
                                style={{
                                  color: inlineStyles?.roomDetailsModalTextColor || undefined,
                                }}
                              >
                                {iconData.name}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            {/* bottom right */}
            <div className="mt-8 w-full sm:mt-0 sm:w-1/2">
              <div className="flex flex-col px-8">
                <HeadingStyle
                  styledAs="h6"
                  className="font-bold"
                  text={t('rooms.moreRoomDetails')}
                />
                {[featureGroups].length > 0 && accordionItems && (
                  <div className="mt-4 w-full" data-testid="modal-accordion">
                    <Accordion
                      className="rooms-modal-accordion accordion-stacked border-b-border-alt flex h-12 w-full items-center justify-between border-b aria-expanded:border-b [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&_span]:flex [&_span]:items-center"
                      items={accordionItems}
                      type="multiple"
                      variant="stacked"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};
