import { useState, useEffect } from 'react';
import { slugify, iconmapper, HandleAnimations } from '../functions/helper';
import { ArrowHead } from '@curated-property/icon-list';
import type { Category } from './interactive-map.types';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { sanitize } from '@curated-property/utils';

export function MapCategories({
  mapCategoryTitle,
  mapCategoryDescription,
  showMobileCategoryAbove,
  categories,
  selectedCategory,
  setSelectedCategory,
  textAlignment,
  titleColor,
  subtitleColor,
  selectedTabBackgroundColor,
  tabBackgroundColor,
  selectedTabTextColor,
  tabTextColor,
  contentBackgroundColor,
  contentBackgroundImage,
  contentBackgroundSize,
  contentBackgroundPosition,
  contentBackgroundRepeat,
  mobileFilterDropdownBackgroundColor,
  mobileFilterDropdownTextColor,
  wWidth,
  mapOverlayPosition,
  hideAnimations,
  animationDirection,
}: Category) {
  const initCatToggle = wWidth >= 1024 ? true : false;
  const [catToggle, setCatToggle] = useState<boolean>(initCatToggle);
  const { t } = useTranslation();
  const iconMap = iconmapper();
  const categoriesHeight = (categories?.length ?? 0) * 40 + 10;

  useEffect(() => {
    setCatToggle(initCatToggle);
  }, [wWidth, setCatToggle, initCatToggle]);

  const handleCategorySelect = (pin?: string) => {
    if (selectedCategory === pin) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(pin);
    }
    if (catToggle) {
      setCatToggle(false);
    }
  };

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: animationDirection
      ? `${animationDirection}-8`
      : mapOverlayPosition === 'left'
      ? '-translate-x-8'
      : 'translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-0',
  });

  return (
    <div
      ref={animations?.ref}
      className={cx(
        'bg-bg absolute z-20 min-w-[35%] shadow-md lg:static lg:left-auto lg:top-auto lg:w-auto lg:translate-x-0 lg:translate-y-0 lg:p-11 lg:shadow-none',
        {
          'w-full -translate-y-[46px]': showMobileCategoryAbove,
          'w-5/6 left-2/4 -translate-x-1/2 -translate-y-6': !showMobileCategoryAbove,
        }
      )}
      style={{
        backgroundImage: contentBackgroundImage ? `url('${contentBackgroundImage}')` : undefined,
        backgroundSize: contentBackgroundSize || undefined,
        backgroundRepeat: contentBackgroundRepeat || undefined,
        backgroundPosition: contentBackgroundPosition || undefined,
        backgroundColor: contentBackgroundColor || undefined,
      }}
    >
      <button
        data-testid="categoryToggle"
        data-element-id="category-toggle-mobile"
        onClick={() => setCatToggle(!catToggle)}
        className="text-primary bg-bg border-overlay w-full border px-3 py-2 text-lg font-bold lg:hidden"
        style={{
          backgroundColor: mobileFilterDropdownBackgroundColor,
          color: mobileFilterDropdownTextColor || titleColor,
        }}
        aria-expanded={catToggle}
      >
        <div className={cx('flex items-center justify-between', animations?.one)}>
          {t('filterMap')}
          <ArrowHead
            className={cx('ml-3 h-5 transition-all', {
              'rotate-180': catToggle,
            })}
            fillColor={mobileFilterDropdownTextColor || contentBackgroundColor}
          />
        </div>
      </button>
      <h2
        className={cx(
          'text-primary font-headline hidden items-center pb-4 text-3xl font-extrabold lg:flex',
          textAlignment,
          animations?.one
        )}
        style={{
          color: titleColor,
        }}
      >
        {mapCategoryTitle ? mapCategoryTitle : t('exploreTheResort')}
      </h2>
      {mapCategoryDescription ? (
        <p
          className={cx('hidden pb-6 text-lg lg:flex', textAlignment, animations?.two)}
          style={{
            color: subtitleColor,
          }}
        >
          {mapCategoryDescription}
        </p>
      ) : null}
      <div
        data-element-id="interactive-map-category-btns"
        className={cx(
          'flex max-h-0 flex-col gap-px overflow-hidden transition-all duration-500 lg:overflow-visible lg:pb-4',
          animations?.three
        )}
        style={{ maxHeight: catToggle ? categoriesHeight : undefined }}
      >
        {categories
          ?.filter(
            (pin, idx, self) => idx === self.findIndex((p) => p?.pinCategory === pin?.pinCategory)
          )
          ?.map((pin, key) => {
            const iconKey: string = pin?.pinIcon || 'hotel';
            const isCatSelected: boolean =
              selectedCategory === pin?.pinCategory || !selectedCategory;
            const Icon = iconMap[iconKey as keyof typeof iconMap];
            const buttonColor = isCatSelected ? selectedTabTextColor : tabTextColor;
            const buttonBackground = isCatSelected
              ? selectedTabBackgroundColor
              : tabBackgroundColor;
            return (
              <button
                data-testid={`${slugify(pin?.pinCategory)}-category`}
                key={key}
                className={cx('flex h-10 items-center py-2 transition-all', {
                  'bg-primary text-bg z-10': isCatSelected,
                  'bg-bg-alt text-text z-0':
                    selectedCategory && selectedCategory !== pin?.pinCategory,
                })}
                style={{
                  backgroundColor: buttonBackground,
                  color: buttonColor,
                }}
                onClick={() => handleCategorySelect(pin?.pinCategory)}
                aria-label={`${pin?.pinCategory}, ${
                  isCatSelected ? t('selected') : t('unselected')
                }`}
              >
                <Icon className="mx-2 h-7 fill-current" />
                <span
                  className="color-text-alt pb-0.5 text-base"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(pin?.pinCategory),
                  }}
                />
              </button>
            );
          })}
      </div>
    </div>
  );
}
