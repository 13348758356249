export function OpenNewTab({
  className,
  fillColor,
  lang,
  strokeColor,
}: {
  className?: string;
  fillColor?: string;
  lang?: string;
  strokeColor?: string;
}) {
  return (
    <svg
      aria-label={lang === 'es' ? 'abre una nueva ventana' : 'opens new window'}
      viewBox="0 0 10 10"
      width="14"
      height="14"
      className={className}
      style={{ fill: fillColor, stroke: strokeColor }}
    >
      <g data-name="open-new-tab">
        <path d="M3.6 1.3h5.3v5.3H3.6z" data-name="Layer 1" />
        <path d="M1.3 8.8V4.6h2.3v2h1.9v2.2z" data-name="Layer 2" />
      </g>
    </svg>
  );
}
