const WeddingsLogo = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="130px"
      height="130px"
      viewBox="0 0 640.000000 640.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
        fill={fillColor}
        stroke="none"
      >
        <path d="M0 3200 l0 -3200 3200 0 3200 0 0 3200 0 3200 -3200 0 -3200 0 0 -3200z m6360 0 l0 -3160 -3160 0 -3160 0 0 3160 0 3160 3160 0 3160 0 0 -3160z" />
        <path d="M4590 5416 c-53 -36 -153 -193 -221 -347 -39 -91 -119 -328 -119 -356 0 -26 -2 -27 -50 -13 -58 17 -98 -2 -173 -80 -37 -38 -72 -70 -78 -70 -6 0 -38 -20 -72 -44 -117 -83 -168 -97 -189 -51 -13 29 4 81 23 70 37 -23 156 53 177 114 13 38 -12 92 -49 105 -69 24 -171 -47 -205 -143 -25 -72 -26 -74 -105 -113 -77 -40 -119 -47 -138 -25 -13 17 0 66 29 107 25 35 19 50 -20 50 -44 0 -60 10 -60 37 0 19 -4 24 -22 21 -15 -2 -33 -22 -56 -63 -18 -33 -48 -75 -65 -93 -42 -43 -116 -90 -154 -98 -29 -6 -30 -5 -36 36 -6 47 -31 80 -59 80 -10 0 -50 -29 -88 -65 -67 -63 -84 -73 -94 -57 -6 10 43 112 55 112 5 0 9 9 9 20 0 15 -7 20 -25 20 -19 0 -31 -11 -55 -52 -38 -67 -39 -109 -4 -137 46 -36 78 -26 158 48 26 24 40 32 43 23 3 -7 16 -26 30 -42 30 -36 68 -39 128 -10 51 25 147 109 174 154 17 27 26 32 46 28 30 -5 29 -1 10 -45 -18 -44 -20 -108 -3 -125 20 -20 103 -14 175 13 38 15 72 30 77 35 17 17 31 11 44 -20 18 -40 65 -64 110 -57 46 8 147 58 203 101 l46 36 6 -30 c4 -16 18 -37 32 -46 36 -24 91 -6 143 46 l39 40 7 -51 c5 -38 15 -59 37 -81 27 -27 34 -30 70 -24 60 10 97 32 159 95 76 78 143 181 111 169 -5 -1 -55 -47 -111 -101 -147 -142 -201 -160 -208 -71 -8 88 6 138 54 190 153 168 374 582 374 700 0 66 -56 97 -110 60z m70 -64 c0 -32 -90 -232 -156 -347 -64 -110 -166 -265 -176 -265 -10 0 59 192 107 295 79 171 188 345 216 345 5 0 9 -12 9 -28z m-851 -701 c-24 -33 -79 -68 -79 -50 0 6 18 29 40 51 44 44 72 44 39 -1z m397 -12 c19 -21 19 -22 -44 -80 -62 -56 -97 -77 -109 -65 -14 13 20 87 57 125 45 46 68 51 96 20z" />
        <path d="M1375 5313 c-297 -69 -370 -92 -472 -148 -127 -69 -163 -101 -163 -144 0 -30 15 -26 64 14 49 40 153 89 210 99 21 4 46 11 55 16 28 15 81 29 81 22 0 -4 -30 -75 -66 -157 -36 -83 -70 -164 -75 -182 -12 -39 -35 -57 -105 -79 -57 -18 -104 -58 -88 -74 7 -7 30 -3 68 11 63 22 77 19 58 -11 -13 -21 -74 -176 -90 -230 -37 -123 -44 -152 -49 -216 -5 -64 -3 -74 16 -93 28 -28 45 -27 70 5 28 36 28 68 0 61 -20 -5 -21 -3 -15 46 15 115 147 456 184 476 11 6 75 18 143 27 185 24 365 60 391 78 21 15 21 16 -20 12 -23 -3 -46 -7 -51 -10 -5 -4 -12 -2 -16 4 -10 15 -46 12 -67 -6 -13 -13 -21 -14 -31 -6 -7 6 -22 9 -33 7 -10 -3 -37 -6 -59 -8 -22 -3 -48 -8 -58 -12 -10 -4 -28 -3 -41 3 -15 7 -39 7 -74 0 -29 -6 -54 -9 -57 -6 -4 4 18 77 35 113 5 11 32 70 59 132 27 61 59 121 70 132 27 27 118 47 296 66 139 14 195 13 195 -6 0 -4 -11 -25 -25 -45 -33 -49 -23 -57 19 -13 37 38 50 66 36 74 -17 10 -11 28 6 21 14 -5 15 -4 4 9 -7 8 -22 15 -34 15 -17 0 -18 -2 -6 -11 11 -8 9 -8 -10 -3 -14 4 -45 7 -70 6 -32 -1 -41 1 -30 8 11 7 8 10 -15 10 -23 0 -27 -3 -15 -10 12 -8 11 -10 -7 -10 -12 0 -23 4 -25 10 -2 8 -105 -2 -204 -21 -35 -6 -19 5 21 16 46 11 37 19 -10 8z m-60 -33 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z" />
        <path d="M2703 5101 l-46 -99 -61 -6 c-34 -3 -93 -9 -133 -12 -119 -10 -138 -16 -138 -44 0 -20 6 -26 28 -28 16 -2 34 1 41 7 7 6 63 13 124 17 l112 7 -39 -94 c-21 -52 -52 -132 -69 -179 -43 -123 -48 -132 -101 -167 -26 -18 -50 -33 -53 -33 -4 0 -44 -25 -90 -56 -98 -66 -105 -60 -63 51 30 81 30 76 5 105 -11 13 -20 31 -20 41 0 29 -46 53 -99 52 -62 -1 -120 -32 -166 -87 -39 -48 -216 -172 -273 -192 -61 -20 -91 3 -76 60 5 22 12 26 41 26 44 0 68 13 121 63 34 34 42 48 42 78 0 29 -7 43 -31 63 -35 30 -69 33 -107 9 -88 -54 -136 -135 -136 -231 -1 -60 1 -65 33 -94 31 -28 39 -30 86 -25 60 6 118 33 192 88 60 45 63 46 63 20 0 -47 42 -82 89 -75 33 6 85 39 113 72 40 47 49 47 33 1 -22 -62 -19 -94 11 -118 15 -12 38 -21 52 -21 33 0 113 48 191 115 36 30 67 53 69 51 2 -3 -1 -28 -7 -56 -28 -132 9 -233 90 -246 32 -5 42 -2 74 28 33 30 36 37 25 54 -12 19 -13 19 -45 -13 -40 -40 -72 -43 -85 -7 -23 59 -4 173 55 344 43 121 152 372 166 381 20 12 257 21 510 19 163 -1 259 2 263 8 16 27 -159 40 -471 38 -150 -2 -273 0 -273 5 0 4 11 31 25 61 14 29 25 63 25 75 0 19 -25 43 -45 43 -4 0 -27 -45 -52 -99z m-983 -491 c-13 -25 -79 -75 -87 -66 -9 8 67 86 84 86 11 0 11 -5 3 -20z m441 -21 c19 -10 18 -11 -13 -27 -18 -10 -64 -43 -101 -75 -37 -31 -72 -57 -78 -57 -19 0 0 51 34 93 53 68 111 92 158 66z m320 -136 c-8 -15 -10 -15 -11 -2 0 17 10 32 18 25 2 -3 -1 -13 -7 -23z" />
        <path d="M1220 4836 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z" />
        <path d="M4922 4613 c-70 -90 -95 -191 -57 -233 27 -29 68 -42 98 -31 25 10 107 103 107 122 0 5 12 12 28 15 15 4 44 15 64 25 48 25 49 24 36 -11 -13 -38 -4 -76 22 -90 28 -15 47 -2 104 69 27 34 55 61 62 61 8 0 16 -19 20 -50 7 -57 30 -80 77 -80 23 0 48 18 118 88 130 128 113 146 -31 30 -65 -52 -99 -72 -107 -64 -3 3 -1 29 5 58 13 65 0 102 -39 106 -20 2 -40 -10 -83 -49 -57 -52 -77 -59 -56 -19 14 26 10 72 -7 78 -7 2 -25 -10 -40 -26 -16 -16 -48 -37 -73 -47 -25 -9 -50 -19 -56 -22 -7 -2 -14 12 -18 35 -7 49 -39 72 -100 72 -39 0 -48 -4 -74 -37z m108 -68 c-5 -14 -14 -23 -20 -20 -13 8 -13 31 1 57 10 18 13 19 20 5 6 -9 5 -26 -1 -42z m-44 -44 l24 -19 -33 -43 c-40 -53 -67 -63 -67 -24 0 25 35 105 46 105 3 0 17 -9 30 -19z" />
        <path d="M5212 4040 c-161 -43 -242 -146 -242 -309 0 -97 16 -138 80 -201 54 -55 168 -119 265 -150 69 -22 184 -80 219 -111 82 -71 104 -207 51 -314 -64 -132 -220 -171 -381 -96 -139 65 -243 218 -244 359 0 17 -4 32 -10 32 -6 0 -10 -79 -10 -217 1 -192 2 -216 15 -198 29 40 36 45 68 45 18 0 69 -13 113 -29 128 -47 215 -57 314 -37 100 21 148 44 210 102 70 65 102 143 102 248 0 184 -90 277 -367 381 -178 66 -243 106 -281 171 -45 76 -25 192 42 256 44 41 95 58 176 58 152 0 261 -92 304 -255 8 -27 14 -64 14 -82 0 -17 5 -35 10 -38 7 -4 10 64 10 197 -1 181 -2 201 -15 184 -8 -11 -15 -23 -15 -28 0 -15 -57 -8 -151 17 -133 35 -190 39 -277 15z" />
        <path d="M635 4030 c-4 -6 8 -10 29 -10 22 0 45 -9 63 -24 l28 -24 3 -514 c2 -282 0 -528 -3 -546 -8 -46 -38 -72 -80 -72 -19 0 -35 -4 -35 -10 0 -13 512 -13 605 0 77 11 172 52 222 94 54 45 83 118 83 205 0 94 -27 158 -92 218 -57 52 -107 77 -194 99 l-69 18 50 11 c191 44 281 181 231 348 -33 112 -148 189 -306 207 -114 13 -527 13 -535 0z m560 -37 c51 -26 69 -47 96 -108 33 -74 31 -212 -3 -287 -44 -96 -109 -127 -265 -128 l-83 0 0 246 c0 255 3 277 43 295 37 16 167 5 212 -18z m1 -557 c64 -20 117 -72 144 -143 28 -74 28 -221 0 -300 -22 -64 -39 -85 -95 -121 -36 -24 -52 -27 -151 -30 -165 -6 -154 -29 -154 331 l0 290 103 -6 c56 -3 125 -13 153 -21z" />
        <path d="M1545 4030 c-4 -6 6 -10 24 -10 17 0 42 -10 58 -24 l28 -24 3 -504 c2 -276 0 -522 -3 -546 -8 -55 -34 -82 -80 -82 -19 0 -35 -4 -35 -10 0 -6 78 -10 215 -10 137 0 215 4 215 10 0 6 -20 10 -45 10 -56 0 -81 23 -90 82 -3 24 -5 153 -3 288 l3 245 98 -1 c124 -2 179 -17 217 -60 39 -45 47 -83 54 -259 9 -255 50 -315 219 -315 31 0 57 4 57 10 0 6 -13 10 -29 10 -52 0 -61 27 -61 175 0 154 -11 230 -42 292 -31 63 -103 118 -173 134 -31 7 -58 16 -60 20 -3 4 16 10 42 14 64 9 153 56 191 99 30 35 62 117 62 161 0 12 -7 45 -16 74 -34 115 -163 203 -326 221 -112 13 -515 13 -523 0z m561 -38 c23 -12 53 -35 67 -52 82 -97 73 -331 -16 -406 -53 -44 -94 -56 -215 -62 l-114 -5 4 253 3 252 30 25 c27 24 34 25 115 21 59 -4 98 -12 126 -26z" />
        <path d="M2520 4031 c0 -4 19 -11 42 -14 82 -11 78 22 78 -591 l0 -543 -23 -21 c-15 -14 -36 -22 -60 -22 -20 0 -37 -4 -37 -10 0 -6 77 -10 210 -10 133 0 210 4 210 10 0 6 -15 10 -34 10 -19 0 -44 9 -57 19 l-24 19 0 547 0 547 28 24 c16 14 41 24 58 24 16 0 29 5 29 10 0 6 -77 10 -210 10 -115 0 -210 -4 -210 -9z" />
        <path d="M2977 4033 c-4 -6 11 -13 36 -16 81 -11 77 21 77 -591 l0 -543 -23 -21 c-15 -14 -36 -22 -60 -22 -20 0 -37 -5 -37 -11 0 -8 83 -10 283 -7 275 4 284 4 355 30 197 71 316 200 368 399 23 85 23 261 0 353 -49 203 -198 356 -401 412 -72 19 -588 34 -598 17z m580 -47 c168 -77 242 -249 243 -561 0 -374 -123 -570 -367 -583 -109 -6 -131 1 -149 44 -12 30 -14 116 -12 560 l3 526 30 25 c27 24 34 25 111 20 60 -3 98 -12 141 -31z" />
        <path d="M4036 4019 c84 -19 79 19 79 -595 0 -612 6 -568 -78 -591 -33 -9 47 -11 401 -12 l442 -1 0 215 c0 137 -4 215 -10 215 -5 0 -10 -17 -10 -37 0 -56 -26 -165 -48 -209 -28 -53 -88 -109 -145 -134 -57 -25 -216 -45 -282 -36 -87 14 -85 6 -85 331 l0 285 73 0 c97 0 145 -15 189 -58 40 -38 53 -67 64 -144 3 -27 10 -48 15 -48 11 0 12 493 2 504 -4 4 -13 -23 -20 -61 -24 -130 -85 -172 -250 -173 l-73 0 0 249 c0 316 -10 296 157 295 97 -1 129 -5 178 -24 86 -32 147 -98 174 -185 12 -37 21 -84 21 -105 0 -22 5 -42 10 -45 7 -4 10 60 10 184 l0 191 -427 -1 c-289 -1 -415 -4 -387 -10z" />
        <path d="M1000 2067 c0 -8 27 -102 61 -208 33 -107 63 -202 66 -211 3 -10 13 -18 22 -18 12 0 32 47 81 195 36 107 67 195 70 195 4 0 35 -87 71 -192 46 -135 70 -194 82 -196 12 -3 26 28 62 145 26 81 58 181 71 222 13 41 22 76 20 79 -17 17 -38 -27 -85 -184 -30 -99 -58 -186 -62 -194 -7 -13 -8 -13 -13 0 -3 8 -32 93 -65 189 -60 176 -74 203 -90 186 -4 -6 -34 -86 -66 -180 -31 -93 -62 -179 -68 -190 -8 -15 -20 14 -64 160 -29 99 -57 186 -61 194 -12 21 -32 26 -32 8z" />
        <path d="M1720 1855 l0 -225 135 0 c169 0 183 18 18 22 l-118 3 -3 92 -3 92 97 3 c67 2 99 7 102 16 3 9 -21 12 -97 12 l-101 0 0 90 0 90 120 0 c100 0 120 3 120 15 0 13 -22 15 -135 15 l-135 0 0 -225z" />
        <path d="M2117 2073 c-4 -3 -7 -105 -7 -225 l0 -218 114 0 c128 0 164 10 213 62 40 42 67 132 58 191 -16 97 -64 159 -144 186 -36 12 -223 16 -234 4z m207 -23 c53 -12 86 -38 115 -90 51 -90 23 -226 -56 -280 -31 -22 -50 -25 -135 -28 l-98 -4 0 199 c0 110 3 203 7 206 9 10 121 8 167 -3z" />
        <path d="M2630 1854 l0 -226 123 4 c117 3 126 5 165 32 109 75 120 270 20 363 -40 37 -102 53 -209 53 l-99 0 0 -226z m204 196 c106 -24 164 -132 136 -255 -23 -103 -87 -145 -223 -145 l-87 0 0 198 c0 109 3 202 7 205 9 10 121 8 167 -3z" />
        <path d="M3150 1855 c0 -208 1 -225 18 -225 16 0 17 16 14 225 -2 190 -5 225 -17 225 -13 0 -15 -34 -15 -225z" />
        <path d="M3360 1855 c0 -173 3 -225 13 -225 9 0 11 45 10 197 l-3 198 23 -26 c25 -29 222 -297 250 -341 11 -15 26 -28 35 -28 15 0 17 21 14 226 -2 171 -6 224 -15 221 -9 -3 -13 -56 -15 -197 l-2 -192 -59 78 c-32 44 -97 132 -144 197 -48 64 -91 117 -97 117 -6 0 -10 -81 -10 -225z" />
        <path d="M3970 2064 c-44 -19 -66 -39 -99 -89 -22 -35 -26 -50 -26 -120 0 -86 14 -125 63 -177 55 -59 196 -74 286 -32 l36 18 0 83 0 83 -60 0 c-33 0 -60 -4 -60 -10 0 -5 20 -10 45 -10 l45 0 0 -66 0 -65 -37 -16 c-46 -20 -149 -21 -183 -4 -77 40 -118 128 -106 225 9 66 34 108 85 142 55 36 150 39 205 6 36 -22 63 -22 50 0 -26 42 -175 62 -244 32z" />
        <path d="M4623 2059 c-75 -37 -113 -108 -113 -214 0 -131 90 -225 214 -225 96 1 183 65 207 155 25 92 -8 214 -71 263 -60 46 -166 55 -237 21z m187 -24 c65 -34 94 -90 94 -185 0 -80 -13 -118 -56 -157 -50 -47 -142 -57 -213 -23 -114 55 -130 271 -26 347 56 42 139 50 201 18z" />
        <path d="M5070 1855 c0 -208 1 -225 18 -225 15 0 17 10 14 100 l-3 100 105 0 c86 0 106 3 106 15 0 11 -20 15 -102 17 l-103 3 0 90 0 90 118 3 c95 2 117 5 117 17 0 13 -23 15 -135 15 l-135 0 0 -225z" />
        <path d="M1810 1295 c0 -12 15 -15 80 -15 l80 0 0 -210 c0 -180 2 -210 15 -210 13 0 15 30 15 210 l0 209 78 3 c45 2 76 7 75 13 -2 6 -70 11 -173 13 -147 2 -170 0 -170 -13z" />
        <path d="M2270 1085 c0 -191 2 -225 15 -225 11 0 15 21 17 108 l3 107 140 0 140 0 3 -107 c2 -88 5 -108 18 -108 12 0 14 31 12 222 -2 166 -6 222 -15 226 -10 3 -13 -22 -13 -102 l0 -106 -145 0 -145 0 0 105 c0 87 -3 105 -15 105 -13 0 -15 -32 -15 -225z" />
        <path d="M2780 1085 l0 -225 136 0 c104 0 135 3 132 13 -3 8 -37 12 -119 13 l-114 0 0 95 0 94 98 3 c129 4 127 22 -3 22 l-100 0 0 90 0 90 120 0 c100 0 120 3 120 15 0 13 -22 15 -135 15 l-135 0 0 -225z" />
        <path d="M3347 1303 c-4 -3 -7 -105 -7 -226 l0 -219 124 3 c119 4 126 5 168 34 60 42 88 101 88 187 0 108 -36 172 -115 209 -31 14 -64 19 -146 19 -58 0 -109 -3 -112 -7z m226 -27 c79 -29 117 -90 117 -191 0 -142 -70 -204 -232 -205 l-78 0 0 205 0 205 78 0 c42 -1 94 -7 115 -14z" />
        <path d="M3957 1283 c-8 -15 -31 -68 -50 -118 -20 -49 -56 -138 -81 -197 -40 -96 -43 -108 -26 -108 11 0 22 10 25 23 4 12 16 44 28 70 l20 48 110 -3 110 -3 28 -65 c15 -36 34 -66 43 -68 9 -2 16 -2 16 1 0 2 -40 102 -89 223 -56 139 -95 220 -104 222 -9 2 -22 -9 -30 -25z m42 -50 c17 -37 81 -201 81 -208 0 -3 -43 -5 -95 -5 -63 0 -95 4 -95 11 0 17 84 229 91 229 3 0 11 -12 18 -27z" />
        <path d="M4200 1306 c0 -2 36 -61 80 -130 l80 -125 0 -95 c0 -79 3 -96 15 -96 12 0 15 17 15 89 l0 89 75 121 c87 139 92 151 73 151 -7 0 -45 -52 -83 -115 -39 -63 -73 -113 -77 -112 -4 1 -38 52 -76 112 -38 61 -76 111 -85 113 -10 2 -17 1 -17 -2z" />
      </g>
    </svg>
  );
};

export default WeddingsLogo;
