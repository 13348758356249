export function Help({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,4.625A4.3021,4.3021,0,0,0,7.625,8.84a.375.375,0,0,0,.75,0A3.5518,3.5518,0,0,1,12,5.375,3.5518,3.5518,0,0,1,15.625,8.84a3.02,3.02,0,0,1-1.7871,2.542A3.7415,3.7415,0,0,0,11.625,14.6V16.2a.375.375,0,0,0,.75,0V14.6a3.02,3.02,0,0,1,1.7871-2.542A3.7415,3.7415,0,0,0,16.375,8.84,4.3021,4.3021,0,0,0,12,4.625Z" />
          <path d="M12,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,18.375Z" />
        </g>
      </g>
    </svg>
  );
}
