export function Star({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.8574,10.2334a.3758.3758,0,0,0-.3047-.2568l-4.9882-.712L12.335,4.8311a.3914.3914,0,0,0-.67,0L9.4355,9.2646l-4.9882.712a.3758.3758,0,0,0-.3047.2568.3719.3719,0,0,0,.0986.3848l3.6045,3.4492-.8506,4.8681a.375.375,0,0,0,.541.3975L12,17.03l4.4639,2.3027a.3723.3723,0,0,0,.3935-.0312.373.373,0,0,0,.1475-.3663l-.8506-4.8681,3.6045-3.4492A.3719.3719,0,0,0,19.8574,10.2334Zm-4.3662,3.4307a.3726.3726,0,0,0-.11.3349l.7548,4.3213-3.9638-2.0449a.3728.3728,0,0,0-.3438,0L7.8643,18.32l.7548-4.3213a.3726.3726,0,0,0-.11-.3349L5.3164,10.61,9.7354,9.98a.3743.3743,0,0,0,.2822-.2022L12,5.835l1.9824,3.9423a.3743.3743,0,0,0,.2822.2022l4.419.6309Z" />
      </g>
    </svg>
  );
}
