import cx from 'classnames';
import type { AnimationOutput } from '../functions/helper';
import { HandleAnimations, HandleWYSIWYGContent } from '../functions/helper';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Padder, GIS_TextAlignment, GIS_merge } from '../functions/global-instance-styles';
import { appliedCloudinaryParams, isReducedMotion } from '@curated-property/utils';
import contentStyles from '../css/contentStyle.module.css';
import { HeadingStyle } from '../includes/heading-style';

export interface MultiColumnListProps {
  title?: string;
  title_noTx?: string;
  copy?: string;
  column?: {
    columnHeading?: string;
    list?: {
      listItem?: string;
    }[];
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function MultiColumnList({
  title,
  title_noTx,
  copy,
  column,
  globalStyles,
  instanceStyles,
}: MultiColumnListProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const hideAnimations: boolean = inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const hasTwoColumns = column?.length === 2;
  const borderStyles =
    'border-text sm:border-l first:border-l-0 [&:nth-child(n+2):nth-child(2n+1)]:border-l-0 xl:[&:nth-child(n+2):nth-child(2n+1)]:border-l xl:[&:nth-child(n+2):nth-child(3n+1)]:border-l-0 2xl:[&:nth-child(n+2):nth-child(3n+1)]:border-l ' +
    'rtl:border-0 rtl:sm:border-r rtl:first:border-r-0 rtl:[&:nth-child(n+2):nth-child(2n+1)]:border-r-0 rtl:xl:[&:nth-child(n+2):nth-child(2n+1)]:border-r rtl:xl:[&:nth-child(n+2):nth-child(3n+1)]:border-r-0 rtl:2xl:[&:nth-child(n+2):nth-child(3n+1)]:border-r rtl:xl:[&:nth-child(n+2):nth-child(2n+1)]:border-l-0 rtl:2xl:!border-l-0';

  const animations: AnimationOutput = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
  });
  const animationsInner: AnimationOutput = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });
  const numHash = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
  };

  return (
    <section
      className={cx(inlineStyles?.showHide && 'hidden', 'overflow-hidden text-lg', paddingStyles)}
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor,
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div
        data-testid="multi-column-list"
        data-element-id="multi-column-list"
        ref={animations?.ref}
        className="container"
        style={{
          backgroundColor: inlineStyles?.contentBackgroundColor,
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || undefined,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          maxWidth: inlineStyles?.containerMaxWidth && `${inlineStyles?.containerMaxWidth}px`,
        }}
      >
        <div
          className="mb-6 flex w-full flex-col text-center lg:mb-12"
          style={{ textAlign: textAlignment }}
        >
          {title && (
            <HeadingStyle
              text={inlineStyles?.headingsNoTranslate ? title_noTx : title}
              type="h2"
              className={cx('mb-4', animations?.one, {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              textColorInline={inlineStyles?.titleColor}
            />
          )}
          {copy && (
            <div
              className={cx(
                animations?.two,
                contentStyles?.paragraphStyle,
                contentStyles?.listStyle
              )}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
              }}
              style={{
                color: inlineStyles?.textColor,
              }}
            />
          )}
        </div>
        <div
          ref={animationsInner?.ref}
          className={cx('flex flex-wrap items-stretch gap-y-8', {
            'justify-between': !hasTwoColumns,
          })}
        >
          {column?.map((c, colIdx) => (
            <div
              data-testid="column"
              key={colIdx}
              className={cx(
                'flex w-full grow flex-col sm:w-1/2 sm:px-6 xl:w-1/3 2xl:w-1/4',
                borderStyles,
                animationsInner[
                  numHash[(colIdx + 1) as keyof typeof numHash] as keyof AnimationOutput
                ],
                {
                  'lg:pl-24 rtl:pr-24': hasTwoColumns,
                }
              )}
              style={{ borderColor: inlineStyles?.dividerColor }}
            >
              {c?.columnHeading && (
                <h3
                  data-testid="column-heading"
                  className="!font-bold"
                  style={{ color: inlineStyles?.columnHeadingColor }}
                >
                  {c?.columnHeading}
                </h3>
              )}
              <ul
                data-testid="list-item"
                className="my-1 ml-7 list-outside list-disc rtl:ml-0 rtl:mr-7"
                style={{ color: inlineStyles?.listItemColor }}
              >
                {c?.list?.map((l, listIdx) => (
                  <li key={listIdx}>{l?.listItem}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
