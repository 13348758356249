import { useIsClient } from 'usehooks-ts';
import { Slider } from '@dx-ui/osc-slider';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { getClasses } from './util/classes';
import type { VideoControlsProps } from './video-player.controls';
import type { PlayerState, useVideoPlayer } from './hooks/use-video-player';

type VolumeSliderProps = Pick<VideoControlsProps, 'brandComponentTheme'> &
  Pick<ReturnType<typeof useVideoPlayer>['videoControlsProps'], 'updateVolume' | 'playerState'>;

const volumeConfig = {
  min: 0,
  max: 100,
  step: 5,
};

export const lowestVolume: PlayerState['volume'] = [volumeConfig.step];

export const initialVolume: PlayerState['volume'] = [
  Math.max(volumeConfig.step, Math.floor(volumeConfig.max / 2)),
];

export function VolumeSlider({
  brandComponentTheme,
  playerState,
  updateVolume,
}: VolumeSliderProps) {
  const isClient = useIsClient();
  const { t, i18n } = useTranslation('osc-video-player');
  const isDark = brandComponentTheme === 'dark';
  const volume = playerState.isMuted ? 0 : playerState.volume[0];

  if (!isClient) {
    return null;
  }

  return (
    <form
      className={classnames(
        'sr-only !absolute bottom-12 shadow group-has-[:focus]:not-sr-only',
        'group-has-[:focus]:!h-32 group-has-[:focus]:!w-full group-has-[:focus]:lg:!h-40',
        'group-hover:not-sr-only group-hover:!h-32 group-hover:!w-full group-hover:lg:!h-40',
        getClasses({ brandComponentTheme })
      )}
    >
      <Slider
        title={t('volume.label')}
        hiddenLegend={true}
        inputLabels={[t('volume.button')]}
        thumbLabelFn={() => t('volume.ariaLabel', { volume })}
        values={[volume]}
        onChange={updateVolume}
        language={i18n.language}
        min={volumeConfig.min}
        max={volumeConfig.max}
        step={volumeConfig.step}
        fillIndex={1}
        orientation="vertical"
        onColor={isDark ? 'color-bg' : 'color-primary'}
        variant="primary"
        brandComponentTheme={brandComponentTheme}
      />
    </form>
  );
}
