import Link from '../link-without-prefetch';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
// Usable for both event types
import { EventCalendarICS } from './event-calendar-ics';
import { appliedCloudinaryParams, sanitize } from '@curated-property/utils';
import type { RegularlyScheduledEventContentProps } from './lib/event-calendar-props';
import { DialogTitle } from '../global/generic-modal';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array } from '../functions/global-instance-styles';
interface BlockProps {
  title?: string;
  eventContent?: RegularlyScheduledEventContentProps;
  dateStringFmt?: string;
  operationTimeString?: string;
  eventType?: string;
  allDay?: boolean;
  hideICS?: boolean;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function EventCalendarModalContentBlock({
  title,
  eventContent,
  dateStringFmt,
  operationTimeString,
  eventType,
  allDay,
  hideICS,
  instanceStyles,
  globalStyles,
}: BlockProps) {
  const { t } = useTranslation();

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  let startEndTimeDisplay = '';
  if (eventType === 'special') {
    startEndTimeDisplay = eventContent?.startTime;
    eventContent?.startTime &&
      eventContent?.endTime &&
      (startEndTimeDisplay += ` ${t('calendar.to')} ${eventContent?.endTime}`);
  } else {
    if (eventType === 'regular') {
      const timeObj = eventContent?.timeObj;
      startEndTimeDisplay = timeObj?.startTime;
      timeObj?.startTime &&
        timeObj?.endTime &&
        (startEndTimeDisplay += ` ${t('calendar.to')} ${timeObj?.endTime}`);
    }
  }

  return (
    <div
      className="w-full p-4"
      tabIndex={0}
      data-element-id="event-calendar-modal-dialog-content-wrapper"
    >
      {eventContent?.image && (
        <div>
          <img
            className="mx-auto max-h-[800px]"
            src={appliedCloudinaryParams(eventContent?.image?.sourceUrl)}
            alt={eventContent?.image?.altText || ''}
          />
        </div>
      )}
      <div>
        {title && (
          <DialogTitle asChild>
            <h2
              className="my-2 text-2xl font-bold"
              dangerouslySetInnerHTML={{
                __html: sanitize(title || ''),
              }}
              style={{
                color: inlineStyles?.eventsCalBaseTextColor,
              }}
            />
          </DialogTitle>
        )}
        {dateStringFmt && (
          <p
            className="text-primary my-2 font-bold"
            dangerouslySetInnerHTML={{
              __html: sanitize(dateStringFmt || ''),
            }}
          />
        )}
        {operationTimeString && (
          <p
            className="text-primary my-2 font-bold"
            dangerouslySetInnerHTML={{
              __html: sanitize(operationTimeString || ''),
            }}
          />
        )}

        {eventContent?.attractionEventLocation && (
          <p
            style={{
              color: inlineStyles?.eventsCalBaseTextColor,
            }}
            className="my-2 font-bold"
          >
            {eventContent?.attractionEventLocation}
          </p>
        )}
        {eventContent?.distanceFromResort && (
          <div
            className="my-2 font-bold"
            style={{
              color: inlineStyles?.eventsCalBaseTextColor,
            }}
          >
            {eventContent?.distanceFromResort}
          </div>
        )}
        {eventContent?.onSiteOrOffSite === 'On-site' && eventContent?.locationAtResort && (
          <p
            style={{
              color: inlineStyles?.eventsCalBaseTextColor,
            }}
            className="my-2 font-bold"
            dangerouslySetInnerHTML={{
              __html: `${t('calendar.location')}: ${eventContent?.locationAtResort}`,
            }}
          />
        )}
      </div>
      {eventContent?.description && (
        <div
          className="my-2"
          style={{
            color: inlineStyles?.eventsCalBaseTextColor,
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(eventContent?.description),
          }}
        />
      )}
      <div className="mt-4 flex justify-items-start">
        {eventContent?.buttons?.map((i, e) => {
          return (
            i?.link?.url && (
              <Link
                key={`eventBtn${e}`}
                href={i?.link?.url}
                className={cx(
                  'btn mr-4 w-auto  py-2 text-center font-bold',
                  i?.buttonStyle ? `btn-${i?.buttonStyle}` : 'btn-primary'
                )}
                target={i?.link?.target}
              >
                {i?.link?.title}
              </Link>
            )
          );
        })}
      </div>
      {!allDay && !hideICS && (
        <div>
          <EventCalendarICS title={title} content={eventContent} />
        </div>
      )}
    </div>
  );
}
