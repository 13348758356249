export function Care({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M8.749,6.96a.375.375,0,0,0,.2656-.64.8145.8145,0,0,1-.2392-.5693.7091.7091,0,0,1,.2012-.5137.6565.6565,0,0,1,.247-.1553.3749.3749,0,0,0-.25-.707,1.4074,1.4074,0,0,0-.5293.334,1.4525,1.4525,0,0,0-.4189,1.05A1.5586,1.5586,0,0,0,8.4834,6.85.3738.3738,0,0,0,8.749,6.96Z" />
          <path d="M11.8965,12.6211a.3749.3749,0,0,0,.5312,0l4.586-4.5957a3.19,3.19,0,0,0,.039-4.4922A3.0861,3.0861,0,0,0,14.85,2.625a3.1886,3.1886,0,0,0-2.2744.9463l-.4141.4121-.4091-.4111a3.2434,3.2434,0,0,0-4.4786-.0391,3.19,3.19,0,0,0,.0391,4.4922ZM7.8047,4.0625A2.3413,2.3413,0,0,1,9.4766,3.375a2.445,2.445,0,0,1,1.7441.7266l.6738.6767a.3743.3743,0,0,0,.2647.11h.001a.3731.3731,0,0,0,.2646-.1094l.6807-.6777a2.4758,2.4758,0,0,1,3.416-.0391,2.439,2.439,0,0,1-.0391,3.4336l-4.32,4.3291L7.8437,7.4961A2.4389,2.4389,0,0,1,7.8047,4.0625Z" />
          <path d="M21.207,13.499a1.3948,1.3948,0,0,0-.834-.6767,1.7739,1.7739,0,0,0-1.3261.1572l-3.2276,1.6562a.3752.3752,0,1,0,.3418.668l3.2344-1.66a1.0218,1.0218,0,0,1,.7617-.1036.6423.6423,0,0,1,.3877.3125.8747.8747,0,0,1-.4336,1.13l-6.6123,3.57-.2724.0782-6.3379.1826a4.5943,4.5943,0,0,0-1.95,1.4385.3751.3751,0,0,0,.0332.5292.3749.3749,0,0,0,.5293-.0332,4.22,4.22,0,0,1,1.5566-1.2l6.27-.1788.4405-.123,6.6982-3.6035A1.613,1.613,0,0,0,21.207,13.499Z" />
          <path d="M8.7646,14.5273a13.7254,13.7254,0,0,0,2.3672.2706H13.583a.8948.8948,0,0,1,.9531.788.8836.8836,0,0,1-.9531.7891H10.1914a.375.375,0,0,0,0,.75H13.583a1.6289,1.6289,0,0,0,1.7031-1.5391,1.6288,1.6288,0,0,0-1.7031-1.538H11.1436a13.3634,13.3634,0,0,1-2.1709-.24,4.2056,4.2056,0,0,0-3.3946.502l-2.833,2.6181a.3753.3753,0,0,0,.51.5508l2.7881-2.583A3.4845,3.4845,0,0,1,8.7646,14.5273Z" />
        </g>
      </g>
    </svg>
  );
}
