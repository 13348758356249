import classnames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { useMenu } from './hooks/use-control-menu';
import { useEventListeners } from './hooks/use-control-menu';
import { ControlIcon } from './video-player.control-icon';
import { ControlButton } from './video-player.control-button';
import { ControlMenu } from './video-player.control-menu';
import { ControlList } from './video-player.control-list';
import { ControlItem } from './video-player.control-item';
import { getClasses } from './util/classes';
import type { Track, VideoControlsProps } from './video-player.controls';
import type { useVideoPlayer } from './hooks/use-video-player';

type SettingsMenuProps = Pick<VideoControlsProps, 'brandComponentTheme'> &
  Pick<
    ReturnType<typeof useVideoPlayer>['videoControlsProps'],
    | 'activeVideo'
    | 'updateAudioTrack'
    | 'updateCaption'
    | 'updateTranscript'
    | 'optionsState'
    | 'playerState'
    | 'videoElement'
  > & {
    menuProps: ReturnType<typeof useMenu>;
  };

export function SettingsMenu(props: SettingsMenuProps) {
  const { brandComponentTheme } = props;
  const { t } = useTranslation('osc-video-player');
  const menuOptions = useMenuOptions(props);
  const { isOpen, toggleMenu, menuId, popupButtonRef, popupMenuRef } = menuOptions;

  return (
    <ControlButton
      aria-label={t('settings')}
      aria-controls={menuId}
      aria-haspopup
      aria-expanded={isOpen}
      ref={popupButtonRef}
      onClick={toggleMenu}
      brandComponentTheme={brandComponentTheme}
      iconContent={
        <>
          <ControlIcon
            name="settings"
            brandComponentTheme={brandComponentTheme}
            className={classnames({ hidden: isOpen })}
          />
          <ControlIcon
            name="settings"
            variant="solid"
            brandComponentTheme={brandComponentTheme}
            className={classnames({ hidden: !isOpen })}
          />
        </>
      }
    >
      <ControlMenu {...menuOptions} wrapperRef={props.videoElement}>
        {isOpen ? (
          <Menu
            {...props}
            {...menuOptions}
            popupButtonRef={popupButtonRef}
            popupMenuRef={popupMenuRef}
          />
        ) : null}
      </ControlMenu>
    </ControlButton>
  );
}

type MenuProps = ReturnType<typeof useMenuOptions>;
type ControlMenuProps = SettingsMenuProps & MenuProps;

function Menu({
  menuOptions,
  brandComponentTheme,
  closeMenu,
  popupButtonRef,
  popupMenuRef,
  goToMainMenu,
}: ControlMenuProps) {
  function resetMenu() {
    closeMenu();
    goToMainMenu();
  }

  useEventListeners({
    popupMenuRef,
    popupButtonRef,
    resetMenu,
  });

  const selectedOption = menuOptions.find((option) => option.isSelected);
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  if (selectedOption) {
    return (
      <ControlList key={selectedOption.type} brandComponentTheme={brandComponentTheme}>
        {selectedOption.options.map((subOption) => (
          <ControlItem
            key={subOption.label}
            brandComponentTheme={brandComponentTheme}
            onClick={() => {
              selectedOption.onSelection(subOption.track);
              resetMenu();
              popupButtonRef.current?.focus();
            }}
            role="menuitemradio"
            aria-checked={subOption.isSelected}
            autoFocus={subOption.isSelected}
            beforeSection={
              <ControlIcon
                size="sm"
                name="check"
                brandComponentTheme={brandComponentTheme}
                className={classnames({ '!fill-transparent': !subOption.isSelected })}
              />
            }
          >
            {subOption.label}
          </ControlItem>
        ))}
        <ControlItem
          key={selectedOption.type}
          brandComponentTheme={brandComponentTheme}
          wrapperClassName={classnames(
            { 'bg-bg-alt': !isLight && !isDark },
            getClasses({ brandComponentTheme })
          )}
          onClick={goToMainMenu}
          beforeSection={
            <ControlIcon
              size="sm"
              name="arrowhead-left"
              brandComponentTheme={brandComponentTheme}
              className="rtl:rotate-180"
            />
          }
          autoFocus={!selectedOption.options.some((subOption) => subOption.isSelected)}
          aria-expanded={true}
        >
          {selectedOption.label}
        </ControlItem>
      </ControlList>
    );
  }

  return (
    <ControlList brandComponentTheme={brandComponentTheme}>
      {menuOptions.map((option, index) => (
        <ControlItem
          key={option.type}
          brandComponentTheme={brandComponentTheme}
          onClick={option.onMenuOptionClick}
          className="justify-between"
          autoFocus={index === 0}
          aria-expanded={false}
          afterSection={
            <ControlIcon
              size="sm"
              name="arrowhead-left"
              brandComponentTheme={brandComponentTheme}
              className="rotate-180 rtl:rotate-0"
            />
          }
        >
          {option.label}
        </ControlItem>
      ))}
    </ControlList>
  );
}

type Option = {
  isSelected: boolean;
  label: Track['label'];
  track: Track | null;
};

type SelectOption = Pick<Option, 'isSelected'> & {
  label: React.ReactNode;
  type: Track['type'];
  options: Option[];
  onSelection: (option: Track | null) => void;
  onMenuOptionClick: () => void;
};

function useMenuOptions(options: SettingsMenuProps) {
  const { t } = useTranslation('osc-video-player');
  const [selectedOption, setSelectedOption] = useState<Track['type'] | 'mainMenu'>('mainMenu');
  const captionOptions = options.activeVideo?.captionTracks || [];
  const hasCaptions = captionOptions.length > 1;
  const hasAudioTracks = options.optionsState.length > 1;
  const transcriptOptions = options.activeVideo?.transcriptTracks || [];
  const hasTranscripts = transcriptOptions.length > 0;
  const goToMainMenu = () => setSelectedOption('mainMenu');

  const menuOptions = [
    hasCaptions && {
      type: 'captionTrack',
      label: (
        <>
          {t('subtitles')}/<abbr title={t('captions')}>{t('cc')}</abbr>
        </>
      ),
      isSelected: selectedOption === 'captionTrack',
      onSelection: options.updateCaption,
      onMenuOptionClick: () => setSelectedOption('captionTrack'),
      options: [
        {
          isSelected: !options.playerState.showCaptions,
          label: t('off'),
          track: null,
        },
        ...captionOptions.map((track) => ({
          isSelected:
            options.playerState.showCaptions &&
            options.playerState.activeCaptionTrack?.url === track.url,
          label: track.label,
          track,
        })),
      ],
    },
    hasAudioTracks && {
      type: 'audioTrack',
      label: t('audioTrack'),
      isSelected: selectedOption === 'audioTrack',
      onSelection: options.updateAudioTrack,
      onMenuOptionClick: () => setSelectedOption('audioTrack'),
      options: options.optionsState.map((track) => ({
        isSelected: options.activeVideo?.videoUrl === track.videoUrl,
        label: track.videoLabel,
        track,
      })),
    },
    hasTranscripts && {
      type: 'transcriptTrack',
      label: t('transcript'),
      isSelected: selectedOption === 'transcriptTrack',
      onSelection: options.updateTranscript,
      onMenuOptionClick: () => setSelectedOption('transcriptTrack'),
      options: [
        {
          isSelected: !options.playerState.activeTranscript,
          label: t('off'),
          track: null,
        },
        ...transcriptOptions.map((track) => ({
          isSelected: options.playerState.activeTranscript?.url === track.url,
          label: track.label,
          track,
        })),
      ],
    },
  ].filter(Boolean) as SelectOption[];

  return {
    ...options.menuProps,
    goToMainMenu,
    menuOptions,
  };
}
