import { add, differenceInDays, parse, startOfToday } from 'date-fns';

import {
  NUM_ADULT_PARAM_SEARCH_VALUE,
  NUM_CHILD_PARAM_SEARCH_VALUE,
  CHILD_AGE_PARAM_SEARCH_VALUE,
  ARRIVAL_DATE_PARAM_NAME,
  BRAND_CODE_PARAM_NAME,
  DEPARTURE_DATE_PARAM_NAME,
  FLEXIBLE_DATES_PARAM_NAME,
  NUM_ROOMS_PARAM_NAME,
  ROOM_NUMBER_PARAM_NAME,
  validParamNames,
  validSpecialRatesKeys,
  validAdditionalQsParamNames,
  ADDITIONAL_QS_MAX_POINTS_PARAM_NAME,
  ADDITIONAL_QS_REDEEM_PTS_PARAM_NAME,
  ADDITIONAL_QS_REQUESTED_RATES_ONLY_PARAM_NAME,
  ADDITIONAL_QS_ADJOINING_ROOM_STAY_PARAM_NAME,
  ADDITIONAL_QS_AVAILABLE_HOTELS_ONLY_PARAM_NAME,
  ADDITIONAL_QS_TOKEN_PARAM_NAME,
  ADDITIONAL_QS_PRICE_RANGE_PARAM_NAME,
  ADDITIONAL_QS_AMENITIES_PARAM_NAME,
  ADDITIONAL_QS_ATTRIBUTE_IDS,
  NUM_ADULT_PARAM_NAME,
  NUM_CHILD_PARAM_NAME,
} from './osc-composable-search.constants';

export const mapAdditionalQsTypes = (key: string, value: string) => {
  if (key === ADDITIONAL_QS_MAX_POINTS_PARAM_NAME && !isNaN(parseInt(value))) {
    return parseInt(value);
  }

  if (
    key === ADDITIONAL_QS_PRICE_RANGE_PARAM_NAME &&
    !isNaN(parseInt(value.split(',')[0])) &&
    !isNaN(parseInt(value.split(',')[1]))
  ) {
    return [parseInt(value.split(',')[0]), parseInt(value.split(',')[1])];
  }

  if (
    (key === ADDITIONAL_QS_ADJOINING_ROOM_STAY_PARAM_NAME && Boolean(value)) ||
    (key === ADDITIONAL_QS_AVAILABLE_HOTELS_ONLY_PARAM_NAME && Boolean(value)) ||
    (key === ADDITIONAL_QS_REDEEM_PTS_PARAM_NAME && Boolean(value)) ||
    (key === ADDITIONAL_QS_REQUESTED_RATES_ONLY_PARAM_NAME && Boolean(value))
  ) {
    return Boolean(value);
  }

  if (
    (key === ADDITIONAL_QS_AMENITIES_PARAM_NAME ||
      key === ADDITIONAL_QS_ATTRIBUTE_IDS ||
      key === ADDITIONAL_QS_TOKEN_PARAM_NAME) &&
    value.includes(',')
  ) {
    return value.split(',');
  }

  return value;
};

export const mapValue = (paramNamesMap: Array<string>, key: string, value: string) => {
  if (paramNamesMap.includes(key) && value) {
    return value;
  }

  return undefined;
};

export const formatDateString = (unformattedDate: string): Date =>
  parse(unformattedDate, 'yyyy-MM-dd', startOfToday());

export const stringOrBooleanOrNull = (value: string | null): string | boolean | null => {
  if (!value) return null;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

export const parseIntParam = (params: URLSearchParams, paramName: string) => {
  return parseInt(isNaN(parseInt(params.get(paramName))) ? '1' : params.get(paramName));
};

// eslint-disable-next-line complexity
export const parseDeepLinkParams = (
  searchQueryParams: URLSearchParams,
  brandCode?: string,
  minLOS?: number
) => {
  const ages = [];
  const adults: Array<{
    adults: number;
    roomNumber?: number;
  }> = [];
  const children: Array<{
    roomNumber?: number;
  }> = [];
  const dates: {
    arrivalDate?: Date;
    datesFlex?: boolean;
    departureDate?: Date;
  } = {};
  const specialRates: Record<string, string | boolean> = {};
  const additioinalQs: Record<string, Array<string> | string | Array<number> | number | boolean> =
    {};

  let internalBrandCode = brandCode;

  const roomNumberParam = parseIntParam(searchQueryParams, ROOM_NUMBER_PARAM_NAME);
  const numRooms = parseIntParam(searchQueryParams, NUM_ROOMS_PARAM_NAME);

  for (const [key, value] of Array.from(searchQueryParams.entries())) {
    if (!validParamNames.includes(key) || !value) continue;

    if (key.includes(NUM_ADULT_PARAM_SEARCH_VALUE)) {
      const roomNumberAssociatedWithGuest = key
        .replaceAll('room', '')
        .replaceAll(NUM_ADULT_PARAM_SEARCH_VALUE, '');

      adults.push({
        adults: parseInt(value),
        roomNumber: parseInt(roomNumberAssociatedWithGuest) ?? roomNumberParam,
      });
    }

    if (key.includes(NUM_CHILD_PARAM_SEARCH_VALUE) && !isNaN(parseInt(value))) {
      const roomNumberAssociatedWithGuest = key
        .replaceAll('room', '')
        .replaceAll(NUM_CHILD_PARAM_SEARCH_VALUE, '');
      const numberOfChildren = parseInt(value);

      Array(numberOfChildren)
        .fill(0)
        .forEach(() => {
          children.push({
            roomNumber: parseInt(roomNumberAssociatedWithGuest) ?? roomNumberParam,
          });
        });
    }

    if (key.includes(CHILD_AGE_PARAM_SEARCH_VALUE) && value) {
      const roomNumberAssociatedWithGuest = key
        .replaceAll('room', '')
        .replaceAll(CHILD_AGE_PARAM_SEARCH_VALUE, '');

      const agesForRoom = value.includes(',')
        ? value.split(',').map((age) => parseInt(age))
        : parseInt(value);

      const roomNumber = parseInt(roomNumberAssociatedWithGuest) ?? roomNumberParam;

      if (Array.isArray(agesForRoom)) {
        agesForRoom.forEach((age) => {
          ages.push({
            key,
            value: age,
            roomNumber,
          });
        });
      } else {
        ages.push({
          key,
          value: parseInt(value),
          roomNumber,
        });
      }
    }

    if (key === FLEXIBLE_DATES_PARAM_NAME && (value === 'true' || value === 'false')) {
      const flexValue = value === 'true' ? true : false;
      dates.datesFlex = flexValue;
    }

    if (key === ARRIVAL_DATE_PARAM_NAME || (key === DEPARTURE_DATE_PARAM_NAME && value)) {
      dates[key] = formatDateString(value);
    }

    if (key === BRAND_CODE_PARAM_NAME && value) {
      internalBrandCode = value;
    }

    const specialRateValue = mapValue(validSpecialRatesKeys, key, value);

    if (specialRateValue) {
      specialRates[key] = stringOrBooleanOrNull(specialRateValue);
    }

    const additioinalQsValue = mapAdditionalQsTypes(
      key,
      mapValue(validAdditionalQsParamNames, key, value)
    );

    if (additioinalQsValue) {
      // this replace is required as the query params have `-` in them
      // while our props have `_` in their place
      const normalizedKey = key.replace(/-/, '_');

      additioinalQs[normalizedKey] = additioinalQsValue;
    }

    if (key === NUM_ADULT_PARAM_NAME) {
      adults.push({
        adults: parseInt(value),
        roomNumber: roomNumberParam,
      });
    }

    if (key === NUM_CHILD_PARAM_NAME) {
      const numberOfChildren = parseInt(value);

      Array(numberOfChildren)
        .fill(0)
        .forEach(() => {
          children.push({
            roomNumber: roomNumberParam,
          });
        });
    }
  }

  const specialRatesLength = Object.entries(specialRates).length;
  const additionalQsLength = Object.entries(additioinalQs).length;

  const transformedRooms = adults.map((adult) => {
    const agesForChildrenInRoom = ages.filter((age) => {
      return age.roomNumber === adult.roomNumber;
    });

    const lastAgeIndex = agesForChildrenInRoom[agesForChildrenInRoom.length - 1];

    const childrenForRoom = children
      .filter((child) => {
        return child.roomNumber === adult.roomNumber;
      })
      .map((_, index) => {
        const age = index + 1 > lastAgeIndex ? null : agesForChildrenInRoom[index];

        return {
          ...(age && { age: age.value }),
        };
      });

    return {
      ...adult,
      roomNumber: adult.roomNumber,
      children: childrenForRoom,
    };
  });

  if (dates.arrivalDate && dates.departureDate && minLOS) {
    const deepLinkLengthOfStay = differenceInDays(dates.departureDate, dates.arrivalDate);

    if (deepLinkLengthOfStay < minLOS) {
      dates.departureDate = add(dates.arrivalDate, { days: minLOS });
    }
  }

  return {
    brandCode: internalBrandCode,
    numRooms,
    roomNumber: roomNumberParam,
    rooms: transformedRooms,
    dates,
    ...(specialRatesLength > 0 && { specialRates }),
    ...(additionalQsLength > 0 && { additionalQs: additioinalQs }),
  };
};
