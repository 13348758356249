import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./smoolol/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-40 antialiased md:w-48" fillColor="#335684" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-40 antialiased md:w-48" fillColor="#FFFFFF" />
);
