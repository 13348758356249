import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings } from './common-vars';

export const Restaurants = gql`
fragment Restaurants on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_Restaurants {
        fieldGroupName
        readMoreLess
        hideList
        restaurants {
          cuisineType
          cuisineTypeSelect
          iconList
          fieldGroupName
          restaurantId
          buttons {
            link {
              target
              title
              url
            }
            buttonStyle
          }
          openingHours {
            hours
            title
          }
          image {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
        }
        restaurantsComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          hoursSeperatorColour
          hideTileBorder
          cuisineTypeTextColour
          cuisineTypeIconColour
          tileBorderColour
          topSectionBackgroundColour
        }
      }
}
`;
