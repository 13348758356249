export function Close({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M12.5312,12l4.7344-4.7344a.3756.3756,0,0,0-.5312-.5312L12,11.4688,7.2656,6.7344a.3756.3756,0,0,0-.5312.5312L11.4687,12,6.7344,16.7344a.3756.3756,0,0,0,.5312.5312L12,12.5312l4.7344,4.7344a.3756.3756,0,0,0,.5312-.5312Z" />
      </g>
    </svg>
  );
}
