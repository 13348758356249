import gql from 'graphql-tag-transform.macro';
import { informationBarComponentSettings } from './common-vars';

export const InformationBar = gql`
fragment InformationBar on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_InformationBar {
        fieldGroupName
        emailAddress
        hideIcons
        phoneNumber
        bookCta {
          fieldGroupName
          buttonStyle
          link {
            target
            title
            url
          }
        }
        details {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        location {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        hours {
          fieldGroupName
          copy
          buttons {
            buttonStyle
            link {
              target
              title
              url
            }
          }
        }
        informationBarComponentSettings {
          fieldGroupName
          showHide
          ${informationBarComponentSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
}
`;
