import type { Brand_Hotel_ShopAvailOptionsQuery as BrandFooterQuery } from '@dx-ui/queries-dx-curated-ui/generated/dx-gql';
import type { ReactNode } from 'react';
import { createContext } from 'react';
/**
 * Shared Props
 * - Define some shared props that all components need access to.
 * - Can be accessed using the useContext() hook.
 * - Kinda like a global state.
 **/
export interface SharedProps {
  hotelName?: string;
  pageInfo?: {
    pageId?: number | string;
    slug?: string;
  };
  ctyhocn?: string;
  brandCode?: string;
  allInclusive?: boolean;
  dxGql?: BrandFooterQuery;
  children?: ReactNode;
  locale?: string;
  modalStyles?: object;
  enableHHR?: boolean;
  hasVisitedWiFi?: string;
}

export const SharedContext = createContext<SharedProps>({});

export function SharedProvider(props: SharedProps) {
  return (
    <SharedContext.Provider
      value={{
        pageInfo: props.pageInfo,
        hotelName: props.hotelName,
        brandCode: props.brandCode,
        ctyhocn: props?.ctyhocn,
        allInclusive: props?.allInclusive,
        dxGql: props.dxGql,
        locale: props?.locale,
        modalStyles: props?.modalStyles,
        enableHHR: props?.enableHHR,
        hasVisitedWiFi: props?.hasVisitedWiFi,
      }}
    >
      {props.children}
    </SharedContext.Provider>
  );
}
