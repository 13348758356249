export function Filters({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,11.625H11a.375.375,0,0,0,0,.75h8a.375.375,0,0,0,0-.75Z" />
          <path d="M5,8.375h8.625V10a.375.375,0,0,0,.75,0V6a.375.375,0,0,0-.75,0V7.625H5a.375.375,0,0,0,0,.75Z" />
          <path d="M11,13.625a.3751.3751,0,0,0-.375.375v1.625H5a.375.375,0,0,0,0,.75h5.625V18a.375.375,0,0,0,.75,0V14A.3751.3751,0,0,0,11,13.625Z" />
          <path d="M5,12.375H7.625V14a.375.375,0,0,0,.75,0V10a.375.375,0,0,0-.75,0v1.625H5a.375.375,0,0,0,0,.75Z" />
          <path d="M17,8.375h2a.375.375,0,0,0,0-.75H17a.375.375,0,0,0,0,.75Z" />
          <path d="M19,15.625H14a.375.375,0,0,0,0,.75h5a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
