import { EventCalendarDaysOfWeekRow } from '../event-calendar-days-of-week-row';
import type { StyleObject } from '../../functions/global-instance-styles';
import { GIS_Array } from '../../functions/global-instance-styles';
interface EventCalendarDatePickerProps {
  dateList?: any;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export default function EventCalendarDatePicker({
  dateList,
  instanceStyles,
  globalStyles,
}: EventCalendarDatePickerProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  return (
    <table
      id="eventCalendarMobileDatepicker"
      data-testid="calendar-range-picker"
      data-element-id="event-calendar-mobile-datepicker"
      aria-hidden="true"
      className="mx-auto w-full table-fixed sm:w-2/3"
      style={{
        backgroundColor: inlineStyles?.eventsCalendarDatePickerBgColor,
      }}
    >
      <EventCalendarDaysOfWeekRow dayDisplayType="abbr" />
      <tbody>
        {dateList?.map((i) => {
          return i;
        })}
      </tbody>
    </table>
  );
}
