import { DISABLE_AARP_SPECIAL_RATES } from '../constants/featureToggles';
import { useFeatureTogglesQuery } from '../gql/queries';
import { getIsFeatureToggleEnabled } from '@dx-ui/utilities-feature-toggles-configs';

export function useAarpToggle() {
  const { data } = useFeatureTogglesQuery({
    names: [DISABLE_AARP_SPECIAL_RATES],
  });

  return {
    disableAARPSpecialRates: getIsFeatureToggleEnabled({
      featureToggleData: data,
      name: DISABLE_AARP_SPECIAL_RATES,
    }),
  };
}
