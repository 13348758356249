import type { QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import type { IncompleteQueryEvent } from './default-fetch-fn';
import { logError } from '@dx-ui/framework-logger';
import type { DXExecutionResult, QueryDebugInfo } from '@dx-ui/types-graphql';

export function useQueryIncomplete({
  queryClient,
  onIncompleteQuery,
  incompleteLoggingEnabled,
}: {
  queryClient: QueryClient;
  onIncompleteQuery:
    | ((query: QueryDebugInfo) => {
        reportIncomplete: boolean;
      })
    | undefined;
  incompleteLoggingEnabled: boolean | undefined;
}) {
  useEffect(() => {
    const ssrResults = queryClient.getQueriesData<DXExecutionResult>({
      predicate: (query) => (query.state.data as DXExecutionResult)?.__info__?.isSSR === true,
    });
    const incompleteSSRQueries = ssrResults.filter(([, data]) => {
      return data && data.__info__?.dxCompleteness !== '1';
    });

    if (incompleteSSRQueries.length > 0) {
      incompleteSSRQueries.forEach(([, data]) => {
        if (data && data.__info__) {
          const { reportIncomplete } = onIncompleteQuery?.(data.__info__) || {
            reportIncomplete: true,
          };
          if (reportIncomplete && incompleteLoggingEnabled) {
            logError(
              'SSR_INCOMPLETE',
              `${data?.__info__.operationName} / ${data.__info__?.originalOpName}`
            );
          }
        }
      });
    }

    if (typeof window !== 'undefined') {
      const listener = (event: CustomEvent<IncompleteQueryEvent>) => {
        // at this point we have a client side query that had a header dx-completeness: 0
        // log that to logger, after letting callers determine if they should report (report by default)
        const { reportIncomplete } = onIncompleteQuery?.(event.detail) || {
          reportIncomplete: true,
        };
        if (reportIncomplete && incompleteLoggingEnabled) {
          // report to logger if the value is on
          logError(
            'CLIENT_INCOMPLETE',
            `${event.detail.operationName} / ${event.detail.originalOpName}`
          );
        }
      };
      window.addEventListener('dx-incomplete', listener as EventListener);
      return () => {
        window.removeEventListener('dx-incomplete', listener as EventListener);
      };
    }
    return () => undefined;
  }, [queryClient, onIncompleteQuery, incompleteLoggingEnabled]);
}
