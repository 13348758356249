/* Cala San Miguel by Curio – ibzseqq */

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/xju0nae.css" />
      <style>
        {`
            :root {
              --font-sans: dm-sans, sans-serif;
            }
            
            
            `}
      </style>
    </>
  );
};
