import gql from 'graphql-tag-transform.macro';

export const Accordion = gql`
  fragment Accordion on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_Accordion {
      content
      fieldGroupName
      heading
      panel {
        fieldGroupName
        panelLabel
        panelRepeater {
          fieldGroupName
          rowText
          rowLabel
        }
      }
      subtitle
      accordionComponentSettings {
        fieldGroupName
        showHide
        iconIndicatorColour
        iconIndicatorColourExpanded
        openPanelBackgroundColour
        borderColour
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
