import { Stepper } from '@dx-ui/osc-stepper';
import { useTranslation } from 'next-i18next';
import type { Action, State } from '../room-types';
import type { Filters } from '../buildFilters';
import { capitalizeFirstLetter } from '@curated-property/utils';
import { reduceOpacity } from '../../functions/helper';

interface FilterItemProps {
  label: string;
  value: string | number | boolean;
}

interface PanelProps {
  data?: FilterItemProps[];
  dispatchFilterState: (obj: Action) => void;
}

interface CheckboxPanelProps extends PanelProps {
  filterKey: keyof Filters;
  filterState: string[];
}

interface ShowerCheckboxProps extends CheckboxPanelProps {
  showerState?: boolean;
  setShowerState?: (val: boolean) => void;
}

interface GuestsProps extends PanelProps {
  guests: {
    adults: number;
    kids: number;
    kidsAges: number[];
  };
  setGuests: (guests) => void;
  maxOccupancy: number;
  filterState: State;
  adultsOnly?: boolean;
  stepperColor?: string;
  stepperColorDisabled?: string;
}

export function GuestsPanel({
  guests,
  setGuests,
  maxOccupancy,
  dispatchFilterState,
  filterState,
  data,
  adultsOnly,
  stepperColor,
  stepperColorDisabled,
}: GuestsProps) {
  const [t] = useTranslation();
  const sharedClasses = 'flex items-center justify-self-center px-2';
  const a11yName = t('accessibleRoomLabel');
  const totalGuests = guests.adults + guests.kids;
  const adjustedDisabledColor = !stepperColorDisabled
    ? reduceOpacity(stepperColor, 0.6)
    : stepperColorDisabled;

  return (
    <div className="w-full" data-adults-only={adultsOnly} data-max-occupancy={maxOccupancy}>
      <div className="flex w-full flex-wrap justify-center gap-y-5 pt-2">
        <div className={sharedClasses} data-testid="adultsStepper">
          <p className="mr-2">
            <strong>{t('occupancy.adultHeader')}</strong>
          </p>
          <Stepper
            onMinusClick={() => {
              setGuests((prevState) => ({
                ...prevState,
                adults: guests.adults > 1 && --guests.adults,
              }));
              setTimeout(() => {
                dispatchFilterState({
                  key: 'guests',
                  checked: true,
                  item: guests.adults + guests.kids,
                });
              }, 20);
            }}
            onPlusClick={() => {
              setGuests((prevState) => ({
                ...prevState,
                adults: guests.adults <= maxOccupancy && ++guests.adults,
              }));
              setTimeout(() => {
                dispatchFilterState({
                  key: 'guests',
                  checked: true,
                  item: guests.adults + guests.kids,
                });
              }, 20);
            }}
            min={1}
            max={maxOccupancy - guests.kids}
            value={guests.adults}
            valueLabel={t('occupancy.adultHeader')}
            plusLabel={t('occupancy.addAdult')}
            plusTestId="search-add-night"
            minusLabel={t('occupancy.removeAdult')}
            minusTestId="search-remove-night"
            id="adultsStepper"
            className="[&>button]:!h-auto"
            iconColor={stepperColor}
            iconColorDisabled={adjustedDisabledColor}
          />
        </div>
        {!adultsOnly && (
          <div className={sharedClasses} data-testid="kidsStepper">
            <p className="mr-2">
              <strong>{t('occupancy.childHeader')}</strong>
            </p>
            <Stepper
              onMinusClick={() => {
                setGuests((prevState) => ({
                  ...prevState,
                  kids: guests.kids > 0 && --guests.kids,
                }));
                setTimeout(() => {
                  dispatchFilterState({
                    key: 'guests',
                    checked: true,
                    item: guests.adults + guests.kids,
                  });
                }, 20);
              }}
              onPlusClick={() => {
                setGuests((prevState) => ({
                  ...prevState,
                  kids: guests.kids <= maxOccupancy && ++guests.kids,
                }));
                setTimeout(() => {
                  dispatchFilterState({
                    key: 'guests',
                    checked: true,
                    item: guests.adults + guests.kids,
                  });
                }, 20);
              }}
              min={0}
              max={maxOccupancy - guests.adults}
              value={guests.kids}
              valueLabel={t('occupancy.childHeader')}
              plusLabel={t('occupancy.addChild')}
              plusTestId="search-add-night"
              minusLabel={t('occupancy.removeChild')}
              minusTestId="search-remove-night"
              id="kidsStepper"
              className="[&>button]:!h-auto"
              iconColor={stepperColor}
              iconColorDisabled={adjustedDisabledColor}
            />
          </div>
        )}
        {data.length > 1 && (
          <div className={sharedClasses}>
            <CheckBoxFilters
              checked={filterState?.adaAccessibleRoom.includes(true as any)}
              value={true}
              isBoolean={true}
              label={a11yName}
              filterKey="adaAccessibleRoom"
              onChange={(item, checked) =>
                dispatchFilterState({
                  key: 'adaAccessibleRoom',
                  checked,
                  item,
                })
              }
            />
          </div>
        )}
      </div>
      {guests.kids ? (
        <div className="mx-auto flex max-w-xs flex-col">
          <p className="pb-2 pt-4 text-sm">
            <strong>{t('occupancy.agesLabel')}</strong>
          </p>
          {Array.from({ length: guests.kids }).map((__, key) => {
            return <ChildAgeSelect id={key} key={key} />;
          })}
        </div>
      ) : (
        ''
      )}
      {totalGuests === maxOccupancy && (
        <p className="p-2 text-center text-sm font-bold italic">{t('maxOccupancyReached')}</p>
      )}
    </div>
  );
}

export function CheckBoxPanel({
  data,
  dispatchFilterState,
  filterState,
  filterKey,
}: CheckboxPanelProps) {
  return (
    <div className="flex w-full flex-wrap justify-center pt-2">
      {data?.map((item, key) => {
        return (
          <CheckBoxFilters
            id={key}
            key={key}
            checked={filterState?.includes(item?.value?.toString())}
            value={item.value}
            label={item.label}
            filterKey={filterKey}
            onChange={(item, checked) =>
              dispatchFilterState({
                key: filterKey,
                checked,
                item,
              })
            }
          />
        );
      })}
    </div>
  );
}

export function TubCustomCheckboxPanels({
  data,
  dispatchFilterState,
  filterState,
  filterKey,
  showerState,
  setShowerState,
}: ShowerCheckboxProps) {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-wrap justify-center pt-2">
      {data?.map((item, key) => {
        return (
          <CheckBoxFilters
            id={key}
            key={key}
            checked={filterState?.includes(item?.value?.toString())}
            value={item.value}
            label={t('policies.yes')}
            filterKey={filterKey}
            onChange={(item, checked) => {
              setShowerState(false);
              dispatchFilterState({
                key: filterKey,
                checked,
                item,
              });
            }}
          />
        );
      })}
      <CheckBoxFilters
        id="shower"
        checked={showerState}
        value="shower"
        label={t('policies.no')}
        filterKey="shower"
        onChange={() => {
          showerState === false ? setShowerState(true) : setShowerState(false);
          dispatchFilterState({
            key: filterKey,
            checked: false,
            item: '',
          });
        }}
      />
    </div>
  );
}

interface SelectBoxProps {
  id: number;
  maxAge?: number;
}

const ChildAgeSelect = ({ id, maxAge = 17 }: SelectBoxProps) => {
  const [t] = useTranslation();
  const idFromOne = id + 1;

  return (
    <div key={id} className="flex items-center py-1">
      <label htmlFor={`kid-age-select-${idFromOne}`} className="w-14">
        <strong>{`${t('occupancy.kidLabel')} ${idFromOne}`}</strong>
      </label>
      <select
        key={idFromOne}
        data-testid={`kid-age-select-${idFromOne}`}
        name={`kid-age-select-${idFromOne}`}
        className="label-inline form-select w-full !self-auto"
        aria-label={t('occupancy.kidLabel')}
      >
        <option disabled selected value="" />
        <option value="0">{t('occupancy.underOne')}</option>
        {Array.from({ length: maxAge }).map((__, key) => {
          return (
            <option key={key++} value={key++}>
              {key++}
            </option>
          );
        })}
      </select>
    </div>
  );
};

interface CheckBoxProps {
  id?: number | string;
  value: FilterItemProps['value'];
  isBoolean?: boolean;
  label: FilterItemProps['label'];
  filterKey: string;
  checked: boolean;
  onChange: (item: string | number | boolean, value: boolean) => void;
}

const CheckBoxFilters = ({ id, value, label, filterKey, checked, onChange }: CheckBoxProps) => {
  if (value === null || label === null) {
    // some values return as an array entry, but have a null value
    return null;
  }

  const formattedId = label.toLowerCase().replace(' ', '');
  return (
    <div className="flex items-center px-2" key={id}>
      <input
        type="checkbox"
        id={label}
        name={label}
        data-testid={`${filterKey}-checkbox-${formattedId}`}
        className="form-checkbox mr-2"
        checked={checked}
        onChange={(e) => {
          onChange(value, e.target.checked);
        }}
      />
      <label htmlFor={label}>
        <strong>{capitalizeFirstLetter(label)}</strong>
      </label>
    </div>
  );
};
