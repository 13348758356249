import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings } from './common-vars';

export const MultiColumnTiles = gql`
fragment MultiColumnTiles on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_MultiColumn {
        fieldGroupName
        header
        headerCopy
        headingValue
        columnCount
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        imageSize
        tileFilters
        filterCategories {
          slug
          name
        }
        repeater {
          accordionPanel {
            panelLabel
            panelRepeater {
              fieldGroupName
                rowLabel
                rowText
            }
          }
          title
          title_noTx: title
          copy
          iconLabel
          iconList
          image {
            altText
            sourceUrl
          }
          images {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          category {
            name
            slug
          }
          tableData {
            title
            copy
          }
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          tileImageLabel
          showHide
        }
        multiColumnComponentSettings {
          fieldGroupName
          showHide
          accordionBorderColor
          accordionIconColor
          accordionIconColorActive
          accordionPanelBackgroundColor
          accordionPanelLabelColor
          accordionRowLabelColor
          accordionRowTextColor
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          filterActiveTextColour
          filterBackgroundColour
          filterTextColour
          uiBackgroundColour
          uiControlsColour
          headerTitleColour
          headerCopyColour
          tileImageLabelBackgroundColor
          tileImageLabelIconColor
          tileImageLabelTextColor
          tileHorizontalDividerLineColor
        }
      }
}
`;
