import { useState, useEffect, useRef } from 'react';
import { slugify, iconmapper } from '../functions/helper';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Caret, LocationBlank } from '@curated-property/icon-list';
import type { PinContent } from './interactive-map.types';
import camelCase from 'lodash/camelCase';

export function MapPin({
  id,
  pinColor,
  pinAccentColor,
  pinTitle,
  pinX,
  pinY,
  pinCategory,
  pinIcon,
  pinModal,
  selectedCategory,
  openModal,
  modalContent,
  modalDescription,
  modalImage,
  modalLink,
  modalLink2,
  modalLinkText,
  pinActive,
  setPinActive,
  boxHeight,
  boxWidth,
}: PinContent) {
  const [pinInfoOffsetLeft, setPinInfoOffsetLeft] = useState<number | null>(null);
  const [pinInfoOffsetRight, setPinInfoOffsetRight] = useState<number | null>(null);
  pinIcon = pinIcon || 'hotel';
  const iconMap = iconmapper();
  const { t } = useTranslation();
  const Icon = iconMap[pinIcon as keyof typeof iconMap];

  const pinRef = useRef<HTMLButtonElement>(null);
  const pinInfoRef = useRef<HTMLDivElement>(null);
  const showPinTextAbove: boolean =
    (pinRef?.current && boxHeight - pinRef.current?.offsetTop < 100) || false;

  useEffect(() => {
    const offsetLeftAmount =
      (pinInfoRef?.current?.offsetWidth || 0) / 2 + 15 + (pinRef?.current?.offsetLeft || 0);
    const offsetRightAmount =
      (pinInfoRef?.current?.offsetWidth || 0) / 2 - 5 - (pinRef?.current?.offsetLeft || 0);
    setPinInfoOffsetLeft(offsetLeftAmount - boxWidth > 0 ? offsetLeftAmount - boxWidth : null);
    setPinInfoOffsetRight(offsetRightAmount > 0 ? offsetRightAmount : null);
  }, [setPinInfoOffsetLeft, setPinInfoOffsetRight, pinInfoRef, pinRef, boxWidth, pinActive]);

  return (
    <button
      data-cy={camelCase(pinCategory + 'MapPin')}
      data-testid={`${slugify(pinTitle)}-pin`}
      className={cx(
        'absolute z-10 -translate-x-1.5 -translate-y-full cursor-pointer whitespace-nowrap text-white transition-all duration-100 ease-in-out',
        pinActive?.id === id ? 'z-20' : '',
        selectedCategory ? 'opacity-1 visible' : 'invisible -translate-y-3/4 opacity-0'
      )}
      style={{
        top: pinX + `%`,
        left: pinY + `%`,
      }}
      onMouseEnter={() => setPinActive({ id })}
      onMouseLeave={() => setPinActive({ id: undefined })}
      onMouseUp={() => {
        pinModal && openModal();
        pinModal &&
          modalContent({
            id,
            title: pinTitle,
            image: modalImage,
            description: modalDescription,
            link: modalLink,
            link2: modalLink2,
            linkText: modalLinkText,
          });
      }}
      onTouchEnd={() => {
        if (pinModal) {
          openModal();
          modalContent({
            id,
            title: pinTitle,
            image: modalImage,
            description: modalDescription,
            link: modalLink,
            link2: modalLink2,
            linkText: modalLinkText,
          });
          setPinActive({ id: undefined });
        } else if (pinActive?.id === id) {
          setPinActive({ id: undefined });
        } else setPinActive({ id });
      }}
      aria-label={`${pinModal ? t('openModal') + ' ' : ''}${pinTitle}`}
      ref={pinRef}
    >
      <div className="relative flex items-center justify-center">
        <Icon className="absolute top-1.5 h-6 fill-current" fillColor={pinAccentColor} />
        <LocationBlank className="fill-primary h-12" fillColor={pinColor} />
      </div>
      {pinActive?.id === id ? (
        <div
          data-cy="hoveredPin"
          data-testid={`${slugify(pinTitle)}-pin-info`}
          className={cx('absolute left-1/2 z-20 flex -translate-x-1/2 flex-col', {
            'bottom-[calc(100%+45px)]': showPinTextAbove,
            'top-[calc(100%+2px)]': !showPinTextAbove,
          })}
        >
          <Caret
            className={cx('h-2 translate-y-0.5 rotate-180', {
              '!rotate-0 translate-y-[39px]': showPinTextAbove,
            })}
            fillColor="#fff"
          />
          <span
            ref={pinInfoRef}
            className="text-text bg-bg absolute left-1/2 top-2 flex h-8 -translate-x-1/2 items-center justify-center rounded px-4"
            style={{
              left: pinInfoOffsetLeft
                ? `-${pinInfoOffsetLeft}px`
                : pinInfoOffsetRight
                ? `${pinInfoOffsetRight}px`
                : '',
            }}
          >
            {pinTitle}
          </span>
        </div>
      ) : null}
    </button>
  );
}
