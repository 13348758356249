import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./romhiwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="mr-0 w-28 fill-current text-current antialiased lg:mr-4 lg:w-40"
    fillColor="#000000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current text-current antialiased lg:w-40" fillColor="#ffffff" />
);
