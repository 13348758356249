export function Coffee({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17,20.625H6a.375.375,0,0,0,0,.75H17a.375.375,0,0,0,0-.75Z" />
          <path d="M11,5.2578h1.25a.3332.3332,0,1,1,0,.6611A1.1054,1.1054,0,0,0,11.125,7a.375.375,0,0,0,.75,0,.3553.3553,0,0,1,.375-.3311,1.0814,1.0814,0,1,0,0-2.1611H11a.5984.5984,0,0,1-.625-.5664A.5984.5984,0,0,1,11,3.375h3a.375.375,0,0,0,0-.75H11A1.3486,1.3486,0,0,0,9.625,3.9414,1.3486,1.3486,0,0,0,11,5.2578Z" />
          <path d="M18,10.625h-.625V9A.3751.3751,0,0,0,17,8.625H6A.3751.3751,0,0,0,5.625,9v6A4.38,4.38,0,0,0,10,19.375h3a4.3664,4.3664,0,0,0,4.3371-4H18a2.375,2.375,0,0,0,0-4.75ZM16.625,15A3.6289,3.6289,0,0,1,13,18.625H10A3.6289,3.6289,0,0,1,6.375,15V9.375h10.25ZM18,14.625h-.625v-3.25H18a1.625,1.625,0,0,1,0,3.25Z" />
        </g>
      </g>
    </svg>
  );
}
