import * as React from 'react';
import { type FieldValues, FormProvider, useForm } from 'react-hook-form';
import type { Dialog } from '@dx-ui/osc-dialog-v2';
import { ActionDialog, DialogTitle } from '@dx-ui/osc-dialog-v2';
import type { FormDataStructure, FormProps } from '@dx-ui/osc-form';
import { useTranslation } from 'next-i18next';
import type { Rooms as RoomsType } from './rooms';
import { Rooms } from './rooms';
import type { ShopFormRoom } from './types';

type RoomsModal = Pick<FormProps, 'useFormProps'> &
  RoomsType &
  React.ComponentProps<typeof Dialog> & { onSubmit: (data: FieldValues) => void };

const RoomsModal: React.FC<React.PropsWithChildren<RoomsModal>> = ({
  ageRange,
  agesRequired,
  useFormProps,
  maxRooms,
  occupancyLimitMessage,
  onSubmit,
  isOpen,
}) => {
  const [t] = useTranslation('osc-rooms');
  const memoizedFormProps = React.useMemo(() => useFormProps, [useFormProps]);
  const confirmButtonRef = React.useRef<HTMLButtonElement>(null);

  const methods = useForm<FormDataStructure>({
    mode: 'onTouched',
    criteriaMode: 'all',
    ...memoizedFormProps,
  });
  const { setValue, clearErrors, getValues } = methods;
  const rooms: ShopFormRoom[] = getValues().rooms;
  const isAnyRoomHaveChildrenOccupants = rooms?.some((room) => room?.children?.length);

  const removeKids = () => {
    clearErrors();
    rooms?.forEach((_room, index) => {
      setValue(`rooms.${index}.children`, []);
    });
    confirmButtonRef?.current?.focus();
  };

  return (
    <FormProvider {...methods}>
      <ActionDialog
        isOpen={isOpen}
        size="xl"
        hasChildrenWithTitle
        data-testid="rooms-modal"
        onCancel={removeKids}
        onConfirm={() => onSubmit?.(getValues())}
        buttonOptions={{
          cancel: {
            label: t('occupancy.ageBasedRemove'),
            disabled: !isAnyRoomHaveChildrenOccupants,
          },
          confirm: { ref: confirmButtonRef },
        }}
      >
        <div className="mb-6 mt-3 text-center">
          <DialogTitle className="text-lg font-bold">{t('occupancy.ageBasedTitle')}</DialogTitle>
          <p>{t('occupancy.ageBasedDescription')}</p>
        </div>

        <Rooms
          ageRange={ageRange}
          maxRooms={maxRooms}
          agesRequired={agesRequired}
          occupancyLimitMessage={occupancyLimitMessage}
        />
      </ActionDialog>
    </FormProvider>
  );
};

export { RoomsModal };
export default RoomsModal;
