import type * as React from 'react';
import { Fragment, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { ArrowHead, Refresh } from '@curated-property/icon-list';
import type { Action } from '../room-types';
import { slugify } from '../../functions/helper';

interface FilterComponentProps {
  active: boolean;
  tabTitle: string;
  tabId: string;
  tabIcon: React.ReactNode;
  tabPanelTitle: string;
  component: React.ReactNode;
}

interface FYBRProps {
  filterData: {
    guests: FilterComponentProps;
    beds: FilterComponentProps;
    view?: FilterComponentProps;
    showerTub?: FilterComponentProps;
  };
  dispatchFilterState: (value: Action | { clearAll: boolean }) => void;
  setShowerState?: (val: boolean) => void;
  styles?: {
    textColor?: string;
    headingColor?: string;
    dividerColor?: string;
    disabledColor?: string;
    navButtonsColor?: string;
  };
}

export function FindYourBestRoom(props: FYBRProps) {
  const filterDataArray = Object.values(props?.filterData).filter(
    (filter) => filter.active === true
  );
  const tabs = filterDataArray.map((item) => `tab-${slugify(item?.tabId)}`);
  const [tabValue, setTabValue] = useState<string>(tabs[0]);

  const handleTabsChange = (index: number) => {
    setTabValue(tabs[index]);
  };

  return (
    <div className="w-full max-w-2xl" style={{ color: props?.styles?.textColor }}>
      <Tabs.Root onValueChange={(value) => setTabValue(value)} value={tabValue}>
        <Tabs.List
          className="cp-trigger-tabs border-overlay flex items-center justify-center border-b bg-transparent p-3"
          style={{ borderColor: props?.styles?.dividerColor }}
        >
          {filterDataArray?.map((item, index) => {
            return (
              <Fragment key={index}>
                <FYBRTab
                  value={`tab-${slugify(item?.tabId)}`}
                  selectedValue={tabValue}
                  disabledColor={props?.styles?.disabledColor}
                >
                  <span className="size-8 fill-current">{item?.tabIcon}</span>{' '}
                  <Text>{item?.tabTitle}</Text>
                </FYBRTab>
                <span
                  className="mx-4 h-px w-4 bg-black last-of-type:mx-0 last-of-type:size-0 sm:mx-5 sm:w-9"
                  style={{ backgroundColor: props?.styles?.dividerColor }}
                />
              </Fragment>
            );
          })}
        </Tabs.List>
        {filterDataArray?.map((item, index) => {
          return (
            <FYBRPanel value={`tab-${slugify(item?.tabId)}`} key={index}>
              <h2 style={{ color: props?.styles?.headingColor }}>
                <Text>{item?.tabPanelTitle}</Text>
              </h2>
              {item?.component}
            </FYBRPanel>
          );
        })}
      </Tabs.Root>
      <TabNavigationControls
        handleTabsChange={handleTabsChange}
        selectedValue={tabValue}
        tabs={filterDataArray.map((item) => `tab-${slugify(item?.tabId)}`)}
        total={tabs?.length}
        reset={props?.dispatchFilterState}
        setShowerState={props?.setShowerState}
        styles={{
          buttonColor: props?.styles?.navButtonsColor,
          dividerColor: props?.styles?.dividerColor,
          disabledColor: props?.styles?.disabledColor,
        }}
      />
    </div>
  );
}

interface TabAndPanelProps {
  children: React.ReactNode;
  value: string;
}

interface FYBRTabProps extends TabAndPanelProps {
  children: React.ReactNode;
  selectedValue: string;
  value: string;
  disabledColor?: string;
}

function FYBRTab({ children, selectedValue, value, disabledColor }: FYBRTabProps) {
  return (
    <Tabs.Trigger
      className={cx(
        'flex content-center items-center gap-1 border-none bg-transparent px-0 transition-opacity ease-in-out',
        {
          'opacity-100': selectedValue === value,
          'opacity-30 hover:opacity-80 focus:hover-80': selectedValue !== value,
        }
      )}
      style={{
        opacity: disabledColor && 1,
        color: selectedValue !== value && disabledColor,
        fill: selectedValue !== value && disabledColor,
      }}
      data-testid={value}
      value={value}
      data-reach-tab
    >
      {children}
    </Tabs.Trigger>
  );
}

function FYBRPanel({ children, value }: TabAndPanelProps) {
  return (
    <Tabs.Content
      className="exclude-focus flex flex-col items-center justify-center px-2 py-8"
      data-testid={value.replace('tab', 'panel')}
      value={value}
    >
      {children}
    </Tabs.Content>
  );
}

function Text({ children }: { children: React.ReactNode }) {
  return <span className="block font-bold leading-6 sm:text-lg">{children}</span>;
}

interface TabNavProps {
  handleTabsChange: (i: number) => void;
  selectedValue: string;
  tabs: string[];
  total: number;
  reset: FYBRProps['dispatchFilterState'];
  setShowerState?: (val: boolean) => void;
  styles: {
    buttonColor?: string;
    dividerColor?: string;
    disabledColor?: string;
  };
}

function TabNavigationControls({
  handleTabsChange,
  selectedValue,
  tabs,
  total,
  reset,
  setShowerState,
  styles,
}: TabNavProps) {
  const [t] = useTranslation();

  let tabIndex = tabs.indexOf(selectedValue);

  const getCmsStyle = (isDisabled: boolean) => {
    return {
      opacity: styles?.disabledColor && 1,
      color: (isDisabled && styles?.disabledColor && styles?.disabledColor) || styles?.buttonColor,
    };
  };

  return (
    <nav
      className="border-overlay flex justify-between border-b py-2"
      style={{ borderColor: styles?.dividerColor }}
    >
      <button
        className="text-primary flex items-center disabled:cursor-not-allowed disabled:opacity-50"
        disabled={tabIndex === 0}
        onClick={() => handleTabsChange(--tabIndex)}
        aria-label={t('fybrNavPrev')}
        data-testid="fybr-nav-prev"
        data-element-id="fybr-nav-prev"
        style={getCmsStyle(tabIndex === 0)}
      >
        <ArrowHead className="w-6 rotate-90 fill-current" />
        <span className="font-bold underline">{t('previous')}</span>
      </button>
      <div className="flex gap-4">
        <button
          className="text-primary flex items-center disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => {
            handleTabsChange(0);
            reset({ clearAll: true });
            setShowerState(false);
          }}
          disabled={tabIndex === 0}
          aria-label={t('fybrNavReset')}
          data-testid="fybr-nav-reset"
          data-element-id="fybr-nav-reset"
          style={getCmsStyle(tabIndex === 0)}
        >
          <Refresh className="isolate w-6 fill-current" />
          <span className="font-bold underline">{t('startAgain')}</span>
        </button>
        <button
          className="text-primary flex items-center disabled:cursor-not-allowed disabled:opacity-50"
          onClick={() => handleTabsChange(++tabIndex)}
          disabled={tabIndex === total - 1}
          aria-label={t('fybrNavNext')}
          data-testid="fybr-nav-next"
          data-element-id="fybr-nav-next"
          style={getCmsStyle(tabIndex === total - 1)}
        >
          <span className="font-bold underline">{t('next')}</span>
          <ArrowHead className="w-6 -rotate-90 fill-current" />
        </button>
      </div>
    </nav>
  );
}
