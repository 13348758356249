import type { ReactNode } from 'react';
import cx from 'classnames';
import type { AnimationOutput } from '../functions/helper';
import { HandleAnimations, HandleWYSIWYGContent, iconmapper } from '../functions/helper';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Padder, GIS_merge } from '../functions/global-instance-styles';
import { appliedCloudinaryParams, isReducedMotion } from '@curated-property/utils';
import type { ACFAnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';
import contentStyles from '../css/contentStyle.module.css';

interface ContentProps {
  copy?: string;
  buttons?: Array<ACFAnchorProps>;
}

export interface InformationBarProps {
  location?: ContentProps;
  hours?: ContentProps;
  details?: ContentProps;
  phoneNumber?: string;
  emailAddress?: string;
  bookCta?: ACFAnchorProps;
  hideIcons?: boolean;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  enableHHR?: boolean;
}

export function InformationBar({
  location,
  hours,
  details,
  phoneNumber,
  emailAddress,
  bookCta,
  hideIcons,
  globalStyles,
  instanceStyles,
  enableHHR,
}: InformationBarProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const iconMap = iconmapper();
  const hideAnimations: boolean = inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  let isAltContact: boolean =
    inlineStyles?.contactBackgroundColor || inlineStyles?.contactBorderColor ? true : false;

  // Set altContact to true for Hilton Hotel & Resorts
  if (enableHHR) isAltContact = true;

  const hasContact: boolean = phoneNumber || emailAddress || bookCta?.link ? true : false;
  const textAlignment = inlineStyles?.textAlignment?.includes('-')
    ? inlineStyles?.textAlignment?.split('-')?.[1]
    : inlineStyles?.textAlignment;
  const colClasses = `flex flex ${
    textAlignment === 'center' ? 'flex-col' : ''
  } px-6 w-full sm:w-1/2 xl:w-auto grow border-transparent sm:even:border-l xl:border-l first:!border-l-0 rtl:xl:border-r rtl:xl:border-l-0 rtl:first:border-r-0`;
  const alignmentClasses = `items-${textAlignment} text-${textAlignment}`;
  const ContactIcon = iconMap['contact'];
  const PhoneIcon = iconMap['telephone'];
  const EmailIcon = iconMap['mail'];
  const colCount: number = [location?.copy, hours?.copy, details?.copy, hasContact].filter(
    (c) => c
  ).length;
  let itemContentMaxWidth = '';
  switch (colCount) {
    case 3:
      itemContentMaxWidth = 'xl:max-w-[450px]';
      break;
    case 4:
      itemContentMaxWidth = 'xl:max-w-[250px]';
      break;
  }
  const numHash = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
  };

  let contactBackgroundColor =
    isAltContact && !enableHHR ? inlineStyles?.contactBackgroundColor || '#D9D9D9' : null;

  if (enableHHR && inlineStyles?.contactBackgroundColor)
    contactBackgroundColor = inlineStyles?.contactBackgroundColor;

  const animations: AnimationOutput = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });

  let itemInst = 0;
  const InfoItem = (content: ContentProps, title: string): ReactNode => {
    itemInst += 1;
    const iconName =
      title === 'Location' ? 'location' : title === 'Details' ? 'information' : 'clock';
    const Icon = iconMap[iconName];

    return (
      <div
        data-testid={`information-bar-item-${title}`}
        className={cx(colClasses, alignmentClasses, animations[numHash[itemInst]])}
        style={{
          borderColor:
            enableHHR && !inlineStyles?.dividerColor ? '#000' : inlineStyles?.dividerColor,
        }}
      >
        {!hideIcons && (
          <div className={textAlignment !== 'center' ? 'mr-2 h-full' : ''}>
            <Icon className="h-10 fill-primary" fillColor={inlineStyles?.iconColor} />
          </div>
        )}
        <div
          data-testid="information-bar-item"
          className={cx('mt-px flex flex-col', alignmentClasses)}
        >
          <h2
            data-testid={`heading-${title}`}
            className="mb-3 text-2xl font-semibold"
            style={{ color: inlineStyles?.titleColor }}
          >
            {title}
          </h2>
          {content && (
            <div
              className={cx(
                '[&>*]:!m-0',
                itemContentMaxWidth,
                contentStyles?.paragraphStyle,
                contentStyles?.listStyle
              )}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(content?.copy, inlineStyles?.textColor),
              }}
              style={{
                color: inlineStyles?.textColor,
              }}
            />
          )}
          {content?.buttons && (
            <div
              className={cx(
                'flex w-auto flex-col',
                textAlignment ? alignmentClasses : 'items-start'
              )}
            >
              {content?.buttons?.map((link, key) => {
                if (!link) {
                  return null;
                }
                return (
                  <AnchorLink
                    key={key}
                    url={link.link?.url || ''}
                    title={link.link?.title || ''}
                    target={link.link?.target || ''}
                    buttonStyle={link?.buttonStyle || 'primary'}
                    className="mt-2 !text-center"
                    noHorizontalMargin={true}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <section
      className={cx(inlineStyles?.showHide && 'hidden', 'overflow-hidden text-lg', paddingStyles)}
      data-element-id="information-bar-wrapper"
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor,
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div
        data-testid="information-bar"
        ref={animations?.ref}
        className={cx(
          'container flex flex-wrap items-stretch justify-between gap-y-8 border-y-2 border-transparent py-6',
          inlineStyles?.borderBottomColor && 'border-b-2',
          inlineStyles?.borderTopColor && 'border-t-2'
        )}
        style={{
          backgroundColor: inlineStyles?.contentBackgroundColor,
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          borderTopColor: inlineStyles?.borderTopColor,
          borderBottomColor: inlineStyles?.borderBottomColor,
        }}
      >
        {location?.copy && InfoItem(location, 'Location')}
        {hours?.copy && InfoItem(hours, 'Hours')}
        {details?.copy && InfoItem(details, 'Details')}

        {hasContact && (
          <div
            data-testid="information-bar-item-contact"
            className={cx(
              isAltContact && 'items-start',
              colClasses,
              alignmentClasses,
              animations[numHash[colCount]]
            )}
            style={{
              borderColor:
                !isAltContact || (enableHHR && inlineStyles?.dividerColor)
                  ? inlineStyles?.dividerColor
                  : null,
            }}
          >
            <div
              data-testid="information-bar-item"
              data-element-id="information-bar-contact"
              className={isAltContact ? `h-auto w-full px-5 py-4` : ''}
              style={{
                backgroundColor: contactBackgroundColor,
              }}
            >
              <div
                className={
                  isAltContact
                    ? 'flex justify-center border-y-2 pb-4 pt-2'
                    : textAlignment !== 'center'
                    ? 'flex'
                    : ''
                }
                style={{
                  borderColor: inlineStyles?.contactBorderColor || '#000',
                }}
              >
                <div
                  className={cx('flex items-center', {
                    'flex-col': textAlignment === 'center',
                  })}
                >
                  {!hideIcons && !isAltContact && (
                    <div className={textAlignment !== 'center' ? 'mr-2 h-full' : ''}>
                      <ContactIcon
                        className="h-10 fill-primary"
                        fillColor={inlineStyles?.iconColor}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cx(
                    'mt-px flex flex-col',
                    !isAltContact ? alignmentClasses : 'items-center',
                    itemContentMaxWidth
                  )}
                >
                  {!isAltContact && (
                    <h2
                      data-testid="heading-contact"
                      className="mb-3 text-2xl font-semibold"
                      style={{ color: inlineStyles?.titleColor }}
                    >
                      Contact
                    </h2>
                  )}
                  {phoneNumber && (
                    <div className="mb-3 flex sm:mb-0" style={{ color: inlineStyles?.textColor }}>
                      <PhoneIcon className="mr-2 h-7 fill-current" />
                      <a
                        className="hover:underline"
                        href={`tel:${phoneNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {phoneNumber}
                      </a>
                    </div>
                  )}
                  {emailAddress && (
                    <div
                      className="mb-3 flex break-all sm:mb-0"
                      style={{ color: inlineStyles?.textColor }}
                    >
                      <EmailIcon className="mr-2 h-7 fill-current" />
                      <a
                        className="hover:underline"
                        href={`mailto:${emailAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {emailAddress}
                      </a>
                    </div>
                  )}
                  {bookCta?.link?.url && (
                    <AnchorLink
                      url={bookCta?.link?.url}
                      title={bookCta?.link?.title || 'Book Online'}
                      target={bookCta?.link?.target || '_self'}
                      buttonStyle={bookCta?.buttonStyle || 'primary'}
                      className="mt-2 !text-center"
                      noHorizontalMargin={true}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
