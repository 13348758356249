import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings } from './common-vars';

export const HeroImage = gql`
fragment HeroImage on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_Masthead {
        fieldGroupName
        subtitle
        subtitleSize
        title
        titleSize
        image {
          altText
          sourceUrl
        }
        imagePositioning
        imageCarouselChoice
        imageCarousel {
          altText
          sourceUrl
        }
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        logoImage {
          altText
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        logoOverlayColour
        logoOverlayOpacity
        logoWidth
        logoHeight
        videoChoice
        videoUrl {
          mediaItemUrl
        }
        videoCtaPlay
        videoCtaPause
        videoMute
        videoQualityOverride
        videoQualitySlider
        bookingWidgetChoice
        bookingWidgetPosition
        bookingWidgetMobile
        mobileCroppingAutoPosition
        mobileCroppingCropHeight
        mobileCroppingCropWidth
        mobileCroppingXPosition
        mobileCroppingXPositionAdvanced
        mobileCroppingYPosition
        mobileCroppingYPositionAdvanced
        captionTracks {
          label
          language
          type
          url
        }
        transcriptTracks {
          label
          language
          type
          url
        }
        audioTracks {
          videoGroup
          videoLabel
          videoName
          videoUrl
          captionTracks {
            label
            language
            type
            url
          }
          transcriptTracks {
            label
            language
            type
            url
          }
        }
        ${clonedCropSettings}
        mastheadComponentSettings {
          fieldGroupName
          mastheadHeight
          overlayBackgroundColour
          overlayMobileBackgroundColour
          mobileTitleColour
          mobileSubtitleColour
          overlayBackgroundOpacity
          overlayBlur
          overlayFullWidth
          overlayXPosition
          overlayYPosition
          mastheadGradient
          overlayMobilePosition
          titleColor
          subtitleColor
          videoCtaBackgroundColor
          videoCtaBorderColor
          videoCtaMuteColor
          videoCtaPlaypauseColor
          videoCtaUnmuteColor
          videoMastheadCtaPosition
          videoCtaShadow
          contentBackgroundPosition
          logoXPosition
          logoYPosition
          heroCarouselArrowColour
          heroCarouselBackgroundColour
          heroCarouselTextColour
          heroCarouselCounterColour
          heroCarouselDividerColour
          heroCarouselRoundedCorners
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          mastheadSpacer
          mastheadSpacerColour
        }
      }
}
`;
