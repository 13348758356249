export function SmallChevron({ className }: { className?: string }) {
  return (
    // paste conrad-abu-dhabi hotel logo SVG
    <svg className={className}>
      <path
        d="M8.05469 8.17285C8.33594 7.8916 8.33594 7.42285 8.05469 7.1416L1.99219 1.04785C1.67969 0.766602 1.21094 0.766602 0.929688 1.04785L0.210938 1.7666C-0.0703125 2.04785 -0.0703125 2.5166 0.210938 2.8291L5.02344 7.6416L0.210938 12.4854C-0.0703125 12.7979 -0.0703125 13.2666 0.210938 13.5479L0.929688 14.2666C1.21094 14.5479 1.67969 14.5479 1.99219 14.2666L8.05469 8.17285Z"
        fill="#BDBDBD"
      />
    </svg>
  );
}
