import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./sfecghh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-30 h-20 fill-current text-bg antialiased md:mr-5 md:w-32" />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-20 w-40 fill-current text-bg antialiased md:w-32" />
);
