import { useState, useEffect } from 'react';
import type * as Icons from '@curated-property/icons';
import cx from 'classnames';
import { sanitize, appliedCloudinaryParams, makeRandomInt } from '@curated-property/utils';
import { Checkmark, BurgerMenu, CloseIcon } from '@curated-property/icons';
import sum from 'lodash/sum';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder, GIS_TextAlignment } from './functions/global-instance-styles';
import { HandleWYSIWYGContent } from './functions/helper';

type MappingType = {
  Checkmark: typeof Icons.Checkmark;
};

function filterMobileText(textStr?: string | JSX.Element) {
  if (typeof textStr !== 'string') return;
  let txt = textStr;
  if (txt !== null && txt.length) {
    // Remove whitespace from beginning and end of string edges.
    txt.trim();
    txt = txt.replace('·', '');
  }
  return txt;
}

export type IconKeyType = keyof MappingType;

export interface ComparisonTableProps {
  title?: string;
  introduction?: string;
  repeater?: {
    tableHeading?: {
      backgroundColour?: string;
      textColour?: string;
      text?: string;
      headerUrl?: {
        target?: string;
        title?: string;
        url?: string;
      };
    }[];
    sectionRows?: {
      rowTitle?: string;
      subrows?: {
        column?: {
          item?: string;
        }[];
      }[];
    }[];
  }[];
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

type SubRow = { title?: string; textColour?: string; colBg?: string };
type RowSectionGroups = Array<SubGroupArr | string>;
type OrderedArray<T> = Array<T> & {
  0?: SubRow;
  1?: RowSectionGroups[];
  2?: string;
};

export function ComparisonTable({
  title,
  introduction,
  repeater,
  instanceStyles,
  globalStyles,
}: ComparisonTableProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  // Array color key - set colors for individual columns
  const colourKeys: string[] = [];
  const textColourKeys: string[] = [];
  const mobileSections: OrderedArray<SubRow | RowSectionGroups[] | string>[] = [];
  const columnHeadings: string[] = [];
  let sectionTitlesPresent = false;

  const [activeMobilePanel, setActiveMobilePanel] = useState('');
  const [useHeight, setUseHeight] = useState(0);

  useEffect(() => {
    if (activeMobilePanel !== '') {
      const sectionChildren = document?.getElementById(activeMobilePanel)?.children;
      const heightArray = [];
      for (let i = 0; i < (sectionChildren?.length || 0); i++) {
        heightArray.push(sectionChildren?.[i]?.clientHeight || 0);
      }
      setUseHeight(activeMobilePanel === '' ? 0 : sum(heightArray));
    }
  }, [activeMobilePanel]);

  repeater?.[0]?.tableHeading?.forEach((i) => {
    columnHeadings.push(i?.text || '');
  });

  const subgroupSectionTitles: string[] = [];

  repeater?.forEach((item) => {
    item?.tableHeading?.forEach((col, columnIndex) => {
      colourKeys.push(col?.backgroundColour ?? '');
      textColourKeys.push(col?.textColour ?? '');

      // For mobile
      const rowSectionGroups: RowSectionGroups[] = [];
      const headerCol: SubRow = {};

      if (col?.text) {
        headerCol.title = col?.text;
        headerCol.textColour = col?.textColour || inlineStyles?.subtitleColor;
        headerCol.colBg = col?.backgroundColour;
      }

      let groupingType = 'standard';

      item?.sectionRows?.forEach((row) => {
        const subRow: RowSectionGroups = [];

        // Find any value at all in subrow for given column (there can be more than 1 sub-row per sub-section)
        const hasSubRowValue = row?.subrows?.find(
          (itemRow) => itemRow?.column?.[columnIndex]?.item
        );
        let rowLabel = '';
        if (row?.rowTitle && hasSubRowValue) {
          rowLabel = row?.rowTitle;
        }

        // Set individual mobile feature list items
        row?.subrows?.forEach((subrowIndividual) => {
          let rowString = '';
          const rowArray: SubGroupArr = [];
          // Standard presentation -- row title + individual table cells with either checkmarks (the [y] code) or text information, no sub-sections used
          if (columnHeadings[0]?.length) {
            if (
              rowLabel &&
              subrowIndividual?.column?.[columnIndex]?.item &&
              subrowIndividual?.column?.[columnIndex]?.item !== '·'
            ) {
              rowString = `${rowLabel}${
                subrowIndividual?.column?.[columnIndex]?.item === '[y]'
                  ? ''
                  : ` - ${subrowIndividual?.column?.[columnIndex]?.item}`
              }`;
            }
          } else {
            // Sub-section presentation -- sections of tables are further subdivided.
            if (!columnHeadings[0]) {
              groupingType = 'subgroups';
              subgroupSectionTitles.push(rowLabel);
              const rowItem = {
                mainPanel: col?.text,
                sectionLabel: rowLabel,
                subsectionLabel: subrowIndividual?.column?.[0]?.item,
                content: subrowIndividual?.column?.[columnIndex]?.item,
              };

              rowArray.push(rowItem);
            }
          }
          // If this table has feature sections, with sub-sections, and column headers
          !rowString ? subRow.push(rowArray) : subRow.push(rowString);
        });

        rowSectionGroups.push(subRow);
      });
      if (groupingType === 'standard') {
        mobileSections.push([headerCol, rowSectionGroups, groupingType]);
      }

      if (groupingType === 'subgroups') {
        mobileSections.push([headerCol, rowSectionGroups, groupingType]);
      }
    });

    // Set display based on whether titles are present for non-mobile table display
    item?.sectionRows?.forEach((row) => {
      if (row?.rowTitle) {
        sectionTitlesPresent = true;
        return false;
      }
      return true;
    });
  });

  const subSectionTitles = new Set(subgroupSectionTitles);

  return (
    <div className={inlineStyles?.showHide ? 'hidden' : ''}>
      <div
        className={paddingStyles}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || undefined,
          backgroundPosition: inlineStyles?.componentBackgroundPosition || undefined,
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        }}
        data-element-id="comparison-table-wrapper"
      >
        <div className="container">
          {title && (
            <h2
              className="text-inverse font-headline mb-6 text-2xl font-black leading-none md:text-4xl"
              dangerouslySetInnerHTML={{ __html: sanitize(title) }}
              style={{
                textAlign: GIS_TextAlignment(inlineStyles?.textAlignment),
                color: inlineStyles?.titleColor,
              }}
            />
          )}
          {introduction && (
            <div
              className="mx-auto text-lg"
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(introduction),
              }}
            />
          )}
        </div>
        <div data-testid="mobilePanels" className="my-2 block lg:hidden">
          {mobileSections?.map((section, e) => {
            if (section?.[0]?.title) {
              const panelId = `${section?.[0]?.title}${e.toString()}`;
              const panelIdNoSpace = panelId.replace(/\s/g, '');

              const sectionRandom = makeRandomInt().toString();

              return (
                <div key={`mobile${section?.[0]?.title}`}>
                  <h3 className="w-full">
                    <button
                      type="button"
                      className="box-sizing flex w-full flex-row flex-nowrap items-center justify-between border-y border-gray-100 p-4"
                      aria-controls={panelIdNoSpace}
                      aria-expanded={activeMobilePanel === panelIdNoSpace ? 'true' : 'false'}
                      style={{
                        background: section?.[0].colBg,
                        color: section?.[0].textColour,
                      }}
                      onClick={() => {
                        const thisItemID = panelIdNoSpace;
                        activeMobilePanel !== thisItemID
                          ? setActiveMobilePanel(thisItemID)
                          : setActiveMobilePanel('');
                      }}
                    >
                      <span className="py-2 text-xl">{section?.[0].title}</span>
                      {panelIdNoSpace === activeMobilePanel ? (
                        <CloseIcon
                          fillColor={section?.[0].textColour}
                          key={`close${e.toString()}`}
                        />
                      ) : (
                        <BurgerMenu
                          fillColor={section?.[0].textColour}
                          key={`menu${e.toString()}`}
                        />
                      )}
                    </button>
                  </h3>
                  <div
                    key={`mobilePanel${e.toString()}`}
                    className={cx(
                      'overflow-hidden text-lg transition-all duration-200 ease-in-out'
                    )}
                    style={{
                      height:
                        activeMobilePanel === panelIdNoSpace
                          ? `${(useHeight + 50).toString()}px`
                          : 0,
                    }}
                    id={panelIdNoSpace}
                    aria-labelledby={panelIdNoSpace}
                    role="region"
                    aria-hidden={panelIdNoSpace === activeMobilePanel ? 'false' : 'true'}
                  >
                    {/* Mobile panel contents */}
                    {section[2] === 'standard' && (
                      <ul
                        data-element-id="mobile-information-row"
                        className="px-4 py-2"
                        key={`${panelIdNoSpace}${sectionRandom}`}
                      >
                        {/* Basic row with data presentation */}
                        {section?.[1]?.map((rowInfo, rowIndex: number) => {
                          let rowTextContent = '';
                          rowInfo?.forEach((el) => {
                            if (el !== undefined && el.length > 1 && typeof el === 'string') {
                              rowTextContent = el;
                            }
                          });

                          return rowTextContent && !Array.isArray(rowInfo.values) ? (
                            <li
                              data-element-id="standard-li-row"
                              className="text-md my-4 ml-6 list-disc pl-2 leading-none"
                              key={`mobileLi${sectionRandom}${e.toString()}${rowIndex.toString()}`}
                              dangerouslySetInnerHTML={{
                                __html: sanitize(rowTextContent || ''),
                              }}
                            />
                          ) : (
                            ''
                          );
                        })}
                        {/* Subdivided section presentation */}
                      </ul>
                    )}

                    {section[2] === 'subgroups' && (
                      <ul
                        data-element-id="mobile-information-groups"
                        className="px-4 py-2"
                        key={`ul${e}${sectionRandom}subgroup`}
                      >
                        {[...subSectionTitles]?.map((label) => {
                          return (
                            <li
                              key={`li${sectionRandom}${label}subgroup`}
                              className="mb-4 w-full py-2"
                              data-element-id="subrow-label"
                            >
                              <span
                                className="font-headline block border-b border-gray-100 font-bold "
                                dangerouslySetInnerHTML={{
                                  __html: sanitize(label),
                                }}
                              />

                              {section[1]?.map((subgroup: Array<SubGroup[] | string>) => {
                                return (
                                  <SubSection
                                    key={label}
                                    subgroup={subgroup}
                                    mainPanel={section[0]?.title}
                                    subSection={label}
                                  />
                                );
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        {/* Table display for larger screens, laptop/desktop */}
        <div
          className="container mt-4 hidden overflow-x-auto md:mt-8 lg:block"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )}')`
              : undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || undefined,
            backgroundPosition: inlineStyles?.contentBackgroundPosition || undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
          }}
        >
          <table
            data-testid="comparison-table-table"
            className={cx(
              'comparison-table mx-auto table-fixed',
              sectionTitlesPresent && 'multi-section'
            )}
            role="table"
          >
            {repeater?.map((item, index) => {
              const headingRow = item?.tableHeading;
              const sections = item?.sectionRows;
              return (
                <>
                  <thead data-testid="table-thead" key={`thead${index.toString()}`}>
                    <tr data-testid="table-thead-tr" key={`theadRow${index.toString()}`}>
                      {sectionTitlesPresent && (
                        <th className="border-b py-4" key={`blank${index.toString()}`} />
                      )}
                      {headingRow?.map((thCol, thIndex) => {
                        // Change to white if there is a color background
                        const useColour = colourKeys[thIndex];
                        const useTextColour = textColourKeys[thIndex];
                        return (
                          <ThItem
                            key={`th${thIndex.toString()}`}
                            displayContent={thCol?.text}
                            colourOption={useColour}
                            specificSubtitleColor={useTextColour}
                            generalSubtitleColor={inlineStyles?.subtitleColor}
                          />
                        );
                      })}
                    </tr>
                  </thead>
                  {sections?.map((section, secIndex) => {
                    const rowSection = section?.subrows;
                    const rowTitle = section?.rowTitle;
                    return (
                      <tbody key={`tbody${secIndex.toString()}`}>
                        {rowSection?.map((tr, trIndex) => {
                          const cols = tr?.column;
                          const rowCount = rowSection.length;
                          if (rowTitle) {
                            return (
                              <tr key={`tBodyTr${trIndex.toString() + secIndex.toString()}`}>
                                {trIndex === 0 && (
                                  <td
                                    key={`sectionTitle${trIndex.toString()}${secIndex.toString()}`}
                                    className="section-title-row-td border-b
                                                px-8 align-text-top text-sm font-medium md:text-lg"
                                    rowSpan={rowCount}
                                    dangerouslySetInnerHTML={{
                                      __html: sanitize(rowTitle),
                                    }}
                                  />
                                )}
                                {cols?.map((tdItem, tdIndex) => {
                                  // Check to see if a different background is used and apply to the colour prop
                                  const useColour = colourKeys[tdIndex];
                                  const useTextColour = textColourKeys[tdIndex];
                                  return (
                                    <TdItem
                                      key={`td2${tdIndex.toString()}${trIndex.toString()}`}
                                      displayContent={tdItem?.item}
                                      displayInt={tdIndex}
                                      colourOption={useColour}
                                      generalTextColourOption={inlineStyles?.textColor}
                                      specificTextColourOption={useTextColour}
                                    />
                                  );
                                })}
                              </tr>
                            );
                          }
                          return (
                            <tr key={`tr2${trIndex.toString()}`}>
                              {cols?.map((tdItem, tdIndex) => {
                                // Check to see if a different background is used and apply to the colour prop
                                const useColour = colourKeys[tdIndex] || '';
                                const useTextColour = textColourKeys[tdIndex] || '';
                                return (
                                  <TdItem
                                    key={`td3${tdIndex.toString()}`}
                                    displayContent={tdItem?.item}
                                    displayInt={tdIndex}
                                    colourOption={useColour}
                                    generalTextColourOption={inlineStyles?.textColor}
                                    specificTextColourOption={useTextColour}
                                  />
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    );
                  })}
                </>
              );
            })}
          </table>
        </div>
        {/* /. overflow-x-auto grid for small screens */}
      </div>
    </div>
  );
}

interface ThProps {
  displayContent?: string;
  colourOption?: string;
  generalSubtitleColor?: string;
  specificSubtitleColor?: string;
}

function ThItem({
  displayContent,
  colourOption,
  generalSubtitleColor,
  specificSubtitleColor,
}: ThProps) {
  // Change to white if there is a color background
  const changeToWhite = specificSubtitleColor
    ? specificSubtitleColor
    : colourOption
    ? '#fff'
    : generalSubtitleColor
    ? generalSubtitleColor
    : 'inherit';

  const backgroundChange = colourOption ?? 'inherit';

  return (
    <th
      style={{ background: backgroundChange, color: changeToWhite }}
      className="border-b p-4 font-bold"
    >
      {displayContent}
    </th>
  );
}

interface TdProps {
  displayContent?: string;
  displayInt?: number;
  colourOption?: string;
  generalTextColourOption?: string;
  specificTextColourOption?: string;
}

function TdItem({
  displayContent,
  displayInt,
  colourOption,
  generalTextColourOption,
  specificTextColourOption,
}: TdProps) {
  // Change to white if there is a color background
  const changeToWhite = specificTextColourOption
    ? specificTextColourOption
    : colourOption
    ? '#fff'
    : generalTextColourOption
    ? generalTextColourOption
    : 'inherit';

  const backgroundChange = colourOption ?? 'inherit';

  const displayItem =
    displayContent === '[y]' ? (
      <Checkmark fill={changeToWhite} className="m-auto flex size-4 fill-current" aria={true} />
    ) : (
      filterMobileText(displayContent)
    );

  return displayContent === '[y]' ? (
    <td
      className={cx(
        'border-b p-4  text-center  text-xs lg:text-sm',
        displayInt === 0 ? 'align-text-top ' : ' p-4 align-text-top md:align-middle'
      )}
      style={{ background: backgroundChange, color: changeToWhite }}
    >
      {displayItem}
    </td>
  ) : (
    <td
      className={cx(
        'border-b p-4  text-center  text-xs lg:text-sm',
        displayInt === 0 ? 'align-text-top ' : ' p-4 align-text-top md:align-middle'
      )}
      style={{ background: backgroundChange, color: changeToWhite }}
      dangerouslySetInnerHTML={{
        __html: sanitize(filterMobileText(displayItem) || ''),
      }}
    />
  );
}
type SubGroup = {
  content?: string;
  mainPanel?: string;
  sectionLabel?: string;
  subsectionLabel?: string;
};
type SubGroupArr = Array<SubGroup>;
interface SubSectionProps {
  subgroup?: (SubGroupArr | string)[];
  mainPanel?: string;
  subSection?: string;
}

function SubSection({ subgroup, mainPanel, subSection }: SubSectionProps) {
  const randomStr = makeRandomInt().toString();

  const obj = (
    <ul key={`ul${randomStr}`} data-element-id="individuale-subrow" data-testid="mobile-subsection">
      {subgroup?.map((s) => {
        if (typeof s === 'string') {
          return (
            <li
              key={`li${randomStr}${s}`}
              data-element-id="subsection-li"
              className="text-md my-4 ml-6 list-disc pl-2 leading-none"
              dangerouslySetInnerHTML={{
                __html: sanitize(s || ''),
              }}
            />
          );
        }

        const item = s?.[0];

        // Set text to return: [y] means provide the main row value, a specific value means provide both the row label + the value
        let rowText = '';

        const itemContent = item?.content ? item?.content : '';

        itemContent?.replace('·', '');
        itemContent.trim();
        itemContent.replace(/\s/g, '');

        if (itemContent === '[y]') {
          rowText = item?.subsectionLabel ?? '';
        }
        if (itemContent !== '[y]') {
          if (itemContent.length > 1 && itemContent.indexOf('·') === -1) {
            rowText = `${item?.subsectionLabel} - ${item?.content}`;
          }
          if (itemContent && itemContent.indexOf('·') !== -1) {
            rowText = '';
          }
        }
        if (!itemContent) {
          rowText = '';
        }

        return item?.mainPanel === mainPanel && item?.sectionLabel === subSection && rowText ? (
          <li
            key={`li${randomStr}${rowText}`}
            data-element-id="subsection-li"
            className="text-md my-4 ml-6 list-disc pl-2 leading-none"
            dangerouslySetInnerHTML={{
              __html: sanitize(rowText || ''),
            }}
          />
        ) : (
          ''
        );
      })}
    </ul>
  );

  return obj;
}
