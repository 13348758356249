import { useRef, useEffect, useState, useContext, createContext } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { CloseIcon } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { elementExceedsWindowHeight, hexToRgb } from '../functions/helper';
import { SharedContext, isReducedMotion } from '@curated-property/utils';

export const DialogTitle = Dialog.Title;

export const ModalContext = createContext<{ closeDialog: () => void } | undefined>(undefined);

export interface GenericModalProps {
  isBookingWidget?: boolean;
  setModalActive: undefined | ((setActive: boolean) => void);
  modalActive: boolean;
  modalChild?: React.ReactNode;
  modalChildWrapperStyles?: string;
  modalChildStyles?: string;
  modalStyles?: string;
  modalSettings?: {
    modalBackgroundOpacity?: number;
    modalBackgroundColour?: string;
    modalCloseButtonBackgroundColour?: string;
    modalCloseButtonIconColour?: string;
    contentBackgroundColour?: string;
    contentTextColor?: string;
    hideBoxShadow?: boolean;
  };
  animationSpeed?: number;
  ignoreReducedMotion?: boolean;
  dialogTrigger?: React.ReactNode;
}

export function GenericModal({
  isBookingWidget,
  setModalActive,
  modalActive,
  modalChild,
  modalChildWrapperStyles,
  modalChildStyles,
  modalStyles,
  modalSettings,
  animationSpeed,
  ignoreReducedMotion = false,
  dialogTrigger,
}: GenericModalProps) {
  const [elementBiggerThanWindow, setElementBiggerThanWindow] = useState(false);
  const modalInner = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();
  const reducedMotion = !ignoreReducedMotion ? isReducedMotion : false;
  const transitionSpeed = !reducedMotion ? animationSpeed || 400 : 0;
  const sContext = useContext(SharedContext);
  modalSettings = modalSettings ? modalSettings : sContext?.modalStyles;
  // Secondary condition for the HHR modal styling
  modalSettings =
    sContext?.enableHHR && sContext?.modalStyles ? sContext?.modalStyles : modalSettings;
  // convert hex result to rgb(a)
  const rgbArray = modalSettings?.modalBackgroundColour
    ? hexToRgb(modalSettings?.modalBackgroundColour)
    : '';
  const modalColorRgba =
    rgbArray !== ''
      ? `rgba(${rgbArray?.r},${rgbArray?.g},${rgbArray?.b}, ${
          modalSettings?.modalBackgroundOpacity || '.98'
        })`
      : '';

  useEffect(() => {
    // <Dialog> uses a focus-lock package that stops animations when the component mounts
    // Setting a timeout of 0 when the mount variable is set seems to bypass that quirk
    if (modalActive)
      setTimeout(() => {
        setMounted(true);
      }, 0);
  }, [modalActive]);

  // split these out into a custom function when we have CMS animation options
  const baseTransitionClasses = 'transform-gpu transition-all ease-in-out duration-300';
  const transitionType = mounted ? 'opacity-1 translate-y-0' : 'opacity-20 -translate-y-full';

  function closeDialog() {
    setModalActive?.(false);
    setMounted(false);
  }

  useEffect(() => {
    function handleResize() {
      setElementBiggerThanWindow(
        elementExceedsWindowHeight(modalInner?.current?.clientHeight || 0, 24)
      );
    }
    const resizeObserver = new ResizeObserver((_entries) => {
      handleResize();
    });

    // check to see if modal is bigger than window size
    setTimeout(() => {
      if (modalInner.current instanceof Object) {
        resizeObserver.observe(modalInner.current);
      }
      handleResize();
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [modalActive, modalInner, setElementBiggerThanWindow]);

  return (
    <Dialog.Root open={modalActive}>
      {dialogTrigger ? <Dialog.Trigger asChild>{dialogTrigger}</Dialog.Trigger> : null}
      <Dialog.Portal>
        <Dialog.Overlay
          data-testid="modal-overlay"
          className={cx(
            'cp-generic-modal dialog-overlay overflow-hidden bg-transparent',
            !reducedMotion ? baseTransitionClasses : '',
            !reducedMotion ? transitionType : ''
          )}
          style={{
            transitionDuration: `${transitionSpeed}ms`,
          }}
        >
          <div
            className={cx('absolute z-40 size-full')}
            style={{
              backgroundColor:
                modalColorRgba ||
                `rgba(var(--color-primary-alt), ${modalSettings?.modalBackgroundOpacity || '.95'})`,
            }}
          />
          <Dialog.Content
            /* This is disabled on purpose because we don't use radix-ui/dialog description. See https://www.radix-ui.com/primitives/docs/components/dialog#description */
            aria-describedby={undefined}
            aria-modal="true"
            onInteractOutside={closeDialog}
            onEscapeKeyDown={closeDialog}
            data-testid="generic-modal-wrapper"
            className={cx(
              'absolute z-50 flex size-full flex-col overflow-auto px-4 lg:px-16',
              modalStyles,
              elementBiggerThanWindow ? 'justify-start' : 'justify-center'
            )}
          >
            <Dialog.Close asChild>
              <button
                data-element-id="close-generic-modal-button"
                data-testid="closeButton"
                className={cx(
                  'bg-bg-alt z-60 !ease-bounce absolute right-5 top-2 flex size-8 transform-gpu items-center justify-center rounded-full transition-all !duration-500 sm:size-12 md:top-5',
                  mounted ? 'opacity-1 scale-100' : 'scale-0 opacity-0'
                )}
                onClick={closeDialog}
                style={{
                  backgroundColor: modalSettings?.modalCloseButtonBackgroundColour,
                }}
              >
                <span className="sr-only">{t('closeModal')}</span>
                <CloseIcon
                  className="text-bg-inverse size-2 fill-current sm:size-4"
                  fillColor={modalSettings?.modalCloseButtonIconColour}
                />
              </button>
            </Dialog.Close>
            <div
              data-testid="generic-modal"
              data-reach-dialog-content
              className={cx(
                'dialog-content-v2 bg-bg max-w-screen-2xl rounded-none p-0',
                modalChildWrapperStyles ? modalChildWrapperStyles : 'w-fit',
                modalSettings?.hideBoxShadow ? 'shadow-none' : ''
              )}
              data-modal-id={isBookingWidget ? 'booking-widget' : 'generic-modal'}
            >
              <div
                ref={modalInner}
                data-testid="child"
                className={modalChildStyles}
                style={{
                  backgroundColor: modalSettings?.contentBackgroundColour,
                  color: modalSettings?.contentTextColor,
                }}
              >
                <ModalContext.Provider value={{ closeDialog }}>{modalChild}</ModalContext.Provider>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
