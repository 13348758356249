import { useMemo } from 'react';
import Cookies from 'universal-cookie';
import { useIsClient } from 'usehooks-ts';
import { env } from '@dx-ui/framework-env';

const SEGMENTATION_COOKIE_NAME = 'TMS';
const SEGMENTATION_COOKIE_NAME_DEV = 'TMS_DEV';
const cookies = new Cookies();

const getSegmentCookie = (cookies: Cookies) => {
  let cookie = '';
  if (env('APP_ENV') !== 'prd') {
    cookie = cookies?.get(SEGMENTATION_COOKIE_NAME_DEV);
  }
  if (!cookie) {
    cookie = cookies?.get(SEGMENTATION_COOKIE_NAME);
    cookies.set(SEGMENTATION_COOKIE_NAME, cookie, {
      domain: '.hilton.com',
      path: '/',
      sameSite: 'lax',
      secure: true,
      encode: (value) => value,
    });
  }
  return cookie || '';
};

export const useSegments = () => {
  const isClient = useIsClient();
  return useMemo(() => {
    const tms: string = isClient ? getSegmentCookie(cookies) : '';
    const segments = tms
      ?.split(',')
      .filter((entry) => (entry.toLowerCase() || '').startsWith('web'))
      .map((entry) => entry.split('=')[1] || '');
    return segments;
  }, [isClient]);
};
