import { GenericModal, DialogTitle } from '../global/generic-modal';
import Markdown from 'markdown-to-jsx';
import type * as React from 'react';
import type { IalertsData } from './alert';

export function AlertModal({
  alert,
  onClose,
  languageDirection,
  modalSettings,
  modalActive,
}: {
  alert: IalertsData;
  onClose: () => void;
  languageDirection: string;
  modalSettings?: React.ComponentProps<typeof GenericModal>['modalSettings'];
  modalActive: boolean;
}) {
  return (
    <GenericModal
      setModalActive={() => onClose()}
      modalActive={modalActive}
      modalChildStyles="bg-white"
      modalStyles="justify-center pt-12"
      modalChild={
        <div className="pb-8" dir={languageDirection}>
          <header className="mb-4 border-b px-8 pb-2 pt-4 text-center text-lg font-bold">
            <DialogTitle>{alert?.headline}</DialogTitle>
          </header>
          <div className="px-8">
            <Markdown
              options={{
                overrides: {
                  span: {
                    props: {
                      style: {
                        color: modalSettings?.contentTextColor,
                      },
                    },
                  },
                },
              }}
            >
              {alert?.description}
            </Markdown>
          </div>

          {alert?.link && (
            <div className="pt-8 text-center">
              <a
                href={alert?.link?.url}
                target={alert?.link?.isNewWindow ? '_blank' : '_self'}
                className="btn btn-primary-outline"
                rel="noreferrer"
              >
                {alert?.link?.label}
              </a>
            </div>
          )}
        </div>
      }
      modalSettings={modalSettings}
    />
  );
}

export default AlertModal;
