export function ArrowHead({
  className,
  fillColor,
  strokeWidth,
  color,
  noPadding,
}: {
  className?: string;
  fillColor?: string;
  strokeWidth?: number;
  color?: string;
  noPadding?: boolean;
}) {
  return (
    <svg
      className={className}
      viewBox={noPadding ? '4 7 18 9' : '0 0 24 24'}
      style={{ fill: fillColor, color }}
    >
      <g data-name="Icons Outlined">
        <path
          stroke="currentColor"
          strokeWidth={strokeWidth}
          d="M18.2656,8.7344a.376.376,0,0,0-.5312,0L12,14.47,6.2656,8.7344a.3756.3756,0,0,0-.5312.5312l6,6a.3771.3771,0,0,0,.5312,0l6-6A.3778.3778,0,0,0,18.2656,8.7344Z"
        />
      </g>
    </svg>
  );
}
