import gql from 'graphql-tag-transform.macro';

export const WeddingsAvailabilityCalendar = gql`
  fragment WeddingsAvailabilityCalendar on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar {
      componentSettings {
        backgroundPrimary
        backgroundSecondary
        backgroundTernary
        cellFreeGroup {
          backgroundColor
          cornerFlagColor
          textColor
        }
        cellFullGroup {
          backgroundColor
          cornerFlagColor
          textColor
        }
        cellPartialGroup {
          backgroundColor
          cornerFlagColor
          textColor
        }
        cellSelectedGroup {
          backgroundColor
          cornerFlagColor
          textColor
        }
        headerCopyAlign
        headerCopyColor
        headerTitleAlign
        headerTitleColor
        mainTextColor
        paddingBottom {
          paddingBottomDesktop
          paddingBottomMobile
          paddingBottomTablet
        }
        paddingTop {
          paddingTopDesktop
          paddingTopMobile
          paddingTopTablet
        }
        progressActive
        progressInactive
        radioButtonChecked
        radioButtonDisabled
        radioButtonUnchecked
        subheadingAlign
        subheadingColor
        cellDisabledGroup {
          backgroundColor
          textColor
          cornerFlagColor
        }
        emailAddress
        emailCopy
        emailTitle
        activeBackgroundColor
        activeTextColor
        inactiveBackgroundColor
        inactiveTextColor
      }
      introCopy
      title
    }
  }
`;
