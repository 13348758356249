import type { FooterProps, MenuProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

export const AdditionalHead = () => {
  return <link rel="stylesheet" href="https://use.typekit.net/tcv5fca.css" />;
};

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Header)
);

export const Header = (props: MenuProps) => {
  return (
    <SharedHeader
      {...props}
      groupBooking={{
        url: 'https://thenomadhotellondon.tripleseat.com/booking_request/25292',
      }}
      maxRooms={7}
    />
  );
};

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return <SharedFooter {...props} brandPhoneNumber="+1-877-666-2312" />;
};
