import { useContext } from 'react';
import type { TagOffersCurated } from '../offers-curated';
import { OffersCuratedContext } from '../offers-curated';
import cx from 'classnames';

export interface TagButtonOffersCuratedStyleProps {
  backgroundColor?: string;
  textColor?: string;
  disabledBackgroundColor?: string;
  disabledTextColor?: string;
  hhrTagBackground?: string;
  hhrTagText?: string;
  hhrTagDisabledBackground?: string;
  hhrTagDisabledText?: string;
}

export const defaultStyles: TagButtonOffersCuratedStyleProps = {
  backgroundColor: '#666666',
  textColor: '#121212',
  disabledBackgroundColor: '#000',
  disabledTextColor: '#FFF',
  hhrTagBackground: '#000000',
  hhrTagText: '#FFFFFF',
  hhrTagDisabledBackground: '#FFF',
  hhrTagDisabledText: '#000000',
};

export interface TagButtonOffersCuratedProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tag: TagOffersCurated;
  label?: string;
}

export function TagButtonOffersCurated({
  tag,
  children,
  label,
  onClick,
  disabled = false,
}: TagButtonOffersCuratedProps) {
  const contextData = useContext(OffersCuratedContext);

  const styles = contextData?.styles?.tagButtons;
  const El = onClick ? `button` : `span`;

  const textColor = contextData?.enableHHR ? styles?.hhrTagText : styles?.textColor;
  const disabledTextColor = contextData?.enableHHR
    ? styles.hhrTagDisabledText
    : styles?.disabledTextColor;
  const backgroundColor = contextData?.enableHHR
    ? styles?.hhrTagBackground
    : styles?.backgroundColor;
  const disabledBackgroundColor = contextData?.enableHHR
    ? styles?.hhrTagDisabledBackground
    : styles?.disabledBackgroundColor;

  return (
    <El
      onClick={onClick}
      data-element-id="curated-offers-pill"
      className={cx(
        'bg-primary whitespace-nowrap rounded-full px-4 py-2',
        disabled ? 'pill-tag-disabled' : ''
      )}
      style={{
        backgroundColor: !disabled ? backgroundColor : disabledBackgroundColor,
        color: !disabled ? textColor : disabledTextColor,
      }}
      aria-label={label || tag?.label}
    >
      <span>{children || tag?.label}</span>
    </El>
  );
}
