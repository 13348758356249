export function Gallery({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,9.625A3.375,3.375,0,1,0,15.375,13,3.3784,3.3784,0,0,0,12,9.625Zm0,6A2.625,2.625,0,1,1,14.625,13,2.6281,2.6281,0,0,1,12,15.625Z" />
          <path d="M18,9.625H17a.375.375,0,0,0,0,.75h1a.375.375,0,0,0,0-.75Z" />
          <path d="M18,7.625H17a2.1837,2.1837,0,0,1-1.7412-.9268A2.6323,2.6323,0,0,0,13.2236,5.625H11A2.9443,2.9443,0,0,0,8.7373,6.7383,2.2293,2.2293,0,0,1,7.02,7.625.1268.1268,0,0,0,7,7.625H6A2.3777,2.3777,0,0,0,3.625,10v6A2.3777,2.3777,0,0,0,6,18.375H18A2.3777,2.3777,0,0,0,20.375,16V10A2.3777,2.3777,0,0,0,18,7.625ZM19.625,16A1.6262,1.6262,0,0,1,18,17.625H6A1.6262,1.6262,0,0,1,4.375,16V10A1.6262,1.6262,0,0,1,6,8.375H7l.01-.002.0108.002A2.9346,2.9346,0,0,0,9.2686,7.2676,2.2374,2.2374,0,0,1,11,6.375h2.2236a1.9194,1.9194,0,0,1,1.4893.8379A2.8887,2.8887,0,0,0,17,8.375h1A1.6262,1.6262,0,0,1,19.625,10Z" />
        </g>
      </g>
    </svg>
  );
}
