const ForbesFourStarLogo = ({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 235.79 221">
      <defs>
        <style>{`
      .cls-2 {
        font-family: Roboto-Black, Roboto;
        font-size: 18px;
        font-weight: 800;
      }
      `}</style>
      </defs>
      <g fill={fillColor}>
        <path d="M233.34,157.53c-3.02-1.38-7.51-2.21-12.24-2.21-10.18,0-16.36,5.97-16.36,12.94s4.65,10,11.07,12.28c6.91,2.47,8.73,4.4,8.73,7.59s-2.41,6.36-6.74,6.36c-5.11,0-8.96-3.01-11.93-11.3l-2.08,.54,.36,11.85c3.38,1.37,9.13,2.52,14.31,2.52,10.79,0,17.34-5.27,17.34-13.82,0-5.74-2.8-8.89-9.88-11.63-7.64-2.96-10.3-4.83-10.3-8.36s2.37-6.03,5.64-6.03c4.92,0,8.27,3.05,10.47,10.2l2.08-.54-.46-10.4Z" />
        <path d="M143.63,155.77c-3.73,0-6.72,1.01-9.84,2.54,.03-8.81,.14-16.5,.36-19.4l-18.12,3.5v1.63l1.76,.23c2.53,.34,3.5,1.82,3.8,5.2,.57,6.46,.49,41.13,0,46.7,4.87,1.09,10.15,1.94,15.44,1.94,14.38,0,23.03-8.89,23.03-22.94,0-11.28-7.02-19.38-16.44-19.38m-6.31,39.45c-1.03,0-2.35-.17-3.09-.3-.29-3.9-.46-19.94-.44-33.96,1.66-.56,2.78-.69,4.23-.69,5.96,0,9.29,6.87,9.31,15.53,.02,11.04-4.11,19.42-10,19.42" />
        <path d="M47.69,140.18H.05v2.29l2.54,.35c3.63,.52,5.03,2.77,5.46,7.9,.81,9.83,.71,27.4,0,35.85-.43,5.13-1.83,7.38-5.46,7.91l-2.54,.34v2.3H30.66v-2.3l-3.22-.34c-3.63-.53-5.02-2.78-5.45-7.91-.29-3.49-.48-9.38-.55-16.29l6.31,.2c4.22,.14,5.98,3.26,6.99,7.88h2.36s0-19.27,0-19.27h-2.36c-1.01,4.63-2.77,7.74-6.99,7.88l-6.32,.21c-.05-9.1,.12-17.85,.56-23.1h9.4c7.34,0,11.27,4.66,13.73,12.9l2.82-.79-.26-16.02Z" />
        <path d="M63.17,155.32c13.55,0,20.27,9.18,20.27,21.28s-7.6,21.5-21.14,21.5-20.26-9.18-20.26-21.28,7.59-21.49,21.13-21.49m-.7,2.62c-5.98,0-7.66,8.09-7.66,18.77s2.65,18.76,8.21,18.76c5.98,0,7.66-8.1,7.66-18.76s-2.65-18.77-8.2-18.77" />
        <path d="M163.39,177.08c-.01-11.2,7.06-21.74,21.02-21.74,11.36,0,16.78,8.42,16.84,19.44h-25.33c-.28,9.99,4.77,17.33,14.46,17.33,4.26,0,6.56-1.06,9.13-3.03l1.19,1.42c-2.81,3.89-8.68,7.59-16.46,7.59-12.21,0-20.84-8.64-20.85-21.01m12.61-5.15l12.7-.27c.07-5.62-.83-13.66-5.41-13.66s-7.18,7.61-7.3,13.93" />
        <path d="M118.78,156.46c-4.57-2.73-12.9-1.44-17.13,8.27l.21-9.45-18.06,3.5v1.63l1.76,.22c2.52,.35,3.49,1.83,3.8,5.2,.57,6.46,.49,18.01,0,23.57-.31,3.36-1.28,4.85-3.8,5.2l-1.76,.23v2.29h25.14v-2.29l-3.28-.23c-2.53-.35-3.5-1.83-3.79-5.2-.46-5.11-.56-15.27-.13-21.89,1.21-2,7.12-3.61,12.6,.07l4.43-11.13Z" />
        <path d="M13.66,210.22h-5.51v10.7h-2.62v-10.7H0v-1.73H13.66v1.73Z" />
        <path d="M22.46,220.92v-12.43h7.22c2.99,0,5.14,2.05,5.14,4.05,0,1.66-1.28,3.19-3.25,3.66l3.95,4.73h-2.97l-3.64-4.33h-3.83v4.33h-2.62Zm2.62-6.06h4.58c1.47,0,2.5-1.07,2.5-2.33s-1.26-2.31-2.64-2.31h-4.44v4.64Z" />
        <path d="M43.73,220.92l6.68-12.43h2.15l6.66,12.43h-2.78l-1.78-3.38h-6.4l-1.73,3.38h-2.8Zm7.75-10.24l-2.75,5.41h5.41l-2.66-5.41Z" />
        <path d="M67.95,208.48l5.02,9.97,4.97-9.97h2.75l-6.61,12.43h-2.24l-6.68-12.43h2.78Z" />
        <path d="M101.54,219.18v1.73h-11.37v-12.43h11.16v1.73h-8.55v3.54h7.4v1.63h-7.4v3.8h8.76Z" />
        <path d="M111.82,220.92v-12.43h2.62v10.7h8.9v1.73h-11.51Z" />
        <path d="M150.06,219.37c-1.38,1.11-2.94,1.63-4.74,1.63-4.32,0-7.7-3.1-7.7-6.36s3.34-6.25,7.8-6.25c3.11,0,5.23,1.08,6.33,2.71l-2.01,1.08c-.89-1.35-2.52-2.03-4.39-2.03-3.06,0-5.07,2.15-5.07,4.54,0,2.5,2.27,4.54,5.25,4.54,1.66,0,3.17-.6,4.53-1.84v-1.31h-3.48v-1.46h5.67v6.29h-2.2v-1.54Z" />
        <path d="M174.18,214.79v-6.31h2.64v6.31c0,3.4-2.05,6.22-7.17,6.22s-7.14-3-7.14-6.22v-6.31h2.61v6.31c0,2.28,1.1,4.45,4.53,4.45s4.53-2.23,4.53-4.45Z" />
        <path d="M187.75,220.92v-12.42h2.61v12.42h-2.61Z" />
        <path d="M201.57,220.92v-12.43h5.88c5.35,0,8.08,2.77,8.08,6.2,0,3.77-3.18,6.23-8.08,6.23h-5.88Zm5.88-10.7h-3.27v8.97h3.27c3.51,0,5.42-1.94,5.42-4.5s-1.98-4.46-5.42-4.46Z" />
        <path d="M235.77,219.18v1.73h-11.37v-12.43h11.16v1.73h-8.55v3.54h7.4v1.63h-7.4v3.8h8.75Z" />
        <g>
          <path d="M146,98.65l21.43,.06,7.02,19.13,6.83-19.07,21.5-.16-17.54-13.7,6.68-20.1-17.44,11.97-17.75-11.72,6.64,19.87-17.37,13.73Z" />
          <path d="M127.89,33.85l21.42,.06,7.01,19.13,6.83-19.08,21.51-.16-17.54-13.7L173.8,0l-17.44,11.98L138.6,.25l6.65,19.87-17.36,13.73Z" />
          <path d="M57.55,33.91l21.43,.05,7.01,19.14,6.83-19.08,21.5-.16-17.54-13.7L103.47,.05l-17.44,11.98L68.27,.3l6.65,19.87-17.37,13.73Z" />
          <path d="M39.53,98.94l21.43,.06,7.01,19.13,6.83-19.07,21.51-.16-17.54-13.7,6.69-20.1-17.45,11.97-17.75-11.72,6.64,19.87-17.37,13.73Z" />
        </g>
        <text className="cls-2" transform="translate(101.1 76.2)">
          <tspan x="0" y="0">
            2023
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default ForbesFourStarLogo;
