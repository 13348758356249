import type { Link } from '@dx-ui/osc-link';
import type { LoginResponse } from '@dx-ui/framework-auth-provider';

export type { LoginResponse };

export type GuestLoginState = {
  password: {
    status: string;
    value: string;
  };
  remember: { value: boolean };
  recaptcha?: string;
  username: {
    override: string;
    status: string;
    value: string;
  };
};

/** @deprecated - @see AuthUserInfo */
export type GuestUserInfo = {
  keyIdentifier: number;
  guestId: number;
  domainUserName: string;
  userRealm: string;
  hhonorsNumber: number;
  username: string;
};

/**
 * Thes are the login event types in the message body passed from the login form
 * @see [Window.postMessage](https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage)
 */
export const LoginFormEventType = {
  /** Used when requesting app's digitalData object */
  DIGITALDATA: 'digitalData',
  /** Used when there is a login attempt */
  LOGIN: 'onLogin',
  /**
   * **deprecated** - used to clear prefilled username
   * @deprecated
   */
  CLEAR: 'clearUser',
  /** Used to indicate closing of the login form */
  CLOSE: 'onClose',
  /** Used when login form is ready */
  READY: 'onReady',
  /** Used when login form height changes */
  HEIGHT: 'onHeightChange',
  /** Used when the login process is finished */
  COMPLETE: 'onComplete',
} as const;

export type GuestLoginResponse = LoginResponse & {
  /**
   * **deprecated** - use `type`
   * @deprecated
   */
  handler?: ValuesOf<typeof LoginFormEventType>;
  /** Login form event type. This  */
  type?: ValuesOf<typeof LoginFormEventType>;
  /**
   * **deprecated** - The active state of parent window
   * @deprecated
   */
  status?: string;
  /** The height of login page */
  height?: number;
};

/** @deprecated - @see AuthTokenInfo */
export type TokenInfo = {
  scope: string;
  token_type: string;
  expires_in: number;
  iat: number;
  exp: number;
  access_token: string;
};

export type GuestLoginConfigOptions = {
  /** Display the create password text. must be set with `forgetInfoLink` */
  showCreatePassword?: boolean;
  /** Displays the forgot password link on the login form */
  forgetInfoLink?: Omit<Link, 'icon'>;
  /** Displays the secure info link on the login form */
  secureInfoLink?: Omit<Link, 'icon'>;
  /** Displays the Join link on the login form */
  joinLink?: Omit<Link, 'icon'>;
  /** Displays the parent window's digitalData object */
  digitalData?: string;
  /** Default username **/
  username?: string;
};
