export function ArrowHeadCircleSimple({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g>
        <path fill="none" d="M0,0V24H24V0Z" />
      </g>
      <g>
        <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
        <path d="M15.7344,9.7344,12,13.47,8.2656,9.7344a.3756.3756,0,0,0-.5312.5312l4,4a.3771.3771,0,0,0,.5312,0l4-4a.3756.3756,0,0,0-.5312-.5312Z" />
      </g>
    </svg>
  );
}
