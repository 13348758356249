import Link from '../link-without-prefetch';
import { useState, useRef, useEffect, useCallback } from 'react';
import type { ReactElement } from 'react';
import { isRelativeUrl } from '../functions/helper';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useLanguage } from '@curated-property/utils';
import { isReducedMotion } from '../../../../utils/src/lib/is-reduced-motion';
import type { InavMenuItem } from '@curated-property/shared-components';
import Icon from '@dx-ui/osc-icon';

interface MenuProps {
  menuItems: Array<InavMenuItem> | undefined;
  iconMenu?: Array<InavMenuItem>;
  stylingOptions?: {
    fontWeight?: string;
    case?: '' | 'uppercase' | 'lowercase' | 'capitalize';
    bgColor?: string;
    accentColor?: string;
  };
  isShopFormInHeader?: boolean;
}

interface ActiveState {
  name: string | null;
  id: number | null;
  url: string | null;
}

export function NavMobile({ menuItems, iconMenu, isShopFormInHeader, stylingOptions }: MenuProps) {
  const { t } = useTranslation();
  const [isMenuToggle, setIsMenuToggle] = useState(false);
  const [lastFocussed, setLastFocussed] = useState<any>(null);
  const [isCurrentButtonName, setIsCurrentButtonName] = useState<string>(null);
  const language = useLanguage();
  const focusOnActive = useRef<HTMLButtonElement>(null);
  const [lastMenuItemActive, setLastMenuItemActive] = useState(null);
  const [activeMenu, setActiveMenu] = useState<ActiveState>({
    id: null,
    name: null,
    url: null,
  });
  const accentElement = <AccentElement color={stylingOptions?.accentColor} />;
  const [isClient, setIsClient] = useState(false);
  const modalRef = useRef(null);
  const backdropFilter = isMenuToggle ? 'blur(4px) opacity(1)' : 'blur(4px) opacity(0)';

  const openOnClick = (e) => {
    document.body.classList.add('overflow-hidden');
    isMenuToggle ? setIsMenuToggle(false) : setIsMenuToggle(true);
    setLastFocussed(e?.target);
    setTimeout(() => {
      focusOnActive?.current?.focus();
    }, 50);
  };

  const closeOnClick = useCallback(() => {
    document.body.classList.remove('overflow-hidden');
    setIsMenuToggle(false);
    setTimeout(() => {
      isMenuToggle && lastFocussed?.focus();
    }, 10);
  }, [isMenuToggle, lastFocussed]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      lastMenuItemActive !== null && lastMenuItemActive?.focus();
    }, 10);
    const modal = modalRef?.current;
    const modalFocusElements = modal.querySelectorAll('button, a:not([tabindex="-1"])');
    const firstElement = modalFocusElements[0];
    const lastElement = modalFocusElements[modalFocusElements.length - 1];
    const handleTabKeyPress = (event) => {
      if (event.key === 'Tab') {
        if (event.shiftKey && document.activeElement === firstElement) {
          event.preventDefault();
          lastElement?.focus();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          event.preventDefault();
          firstElement?.focus();
        }
      }
    };

    function modalKeypress(e: KeyboardEvent) {
      switch (e.key) {
        case 'Escape':
          closeOnClick();
          break;
        default:
          return null;
      }
    }

    modal.addEventListener('keydown', handleTabKeyPress);
    window.addEventListener('keyup', modalKeypress);

    return () => {
      window.removeEventListener('keyup', modalKeypress);
      modal.removeEventListener('keydown', handleTabKeyPress);
    };
  }, [isMenuToggle, activeMenu.id, lastMenuItemActive, lastFocussed, closeOnClick]);

  return (
    <div
      data-testid="navMobile"
      data-element-id="nav-mobile"
      className={`flex size-full items-center justify-end rtl:justify-start ${
        stylingOptions?.fontWeight || 'font-bold'
      } nav-mobile-outer ${stylingOptions?.case || ''}`}
    >
      <button
        type="button"
        className={cx('flex flex-col items-center justify-end', {
          'mx-3': !isShopFormInHeader,
        })}
        data-testid="mobile-nav-toggle"
        aria-expanded={isMenuToggle}
        onClick={(e) => openOnClick(e)}
      >
        <span className="pointer-events-none">
          <Icon name="menu" size="lg" />
        </span>
        <span className="pointer-events-none -mt-1.5 text-current">{t('menu')}</span>
      </button>
      <aside
        ref={modalRef}
        role="dialog"
        style={{
          backdropFilter,
          WebkitBackdropFilter: backdropFilter,
        }}
        className={cx(
          `ease fixed left-0 top-0 z-30 flex size-full justify-start transition-all ${
            isClient && !isReducedMotion ? 'duration-100' : 'duration-0'
          }`,
          isMenuToggle ? 'visible' : 'pointer-events-none invisible'
        )}
      >
        <div
          data-testid="mobile-nav-inner"
          style={{ backgroundColor: stylingOptions?.bgColor }}
          className={cx(
            `z-2 ease relative w-full max-w-xs overflow-scroll transition-all ${
              isClient && !isReducedMotion ? 'duration-300' : 'duration-0'
            }`,
            isMenuToggle ? 'translate-x-0' : '-translate-x-full'
          )}
        >
          <button
            type="button"
            className="m-4 ml-auto flex flex-col items-center justify-end"
            data-testid="mobile-nav-toggle"
            aria-expanded={isMenuToggle}
            tabIndex={isMenuToggle ? 0 : -1}
            onClick={() => closeOnClick()}
            ref={focusOnActive}
          >
            <span className="pointer-events-none">
              <Icon name="close" size="lg" />
            </span>
            <span className="pointer-events-none -mt-1.5 text-current">{t('close')}</span>
          </button>
          <nav>
            <ul
              className={activeMenu?.id !== null ? 'hidden' : ''}
              style={{ transitionBehavior: 'allow-discrete' }}
              key="main-ul"
            >
              {menuItems?.concat(iconMenu || [])?.map((item) => {
                const textLogic = item?.noTx
                  ? item?.label_noTx?.replace('&#038;', '&')
                  : item?.label?.replace('&#038;', '&') || '';
                return (
                  <li key={item?.url}>
                    {item?.childItems?.length > 0 ? (
                      <MenuItemButton
                        key={item?.pageId}
                        id={item?.pageId}
                        text={textLogic}
                        url={item?.url}
                        noTx={item?.noTx}
                        setActiveMenu={setActiveMenu}
                        accent={accentElement}
                        isClient={isClient}
                        setLastMenuItemActive={setLastMenuItemActive}
                        lastMenuItemActive={lastMenuItemActive}
                        isMenuToggle={isMenuToggle}
                        isCurrentButtonName={isCurrentButtonName}
                        setIsCurrentButtonName={setIsCurrentButtonName}
                      />
                    ) : (
                      <MenuItemAnchor
                        id={item?.pageId}
                        target={isRelativeUrl(item?.url) ? '' : '_blank'}
                        text={textLogic}
                        noTx={item?.noTx}
                        href={isRelativeUrl(item?.url) ? `/${language}${item?.url}` : item?.url}
                        accent={accentElement}
                        isClient={isClient}
                        isMenuToggle={isMenuToggle}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
            {/** DISPLAY SECOND LEVEL NAV ITEMS WHEN ACTIVE === PAGEID */}
            {activeMenu?.id !== null && (
              <ul style={{ transitionBehavior: 'allow-discrete' }} key={activeMenu?.id}>
                <li
                  className="z-1 sticky left-0 top-0"
                  style={{ backgroundColor: stylingOptions?.bgColor }}
                >
                  <MenuItemButton
                    id={null}
                    text={activeMenu?.name}
                    setActiveMenu={setActiveMenu}
                    accent={accentElement}
                    isSubmenuButton={true}
                    isClient={isClient}
                    activeMenu={activeMenu}
                    isMenuToggle={isMenuToggle}
                  />
                </li>
                <li>
                  <MenuItemAnchor
                    text={t('addOverview', { item: activeMenu?.name || '' })}
                    accent={accentElement}
                    isClient={isClient}
                    href={
                      isRelativeUrl(activeMenu?.url)
                        ? `/${language}${activeMenu?.url}`
                        : activeMenu?.url
                    }
                    target={isRelativeUrl(activeMenu?.url) ? '' : '_blank'}
                    isMenuToggle={isMenuToggle}
                  />
                </li>
                {menuItems?.map((item) =>
                  item?.childItems?.map((cItem) => {
                    const textLogic = cItem?.noTx
                      ? cItem?.label_noTx?.replace('&#038;', '&')
                      : cItem?.label?.replace('&#038;', '&') || '';
                    if (item.pageId === activeMenu?.id) {
                      return (
                        <li key={cItem?.pageId}>
                          <MenuItemAnchor
                            id={cItem?.pageId}
                            target={isRelativeUrl(cItem?.url) ? '' : '_blank'}
                            text={textLogic}
                            noTx={cItem?.noTx}
                            href={
                              isRelativeUrl(cItem?.url) ? `/${language}${cItem?.url}` : cItem?.url
                            }
                            accent={accentElement}
                            isClient={isClient}
                            isMenuToggle={isMenuToggle}
                          />
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })
                )}
              </ul>
            )}
          </nav>
        </div>
        <MobileNavBackdrop
          isMenuToggle={isMenuToggle}
          className="z-1"
          closeModalOnClick={closeOnClick}
          isClient={isClient}
        />
      </aside>
    </div>
  );
}

interface MobileMenuActiveProps {
  setActiveMenu?: ({ name, id, url }) => void;
  activeMenu?: ActiveState;
  isSubmenuButton?: boolean;
  setLastMenuItemActive?: (target) => void;
  lastMenuItemActive?: HTMLButtonElement;
}

const BASE_NAV_ITEM_CLASSES = `py-3.5 px-6 hover:px-8 focus:px-8 ease w-full items-center border-b border-current flex text-left relative`;

interface MobileSharedMenuItemProps {
  id?: number;
  text: string;
  url?: string;
  noTx?: boolean;
  fontWeight?: string;
  accent?: ReactElement;
  isClient?: boolean;
  isMenuToggle?: boolean;
  isCurrentButtonName?: string;
  setIsCurrentButtonName?: (str: string) => void;
}

interface MobileMenuAnchorProps extends MobileSharedMenuItemProps {
  href: string;
  target: string;
}

type MobileButtonCombined = MobileSharedMenuItemProps & MobileMenuActiveProps;

function MenuItemButton({
  id,
  text,
  url,
  noTx,
  setActiveMenu,
  activeMenu,
  accent,
  isClient,
  isSubmenuButton,
  setLastMenuItemActive,
  isMenuToggle,
  isCurrentButtonName,
  setIsCurrentButtonName,
}: MobileButtonCombined) {
  const [isHovered, setIsHovered] = useState(false);
  const buttonStateClasses = isSubmenuButton ? 'hover:pl-3' : 'justify-between';
  const arrowPadding = isSubmenuButton ? 'pr-2' : '';
  const arrowElement = (
    <div data-testid="button-arrow" className={cx(arrowPadding, 'h-6')}>
      <Icon name={isSubmenuButton ? 'arrowhead-left' : 'arrowhead-right'} size="md" />
    </div>
  );
  const navButtonRef = useRef<HTMLButtonElement>(null);
  const transitions = !isReducedMotion ? 'transition-padding duration-300' : 'duration-[0s]';

  useEffect(() => {
    if (isSubmenuButton || isCurrentButtonName === navButtonRef?.current?.innerText) {
      navButtonRef?.current?.focus();
    }
  }, [activeMenu?.id, isCurrentButtonName, isSubmenuButton]);

  return (
    <button
      type="button"
      data-element-id="mobile-nav-item"
      ref={navButtonRef}
      aria-expanded={isSubmenuButton === true ? true : false}
      onClick={(e) => {
        setActiveMenu({ id, name: text, url });
        !isSubmenuButton && setIsCurrentButtonName(text);
        !isSubmenuButton && setLastMenuItemActive(e.target);
      }}
      className={cx(
        BASE_NAV_ITEM_CLASSES,
        { OneLinkNoTx: noTx },
        buttonStateClasses,
        isClient ? transitions : ''
      )}
      style={{
        textTransform: 'inherit',
        fontWeight: 'inherit',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      tabIndex={isMenuToggle ? 0 : -1}
    >
      {isSubmenuButton && arrowElement}
      {(isHovered || isSubmenuButton) && isClient && accent}
      {text}
      {!isSubmenuButton && arrowElement}
    </button>
  );
}

function MenuItemAnchor({
  target,
  text,
  href,
  noTx,
  accent,
  isClient,
  isMenuToggle,
}: MobileMenuAnchorProps) {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const activePageLogic =
    typeof window !== 'undefined' && window.location.pathname === href ? true : false;
  const { preventClickEvents, preventTabEvents } = {
    preventClickEvents: activePageLogic ? 'pointer-events-none' : '',
    preventTabEvents: activePageLogic ? -1 : 0,
  };

  const transitions = !isReducedMotion ? 'transition-padding duration-300' : 'duration-[0s]';

  return (
    <Link
      data-element-id="mobile-nav-item"
      suppressHydrationWarning
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      tabIndex={isMenuToggle ? preventTabEvents : -1}
      className={cx(BASE_NAV_ITEM_CLASSES, preventClickEvents, isClient ? transitions : '', {
        OneLinkNoTx: noTx,
      })}
      onClick={() => {
        document.body.classList.remove('overflow-hidden');
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
    >
      {isHovered && isClient && accent}
      {text}
      {target === '_blank' ? `<span className='sr-only'> ${t('newTab')}</span>` : ''}
    </Link>
  );
}

interface MobileNavBackdropProps {
  isMenuToggle: boolean;
  className?: string;
  closeModalOnClick?: () => void;
  isClient?: boolean;
}

function MobileNavBackdrop({
  isMenuToggle,
  className,
  closeModalOnClick,
  isClient,
}: MobileNavBackdropProps) {
  return (
    <div
      role="presentation"
      className={cx(
        `bg-primary ease absolute left-0 top-0 size-full transition-opacity ${
          isClient && !isReducedMotion ? 'duration-300' : 'duration-0'
        }`,
        isMenuToggle ? 'opacity-20' : 'opacity-0',
        className
      )}
      onClick={() => closeModalOnClick()}
    />
  );
}

interface AccentElementProps {
  className?: string;
  color?: string;
}

function AccentElement({ className, color }: AccentElementProps) {
  return (
    <div
      data-testid="nav-item-accent"
      className={cx(`bg-secondary absolute left-0 top-0 h-full w-2`, className)}
      style={{ backgroundColor: color }}
    />
  );
}
