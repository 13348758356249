// actually belongs to the demo site - hilton in a box logo
import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./eywqqqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="h-24 w-40 antialiased" />;

export const hotelLogoFooter = <HotelLogo fillColor="#ffffff" className="h-24 w-40 antialiased" />;
