import gql from 'graphql-tag-transform.macro';

export const ComparisonTable = gql`
  fragment ComparisonTable on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_ComparisonTable {
      fieldGroupName
      introduction
      repeater {
        fieldGroupName
        sectionRows {
          fieldGroupName
          rowTitle
          subrows {
            column {
              fieldGroupName
              item
            }
            fieldGroupName
          }
        }
        tableHeading {
          fieldGroupName
          text
          backgroundColour
          headerUrl {
            target
            title
            url
          }
          textColour
        }
      }
      title
      comparisonTableSettings {
        fieldGroupName
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
