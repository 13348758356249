export const youtubeEmbedUrlPattern = RegExp('^https://(www.)?youtube.com/embed/');
export const youtubeShortsUrlPattern = RegExp('^https://(www.)?youtube.com/shorts/');
export const youtubePlaylistUrlPattern = RegExp(
  '^https://(www.)?youtube.com/watch\\?v=[^&]+&list=[^&]+'
);

export const testUrlPattern = (url: string, pattern: RegExp) => {
  return pattern.test(url);
};

export const getVideoSrc = (
  url: string | null,
  source: string | null,
  autoPlay: boolean,
  autoLoop: boolean
) => {
  switch (source) {
    case 'youtube-short':
    case 'youtube': {
      if (!url) {
        return '';
      }

      try {
        const parsedUrl = new URL(url);

        let videoId, listId, listIndex;
        const isShortsLink = testUrlPattern(url, youtubeShortsUrlPattern);
        const isEmbedLink = testUrlPattern(url, youtubeEmbedUrlPattern);
        const isPlaylistLink = testUrlPattern(url, youtubePlaylistUrlPattern);

        if (isEmbedLink || isShortsLink) {
          videoId = parsedUrl.pathname ? parsedUrl.pathname.substring(isShortsLink ? 8 : 7) : '';

          const slashIndex = videoId.indexOf('/');
          if (slashIndex > 0) {
            videoId = videoId.substring(0, slashIndex);
          }
        } else {
          videoId = parsedUrl.searchParams.get('v');
        }

        if (isPlaylistLink) {
          listId = parsedUrl.searchParams.get('list');
          listIndex = parsedUrl.searchParams.get('index');
          return `https://www.youtube.com/embed?listType=playlist&list=${listId}&rel=0;&autoplay=${
            autoPlay ? '1' : '0'
          }&controls=1&enablejsapi=1&showinfo=0&playsinline=1&cc_lang_pref=en&cc_load_policy=0&modestbranding=1${
            autoPlay ? '&mute=1' : ''
          }${listIndex ? `&index=${listIndex}` : ''}`;
        }

        if (videoId) {
          return `https://www.youtube.com/embed/${videoId}/?rel=0;&autoplay=${
            autoPlay ? '1' : '0'
          }&controls=1&enablejsapi=1&showinfo=0&playsinline=1&cc_lang_pref=en&cc_load_policy=0&modestbranding=1${
            autoPlay ? '&mute=1' : ''
          }${autoLoop ? `&loop=1&playlist=${videoId}` : ''}`;
        }
        return '';
      } catch {
        return '';
      }
    }
    default: {
      return url || '';
    }
  }
};
