export function Cancellation({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M12.375,11.918c-0.0001-0.2071-0.1679-0.3749-0.375-0.375H7c-0.2071,0-0.375,0.1679-0.375,0.375S6.7929,12.293,7,12.293
            h5C12.2071,12.2929,12.3749,12.1251,12.375,11.918z"
            />
            <path
              d="M11.375,14c-0.0001-0.2071-0.1679-0.3749-0.375-0.375H7c-0.2071,0-0.375,0.1679-0.375,0.375S6.7929,14.375,7,14.375h4
            C11.2071,14.3749,11.3749,14.2071,11.375,14z"
            />
            <path
              d="M7.001,15.6182H7c-0.2071-0.0003-0.3752,0.1674-0.3755,0.3745c-0.0003,0.2071,0.1674,0.3752,0.3745,0.3755l0,0l3,0.0068
            H10c0.2071,0.0003,0.3752-0.1674,0.3755-0.3745c0.0003-0.2071-0.1674-0.3752-0.3745-0.3755L7.001,15.6182z"
            />
            <path
              d="M8.375,8V6c0-0.2071-0.1679-0.375-0.375-0.375S7.625,5.7929,7.625,6v1.625H6C5.7929,7.625,5.625,7.7929,5.625,8
            S5.7929,8.375,6,8.375h2C8.2071,8.3749,8.3749,8.2071,8.375,8z"
            />
            <path
              d="M17.7656,14.2344c-0.1468-0.1465-0.3844-0.1465-0.5312,0L16,15.4688l-1.2344-1.2344
            c-0.1467-0.1467-0.3845-0.1467-0.5312,0s-0.1467,0.3845,0,0.5312L15.4687,16l-1.2343,1.2344
            c-0.1467,0.1467-0.1467,0.3845,0,0.5312s0.3845,0.1467,0.5312,0L16,16.5312l1.2344,1.2344c0.1467,0.1467,0.3845,0.1467,0.5312,0
            s0.1467-0.3845,0-0.5312L16.5312,16l1.2344-1.2344C17.9111,14.6184,17.9111,14.3816,17.7656,14.2344z"
            />
            <path
              d="M16,11.625c-2.4162,0-4.375,1.9588-4.375,4.375s1.9588,4.375,4.375,4.375s4.375-1.9588,4.375-4.375l0,0
            C20.3722,13.5849,18.4151,11.6278,16,11.625z M16,19.625c-2.002,0-3.625-1.623-3.625-3.625s1.623-3.625,3.625-3.625
            s3.625,1.623,3.625,3.625C19.6229,18.0011,18.0011,19.6229,16,19.625z"
            />
            <path
              d="M12,19.625H4.375V8.1553l3.78-3.78h7.47V10c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375V4
            C16.3749,3.7929,16.2071,3.6251,16,3.625H8C7.9005,3.6248,7.8049,3.6642,7.7344,3.7344l-4,4C3.6642,7.8049,3.6248,7.9005,3.625,8
            v12C3.6251,20.2071,3.7929,20.3749,4,20.375h8c0.2071,0,0.375-0.1679,0.375-0.375S12.2071,19.625,12,19.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
