/* Twitter aka X */

export function Twitter({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m13.492 11.128 5.374-5.788c.188-.202.177-.519-.026-.706-.2-.188-.517-.176-.706.026l-5.235 5.638-3.992-5.589c-.094-.131-.245-.209-.407-.209h-3c-.188 0-.359.104-.444.271-.086.166-.071.367.038.52l5.415 7.581-5.374 5.788c-.188.202-.176.519.026.706.096.09.218.134.34.134.134 0 .268-.054.366-.16l5.235-5.638 3.992 5.589c.094.131.246.209.407.209h3c.188 0 .358-.104.444-.271.086-.166.071-.367-.037-.52l-5.415-7.581zm2.265 7.372-9.285-13h1.771l9.286 13h-1.771z" />
    </svg>
  );
}
