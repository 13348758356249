export function FavouriteHotel({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M7.375,10C7.3749,9.7929,7.2071,9.6251,7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v5
            c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
            C7.2071,10.3749,7.3749,10.2071,7.375,10z"
            />
            <path
              d="M16.625,5v5c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V5C17.3739,4.2411,16.7589,3.6261,16,3.625h-6
            C9.2411,3.6261,8.6261,4.2411,8.625,5v11c0,0.2071,0.1679,0.375,0.375,0.375S9.375,16.2071,9.375,16V5
            C9.3753,4.655,9.655,4.3753,10,4.375h6C16.345,4.3753,16.6247,4.655,16.625,5z"
            />
            <path
              d="M13.375,12c-0.0001-0.2071-0.1679-0.3749-0.375-0.375h-2c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h2
            C13.2071,12.3749,13.3749,12.2071,13.375,12z"
            />
            <path
              d="M15,9.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,9.625,15,9.625z"
            />
            <path
              d="M15,7.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,8.375,11,8.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,7.625,15,7.625z"
            />
            <path
              d="M15,5.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,6.375,11,6.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,5.625,15,5.625z"
            />
            <path
              d="M7.0068,13.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,14.375,6,14.375h1.0068c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2139,13.625,7.0068,13.625z"
            />
            <path
              d="M7,11.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,12.375,6,12.375h1c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,11.625,7,11.625z"
            />
            <path
              d="M19.751,14.2461c-0.8585-0.8379-2.2321-0.827-3.0772,0.0244l-0.1748,0.1719l-0.1719-0.1709
            c-0.8444-0.8536-2.2197-0.865-3.0781-0.0254c-0.4023,0.4002-0.6271,0.9452-0.624,1.5127c-0.0018,0.5859,0.2319,1.148,0.6484,1.56
            l2.961,2.9472c0.1463,0.1459,0.383,0.1459,0.5293,0l2.9629-2.9472c0.4165-0.412,0.6502-0.9741,0.6484-1.56
            C20.3781,15.1913,20.1533,14.6463,19.751,14.2461z M19.1973,16.7871l-2.6983,2.6836l-2.6963-2.6836
            c-0.2745-0.2717-0.4286-0.6421-0.4277-1.0283c-0.0023-0.368,0.1429-0.7215,0.4033-0.9815
            c0.262-0.2604,0.6169-0.4058,0.9863-0.4043c0.3878,0.0008,0.7594,0.1554,1.0333,0.43l0.4355,0.4336
            c0.1458,0.1458,0.382,0.1462,0.5283,0.001l0.44-0.4346c0.5519-0.5622,1.4541-0.5736,2.02-0.0254
            c0.2604,0.26,0.4056,0.6135,0.4033,0.9815C19.6258,16.1452,19.4717,16.5155,19.1973,16.7871z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
