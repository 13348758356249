import { ReactSVG } from 'react-svg';
import { IconSetNames } from './osc-icon-map';
import { EYIconSetNames, ESIconSetNames, HPIconSetNames } from './osc-brand-icon-map';
import type { ESIconNames, EYIconNames, HPIconNames, SupportedBrand } from './osc-brand-icon-map';
import type { IconVariant, IconNames } from './osc-icon-map';

export const sizeToPixelValue = {
  xs: '18',
  sm: '20',
  md: '28',
  lg: '32',
  xl: '40',
  '2xl': '48',
  '3xl': '60',
  '4xl': '72',
  '5xl': '96',
};

const basePath = `/modules/assets/svgs/icons`;
export const baseIconPath = basePath;
export const brandIconPath = `${basePath}/brand`;

type BaseIconProps = Pick<React.ComponentProps<typeof ReactSVG>, 'title' | 'wrapper'> & {
  /**
   * For passing additional classes to the parent `svg` element
   */
  className?: string;
  /**
   * One of five size options; defaults to medium
   */
  size?: keyof typeof sizeToPixelValue;
};

type IconPropsWithBrand<
  BrandCode extends SupportedBrand,
  BrandIconNames extends ReadonlyArray<string>
> = BaseIconProps & {
  /**
   * Brand code for the icon
   */
  brandCode: BrandCode;
  /**
   * Name of the icon to display from a brand specific set
   */
  name: BrandIconNames[number];
  variant?: never;
};

export type OSCIconNames = (typeof IconNames)[number];

type IconPropsWithVariant = BaseIconProps & {
  brandCode?: never;
  /**
   * Name of the icon to display from the approved set
   */
  name: OSCIconNames;
  /**
   * Controls icon style: regular or solid; defaults to “regular”
   */
  variant?: IconVariant;
};

export type IconProps =
  | IconPropsWithVariant
  | IconPropsWithBrand<'ES', typeof ESIconNames>
  | IconPropsWithBrand<'EY', typeof EYIconNames>
  | IconPropsWithBrand<'HP', typeof HPIconNames>;

export function Icon({
  className,
  brandCode,
  name,
  size = 'md',
  title,
  variant = 'regular',
  wrapper = 'span',
}: IconProps) {
  if (brandCode === 'ES' && !ESIconSetNames.has(name)) {
    return null;
  }
  if (brandCode === 'EY' && !EYIconSetNames.has(name)) {
    return null;
  }
  if (brandCode === 'HP' && !HPIconSetNames.has(name)) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  if (!brandCode && !IconSetNames.has(name as OSCIconNames)) {
    return null;
  }
  const sizeInPixels = sizeToPixelValue[size];
  const src = brandCode
    ? `${brandIconPath}/${brandCode}/${name}.svg`
    : `${baseIconPath}/${variant}/${name}.svg`;

  return (
    <ReactSVG
      beforeInjection={(svg) => {
        !title && svg.setAttribute('aria-hidden', 'true');
        title && svg.setAttribute('aria-label', title);
        svg.setAttribute('fill', 'currentColor');
        svg.setAttribute('width', sizeInPixels);
        svg.setAttribute('height', sizeInPixels);
        svg.setAttribute('viewBox', '0 0 24 24');
        svg.setAttribute('data-testid', `icon-${name}`);
        className && svg.classList.add(...className.split(' '));
      }}
      afterInjection={(svg) => {
        if (svg) {
          const wrapperDiv = svg.parentElement?.parentElement;
          if (wrapperDiv) {
            wrapperDiv.removeAttribute('style');
          }
        }
      }}
      src={src}
      {...(title ? { title } : null)}
      style={{ height: `${sizeInPixels}px`, width: `${sizeInPixels}px` }}
      wrapper={wrapper}
      className="pointer-events-none block"
    />
  );
}

export default Icon;
