export function Location({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A6.3817,6.3817,0,0,0,5.625,10a6.3085,6.3085,0,0,0,1.2891,3.81l4.7851,6.415a.375.375,0,0,0,.6006,0l4.8-6.3994A6.3748,6.3748,0,0,0,12,3.625Zm4.5,9.75-4.499,5.999L7.5146,13.3594A5.5622,5.5622,0,0,1,6.375,10,5.625,5.625,0,1,1,16.5,13.375Z" />
          <path d="M12,6.625A3.375,3.375,0,1,0,15.375,10,3.3784,3.3784,0,0,0,12,6.625Zm0,6A2.625,2.625,0,1,1,14.625,10,2.6281,2.6281,0,0,1,12,12.625Z" />
        </g>
      </g>
    </svg>
  );
}
