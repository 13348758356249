import type * as React from 'react';
import { useIntersectionObserver } from 'usehooks-ts';
import cx from 'classnames';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';

export type AnimateRevealItemType = {
  delay: number;
  isAnimated?: boolean;
  animationType?: 'fade-in-up' | 'wipe' | 'fade-in';
  className?: string;
};

export const AnimateRevealItem: React.FC<React.PropsWithChildren<AnimateRevealItemType>> = ({
  children,
  delay,
  animationType = 'fade-in',
  isAnimated = false,
  className,
}) => {
  const isReducedMotion = getIsReducedMotion();
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.3],
    freezeOnceVisible: true,
  });
  const ref = !isReducedMotion && isAnimated ? intersectionRef : null;

  if (animationType === 'wipe') {
    const clipPathValue = inView ? 'inset(0% 0% 0%)' : 'inset(0% 0% 100%)';

    return (
      <div className={className} ref={ref}>
        <div
          className={cx({
            'opacity-100': inView && isAnimated,
            'duration-1000 ease-in-out opacity-0 motion-reduce:opacity-100 motion-reduce:transition-none':
              isAnimated,
          })}
          data-testid="wipe-item-wrapper"
          style={
            isAnimated
              ? {
                  transitionDelay: `${delay}ms`,
                  clipPath: isReducedMotion ? 'inset(0% 0% 0%)' : clipPathValue,
                }
              : {}
          }
        >
          {children}
        </div>
      </div>
    );
  }

  const isFadeInUp = animationType === 'fade-in-up';

  return (
    <div
      ref={ref}
      data-testid="fade-item-wrapper"
      style={
        isAnimated
          ? {
              transitionDelay: `${delay}ms`,
              transitionTimingFunction: 'cubic-bezier(.25, .46, .45, .99);',
            }
          : {}
      }
      className={cx(
        {
          'opacity-100': inView && isAnimated,
          'opacity-100 translate-y-0': inView && isAnimated && isFadeInUp,
          'translate-y-6': !inView && isAnimated && isFadeInUp,
          'duration-1000 opacity-0 motion-reduce:transition-none motion-reduce:opacity-100 transition-all':
            isAnimated,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default AnimateRevealItem;
