import type { ReactChild } from 'react';
import { iconmapper, HandleWYSIWYGContent } from './functions/helper';
import { WrappedSubtitle, appliedCloudinaryParams, sanitize } from '@curated-property/utils';
import { Close } from '@curated-property/icon-list';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_TextAlignment, GIS_Padder } from './functions/global-instance-styles';

type IconKeyType = keyof typeof iconmapper;

export interface TwoColumnIconListProps {
  title?: string;
  subtitle?: string;
  repeater?: {
    iconList?: IconKeyType | string;
    iconText?: string;
    iconDescription?: string;
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function TwoColumnIconList({
  title,
  subtitle,
  repeater,
  globalStyles,
  instanceStyles,
}: TwoColumnIconListProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const iconMap = iconmapper();

  return (
    <div data-testid="twoColIconList" className={inlineStyles?.showHide ? 'hidden' : ''}>
      <div
        className={cx('two-column-icon-list', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : undefined,
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        }}
      >
        <div
          className="container"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          }}
        >
          <div className={`text- mx-0 p-0 md:mx-4 md:p-8 lg:mx-12${textAlignment}`}>
            {title && (
              <h2
                className="font-headline mb-4 text-2xl font-black leading-none md:mb-6 md:text-4xl"
                style={{ color: inlineStyles?.titleColor }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(title || ''),
                }}
              />
            )}
            {subtitle ? (
              <WrappedSubtitle>
                <div
                  className={cx('my-4 text-lg')}
                  style={{ color: inlineStyles?.subtitleColor }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(subtitle || ''),
                  }}
                />
              </WrappedSubtitle>
            ) : (
              ''
            )}
            <div className="mx-0 flex-none md:flex md:flex-wrap lg:mx-6">
              {repeater?.map((icon, key) => {
                const Icon = iconMap[(icon?.iconList as keyof typeof iconMap) || ''];

                return (
                  <TwoColumnBlockItem
                    key={key}
                    description={icon?.iconDescription}
                    iconSymbol={
                      Icon ? (
                        <Icon
                          className="size-12 lg:size-16 "
                          fillColor={inlineStyles?.twoColumnIconColour}
                        />
                      ) : (
                        <Close
                          className="size-12 lg:size-16 "
                          fillColor={inlineStyles?.twoColumnIconColour}
                        />
                      )
                    }
                    iconText={icon?.iconText}
                    labelColor={inlineStyles?.twoColumnLabelColour}
                    textAlignment={textAlignment}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IconProps {
  iconSymbol?: ReactChild;
  iconText?: string;
  description?: string;
  labelColor?: string;
  textAlignment?: string;
}

function TwoColumnBlockItem({ iconSymbol, description, labelColor, textAlignment }: IconProps) {
  switch (textAlignment) {
    case 'left':
      textAlignment = 'start';
      break;
    case 'center':
      textAlignment = 'center';
      break;
    case 'right':
      textAlignment = 'end';
      break;
    default:
      textAlignment = 'start';
  }

  const textAlignmentJustify = `justify-${textAlignment}`;

  return (
    <div className="flex w-full shrink-0 md:w-6/12" data-testid="block-item">
      <div
        className={`${textAlignmentJustify} mx-0 flex w-full flex-row items-center py-8 text-left md:mr-8 lg:odd:mx-0`}
      >
        {!iconSymbol ? (
          ''
        ) : (
          <div className="flex items-center justify-center pr-4">{iconSymbol}</div>
        )}

        <div
          className="flex items-center text-lg"
          style={{ color: labelColor }}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(description, labelColor),
          }}
        />
      </div>
    </div>
  );
}
