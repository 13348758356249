/**
 *
 *
 * DO NOT UPDATE THIS FILE, UPDATE SJCSMSA.TSX - THIS FILE IS THE OLD CTYHON FOR SIGNIA SAN JOSE
 *
 * TODO: DELETE THIS FILE
 *
 */

import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sjcsmhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-20 w-40 fill-current text-bg antialiased" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-20 w-32 fill-current text-bg antialiased" fillColor="#fff" />
);
