export function ThreeSixtyTour({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M19.75,7.875c0.6213,0,1.125-0.5037,1.125-1.125s-0.5037-1.125-1.125-1.125s-1.125,0.5037-1.125,1.125
				C18.6259,7.371,19.129,7.8741,19.75,7.875z M19.75,6.375c0.2071,0,0.375,0.1679,0.375,0.375s-0.1679,0.375-0.375,0.375
				s-0.375-0.1679-0.375-0.375C19.3751,6.5429,19.5429,6.3751,19.75,6.375z"
            />
            <path
              d="M16.25,12.375c0.8972-0.0007,1.6243-0.7278,1.625-1.625v-3.5c0-0.8975-0.7275-1.625-1.625-1.625
				s-1.625,0.7275-1.625,1.625v3.5C14.6257,11.6472,15.3528,12.3743,16.25,12.375z M15.375,7.25c0-0.4832,0.3918-0.875,0.875-0.875
				c0.4832,0,0.875,0.3918,0.875,0.875l0,0v3.5c0,0.4832-0.3918,0.875-0.875,0.875c-0.4832,0-0.875-0.3918-0.875-0.875l0,0V7.25z"
            />
            <path
              d="M19.1807,9.6592c-0.1818-0.0998-0.41-0.0334-0.5098,0.1484c-0.0998,0.1818-0.0334,0.41,0.1484,0.5098
				c1.1641,0.64,1.8057,1.415,1.8057,2.1826c0,1.8076-3.3574,3.3887-7.6436,3.5986c-0.207,0.0095-0.3671,0.1851-0.3575,0.3921
				c0.0093,0.2008,0.1751,0.3586,0.3761,0.3579h0.0186C17.86,16.61,21.375,14.7822,21.375,12.5
				C21.375,11.4307,20.6162,10.4482,19.1807,9.6592z"
            />
            <path
              d="M11.37,16.4583c-0.0029-0.0922-0.0402-0.18-0.1045-0.2461l-0.0026-0.0061l-1.5-1.4737
				c-0.147-0.1459-0.3845-0.1449-0.5303,0.0021c-0.1459,0.147-0.1449,0.3845,0.0021,0.5303
				c0.0009,0.0009,0.0019,0.0018,0.0028,0.0028l0.7642,0.7508C6.2144,15.6285,3.375,14.1615,3.375,12.5
				c0-0.7676,0.6416-1.543,1.8057-2.1826c0.1818-0.0998,0.2482-0.328,0.1484-0.5098c-0.0998-0.1818-0.328-0.2482-0.5098-0.1484
				C3.3838,10.4482,2.625,11.4307,2.625,12.5c0,2.1512,3.1241,3.8976,7.5377,4.2907l-0.93,0.9466
				c-0.1459,0.147-0.1449,0.3845,0.0021,0.5303c0.147,0.1459,0.3845,0.1449,0.5303-0.0021
				c0.0009-0.0009,0.0018-0.0019,0.0028-0.0028l1.5-1.5264l0.0035-0.0088c0.0615-0.0636,0.0984-0.147,0.1039-0.2353
				c0-0.0056-0.0024-0.01-0.0024-0.0158S11.37,16.4649,11.37,16.4583z"
            />
            <path
              d="M7.75,11.625c-0.483-0.0006-0.8744-0.392-0.875-0.875c0-0.2071-0.1679-0.375-0.375-0.375s-0.375,0.1679-0.375,0.375
				c0,0.8975,0.7275,1.625,1.625,1.625s1.625-0.7275,1.625-1.625v-0.5C9.3734,9.7645,9.1533,9.3054,8.7759,9
				C9.1533,8.6946,9.3734,8.2355,9.375,7.75v-0.5c0-0.8975-0.7275-1.625-1.625-1.625S6.125,6.3525,6.125,7.25
				c0,0.2071,0.1679,0.375,0.375,0.375S6.875,7.4571,6.875,7.25l0,0c0-0.4832,0.3918-0.875,0.875-0.875S8.625,6.7668,8.625,7.25l0,0
				v0.5C8.6244,8.233,8.233,8.6244,7.75,8.625C7.5429,8.625,7.375,8.7929,7.375,9S7.5429,9.375,7.75,9.375
				c0.483,0.0006,0.8744,0.392,0.875,0.875v0.5C8.6244,11.233,8.233,11.6244,7.75,11.625z"
            />
            <path
              d="M13.625,10.75v-0.5c0.0028-0.8947-0.7202-1.6223-1.6149-1.6251c-0.314-0.001-0.6215,0.0893-0.8851,0.2599V7.25
				c0-0.4832,0.3918-0.875,0.875-0.875s0.875,0.3918,0.875,0.875l0,0c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375
				l0,0c0-0.8975-0.7275-1.625-1.625-1.625s-1.625,0.7275-1.625,1.625v3.5c0,0.8975,0.7275,1.625,1.625,1.625
				S13.625,11.6475,13.625,10.75z M12.875,10.75c0,0.4832-0.3918,0.875-0.875,0.875s-0.875-0.3918-0.875-0.875l0,0v-0.5
				c0-0.4832,0.3918-0.875,0.875-0.875s0.875,0.3918,0.875,0.875V10.75z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
