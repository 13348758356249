import { useState, Fragment } from 'react';
import { HeadingStyle } from './includes/heading-style';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder } from './functions/global-instance-styles';
import { AnchorLink } from './global/anchor-link';
import { useTranslation } from 'next-i18next';
import { Caret, Restaurant } from '@curated-property/icon-list';
import { iconmapper } from './functions/helper';
import { sanitize, appliedCloudinaryParams } from '@curated-property/utils';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import cx from 'classnames';
import type { MergedRestaurantData } from './global/restaurants';
import { useRouter } from 'next/router';
import { useLocale } from '@dx-ui/utilities-dates';
import type { LocaleDayNameKey } from '@dx-ui/framework-i18n';
import { convertTimeTo24HourFormat, getLocaleDayName } from '@dx-ui/framework-i18n';

export interface RestaurantsProps {
  readMoreLess?: boolean;
  hideList?: string;
  restaurants?: MergedRestaurantData;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function Restaurants({
  readMoreLess,
  hideList,
  restaurants,
  globalStyles,
  instanceStyles,
}: RestaurantsProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  let hideListArray;
  if (hideList) {
    hideListArray = hideList?.includes(',') ? hideList?.split(',') : [hideList];
  }

  if (hideListArray?.length > 0) {
    restaurants = restaurants?.filter((item) => {
      return !hideListArray.includes(item?.id);
    });
  }

  return (
    <div
      data-element-id="restaurants-wrapper"
      className={paddingStyles}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div className="container">
        <div className="flex w-full flex-col justify-center">
          <div
            data-element-id="restaurants-grid"
            className="grid auto-rows-auto grid-cols-1 gap-x-4 gap-y-2 overflow-hidden py-4 pt-2 md:grid-flow-row-dense md:grid-cols-2 md:gap-y-6 md:pt-4 lg:grid-cols-3"
          >
            {restaurants?.map((restaurant, key) => {
              return (
                <RestaurantItems
                  readMoreLess={readMoreLess}
                  item={restaurant}
                  key={key}
                  inlineStyles={inlineStyles}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

interface restaurantProps {
  readMoreLess?: boolean;
  item?: RestaurantsProps['restaurants'][number];
  inlineStyles?: any;
}

function RestaurantItems({ readMoreLess, item, inlineStyles }: restaurantProps) {
  const { locale: localeStr = 'en' } = useRouter();
  const locale = useLocale({ language: localeStr });
  const [t] = useTranslation();
  const [readMore, setReadMore] = useState(false);
  const [openingHours, setOpeningHours] = useState(false);

  const cuisineType = item?.cuisineTypeSelect ?? item?.cuisineType ?? null;
  const iconMap = iconmapper();
  const Icon = iconMap[item?.iconList || ''];

  return (
    <div data-id={item?.id} data-testid="restaurantItem">
      <div
        className={cx(
          'border-bg-alt relative flex h-full flex-col border border-solid',
          inlineStyles?.hideTileBorder && 'border-0'
        )}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          textAlign: inlineStyles?.textAlignment,
          borderColor: inlineStyles?.tileBorderColour || null,
        }}
      >
        <div
          className="bg-bg-alt p-4"
          style={{
            backgroundColor: inlineStyles?.topSectionBackgroundColour || null,
          }}
        >
          {item?.name_noTx && (
            <HeadingStyle
              text={item?.name_noTx}
              type="h2"
              styledAs="h4"
              className={cx('OneLinkNoTx font-extrabold', cuisineType && 'mb-2')}
              textColorInline={inlineStyles?.titleColor}
            />
          )}
          {cuisineType && (
            <div className="fill-primary flex items-center">
              {Icon ? (
                <Icon
                  className="mr-2 w-8"
                  fillColor={inlineStyles?.cuisineTypeIconColour || inlineStyles?.subtitleColor}
                />
              ) : (
                <Restaurant
                  className="mr-2 w-8"
                  fillColor={inlineStyles?.cuisineTypeIconColour || inlineStyles?.subtitleColor}
                />
              )}
              <span
                className="text-primary font-normal leading-6"
                style={{
                  color: inlineStyles?.cuisineTypeTextColour || inlineStyles?.subtitleColor,
                }}
              >
                {cuisineType}
              </span>
            </div>
          )}
        </div>
        <div>
          {item?.image?.sourceUrl ? (
            <div className="h-0 overflow-hidden pb-3/4">
              <CroppedImage
                loader={() => {
                  return customLoader({
                    src: item?.image?.sourceUrl,
                    crop: item?.enableCropping,
                    cropType: item?.cropType,
                    cropHeight: item?.cropHeight,
                    cropWidth: item?.cropWidth,
                    xPosition: item?.xPosition,
                    xPositionAdvanced: item?.xPositionAdvanced,
                    yPosition: item?.yPosition,
                    yPositionAdvanced: item?.yPositionAdvanced,
                    autoPosition: item?.autoPosition,
                  });
                }}
                width="432"
                height="329"
                src={item?.image?.sourceUrl || ''}
                alt={item?.image?.altText || ''}
                objectFit="cover"
              />
            </div>
          ) : (
            // core+ image
            <div className="relative" data-testid="background-img">
              {item?.overviewImage?.variants[0]?.url &&
              item?.overviewImage?.variants[0]?.url !==
                'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png' ? (
                <div
                  className="h-0 pb-3/4"
                  style={{
                    backgroundImage: `url(${item?.overviewImage?.variants[0]?.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                  }}
                />
              ) : (
                <div className="relative">
                  <div className="absolute flex size-full items-center justify-center text-lg">
                    <p>No image available</p>
                  </div>
                  <CroppedImage
                    src=""
                    alt=""
                    width="431"
                    height="287"
                    className="w-full"
                    layout="responsive"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {(item?.openingHours || item?.operatingHours.length > 0) && (
          <div
            className="border-b-solid border-bg-alt mx-4 border-b pb-4 pt-6"
            data-element-id="hours-button"
            style={{ borderColor: inlineStyles?.hoursSeperatorColour || null }}
          >
            <button
              data-testid="hoursToggle"
              className="relative w-full text-left"
              style={{ color: inlineStyles?.textColor }}
              onClick={() => {
                !openingHours ? setOpeningHours(true) : setOpeningHours(false);
              }}
              aria-expanded={openingHours ? 'true' : 'false'}
              type="button"
            >
              <HeadingStyle
                text={t('openingHours')}
                type="h3"
                styledAs="h6"
                className="font-bold leading-6"
              />
              <Caret
                className={cx('absolute right-[5px] top-[calc(50%-4px)] h-1.5 transition-all', {
                  'rotate-180': openingHours,
                })}
                fillColor={inlineStyles?.textColor || '#000'}
              />
            </button>
          </div>
        )}
        <div
          data-testid="openingHours"
          className={cx('border-b-solid mx-4 flex flex-col border-b transition-all', {
            'opacity-0 pointer-events-none max-h-0 transition-none': !openingHours,
            'max-h-auto duration-500': openingHours,
          })}
          style={{ borderColor: inlineStyles?.hoursSeperatorColour || null }}
          data-element-id="hours-separator"
        >
          {item?.openingHours
            ? item?.openingHours?.map((item, key) => {
                const { time, day } = getFormattedOpeningHours(item, locale);
                const days = day.replace(DAILY_KEY, t('daily'));

                return (
                  <Fragment key={key}>
                    {key === 0 && <div className="py-1" />}
                    {item?.title ? (
                      <OperatingHours dayOfTheWeek={days} time={time} inlineStyles={inlineStyles} />
                    ) : (
                      <span className="pt-4" />
                    )}
                  </Fragment>
                );
              })
            : item?.operatingHours?.map((item, key) => {
                const operatingHoursArray = convertOperatingHoursToArray(item);
                const combinedOperatingHours = combineDaysWithSameHours(
                  operatingHoursArray,
                  locale
                );
                const combinedOperatingHoursStripped =
                  stripMealNamesAndCommasFromhours(combinedOperatingHours);

                return (
                  <Fragment key={key}>
                    {item?.headline && item?.headline !== 'Hours' ? (
                      <HeadingStyle
                        text={item?.headline}
                        type="h3"
                        styledAs="h6"
                        className="py-4 font-bold leading-6"
                        textColorInline={inlineStyles?.textColor}
                      />
                    ) : (
                      <div className="py-1" />
                    )}
                    {combinedOperatingHoursStripped?.map((item, key) => {
                      const days = item?.day.replace(DAILY_KEY, t('daily'));
                      return (
                        <OperatingHours
                          key={key}
                          dayOfTheWeek={days}
                          time={item?.hours}
                          inlineStyles={inlineStyles}
                        />
                      );
                    })}
                  </Fragment>
                );
              })}
        </div>

        <div
          className={cx(
            'mx-4 mb-4 py-4 text-base',
            item?.buttons?.length > 0 && 'border-b-solid border-bg-alt border-b'
          )}
          data-element-id="tile-content"
          style={{ color: inlineStyles?.textColor }}
        >
          {readMoreLess && item?.description.length > 300 ? (
            <Fragment>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    readMore ? item?.description : item?.description.substring(0, 300) + '...'
                  ),
                }}
              />

              <button
                data-testid="mobile-button-trigger"
                className="mb-2 mt-3"
                onClick={() => {
                  !readMore ? setReadMore(true) : setReadMore(false);
                }}
                type="button"
              >
                <span className="font-bold underline">
                  {!readMore ? t('dottedMore') : t('dottedLess')}
                </span>
              </button>
            </Fragment>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(item?.description || ''),
              }}
            />
          )}
        </div>

        {item?.buttons?.length > 0 && (
          <div className="flex size-full flex-wrap content-end items-end px-2 pb-4 pt-2">
            {item?.buttons?.map((link, key) => {
              return (
                <AnchorLink
                  key={key}
                  url={link?.link?.url}
                  title={link?.link?.title}
                  target={link?.link?.target}
                  buttonStyle={link?.buttonStyle ?? 'primary'}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

interface OperatingHoursProps {
  dayOfTheWeek?: string;
  time?: string;
  inlineStyles?: { [key: string]: string };
}

function OperatingHours({ dayOfTheWeek, time, inlineStyles }: OperatingHoursProps) {
  const [t] = useTranslation();
  const a11yHours = time.toString().includes('-') && time.toString().replace('-', t('through'));

  // add larger dashes to time
  const timeWithDashes = time.toString().replace('-', '–');

  return (
    <div
      className={cx('flex justify-between pb-2 text-base leading-6')}
      style={{ color: inlineStyles?.textColor }}
    >
      <span
        aria-hidden={true}
        dangerouslySetInnerHTML={{ __html: sanitize(dayOfTheWeek) }}
        className="whitespace-nowrap font-bold capitalize"
      />
      <span
        aria-hidden={true}
        dangerouslySetInnerHTML={{
          __html: time.length && time[0] !== '' ? timeWithDashes : t('occupancy.closed'),
        }}
        className="w-full text-balance text-end"
      />
      <span
        className="sr-only"
        dangerouslySetInnerHTML={{
          __html: sanitize(dayOfTheWeek || ''),
        }}
      />
      <span
        className="sr-only"
        dangerouslySetInnerHTML={{
          __html: sanitize(a11yHours ? a11yHours : t('occupancy.closed') || ''),
        }}
      />
    </div>
  );
}

function includesDash(str: string) {
  return str.includes('-') || str.includes('–') || str.includes('to');
}

function getFormattedOpeningHours(
  item: RestaurantsProps['restaurants'][number]['openingHours'][number],
  locale: Intl.Locale
) {
  const time = includesDash(item.hours) ? formatHours(item.hours, locale) : item.hours;
  const [start, end] =
    item.title
      .toLowerCase()
      ?.split(/[-–]|to/)
      .map((t) => t.trim()) || [];
  const isDaily = start === 'monday' && end === 'sunday';
  const day = isDaily
    ? DAILY_KEY
    : includesDash(item.title)
    ? `${getLocaleDayName(locale, start as LocaleDayNameKey)} – ${getLocaleDayName(
        locale,
        end as LocaleDayNameKey
      )}`
    : item.title;

  return {
    time,
    day,
  };
}

type DaysWithHours = Array<{ day: string; hours: string | string[] }>;

const DAILY_KEY = '__DAILY__';

/**
 * Convert object to an array of objects, this way we can loop through them
 * @return Array of objects for each day listed in Core+: [{ day: key, hours: value }]
 */
export function convertOperatingHoursToArray(
  obj: RestaurantsProps['restaurants'][number]['operatingHours'][number]
) {
  const combinedArray: DaysWithHours = [];
  const whitelistValues = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  for (const [key, value] of Object.entries(obj)) {
    // we only ever want the days of the week, so only add to the array if the key is whitelisted
    if (whitelistValues.includes(key)) {
      combinedArray.push({
        day: key,
        hours: value.length === 0 ? [''] : value,
      });
    }
  }
  return combinedArray;
}

/**
 * Combine days with similar opening hours together
 * @return Array of objects for each day listed in Core+: [{ day: key, hours: value }] - shortened to reduce openingHours duplication
 */
export function combineDaysWithSameHours(arr: DaysWithHours, locale: Intl.Locale) {
  const modifiedArray: DaysWithHours = [];
  let indexesWithDuplicate = [];
  let currentHours = arr[0].hours;

  for (let i = 0; i < arr.length; i++) {
    const curr = arr[i];

    if (JSON.stringify(curr.hours) === JSON.stringify(currentHours)) {
      indexesWithDuplicate.push(curr?.day);
    } else {
      if (indexesWithDuplicate.length > 1) {
        modifiedArray.push({
          day: `${getLocaleDayName(
            locale,
            indexesWithDuplicate[0] as LocaleDayNameKey
          )} – ${getLocaleDayName(
            locale,
            indexesWithDuplicate[indexesWithDuplicate.length - 1] as LocaleDayNameKey
          )}`,
          hours: formatCurrentHours(currentHours, locale),
        });
      } else {
        modifiedArray.push({
          day: getLocaleDayName(locale, indexesWithDuplicate[0] as LocaleDayNameKey),
          hours: formatCurrentHours(currentHours, locale),
        });
      }
      indexesWithDuplicate = [curr.day];
      currentHours = curr.hours;
    }
  }

  // Process the last accumulated days
  if (indexesWithDuplicate.length > 1) {
    const isDaily = indexesWithDuplicate.length === 7;
    modifiedArray.push({
      day: isDaily
        ? DAILY_KEY
        : `${getLocaleDayName(
            locale,
            indexesWithDuplicate[0] as LocaleDayNameKey
          )} – ${getLocaleDayName(
            locale,
            indexesWithDuplicate[indexesWithDuplicate.length - 1] as LocaleDayNameKey
          )}`,
      hours: formatCurrentHours(currentHours, locale),
    });
  } else {
    modifiedArray.push({
      day: getLocaleDayName(locale, indexesWithDuplicate[0] as LocaleDayNameKey),
      hours: formatCurrentHours(currentHours, locale),
    });
  }

  return modifiedArray;
}

export function stripMealNamesAndCommasFromhours(arr: DaysWithHours) {
  const arrStripped = arr.map((item) => {
    return {
      ...item,
      hours: item?.hours
        ?.toString()
        .replace('Breakfast: ', '')
        .replace('Lunch: ', '')
        .replace('Dinner: ', '')
        .replace(/,/g, '<br>'),
    };
  });

  return arrStripped;
}

function formatCurrentHours(hours: string | string[], locale: Intl.Locale) {
  if (Array.isArray(hours)) {
    return hours.map((hour) => formatHours(hour, locale));
  }
  return formatHours(hours, locale);
}

const formatHours = (hours: string, locale: Intl.Locale): string => {
  if (!hours) return '';

  if (locale.language === 'en') {
    return hours;
  }
  // hours example: 7:00 pm - 1:00 am or 12:30 pm - 5 pm
  const [start, end] = hours.split(/[-–]|to/).map((t) => t.trim());

  // Convert each time separately and rejoin with dash
  return `${convertTimeTo24HourFormat(start)} - ${convertTimeTo24HourFormat(end)}`;
};
