import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./aytakdi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-16 text-bg antialiased md:h-20 lg:h-24" fillColor="#09194E" />
);

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 h-16 text-bg antialiased md:mr-0 md:h-20" fillColor="#ffffff" />
);
