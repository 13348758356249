export const getIntlNumberLocale = (locale: string) => {
  if (locale === 'ar') {
    return 'ar-ae';
  }
  return locale;
};

function getNumberFormatOptions(locale: string) {
  if (locale === 'ar') {
    return { useGrouping: false };
  }
  return { useGrouping: true };
}

export function getLocaleFormattedNumber(
  value: number | null | undefined,
  { locale, options }: { locale: string; options?: Intl.NumberFormatOptions }
) {
  return typeof value === 'number'
    ? Intl.NumberFormat(
        getIntlNumberLocale(locale),
        options || getNumberFormatOptions(locale)
      ).format(value)
    : '';
}
