import { isBrowser } from '@dx-ui/utilities-is-browser';

export function getCIDParam({
  campaignId,
  componentName,
}: {
  campaignId: string;
  componentName: string;
}) {
  const digitalData = isBrowser ? window.digitalData : {};
  const brandCode =
    digitalData?.global?.page?.brandCode || digitalData?.page?.category?.brand || '';
  const ddPageType =
    digitalData?.global?.page?.pageType || digitalData?.page?.pageInfo?.pageType || '';
  const ddPrimaryCategory =
    digitalData?.global?.page?.primaryCategory ||
    digitalData?.page?.category?.primaryCategory ||
    '';
  const ddPageDetail1 =
    digitalData?.global?.page?.pageDetail1 || digitalData?.page?.pageInfo?.pageDetail1 || '';
  const ddPageDetail2 =
    digitalData?.global?.page?.pageDetail2 || digitalData?.page?.pageInfo?.pageDetail2 || '';

  const pageType = ddPageType ? `,${ddPageType}` : '';
  const pageDetail1 = ddPageDetail1 ? `,${ddPageDetail1}` : '';
  const pageDetail2 = ddPageDetail2 ? `,${ddPageDetail2}` : '';
  const primaryCategory = ddPrimaryCategory ? `,${ddPrimaryCategory}` : '';

  return `OH,${brandCode},${campaignId},MULTIPR,${componentName}${pageType}${primaryCategory}${pageDetail1}${pageDetail2}`;
}
