import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksTwoHorizontalInterface } from './interfaces';

export function MediaBlocksTwoHorizontal(props: MediaBlocksTwoHorizontalInterface) {
  return (
    <div className="grid size-full grid-rows-2" data-testid="media-blocks-two-hoz">
      <div
        className="relative flex items-center justify-center overflow-hidden bg-blue-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderBottomWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageTop?.sourceUrl}
          alt={props?.imageTop?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageTop?.sourceUrl,
              crop: props?.imageTopCrop?.imageCrop21AddCrop && true,
              cropType: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropType,
              cropHeight: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageTopCrop?.imageCrop21AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
      <div
        className="relative flex items-center justify-center overflow-hidden bg-red-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderTopWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageBottom?.sourceUrl}
          alt={props?.imageBottom?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageBottom?.sourceUrl,
              crop: props?.imageBottomCrop?.imageCrop21AddCrop && true,
              cropType: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.cropType,
              cropHeight: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageBottomCrop?.imageCrop21AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
    </div>
  );
}
