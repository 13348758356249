import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./psplqqq/hotel-logo').then((i) => i.HotelLogo)
);
const HotelLogoWithCurio = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./psplqqq/hotel-logo-with-curio').then((i) => i.HotelLogoWithCurio)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#000000" className="h-24 w-40 antialiased md:w-48" />
);

export const hotelLogoFooter = (
  <HotelLogoWithCurio fillColor="#ffffff" className="h-auto w-32 antialiased md:w-40" />
);
