export function ClosedCaptionsSolid({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m20.791 6.79c-.23-.689-.935-1.342-1.64-1.518-.126-.031-3.136-.772-7.151-.772s-7.025.741-7.151.772c-.705.176-1.409.828-1.64 1.518-.028.087-.709 2.164-.709 5.21s.681 5.123.709 5.21c.23.689.935 1.342 1.64 1.518.126.031 3.136.772 7.151.772s7.025-.741 7.151-.772c.705-.176 1.409-.828 1.64-1.518.028-.087.709-2.164.709-5.21s-.681-5.123-.709-5.21zm-11.791 7.71c.586 0 1.122-.348 1.364-.886.114-.252.409-.364.662-.25.251.114.363.41.25.662-.404.896-1.298 1.474-2.276 1.474-1.379 0-2.5-1.121-2.5-2.5v-2c0-1.379 1.121-2.5 2.5-2.5.979 0 1.872.578 2.276 1.474.113.252 0 .548-.25.662-.253.115-.548.002-.662-.25-.242-.538-.778-.886-1.364-.886-.827 0-1.5.673-1.5 1.5v2c0 .827.673 1.5 1.5 1.5zm6 0c.586 0 1.122-.348 1.364-.886.114-.252.41-.364.662-.25.251.114.363.41.25.662-.404.896-1.298 1.474-2.276 1.474-1.379 0-2.5-1.121-2.5-2.5v-2c0-1.379 1.121-2.5 2.5-2.5.979 0 1.872.578 2.276 1.474.113.252 0 .548-.25.662-.252.115-.548.002-.662-.25-.242-.538-.778-.886-1.364-.886-.827 0-1.5.673-1.5 1.5v2c0 .827.673 1.5 1.5 1.5z" />
    </svg>
  );
}
