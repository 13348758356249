import type * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import Link from '../link-without-prefetch';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { checkIfNested, makeRandomInt, useLanguage } from '@curated-property/utils';
import type { InavMenuItem } from '@curated-property/shared-components';
import {
  isRelativeUrl,
  isHexOrRgb,
  slugify,
  elementWidthExceedsViewportBoundary,
} from '../functions/helper';
import Icon from '@dx-ui/osc-icon';

interface MenuStylingOptionProps {
  bgSubmenuColor?: string;
  colorOnHover?: string;
  underlineActive?: boolean;
  underlineColor?: string;
  underlineSize?: string;
  headerActiveStateTextColor?: string;
  itemActiveClasses?: string;
  itemActiveBgColor?: string;
  itemActiveTextColor?: string;
  paddingClasses?: string;
  fontWeight?: 'light' | 'normal' | 'medium' | 'bold';
  fontSize?: string;
  case?: '' | 'uppercase' | 'lowercase' | 'capitalize';
  activeMenuItemDisplayType?: string;
  bgSubmenuBlur?: number;
  bgSubmenuTextColour?: string;
  hasSubmenuDropShadowHidden?: boolean;
}

interface MenuProps {
  activePageId?: number;
  menuItems?: Array<InavMenuItem>;
  urlPrefix?: string;
  activeHeader: (arg: boolean) => void;
  stylingOptions?: MenuStylingOptionProps;
  currentPage?: string;
}

export function NavDesktop({
  activePageId,
  menuItems,
  activeHeader,
  stylingOptions,
  currentPage,
}: MenuProps) {
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [hashId, setHashId] = useState<string | null>(null);
  const [hashTrig, setHashTrig] = useState(false);
  const { t } = useTranslation();
  const language = useLanguage();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window?.location?.href?.includes('#')) {
        setHashId(window.location.href.split('#')[1]);
      } else {
        const body = window?.location?.href?.split(`/${language}/`)[1];
        setHashId(`/${language}/${body}`);
      }
    }
  }, [hashTrig, language]);

  const pseudoElements = (
    <li>
      <div
        style={{
          background: stylingOptions?.bgSubmenuColor,
          left: '100%',
          width: '1000%',
        }}
      />
      <div
        style={{
          background: stylingOptions?.bgSubmenuColor,
          right: '100%',
          width: '1000%',
        }}
      />
    </li>
  );

  const fontWeight = stylingOptions?.fontWeight
    ? `font-${stylingOptions?.fontWeight}`
    : 'font-bold';

  function createMenu(start: number, end: number, menuType: 'hover' | 'expanded') {
    return menuItems?.slice(start, end).map((item, key) => {
      if (menuType === 'hover') {
        return (
          <HoverLi
            activePageId={activePageId}
            key={key}
            theKey={key}
            menuItem={item}
            inlineStyles={{
              top: 104 + 'px',
              background: stylingOptions?.bgSubmenuColor || '',
            }}
            activeHeader={activeHeader}
            pseudoElements={pseudoElements}
            paddingClasses={stylingOptions?.paddingClasses}
            bgColor={stylingOptions?.bgSubmenuColor || ''}
            underlineActive={stylingOptions?.underlineActive}
            underlineColor={stylingOptions?.underlineColor}
            underlineSize={stylingOptions?.underlineSize}
            itemActiveClasses={stylingOptions?.itemActiveClasses}
            itemActiveBgColor={stylingOptions?.itemActiveBgColor}
            itemActiveTextColor={stylingOptions?.itemActiveTextColor}
            headerActiveStateTextColor={stylingOptions?.headerActiveStateTextColor}
            currentPage={currentPage ?? ''}
            currentActiveNav={activeNav}
            setCurrentActiveNav={setActiveNav}
            language={language}
            t={t}
            hashId={hashId}
            setHashTrig={setHashTrig}
            hashTrig={hashTrig}
            fontWeight={fontWeight}
            fontCase={stylingOptions?.case}
            activeMenuItemDisplayType={stylingOptions?.activeMenuItemDisplayType}
            bgSubmenuBlur={stylingOptions?.bgSubmenuBlur}
            bgSubmenuTextColour={stylingOptions?.bgSubmenuTextColour}
            hasSubmenuDropShadowHidden={stylingOptions?.hasSubmenuDropShadowHidden}
          />
        );
      } else if (menuType === 'expanded') {
        // return <ExpandedLi key={key} menuItem={item} />;
        return null;
      } else {
        return null;
      }
    });
  }

  return (
    <ul
      data-testid="navDesktop"
      data-element-id="nav-desktop"
      className={`${fontWeight} flex size-full justify-end text-current ${stylingOptions?.case} ${stylingOptions?.fontSize}`}
    >
      {menuItems ? createMenu(0, 9, 'hover') : false}
    </ul>
  );
}

/**
 * List items that display submenu on hover
 */
interface HoverLiProps {
  activePageId?: number;
  activeHeader: (arg: boolean) => void;
  menuItem: InavMenuItem;
  inlineStyles?: Record<string, string>;
  theKey: number;
  bgColor: string;
  pseudoElements: React.ReactNode;
  underlineActive?: boolean;
  underlineColor?: string;
  underlineSize?: string;
  itemActiveClasses?: string;
  itemActiveBgColor?: string;
  itemActiveTextColor?: string;
  headerActiveStateTextColor?: string;
  paddingClasses?: string;
  currentPage: string;
  currentActiveNav: string | null;
  setCurrentActiveNav: (activeNav: string | null) => void;
  language?: string;
  t: (key: string, interpolation?: Record<string, string>) => string;
  hashId?: string | null;
  setHashTrig?: (hash: boolean) => void;
  hashTrig?: boolean;
  fontWeight?: string;
  fontCase?: string;
  activeMenuItemDisplayType?: string;
  bgSubmenuBlur?: number;
  bgSubmenuTextColour?: string;
  hasSubmenuDropShadowHidden?: boolean;
}

function HoverLi({
  activePageId,
  activeHeader,
  menuItem,
  inlineStyles,
  theKey,
  pseudoElements,
  bgColor,
  underlineActive,
  underlineColor,
  underlineSize,
  itemActiveClasses,
  itemActiveBgColor,
  itemActiveTextColor,
  headerActiveStateTextColor,
  paddingClasses = 'px-2 2xl:px-3 py-2',
  currentPage,
  currentActiveNav,
  setCurrentActiveNav,
  language,
  t,
  hashId,
  setHashTrig,
  hashTrig,
  fontWeight,
  fontCase,
  activeMenuItemDisplayType,
  bgSubmenuBlur,
  bgSubmenuTextColour,
  hasSubmenuDropShadowHidden,
}: HoverLiProps) {
  /* submenu state */
  const [liHover, setLiHover] = useState(false);
  const [subLiHover, setSubLiHover] = useState('');
  const [isTab, setIsTab] = useState(false);
  const [isOutOfViewport, setIsOutOfViewport] = useState(false);
  const strippedMenuItem = {
    ...menuItem,
    childItems: null,
    label: t('addOverview', { item: menuItem?.label || '' }),
    label_noTx: t('addOverview', { item: menuItem?.label_noTx || '' }),
  };
  const isOpen = currentActiveNav === menuItem?.label;
  const childItems = menuItem?.childItems?.length ? [strippedMenuItem, ...menuItem.childItems] : [];
  const urlArray = menuItem?.url?.split('/');
  const active =
    currentPage === urlArray?.[urlArray?.length || 2 - 2] ||
    menuItem?.childItems?.some((c) => c?.url?.split('/')?.slice(-2)?.[0] === currentPage)
      ? true
      : false;
  const activeSub =
    menuItem?.childItems
      ?.filter((c) => c?.url?.split('/')?.slice(-2)?.[0] === currentPage)?.[0]
      ?.label?.replace('&#038;', '&') || '';
  const activeClasses =
    isHexOrRgb(itemActiveBgColor || '') || isHexOrRgb(itemActiveTextColor || '')
      ? itemActiveBgColor + ' ' + itemActiveTextColor
      : '';
  const activeBgColor = isHexOrRgb(itemActiveBgColor || '') ? itemActiveBgColor : undefined;
  const activeTextColor = isHexOrRgb(itemActiveTextColor || '') ? itemActiveTextColor : undefined;
  let isNested = false;

  isNested = checkIfNested(menuItem, activePageId);
  // here
  const itemUnderline =
    underlineActive !== false && activeMenuItemDisplayType !== 'Background Colour' ? (
      <div
        data-testid={underlineActive}
        data-element-id="nav-underline-bar"
        className={cx(
          `bg-secondary absolute inset-x-0 bottom-0 m-auto translate-y-2 transition-all duration-300 ease-in-out ${
            underlineSize ?? 'h-1'
          }`,
          {
            'opacity-100 w-full': liHover || isNested,
            'opacity-0 w-4/5': !liHover && !isNested,
          }
        )}
        style={{
          backgroundColor: underlineColor,
        }}
      />
    ) : (
      ''
    );

  const menuItemText = menuItem?.label?.replace('&#038;', '&');
  const menuItemTextNoTx = menuItem?.label_noTx?.replace('&#038;', '&');
  const subMenu = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function checkIsOutOfViewport() {
        const submenuOffsetLeft = subMenu?.current?.parentElement?.offsetLeft;
        const submenuWidth = subMenu?.current?.clientWidth;
        const windowWidth = window?.innerWidth;
        setIsOutOfViewport(
          elementWidthExceedsViewportBoundary(submenuWidth, submenuOffsetLeft, windowWidth)
        );
      }

      checkIsOutOfViewport();

      const resizeObserver = new ResizeObserver((_entries) => {
        checkIsOutOfViewport();
      });

      window.addEventListener('resize', checkIsOutOfViewport);
      return () => {
        resizeObserver.disconnect();
        window.removeEventListener('resize', checkIsOutOfViewport);
      };
    }
  }, [subMenu, isOpen]);

  return (
    <li
      data-testid="navListItem"
      className={`relative flex h-full items-center ${paddingClasses} leading-tight`}
      onMouseEnter={() => {
        activeHeader?.(true);
        setLiHover(true);
        setIsTab(false);
      }}
      onMouseLeave={() => {
        setLiHover(false);
        if (!currentActiveNav) {
          activeHeader?.(false);
        }
        setIsTab(false);
      }}
      onFocus={() => {
        activeHeader?.(true);
        setLiHover(true);
        setIsTab(true);
      }}
      onBlur={() => {
        setLiHover(false);
      }}
    >
      <div
        className={
          menuItem?.pageId === activePageId && !childItems?.length ? 'pointer-events-none' : ''
        }
      >
        {childItems?.length > 0 ? (
          <button
            data-testid="desktopMainNavItem"
            data-cy={'desktop-top-level-button-' + menuItem?.label}
            onClick={() => {
              setIsTab(false);
              setTimeout(() => {
                if (isOpen) {
                  setCurrentActiveNav(null);
                  setTimeout(() => {
                    setIsTab(true);
                  }, 300);
                } else {
                  setCurrentActiveNav(menuItem?.label || null);
                }
              }, 0);
            }}
            aria-expanded={isOpen}
            className={cx(
              `${fontWeight} relative whitespace-nowrap ${fontCase} flex items-center`,
              active || liHover ? itemActiveBgColor : '',
              active || liHover ? itemActiveTextColor : '',
              itemActiveClasses,
              active ? activeClasses : '',
              {
                OneLinkNoTx: menuItem?.noTx,
              }
            )}
          >
            {menuItem?.noTx ? menuItemTextNoTx : menuItemText}
            {itemUnderline}
            <div
              className={`ml-2 w-4 transition-all origin-center flex justify-center items-center ${
                isOpen ? 'rotate-180' : ''
              }`}
            >
              <Icon name="arrowhead-down" size="sm" />
            </div>
          </button>
        ) : (
          <Link
            href={
              isRelativeUrl(menuItem?.url) ? `/${language}${menuItem?.url}` : menuItem?.url || ''
            }
            target={isRelativeUrl(menuItem?.url) ? '' : '_blank'}
            data-testid="desktopMainNavItem"
            className={cx(
              'relative whitespace-nowrap',
              active || liHover ? itemActiveBgColor : '',
              active || liHover ? itemActiveTextColor : '',
              itemActiveClasses,
              active ? activeClasses : ''
            )}
            style={{
              backgroundColor: liHover ? activeBgColor : undefined,
              color: liHover ? activeTextColor : undefined,
            }}
          >
            <>
              {menuItem?.noTx ? menuItemTextNoTx : menuItemText}
              {itemUnderline}
            </>
          </Link>
        )}
      </div>
      <ul
        ref={subMenu}
        data-testid={'nav-dropdown-menu-' + theKey}
        data-cy={childItems?.length > 0 ? 'subMenu' : null}
        className={cx(
          isOpen ? 'max-h-[600px]' : isTab ? 'hidden max-h-0' : 'max-h-0',
          'absolute z-50 overflow-hidden px-5 transition-all duration-300 ease-in-out lg:w-max',
          isOutOfViewport ? 'right-0' : 'left-0',
          hasSubmenuDropShadowHidden ? 'shadow-none' : 'shadow-lg'
        )}
        style={{
          color:
            !liHover && bgColor?.length !== 0
              ? bgSubmenuTextColour || headerActiveStateTextColor
              : bgSubmenuTextColour || '',
          backdropFilter: bgColor && bgSubmenuBlur && `blur(${bgSubmenuBlur}px)`,
          ...inlineStyles,
        }}
      >
        {pseudoElements}
        {childItems?.map((item, key) => {
          return (
            <SubNavItems
              key={key}
              slug={isRelativeUrl(item.url) ? `/${language}${item.url}` : item.url}
              text={item?.label?.replace('&#038;', '&')}
              textNoTx={item?.label_noTx?.replace('&#038;', '&')}
              underlineActive={underlineActive}
              underlineColor={underlineColor}
              underlineSize={underlineSize}
              itemActiveBgColor={itemActiveBgColor}
              itemActiveTextColor={itemActiveTextColor}
              currentPage={currentPage}
              subLiHover={subLiHover}
              setSubLiHover={setSubLiHover}
              item={item}
              hashId={hashId}
              setHashTrig={setHashTrig}
              hashTrig={hashTrig}
              active={activeSub}
              activePageId={activePageId}
              setCurrentActiveNav={setCurrentActiveNav}
              randInt={makeRandomInt()}
              activeMenuItemDisplayType={activeMenuItemDisplayType}
            />
          );
        })}
      </ul>
    </li>
  );
}

/**
 * Submenu List Items
 */

interface SubMenuProps {
  slug?: string;
  text?: string;
  textNoTx?: string;
  underlineActive?: boolean;
  underlineColor?: string;
  underlineSize?: string;
  itemActiveBgColor?: string;
  itemActiveTextColor?: string;
  currentPage?: string;
  subLiHover?: string;
  setSubLiHover?: (v: string) => void;
  item?: InavMenuItem | null;
  hashId?: string | null;
  setHashTrig?: (hash: boolean) => void;
  hashTrig?: boolean;
  active?: string;
  activePageId?: number;
  setCurrentActiveNav?: (activeNav: string | null) => void;
  randInt?: string;
  activeMenuItemDisplayType?: string;
}

function SubNavItems({
  slug,
  text,
  textNoTx,
  underlineActive,
  underlineColor,
  underlineSize,
  itemActiveBgColor,
  itemActiveTextColor,
  currentPage,
  subLiHover,
  setSubLiHover,
  item,
  hashId,
  setHashTrig,
  hashTrig,
  active,
  activePageId,
  setCurrentActiveNav,
  randInt,
  activeMenuItemDisplayType,
}: SubMenuProps) {
  const [liHover, setLiHover] = useState(false);
  if (!slug) {
    return null;
  }
  const children = item?.childItems;
  const activeClasses =
    isHexOrRgb(itemActiveBgColor || '') || isHexOrRgb(itemActiveTextColor || '')
      ? itemActiveBgColor + ' ' + itemActiveTextColor
      : '';
  const activeBgColor = isHexOrRgb(itemActiveBgColor || '') ? itemActiveBgColor : undefined;
  const activeTextColor = isHexOrRgb(itemActiveTextColor || '') ? itemActiveTextColor : undefined;

  const isNested = checkIfNested(item, activePageId);

  const itemUnderlineSub =
    underlineActive !== false && activeMenuItemDisplayType !== 'Background Colour' ? (
      <div
        className={cx(
          `bg-secondary m-auto transition-all duration-300 ease-in-out ${
            underlineSize ?? 'h-1'
          } z-10`,
          {
            'opacity-100 w-full':
              isNested ||
              subLiHover === slug ||
              hashId === slug?.split('#')[slug?.split('#').length - 1],
            'opacity-0 w-4/5':
              !isNested &&
              subLiHover !== slug &&
              hashId !== slug?.split('#')[slug?.split('#').length - 1],
          }
        )}
        style={{
          backgroundColor: underlineColor,
        }}
      />
    ) : (
      ''
    );

  const triggerHashChange = () => {
    if (slug.includes('#')) {
      setHashTrig(!hashTrig);
    }
  };

  return (
    <>
      <li
        data-testid="subMenuItem"
        className={cx(
          'w-auto py-2.5 pl-3 pr-5 font-normal leading-tight transition duration-200',
          item?.pageId === activePageId ? 'pointer-events-none' : '',
          active === text || liHover ? itemActiveBgColor : '',
          active === text || liHover ? itemActiveTextColor : '',
          activeClasses
        )}
        onMouseEnter={() => {
          setSubLiHover(slug);
          setLiHover(true);
        }}
        onMouseLeave={() => {
          setSubLiHover('');
          setLiHover(false);
        }}
        onFocus={() => {
          setSubLiHover(slug);
          setLiHover(true);
        }}
        onBlur={(e) => {
          setSubLiHover('');
          setLiHover(false);
          const nextTarget = e?.relatedTarget as HTMLElement;
          if (
            !nextTarget?.id?.includes('subMenuItem') &&
            !nextTarget?.id?.includes('childMenuItem')
          ) {
            setCurrentActiveNav(null);
          }
        }}
        onClick={triggerHashChange}
        style={{
          backgroundColor: liHover ? activeBgColor : undefined,
          color: liHover ? activeTextColor : undefined,
        }}
      >
        <Link
          suppressHydrationWarning
          href={slug}
          id={`subMenuItem-${slugify(text || '')}-${randInt}`}
          target={isRelativeUrl(slug) ? '' : '_blank'}
          className={cx('inline-block cursor-pointer', {
            OneLinkNoTx: item?.noTx,
          })}
        >
          <>
            {item?.noTx ? textNoTx : text}
            {itemUnderlineSub}
          </>
        </Link>
      </li>
      {children?.map((item, key) => {
        const itemUnderlineNested =
          underlineActive !== false ? (
            <div
              className={cx(
                `bg-secondary m-auto transition-all duration-300 ease-in-out ${
                  underlineSize ?? 'h-1'
                } z-10`,
                {
                  'opacity-100 w-full':
                    subLiHover === item.url ||
                    currentPage === item?.url?.split('/')[item?.url?.split('/')?.length || 2 - 2]
                      ? true
                      : false,
                  'opacity-0 w-4/5':
                    subLiHover !== item.url &&
                    currentPage !== item?.url?.split('/')[item?.url?.split('/')?.length || 2 - 2]
                      ? true
                      : false,
                }
              )}
              style={{
                backgroundColor: underlineColor,
              }}
            />
          ) : (
            ''
          );

        const menuItemText = item?.label?.replace('&#038;', '&');
        const menuItemTextNoTx = item?.label_noTx?.replace('&#038;', '&');

        return (
          <li key={key}>
            <ul
              className="w-auto py-1.5 pl-9 pr-5 font-normal leading-tight transition duration-200"
              onMouseEnter={() => {
                setSubLiHover(item.url);
              }}
              onMouseLeave={() => {
                setSubLiHover('');
              }}
              onFocus={() => {
                setSubLiHover(item.url);
              }}
              onBlur={(e) => {
                setSubLiHover('');
                const nextTarget = e?.relatedTarget as HTMLElement;
                if (
                  !nextTarget?.id?.includes('subMenuItem') &&
                  !nextTarget?.id?.includes('childMenuItem')
                ) {
                  setCurrentActiveNav(null);
                }
              }}
            >
              <li
                data-testid="childMenuItems"
                className={item?.pageId === activePageId ? 'pointer-events-none' : ''}
              >
                <Link
                  href={`/${slug.split('/')[1]}${item.url}`}
                  className={cx('inline-block cursor-pointer', {
                    OneLinkNoTx: item?.noTx,
                  })}
                  id={`childMenuItem-${slugify(item?.label || '')}-${randInt}`}
                  suppressHydrationWarning
                >
                  <>
                    {item?.noTx ? menuItemTextNoTx : menuItemText}
                    {itemUnderlineNested}
                  </>
                </Link>
              </li>
            </ul>
          </li>
        );
      })}
    </>
  );
}
