export function RoomPlan({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <path
            d="M20,4.625h-2.0264c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h1.6514v13.25h-8.25V17
          c0-0.2071-0.1679-0.375-0.375-0.375S10.625,16.7929,10.625,17v1.625h-6.25v-6.25h6.25V14c0,0.2071,0.1679,0.375,0.375,0.375
          s0.375-0.1679,0.375-0.375v-4c0-0.2071-0.1679-0.375-0.375-0.375S10.625,9.7929,10.625,10v1.625h-6.25v-6.25h6.25V7
          c0,0.2071,0.1679,0.375,0.375,0.375S11.375,7.2071,11.375,7V5.375H15c0.2071,0,0.375-0.1679,0.375-0.375S15.2071,4.625,15,4.625H4
          C3.7929,4.6251,3.6251,4.7929,3.625,5v14C3.6251,19.2071,3.7929,19.3749,4,19.375h16c0.2071-0.0001,0.3749-0.1679,0.375-0.375V5
          C20.3749,4.7929,20.2071,4.6251,20,4.625z"
          />
        </g>
      </g>
    </svg>
  );
}
