import {
  GetLanguageSelectorHotelDataDocument,
  GetLanguageSelectorOneLinkConfigDocument,
} from './generated/queries';

export * from './generated/queries';
export * from './generated/types';

export const GET_HOTEL_LANGUAGE_SELECTOR_DATA_ORIGINAL_OP_NAME =
  GetLanguageSelectorHotelDataDocument.originalOpName;
export const GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME =
  GetLanguageSelectorOneLinkConfigDocument.originalOpName;

export const LANGUAGE_SELECTOR_ORIGINAL_OP_NAMES = [
  GET_HOTEL_LANGUAGE_SELECTOR_DATA_ORIGINAL_OP_NAME,
  GET_LANGUAGE_SELECTOR_ONE_LINK_CONFIG_DOCUMENT_ORIGINAL_OP_NAME,
];
