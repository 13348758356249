import gql from 'graphql-tag-transform.macro';

export const TwoTwoMediaCopy = gql`
  fragment TwoTwoMediaCopy on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy {
      fieldGroupName
      reverseDirection
      column1 {
        title
        title_noTx: title
        copy
        image {
          altText
          sourceUrl
        }
        buttons {
          buttonStyle
          link {
            target
            title
            url
          }
        }
      }
      column2 {
        title
        title_noTx: title
        copy
        image {
          altText
          sourceUrl
        }
        buttons {
          buttonStyle
          link {
            target
            title
            url
          }
        }
      }
      imageColumn1AutoPosition
      imageColumn1CropHeight
      imageColumn1CropType
      imageColumn1CropWidth
      imageColumn1EnableCropping
      imageColumn1XPosition
      imageColumn1XPositionAdvanced
      imageColumn1YPosition
      imageColumn1YPositionAdvanced
      imageColumn2AutoPosition
      imageColumn2CropHeight
      imageColumn2CropType
      imageColumn2CropWidth
      imageColumn2EnableCropping
      imageColumn2XPosition
      imageColumn2XPositionAdvanced
      imageColumn2YPosition
      imageColumn2YPositionAdvanced
      twoTwoMediaCopySettings {
        fieldGroupName
        horizontalDirection
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
