import { appliedCloudinaryParams } from '@curated-property/utils';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_merge, GIS_Padder } from '../functions/global-instance-styles';
import cx from 'classnames';
import { useState } from 'react';
import { Spinner } from '@dx-ui/osc-spinner';

interface CventProps {
  cventCode: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function Cvent(props: CventProps) {
  const [isLoaded, setLoaded] = useState(false);

  const inlineStyles = GIS_merge(props?.globalStyles, props?.instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  return (
    <div
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || null,
        backgroundPosition: inlineStyles?.componentBackgroundPosition || null,
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
      }}
      data-element-id="icon-grid-wrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || null,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
        }}
      >
        <div
          className={cx('', {
            'flex justify-center': !isLoaded,
            hidden: isLoaded,
          })}
        >
          <Spinner size="xl" />
        </div>
        <iframe
          data-testid="social-table-iframe"
          src={`https://connect.socialtables.com/embedded-microsite?hotel_code=${props.cventCode}`}
          height="500px"
          allowFullScreen={false}
          title="Interactive floor plans"
          className={cx('mx-auto w-full max-w-[1200px]', {
            hidden: !isLoaded,
          })}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </div>
    </div>
  );
}
