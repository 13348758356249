const ForbesFiveStarLogo = ({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.6952 252.6237">
      <defs>
        <style>{`.forbes-cls-2{font-size:18px;font-family:Roboto-Black, Roboto;font-weight:800;}`}</style>
      </defs>
      <g fill={fillColor}>
        <g>
          <path d="M231.2626,189.7089a30.8153,30.8153,0,0,0-12.1306-2.1907c-10.0876,0-16.2158,5.9134-16.2158,12.826,0,7.1077,4.6084,9.9053,10.9685,12.1726,6.8437,2.4424,8.6494,4.3542,8.6494,7.5219a6.28,6.28,0,0,1-6.6832,6.3058c-5.0649,0-8.8889-2.98-11.8246-11.1954l-2.0625.5354.36,11.743a42.1023,42.1023,0,0,0,14.1854,2.4919c10.6874,0,17.1857-5.22,17.1857-13.6946,0-5.6891-2.7779-8.8075-9.7893-11.5286-7.5765-2.9309-10.2086-4.7837-10.2086-8.2869,0-3.5377,2.3436-5.9751,5.5853-5.9751,4.8749,0,8.1906,3.0245,10.3764,10.1124l2.0576-.5328Z" />
          <path d="M142.355,187.9721a21.1919,21.1919,0,0,0-9.75,2.5089c.0346-8.7308.1433-16.3515.3529-19.2307l-17.9538,3.4688v1.6133l1.7479.2319c2.5053.338,3.4673,1.806,3.7646,5.1513.5636,6.397.4835,40.768,0,46.2793a70.7935,70.7935,0,0,0,15.3006,1.922c14.25,0,22.8252-8.8124,22.8252-22.7414,0-11.1757-6.957-19.2034-16.2875-19.2034M136.1035,227.07a19.7928,19.7928,0,0,1-3.0591-.2961c-.2862-3.8682-.459-19.7609-.4416-33.6578a11.6977,11.6977,0,0,1,4.1866-.6884c5.9036,0,9.2069,6.8067,9.2266,15.397.02,10.9438-4.07,19.2453-9.9125,19.2453" />
          <path d="M47.2625,172.5185H.047v2.2671l2.5139.35c3.6019.5206,4.9858,2.7458,5.4127,7.8329a279.2837,279.2837,0,0,1,0,35.5328c-.4269,5.0872-1.8108,7.3148-5.4127,7.8479l-2.5139.338v2.2746H30.3917v-2.2746l-3.1874-.338c-3.5982-.5331-4.9785-2.7607-5.4042-7.8479-.291-3.4539-.48-9.2981-.5439-16.1443l6.254.2024c4.18.1356,5.9246,3.2293,6.9275,7.8081l2.3424.0074V191.273H34.4377c-1.0029,4.5813-2.747,7.6725-6.9275,7.8132l-6.2676.1973c-.048-9.0195.1184-17.6864.5575-22.8892h9.3156c7.2754,0,11.1658,4.6183,13.6069,12.7816l2.7991-.7844Z" />
          <path d="M62.604,187.5207c13.4255,0,20.0916,9.0958,20.0916,21.0907,0,11.7087-7.5295,21.3055-20.949,21.3055s-20.083-9.0961-20.083-21.0933c0-11.7061,7.5219-21.3029,20.94-21.3029m-.6932,2.6c-5.9347,0-7.595,8.02-7.595,18.5991,0,10.29,2.6286,18.5965,8.1338,18.5965,5.9259,0,7.5887-8.02,7.5887-18.5965,0-10.29-2.6248-18.5991-8.1275-18.5991" />
          <path d="M161.9335,209.0876c-.01-11.0968,6.9917-21.5424,20.8318-21.5424,11.2547,0,16.6278,8.3412,16.6872,19.26H174.3452c-.2786,9.9,4.7343,17.1783,14.3335,17.1783a13.1127,13.1127,0,0,0,9.0516-3.0024l1.1793,1.4134c-2.7878,3.8586-8.6,7.5222-16.31,7.5222-12.101,0-20.6541-8.5681-20.6665-20.8293m12.5029-5.1043,12.5919-.2665c.0642-5.5755-.8288-13.5392-5.3584-13.5392-4.5592,0-7.12,7.5368-7.2335,13.8057" />
          <path d="M117.72,188.658c-4.53-2.7064-12.7869-1.426-16.9722,8.1929l.211-9.3648-17.8935,3.4711v1.616l1.7441.22c2.498.3478,3.4638,1.8108,3.7659,5.156a174.3447,174.3447,0,0,1,0,23.3629c-.3021,3.3354-1.2679,4.8085-3.7659,5.1513l-1.7441.2268v2.2674h24.916V226.69l-3.2516-.2268c-2.5053-.3428-3.47-1.8159-3.7611-5.1513a177.403,177.403,0,0,1-.1233-21.6975c1.199-1.9786,7.0521-3.575,12.4881.0689Z" />
          <path d="M13.5367,241.9313H8.0759v10.606H5.4843v-10.606H0v-1.7169H13.5367Z" />
          <path d="M22.26,252.5373V240.2144H29.411c2.963,0,5.0919,2.03,5.0919,4.009A3.91,3.91,0,0,1,31.286,247.85l3.9114,4.6875H32.2581L28.6475,248.25H24.8531v4.2878Zm2.593-6.0072h4.5356a2.3541,2.3541,0,0,0,2.4758-2.3067,2.5193,2.5193,0,0,0-2.6152-2.2921H24.8531Z" />
          <path d="M43.3435,252.5373l6.6181-12.3229h2.1278l6.5956,12.3229H55.9319l-1.76-3.35H47.8324l-1.712,3.35ZM51.0261,242.38l-2.7311,5.3658h5.3683Z" />
          <path d="M67.3407,240.2144l4.976,9.8754,4.928-9.8754h2.7309l-6.55,12.3229H71.2041l-6.618-12.3229Z" />
          <path d="M100.6383,250.82v1.7171H89.3715V240.2144h11.06v1.7169H91.9619v3.5057h7.3358v1.616H91.9619V250.82Z" />
          <path d="M110.8212,252.5373V240.2144h2.5929V250.82H122.23v1.7171Z" />
          <path d="M148.7275,251.0076a7.2435,7.2435,0,0,1-4.7,1.6161c-4.2777,0-7.6331-3.0717-7.6331-6.301,0-3.3478,3.3059-6.1972,7.7268-6.1972,3.079,0,5.1859,1.0757,6.2715,2.6915l-1.9885,1.0757a5.0227,5.0227,0,0,0-4.3519-2.0132,4.7011,4.7011,0,0,0-5.023,4.495c0,2.482,2.2451,4.4975,5.2079,4.4975a6.4709,6.4709,0,0,0,4.49-1.8255v-1.3h-3.4491v-1.4407h5.6224v6.2318h-2.1733Z" />
          <path d="M172.6307,246.4609v-6.2465h2.6152v6.2465c0,3.37-2.0355,6.1628-7.1029,6.1628-5.23,0-7.0829-2.9679-7.0829-6.1628v-6.2465h2.593v6.2465c0,2.2574,1.0878,4.4111,4.49,4.4111C171.59,250.872,172.6307,248.6665,172.6307,246.4609Z" />
          <path d="M186.0762,252.5373V240.2291h2.5927v12.3082Z" />
          <path d="M199.7781,252.5373V240.2144h5.83c5.2968,0,8.0057,2.7408,8.0057,6.1429,0,3.7325-3.1457,6.18-8.0057,6.18Zm5.83-10.606h-3.2392V250.82h3.2392c3.4711,0,5.3658-1.9268,5.3658-4.4629C210.9735,243.7719,209.01,241.9313,205.6077,241.9313Z" />
          <path d="M233.6755,250.82v1.7171H222.4109V240.2144H233.468v1.7169h-8.4667v3.5057h7.337v1.616h-7.337V250.82Z" />
          <polygon points="62.108 80.007 79.246 92.468 72.702 72.3 89.847 59.848 68.651 59.848 62.108 39.69 55.552 59.848 34.356 59.848 51.498 72.3 44.96 92.468 62.108 80.007" />
          <polygon points="178.135 59.841 171.585 39.69 165.03 59.841 143.853 59.841 160.994 72.304 154.449 92.455 171.585 79.994 188.739 92.455 182.189 72.304 199.34 59.841 178.135 59.841" />
          <polygon points="89.571 124.216 83.027 104.063 76.478 124.216 55.29 124.216 72.433 136.68 65.891 156.83 83.027 144.367 100.175 156.828 93.628 136.68 110.78 124.216 89.571 124.216" />
          <polygon points="127.451 32.615 144.603 20.153 123.405 20.153 116.86 0 110.306 20.153 89.118 20.153 106.271 32.615 99.708 52.77 116.86 40.312 134.012 52.768 127.451 32.615" />
          <polygon points="178.35 124.216 157.153 124.216 150.603 104.058 144.058 124.216 122.851 124.216 140.012 136.667 133.46 156.835 150.603 144.372 167.759 156.835 161.209 136.667 178.35 124.216" />
          <text className="forbes-cls-2" transform="translate(96.0395 92.2016)">
            2023
          </text>
        </g>
      </g>
    </svg>
  );
};

export default ForbesFiveStarLogo;
