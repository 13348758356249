import { Hero, heroPropMapper } from '@curated-property/shared-components';
import { getGlobalStyles, getPageDetails, type PageProps, type PartialComponentMapping } from '../';

import type { HeroImageFlexProps } from '@curated-property/shared-components';
import { useTranslation } from 'next-i18next';

const overrides: PartialComponentMapping = {
  Page_Acfflexible_FlexibleComponents_Masthead: (
    flex: HeroImageFlexProps,
    {
      dxGqlInfo,
      wpPageInfo,
      wpThemeSettings,
      ctyhocn,
      associatedHotelInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const [t] = useTranslation();
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const oscBookButtonStyle =
      wpThemeSettings?.shopFormSettings?.shopFormSettings?.bookButtonStyleFromGlobal
        ?.shopFormBookGlobalButtonStyleButtonStyle || '';

    const heroProps = heroPropMapper(flex, globalStyles, t, oscBookButtonStyle);

    const pageDetails = getPageDetails(wpPageInfo);
    if (flex?.mastheadComponentSettings?.showHide) {
      return null;
    }

    return (
      <Hero
        {...heroProps}
        isShopFormInHeader={wpThemeSettings?.shopFormSettings?.shopFormSettings?.attachToHeader}
        key={key + (pageDetails?.slug ?? '')}
        componentInstance={key}
        pageDetails={pageDetails}
        bookingWidgetConfig={{
          ctyhocn,
          defaultArrivalDate: dxGqlInfo?.hotel?.display?.resEnabledDate,
          gmtHours: dxGqlInfo?.hotel?.localization?.gmtHours,
          brandCode: dxGqlInfo?.hotel?.brandCode || 'HI',
          resEnabled: dxGqlInfo?.hotel?.display?.resEnabled,
          currency: dxGqlInfo?.hotel?.localization?.currencyCode,
          associatedHotels: associatedHotelInfo,
          maxRooms: 7,
          groupBooking: {
            url: 'https://thenomadhotellondon.tripleseat.com/booking_request/25292',
          },
        }}
      />
    );
  },
};
export default overrides;
