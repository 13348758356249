import type * as React from 'react';
import cx from 'classnames';

import { type PatchWorkGridRowType, PatchworkGridRow } from './patchwork-grid-row';
import { HeadingLevel, HeadingLevelProvider } from '@dx-ui/osc-heading-level';
import { useMemo } from 'react';

export type PatchworkGridType = {
  /** Headline for Patchwork Grid */
  listHeadline?: string;
  /** Long Description sits underneath Headline */
  listDescription?: string;
  /** Flip the direction of the Image / Copy sections */
  isReverse?: boolean;
  /** An array of PatchWorkRowType objects */
  rows: PatchWorkGridRowType[];
  /** Theme variable for dark/light theming */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Add Tailwind classes to root element */
  className?: string;
  /** Add Tailwind classes to the row headline */
  rowHeadlineClassName?: string;
  /** Use getAspectRatioUrl for Images */
  shouldUseGetAspectRatio?: boolean;
  /** Enable scrolling animations */
  isAnimated?: boolean;
  /** Render custom content instead of heading / description / link */
  customContent?: React.ReactNode;
};

export const PatchworkGrid: React.FC<PatchworkGridType> = ({
  listHeadline,
  listDescription,
  isReverse = false,
  rows,
  brandComponentTheme,
  className = '',
  rowHeadlineClassName,
  shouldUseGetAspectRatio,
  isAnimated = false,
  customContent,
}) => {
  // Count the total number of images in the Patchwork Grid
  const imageCount = rows.reduce((total, row) => {
    return total + (Array.isArray(row.images) ? row.images.length : 0);
  }, 0);

  // Get the first non-empty headline from the rows
  const patchWorkHeadline = useMemo(() => {
    const rowWithHeadline = rows.find((row) => row.headline);
    return rowWithHeadline ? rowWithHeadline.headline : undefined;
  }, [rows]);

  let imageIndex = 1;
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <section
      className={cx('relative', {
        'bg-bg-transparent': !isLight && !isDark,
        'bg-bg-light': isLight,
        'bg-bg-dark': isDark,
      })}
    >
      <div
        className={cx('container overflow-hidden', {
          [className]: !!className,
        })}
        data-testid="patchworkGrid"
      >
        {listHeadline ? (
          <HeadingLevel
            headingLevelFallback={2}
            className={cx(
              'heading-2xl patchwork-grid-headline-text mb-2 py-6 lg:heading-4xl xl:mb-4',
              {
                '!text-text-inverse': isDark,
                'patchwork-grid-headline-text-light': isLight,
              }
            )}
          >
            {listHeadline}
          </HeadingLevel>
        ) : null}
        {listDescription ? (
          <p
            className={cx(
              'mb-4 py-6 text-base font-normal text-text xl:text-xl',
              'patchwork-grid-description-text',
              {
                'text-text-inverse': isDark,
                'patchwork-grid-description-text-light': isLight,
              }
            )}
          >
            {listDescription}
          </p>
        ) : null}
        {rows?.map((row, i) => {
          // Keep track of the current row's image count
          const numImagesInRow = Array.isArray(row.images) ? row.images.length : 0;
          const currentImageIndex = imageIndex;
          imageIndex += numImagesInRow;
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key --- Use i to guard against missing or duplicate headlines
              key={`${row.headline}${i}`}
              className="grid grid-cols-1 gap-y-4 pb-4 sm:grid-cols-3 sm:gap-x-4"
            >
              <HeadingLevelProvider
                isEnabled
                shouldIncreaseLevel={!!listHeadline}
                level={!listHeadline ? 2 : 3}
              >
                <PatchworkGridRow
                  {...row}
                  isReverse={isReverse}
                  brandComponentTheme={brandComponentTheme}
                  isAnimated={isAnimated}
                  rowHeadlineClassName={rowHeadlineClassName}
                  shouldUseGetAspectRatio={shouldUseGetAspectRatio}
                  customContent={customContent}
                  numImages={imageCount}
                  startingImageIndex={currentImageIndex}
                  patchWorkHeadline={patchWorkHeadline}
                />
              </HeadingLevelProvider>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PatchworkGrid;
