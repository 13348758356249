import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useTranslation } from 'next-i18next';

export const GeocodeFailModal = ({
  isGeocodeError,
  setIsGeoCodeError,
  dialogTrigger,
}: {
  isGeocodeError: boolean;
  setIsGeoCodeError: React.Dispatch<React.SetStateAction<boolean>>;
  dialogTrigger?: React.ReactNode;
}) => {
  const { t } = useTranslation('osc-shop-form');

  return (
    <Dialog
      isOpen={isGeocodeError}
      size="sm"
      onDismiss={() => setIsGeoCodeError(false)}
      title={t('geocodeFailModalTitle')}
      dialogTrigger={dialogTrigger}
    >
      <div className="pb-2 text-center">
        <div>{t('geocodeFailModalContent')}</div>
      </div>
      <div className="flex items-center justify-center space-x-2 py-2 rtl:space-x-reverse">
        <button
          className="btn btn-lg btn-primary"
          onClick={() => setIsGeoCodeError(false)}
          type="button"
        >
          {t('geocodeFailModalButton')}
        </button>
      </div>
    </Dialog>
  );
};
