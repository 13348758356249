export function Event({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M20.2656,9.7344l-2-2a.376.376,0,0,0-.5312,0,.9607.9607,0,0,1-1.4688,0,.9621.9621,0,0,1,0-1.4688.3778.3778,0,0,0,0-.5312l-2-2a.376.376,0,0,0-.5312,0l-10,10a.3755.3755,0,0,0-.001.5293c2.084,2.11,2.084,2.11,2.2656,2.1113H6a.3754.3754,0,0,0,.2656-.1094.9607.9607,0,0,1,1.4688,0,.9621.9621,0,0,1,0,1.4688.38.38,0,0,0-.1094.2666c.001.1816.001.1816,2.1113,2.2656a.376.376,0,0,0,.5293-.001l10-10A.3778.3778,0,0,0,20.2656,9.7344Zm-7.2651,6.7348-.7349-.7348a.3756.3756,0,0,0-.5312.5312l.7348.7349L9.998,19.4717c-.5224-.5176-1.164-1.1553-1.4941-1.4883A1.6941,1.6941,0,0,0,8.8271,17a1.7815,1.7815,0,0,0-.5615-1.2656,1.7133,1.7133,0,0,0-2.249-.2383c-.333-.33-.9707-.9717-1.4883-1.4941L7,11.5308l.7349.7348a.3756.3756,0,1,0,.5312-.5312L7.5308,11,14,4.53l1.4922,1.4922A1.6878,1.6878,0,0,0,15.1729,7a1.7815,1.7815,0,0,0,.5615,1.2656,1.71,1.71,0,0,0,2.2431.2422L19.47,10Z" />
          <path d="M9.2656,12.7344a.3756.3756,0,0,0-.5312.5312l2,2a.3756.3756,0,0,0,.5312-.5312Z" />
          <path d="M13.2656,8.7344a.376.376,0,0,0-.5312,0l-2,2a.3756.3756,0,0,0,.5312.5312l2-2A.3778.3778,0,0,0,13.2656,8.7344Z" />
          <path d="M14.7344,10.7344l-2,2a.3756.3756,0,0,0,.5312.5312l2-2a.3756.3756,0,0,0-.5312-.5312Z" />
        </g>
      </g>
    </svg>
  );
}
