export function SprayBottleAndFaceCovering({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M16.26,16.2119c-0.6262,0.5583-1.4273,0.8812-2.2657,0.9131c-0.8331-0.0315-1.6296-0.3507-2.2539-0.9033
                    c-0.1556-0.1368-0.3926-0.1215-0.5294,0.0341c-0.1368,0.1556-0.1215,0.3926,0.0341,0.5294
                    c0.005,0.0044,0.01,0.0086,0.0152,0.0127c0.7587,0.6657,1.7254,1.0465,2.7343,1.0771c1.0138-0.0312,1.9849-0.4157,2.7453-1.0869
                    c0.1626-0.1283,0.1904-0.3642,0.0621-0.5268c-0.1283-0.1626-0.3642-0.1904-0.5268-0.0621
                    C16.2701,16.2033,16.265,16.2075,16.26,16.2119z"
            />
            <path
              d="M11.8262,14.8379c1.3607,0.7182,2.9888,0.716,4.3476-0.0059c0.1834-0.096,0.2542-0.3224,0.1582-0.5058
                    c-0.096-0.1834-0.3224-0.2542-0.5058-0.1582c-1.1412,0.6079-2.5099,0.6094-3.6524,0.0039
                    c-0.1841-0.0934-0.4092-0.0227-0.5068,0.1592C11.5714,14.515,11.6426,14.7417,11.8262,14.8379z"
            />
            <path
              d="M21.375,13.9443c0-0.9658-0.7217-1.542-1.9307-1.542c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375
                    c1.1807,0,1.1807,0.5957,1.1807,0.792c-0.3219,0.8548-0.8173,1.6336-1.455,2.2875l-0.81-2.8343
                    c-0.0214-0.0762-0.0666-0.1436-0.129-0.1924c-1.1924-0.9878-2.683-1.5445-4.231-1.5801c-1.5459,0.0439-3.0335,0.5993-4.23,1.5791
                    c-0.0629,0.0489-0.1085,0.1167-0.13,0.1934l-0.81,2.8343c-0.6377-0.6539-1.1331-1.4327-1.455-2.2875
                    c0-0.1963,0-0.792,1.1807-0.792c0.2071,0,0.375-0.1679,0.375-0.375s-0.1679-0.375-0.375-0.375
                    c-1.209,0-1.9307,0.5762-1.9307,1.542c0,0.9384,1.6756,2.8427,2.0694,3.277C9.8092,19.1087,11.8093,20.2976,14,20.375
                    c2.1907-0.0774,4.1908-1.2663,5.3056-3.1537C19.6994,16.787,21.375,14.8827,21.375,13.9443z M14,19.625
                    c-1.8785-0.0851-3.5956-1.087-4.5938-2.6807l0.92-3.2226c1.0471-0.8338,2.3359-1.3063,3.6738-1.3467
                    c1.3402,0.027,2.6329,0.501,3.6729,1.3467l0.9218,3.2236C17.5985,18.5413,15.8797,19.5438,14,19.625z"
            />
            <path
              d="M8,18.625H4.375V14c-0.0341-0.8025,0.0989-1.6033,0.3906-2.3516C5.249,10.2782,5.4552,8.8256,5.3723,7.375H6.125V8
                    c-0.0026,1.6462,1.1882,3.0516,2.8125,3.3193C8.9582,11.3224,8.9791,11.324,9,11.3242c0.2072-0.0002,0.375-0.1683,0.3749-0.3755
                    c-0.0002-0.1828-0.1321-0.3389-0.3124-0.3696C7.7998,10.3717,6.8735,9.2796,6.875,8V7.375H7C7.2071,7.3749,7.3749,7.2071,7.375,7
                    C7.3753,6.655,7.655,6.3753,8,6.375h3C11.2071,6.3749,11.3749,6.2071,11.375,6V4C11.3749,3.7929,11.2071,3.6251,11,3.625H4.5
                    c-0.1181-0.0003-0.2294,0.0554-0.3,0.15l-1.5,2c-0.1121,0.1491-0.0976,0.3579,0.0342,0.49l1,1
                    C3.8047,7.3355,3.9003,7.3751,4,7.375h0.6224C4.7022,8.7392,4.511,10.1056,4.06,11.3955C3.7383,12.2248,3.5903,13.1112,3.625,14
                    v5C3.6251,19.2071,3.7929,19.3749,4,19.375h4c0.2071,0,0.375-0.1679,0.375-0.375S8.2071,18.625,8,18.625z M10.625,5.625h-1.25
                    v-1.25h1.25V5.625z M3.495,5.965l1.1924-1.59H8.625v1.25H8c-0.6147,0.0011-1.1544,0.409-1.3232,1H4.1553L3.495,5.965z"
            />
            <circle cx="13" cy="5" r="0.625" />
            <circle cx="15" cy="6" r="0.625" />
            <circle cx="15" cy="4" r="0.625" />
            <path
              d="M8.875,7.5c0-0.2071-0.1679-0.375-0.375-0.375S8.125,7.2929,8.125,7.5C8.1259,8.5351,8.9649,9.3741,10,9.375
                    c0.2071,0,0.375-0.1679,0.375-0.375S10.2071,8.625,10,8.625C9.379,8.6241,8.8759,8.121,8.875,7.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
