import { ArrowHead } from '@curated-property/icon-list';
import { useTranslation } from 'next-i18next';
import type { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import { HeadingStyle } from '../../includes/heading-style';
import type { OfferOffersCurated } from '../offers-curated';
import { OffersCuratedContext } from '../offers-curated';
import { TagButtonOffersCurated } from './tag';
import { sanitize } from '@curated-property/utils';

export interface TileOffersCuratedStyleProps {
  backgroundColor?: string;
  textColor?: string;
  bookButtonStyle?: string;
  termsButtonStyle?: string;
  termsBookButtonStyle?: string;
  additionalDataAccentColor?: string;
  arrowAccentColor?: string;
  hhrArrowAccentColor?: string;
}

export const defaultStyles: TileOffersCuratedStyleProps = {
  backgroundColor: '#f7f6f4',
  bookButtonStyle: 'primary',
  termsButtonStyle: 'primary-outline',
  termsBookButtonStyle: 'primary',
  textColor: '#000',
  additionalDataAccentColor: '#9ab9ad',
  arrowAccentColor: '#9ab9ad',
  hhrArrowAccentColor: '#002F61',
};

export interface TileOffersCuratedProps {
  id: number;
  offer: OfferOffersCurated;
  active: boolean;
  setActive: Dispatch<SetStateAction<number>>;
}

export function TileOffersCurated({ id, offer, active, setActive }: TileOffersCuratedProps) {
  const { t } = useTranslation();
  const contextData = useContext(OffersCuratedContext);
  const styles = contextData?.styles?.tiles;
  const additionalData = offer?.additionalData?.map((data) => (
    <div
      className="flex justify-between py-2"
      style={{ borderBottom: `1px solid ${styles?.additionalDataAccentColor}` }}
    >
      <strong>{data?.label}</strong>
      <span>{data?.copy}</span>
    </div>
  ));

  const arrowAccentColor = contextData?.enableHHR
    ? styles?.hhrArrowAccentColor
    : styles?.arrowAccentColor;

  const bookStyle = `btn-${styles?.bookButtonStyle}`;
  const termsStyle = `btn-${styles?.termsButtonStyle}`;

  return (
    <>
      <div
        data-offerid={offer?.id?.toString()}
        data-offer-type={offer?.type}
        key={`offers-curated-tile-id-${id}`}
        data-testid={`offers-curated-tile-id-${id}`}
        className="relative flex flex-col justify-between"
        style={{
          backgroundColor: styles?.backgroundColor,
          color: styles?.textColor,
        }}
      >
        <div>
          <img
            data-testid={`offers-curated-tile-id-${id}-img`}
            alt={offer.imageAlt || `image for offer - ${offer?.title}`}
            src={
              offer?.image ||
              'https://assets.hiltonstatic.com/images/c_fill,w_780,h_520,q_60,f_auto,g_center/v1583957952/no-image/no-image.png'
            }
            width={1024}
            height={1024}
            style={{ objectFit: 'cover', aspectRatio: '1' }}
          />
          <div className="p-4">
            {offer?.title && (
              <HeadingStyle
                textColorInline={styles?.textColor}
                className="font-bold"
                styledAs="h5"
                text={offer?.title}
              />
            )}
            <div className="my-4 flex flex-wrap gap-2">
              {offer?.tags?.map((tag) => (
                <TagButtonOffersCurated
                  key={tag?.id}
                  tag={tag}
                  disabled={
                    !(contextData.getActiveTagCount() <= 0 || contextData.isTagActive(tag?.id))
                  }
                />
              ))}
            </div>
            <p>{offer?.copy}</p>
            {offer?.additionalData instanceof Array && offer?.additionalData?.length > 0 ? (
              <div className="pt-4" style={{ color: styles?.additionalDataAccentColor }}>
                {' '}
                {additionalData}{' '}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className="mt-8 flex flex-wrap gap-2 p-4 pt-0">
            <a
              aria-label={offer?.CTA?.adaDescription}
              className={`btn ${bookStyle} p-2 text-center text-base xl:text-lg`}
              href={offer?.CTA?.url}
              target={offer?.CTA?.isNewWindow ? '_blank' : null}
              rel="noreferrer"
            >
              {offer?.CTA?.label || t('bookThisOffer')}
            </a>
            {offer?.terms ? (
              <button
                data-testid="terms-button"
                aria-expanded={active}
                className={`btn ${termsStyle} !flex gap-2 p-2 text-center text-base xl:text-lg`}
                onClick={() => {
                  setActive(id);
                }}
              >
                <span className="sr-only">{offer?.title} - </span>
                {t('termsAndConditions')}
                <ArrowHead className={`my-auto h-5 transition-all ${active ? 'rotate-180' : ''}`} />
              </button>
            ) : null}
          </div>
        </div>
        <div
          className="absolute bottom-0 right-4 size-0 border-[12px] border-b-[16px] border-t-0 border-transparent"
          style={{
            borderBottomColor: arrowAccentColor,
            opacity: active ? 1 : 0,
          }}
        />
      </div>
      <div
        data-testid="termsAndConditions"
        className="relative p-8 transition duration-300 md:col-span-2 2xl:col-span-3"
        style={{
          display: active ? 'block' : 'none',
          backgroundColor: styles?.backgroundColor,
          color: styles?.textColor,
        }}
      >
        <HeadingStyle text={t('offers.offerTermsAndConditions')} styledAs="h3" />
        <div className="py-8" dangerouslySetInnerHTML={{ __html: sanitize(offer?.terms) }} />
        <a
          aria-label={offer?.CTA?.adaDescription}
          className={`btn ${termsStyle} py-2`}
          href={offer?.CTA?.url}
          target={offer?.CTA?.isNewWindow ? '_blank' : null}
          rel="noreferrer"
        >
          {offer?.CTA?.label || t('bookThisOffer')}
        </a>
      </div>
    </>
  );
}
