import { useEffect, useState } from 'react';

const getHasUserModifiedFontDefaults = (maxSize: number) => {
  const { fontSize, wordSpacing, letterSpacing } = getComputedStyle(document.documentElement);
  const hasModifiedLetterSpacing = letterSpacing !== 'normal';
  const hasModifiedFontSize = parseInt(fontSize.split('px')[0] || '') > maxSize;
  const hasModifiedWordSpacing =
    wordSpacing !== 'normal' && parseInt(wordSpacing.split('px')[0] || '') > 0;
  if (hasModifiedFontSize || hasModifiedWordSpacing || hasModifiedLetterSpacing) {
    return true;
  }
  return false;
};

/* Default browser font size is 16px, function defaults to a max of 20px  */
export const useHasUserModifiedFontDefaults = (maxSize = 20) => {
  const [hasUserModifiedFonts, setHasUserModifiedFonts] = useState(false);
  useEffect(() => {
    setHasUserModifiedFonts(getHasUserModifiedFontDefaults(maxSize));
  }, [maxSize]);
  return hasUserModifiedFonts;
};
