import { createContext } from 'react';
import { initialContext } from './utils/constants';
import type { UserLocation } from './location-util';

export type ILoadable = {
  isLoading: boolean;
};

export type LocationState = {
  clear: () => void;
  lastUpdated?: Date;
} & ILoadable &
  UserLocation;

export const LocationContext = createContext<LocationState>(initialContext);
