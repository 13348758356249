import cx from 'classnames';
import type { useMenu } from '../helpers/use-control-menu';

export function ControlMenu(
  props: ReturnType<typeof useMenu> & Pick<React.ComponentProps<'div'>, 'children' | 'className'>
) {
  const { menuId, popupMenuRef, isOpen, children, className } = props;

  return (
    <div
      id={menuId}
      ref={popupMenuRef}
      className={cx(
        'border-border bg-bg absolute bottom-full start-0 w-full min-w-52 border-y shadow-md',
        {
          'motion-safe:animate-fade-in': isOpen,
          'motion-safe:animate-fade-out': !isOpen,
          hidden: !isOpen,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
