import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import type { MediaBlocksThreeTwoLeftOneRightInterface } from './interfaces';

export function MediaBlocksThreeTwoLeftOneRight(props: MediaBlocksThreeTwoLeftOneRightInterface) {
  return (
    <div className="grid size-full grid-cols-2" data-testid="media-blocks-three-two-left-one-right">
      <div
        className="grid-row-2 grid bg-green-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderRightWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <div
          className="relative flex items-center justify-center overflow-hidden bg-red-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderBottomWidth: `${props.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageTopLeft?.sourceUrl}
            alt={props?.imageTopLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageTopLeft?.sourceUrl,
                crop: props?.imageTopLeftCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
        <div
          className="relative flex items-center justify-center overflow-hidden bg-yellow-500"
          style={{
            borderColor: props?.imageInsetBorderColour,
            borderTopWidth: `${props.imageInsetBorderWidth}px`,
          }}
        >
          <CroppedImage
            layout="fill"
            src={props?.imageBottomLeft?.sourceUrl}
            alt={props?.imageBottomLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomLeft?.sourceUrl,
                crop: props?.imageBottomLeftCrop?.imageCrop11AddCrop && true,
                cropType: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.xPositionAdvanced,
                yPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.yPositionAdvanced,
                autoPosition: props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
      </div>
      <div
        className="relative flex items-center justify-center overflow-hidden bg-blue-500"
        style={{
          borderColor: props?.imageInsetBorderColour,
          borderLeftWidth: `${props.imageInsetBorderWidth}px`,
        }}
      >
        <CroppedImage
          layout="fill"
          src={props?.imageRight?.sourceUrl}
          alt={props?.imageRight?.altText}
          loader={() => {
            return customLoader({
              width: props?.maxImageWidth || undefined,
              src: props?.imageRight?.sourceUrl,
              crop: props?.imageRightCrop?.imageCrop12AddCrop && true,
              cropType: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPositionAdvanced,
              yPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPositionAdvanced,
              autoPosition: props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
    </div>
  );
}
