import gql from 'graphql-tag-transform.macro';
import { videoMediaCopyComponentSettings } from './common-vars';
export const VideoMediaCopy = gql`
fragment VideoMediaCopy on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_VideoMediaCopy {
        copy
        fieldGroupName
        videoMediaUrl
        videoTitle
        heading
        buttonsButtons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        imageLarge
        imageLargeAutoPosition
        imageLargeCropHeight
        imageLargeCropType
        imageSmall
        imageLargeYPositionAdvanced
        imageLargeYPosition
        imageLargeXPositionAdvanced
        imageLargeXPosition
        imageLargeEnableCropping
        imageLargeCropWidth
        imageSmallYPositionAdvanced
        imageSmallYPosition
        imageSmallXPositionAdvanced
        imageSmallXPosition
        imageSmallEnableCropping
        imageSmallCropWidth
        imageSmallCropType
        imageSmallCropHeight
        imageSmallAutoPosition
        largeImage {
          altText
          sourceUrl
        }
        smallImage {
          altText
          sourceUrl
        }
        componentSettingsVideoMediaCopySettings {
          fieldGroupName
          showHide
          ${videoMediaCopyComponentSettings}
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          animationDirection
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
        }
      }
}
`;
