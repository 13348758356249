import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./cunhihh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="w-24 fill-bg antialiased sm:w-28" />;

export const hotelLogoFooter = (
  <HotelLogo className="mr-2 w-24 fill-bg antialiased sm:mr-0 sm:w-28" />
);
