import type { HotelAmenityId } from '@dx-ui/gql-types';
import type { IconNames } from './osc-icon-map';

export const AmenityIcons: { [key in HotelAmenityId]: (typeof IconNames)[number] } = {
  adjoiningRooms: 'connecting-rooms',
  adventureStays: 'adventure-stays',
  airportShuttle: 'shuttle-service',
  allInclusive: 'all-inclusive',
  beach: 'beach',
  boutique: 'boutique-hotels',
  businessCenter: 'business-centre',
  casino: 'casino',
  concierge: 'concierge',
  connectedRoom: 'remote',
  digitalKey: 'digital-key',
  dining: 'dining',
  evCharging: 'ev-charging',
  eveningReception: 'evening-reception',
  eveningSocial: 'evening-reception',
  executiveLounge: 'lounge',
  familyFriendly: 'family',
  fitnessCenter: 'gym',
  freeBreakfast: 'breakfast',
  freeParking: 'parking',
  freeWifi: 'wifi',
  golf: 'golf',
  hotelResidence: 'residence',
  indoorPool: 'pool',
  inRoomKitchen: 'in-room-kitchen',
  luxury: 'luxury',
  meetingRooms: 'meeting',
  newHotel: 'new-hotel',
  nonSmoking: 'no-smoking',
  outdoorPool: 'outdoor-pool',
  petsAllowed: 'pet-friendly',
  petsNotAllowed: 'no-pets',
  resort: 'resort',
  roomService: 'room-service',
  ski: 'ski',
  spa: 'spa',
  streamingTv: 'streaming-tv',
  tennisCourt: 'tennis',
};
