import * as React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import type { FilterPanelProps } from './filter-panel';
import cx from 'classnames';

interface FilterTabProps extends FilterPanelProps {
  id: number;
  selectedValue: string;
}

export const FilterTab = React.forwardRef<HTMLButtonElement, FilterTabProps>(
  function FilterTabInner({ className, value, selectedValue, children }, ref) {
    return (
      <Tabs.Trigger
        data-reach-tab
        className={cx(
          'font-headline z-1 size-full max-w-lg border-b-[5px] p-2 text-center text-sm font-extralight tracking-tight hover:bg-[#00000007] sm:p-4 sm:text-xl',
          className,
          {
            'border-current': selectedValue === value,
            '!border-transparent': selectedValue !== value,
          }
        )}
        data-testid={`${value}-tab`}
        value={value}
        ref={ref}
      >
        {children}
      </Tabs.Trigger>
    );
  }
);
