import * as React from 'react';
import type { DialogBaseProps, DialogOptionalProps } from './dialog.base';
import { DialogBase } from './dialog.base';

/**
 * A modal is an overlay element which blocks interaction with elements outside it.
 *
 * Dialogs are windows containing contextual information, tasks, or workflows that appear over the user interface. Depending on the kind of Dialog, further interactions may be blocked until the Dialog is acknowledged.
 *
 * [Radix <Dialog /> Primitive](https://www.radix-ui.com/primitives/docs/components/dialog)
 */
export const Dialog: React.FC<React.PropsWithChildren<DialogBaseProps & DialogOptionalProps>> = (
  props
) => {
  const dialogRef = React.useRef<React.ElementRef<'div'>>(null);
  return <DialogBase {...props} ref={dialogRef} />;
};

export default Dialog;
