import gql from 'graphql-tag-transform.macro';

export const CopyBlock = gql`
  fragment CopyBlock on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_Introduction {
      copy
      fieldGroupName
      subtitle
      subtitle_noTx: subtitle
      title
      title_noTx: title
      combinedTitleSubtitle
      titleSize
      subtitleSize
      pullFromCorePlus
      pullQuote
      imageSize
      image {
        sourceUrl
        altText
      }
      buttons {
        buttonStyle
        fieldGroupName
        link {
          target
          title
          url
        }
      }
      introductionComponentSettings {
        fieldGroupName
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
        layoutDirection
        padding
      }
    }
  }
`;
