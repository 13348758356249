import type { ReactElement, ReactNode } from 'react';

type ReactNodeWithChildren = ReactElement<{ children: ReactNode }>;

/**
 * Type for `getReactNodeInnerText`
 * - Accepts any `ReactNode`
 * - Returns a `string`
 */
type ReactNodeInnerText = {
  (jsx: ReactNode): string;
  default: ReactNodeInnerText;
};

const hasProps = (jsx: ReactNode): jsx is ReactNodeWithChildren =>
  jsx !== null &&
  typeof jsx === 'object' &&
  Object.prototype.hasOwnProperty.call(jsx, 'props') &&
  'children' in (jsx as ReactElement).props;

const reduceJsxToString = (previous: string, current: ReactNode): string =>
  previous + getReactNodeInnerText(current);

export const getReactNodeInnerText: ReactNodeInnerText = (jsx: ReactNode): string => {
  // Empty
  if (jsx === null || typeof jsx === 'boolean' || typeof jsx === 'undefined') {
    return '';
  }

  // Numeric children
  if (typeof jsx === 'number') {
    return jsx.toString();
  }

  // String literals
  if (typeof jsx === 'string') {
    return jsx;
  }

  // Array of JSX
  if (Array.isArray(jsx)) {
    return jsx.reduce<string>(reduceJsxToString, '');
  }

  // React elements with children
  if (hasProps(jsx)) {
    return getReactNodeInnerText(jsx.props.children);
  }

  // Default
  return '';
};

getReactNodeInnerText.default = getReactNodeInnerText;
