import gql from 'graphql-tag-transform.macro';
import { twoColumnIconListSettings } from './common-vars';

export const TwoColumnIconList = gql`
fragment TwoColumnIconList on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_TwoColumnIconList {
        fieldGroupName
        subtitle
        repeater {
          fieldGroupName
          iconList
          iconDescription
          iconText
        }
        title
        twoColumnIconListSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd
          ${twoColumnIconListSettings}
        }
      }
}
`;
