export function Check({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.2656,6.7344a.376.376,0,0,0-.5312,0L10,15.47,5.2656,10.7344a.3756.3756,0,0,0-.5312.5312l5,5a.3771.3771,0,0,0,.5312,0l9-9A.3778.3778,0,0,0,19.2656,6.7344Z" />
      </g>
    </svg>
  );
}
