import type { Child } from '@dx-ui/framework-uri-builder';

export const DEFAULT_FORM_VALUES = {
  attendee_count: 0,
  brandCode: 'HH',
  dates: {
    arrivalDate: null,
    datesFlex: false,
    departureDate: null,
  },
  hhonors: null,
  numNights: null,
  numRooms: null,
  numAttendees: null,
  placeId: '',
  query: '',
  rooms: [
    {
      adults: 1,
      children: [] as Child[],
    },
  ],
  sessionToken: '',
  specialRates: {
    aaaRate: false,
    aarpRate: false,
    corporateCode: '',
    employeeRate: false,
    friendsAndFamilyRate: false,
    governmentOrMilitaryRate: false,
    groupCode: '',
    offerId: null,
    ownerHGVRate: false,
    ownerVIPRate: false,
    pnd: null,
    promoCode: '',
    redeemPts: false,
    requestedRatesOnly: false,
    searchedRatePlanDescription: '',
    seniorRate: false,
    smbRate: false,
    travelAgentId: '',
    useOfferId: false,
    usePnd: false,
    useRequestedRatesOnly: false,
    travelAgentRate: false,
  },
};
