import { type SVGAttributes } from 'react';

const MeetingRoomIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <path
        stroke="currentColor"
        d="M34.523 19.747c-.056-.62-.034-1.053-.034-1.62.28-.147.784-1.087.869-1.88.22-.019.57-.234.671-1.086.055-.456-.163-.713-.297-.794.36-1.081 1.107-4.425-1.38-4.77-.256-.45-.912-.677-1.763-.677-3.406.062-3.818 2.572-3.071 5.447-.133.081-.352.338-.297.794.102.852.45 1.067.671 1.086.085.793.609 1.733.89 1.88 0 .567.021 1-.035 1.62-.232.624-.926.972-1.74 1.304-.61-3.488-4.473-3.135-5.109-4.845-.056-.62-.034-1.054-.034-1.62.28-.148.784-1.088.869-1.881.22-.018.57-.234.671-1.085.055-.457-.163-.713-.297-.795.36-1.08 1.107-4.425-1.38-4.77-.256-.45-.912-.666-1.763-.666-3.406.063-3.818 2.562-3.071 5.436-.133.082-.352.338-.297.795.102.851.45 1.067.671 1.085.085.793.609 1.733.89 1.88 0 .567.021 1-.035 1.62-.637 1.711-4.516 1.358-5.128 4.846-.815-.332-1.509-.68-1.74-1.304-.056-.62-.035-1.053-.035-1.62.281-.147.804-1.087.889-1.88.22-.019.57-.234.671-1.086.055-.456-.163-.713-.297-.794.748-2.875.336-5.385-3.07-5.447-.852 0-1.507.227-1.763.676-2.487.346-1.74 3.69-1.381 4.77-.134.082-.352.339-.297.795.102.852.45 1.067.671 1.086.085.793.588 1.733.87 1.88 0 .567.021 1-.035 1.62C8.803 21.56 5.21 21.007 5 24.503v7.792s1.026 5.053 9.208 7.067v-.692c.23-3.803 5.015-3.25 5.75-5.221.06-.676.037-1.147.037-1.763-.306-.16-.876-1.183-.968-2.046-.24-.02-.619-.254-.73-1.18-.06-.497.178-.777.323-.864-.813-3.128-.365-5.858 3.341-5.926.927 0 1.64.247 1.918.736 2.705.376 1.893 4.014 1.502 5.19.144.087.382.367.322.864-.111.926-.49 1.16-.73 1.18-.092.863-.64 1.885-.946 2.046 0 .616-.023 1.087.039 1.763.733 1.972 5.498 1.418 5.726 5.22v.693C37.975 37.348 39 32.295 39 32.295v-7.792c-.21-3.496-3.802-2.943-4.477-4.756z"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MeetingRoomIcon;
