import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { ACFAnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array } from './functions/global-instance-styles';
import cx from 'classnames';
import { Cancellation } from '@curated-property/icons';
import { HandleWYSIWYGContent } from './functions/helper';
import { appliedCloudinaryParams, WrappedHeading, useHeadingLevel } from '@curated-property/utils';
import type { FlexComponents } from 'apps/dx-curated-ui';

export interface RetailRailProps {
  componentIndex?: number;
  allComponents?: FlexComponents;
  copy?: string;
  title?: string;
  buttons?: Array<ACFAnchorProps>;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function RetailRail({
  componentIndex,
  allComponents,
  copy,
  title,
  buttons,
  instanceStyles,
  globalStyles,
}: RetailRailProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [lastFocused, setLastFocused] = useState<any | null>(null);
  const headingLevel = useHeadingLevel(componentIndex, allComponents);

  const { t } = useTranslation();

  const handleClose = () => {
    setIsToggled(false);
    lastFocused?.focus();
  };

  useEffect(() => {
    function modalKeypress(e: KeyboardEvent) {
      const tar = e.target as HTMLElement;
      if (
        tar.tagName !== 'BODY' &&
        tar.innerText !== buttons?.[0]?.link?.title &&
        tar.innerText !== t('close')
      ) {
        setLastFocused(e.target);
      }
    }

    if (isToggled) window.addEventListener('keydown', modalKeypress);
    return () => {
      window.removeEventListener('keydown', modalKeypress);
    };
  }, [buttons, isToggled, t]);

  return (
    <div
      data-testid="retail-rail"
      data-element-id="retail-rail-component"
      className={cx(
        inlineStyles?.showHide && 'hidden',
        `${
          isToggled ? '' : 'translate-y-full opacity-0'
        } bg-primary fixed bottom-0 z-50 flex w-full justify-center py-0 transition duration-500 ease-in-out sm:py-2`
      )}
      style={{
        background: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : inlineStyles.componentBackgroundColor,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
      }}
    >
      <div
        className={cx(
          `text-bg container relative flex h-auto w-full flex-col justify-between sm:flex-row sm:justify-between`
        )}
        style={{
          background: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : inlineStyles.contentBackgroundColor,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'top left',
        }}
      >
        <div
          className={cx(
            `${
              inlineStyles?.textAlignment === 'flex-end'
                ? 'text-right rtl:text-left'
                : inlineStyles?.textAlignment !== 'flex-start'
                ? `text-${inlineStyles?.textAlignment}`
                : 'text-left rtl:text-right'
            } border-current/20 flex w-full flex-col justify-center overflow-hidden px-5 lg:px-20
          `,
            buttons?.length && 'sm:w-4/6'
          )}
        >
          <WrappedHeading header={headingLevel}>
            <span
              className="text-bg font-headline mb-1.5 w-full font-bold md:text-lg lg:text-xl"
              style={{
                color: inlineStyles?.titleColor,
              }}
            >
              {title}
            </span>
          </WrappedHeading>
          <div
            className={cx(`mb-2 text-sm leading-5 md:text-base md:leading-normal`)}
            dangerouslySetInnerHTML={{
              __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
            }}
            style={{
              color: inlineStyles?.textColor,
            }}
          />
        </div>
        {buttons?.length && (
          <div className="flex items-center justify-center">
            <div className={cx(`${isToggled ? '' : 'hidden'} z-30 mb-5 mt-0 md:mb-0`)}>
              <AnchorLink
                url={buttons?.[0]?.link?.url}
                title={buttons?.[0]?.link?.title}
                target={buttons?.[0]?.link?.target}
                buttonStyle={buttons?.[0]?.buttonStyle || 'white-outline'}
              />
            </div>
          </div>
        )}

        <div
          className={cx(
            `${
              isToggled ? '' : 'hidden'
            } order-first mr-5 mt-2 flex grow-0 justify-end sm:order-last sm:justify-start`
          )}
          style={{
            color: inlineStyles?.closeTextColour,
          }}
        >
          <button
            id="closeButton"
            aria-hidden={isToggled ? false : true}
            className={cx(
              `z-30 flex h-5 w-20 items-center justify-around fill-current text-sm font-extrabold hover:cursor-pointer md:w-16`
            )}
            onClick={handleClose}
          >
            {t('close')}
            <Cancellation className="h-5" color={inlineStyles?.closeIconColour} />
          </button>
        </div>
      </div>
    </div>
  );
}
