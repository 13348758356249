import gql from 'graphql-tag-transform.macro';
import { roomsComponentSettings } from './common-vars';

export const RoomsTiles = gql`
fragment RoomsTiles on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_RoomTypes {
        fieldGroupName
        ctyhocn
        showPricing
        hideLeadRates
        hideMaxGuestsFilter
        hideBedsFilter
        hidePriceFilter
        showFindYourBestRoomFilters
        arrivalDate
        displayRoomsFromPosts
        pricingDisclaimer
        sortRoomsBy
        room {
          roomTypeCode
          view
          view_noTx: view
          recommendedFor
          premiumOptions
          balconyDetail
          balconyDetail_noTx: balconyDetail
          customView
          customView_noTx: customView
          customBalcony
          customBalcony_noTx: customBalcony
          squareFootage
          outdoorFeatures
          roomFeatures
          roomType
          bathroomAmenities
          locations
          image {
            sourceUrl
            altText
          }
          icon {
            altText
            sourceUrl
          }
          iconWidth
          carouselImages {
            image {
              sourceUrl(size: _1920X1080)
              altText
              mediaDetails {
                height
                width
              }
            }
          }
          floorPlanCtaUrl
          floorPlanCtaLabel
          buttonStyle
          showCustomFilter1 {
            customFilter1Select
            showCustomFilter1
          }
          showCustomFilter2 {
            customFilter2Select
            showCustomFilter2
          }
          showCustomFilter3 {
            customFilter3Select
            showCustomFilter3
          }
          enableUpsell
          upsellModalTitle
          upsellModalTitleHighlight
          upsellRoomCode
          rooms {
            fieldGroupName
            selectedRoomTitle
            selectedRoomTitleHighlight
            upsellRoomTitle
            upsellRoomTitleHighlight
            selectedRoomFeatures {
              item
            }
            selectedRoomDownsellFeatures {
              item
            }
            upsellRoomFeatures {
              item
            }
            upsellRoomAdditionalFeatures {
              item
            }
          }
          balconyIconGroup {
            iconList
            iconList_noTx: iconList
          }
          viewIconGroup {
            iconList
            iconList_noTx: iconList
          }
          squareFootageIconGroup {
            iconList
            iconList_noTx: iconList
          }
        }
        roomsComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${roomsComponentSettings}
        }
      }
}
`;
