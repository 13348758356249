import type { RestaurantsFragmentFragment } from '@dx-ui/queries-dx-curated-ui/generated/dx-gql';
import type {
  RestaurantsFragment,
  FullWidthImageFragment,
} from '@dx-ui/queries-dx-curated-ui/generated/wp';

export type DxGqlRestaurants = RestaurantsFragmentFragment['restaurantOverview']['restaurants'];
type FullWidthImageContent = Extract<
  FullWidthImageFragment,
  { __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent' }
>;
type FullWidthImage = Pick<
  FullWidthImageContent,
  | 'autoPosition'
  | 'cropHeight'
  | 'cropType'
  | 'cropWidth'
  | 'enableCropping'
  | 'image'
  | 'xPosition'
  | 'xPositionAdvanced'
  | 'yPosition'
  | 'yPositionAdvanced'
>;
type Restaurant = Extract<
  RestaurantsFragment,
  { __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants' }
>['restaurants'][number];

export type WpRestaurants = Array<Restaurant & FullWidthImage>;

export type MergedRestaurantData = ReturnType<typeof mergeRestaurants>;

export function mergeRestaurants({
  dxGqlRestaurants,
  wpRestaurants,
}: {
  dxGqlRestaurants?: DxGqlRestaurants;
  wpRestaurants?: WpRestaurants;
}) {
  const wpRestaurantsByRoomTypeCode = wpRestaurants?.reduce((prev, curr) => {
    if (!curr.restaurantId) {
      return prev;
    }
    prev[curr.restaurantId] = curr;
    return prev;
  }, {} as Record<string, WpRestaurants[number]>);

  // changing the logic here so that we pull in all IDs from core+ and supplement that data with IDs from WorDress
  // mergeRooms works the other way around
  return dxGqlRestaurants?.map((wpRoom) => {
    return {
      ...wpRestaurantsByRoomTypeCode?.[wpRoom?.id || ''],
      ...wpRoom,
    };
  });
}
