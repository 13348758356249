export function RenovatedHotels({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M17.2246,15.5459c0.0001,0.2071,0.1679,0.3749,0.375,0.375l2.3086-0.001l0.0023-0.0005
            c0.0058,0,0.0116-0.0032,0.0175-0.0035c0.0088-0.0005,0.0164-0.004,0.025-0.005c0.0155,0.0039,0.0312,0.007,0.047,0.0091
            c0.2071-0.0001,0.3749-0.1679,0.375-0.375V13c0-0.2071-0.1679-0.375-0.375-0.375S19.625,12.7929,19.625,13v1.42
            c-0.6391-0.7298-1.5599-1.1512-2.53-1.1579c-1.9643,0.0477-3.518,1.6788-3.4702,3.6431
            c0.0461,1.8971,1.5731,3.4241,3.4702,3.4702c1.4809-0.0167,2.789-0.9689,3.26-2.373c0.068-0.1958-0.0357-0.4096-0.2314-0.4776
            c-0.1958-0.068-0.4096,0.0357-0.4776,0.2315l0,0c-0.3656,1.1026-1.3893,1.8527-2.5508,1.8691
            c-1.5501-0.0481-2.7677-1.3436-2.7196-2.8937c0.046-1.4825,1.2371-2.6736,2.7196-2.7196c0.8689,0.0096,1.6786,0.4419,2.17,1.1585
            L17.6,15.1709c-0.2072-0.0002-0.3752,0.1676-0.3754,0.3748C17.2246,15.5458,17.2246,15.5458,17.2246,15.5459z"
            />
            <path
              d="M7.375,10C7.3749,9.7929,7.2071,9.6251,7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v5
            c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
            C7.2071,10.3749,7.3749,10.2071,7.375,10z"
            />
            <path
              d="M16.625,5v5c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V5C17.3739,4.2411,16.7589,3.6261,16,3.625h-6
            C9.2411,3.6261,8.6261,4.2411,8.625,5v11c0,0.2071,0.1679,0.375,0.375,0.375S9.375,16.2071,9.375,16V5
            C9.3753,4.655,9.655,4.3753,10,4.375h6C16.345,4.3753,16.6247,4.655,16.625,5z"
            />
            <path
              d="M13,11.625h-2c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h2c0.2071,0,0.375-0.1679,0.375-0.375
            S13.2071,11.625,13,11.625z"
            />
            <path
              d="M15,9.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,9.625,15,9.625z"
            />
            <path
              d="M15,7.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,8.375,11,8.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,7.625,15,7.625z"
            />
            <path
              d="M15,5.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,6.375,11,6.375h4c0.2071,0,0.375-0.1679,0.375-0.375
            S15.2071,5.625,15,5.625z"
            />
            <path
              d="M7.0068,13.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,14.375,6,14.375h1.0068c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2139,13.625,7.0068,13.625z"
            />
            <path
              d="M7,11.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,12.375,6,12.375h1c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,11.625,7,11.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
