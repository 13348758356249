import gql from 'graphql-tag-transform.macro';
import { clonedCropSettings } from './common-vars';

export const MultiColumnTilesCarousel = gql`
fragment MultiColumnTilesCarousel on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_MultiItemCarousel {
        fieldGroupName
        copy
        columnCount
        header
        headingValue
        headingOnActiveState
        titleAlignment
        tileLayout
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        tileSizes
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          tableData {
            title
            text
          }
          image {
            sourceUrl
            altText
          }
          logo {
            sourceUrl
            altText
          }
          ${clonedCropSettings}
          tileBackgroundLayer2
          buttons {
            buttonStyle
            fieldGroupName
            link {
              target
              title
              url
            }
          }
          showHide
        }
        multiItemCarouselComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          headerTitleColour
          headerCopyColour
          uiPrimaryColour
          uiSecondaryColour
          titleSize
          tileTitleBackgroundColour
          headingStyle
          tileTitleAlignment
          tileTitleLayout
        }
      }
}
`;
