import { getDaysOfWeek } from '@dx-ui/framework-i18n';
import EventCalendarDayName from './event-calendar-day-name';
import { useRouter } from 'next/router';
import { useLocale } from '@dx-ui/utilities-dates';

// Returns row of day names for use in a table.
export interface EventCalendarDaysOfWeekProps {
  dayDisplayType?: string;
}
export function EventCalendarDaysOfWeekRow({ dayDisplayType }: EventCalendarDaysOfWeekProps) {
  const { locale: language = 'en' } = useRouter();
  const locale = useLocale({ language });

  return (
    <thead>
      <tr>
        {getDaysOfWeek(locale).map((i, e) => {
          return (
            <EventCalendarDayName
              key={`${!dayDisplayType ? 'n' : 'd'}${e}`}
              dayNameFull={i.long}
              dayNameDisplay={!dayDisplayType || dayDisplayType === 'long' ? i.long : i.short}
            />
          );
        })}
      </tr>
    </thead>
  );
}
