import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./bjvlxol/hotel-logo').then((i) => i.HotelLogo)
);

const FooterLogo = dynamic<{ className: string }>(() =>
  import('./bjvlxol/footer-logo').then((i) => i.FooterLogo)
);

export const hotelLogoHeader = <HotelLogo className="h-20 text-bg antialiased" />;

export const hotelLogoFooter = <FooterLogo className="h-24 fill-current text-bg antialiased" />;
