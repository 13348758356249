export function Thermal({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} x="0px" y="0px" viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m19 10.5h-1.793l.146-.146c.195-.195.195-.512 0-.707s-.512-.195-.707 0l-.854.854h-1.586l2-2h1.793c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-.793l1.146-1.146c.195-.195.195-.512 0-.707s-.512-.195-.707 0l-1.999 1.999h-.001s-2.999 3-2.999 3h-.001s-1.999 2-1.999 2h-.001s-2.999 3-2.999 3h-.001s-1.999 2.001-1.999 2.001c-.195.195-.195.512 0 .707.098.098.226.146.354.146s.256-.049.354-.146l1.146-1.146v.793c0 .276.224.5.5.5s.5-.224.5-.5v-1.793l2-2v1.586l-.854.854c-.195.195-.195.512 0 .707s.512.195.707 0l.146-.146v1.793c0 .276.224.5.5.5s.5-.224.5-.5v-1.793l.146.146c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512 0-.707l-.854-.854v-2.586l.5-.5 1.5 1.5v1.793c0 .276.224.5.5.5s.5-.224.5-.5v-.793l1 1v1.793c0 .276.224.5.5.5s.5-.224.5-.5v-.793l1.146 1.146c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512 0-.707l-1.146-1.146h.793c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-1.793l-1-1h.793c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-1.793l-1.5-1.5.5-.5h2.586l.854.854c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512 0-.707l-.146-.146h1.793c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
      <path d="m8.165 12.699c.096.144.254.221.415.221.096 0 .193-.027.279-.085.229-.154.29-.465.135-.694-.324-.48-.495-1.048-.495-1.641 0-1.654 1.346-3 3-3 .592 0 1.16.171 1.641.495.009.006.021.004.03.009.074.044.156.076.249.076h.01c.276 0 .495-.224.495-.5 0-.084-.026-.159-.063-.228-.011-.02-.024-.035-.037-.053-.034-.047-.074-.087-.122-.12-.011-.008-.017-.022-.029-.029-.642-.425-1.393-.649-2.175-.649-2.206 0-4 1.794-4 4 0 .792.23 1.553.666 2.199z" />
      <path d="m6.646 7.354c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512 0-.707l-2-2c-.195-.195-.512-.195-.707 0s-.195.512 0 .707l2 2z" />
      <path d="m11 5.5c.276 0 .5-.224.5-.5v-2c0-.276-.224-.5-.5-.5s-.5.224-.5.5v2c0 .276.224.5.5.5z" />
      <path d="m15 6.5c.128 0 .256-.049.354-.146l2-2c.195-.195.195-.512 0-.707s-.512-.195-.707 0l-2 2c-.195.195-.195.512 0 .707.098.098.226.146.354.146z" />
      <path d="m6.5 10c0-.276-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5s.224.5.5.5h2c.276 0 .5-.224.5-.5z" />
      <path d="m7.353 13.646c-.195-.195-.512-.195-.707 0l-2 2c-.195.195-.195.512 0 .707.098.098.226.146.354.146s.256-.049.354-.146l2-2c.195-.195.195-.512 0-.707z" />
    </svg>
  );
}
