import { useEffect, useState } from 'react';
import classnames from 'classnames';
import type { useMenu } from './hooks/use-control-menu';
import type { VideoControlsProps } from './video-player.controls';
import { getClasses } from './util/classes';

export function ControlMenu(
  props: ReturnType<typeof useMenu> &
    Pick<VideoControlsProps, 'brandComponentTheme'> &
    Pick<React.ComponentProps<'div'>, 'children' | 'className'> & {
      wrapperRef: React.RefObject<HTMLElement>;
    }
) {
  const { brandComponentTheme, menuId, popupMenuRef, isOpen, children, className } = props;
  const isDark = brandComponentTheme === 'dark';
  const maxHeight = useSafeMaxHeight(props);

  return (
    <div
      id={menuId}
      ref={popupMenuRef}
      style={maxHeight ? { maxHeight: `${maxHeight}px` } : {}}
      className={classnames(
        'absolute bottom-full start-0 w-full border-y border-border shadow-md md:min-w-60',
        getClasses({ brandComponentTheme }),
        {
          'overflow-y-auto overscroll-contain': Boolean(maxHeight),
          'border-bg-dark': isDark,
          'motion-safe:animate-fadein': isOpen,
          'motion-safe:animate-fadeout': !isOpen,
          hidden: !isOpen,
        },
        className
      )}
    >
      {children}
    </div>
  );
}

function useSafeMaxHeight(props: React.ComponentProps<typeof ControlMenu>) {
  const [maxHeight, setMaxHeight] = useState<number | null>(null);
  const { isOpen, wrapperRef, popupMenuRef, popupButtonRef } = props;

  useEffect(() => {
    const wrapperRect = wrapperRef.current?.getBoundingClientRect();
    const menuRect = popupMenuRef.current?.getBoundingClientRect();
    const buttonRect = popupButtonRef.current?.getBoundingClientRect();

    setMaxHeight(() => {
      if (isOpen && menuRect && wrapperRect && buttonRect) {
        const offset = wrapperRect.bottom - menuRect.bottom;
        const buttonOffset = buttonRect.height / 2;
        return wrapperRect.height - offset - buttonOffset;
      }
      return null;
    });
  }, [isOpen, popupButtonRef, popupMenuRef, wrapperRef]);

  return maxHeight;
}
