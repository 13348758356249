import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./auhyihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-20 text-bg antialiased sm:w-24 md:w-28" fillColor="#FFFFFF" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-20 text-bg antialiased sm:w-28" fillColor="#FFFFFF" />
);
