import { useState } from 'react';
import { HeadingStyle } from '../includes/heading-style';
import cx from 'classnames';
import styles from '../css/tabbed-table-data.module.css';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array, GIS_Padder } from '../functions/global-instance-styles';
import * as Tabs from '@radix-ui/react-tabs';
import { useURLParamState, appliedCloudinaryParams } from '@curated-property/utils';
import { slugify, HandleAnimations } from '../functions/helper';
import { MenuSection } from './includes/menu-section';
import { MenuTab } from './includes/menu-tab';

export interface HTMLMenuProps {
  header?: string;
  header_noTx?: string;
  menu?: string;
  tabTypeButtonStyle?: string;
  menuRepeater?: {
    tabTitle?: string;
    tabTitle_noTx?: string;
    menuSections?: {
      sectionBackgroundColour?: string;
      sectionDisclaimer?: boolean;
      sectionDisclaimerLine1?: string;
      sectionDisclaimerLine1_noTx?: string;
      sectionDisclaimerLine2?: string;
      sectionDisclaimerLine2_noTx?: string;
      sectionDisclaimerColour?: string;
      sectionAlignment?: string;
      spotlightBorderColour?: string;
      sectionItems?: {
        itemTitleColour?: string;
        itemDisclaimer?: string;
        itemDisclaimer_noTx?: string;
        itemLine1?: string;
        itemLine1_noTx?: string;
        itemLine2?: string;
        itemLine2_noTx?: string;
        itemPrice?: string;
        itemPrice_noTx?: string;
        highlightItem?: boolean;
      }[];
      sectionLayout?: string;
      sectionSubtitle?: string;
      sectionSubtitle_noTx?: string;
      sectionTitle?: string;
      sectionTitle_noTx?: string;
    }[];
  }[];
  menuFromPosts?: any;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  urlQueryParamName: string;
}

export function HTMLMenu({
  header,
  header_noTx,
  menu,
  tabTypeButtonStyle,
  menuRepeater,
  menuFromPosts,
  globalStyles,
  instanceStyles,
  urlQueryParamName,
}: HTMLMenuProps) {
  const { val: activeIndex, changeParamValue: changeActiveIndex } = useURLParamState({
    urlQueryParamName,
  });
  let tabStart = 0;

  if (activeIndex instanceof Array) {
    if (activeIndex.length > 0) {
      tabStart = parseInt(activeIndex[0]);
    }
  } else {
    tabStart = parseInt(activeIndex);
  }

  tabStart = isNaN(tabStart) ? 0 : tabStart;

  const [clicked, setClicked] = useState(tabStart);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  if (menu) {
    menuFromPosts?.HTMLMenu?.forEach((item) => {
      if (menu === item?.node?.id) {
        menuRepeater = item?.node?.htmlMenuContent?.menuRepeater;
      }
    });
  }
  // Count valid array members in each menuReater. To be valid, a sub array must have both menu section contents and a menu tab title
  // If there's only one valid section/panel, then the presentation changes to a single section (non-tabbed) format

  const menuRepeaterCount =
    menuRepeater !== null
      ? menuRepeater.filter((obj) => obj?.menuSections && obj?.tabTitle).length
      : 0;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  const htmlAnchorFrag = `html-menu-${slugify(header ?? 'no-title')}`;
  return (
    <div
      data-testid={menu ? 'menuFromPosts' : 'htmlMenuContainer'}
      id={htmlAnchorFrag}
      className={cx(
        'bg-bg-alt cp-htmlMenus',
        styles.cpTabbedStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'left center',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      <div
        ref={animations?.ref}
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: inlineStyles?.contentBackgroundColor,
        }}
      >
        {header && (
          <HeadingStyle
            text={inlineStyles?.headingsNoTranslate ? header_noTx : header}
            anchorFragment={htmlAnchorFrag}
            type="h2"
            styledAs="h1"
            className={cx('text-inverse pb-10 text-center', animations?.one, {
              OneLinkNoTx: inlineStyles?.headingsNoTranslate,
            })}
            textColorInline={inlineStyles?.titleColor}
          />
        )}
        <div>
          {/* Tabbed Panel version */}
          {menuRepeaterCount > 1 && (
            <Tabs.Root
              defaultValue={clicked?.toString()}
              onValueChange={(v) => {
                void changeActiveIndex(v);
                setClicked(parseInt(v));
              }}
            >
              <Tabs.List
                className={cx(
                  'border-text-disabled flex w-full flex-wrap justify-center border-b-2 md:flex-nowrap',
                  animations?.two
                )}
                style={{
                  borderColor: tabTypeButtonStyle
                    ? 'transparent'
                    : inlineStyles?.htmlMenuTabUnderlineColour,
                }}
              >
                <div
                  className={cx(
                    'relative',
                    tabTypeButtonStyle ? 'flex flex-wrap justify-center sm:block' : ''
                  )}
                >
                  {menuRepeater?.map((item, key) => {
                    return item?.tabTitle && item?.menuSections ? (
                      <MenuTab
                        key={key}
                        index={key}
                        isClicked={clicked}
                        title={inlineStyles?.tabTitleNoTx ? item?.tabTitle_noTx : item?.tabTitle}
                        tabTypeButtonStyle={tabTypeButtonStyle}
                        textColor={inlineStyles?.htmlMenuTabTextColour}
                        activeTabAccentColour={inlineStyles?.htmlMenuActiveTabUnderlineColour}
                        activeTabTextColour={inlineStyles?.htmlMenuActiveTabTextColour}
                      />
                    ) : (
                      ''
                    );
                  })}
                </div>
              </Tabs.List>
              <div className={cx('w-full', animations?.three)}>
                {menuRepeater?.map((tabItem, tabKey) => {
                  return tabItem?.tabTitle && tabItem?.menuSections ? (
                    <Tabs.Content key={tabKey} value={tabKey.toString()}>
                      <div
                        data-testid="tab-panel"
                        className="mt-12 flex w-full flex-col space-y-2 md:space-y-4"
                      >
                        {tabItem?.menuSections?.map((sectionItem, sectionKey) => {
                          return (
                            <MenuSection
                              key={sectionKey}
                              item={sectionItem}
                              inlineStyles={inlineStyles}
                            />
                          );
                        })}
                      </div>
                    </Tabs.Content>
                  ) : (
                    ''
                  );
                })}
              </div>
            </Tabs.Root>
          )}
          {/* Single section version (only 1 menu section available) */}
          {menuRepeaterCount === 1 && (
            <div className={animations?.two}>
              {menuRepeater?.map((item, key) => {
                return item?.tabTitle && item?.menuSections ? (
                  <div key={key}>
                    <div>
                      <h3
                        className={cx(
                          'font-headline htmlMenuTitle text-inverse pb-3 text-center text-2xl font-extrabold leading-none md:text-3xl',
                          {
                            OneLinkNoTx: inlineStyles?.tabTitleNoTx,
                          }
                        )}
                      >
                        {inlineStyles?.tabTitleNoTx ? item?.tabTitle_noTx : item?.tabTitle}
                      </h3>
                    </div>
                    <div className="mt-8">
                      {item?.menuSections?.map((subItem, subKey) => {
                        return (
                          <MenuSection key={subKey} item={subItem} inlineStyles={inlineStyles} />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
