import type { ReactElement } from 'react';
import cx from 'classnames';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array, GIS_Padder, GIS_TextAlignment } from '../functions/global-instance-styles';
import { HandleAnimations, HandleWYSIWYGContent } from '../functions/helper';
import { appliedCloudinaryParams, sanitize } from '@curated-property/utils';
import { AdditionalLogosCMS } from '../footer';
import type { AnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';

export interface LogoGridProps {
  heading?: string;
  headingCopy?: string;
  columnCount?: number;
  logoRepeater?: {
    logo?: {
      sourceUrl?: string;
    };
    label?: string;
    maxWidth?: string;
    tailwindClasses?: string;
  }[];
  button?: {
    link?: Pick<AnchorProps, 'url' | 'title' | 'target'>;
    buttonStyle?: string;
  };
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function LogoGrid({
  heading,
  headingCopy,
  columnCount,
  logoRepeater,
  button,
  globalStyles,
  instanceStyles,
}: LogoGridProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);

  let flexColumns: string;
  switch (columnCount) {
    case 2:
      flexColumns = 'w-full sm:w-1/2';
      break;
    case 3:
      flexColumns = 'w-full sm:w-1/2 md:w-1/3';
      break;
    default:
      flexColumns = 'w-full sm:w-1/2 md:w-1/3 lg:w-1/4';
      break;
  }

  const hideAnimations = inlineStyles?.hideAnimations !== 'show';
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
  });

  return (
    <div
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || undefined,
        backgroundPosition: inlineStyles?.componentBackgroundPosition || undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
      }}
      data-testid="logoGridWrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || undefined,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || undefined,
          backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
        }}
      >
        {(heading || headingCopy) && (
          <div
            ref={animations?.ref}
            className="mb-12 text-center"
            style={{ textAlign: textAlignment }}
          >
            {heading && (
              <h2
                className={cx(
                  'font-headline text-2xl font-black leading-none md:text-4xl',
                  animations?.one
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitize(heading || ''),
                }}
                style={{
                  color: inlineStyles?.titleColor,
                }}
              />
            )}
            {headingCopy && (
              <div
                data-testid="logoCopy"
                className={cx('text-lg', animations?.two, {
                  'mt-6': heading,
                })}
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(headingCopy, inlineStyles?.subtitleColor),
                }}
                style={{
                  color: inlineStyles?.subtitleColor,
                }}
              />
            )}
          </div>
        )}
        <div className="flex flex-wrap justify-center gap-y-12">
          {logoRepeater?.map((logo, key) => {
            const urlSansCloudinaryParams = logo?.logo?.sourceUrl?.replace(/,w_300,h_300/gi, '');
            return (
              <LogoGridItem
                key={key}
                logo={
                  <AdditionalLogosCMS
                    key={key}
                    image={{
                      sourceUrl: urlSansCloudinaryParams,
                    }}
                    margin="0"
                    maxWidth={logo?.maxWidth || '150'}
                    tailwindClasses={logo?.tailwindClasses}
                  />
                }
                logoLabel={logo?.label}
                labelColor={inlineStyles?.textColor}
                columnStyles={flexColumns}
                hideAnimations={hideAnimations}
                animationDirection={inlineStyles?.animationDirection}
              />
            );
          })}
        </div>
        {button?.link && (
          <div className="mt-10 flex justify-center">
            <AnchorLink
              url={button?.link?.url}
              title={button?.link?.title}
              target={button?.link?.target}
              buttonStyle={button?.buttonStyle || 'primary'}
            />
          </div>
        )}
      </div>
    </div>
  );
}

interface LogoItemProps {
  logo?: ReactElement;
  logoLabel?: string;
  logoColor?: string;
  labelColor?: string;
  columnStyles?: string;
  hideAnimations?: boolean;
  animationDirection?: string;
}

function LogoGridItem({
  logo,
  logoLabel,
  logoColor,
  labelColor,
  columnStyles,
  hideAnimations,
  animationDirection,
}: LogoItemProps) {
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-y'}-8`,
    end: 'translate-y-0',
    delayOne: 'delay-100',
  });
  return (
    <div
      ref={animations?.ref}
      data-testid="logoGridItem"
      className={cx(
        'flex grow flex-col items-center justify-between text-center',
        columnStyles,
        animations?.one
      )}
      style={{
        color: logoColor,
      }}
    >
      <div className="flex grow flex-col items-center justify-center">
        <div
          aria-hidden="true"
          className="align-center flex w-full justify-center"
          style={{ color: logoColor }}
        >
          {logo}
        </div>
      </div>
      {logoLabel && (
        <p
          style={{ color: labelColor }}
          className="w-full pt-4 text-lg"
          dangerouslySetInnerHTML={{
            __html: sanitize(logoLabel || ''),
          }}
        />
      )}
    </div>
  );
}
