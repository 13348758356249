const BrandTextLXRLogo = ({ className, fill }: { className?: string; fill?: string }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 582.3 146.21">
      <g fill={fill} data-name="Layer 2">
        <g data-name="Layer 2">
          <g data-name="Layer 1-2">
            <path d="M0 142h21.56V3.42H0V.58h62.6v2.84H41V142h34.6q17.11 0 26.84-11.14t14.19-30.38h2.14v44.35H0v-2.84ZM222.22 141.95h18.66l38.89-67.64-41.41-70.89h-16.14V.58h57.94v2.84H261.5l31.1 53.27 31.11-53.27h-18.66V.58h40.44v2.84h-18.66l-32.47 56.1 48.41 82.43h16.13v2.84h-58.32v-2.84h18.66l-37.92-64.81-37.13 64.81h18.66v2.84h-40.63v-2.84zM489 66h17.9a32.43 32.43 0 0 0 9.9-1.42 17.15 17.15 0 0 0 7.69-5 23.77 23.77 0 0 0 4.92-9.58 56.18 56.18 0 0 0 1.76-15.4q0-10.53-2.63-16.91a22.85 22.85 0 0 0-6.8-9.62 22.16 22.16 0 0 0-9.33-4.25 53.31 53.31 0 0 0-10.21-1c-2.34 0-4.7 0-7.1.1a48.38 48.38 0 0 0-6.12.51V66Zm-41 76h21.58V3.43H448V.59h36.94c1.55 0 3.1 0 4.67-.1s3.1-.16 4.66-.3 3.1 0 4.66-.09S502 0 503.54 0a137.07 137.07 0 0 1 20.23 1.32 33.06 33.06 0 0 1 16.33 7.59 31.1 31.1 0 0 1 9.63 12.36 34.43 34.43 0 0 1 2.82 13 38 38 0 0 1-1.06 7.8 26.9 26.9 0 0 1-4.67 9.71 35.54 35.54 0 0 1-10.4 9.12q-6.81 4.14-18.08 5.56v.41c2.07.28 4.9.77 8.47 1.52a48 48 0 0 1 10.88 3.74 40.91 40.91 0 0 1 10.21 7 24 24 0 0 1 6.61 11.24 51.14 51.14 0 0 1 1.65 9.22c.31 3.44.58 7 .78 10.63s.39 7.16.59 10.54a53.14 53.14 0 0 0 1.06 8.51 32.1 32.1 0 0 0 3.69 9.51q2.52 4.26 8.15 4.25a14.73 14.73 0 0 0 5.26-1 12.9 12.9 0 0 0 4.86-3.24l1.75 2.22c-.65.55-1.4 1.12-2.24 1.73a16.54 16.54 0 0 1-3.21 1.73 23.18 23.18 0 0 1-4.76 1.31 40.68 40.68 0 0 1-6.9.5 35.66 35.66 0 0 1-12.93-2 19.15 19.15 0 0 1-8.26-6 25.62 25.62 0 0 1-4.57-9.72 78.64 78.64 0 0 1-2-13.47L536 96.6q-.75-10.12-3.88-15.81a22 22 0 0 0-7.29-8.3 20.35 20.35 0 0 0-8.65-3.13 69.79 69.79 0 0 0-7.78-.51H489V142h21.58v2.84H448V142Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BrandTextLXRLogo;
