export function Kitchen({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M20,12.625H18.375V11a1.375,1.375,0,0,0-2.75,0,.375.375,0,0,0,.75,0,.625.625,0,0,1,1.25,0v1.625H4A.3751.3751,0,0,0,3.625,13v7A.3751.3751,0,0,0,4,20.375H20A.3751.3751,0,0,0,20.375,20V13A.3751.3751,0,0,0,20,12.625Zm-12.3779,7H4.375v-6.25H7.6221Zm8.0029,0H8.3721v-6.25H15.625Zm4,0h-3.25v-6.25h3.25Z" />
          <path d="M14,15.625A.625.625,0,1,0,13.375,15,.6256.6256,0,0,0,14,15.625Z" />
          <path d="M10,15.625A.625.625,0,1,0,9.375,15,.6256.6256,0,0,0,10,15.625Z" />
          <path d="M12,15.625A.625.625,0,1,0,11.375,15,.6256.6256,0,0,0,12,15.625Z" />
          <path d="M8,8.375h8a.3753.3753,0,0,0,.2656-.6406l-1.8906-1.89V4A.3751.3751,0,0,0,14,3.625H10A.3751.3751,0,0,0,9.625,4V5.8447l-1.8906,1.89A.3753.3753,0,0,0,8,8.375Zm2.2656-2.1094A.3754.3754,0,0,0,10.375,6V4.375h3.25V6a.3754.3754,0,0,0,.1094.2656l1.36,1.3594H8.9053Z" />
        </g>
      </g>
    </svg>
  );
}
