import { useState, useEffect, useRef } from 'react';
import type { MenuProps, ShopFormInlineStyles } from './header-types.types';
import { useLanguage, useLinkContext, useInitializeConductrics } from '@curated-property/utils';
import { BookingWidgetButton } from '../global/booking-widget-button';
import { OSCLanguageSelector } from '../global/osc-language-switcher';
import { useDeviceDetect, useWindowScroll, useWindowSize } from '../functions/helper';
import { HonorsSignIn } from '../includes/honors-sign-in';
import { PropertyAlert } from '../property-alerts/alert';
import { useRect } from '@dx-ui/utilities-use-rect';
import { NavUtility } from '../global/nav-utility';
import { NavDesktop } from '../global/nav-desktop';
import { NavMobile } from '../global/nav-mobile';
import { useTranslation } from 'next-i18next';
import Link from '../link-without-prefetch';
import cx from 'classnames';
import OscComposableSearchForm from '../osc-composable/osc-composable-search-form';

export function Header({
  activePageId,
  pageSlug,
  menu,
  iconMenu,
  languageMenu,
  locale,
  isFrontPage,
  navKey,
  defaultArrivalDate,
  gmtHours,
  associatedHotels,
  hotelDates,
  hotelLogo,
  brandLogo,
  CTYHOCN,
  brandCode,
  currency,
  bookingButtonText,
  hotelAlerts,
  alertBarSettings,
  title,
  headerSettings,
  modalSettings,
  styleOptions,
  cmsLogoSettings,
  cmsTranslationOverrides,
  bookingWidgetConfig,
  maxRooms,
  groupBooking,
  hideGroupLink,
}: MenuProps) {
  const { itemPrefix: urlPrefix } = useLinkContext();
  const [headerActive, setHeaderActive] = useState(false);
  const [isScrolledPastHeaderContent, setIsScrolledPastHeaderContent] = useState(false);
  const [isScrolledPastHeader, setIsScrolledPastHeader] = useState(false);
  const [showHeaderContent, setShowHeaderContent] = useState(true);
  const [disableStickyHeader, setDisableStickyHeader] = useState(false);
  const headerContentRef = useRef<HTMLDivElement | null>(null);
  const headerContentRect = useRect({ ref: headerContentRef });
  const shopFormRef = useRef<HTMLDivElement | null>(null);
  const shopFormRect = useRect({ ref: shopFormRef });

  const lang = useLanguage();
  const { t } = useTranslation();
  const isMobile = useDeviceDetect()?.isMobile;
  const scrollDistance = useWindowScroll(isMobile ? 30 : 5).scrollY || 0;
  const scrollDirection = useWindowScroll(isMobile ? 30 : 5).scrollDirection || 'down';
  const isDesktopOrLarger: boolean = useWindowSize()?.width >= 1280;
  const shopFormHeight = shopFormRect?.height || 0;
  const headerContentHeight = headerContentRect?.height || 0;
  const isShopFormInHeader =
    bookingWidgetConfig?.shopFormInHeader && !styleOptions?.bookingButton?.hideBookingWidget;
  const showHeader =
    isShopFormInHeader &&
    (!isDesktopOrLarger
      ? !isScrolledPastHeader
        ? isScrolledPastHeader
        : !showHeaderContent
      : !showHeaderContent) &&
    !disableStickyHeader;

  useInitializeConductrics();

  useEffect(() => {
    const isTooLargeForStickyHeader = shopFormHeight > (window?.innerHeight || 0) * 0.33;
    setDisableStickyHeader(
      (!isShopFormInHeader && !isDesktopOrLarger) ||
        (isShopFormInHeader && isTooLargeForStickyHeader)
    );

    if (scrollDistance > headerContentHeight) {
      setIsScrolledPastHeaderContent(true);
      if (isShopFormInHeader) {
        if (scrollDirection === 'up') {
          setShowHeaderContent(true);
        } else if (scrollDirection === 'down') {
          setShowHeaderContent(false);
        }
      }
    } else {
      setIsScrolledPastHeaderContent(false);
    }
    if (scrollDistance > shopFormHeight + headerContentHeight) {
      setIsScrolledPastHeader(true);
    } else {
      setIsScrolledPastHeader(false);
    }
  }, [
    scrollDistance,
    headerContentRect,
    shopFormRect,
    isShopFormInHeader,
    scrollDirection,
    isDesktopOrLarger,
    shopFormHeight,
    headerContentHeight,
  ]);

  useEffect(() => {
    const hotelLogo = document?.getElementById('hotel-logo');
    const brandLogo = document?.getElementById('brand-logo');
    if (cmsLogoSettings?.hotelLogo?.sourceUrl && hotelLogo) {
      isScrolledPastHeaderContent || headerActive
        ? (hotelLogo.style.backgroundColor = cmsLogoSettings?.hotelLogoColourActive || '')
        : (hotelLogo.style.backgroundColor = cmsLogoSettings?.hotelLogoColourInactive || '');
    }

    if (cmsLogoSettings?.brandLogo?.sourceUrl && brandLogo) {
      isScrolledPastHeaderContent || headerActive
        ? (brandLogo.style.backgroundColor = cmsLogoSettings?.brandLogoColourActive || '')
        : (brandLogo.style.backgroundColor = cmsLogoSettings?.brandLogoColourInactive || '');
    }

    // Removes focus from any page elements upon a new page load.
    if (document.activeElement instanceof HTMLElement) {
      if (document.activeElement.id === 'logoLink') document.activeElement.blur();
    }
  }, [
    cmsLogoSettings?.brandLogo?.sourceUrl,
    cmsLogoSettings?.brandLogoColourActive,
    cmsLogoSettings?.brandLogoColourInactive,
    cmsLogoSettings?.hotelLogo?.sourceUrl,
    cmsLogoSettings?.hotelLogoColourActive,
    cmsLogoSettings?.hotelLogoColourInactive,
    headerActive,
    isScrolledPastHeaderContent,
  ]);

  // Set Nav appearance colors based on either CMS controls or hard-coded app props
  const headerCmsSettings = headerSettings?.SettingsHeaderSettings;
  // Background
  const headerBgOnLoad = headerCmsSettings?.backgroundColour || styleOptions?.bgColorOnLoad;
  let headerBgOnActive = headerCmsSettings?.backgroundColourActive || styleOptions?.bgColorOnActive;
  // bg blur
  const bgBlur = headerCmsSettings?.backgroundBlur;
  const bgBlurActive = headerCmsSettings?.backgroundBlurActive;
  // If there is no active background color but there is a color set for load, use the load color for the active state as well.
  if (
    headerBgOnLoad &&
    !headerCmsSettings?.backgroundColourActive &&
    !styleOptions?.bgColorOnActive
  ) {
    headerBgOnActive = headerBgOnLoad;
  }

  // Text
  const textColourOnLoad = headerCmsSettings?.textColour || styleOptions?.textColorOnLoad;
  const textColourOnActive = headerCmsSettings?.textColourActive || styleOptions?.textColorOnActive;

  // Additional items - navigation
  const mainNavStyles = headerCmsSettings?.mainNavStyles;
  const mainNavUnderlineColor =
    mainNavStyles?.menuItemUnderlineColour || styleOptions?.menuItems?.underline?.color;
  const mainNavUnderlineActive =
    mainNavStyles?.menuItemUnderlineToggle || styleOptions?.menuItems?.underline?.active;
  const mainNavSubNavBgColor =
    mainNavStyles?.submenuBackgroundColour || styleOptions?.bgSubmenuColor;
  const fontWeight = mainNavStyles?.fontWeight || styleOptions?.fontWeight;
  const fontCase = mainNavStyles?.fontCase || styleOptions?.menuItems?.case;
  const fontSize = mainNavStyles?.fontSize || styleOptions?.menuItems?.fontSize;
  let itemPadding = mainNavStyles?.menuItemPadding || styleOptions?.menuItems?.paddingClasses;
  if (!itemPadding) itemPadding = `px-2 xl:px-3 py-2`;
  // Underline, Background Colour, nothing
  const activeMenuItemDisplayType = mainNavStyles?.menuItemActiveState || '';
  // Icon Nav Underline
  const cmsIconNavUnderline = headerCmsSettings?.iconNavStyles?.underline;
  let iconNavItemUnderline =
    !cmsIconNavUnderline || cmsIconNavUnderline === undefined ? false : true;
  // CMS control for underline, which should take precedence
  const styleOptIconNavUnderline = styleOptions?.iconMenu?.underline;
  iconNavItemUnderline =
    !styleOptIconNavUnderline || styleOptIconNavUnderline === undefined ? false : true;

  const headerBgColors =
    headerActive || isScrolledPastHeaderContent
      ? headerBgOnActive || 'rgba(10, 57, 113, 0.98)'
      : headerBgOnLoad || 'rgba(42, 58, 78, 0.7)';
  const headerBgBlur = headerActive || isScrolledPastHeaderContent ? bgBlurActive : bgBlur;

  const mainNavSubMenuBgBlur = mainNavStyles?.submenuBackgroundBlur;
  const mainNavSubMenuTextColour = mainNavStyles?.submenuTextColour
    ? mainNavStyles?.submenuTextColour
    : textColourOnActive;

  const hasSubmenuDropShadowHidden = mainNavStyles?.hideSubmenuDropShadow;

  const headerTextColors =
    headerActive || isScrolledPastHeaderContent
      ? textColourOnActive || '#fff'
      : textColourOnLoad || '#fff';
  const headerDividerColors =
    (headerActive || isScrolledPastHeaderContent
      ? headerCmsSettings?.dividerColourActive
      : headerCmsSettings?.dividerColour) || headerTextColors;
  const headerIconMenuColors =
    headerActive || isScrolledPastHeaderContent
      ? textColourOnActive || '#fff'
      : textColourOnLoad || '#fff';

  const languageSelectorLabelColor =
    headerCmsSettings?.languageSwitchTitleStyles?.textColour || headerTextColors;

  // Header Shop Form Background - uses its own color & blur settings but will default to the rest of the header if bg color isn't provided
  const shopFormBgColor = bookingWidgetConfig?.shopFormBackgroundColor;
  const shopFormBgBlur = bookingWidgetConfig?.shopFormBackgroundBlur;

  const hideSignInBorder = iconMenu?.length > 0;

  const iconNavProps = {
    iconMenu,
    activeHeader: setHeaderActive,
    urlPrefix,
    stylingOptions: {
      extraClasses: styleOptions?.iconMenu?.extraClasses,
      fontSize: styleOptions?.iconMenu?.fontSize,
      color:
        headerActive || isScrolledPastHeaderContent
          ? styleOptions?.iconMenu?.activeColor || headerIconMenuColors
          : styleOptions?.iconMenu?.color || headerIconMenuColors,
      iconColor:
        headerActive || isScrolledPastHeaderContent
          ? styleOptions?.iconMenu?.activeIconColor || headerIconMenuColors
          : styleOptions?.iconMenu?.iconColor || headerIconMenuColors,
      separatorColor: styleOptions?.separatorColor,
      underline: iconNavItemUnderline,
      underlineHover: styleOptions?.iconMenu?.underlineHover,
    },
  };

  const LanguageSelectorStyleProps = {
    textColour:
      headerActive || isScrolledPastHeaderContent
        ? headerSettings?.SettingsHeaderSettings?.languageSwitchButtonStylesActive?.textColour ||
          headerCmsSettings?.languageSwitchButtonStyles?.textColour
        : headerCmsSettings?.languageSwitchButtonStyles?.textColour,
    textCase: headerCmsSettings?.languageSwitchButtonStyles?.textCase,
    backgroundColour:
      headerActive || isScrolledPastHeaderContent
        ? headerSettings?.SettingsHeaderSettings?.languageSwitchButtonStylesActive
            ?.backgroundColour || headerCmsSettings?.languageSwitchButtonStyles?.backgroundColour
        : headerCmsSettings?.languageSwitchButtonStyles?.backgroundColour,
    textColourHover:
      headerActive || isScrolledPastHeaderContent
        ? headerSettings?.SettingsHeaderSettings?.languageSwitchButtonStylesActive
            ?.textColourHover || headerCmsSettings?.languageSwitchButtonStyles?.textColourHover
        : headerCmsSettings?.languageSwitchButtonStyles?.textColourHover,
    backgroundColourHover:
      headerActive || isScrolledPastHeaderContent
        ? headerSettings?.SettingsHeaderSettings?.languageSwitchButtonStylesActive
            ?.backgroundColourHover ||
          headerCmsSettings?.languageSwitchButtonStyles?.backgroundColourHover
        : headerCmsSettings?.languageSwitchButtonStyles?.backgroundColourHover,
    labelTextColour:
      headerActive || isScrolledPastHeaderContent
        ? headerCmsSettings?.languageSwitchTitleStyles?.textColourActive ||
          languageSelectorLabelColor
        : languageSelectorLabelColor,
    labelTextCase: headerCmsSettings?.languageSwitchTitleStyles?.textCase,
    defaultTextColour: headerTextColors,
    accentColour: languageMenu?.colors?.languageNavAccentColour,
  };

  const shopFormStyle: ShopFormInlineStyles = {
    borderColor: headerDividerColors,
    '--border-color': headerDividerColors,
    backgroundColor: shopFormBgColor,
    backdropFilter: `blur(${shopFormBgBlur || '0'}px)`,
  };

  const headerTransitionClasses =
    'transition duration-200 ease-in-out motion-reduce:transition-none';

  return (
    <div className="relative">
      {/* skip to content */}
      <a id="skip-to-content" href="#main" className="skip-to-content block size-0 overflow-hidden">
        {t('skipToContent')}
      </a>
      <div
        className={cx(headerTransitionClasses, 'relative min-h-0 duration-100')}
        style={{
          minHeight:
            !isDesktopOrLarger &&
            !disableStickyHeader &&
            `${shopFormHeight + headerContentHeight}px`,
        }}
      />
      <header
        onMouseEnter={() => setHeaderActive(true)}
        onMouseLeave={() => setHeaderActive(false)}
        data-active={headerActive || isScrolledPastHeaderContent}
        data-testid="header-wrapper"
        className={cx(
          headerTransitionClasses,
          styleOptions?.headerClassName,
          'cp-header z-50 w-full [&:not(:last-child)]:will-change-transform duration-500 ease-out',
          {
            'cp-header--scroll': isScrolledPastHeaderContent,
            'cp-header--active': headerActive,
            'fixed top-0': !disableStickyHeader,
            relative: disableStickyHeader,
          }
        )}
        style={{
          transform: showHeader ? `translateY(-${headerContentHeight || 0}px)` : 'none',
        }}
      >
        <div ref={headerContentRef} data-element-id="cp-header-top-content">
          {/* Apply or separate background settings for the core header and header sticky shop form based on whether the latter has its own settings */}
          <div
            className={cx(
              'absolute inset-0 -z-10 bg-transparent',
              !shopFormBgColor && headerTransitionClasses
            )}
            style={{
              background: !shopFormBgColor && headerBgColors,
              backdropFilter: !shopFormBgColor && `blur(${headerBgBlur || '0'}px)`,
            }}
          />
          {pageSlug ? (
            <PropertyAlert
              {...hotelDates}
              alerts={hotelAlerts}
              alertBarSettings={alertBarSettings}
              modalSettings={modalSettings}
            />
          ) : null}
          <div data-element-id="cp-header-core-wrapper" className={cx('relative bg-transparent')}>
            <div className="relative flex justify-end pb-4">
              <div
                role="application"
                className="container absolute inset-x-0 top-0 z-10 border-b pb-1 sm:border-b-0"
                style={{ borderColor: headerDividerColors }}
              >
                <div
                  className={`flex justify-end ${styleOptions?.iconMenu?.fontSize || 'text-sm'}`}
                >
                  <NavUtility {...iconNavProps} />
                  <HonorsSignIn
                    hideBorder={hideSignInBorder}
                    fontSize={styleOptions?.iconMenu?.fontSize}
                    underline={iconNavItemUnderline}
                    textColor={
                      headerActive || isScrolledPastHeaderContent
                        ? styleOptions?.iconMenu?.activeColor || headerIconMenuColors
                        : styleOptions?.iconMenu?.color || headerIconMenuColors
                    }
                    iconColor={
                      headerActive || isScrolledPastHeaderContent
                        ? styleOptions?.iconMenu?.activeIconColor || headerIconMenuColors
                        : styleOptions?.iconMenu?.iconColor || headerIconMenuColors
                    }
                    ddTextColor={styleOptions?.honorsSignIn?.textColor}
                    ddTextHighlightColor={styleOptions?.honorsSignIn?.textHighlightColor}
                    ddBackgroundColor={styleOptions?.honorsSignIn?.backgroundColor}
                    ddBackgroundHighlightColor={
                      styleOptions?.honorsSignIn?.backgroundHighlightColor
                    }
                  />
                  <OSCLanguageSelector
                    locale={locale || ''}
                    CTYHOCN={CTYHOCN}
                    languageSelectorStyles={LanguageSelectorStyleProps}
                    cmsTranslationOverrides={cmsTranslationOverrides}
                  />
                </div>
              </div>
            </div>
            <div
              className="container flex h-32 items-center  justify-between border-current pt-8 sm:h-28 sm:pt-0"
              style={{ color: headerTextColors }}
            >
              {/* LOGO */}
              <section
                className={cx('flex cursor-pointer outline-none', {
                  'pointer-events-none': isFrontPage,
                })}
              >
                <Link href={`/${lang}${urlPrefix}`} data-testid="logo-link" id="logoLink">
                  <div className="flex items-center justify-center">
                    {hotelLogo ? (
                      <div id="hotel-logo-button" data-testid="hotel-logo">
                        {hotelLogo}
                        <span className="sr-only">{title}</span>
                      </div>
                    ) : (
                      false
                    )}
                    {brandLogo ? (
                      <div
                        data-testid="brand-logo"
                        className="border-l"
                        style={{
                          borderColor: headerTextColors,
                        }}
                      >
                        {brandLogo}
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </Link>
              </section>

              {/* MAIN NAV / ICON NAV INLINE / BOOKING BUTTON */}
              <section
                className={cx(
                  'mt-0 flex size-full items-center py-4 sm:mt-4 sm:py-0 rtl:lg:w-auto'
                )}
              >
                <div className="h-auto w-full sm:h-full rtl:lg:w-auto">
                  <div
                    className={`-mt-1 flex size-full xl:hidden ${styleOptions?.navStyles || ''}`}
                  >
                    <NavMobile
                      key={navKey + '-nav-mobile'}
                      menuItems={menu}
                      iconMenu={iconMenu}
                      isShopFormInHeader={
                        bookingWidgetConfig?.shopFormInHeader &&
                        !styleOptions?.bookingButton?.hideBookingWidget
                      }
                      stylingOptions={{
                        fontWeight: `font-${fontWeight}`,
                        case: fontCase || '',
                        bgColor: headerBgOnActive || headerBgOnLoad,
                        accentColor: mainNavUnderlineColor,
                      }}
                    />
                  </div>
                  <div
                    className={cx(
                      'hidden h-full xl:flex xl:w-full rtl:w-auto rtl:lg:inline-flex',
                      styleOptions?.navStyles ? styleOptions?.navStyles : '2xl:text-lg'
                    )}
                  >
                    <NavDesktop
                      key={navKey + '-nav-desktop'}
                      activePageId={activePageId}
                      menuItems={menu}
                      urlPrefix={urlPrefix}
                      activeHeader={setHeaderActive}
                      stylingOptions={{
                        bgSubmenuColor: mainNavSubNavBgColor,
                        colorOnHover: styleOptions?.menuItems?.colorOnHover,
                        underlineActive: mainNavUnderlineActive,
                        underlineColor: mainNavUnderlineColor,
                        underlineSize: styleOptions?.menuItems?.underline?.size,
                        headerActiveStateTextColor: textColourOnActive,
                        itemActiveClasses: styleOptions?.menuItems?.itemActive?.classes,
                        itemActiveBgColor: styleOptions?.menuItems?.itemActive?.bgColor,
                        itemActiveTextColor: styleOptions?.menuItems?.itemActive?.bgText,
                        fontWeight,
                        paddingClasses: itemPadding || 'px-2',
                        case: fontCase || '',
                        fontSize: fontSize || '',
                        activeMenuItemDisplayType,
                        bgSubmenuBlur: mainNavSubMenuBgBlur,
                        bgSubmenuTextColour: mainNavSubMenuTextColour,
                        hasSubmenuDropShadowHidden: hasSubmenuDropShadowHidden,
                      }}
                      currentPage={pageSlug}
                    />
                  </div>
                </div>
                <div
                  className={cx(
                    'cp-header-navUtilities flex items-center rtl:lg:w-full rtl:lg:justify-between',
                    !hotelDates?.resEnabled ? 'pt-4' : ''
                  )}
                >
                  {(hotelDates?.resEnabled || associatedHotels?.length > 0) &&
                    !styleOptions?.bookingButton?.hideBookingWidget &&
                    !bookingWidgetConfig?.shopFormInHeader && (
                      <div
                        data-testid="booking-button-wrapper"
                        className={cx('text-text flex-col justify-center')}
                      >
                        <BookingWidgetButton
                          CTYHOCN={CTYHOCN}
                          brandCode={brandCode}
                          currency={currency}
                          associatedHotels={associatedHotels}
                          text={bookingButtonText ? bookingButtonText : t('makeAReservation')}
                          mobileText={t('book')}
                          defaultArrivalDate={defaultArrivalDate}
                          gmtHours={gmtHours}
                          mainClasses={styleOptions?.bookingButton?.mainClasses}
                          className={styleOptions?.bookingButton?.extraClasses}
                          activeHeader={setHeaderActive}
                          modalSettings={modalSettings}
                          styleOptions={{
                            buttonStyle: styleOptions?.bookingButton?.buttonStyle || null,
                            backgroundColor: styleOptions?.bookingButton?.backgroundColor || null,
                            backgroundColorHover:
                              styleOptions?.bookingButton?.backgroundColorHover || null,
                            textColor: styleOptions?.bookingButton?.textColor || null,
                            textColorHover: styleOptions?.bookingButton?.textColorHover || null,
                            borderColor: styleOptions?.bookingButton?.borderColor || null,
                            borderColorHover: styleOptions?.bookingButton?.borderColorHover || null,
                            textCase: styleOptions?.bookingButton?.textCase || undefined,
                          }}
                          cmsBookStyle={
                            headerSettings?.SettingsHeaderSettings?.bookingWidgetButtonStyles
                          }
                          oscBookBtnGlobalSetting={bookingWidgetConfig?.oscBookButtonStyle}
                          maxRooms={maxRooms}
                          groupBooking={groupBooking}
                          hideGroupLink={hideGroupLink}
                        />
                      </div>
                    )}
                </div>
              </section>
            </div>
            <div
              data-element-id="cp-header-top-section-blur"
              className={cx(
                'absolute bottom-0 end-0 -z-10 size-full',
                shopFormBgColor && headerTransitionClasses
              )}
              style={{
                background: shopFormBgColor && headerBgColors,
                backdropFilter: shopFormBgColor && `blur(${headerBgBlur || '0'}px)`,
              }}
            />
          </div>
        </div>
        {isShopFormInHeader && (
          <div
            ref={shopFormRef}
            data-testid="shopFormInHeader"
            className="cp-header-shop-form border-t py-2"
            style={shopFormStyle}
          >
            <OscComposableSearchForm
              ctyhocn={bookingWidgetConfig?.ctyhocn}
              defaultArrivalDate={bookingWidgetConfig?.defaultArrivalDate}
              gmtHours={bookingWidgetConfig?.gmtHours}
              brandCode={bookingWidgetConfig?.brandCode}
              currency={bookingWidgetConfig?.currency}
              associatedHotels={associatedHotels}
              oscBookButtonStyle={
                bookingWidgetConfig?.brandCode === 'WA'
                  ? ''
                  : bookingWidgetConfig?.oscBookButtonStyle
              }
              maxRooms={maxRooms}
              groupBooking={groupBooking}
              hideGroupLink={hideGroupLink}
            />
          </div>
        )}
      </header>
    </div>
  );
}
