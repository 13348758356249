import gql from 'graphql-tag-transform.macro';
import { iconGridSettings } from './common-vars';

export const IconGrid = gql`
fragment IconGrid on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_IconBlock {
        fieldGroupName
        header
        headerCopy
        iconColumnCount
        iconSize
        iconRepeater {
          fieldGroupName
          iconList
          iconLabel
          iconColor
          labelColor
        }
        iconBlockComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${iconGridSettings}
        }
      }
}
`;
