import { type SVGAttributes } from 'react';

const LoungeIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="currentColor"
      >
        <path d="M36.87 17.268v-2.035a6.5 6.5 0 0 0-6.5-6.501H14.16a6.5 6.5 0 0 0-6.502 6.501l.002 2.035" />
        <path d="M39.797 20.636a3.117 3.117 0 1 0-6.042-1.09l-1.48 3.32c-.111-.07-4.818-1.695-9.926-1.695-5.108 0-9.992 1.632-10.106 1.702l-1.404-3.112a3.114 3.114 0 0 0-2.983-2.25 3.124 3.124 0 0 0-3.124 3.125c0 1.436.974 2.633 2.295 2.999l1.929 4.968c.61 2.111 3.627 2.08 3.627 2.08h19.363s3.018.031 3.627-2.08l1.93-4.968a3.118 3.118 0 0 0 2.294-2.999z" />
        <path d="M31.805 30.704l1.803 3.623a1.35 1.35 0 1 0 2.416-1.209l-1.534-3.085M12.724 30.704l-1.803 3.623a1.35 1.35 0 0 1-2.416-1.209l1.534-3.085" />
      </g>
    </svg>
  );
};

export default LoungeIcon;
