import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./pvrpmci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="w-16 fill-current text-current antialiased sm:w-24 lg:w-32"
    fillColor="#B08D59"
  />
);

export const hotelLogoFooter = <HotelLogo className="w-32 fill-current  text-dark antialiased" />;
