import { ComboboxOption } from '@headlessui/react';
import type { FormInputBase } from '@dx-ui/osc-form';
import type {
  LocationTypes,
  PlacesAutocompleteSuggestion,
} from '@dx-ui/framework-places-autocomplete';
import { LocationIcons } from './icons.constant';
import Icon from '@dx-ui/osc-icon';

type PlacesAutoCompleteSuggestionOptions = FormInputBase<
  Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onSelect'>
>;

const PlacesAutoCompleteSuggestionOptions = ({
  suggestions,
  header = '',
}: {
  header?: string;
  suggestions?: PlacesAutocompleteSuggestion[];
}) => {
  if (!suggestions) return null;
  return (
    <div>
      {suggestions.map((suggestion, index: number) => {
        return (
          <ComboboxOption
            key={suggestion.description}
            value={suggestion.description}
            className="cursor-pointer px-4 py-2 data-[focus]:bg-bg-primary data-[selected]:bg-bg-alt highlighted:bg-bg-alt"
            aria-setsize={suggestions.length}
            aria-posinset={index + 1}
            data-testid={header ? header : undefined}
          >
            <AutoCompleteSuggestionOptionsWrapper
              iconType={suggestion?.type}
              header={header && !!suggestions.length && index === 0 ? header : undefined}
            >
              <>
                <span className="text-base" data-testid="autocomplete-text">
                  {suggestion.formattedSuggestion.mainText}
                </span>
                <span className="text-xs text-text-alt">
                  {suggestion.formattedSuggestion.secondaryText}
                </span>
              </>
            </AutoCompleteSuggestionOptionsWrapper>
          </ComboboxOption>
        );
      })}
    </div>
  );
};

const AutoCompleteSuggestionOptionsWrapper = ({
  header,
  iconType,
  children,
}: {
  header?: string;
  iconType?: string;
  children: React.ReactNode;
}) => {
  const locationIconName =
    iconType && iconType in LocationIcons ? LocationIcons[iconType as LocationTypes] : 'location';
  return (
    <>
      {header ? (
        <div className="flex min-w-[226px] pb-2 pr-1 ltr:text-left rtl:ps-2">
          <span className="text-text-alt">{header}</span>
        </div>
      ) : null}
      <div className="flex min-w-[226px]">
        <div className="flex shrink-0 justify-center" aria-hidden>
          <Icon name={locationIconName} size="lg" />
        </div>
        <div className="flex flex-col ps-2 ltr:text-left">{children}</div>
      </div>
    </>
  );
};

export { PlacesAutoCompleteSuggestionOptions, AutoCompleteSuggestionOptionsWrapper };
export default PlacesAutoCompleteSuggestionOptions;
