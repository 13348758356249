export type SupportedBrand = 'EY' | 'ES' | 'HP';

export const EYIconNames = [
  'breakfast',
  'fitness',
  'grill',
  'kitchen',
  'laundry',
  'pet-friendly',
  'studio',
] as const;

export const ESIconNames = [
  'breakfast',
  'drink',
  'fitness',
  'breakfast_inverted',
  'drink_inverted',
  'fitness_inverted',
] as const;

export const HPIconNames = ['hex', 'hex_inverted'] as const;

export const EYIconSetNames = new Set([...EYIconNames]);

export const ESIconSetNames = new Set([...ESIconNames]);

export const HPIconSetNames = new Set([...HPIconNames]);
