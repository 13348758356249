import type { QueryClient } from '@tanstack/react-query';
import {
  serverSideGetSpeedBumpFeatureConfigsQuery,
  useGetSpeedBumpFeatureConfigsQuery,
} from '../gql/queries';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';
import { CDN_STALE_TIME } from '@dx-ui/framework-react-query';

const TRANSLATE_RULES_CONFIG_NAME = 'rule-ui-translate-urls';
const ONELINK_CONFIG_NAME = 'config-ui-translate-onelink';

export const names = [TRANSLATE_RULES_CONFIG_NAME, ONELINK_CONFIG_NAME];

export function useTranslationsConfig({ isEnabled }: { isEnabled?: boolean } = {}) {
  const { data } = useGetSpeedBumpFeatureConfigsQuery(
    {
      names,
    },
    {
      enabled: isEnabled,
      staleTime: CDN_STALE_TIME,
    }
  );
  const translateRules =
    data?.featureConfigs?.find((config) => config.name === TRANSLATE_RULES_CONFIG_NAME) || null;
  const oneLinkConfig =
    (data?.featureConfigs?.find((config) => config.name === ONELINK_CONFIG_NAME)
      ?.config as OneLinkConfig) || null;

  return { oneLinkConfig, translateRules };
}

export const getSpeedBumpFeatureConfig = (queryClient: QueryClient) => {
  return serverSideGetSpeedBumpFeatureConfigsQuery(queryClient, { names });
};
