import type { AdditionalHeadProps } from '../pages/_app';

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  switch (pageSlug) {
    case 'ribs-brews':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/avf8bst.css" />
          <link rel="stylesheet" href="https://use.typekit.net/avf8bst.css" />
          <style>{`
                  div#main {
                    font-family: halyard-text!important;
                    font-weight: 700;
                    font-style: normal;
                  }
                .font-headline {
                    font-family: "instrument-serif", serif!important;
                    font-weight: 400;
                    font-style: italic;
                  }
                span[data-element-id="subtitle-wrapper"],
                .htmlMenuSubtitle {
                    font-family: "instrument-serif", serif!important;
                    font-weight: 400;
                    font-style: italic;
                  }    
                `}</style>
        </>
      );
  }
};
