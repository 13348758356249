import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./nycnhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-20 w-32 antialiased sm:h-24" fillColor="#ffffff" />
);

export const hotelLogoFooter = <HotelLogo className="w-32 antialiased" fillColor="#ffffff" />;
