import type { ReactElement } from 'react';
import cx from 'classnames';
import { HandleWYSIWYGContent, HandleAnimations, useWindowSize } from '../functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { HeadingStyle } from '../includes/heading-style';
import { GIS_TextAlignment } from '../functions/global-instance-styles';
import { CroppedImages } from './cropped-images';
import { CopyTable } from '../copy-table';
import { AnchorLink } from '../global/anchor-link';
import contentStyles from '../css/contentStyle.module.css';
import type { ComponentContentProps } from './media-and-copy-carousel.types';

export function ComponentContent({
  repeater,
  offset,
  layoutDirection,
  inlineStyles,
  layoutMode,
  sizes,
  header,
  headingValue,
  nextImage,
  currentIndex,
  navAnimationDir,
  nextTimeout,
  getNextIndex,
  getPrevIndex,
}: ComponentContentProps): ReactElement {
  const wWidth: number = useWindowSize().width || 0;
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const slideAnimation: string = inlineStyles?.sliderAnimations || 'fade';
  let mediaSize: string, copySize: string;
  switch (layoutMode) {
    case '50 / 50':
      mediaSize = 'md:w-1/2';
      copySize = 'md:w-1/2';
      break;
    case '60 / 40':
      mediaSize = 'md:w-3/5';
      copySize = 'md:w-2/5';
      break;
    case '70 / 30':
      mediaSize = 'md:w-3/5 lg:w-8/12';
      copySize = 'md:w-2/5 lg:w-4/12';
      break;
    default:
      mediaSize = 'md:w-1/2';
      copySize = 'md:w-1/2';
  }

  const images = CroppedImages({
    repeater,
    currentIndex,
    slideAnimation,
    navAnimationDir,
    nextImage,
    sizes,
    nextTimeout,
    getNextIndex,
    getPrevIndex,
    contentBackgroundPosition: inlineStyles?.contentBackgroundPosition,
  });

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : layoutDirection?.substring(0, 4) === 'copy'
      ? 'lg:-translate-x-8'
      : 'lg:translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
    delayFour: 'delay-700',
    delayFive: 'delay-1000',
  });

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div
          data-element-id="media-copy-carousel-content-wrapper"
          className={cx('space-around inset-0 flex flex-wrap items-stretch justify-around')}
          style={{ backgroundColor: inlineStyles?.contentBackgroundColor }}
        >
          <div
            data-testid="mediaAndCopyCarouselMediaContainer"
            data-element-id="media-and-copy-carousel-media"
            className={cx(
              'w-full scale-100 transition-all duration-700 ease-in-out md:min-h-[630px]',
              'lg:' + offset,
              layoutDirection === 'copy / media' && 'md:order-1',
              mediaSize
            )}
            style={{ height: wWidth >= 768 ? sizes?.height : 'auto' }}
          >
            {wWidth >= 768 ? images?.desktop : images?.mobile}
          </div>
          <div
            ref={animations?.ref}
            data-element-id="media-and-copy-carousel-copy"
            className={cx(
              'text-bg-inverse flex h-auto w-full flex-col justify-center p-4 font-sans md:px-8 lg:px-12 xl:px-16',
              layoutDirection === 'copy / media' && 'order-none',
              copySize
            )}
            style={{
              backgroundImage: inlineStyles?.contentBackgroundImage
                ? `url(${appliedCloudinaryParams(
                    inlineStyles?.contentBackgroundImage?.sourceUrl,
                    inlineStyles?.contentBackgroundRepeat
                  )})`
                : undefined,
              backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
              backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
              backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
              alignItems: inlineStyles?.textAlignment || 'items-start',
              textAlign: textAlignment || 'left',
              borderColor: inlineStyles?.componentBackgroundColor || '#fff',
            }}
          >
            <HeadingStyle
              text={
                inlineStyles?.headingsNoTranslate
                  ? repeater?.[nextImage?.ind]?.title_noTx
                  : repeater?.[nextImage?.ind]?.title
              }
              styledAs="h2"
              type={
                header && !headingValue
                  ? 'h3'
                  : header && headingValue === 'h2'
                  ? 'h3'
                  : header && headingValue === 'h3'
                  ? 'h4'
                  : header && headingValue === 'h4'
                  ? 'h5'
                  : !header && headingValue
                  ? headingValue
                  : 'h2'
              }
              className={cx('mb-4 mt-1', animations?.one, {
                OneLinkNoTx: inlineStyles?.headingsNoTranslate,
              })}
              textColorInline={inlineStyles?.titleColor}
            />
            <div
              className={cx(
                'mb-4 text-lg',
                animations?.two,
                contentStyles.listStyle,
                contentStyles.paragraphStyle
              )}
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  repeater?.[nextImage?.ind]?.copy,
                  inlineStyles?.textColor
                ),
              }}
            />
            {repeater?.[nextImage?.ind]?.tableData && (
              <div className={cx(animations?.three, 'w-full')}>
                <CopyTable
                  repeater={repeater?.[nextImage?.ind]?.tableData}
                  textColor={inlineStyles?.textColor}
                />
              </div>
            )}
            {repeater?.[nextImage?.ind]?.singleLink && (
              <div
                className={cx('-mt-2 pb-2.5', animations?.four)}
                style={{ color: inlineStyles?.textColor }}
              >
                <AnchorLink
                  key="singleLink"
                  url={repeater?.[nextImage?.ind]?.singleLink?.url}
                  title={repeater?.[nextImage?.ind]?.singleLink?.title}
                  target={repeater?.[nextImage?.ind]?.singleLink?.target}
                  buttonStyle="plain"
                />
              </div>
            )}
            {repeater?.[nextImage?.ind]?.buttons ? (
              <div className={cx('mt-4', animations?.five)}>
                {repeater?.[nextImage?.ind]?.buttons?.map((link, key) => {
                  return (
                    <AnchorLink
                      key={key}
                      url={link?.link?.url}
                      title={link?.link?.title}
                      target={link?.link?.target}
                      buttonStyle={link?.buttonStyle ?? 'primary'}
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
