import { PatchworkGrid as OscPatchworkGrid, type PatchworkGridType } from '@dx-ui/patchwork-grid';
import { HandleAnimations, HandleWYSIWYGContent } from '../functions/helper';
import type { ACFAnchorProps } from '../global/anchor-link';
import { AnchorLink } from '../global/anchor-link';
import { appliedCloudinaryParams, makeRandomInt } from '@curated-property/utils';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_merge, GIS_Padder, GIS_TextAlignment } from '../functions/global-instance-styles';
import cx from 'classnames';
import { HeadingStyle } from '../includes/heading-style';
import { customLoader } from '../cropped-image/cropped-image';
import styles from '../css/contentStyle.module.css';
import { useEffect } from 'react';

export interface PatchworkGridCMSProps {
  horizontalDirection?: string;
  repeater?: {
    title?: string;
    copy?: string;
    buttons?: Array<ACFAnchorProps>;
    image1?: {
      altText?: string;
      title?: string;
      sourceUrl?: string;
    };
    image2?: {
      altText?: string;
      title?: string;
      sourceUrl?: string;
    };
    image3?: {
      altText?: string;
      title?: string;
      sourceUrl?: string;
    };
    image1CaptionImageCaption?: {
      captionLink?: {
        url?: string;
      };
      captionText?: string;
    };
    image2CaptionImageCaption?: {
      captionLink?: {
        url?: string;
      };
      captionText?: string;
    };
    image3CaptionImageCaption?: {
      captionLink?: {
        url?: string;
      };
      captionText?: string;
    };
    image1CroppingEnableCropping?: boolean;
    image1CroppingOrientation?: string;
    image1CroppingCropType?: string;
    image1CroppingXPosition?: string;
    image1CroppingXPositionAdvanced?: string;
    image1CroppingYPosition?: string;
    image1CroppingYPositionAdvanced?: string;
    image1CroppingCropWidth?: string;
    image1CroppingCropHeight?: string;
    image1CroppingAutoPosition?: boolean;
    image2CroppingEnableCropping?: boolean;
    image2CroppingOrientation?: string;
    image2CroppingCropType?: string;
    image2CroppingXPosition?: string;
    image2CroppingXPositionAdvanced?: string;
    image2CroppingYPosition?: string;
    image2CroppingYPositionAdvanced?: string;
    image2CroppingCropWidth?: string;
    image2CroppingCropHeight?: string;
    image2CroppingAutoPosition?: boolean;
    image3CroppingEnableCropping?: boolean;
    image3CroppingOrientation?: string;
    image3CroppingCropType?: string;
    image3CroppingXPosition?: string;
    image3CroppingXPositionAdvanced?: string;
    image3CroppingYPosition?: string;
    image3CroppingYPositionAdvanced?: string;
    image3CroppingCropWidth?: string;
    image3CroppingCropHeight?: string;
    image3CroppingAutoPosition?: boolean;
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export interface PatchworkGridProps {
  rows: PatchworkGridCMSProps['repeater'];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function PatchworkGridPropMapper(
  flex: PatchworkGridCMSProps & {
    patchworkGridSettings?: StyleObject;
  }
) {
  return {
    rows: flex?.repeater,
    instanceStyles: flex?.patchworkGridSettings,
    globalStyles: flex?.globalStyles,
  };
}

function patchWorkGridImageProcessor(imageCroppingData) {
  const imageCropSrc = imageCroppingData?.enableCropping
    ? customLoader({
        src: imageCroppingData?.imageSrc,
        quality: 90,
        scale: 1600,
        crop: imageCroppingData?.enableCropping,
        cropType: imageCroppingData?.cropType,
        cropHeight: imageCroppingData?.cropHeight,
        cropWidth: imageCroppingData?.cropWidth,
        xPosition: imageCroppingData?.xPosition,
        xPositionAdvanced: imageCroppingData?.xPositionAdvanced,
        yPosition: imageCroppingData?.yPosition,
        yPositionAdvanced: imageCroppingData?.yPositionAdvanced,
        autoPosition: imageCroppingData?.autoPosition,
      })
    : appliedCloudinaryParams(imageCroppingData?.imageSrc);

  return imageCropSrc;
}

function patchworkGridRowsMapping(
  cmsData: PatchworkGridCMSProps['repeater']
): PatchworkGridType['rows'] {
  const rows: PatchworkGridType['rows'] = [];

  cmsData?.forEach((data) => {
    const { image1, image2, image3 } = data;

    const image1CroppingData = {
      imageSrc: image1?.sourceUrl,
      enableCropping: data?.image1CroppingEnableCropping,
      cropType: data?.image1CroppingCropType,
      cropHeight: data?.image1CroppingCropHeight,
      cropWidth: data?.image1CroppingCropWidth,
      xPosition: data?.image1CroppingXPosition,
      xPositionAdvanced: data?.image1CroppingXPositionAdvanced,
      yPosition: data?.image1CroppingYPosition,
      yPositionAdvanced: data?.image1CroppingYPositionAdvanced,
      autoPosition: data?.image1CroppingAutoPosition,
    };

    const image2CroppingData = {
      imageSrc: image2?.sourceUrl,
      enableCropping: data?.image2CroppingEnableCropping,
      cropType: data?.image2CroppingCropType,
      cropHeight: data?.image2CroppingCropHeight,
      cropWidth: data?.image2CroppingCropWidth,
      xPosition: data?.image2CroppingXPosition,
      xPositionAdvanced: data?.image2CroppingXPositionAdvanced,
      yPosition: data?.image2CroppingYPosition,
      yPositionAdvanced: data?.image2CroppingYPositionAdvanced,
      autoPosition: data?.image2CroppingAutoPosition,
    };

    const image3CroppingData = {
      imageSrc: image3?.sourceUrl,
      enableCropping: data?.image3CroppingEnableCropping,
      cropType: data?.image3CroppingCropType,
      cropHeight: data?.image3CroppingCropHeight,
      cropWidth: data?.image3CroppingCropWidth,
      xPosition: data?.image3CroppingXPosition,
      xPositionAdvanced: data?.image3CroppingXPositionAdvanced,
      yPosition: data?.image3CroppingYPosition,
      yPositionAdvanced: data?.image3CroppingYPositionAdvanced,
      autoPosition: data?.image3CroppingAutoPosition,
    };

    if (image1?.sourceUrl) {
      rows.push({
        headline: 'headline',
        description: '',
        images: [
          {
            url: patchWorkGridImageProcessor(image1CroppingData),
            altText: image1.altText,
            captionData: {
              caption: data?.image1CaptionImageCaption?.captionText,
              captionLink: data?.image1CaptionImageCaption?.captionLink?.url,
            },
          },
        ],
      });
    }

    const imagesRowTwo = [];
    if (image2?.sourceUrl) {
      imagesRowTwo.push({
        url: patchWorkGridImageProcessor(image2CroppingData),
        variants: {},
        altText: image2.altText,
        captionData: {
          caption: data?.image2CaptionImageCaption?.captionText,
          captionLink: data?.image2CaptionImageCaption?.captionLink?.url,
        },
      });
    }
    if (image3?.sourceUrl) {
      imagesRowTwo.push({
        url: patchWorkGridImageProcessor(image3CroppingData),
        variants: {},
        altText: image3.altText,
        captionData: {
          caption: data?.image3CaptionImageCaption?.captionText,
          captionLink: data?.image3CaptionImageCaption?.captionLink?.url,
        },
      });
    }
    if (imagesRowTwo.length > 0) {
      rows.push({ images: imagesRowTwo });
    }
  });

  return rows;
}

export function PatchworkGrid({ rows, globalStyles, instanceStyles }: PatchworkGridProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);

  const randomInt = makeRandomInt().toString();
  const styleElementID = `patchworkGridStyle${randomInt}`;
  useEffect(() => {
    let style = '';

    if (inlineStyles?.imageCaptionBackgroundColor || inlineStyles?.imageCaptionTextColor) {
      if (inlineStyles?.imageCaptionBackgroundColor)
        style += `#patchworkGrid${randomInt} figcaption > div, #patchworkGrid${randomInt} figcaption > a  { opacity:1; background: ${inlineStyles?.imageCaptionBackgroundColor} !important;}`;

      if (inlineStyles?.imageCaptionTextColor)
        style += `#patchworkGrid${randomInt} figcaption > div, #patchworkGrid${randomInt} figcaption > a  {color: ${inlineStyles?.imageCaptionTextColor} !important;}`;

      if (!document.getElementById(styleElementID)) {
        const $style = document.createElement('style');
        $style.setAttribute('id', styleElementID);
        document.head.appendChild($style);
        $style.innerHTML = style;
      }
    }
  }, [
    randomInt,
    styleElementID,
    inlineStyles?.imageCaptionBackgroundColor,
    inlineStyles?.imageCaptionTextColor,
  ]);

  return (
    <div
      id={`patchworkGrid${randomInt}`}
      className={cx('patchwork-grid-wrapper overflow-hidden', paddingStyles)}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      {rows?.map((row, index) => {
        const patchworkGridRows = patchworkGridRowsMapping([row]);

        const animationDirection =
          (index || 0) % 2 === 0
            ? `sm:${inlineStyles?.animationDirection || '-translate-x'}-8`
            : `sm:${
                (inlineStyles.animationDirection?.startsWith('-')
                  ? inlineStyles.animationDirection?.slice(1)
                  : inlineStyles.animationDirection
                  ? `-${inlineStyles?.animationDirection}`
                  : '') || 'translate-x'
              }-8`;
        const animations = HandleAnimations({
          hideAnimation: inlineStyles?.hideAnimations !== 'show',
          start: animationDirection,
          delayOne: 'delay-100',
        });

        return (
          <div
            data-testid="patchwork-grid-inner-wrapper"
            key={index}
            ref={animations?.ref}
            className={cx(
              'patchwork-grid-inner-wrapper [&_figcaption>*]:text-bg container m-auto [&>section>div]:px-0',
              index === rows?.length - 1 ? '[&>section>div>div:last-of-type]:pb-0' : '',
              animations?.one
            )}
            style={{
              backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
                ? `url(${appliedCloudinaryParams(
                    inlineStyles?.contentBackgroundImage?.sourceUrl,
                    inlineStyles?.contentBackgroundRepeat
                  )})`
                : undefined,
              backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
              backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
              backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
              backgroundColor: inlineStyles?.contentBackgroundColor,
              maxWidth: inlineStyles?.containerMaxWidth
                ? `${inlineStyles?.containerMaxWidth}px`
                : '1440px',
            }}
          >
            <OscPatchworkGrid
              isReverse={index % 2 === 1}
              rows={patchworkGridRows}
              customContent={
                <div
                  className="flex flex-col"
                  style={{
                    alignItems: inlineStyles?.textAlignment || 'flex-start',
                    justifyContent: inlineStyles?.textAlignment,
                    textAlign: textAlignment,
                  }}
                >
                  <HeadingStyle
                    text={row?.title}
                    textColorInline={inlineStyles?.titleColor}
                    type="h2"
                    className="text-inverse font-headline mb-6 text-2xl font-black leading-none md:text-4xl"
                  />
                  <div
                    className={cx('mb-6 text-lg', styles?.listStyle, styles?.paragraphStyle)}
                    style={{ color: inlineStyles?.textColor }}
                    dangerouslySetInnerHTML={{
                      __html: HandleWYSIWYGContent(row?.copy),
                    }}
                  />
                  {row?.buttons && (
                    <div className="mt-0">
                      {row.buttons.map(
                        (link, key) =>
                          link?.link?.url && (
                            <AnchorLink
                              key={key}
                              url={link.link.url}
                              title={link.link.title}
                              target={link.link.target}
                              buttonStyle={link.buttonStyle ?? 'primary'}
                            />
                          )
                      )}
                    </div>
                  )}
                </div>
              }
            />
          </div>
        );
      })}
    </div>
  );
}
