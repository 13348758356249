import type { Room, Rates, Dates, Person, CBPRoom } from '../types';
import { RatePlanParamKeys } from '../types';
import { format } from 'date-fns';

/**
 * create a safe query parameter string for specialRates based parameters
 * @param rates {Rates}
 * @returns string
 */
export const buildRatesParams = (params: URLSearchParams, rates: Rates) => {
  Object.keys(rates).forEach((rateKey) => {
    if (rates[rateKey]) {
      params.append(
        RatePlanParamKeys[rateKey as keyof typeof RatePlanParamKeys],
        encodeURI(rates[rateKey] as string)
      );
    }
  });
};

/**
 * Maps the original Room type to the newer room type that includes child ages for CBP (child based pricing)
 * @param rooms
 * @returns CBPRoom[]
 */
const mapRoomsArrayToCBPFormat = (rooms: Room[]) =>
  rooms.reduce((accum, curr) => {
    // if either param is old occuptant number then convert to array of objects, otherwise use new CBP value that is passed
    const adults: Person[] =
      typeof curr.adults === 'number' ? Array(curr.adults).fill({ age: undefined }) : curr.adults;
    const children: Person[] =
      typeof curr.children === 'number'
        ? Array(curr.children).fill({ age: undefined })
        : curr.children;
    accum.push({ adults, children });
    return accum;
  }, [] as CBPRoom[]);

/**
 * create a safe query parameter string for rooms based query parameters
 * @param rooms
 * @returns string
 */
export const buildRoomsParams = (params: URLSearchParams, numRooms?: number, rooms?: Room[]) => {
  if (!rooms && !numRooms) return;

  if (rooms) {
    const normalizedRooms = mapRoomsArrayToCBPFormat(rooms);
    if (normalizedRooms.length !== numRooms) {
      params.append('numRooms', String(numRooms));
    }

    // single room logic param names differ from multiroom
    else if (normalizedRooms.length === 1 && normalizedRooms[0]) {
      params.append('numRooms', String(normalizedRooms.length));
      params.append('numAdults', String(normalizedRooms[0].adults.length));
      params.append('numChildren', String(normalizedRooms[0].children.length));
      params.append(
        'room1ChildAges',
        normalizedRooms[0].children
          .filter((c) => c.age !== undefined)
          .map((c) => c.age)
          .join(',')
      );
      params.append(
        'room1AdultAges',
        normalizedRooms[0].adults
          .filter((a) => a.age !== undefined)
          .map((a) => a.age)
          .join(',')
      );
    } else {
      params.append('numRooms', String(normalizedRooms.length));
      normalizedRooms.forEach((room, i) => {
        const currentRoom = normalizedRooms[i];
        params.append(`room${i + 1}NumAdults`, String(room.adults.length));
        params.append(`room${i + 1}NumChildren`, String(room.children.length));
        if (currentRoom)
          params.append(
            `room${i + 1}AdultAges`,
            currentRoom.adults
              .filter((a) => a.age !== undefined)
              .map((a) => a.age)
              .join(',')
          );
        if (currentRoom)
          params.append(
            `room${i + 1}ChildAges`,
            currentRoom.children
              .filter((c) => c.age !== undefined)
              .map((c) => c.age)
              .join(',')
          );
      });
    }
  } else if (numRooms) params.append('numRooms', String(numRooms));
};

/**
 * Creates a safe query parameter string for dates based query parameters.
 * @param dates
 * @returns string
 */

export const buildDatesParams = (params: URLSearchParams, dates?: Dates) => {
  if (!dates) return;

  if (dates?.arrivalDate) params.append('arrivalDate', format(dates?.arrivalDate, 'yyyy-MM-dd'));
  if (dates?.departureDate)
    params.append('departureDate', format(dates?.departureDate, 'yyyy-MM-dd'));
  if (dates?.flexibleDates !== undefined)
    params.append('flexibleDates', dates?.flexibleDates.toString());
};

export type GetGroupOrTransientSubpathProps = {
  groupsPath: string;
  numAttendees: number;
  numRooms: number;
  transientPath: string;
};
/**
 *
 * @param groupPath - path that corresponds to 10+ or group booking (example: group-search, group)
 * @param transientPath - path that corresponds to <10 or transient booking (example: search, reservations)
 * @param numRooms - number of rooms requested (typically for hotel rooms)
 * @param numAttendees - number of attendees requested (typically for meeting room)
 */
export const getGroupOrTransientSubpath = ({
  groupsPath,
  numAttendees,
  numRooms,
  transientPath,
}: GetGroupOrTransientSubpathProps) => {
  return numRooms >= 10 || numAttendees > 0 ? groupsPath : transientPath;
};
