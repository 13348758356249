import { sanitize } from '@curated-property/utils';
interface Props {
  // todo: build out these types
  dxGql: any;
  wpGql: any;
  pageMeta?: any;
}

export function SchemaJSON({ dxGql, wpGql, pageMeta }: Props) {
  const mapSchemaProps = {
    name: dxGql?.hotel?.name
      ? dxGql?.hotel?.name
      : pageMeta?.metaTitle
      ? pageMeta?.metaTitle
      : wpGql?.allSettings?.generalSettingsTitle,
    description: pageMeta?.metaDescription
      ? pageMeta?.metaDescription
      : wpGql?.allSettings?.generalSettingsDescription
      ? wpGql?.allSettings?.generalSettingsDescription
      : dxGql?.hotel?.facilityOverview?.shortDesc,
    telephone: wpGql?.propertyInfo?.propertyInfo?.phoneNumber
      ? wpGql?.propertyInfo?.propertyInfo?.phoneNumber
      : dxGql?.hotel?.contactInfo?.phoneNumber,
    fax: wpGql?.propertyInfo?.propertyInfo?.faxNumber
      ? wpGql?.propertyInfo?.propertyInfo?.faxNumber
      : dxGql?.hotel?.contactInfo?.faxNumber,
    streetAddress: wpGql?.propertyInfo?.propertyInfo?.address?.streetAddress
      ? wpGql?.propertyInfo?.propertyInfo?.address?.streetAddress
      : dxGql?.hotel?.address?.addressLine1,
    addressLocality: wpGql?.propertyInfo?.propertyInfo?.address?.addressLocality
      ? wpGql?.propertyInfo?.propertyInfo?.address?.addressLocality
      : dxGql?.hotel?.address?.city,
    addressRegion: wpGql?.propertyInfo?.propertyInfo?.address?.addressRegion
      ? wpGql?.propertyInfo?.propertyInfo?.address?.addressRegion
      : dxGql?.hotel?.address?.state,
    addressCountry: wpGql?.propertyInfo?.propertyInfo?.address?.addressCountry
      ? wpGql?.propertyInfo?.propertyInfo?.address?.addressCountry
      : dxGql?.hotel?.address?.country,
    postalCode: wpGql?.propertyInfo?.propertyInfo?.address?.postalCode
      ? wpGql?.propertyInfo?.propertyInfo?.address?.postalCode
      : dxGql?.hotel?.address?.postalCode,
    latitude: wpGql?.propertyInfo?.propertyInfo?.geo?.latitude
      ? wpGql?.propertyInfo?.propertyInfo?.geo?.latitude
      : dxGql?.hotel?.localization?.coordinate?.latitude,
    longitude: wpGql?.propertyInfo?.propertyInfo?.geo?.longitude
      ? wpGql?.propertyInfo?.propertyInfo?.geo?.longitude
      : dxGql?.hotel?.localization?.coordinate?.longitude,
    url: wpGql?.propertyInfo?.propertyInfo?.liveUrl,
    openingHours: wpGql?.propertyInfo?.propertyInfo?.openingHours,
    image: dxGql?.hotel?.images?.master?.url,
  };

  const schemaOutput = {
    '@context': 'http://schema.org',
    '@type': 'hotel',
    name: mapSchemaProps.name || '',
    url: mapSchemaProps.url || '',
    image: mapSchemaProps.image || '',
    telephone: mapSchemaProps.telephone || '',
    faxNumber: mapSchemaProps.fax || '',
    description: mapSchemaProps.description || '',
    openingHours: mapSchemaProps.openingHours || '',
    address: {
      '@type': 'PostalAddress',
      streetAddress: mapSchemaProps.streetAddress || '',
      addressLocality: mapSchemaProps.addressLocality || '',
      addressRegion: mapSchemaProps.addressRegion || '',
      addressCountry: mapSchemaProps.addressCountry || '',
      postalCode: mapSchemaProps.postalCode || '',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: mapSchemaProps.latitude || '',
      longitude: mapSchemaProps.longitude || '',
    },
  };

  return (
    <script
      type="application/ld+json"
      data-testid="seoSchema"
      dangerouslySetInnerHTML={{
        __html: sanitize(JSON.stringify(schemaOutput)),
      }}
    />
  );
}
