import { useState, useEffect, useRef, useCallback } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowRight, ArrowLeft, CloseIcon } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { CroppedImage, customLoader } from '../cropped-image/cropped-image';
import { makeRandomInt } from '@curated-property/utils';
import { adobeEventTracking } from '../global/adobe-analytics';

export type ImageModalCroppedType = {
  image?: {
    sourceUrl?: string;
    src?: string;
    altText?: string;
  };
  description?: string;
  selectOrientation?: string;
  portraitModal?: boolean;
  faceDetection?: boolean;
};

interface CroppedModalProps {
  close: () => void;
  title?: string;
  images?: ImageModalCroppedType[];
  active?: boolean;
  activeImage?: number;
  captionColour?: string;
  slideAnimation?: string;
  controlsIconColour?: string;
  modalBackgroundColour?: string;
  modalBackgroundOpacity?: number;
  controlsBackgroundColour?: string;
}

export function ImageModalSeries({
  close,
  title,
  images,
  active,
  activeImage,
  captionColour,
  slideAnimation,
  controlsIconColour,
  modalBackgroundColour,
  modalBackgroundOpacity,
  controlsBackgroundColour,
}: CroppedModalProps) {
  const [firstLoad, updateFirstLoad] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(activeImage ?? 0);
  const [nextImage, setNextImage] = useState({
    image: images?.[currentIndex],
    selectOrientation: images?.[currentIndex]?.selectOrientation,
    portraitModal: images?.[currentIndex]?.portraitModal,
    ind: currentIndex,
  });
  const [nextTimeout, setNextTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  useEffect(() => {
    if (firstLoad === 0) {
      setCurrentIndex(activeImage ?? 0);
    }
  }, [firstLoad, activeImage]);

  const count = images ? images.length : 0;
  const focusOnActive = useRef<HTMLButtonElement>(null);
  const modalContainer = useRef<HTMLElement>(null);
  const { t } = useTranslation();
  const randInt = makeRandomInt();

  const getNextIndex = useCallback(
    () => (currentIndex + 1 > count - 1 ? 0 : currentIndex + 1),
    [count, currentIndex]
  );
  const getPrevIndex = useCallback(
    () => (currentIndex - 1 < 0 ? count - 1 : currentIndex - 1),
    [count, currentIndex]
  );

  const adobe = useCallback(
    (index: number) => {
      adobeEventTracking({
        carouselNumber: `${index + 1}:${count}`,
        sectionHeading: title,
        actionDetail: 'Carousel',
        interactionName: 'propertyGalleryCarousel',
      });
    },
    [count, title]
  );

  const next = useCallback(() => {
    updateFirstLoad(1);
    if (!images || images.length <= 1) {
      return;
    }
    if (!navAnimationDir) setNavAnimationDir(true);
    if (!nextTimeout) {
      const nextInd = getNextIndex();
      setNextImage({
        image: images[nextInd],
        selectOrientation: images[nextInd]?.selectOrientation,
        portraitModal: images[nextInd]?.portraitModal,
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(getNextIndex);
          setNextTimeout(null);
          adobe(getNextIndex());
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === count - 1 ? 1 : currentIndex + 2 > count - 1 ? 0 : currentIndex + 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: images[skipTransInd],
        selectOrientation: images[skipTransInd]?.selectOrientation,
        portraitModal: images[skipTransInd]?.portraitModal,
        ind: skipTransInd,
      });
      adobe(skipTransInd);
    }
  }, [adobe, count, currentIndex, getNextIndex, images, nextTimeout, navAnimationDir]);

  const prev = useCallback(() => {
    updateFirstLoad(1);
    if (!images || images.length <= 1) {
      return;
    }
    if (navAnimationDir) setNavAnimationDir(false);
    if (!nextTimeout) {
      const nextInd = getPrevIndex();
      setNextImage({
        image: images[nextInd],
        selectOrientation: images[nextInd]?.selectOrientation,
        portraitModal: images[nextInd]?.portraitModal,
        ind: nextInd,
      });
      setNextTimeout(
        setTimeout(() => {
          setCurrentIndex(getPrevIndex);
          setNextTimeout(null);
          adobe(getPrevIndex());
        }, 500)
      );
    } else {
      clearTimeout(nextTimeout);
      setNextTimeout(null);
      const skipTransInd =
        currentIndex === 0 ? 1 : currentIndex - 2 < 0 ? count - 1 : currentIndex - 2;
      setCurrentIndex(skipTransInd);
      setNextImage({
        image: images[skipTransInd],
        selectOrientation: images[skipTransInd]?.selectOrientation,
        portraitModal: images[skipTransInd]?.portraitModal,
        ind: skipTransInd,
      });
      adobe(skipTransInd);
    }
  }, [adobe, count, currentIndex, getPrevIndex, images, nextTimeout, navAnimationDir]);

  useEffect(() => {
    if (!active) {
      return;
    }

    function modalKeypress(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowLeft':
          prev();
          break;
        case 'ArrowRight':
          next();
          break;
        default:
          return null;
      }
    }

    window.addEventListener('keyup', modalKeypress);
    return () => window.removeEventListener('keyup', modalKeypress);
  }, [active, close, next, prev, images]);

  const imageWidth = images?.[currentIndex]?.portraitModal ? '529' : '956';
  const imageHeight = images?.[currentIndex]?.portraitModal ? '800' : '632';

  const getPrevSlideInd = () => {
    const ind = slideAnimation === 'slide-ltr' && navAnimationDir ? getNextIndex() : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  return (
    <Dialog.Root open={active}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <aside
            data-testid="image-modal"
            ref={modalContainer}
            data-element-id="image-wall-modal-overlay-wrapper"
            className="z-120 animate-fade-in-fast fixed inset-0"
          >
            <Dialog.Content
              /* This is disabled on purpose because we don't use radix-ui/dialog description. See https://www.radix-ui.com/primitives/docs/components/dialog#description */
              aria-describedby={undefined}
              onInteractOutside={close}
              onEscapeKeyDown={close}
              className="flex size-full items-center justify-center px-8 sm:px-24 lg:px-32"
            >
              <div
                data-element-id="gallery-modal-background"
                className="bg-primary opacity-98 fixed inset-0 size-full"
                style={{
                  backgroundColor: modalBackgroundColour,
                  opacity: modalBackgroundOpacity,
                }}
              />
              <div
                className={cx(
                  images?.[currentIndex]?.portraitModal ? 'max-w-[529px]' : 'max-w-[956px]',
                  'relative w-10/12 transition-all duration-200 ease-in-out lg:w-9/12'
                )}
              >
                <Dialog.Close asChild>
                  <button
                    className="bg-bg-alt absolute right-0 top-0 z-10 flex size-8 -translate-y-6 translate-x-6 items-center justify-center rounded-full sm:size-12"
                    data-testid="image-modal-close"
                    onClick={() => {
                      close();
                      updateFirstLoad(0);
                      setCurrentIndex(activeImage ?? 0);
                    }}
                    ref={focusOnActive}
                    style={{
                      backgroundColor: controlsBackgroundColour,
                    }}
                  >
                    <span className="sr-only">{t('closeModal')}</span>
                    <CloseIcon
                      className="text-bg-inverse size-2 fill-current sm:size-4"
                      fillColor={controlsIconColour}
                    />
                  </button>
                </Dialog.Close>
                <Dialog.Title className="sr-only">{title}</Dialog.Title>
                <div className="relative flex justify-center">
                  {slideAnimation !== 'fade' ? (
                    <div className="relative flex size-full flex-row justify-center overflow-hidden">
                      <div
                        className={cx('size-full transition-all duration-500 ease-in-out', {
                          'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
                          '-translate-x-full': !nextTimeout || slideAnimation === 'slide-rtl',
                          'transition-none': !nextTimeout,
                        })}
                      >
                        <CroppedImage
                          src={images?.[getPrevSlideInd()].image?.sourceUrl || ''}
                          alt={`${images?.[getPrevSlideInd()].image?.altText}-previous-transition`}
                          loader={() => {
                            return customLoader({
                              src: images?.[getPrevSlideInd()].image?.sourceUrl,
                              width: imageWidth,
                              height: imageHeight,
                              faceDetection: images?.[getPrevSlideInd()]?.faceDetection,
                            });
                          }}
                          width={imageWidth}
                          height={imageHeight}
                          objectFit="cover"
                          layout="fill"
                        />
                      </div>
                      <div
                        className={cx('order-3 size-full transition-all duration-500 ease-in-out', {
                          'translate-x-0':
                            (slideAnimation === 'slide-rtl' && nextTimeout) ||
                            (nextTimeout && slideAnimation !== 'slide-ltr' && navAnimationDir),
                          'translate-x-full':
                            !nextTimeout ||
                            (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
                            slideAnimation === 'slide-ltr',
                          'transition-none': !nextTimeout,
                        })}
                      >
                        <CroppedImage
                          src={nextImage.image?.image?.sourceUrl || ''}
                          alt={`${nextImage.image?.image?.altText}-next-transition`}
                          loader={() => {
                            return customLoader({
                              src: nextImage.image?.image?.sourceUrl,
                              width: imageWidth,
                              height: imageHeight,
                              faceDetection: nextImage.image?.faceDetection,
                            });
                          }}
                          width={imageWidth}
                          height={imageHeight}
                          objectFit="cover"
                          layout="fill"
                        />
                      </div>
                      <div
                        className={cx(
                          'order-2 size-full transition-all duration-500 ease-in-out',
                          {
                            'transition-none': !nextTimeout,
                          },
                          slideStyles
                        )}
                      >
                        <CroppedImage
                          src={images?.[currentIndex].image?.sourceUrl || ''}
                          alt={images?.[currentIndex].image?.altText}
                          loader={() => {
                            return customLoader({
                              src: images?.[currentIndex].image?.sourceUrl,
                              width: imageWidth,
                              height: imageHeight,
                              faceDetection: images?.[currentIndex]?.faceDetection,
                            });
                          }}
                          width={imageWidth}
                          height={imageHeight}
                          objectFit="cover"
                          layout="responsive"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="relative flex justify-center">
                      <CroppedImage
                        src={nextImage.image?.image?.sourceUrl || ''}
                        alt={`${nextImage.image?.image?.altText}-transition`}
                        width={imageWidth}
                        height={imageHeight}
                        loader={() => {
                          return customLoader({
                            src: nextImage.image?.image?.sourceUrl,
                            width: imageWidth,
                            height: imageHeight,
                            faceDetection: nextImage.image?.faceDetection,
                          });
                        }}
                        objectFit="cover"
                        layout="fill"
                        className="relative transition-all duration-500 ease-in-out"
                      />
                      <CroppedImage
                        src={images?.[currentIndex].image?.sourceUrl || ''}
                        alt={images?.[currentIndex].image?.altText}
                        width={imageWidth}
                        height={imageHeight}
                        loader={() => {
                          return customLoader({
                            src: images?.[currentIndex].image?.sourceUrl,
                            width: imageWidth,
                            height: imageHeight,
                            faceDetection: images?.[currentIndex]?.faceDetection,
                          });
                        }}
                        objectFit="cover"
                        className={cx('transition-all duration-500 ease-in-out', {
                          'opacity-0': nextTimeout ? true : false,
                          'opacity-100': nextTimeout ? false : true,
                        })}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      height: 0,
                      width: 0,
                      overflow: 'hidden',
                      visibility: 'hidden',
                    }}
                  >
                    {images?.map((preload, key) => {
                      return (
                        <CroppedImage
                          key={key}
                          src={preload.image?.sourceUrl || ''}
                          alt={preload.image?.altText}
                          width="956"
                          height="632"
                          objectFit="cover"
                        />
                      );
                    })}
                  </div>
                  <div
                    data-testid="buttonContainer"
                    data-element-id="image-modal-series-buttons"
                    className={cx('absolute inset-0 z-10 m-auto flex h-8 justify-between sm:h-16', {
                      hidden: images?.length === 1,
                    })}
                  >
                    <button
                      id={`imageModalLeft-${randInt}`}
                      data-testid="image-modal-left"
                      className="bg-bg-alt flex size-8 -translate-x-6 items-center justify-center rounded-full sm:size-12 rtl:translate-x-6  rtl:rotate-180"
                      onClick={prev}
                      style={{ backgroundColor: controlsBackgroundColour }}
                    >
                      <span aria-live="polite" className="sr-only">
                        {t('modalPrevImage')
                          .replace('{x}', '' + (getPrevIndex() + 1))
                          .replace('{y}', '' + count)}
                      </span>
                      <ArrowLeft
                        className="text-bg-inverse size-4 fill-current lg:mr-1"
                        fillColor={controlsIconColour}
                      />
                    </button>
                    <button
                      id={`imageModalRight-${randInt}`}
                      data-testid="image-modal-right"
                      className="bg-bg-alt flex size-8 translate-x-6 items-center justify-center rounded-full sm:size-12 rtl:-translate-x-6 rtl:rotate-180"
                      onClick={next}
                      style={{ backgroundColor: controlsBackgroundColour }}
                    >
                      <span className="sr-only">
                        {t('modalNextImage')
                          .replace('{x}', '' + (getNextIndex() + 1))
                          .replace('{y}', '' + count)}
                      </span>
                      <ArrowRight
                        className="text-bg-inverse size-4 fill-current lg:ml-1"
                        fillColor={controlsIconColour}
                      />
                    </button>
                  </div>
                </div>

                <div
                  className="text-bg flex justify-between rtl:flex-row-reverse"
                  data-element-id="image-modal-series-pagination"
                  aria-live="assertive"
                >
                  <span className="sr-only">{`${
                    nextTimeout ? nextImage?.ind + 1 : currentIndex + 1
                  } ${t('of')} ${count}`}</span>
                  <p
                    data-testid="currentIndex"
                    aria-hidden={true}
                    className="p-4 text-sm md:text-lg"
                    style={{ color: captionColour }}
                  >
                    {nextTimeout ? nextImage?.ind + 1 : currentIndex + 1 || ''} / {count || ''}
                  </p>
                  <p className="p-4 text-sm md:text-lg" style={{ color: captionColour }}>
                    {t(images?.[nextTimeout ? nextImage?.ind : currentIndex]?.description || '')}
                  </p>
                </div>
              </div>
            </Dialog.Content>
          </aside>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
