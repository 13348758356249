export function Policies({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M8.625,11.918A.3751.3751,0,0,0,9,12.293h6a.375.375,0,0,0,0-.75H9A.3751.3751,0,0,0,8.625,11.918Z" />
          <path d="M15,13.625H9a.375.375,0,0,0,0,.75h6a.375.375,0,0,0,0-.75Z" />
          <path d="M15,15.625l-6-.0068a.375.375,0,1,0,0,.75l6,.0068a.375.375,0,0,0,0-.75Z" />
          <path d="M18,3.625H10a.3754.3754,0,0,0-.2656.1094l-4,4A.3754.3754,0,0,0,5.625,8V20A.3751.3751,0,0,0,6,20.375H18A.3751.3751,0,0,0,18.375,20V4A.3751.3751,0,0,0,18,3.625Zm-.375,16H6.375V8.1553l3.78-3.78h7.47Z" />
          <path d="M9.625,6V7.625H8a.375.375,0,0,0,0,.75h2A.3751.3751,0,0,0,10.375,8V6a.375.375,0,0,0-.75,0Z" />
        </g>
      </g>
    </svg>
  );
}
