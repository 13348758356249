import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dpsbahi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#104C97" className="w-30 h-20 antialiased md:w-32" />
);

export const hotelLogoFooter = <HotelLogo fillColor="#104C97" className="h-20 w-32 antialiased" />;
