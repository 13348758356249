import { type SVGAttributes } from 'react';

const GolfIcon = (props: SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path d="M15.993 4.843c6.109-3.226 12.217 9.185 18.325 5.807-6.108 7.098-12.216-1.443-18.325 5.807V4.843zM16 15.4v22.398M19.217 35.891c1.86.455 3.108 1.317 3.108 2.301 0 1.473-2.755 2.66-6.162 2.66S10 39.666 10 38.193c0-.923 1.1-1.744 2.77-2.213" />
      </g>
    </svg>
  );
};

export default GolfIcon;
