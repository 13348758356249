import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import * as NewIcons from '@curated-property/icon-list';
import { GenericModal, DialogTitle } from './global/generic-modal';
import cx from 'classnames';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_merge, GIS_Padder } from './functions/global-instance-styles';
import { HeadingStyle } from './includes/heading-style';
import { HandleAnimations } from './functions/helper';
import { sanitize } from '@curated-property/utils';

interface IhotelAmenties {
  id?: string;
  name?: string;
}

interface Props {
  header?: string;
  buttonStyling?: string | undefined;
  hotelAmenties?: Array<IhotelAmenties> | undefined;
  accessibleHotelAmenities?: Array<IhotelAmenties> | undefined;
  nonAccessibleHotelAmenities?: Array<IhotelAmenties> | undefined;
  showHotelAmenitiesIcons?: boolean;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
  modalSettings?: any;
}

export function HotelAmenities({
  hotelAmenties,
  accessibleHotelAmenities,
  nonAccessibleHotelAmenities,
  header,
  buttonStyling,
  showHotelAmenitiesIcons,
  instanceStyles,
  globalStyles,
  modalSettings,
}: Props) {
  const { t } = useTranslation();
  const [accessibleOpen, setAccessibleOpen] = useState<boolean>(false);
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const modalUlStyle =
    'text-left list-disc px-8 py-6 grid md:grid-cols-2 gap-0 sm:gap-2 list-inside';
  const modalLiStyle = 'list-outside leading-4 text-sm lg:text-base mb-2 md:mb-0 md:pr-0 pr-4';

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
  });

  return (
    <div
      data-element-id="icon-grid-core-plus"
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? 'url(' + inlineStyles?.componentBackgroundImage + ')'
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || undefined,
        backgroundPosition: inlineStyles?.componentBackgroundPosition || undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
      }}
    >
      <div
        ref={animations?.ref}
        className={cx('cp-iconCorePlus container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? 'url(' + inlineStyles?.contentBackgroundImage + ')'
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || undefined,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || undefined,
          backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
        }}
      >
        {header && (
          <div className="text-center">
            <h2
              className={cx('font-headline text-3xl font-black', animations?.one)}
              style={{
                color: inlineStyles?.titleColor,
              }}
            >
              {header}
            </h2>
          </div>
        )}
        {showHotelAmenitiesIcons && (
          <div className="flex flex-wrap justify-center py-4 md:py-8">
            {hotelAmenties?.map((amenity, ind) => {
              const iconId = amenity.id || '';
              const Icon = mapping[iconId as keyof typeof mapping] || (() => null);
              return (
                <div
                  className={cx(
                    'w-1/2 py-1 text-center sm:w-1/3 md:w-1/4 lg:w-1/6',
                    animations?.two
                  )}
                  key={amenity.id}
                  style={{
                    color: inlineStyles?.iconCoreColour,
                    transitionDelay: `${ind + 1}00ms`,
                  }}
                >
                  <Icon className="inline-block size-8 fill-current lg:size-16" />
                  <p
                    style={{ color: inlineStyles?.labelCoreColour }}
                    className="w-full px-2 text-center text-lg"
                    dangerouslySetInnerHTML={{
                      __html: sanitize(amenity.name || ''),
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
        <div className="text-center">
          <button
            data-testid="iconGridCorePlusModalButton"
            onClick={() => setAccessibleOpen(true)}
            className={`btn btn-${buttonStyling || 'primary'} px-4 py-2 text-base`}
          >
            {t('accessibleAmenities')}
          </button>
          {accessibleOpen && (
            <GenericModal
              modalChildStyles="cp-iconGridCorePlus bg-white"
              modalStyles="pt-12"
              modalChild={
                <div
                  className="p-4 md:p-8"
                  style={{
                    backgroundColor: inlineStyles?.modalBackgroundColor || undefined,
                    color: inlineStyles?.modalTextColor || undefined,
                  }}
                >
                  {(accessibleHotelAmenities?.length || 0) > 0 && (
                    <>
                      <DialogTitle asChild>
                        <HeadingStyle
                          text={t('accessibleAmenities')}
                          type="h2"
                          styledAs="h3"
                          className="border-text border-b"
                          style={{
                            color: inlineStyles?.modalTitleColor || undefined,
                            borderBottomColor: inlineStyles?.modalTitleUnderlineColor || undefined,
                          }}
                        />
                      </DialogTitle>
                      <ul className={modalUlStyle}>
                        {accessibleHotelAmenities?.map((item) => (
                          <li className={modalLiStyle} key={item.name}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {(nonAccessibleHotelAmenities?.length || 0) > 0 && (
                    <>
                      <DialogTitle asChild>
                        <HeadingStyle
                          text={t('nonAccessibleAmenities')}
                          type="h2"
                          styledAs="h3"
                          className="border-text border-b"
                          style={{
                            color: inlineStyles?.modalTitleColor || undefined,
                            borderBottomColor: inlineStyles?.modalTitleUnderlineColor || undefined,
                          }}
                        />
                      </DialogTitle>
                      <ul className={modalUlStyle}>
                        {nonAccessibleHotelAmenities?.map((item) => (
                          <li className={modalLiStyle} key={item.name}>
                            {item.name}
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  <p className="lg:text-md my-2 pb-2 text-sm font-bold leading-4">
                    {t('accessibilityRequests')}
                  </p>
                </div>
              }
              setModalActive={setAccessibleOpen}
              modalActive={accessibleOpen}
              modalSettings={modalSettings}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapping = {
  allInclusive: NewIcons.AllInclusive,
  airportShuttle: NewIcons.ShuttleService,
  businessCenter: NewIcons.BusinessCentre,
  concierge: NewIcons.Concierge,
  digitalKey: NewIcons.DigitalKey,
  dining: NewIcons.Restaurant,
  eveningReception: NewIcons.EveningReception,
  eveningSocial: NewIcons.EveningReception,
  executiveLounge: NewIcons.Lounge,
  fitnessCenter: NewIcons.Gym,
  freeBreakfast: NewIcons.Breakfast,
  freeParking: NewIcons.Parking,
  freeWifi: NewIcons.WiFi,
  golf: NewIcons.Golf,
  indoorPool: NewIcons.IndoorPool,
  meetingRooms: NewIcons.Meeting,
  nonSmoking: NewIcons.NoSmoking,
  outdoorPool: NewIcons.OutdoorPool,
  petsAllowed: NewIcons.PetFriendly,
  petsNotAllowed: NewIcons.NoPets,
  resort: NewIcons.Resort,
  roomService: NewIcons.RoomService,
  spa: NewIcons.Spa,
  tennisCourt: NewIcons.Tennis,
  adjoiningRooms: NewIcons.ConnectingRooms,
  luxury: NewIcons.Luxury,
  beach: NewIcons.Beach,
  boutique: NewIcons.BoutiqueHotels,
  newHotel: NewIcons.NewHotels,
  ski: NewIcons.Ski,
  evCharging: NewIcons.EvCharging,
  casino: NewIcons.Casino,
  hotelResidence: NewIcons.Keys,
  streamingTv: NewIcons.TV,
};
