import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { ControlMenu } from './video-control-menu';
import { ControlList } from './video-control-list';
import { ControlItem } from './video-control-item';
import { ArrowLeft, ArrowRight } from '@curated-property/icons';
import type { useMenu } from '../helpers/use-control-menu';
import { useEventListeners } from '../helpers/use-control-menu';
import { Check, Settings, SettingsSolid } from '@curated-property/icon-list';
import type { VideoProps } from '../hero-image.types';

type SettingsMenuProps = Pick<
  VideoProps['videoControlsProps'],
  | 'activeVideo'
  | 'updateAudioTrack'
  | 'updateCaption'
  | 'updateTranscript'
  | 'optionsState'
  | 'playerState'
> & {
  menuProps: ReturnType<typeof useMenu>;
};

export function SettingsMenu({ videoControlsProps, videoCtaUnmuteColor }) {
  const { t } = useTranslation();
  const menuOptions = useMenuOptions({
    ...videoControlsProps,
    menuProps: videoControlsProps?.settingsMenu,
  });
  const { isOpen, toggleMenu, menuId, popupButtonRef, popupMenuRef } = menuOptions;

  return (
    <>
      <button
        data-testid={isOpen ? 'hideVideoSettings' : 'showVideoSettings'}
        aria-live="polite"
        aria-controls={menuId}
        aria-label={isOpen ? t('hideVideoSettings') : t('showVideoSettings')}
        className="flex h-full items-center justify-center border-l-2 px-3.5"
        onClick={toggleMenu}
      >
        {isOpen ? (
          <SettingsSolid fillColor={videoCtaUnmuteColor} className="h-7" />
        ) : (
          <Settings fillColor={videoCtaUnmuteColor} className="h-7" />
        )}
      </button>
      <ControlMenu {...menuOptions}>
        {isOpen ? (
          <Menu
            {...videoControlsProps}
            {...menuOptions}
            popupButtonRef={popupButtonRef}
            popupMenuRef={popupMenuRef}
          />
        ) : null}
      </ControlMenu>
    </>
  );
}

type MenuProps = ReturnType<typeof useMenuOptions>;
type ControlMenuProps = SettingsMenuProps & MenuProps;

function Menu({
  menuOptions,
  closeMenu,
  popupButtonRef,
  popupMenuRef,
  goToMainMenu,
}: ControlMenuProps) {
  function resetMenu() {
    closeMenu();
    goToMainMenu();
  }

  useEventListeners({
    popupMenuRef,
    popupButtonRef,
    resetMenu,
  });

  const selectedOption = menuOptions.find((option) => option.isSelected);

  if (selectedOption) {
    return (
      <ControlList key={selectedOption.type}>
        {selectedOption.options.map((subOption) => (
          <ControlItem
            key={subOption.label}
            onClick={() => {
              selectedOption.onSelection(subOption.track);
              resetMenu();
              popupButtonRef.current?.focus();
            }}
            role="menuitemradio"
            aria-checked={subOption.isSelected}
            autoFocus={subOption.isSelected}
            beforeSection={
              <Check
                className={cx('h-7', {
                  '!fill-transparent': !subOption.isSelected,
                })}
              />
            }
          >
            {subOption.label}
          </ControlItem>
        ))}
        <ControlItem
          key={selectedOption.type}
          wrapperClassName={cx()}
          onClick={goToMainMenu}
          beforeSection={<ArrowLeft className="mr-2.5 size-4 rtl:rotate-180" />}
          autoFocus={!selectedOption.options.some((subOption) => subOption.isSelected)}
          aria-expanded={true}
        >
          {selectedOption.label}
        </ControlItem>
      </ControlList>
    );
  }

  return (
    <ControlList>
      {menuOptions.map((option, index) => (
        <ControlItem
          key={option.type}
          onClick={option.onMenuOptionClick}
          className="justify-between"
          autoFocus={index === 0}
          aria-expanded={false}
          afterSection={<ArrowRight className="mr-2.5 size-4 rtl:rotate-180" />}
        >
          {option.label}
        </ControlItem>
      ))}
    </ControlList>
  );
}

type Option = {
  isSelected: boolean;
  label: HTMLTrackElement['label'];
  track: any | null;
};

type SelectOption = Pick<Option, 'isSelected'> & {
  label: React.ReactNode;
  type: any;
  options: Option[];
  onSelection: (option: Option['track'] | null) => void;
  onMenuOptionClick: () => void;
};

function useMenuOptions(options: SettingsMenuProps) {
  const { t } = useTranslation('osc-video-player');
  const [selectedOption, setSelectedOption] = useState<any | 'mainMenu'>('mainMenu');
  const captionOptions = options.activeVideo?.captionTracks || [];
  const hasCaptions = captionOptions.length > 1;
  const hasAudioTracks = options.optionsState.length > 1;
  const transcriptOptions = options.activeVideo?.transcriptTracks || [];
  const hasTranscripts = transcriptOptions.length > 0;
  const goToMainMenu = () => setSelectedOption('mainMenu');

  const menuOptions = [
    hasCaptions && {
      type: 'captionTrack',
      label: (
        <>
          {t('subtitles')}/<abbr title={t('captions')}>{t('cc')}</abbr>
        </>
      ),
      isSelected: selectedOption === 'captionTrack',
      onSelection: options.updateCaption,
      onMenuOptionClick: () => setSelectedOption('captionTrack'),
      options: [
        {
          isSelected: !options.playerState.showCaptions,
          label: t('off'),
          track: null,
        },
        ...captionOptions.map((track) => ({
          isSelected:
            options.playerState.showCaptions &&
            options.playerState.activeCaptionTrack?.url === track.url,
          label: track.label,
          track,
        })),
      ],
    },
    hasAudioTracks && {
      type: 'audioTrack',
      label: t('audioTrack'),
      isSelected: selectedOption === 'audioTrack',
      onSelection: options.updateAudioTrack,
      onMenuOptionClick: () => setSelectedOption('audioTrack'),
      options: options.optionsState.map((track) => ({
        isSelected: options.activeVideo?.videoUrl === track.videoUrl,
        label: track.videoLabel,
        track,
      })),
    },
    hasTranscripts && {
      type: 'transcriptTrack',
      label: t('transcript'),
      isSelected: selectedOption === 'transcriptTrack',
      onSelection: options.updateTranscript,
      onMenuOptionClick: () => setSelectedOption('transcriptTrack'),
      options: [
        {
          isSelected: !options.playerState.activeTranscript,
          label: t('off'),
          track: null,
        },
        ...transcriptOptions.map((track) => ({
          isSelected: options.playerState.activeTranscript?.url === track.url,
          label: track.label,
          track,
        })),
      ],
    },
  ].filter(Boolean) as SelectOption[];

  return {
    ...options.menuProps,
    goToMainMenu,
    menuOptions,
  };
}
