import type { AdditionalHeadProps } from '../pages/_app';

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  switch (pageSlug) {
    case 'dorada':
      return (
        <>
          <link rel="stylesheet" href="https://use.typekit.net/vuo2bqx.css" />
          <style id="doradaStyles">
            {`
              :root {
                --font-headline: tt-commons-pro, sans-serif !important;
                --font-body: vendetta, serif !important;
                --font-cta: vendetta, serif !important;
              }

              h1, h2, h3, h4, h5, h6,
              .font-headline {
                font-family: var(--font-headline) !important;
                font-weight: 300;
                font-style: normal;
              }

              /* h4 for Dorada page had issues with the font-family taking styling from eywcmqq.css so this was needed to override it */
              h4:not(.placeholder) {
                font-family: var(--font-headline) !important;
                font-weight: 300;
                font-style: normal;
              }

              p, a, li, div, ul, ol {
                font-family: var(--font-body) !important;
                font-weight: 500;
                font-style: normal;
                font-size: 18px !important;
              }

              /* Button override for Dorada page due to .btn styling from eywcmqq.css */
              .btn:not(.placeholder) {
                font-family: var(--font-cta) !important;
                font-weight: 500 !important;
                font-style: normal !important;
                font-size: 18px !important;
                text-transform: uppercase !important;
              }
            `}
          </style>
        </>
      );
    default:
      return <link rel="stylesheet" href="https://use.typekit.net/vuo2bqx.css" />;
  }
};
