import gql from 'graphql-tag-transform.macro';
import { buttonClone, clonedCropSettings, mediaAndCopyCarouselSettings } from './common-vars';

export const MediaAndCopyCarousel = gql`
fragment MediaAndCopyCarousel on Page_Acfflexible_FlexibleComponents {
... on Page_Acfflexible_FlexibleComponents_SingleItemSlider {
        fieldGroupName
        imageSizes
        layoutDirection
        layoutMode
        offsetImage
        header
        headingValue
        copy
        ${buttonClone}
        repeater {
          copy
          fieldGroupName
          title
          title_noTx: title
          image {
            altText
            sourceUrl
          }
          ${clonedCropSettings}
          imagePositioning
          tableData {
            text
            title
          }
          singleLink {
            target
            title
            url
          }
          ${buttonClone}
          showHide
        }
        singleItemSliderComponentSettings {
          fieldGroupName
          showHide
          #ClonedComponentSettingsStart
          componentBackgroundColor
          componentBackgroundSize
          componentBackgroundRepeat
          componentBackgroundPosition
          contentBackgroundColor
          contentBackgroundSize
          contentBackgroundRepeat
          contentBackgroundPosition
          contentBackgroundColorLayerTwo
          subtitleColor
          textAlignment
          textColor
          titleColor
          componentBackgroundImage {
            sourceUrl
          }
          contentBackgroundImage {
            sourceUrl
          }
          paddingTop {
            paddingTopDesktop
            paddingTopTablet
            paddingTopMobile
          }
          paddingBottom {
            paddingBottomDesktop
            paddingBottomTablet
            paddingBottomMobile
          }
          showHide
          hideAnimations
          animationDirection
          hideParallax
          sliderAnimations
          zoomOnHover
          containerMaxWidth
          headingsNoTranslate
          #ClonedComponentSettingsEnd

          ${mediaAndCopyCarouselSettings}
        }
      }
}
`;
