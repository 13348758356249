export function CloseCircle({
  className,
  fillColor,
  backgroundColor,
}: {
  className?: string;
  fillColor?: string;
  backgroundColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      {backgroundColor && (
        <circle cx="12" cy="12" r="8" style={{ fill: backgroundColor || 'current' }} />
      )}
      <path
        d="M15.2656,8.7344a.376.376,0,0,0-.5312,0L12,11.4688,9.2656,8.7344a.3756.3756,0,0,0-.5312.5312L11.4687,12,8.7344,14.7344a.3756.3756,0,0,0,.5312.5312L12,12.5312l2.7344,2.7344a.3756.3756,0,0,0,.5312-.5312L12.5312,12l2.7344-2.7344A.3778.3778,0,0,0,15.2656,8.7344Z"
        style={{ fill: fillColor || 'current' }}
      />
      {!backgroundColor && (
        <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
      )}
    </svg>
  );
}
