/**
 * CONRAD PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  headerClassName: undefined,
  navStyles: undefined,
  textColorOnLoad: 'var(--color-dark)',
  bgColorOnLoad: 'rgba(255,255,255,1)',
  textColorOnActive: 'var(--color-dark)',
  bgColorOnActive: '#FFF',
  bgSubmenuColor: '#FFF',
  fontWeight: 'medium',
  menuItems: {
    colorOnHover: 'var(--brand-secondary)',
    paddingClasses: 'p-2',
    case: 'uppercase',
    underline: {
      active: true,
      color: undefined,
      size: undefined,
    },
    itemActive: {
      classes: undefined,
      bgColor: undefined,
      bgText: undefined,
    },
  },
  iconMenu: {
    extraClasses: 'px-2',
    fontSize: undefined,
    color: '#000000',
    iconColor: '#000000',
    activeColor: '#000000',
    activeIconColor: undefined,
    separatorColor: undefined,
    underline: false,
    underlineHover: false,
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: 'btn-primary bg-brand-dark',
    extraClasses: 'rounded-none border-brand-5',
    backgroundColor: undefined,
    backgroundColorHover: undefined,
    textColor: undefined,
    textColorHover: undefined,
    textCase: undefined,
  },
};

export const brandBreadcrumbProps = {
  activeTextColour: '#000000',
  backgroundColour: '#f0e4d7',
  textColour: '#000000',
};

export const brandFooterProps = {
  brandPhoneNumber: '+1-800-CONRADS',
  backgroundColour: 'rgb(240, 229, 216)',
  textColour: '#000000',
  copyrightBackgroundColour: '#000000',
  copyrightTextColour: '#FFFFFF',
};
