export function StreamingTV({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M15,18.625H9c-0.2071,0-0.375,0.1679-0.375,0.375S8.7929,19.375,9,19.375h6c0.2071,0,0.375-0.1679,0.375-0.375
                    S15.2071,18.625,15,18.625z"
            />
            <path
              d="M19,6.625H5C4.2411,6.6261,3.6261,7.2411,3.625,8v8C3.6261,16.7589,4.2411,17.3739,5,17.375h14
                    c0.7589-0.0011,1.3739-0.6161,1.375-1.375V8C20.3739,7.2411,19.7589,6.6261,19,6.625z M19.625,16
                    c-0.0003,0.345-0.28,0.6247-0.625,0.625H5C4.655,16.6247,4.3753,16.345,4.375,16V8C4.3753,7.655,4.655,7.3753,5,7.375h14
                    c0.345,0.0003,0.6247,0.28,0.625,0.625V16z"
            />
            <path
              d="M12,11.375c-0.3452,0-0.625,0.2798-0.625,0.625s0.2798,0.625,0.625,0.625s0.625-0.2798,0.625-0.625
                    C12.6247,11.655,12.345,11.3753,12,11.375z"
            />
            <path
              d="M10.3516,10.3213c-0.1461-0.1466-0.3833-0.147-0.53-0.001c-0.9277,0.9266-0.9285,2.4298-0.0019,3.3575
                    c0.0006,0.0006,0.0013,0.0013,0.0019,0.0019c0.1483,0.1446,0.3857,0.1417,0.5303-0.0066c0.1425-0.146,0.142-0.3792-0.001-0.5247
                    c-0.6341-0.6343-0.6341-1.6625,0-2.2968C10.4975,10.7053,10.4978,10.4679,10.3516,10.3213z"
            />
            <path
              d="M13.6484,10.3213c-0.146,0.1467-0.1455,0.3839,0.001,0.53c0.6341,0.6343,0.6341,1.6625,0,2.2968
                    c-0.1483,0.1446-0.1512,0.382-0.0066,0.5303c0.1446,0.1483,0.382,0.1512,0.5303,0.0066
                    c0.0019-0.0018,0.0038-0.0037,0.0056-0.0056c0.9277-0.9266,0.9285-2.4298,0.0019-3.3575
                    c-0.0006-0.0006-0.0013-0.0013-0.0019-0.0019c-0.1467-0.1461-0.3841-0.1455-0.5302,0.0012
                    C13.6485,10.3212,13.6484,10.3213,13.6484,10.3213z"
            />
            <path
              d="M15.209,9.2607c-0.146,0.1467-0.1456,0.3839,0.001,0.53c1.22,1.22,1.22,3.198,0,4.418
                    c-0.1467,0.1462-0.1471,0.3836-0.001,0.5303c0.1462,0.1467,0.3836,0.1471,0.5303,0.0009
                    c1.5133-1.5122,1.5141-3.9648,0.0019-5.4781c-0.0006-0.0006-0.0013-0.0013-0.0019-0.0019
                    C15.5926,9.1137,15.3552,9.1141,15.209,9.2607z"
            />
            <path
              d="M8.791,9.2607c-0.1462-0.1465-0.3833-0.1469-0.53-0.0009c-1.5133,1.5122-1.5141,3.9648-0.0019,5.4781
                    c0.0006,0.0006,0.0013,0.0013,0.0019,0.0019c0.1418,0.1509,0.379,0.1583,0.5299,0.0166s0.1583-0.379,0.0166-0.5299
                    C8.8019,14.2204,8.796,14.2146,8.79,14.209c-1.22-1.22-1.22-3.198,0-4.418c0.1467-0.1461,0.1472-0.3835,0.0011-0.5302
                    C8.7911,9.2608,8.791,9.2607,8.791,9.2607z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
