export function Night({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.2656,14.417a.3748.3748,0,0,0-.42-.0762,6.8888,6.8888,0,0,1-2.8535.6182A6.959,6.959,0,0,1,9.041,8.0078a6.8888,6.8888,0,0,1,.6182-2.8535.3747.3747,0,0,0-.4961-.4961A7.6975,7.6975,0,1,0,19.3418,14.8369.3766.3766,0,0,0,19.2656,14.417Zm-6.94,4.208A6.9465,6.9465,0,0,1,8.6162,5.8027,7.6068,7.6068,0,0,0,8.291,8.0078a7.71,7.71,0,0,0,7.7012,7.7012,7.6068,7.6068,0,0,0,2.2051-.3252A6.963,6.963,0,0,1,12.3252,18.625Z" />
      </g>
    </svg>
  );
}
