import type { HTMLAttributes, ReactNode } from 'react';
import { createContext, useContext } from 'react';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;
export type HeadingLevelContext = [
  // Should the heading level increase?
  boolean,
  // The current heading level defined in the context
  HeadingLevel
];

const DEFAULT_HEADING_LEVEL = 2;
const DEFAULT_CONTEXT_VALUE: HeadingLevelContext = [false, DEFAULT_HEADING_LEVEL];

const HeadingLevelContext = createContext<HeadingLevelContext>(DEFAULT_CONTEXT_VALUE);

export function useHeadingLevel() {
  return useContext(HeadingLevelContext);
}

/**
 * Calculate the heading level based on props passed via the HeadingLevelProvider component
 * 1) Always return the level if it has been explicitly defined via the level prop e.g. <HeadingLevelProvider level="1" />
 * 2) Return the parent level + 1, if the <HeadingLevelProvider /> component has been nested inside another <HeadingLevelProvider />
 * 3) Fallback to the DEFAULT_HEADING_LEVEL at the top of this file
 */
function calculateHeadingLevel({
  level,
  parent,
}: {
  level?: HeadingLevel;
  parent: {
    level?: HeadingLevel;
    shouldIncreaseLevel?: boolean;
  };
}) {
  if (level) {
    return level;
  }

  const { level: parentLevel, shouldIncreaseLevel } = parent;

  if (shouldIncreaseLevel && parentLevel) {
    return (parentLevel + 1) as HeadingLevel;
  }

  if (parentLevel) {
    return parentLevel;
  }

  return DEFAULT_HEADING_LEVEL;
}

export interface HeadingLevelProviderProps {
  /** Any valid `ReactNode` */
  children: ReactNode;
  /** The level that headings should render as. Nested `<HeadingLevelProvider>`s will increase this number automatically. */
  level?: HeadingLevel;
  /**
   * Increase the heading level when nested inside a `<HeadingLevelProvider>` component.
   * This can be used to stop the heading level increasing if a parent heading in the component is set to conditionally
   * render.
   * */
  shouldIncreaseLevel?: boolean;
  /**
   * Should heading levels increase or always use the fallback value?
   * This prop only needs to be set on the first provider.
   * */
  isEnabled?: boolean;
}

/**
 * Automatically increase the heading level for child `<HeadingLevel />` components. You should wrap any child headings
 * with this provider so levels increase.
 * The first `<HeadingLevelProvider />` must have the `isEnabled` prop set for heading levels to start increasing.
 */
export function HeadingLevelProvider({
  children,
  level,
  shouldIncreaseLevel = true,
  isEnabled = false,
}: HeadingLevelProviderProps) {
  const [isParentEnabled, parentLevel] = useHeadingLevel() ?? DEFAULT_CONTEXT_VALUE;

  return (
    <HeadingLevelContext.Provider
      value={[
        isParentEnabled || isEnabled,
        calculateHeadingLevel({ level, parent: { level: parentLevel, shouldIncreaseLevel } }),
      ]}
    >
      {children}
    </HeadingLevelContext.Provider>
  );
}

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  /**
   * The heading level that should be used if this component is either not rendered inside the `<HeadingLevelProvider />`
   * component OR a parent `<HeadingLevelProvider />` hasn't enabled stacking
   * */
  headingLevelFallback?: HeadingLevel;
}

/**
 * Automatically render the correct heading level when this component is a child of the `<HeadingLevelProvider />`
 * component.
 * Defaults to a `<h2>` if `headingLevelFallback` prop is not defined.
 * The `headingLevelFallback` prop will be used when `HeadingLevelContext` is `undefined` OR `<HeadingLevelProvider />`
 * has the `isEnabled` prop set to `false`.
 */
export function HeadingLevel({
  children,
  headingLevelFallback = DEFAULT_HEADING_LEVEL,
  ...props
}: HeadingProps) {
  const context = useHeadingLevel();
  let Tag = `h${headingLevelFallback}`;

  if (context !== undefined && context[0]) {
    const level = context[1];
    Tag = level > 6 ? 'span' : `h${level}`;
  }

  return <Tag {...props}>{children}</Tag>;
}
