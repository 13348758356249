export type GetHotelAddressLinkData = {
  addressLine1: string;
  city: string;
  countryCode: string;
  hotelName: string;
  stateCode?: string | null;
};

export function getHotelAddressLink({
  addressLine1,
  city,
  hotelName,
  countryCode,
  stateCode,
}: GetHotelAddressLinkData) {
  const stateCountries = ['US', 'CA', 'MX'];
  const state =
    stateCode && countryCode && stateCountries.includes(countryCode) ? `, ${stateCode}` : '';
  const query = `${hotelName}, ${addressLine1} ${city}${state} ${countryCode}`;
  const params = new URLSearchParams({ api: '1', query }).toString();
  return `https://www.google.com/maps/search/?${params}`;
}
