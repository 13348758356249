import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./curryqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-16 fill-current text-bg antialiased sm:w-24 lg:w-32" />
);

export const hotelLogoFooter = <HotelLogo className="w-32 fill-current text-bg antialiased" />;
