/**
 * CURIO BRAND PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  separatorColor: '#000',
  textColorOnLoad: '#000',
  bgColorOnLoad: 'rgba(255,255,255,1)',
  textColorOnActive: '#000',
  bgColorOnActive: '#FFF',
  bgSubmenuColor: '#FFF',
  seperatorClasses: 'px-8',
  menuItems: {
    colorOnHover: undefined,
    paddingClasses: undefined,
    case: undefined,
    underline: {
      active: true,
      color: 'rgb(87, 167, 185)',
    },
    itemActive: {
      classes: undefined,
      bgColor: undefined,
      bgText: undefined,
    },
  },
  iconMenu: {
    extraClasses: '!px-2',
    fontSize: undefined,
    color: undefined,
    iconColor: undefined,
    activeColor: undefined,
    activeIconColor: undefined,
    separatorColor: undefined,
    underline: true,
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: 'btn-primary bg-primary',
    extraClasses: undefined,
    backgroundColor: undefined,
    backgroundColorHover: undefined,
    textColor: undefined,
    textColorHover: undefined,
    textCase: undefined,
  },
};

export const brandBreadcrumbProps = {
  activeTextColour: '#FFFFFF',
  backgroundColour: 'var(rgb(--bg-primary))',
  textColour: '#FFFFFF',
};

export const brandFooterProps = {
  brandPhoneNumber: '+1-844-HI-CURIO',
  backgroundColour: 'var(rgb(--bg-primary))',
  textColour: '#FFFFFF',
  copyrightBackgroundColour: '#FFFFFF',
  copyrightTextColour: 'var(rgb(--text-primary))',
};
