export function Spin({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M14.14,8.0371c.03.1358.0518.3369.0772.5645a3.4477,3.4477,0,0,0,.5752,1.9375.3894.3894,0,0,0,.1494.09s.7539.2354,2.7227.84a.375.375,0,0,0,.2207-.7168c-1.5528-.4766-2.3487-.7237-2.6153-.8067a4.53,4.53,0,0,1-.3066-1.4277A6.529,6.529,0,0,0,14.87,7.87a2.77,2.77,0,0,0-.7754-1.41,1.4744,1.4744,0,0,0-1.2656-.3174,2.9913,2.9913,0,0,0-1.835.792L8.3916,9.2324a2.2392,2.2392,0,0,0-.6172,1.8262,1.7746,1.7746,0,0,0,1.2891,1.1719,7.3008,7.3008,0,0,1,1.8877,1.3154.3779.3779,0,0,0,.2451.0908.375.375,0,0,0,.2451-.6592,7.4693,7.4693,0,0,0-2.1357-1.457c-.4659-.1611-.7364-.3779-.8028-.6426A1.5056,1.5056,0,0,1,8.915,9.7686l2.5616-2.2608a2.3221,2.3221,0,0,1,1.4824-.624q.1-.0088.1787-.0088a.92.92,0,0,1,.3828.0645.4158.4158,0,0,0,.0654.0712A1.9778,1.9778,0,0,1,14.14,8.0371Z" />
          <path d="M13.4258,8.7578a.3794.3794,0,0,0-.5225-.0928l-2.3652,1.6563a.3755.3755,0,0,0-.16.293.38.38,0,0,0,.1367.3046l2.0713,1.6993c-.0732.4052-.3193,1.6015-1.1425,4.833a.3751.3751,0,0,0,.2705.456.3836.3836,0,0,0,.0927.0117.375.375,0,0,0,.3633-.2822A51.9351,51.9351,0,0,0,13.3633,12.49a.375.375,0,0,0-.1358-.3164L11.373,10.6523l1.96-1.372A.376.376,0,0,0,13.4258,8.7578Z" />
          <path d="M7,12.625A3.375,3.375,0,1,0,10.375,16,3.3784,3.3784,0,0,0,7,12.625Zm0,6A2.625,2.625,0,1,1,9.625,16,2.6281,2.6281,0,0,1,7,18.625Z" />
          <path d="M17,12.625A3.375,3.375,0,1,0,20.375,16,3.3784,3.3784,0,0,0,17,12.625Zm0,6A2.625,2.625,0,1,1,19.625,16,2.6281,2.6281,0,0,1,17,18.625Z" />
          <path d="M17,8.375A1.875,1.875,0,1,0,15.125,6.5,1.8767,1.8767,0,0,0,17,8.375Zm0-3A1.125,1.125,0,1,1,15.875,6.5,1.1266,1.1266,0,0,1,17,5.375Z" />
        </g>
      </g>
    </svg>
  );
}
