import { isBrowser } from '@dx-ui/utilities-is-browser';
import type { EnvKeys, PublicEnv } from './types';

/**
 * Isomorphic function that returns all environment variables.
 * @note This is only available on the client when the `EnvScript` component is used.
 */
export function allEnv() {
  return isBrowser ? getClientEnv() : getServerEnv();
}

/**
 * Isomorphic getter that returns a specific environment variable.
 * @note This is only available on the client when the `EnvScript` component is used.
 * @param key
 */
export function env(key: EnvKeys, defaultValue?: string) {
  return allEnv()[key] || defaultValue || '';
}

function getClientEnv() {
  if (!window.__ENV && window.__NEXT_DATA__?.buildId === 'development') {
    // eslint-disable-next-line no-console
    console.warn(
      'Environment variables are not available.  Did you forget to include the `EnvScript` component?'
    );
  }
  return window.__ENV || {};
}

function getServerEnv() {
  return process.env as unknown as PublicEnv;
}
