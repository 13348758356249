import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { NewWindow } from '@curated-property/icon-list';
import cx from 'classnames';
import type { SectionRowProps } from './hotel-policies.types';
import get from 'lodash/get';

export function SectionRow({
  rowLabel,
  rowText,
  rowCopy,
  rowUrl,
  rowMultiLine,
  keyNumber,
}: SectionRowProps) {
  const { t, i18n } = useTranslation();
  const commonNamespace = i18n?.store?.data?.['en']?.['common'] || {};

  return (
    <li
      key={`sectionLi${keyNumber}`}
      className={cx('block w-full p-2', { 'sm:flex': rowLabel !== null })}
    >
      {rowLabel && !rowCopy && (rowText || rowMultiLine?.length > 0) && (
        <div key={`labelKey${keyNumber}`} className="block w-full font-extrabold sm:w-1/2 lg:w-1/3">
          {t(rowLabel)}
        </div>
      )}
      {rowText && !rowCopy && (
        <div
          key={`label2${keyNumber}`}
          className={cx('block w-full', {
            'sm:w-1/2 lg:w-2/3': rowLabel !== null,
          })}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: get(commonNamespace, rowText) ? t(rowText) : rowText || '',
            }}
          />
          {rowUrl && (
            <p>
              <Link href={rowUrl} target="_blank" rel="noopener noreferrer">
                {t('policies.accessibilityLinkLabel')}
                <NewWindow className="inline size-6" />
              </Link>
            </p>
          )}
        </div>
      )}
      {rowCopy && (
        <div key={`labelRowCopy${keyNumber}`} className={cx('block w-full')}>
          {typeof rowCopy === 'string' ? (
            <p
              dangerouslySetInnerHTML={{
                __html: get(commonNamespace, rowCopy) ? t(rowCopy) : rowCopy || '',
              }}
            />
          ) : (
            rowCopy
          )}
          {rowUrl && (
            <p>
              <Link href={rowUrl} target="_blank" rel="noopener noreferrer">
                {t('policies.accessibilityLinkLabel')}
                <NewWindow className="inline size-6" />
              </Link>
            </p>
          )}
        </div>
      )}
      {rowMultiLine?.length > 0 && (
        <div
          key={`multiLine${keyNumber}`}
          className={cx('block w-full', {
            'sm:w-1/2 lg:w-2/3': rowLabel !== null,
          })}
        >
          <ul key={`sectionLiUl${keyNumber}`}>
            {rowMultiLine?.map((i, int) => {
              return <li key={`multiLi${int.toString()}`}>{i}</li>;
            })}
          </ul>
        </div>
      )}
    </li>
  );
}
