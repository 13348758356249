/**
 * HILTON ALL INCLUSIVE PROPS
 *  - Header
 *  - Footer
 *  - Breadcrumbs
 */

export const brandHeaderProps = {
  headerClassName: undefined,
  navStyles: undefined,
  textColorOnLoad: '#000000',
  bgColorOnLoad: 'rgba(255, 255, 255, 1)',
  textColorOnActive: '#000000',
  bgColorOnActive: 'rgba(255, 255, 255, 1)',
  bgSubmenuColor: 'rgba(255, 255, 255, 1)',
  separatorColor: '#000000',
  separatorClasses: undefined,
  fontWeight: undefined,
  menuItems: {
    colorOnHover: undefined,
    paddingClasses: undefined,
    case: undefined,
    underline: {
      active: true,
      color: '#83235E',
      size: undefined,
    },
    itemActive: {
      classes: undefined,
      bgColor: undefined,
      bgText: undefined,
    },
  },
  iconMenu: {
    extraClasses: undefined,
    fontSize: 'text-xs',
    color: '#000000',
    iconColor: '#83235E',
    activeColor: '#000000',
    activeIconColor: '#83235E',
    separatorColor: '#000000',
    underline: true,
  },
  honorsSignIn: {
    backgroundColor: undefined,
    backgroundHighlightColor: undefined,
    textColor: undefined,
    textHighlightColor: undefined,
  },
  bookingButton: {
    mainClasses: undefined,
    extraClasses: 'rounded-none',
    backgroundColor: 'transparent',
    backgroundColorHover: '#83235E',
    borderColor: '#83235E',
    borderColorHover: 'transparent',
    textColor: '#83235E',
    textColorHover: '#ffffff',
    textCase: undefined,
  },
};

export const brandBreadcrumbProps = {
  activeTextColour: '#FFFFFF',
  backgroundColour: '#CE3262',
  textColour: '#FFFFFF',
};

export const brandFooterProps = {
  brandPhoneNumber: undefined,
  backgroundColour: '#FFFFFF',
  textColour: '#000000',
  copyrightBackgroundColour: '#CE3262',
  copyrightTextColour: '#FFFFFF',
};

export const brandLogoProps = {
  hotelLogoColourActive: '#000000',
  hotelLogoColourInactive: '#000000',
};

export const brandModalProps = {
  modalBackgroundColour: '#83235E',
  modalBackgroundOpacity: 0.98,
  // modalCloseButtonBackgroundColour: '#ff0099',
  // modalCloseButtonIconColour: '#ffffff'
};
