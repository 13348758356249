import { createMetrics } from './react-metrics';
import AdobeTagManager from './AdobeTagManager/AdobeTagManager';
import metricsConfig from './config/metricsConfig';
export * from './types/imageMetrics';
export * from './types/types';
export * from './utils/clearImageAnalyticsData';
export * from './utils/trackEvent';
export * from './utils/utils';

export { AdobeTagManager, createMetrics, metricsConfig };
