export function Ski({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M19.1738,11.3613l-3.2275-0.8906c0.3869-1.2897,0.4792-2.6499,0.27-3.98c-0.009-0.0416-0.0252-0.0813-0.0479-0.1172
            c-0.4236-0.6419-1.1615-1.0042-1.9284-0.9467c-0.974,0.1451-1.8922,0.5457-2.6611,1.1611
            c-0.1676,0.1219-0.2047,0.3566-0.0829,0.5242c0.1202,0.1654,0.3507,0.2041,0.5184,0.0871
            c0.6562-0.5394,1.445-0.8931,2.2842-1.0244c0.4666-0.0289,0.9167,0.1775,1.1992,0.55c0.1632,1.3122,0.0356,2.6442-0.374,3.9014
            c-0.0589,0.1982,0.054,0.4066,0.2522,0.4656c0.0026,0.0008,0.0052,0.0015,0.0078,0.0022l3.5908,0.99
            c0.0325,0.0094,0.0662,0.014,0.1,0.0137c0.2071,0,0.375-0.168,0.3749-0.3751c0-0.1685-0.1125-0.3163-0.2749-0.3613
            L19.1738,11.3613z"
            />
            <path
              d="M18.834,19.2861c-0.4685,0.3673-1.1015,0.4458-1.6455,0.2041l-5.126-2.7917l2.8457-2.5823
            c0.3765-0.2807,0.6133-0.7105,0.6494-1.1787c0.0007-0.3031-0.124-0.593-0.3447-0.8008l-2.5791-2.1074l0.701-0.5684l0.4869,0.2243
            l-0.0365,0.45c-0.0164,0.2064,0.1373,0.3872,0.3437,0.4043l0.03,0.001c0.1953-0.0004,0.3577-0.1501,0.374-0.3447l0.1943-2.3955
            c0.0164-0.2064-0.1373-0.3872-0.3437-0.4043c-0.1973-0.0242-0.3769,0.1162-0.4011,0.3136
            C13.9812,7.7197,13.9804,7.7298,13.98,7.74l-0.0931,1.1483L7.9965,6.174L8.209,5.7451c0.0917-0.1855,0.0156-0.4103-0.1699-0.5019
            c-0.1855-0.0917-0.4103-0.0156-0.502,0.17L7.3156,5.86L6.5361,5.501c-0.1882-0.0868-0.4112-0.0046-0.498,0.1836
            s-0.0046,0.4112,0.1836,0.498l0.76,0.3505l-0.2,0.4034C6.69,7.122,6.7661,7.3468,6.9516,7.4385s0.4103,0.0156,0.502-0.1699
            l0.2089-0.4217l2.2763,1.0488l-0.35,0.2811C9.0651,8.6078,8.7442,9.2371,8.703,9.9141c0.0575,0.6285,0.4166,1.19,0.963,1.5059
            l2.5332,1.6709l-2.524,2.3075L4.18,12.4053c-0.1831-0.0968-0.41-0.0268-0.5068,0.1563
            c-0.0949,0.1796-0.0296,0.4021,0.1474,0.5019l12.999,7.08c0.3047,0.1562,0.643,0.2357,0.9854,0.2314
            c0.5167-0.0068,1.0194-0.1684,1.4433-0.4639c0.1726-0.1143,0.2198-0.3469,0.1055-0.5195
            c-0.1143-0.1726-0.3469-0.2198-0.5195-0.1055l0,0L18.834,19.2861z M13.0605,13.3193c0.1529-0.1392,0.164-0.376,0.0248-0.5289
            c-0.0212-0.0233-0.0453-0.0438-0.0716-0.0611l-2.9268-1.9307c-0.3429-0.1865-0.5778-0.5237-0.6338-0.91
            c0.048-0.4417,0.2667-0.8472,0.6094-1.13L10.7,8.2465l1.876,0.8646l-0.7722,0.6262c-0.1607,0.1302-0.1854,0.3661-0.0552,0.5267
            c0.0161,0.0199,0.0343,0.0381,0.0542,0.0544l2.9151,2.3808c0.0603,0.0664,0.0925,0.1536,0.09,0.2432
            c-0.0487,0.249-0.1918,0.4694-0.3994,0.6152l-3.0426,2.7613l-0.9944-0.5416L13.0605,13.3193z"
            />
            <path
              d="M18.5,3.625c-1.0355,0-1.875,0.8395-1.875,1.875s0.8395,1.875,1.875,1.875s1.875-0.8395,1.875-1.875
            C20.3741,4.4649,19.5351,3.6259,18.5,3.625z M18.5,6.625c-0.6213,0-1.125-0.5037-1.125-1.125s0.5037-1.125,1.125-1.125
            s1.125,0.5037,1.125,1.125C19.6241,6.121,19.121,6.6241,18.5,6.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
