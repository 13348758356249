import gql from 'graphql-tag-transform.macro';

export const PatchworkGrid = gql`
  fragment PatchworkGrid on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_PatchworkGrid {
      repeater {
        horizontalDirection
        title
        copy
        buttons {
          buttonStyle
          fieldGroupName
          link {
            target
            title
            url
          }
        }
        image1 {
          altText
          sourceUrl
          title
          caption
        }
        image1CaptionImageCaption {
          captionText
          captionLink {
            target
            title
            url
          }
        }
        image1Cropping
        image1CroppingAutoPosition
        image1CroppingCropHeight
        image1CroppingCropType
        image1CroppingCropWidth
        image1CroppingEnableCropping
        image1CroppingOrientation
        image1CroppingXPosition
        image1CroppingXPositionAdvanced
        image1CroppingYPosition
        image1CroppingYPositionAdvanced
        image2 {
          altText
          sourceUrl
          title
          caption
        }
        image2CaptionImageCaption {
          captionText
          captionLink {
            target
            title
            url
          }
        }
        image2Cropping
        image2CroppingAutoPosition
        image2CroppingCropHeight
        image2CroppingCropType
        image2CroppingCropWidth
        image2CroppingEnableCropping
        image2CroppingOrientation
        image2CroppingXPosition
        image2CroppingXPositionAdvanced
        image2CroppingYPosition
        image2CroppingYPositionAdvanced
        image3 {
          altText
          sourceUrl
          title
        }
        image3CaptionImageCaption {
          captionText
          captionLink {
            target
            title
            url
          }
        }
        image3Cropping
        image3CroppingAutoPosition
        image3CroppingCropHeight
        image3CroppingCropType
        image3CroppingCropWidth
        image3CroppingEnableCropping
        image3CroppingOrientation
        image3CroppingXPosition
        image3CroppingXPositionAdvanced
        image3CroppingYPosition
        image3CroppingYPositionAdvanced
      }
      patchworkGridSettings {
        imageCaptionBackgroundColor
        imageCaptionTextColor
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        animationDirection
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
