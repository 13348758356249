export function CheckIn({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M13.7344,14.7344,11,17.47l-.7344-.7353a.3756.3756,0,0,0-.5312.5312l1,1a.3771.3771,0,0,0,.5312,0l3-3a.3756.3756,0,0,0-.5312-.5312Z" />
          <path d="M12,2.625A4.38,4.38,0,0,0,7.625,7a1.375,1.375,0,0,0,2.75,0,1.625,1.625,0,0,1,3.25,0c0,3.8389-1.8486,4.625-4.625,4.625A1.377,1.377,0,0,0,7.625,13v7A1.377,1.377,0,0,0,9,21.375h6A1.377,1.377,0,0,0,16.375,20V7A4.38,4.38,0,0,0,12,2.625ZM15.625,20a.6256.6256,0,0,1-.625.625H9A.6256.6256,0,0,1,8.375,20V13A.6256.6256,0,0,1,9,12.375c2.5029,0,5.375-.6113,5.375-5.375a2.375,2.375,0,0,0-4.75,0,.625.625,0,0,1-1.25,0,3.625,3.625,0,0,1,7.25,0Z" />
        </g>
      </g>
    </svg>
  );
}
