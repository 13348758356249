const RTL_LANGUAGES = ['ar', 'he'];

/**
 * Returns whether a locale is LTR (left to right) or RTL (right to left)
 *
 * Currently arabic (ar) is only supported RTL locale
 * @param lang - ISO code for locale
 */
export const getLanguageDirection = (lang?: string) =>
  !!lang && RTL_LANGUAGES.includes(lang.toLowerCase()) ? 'rtl' : 'ltr';

/**
 * Returns a boolean as to whether a locale is LTR (left to right) or RTL (right to left)
 *
 * Currently arabic (ar) is only supported RTL locale
 * @param lang
 */
export const isRtl = (lang?: string) => getLanguageDirection(lang) === 'rtl';
