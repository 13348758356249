import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dxbahhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#ffffff" className="w-24 antialiased md:w-28" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#ffffff" className="mr-2 w-24 antialiased md:w-28" />
);
