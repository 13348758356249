import type { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { WeddingsLogo } from '@curated-property/icons';

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  const footerLogo =
    props?.pageSlug === 'celebrations' ? (
      <div className="py-6">
        <WeddingsLogo fillColor="#000" />
      </div>
    ) : null;

  return <SharedFooter {...props} footerNavOverride={footerLogo} />;
};
