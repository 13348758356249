import { type SVGAttributes } from 'react';
const CoupleTurndownIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="50"
    height="37"
    viewBox="0 0 50 37"
    {...props}
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M44.2548658,16 C46.8009705,16 48.8845997,18.0602781 48.9953684,20.6155854 L49,20.82964 L49,36 L47,36 L47,30.5 L1,30.5 L1,36 L-1,36 L-1,20.82964 C-1,18.2480389 1.0148155,16.1179878 3.53406169,16.0047356 L3.74513417,16 L44.2548658,16 Z M44.2548658,18 L3.74513417,18 C2.29862047,18 1.09242849,19.1786252 1.00506518,20.6576948 L1,20.82964 L1,28.5 L47,28.5 L47,20.82964 C47,19.3287585 45.8473826,18.094545 44.4206548,18.0051806 L44.2548658,18 Z M39.7814416,-1 C43.1353258,-1 45.875752,1.75553026 45.9958925,5.16058621 L46,5.39373089 L46,14 L44,14 L44,5.39373089 C44,3.04346526 42.2025002,1.11640303 39.9842239,1.00507953 L39.7814416,1 L8.21929825,1 C5.97615595,1 4.11259456,2.85784484 4.00491373,5.18126686 L4,5.39373089 L4,14 L2,14 L2,5.39373089 C2,1.96010227 4.66602686,-0.871597297 7.99155901,-0.995754992 L8.21929825,-1 L39.7814416,-1 Z M18.6518701,5 C20.4253685,5 21.8953859,6.36696758 21.9946576,8.09192957 L22,8.27807084 L22,14 L20,14 L20,8.27807084 C20,7.62799612 19.4703919,7.07350705 18.7898736,7.00673527 L18.6518701,7 L11.3473534,7 C10.6490581,7 10.0758692,7.51391586 10.0069483,8.14945714 L10,8.27807084 L10,14 L8,14 L8,8.27807084 C8,6.52898858 9.40870391,5.10154966 11.1586069,5.00518536 L11.3473534,5 L18.6518701,5 Z M36.6518701,5 C38.4253685,5 39.8953859,6.36696758 39.9946576,8.09192957 L40,8.27807084 L40,14 L38,14 L38,8.27807084 C38,7.62799612 37.4703919,7.07350705 36.7898736,7.00673527 L36.6518701,7 L29.3473534,7 C28.6490581,7 28.0758692,7.51391586 28.0069483,8.14945714 L28,8.27807084 L28,14 L26,14 L26,8.27807084 C26,6.52898858 27.4087039,5.10154966 29.1586069,5.00518536 L29.3473534,5 L36.6518701,5 Z" />
  </svg>
);

export default CoupleTurndownIcon;
