export function Link({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18.2705,5.7305a3.7722,3.7722,0,0,0-5.335-.001L11.1338,7.5312a.3757.3757,0,0,0,.5312.5313L13.4668,6.26a3.0942,3.0942,0,0,1,4.2734.0009,3.02,3.02,0,0,1-.0009,4.2725l-2.4024,2.4023a3.0564,3.0564,0,0,1-4.2724.001,3.0306,3.0306,0,0,1-.4581-.6113.3751.3751,0,1,0-.6464.3809,3.76,3.76,0,0,0,4.205,1.7255,3.72,3.72,0,0,0,1.7032-.9648L18.27,11.0645a3.771,3.771,0,0,0,.001-5.334Z" />
          <path d="M12.335,15.9375,10.5332,17.74A3.0942,3.0942,0,0,1,6.26,17.7393a3.0214,3.0214,0,0,1,.0009-4.2735l2.4-2.4013a3.06,3.06,0,0,1,4.2744-.001,3.0306,3.0306,0,0,1,.4581.6113.3751.3751,0,1,0,.6464-.3809A3.6812,3.6812,0,0,0,9.835,9.5684a3.72,3.72,0,0,0-1.7041.9658l-2.4,2.4013a3.7721,3.7721,0,0,0,5.334,5.335l1.8017-1.8017a.3757.3757,0,0,0-.5312-.5313Z" />
        </g>
      </g>
    </svg>
  );
}
