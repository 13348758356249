import { useEffect, useRef } from 'react';

function usePreviousValue<T = unknown>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export { usePreviousValue };
