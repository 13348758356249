import type { ReactElement } from 'react';
import { useContext } from 'react';
import { EventCalendarLineItem } from './event-calendar-line-item';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { useTranslation } from 'next-i18next';
import { format, getDaysInMonth, endOfWeek, startOfWeek, differenceInDays } from 'date-fns';
import * as locales from 'date-fns/locale';
import cx from 'classnames';
import {
  formatHourDisplay,
  compareTimes,
  sortByStartDate,
  convertTimeTo24Hour,
} from './event-calendar-helpers';
import {
  dayNameArray,
  categoryDefault,
  eventCalendarLocalesSundayStart,
} from './lib/event-calendar-constants';
import type {
  EventCalendarDayProps,
  EventCalendarRegularEventProps,
  SpecialEventPropsForType,
} from './lib/event-calendar-props';
import { GIS_Array } from '../functions/global-instance-styles';

export default function EventCalendarDay({
  dateStringNom,
  monthName,
  dateNumber,
  specialEventEntryArray,
  regularEventsArray,
  dayOfWeekKey,
  selectedLocales,
  selectedCategories,
  disabledState,
  multiDateEventsInWeek,
  instanceStyles,
  globalStyles,
}: EventCalendarDayProps) {
  const locale = locales?.enUS;
  // Safari doesn't properly display the date string with hyphens, so convert to slashes.
  const dateStringSafariSafe = dateStringNom.replace(/-/g, '/');
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  const dateSplit = dateStringSafariSafe?.split(/\//g);
  const year = parseInt(dateSplit?.[0]);
  const monthKey = parseInt(dateSplit?.[1]) - 1;
  const daysInMonth = getDaysInMonth(new Date(year, monthKey));

  const { t } = useTranslation();

  // All events that should appear in this day item, whether a special or regularly scheduled event.
  let eventsScheduledToday = [];
  const multiDateEventsScheduledToday = [];
  const hotelInfoContext = useContext(EventCalendarInfoContext);

  const specialEventObjects = Object.values(hotelInfoContext?.specialEvents);

  const dateNumberNoDashes = dateStringNom.replace(/[-]/g, '');
  const dateInt = parseInt(dateNumberNoDashes);

  function selectedCategoryDisplayFilter(nodeBase, hotelInfoContext) {
    let doesItemShow = true;

    // Compare item category against available options; set to default if not in full list.
    const categoryFiltered =
      hotelInfoContext?.eventCategoriesWithCustom?.indexOf(nodeBase?.category) !== -1
        ? nodeBase?.category
        : categoryDefault;

    // If both classification types have values, then the item must match a member of both arrays
    if (selectedCategories.length && selectedLocales.length) {
      // If both classification types have values, then the item must match a member of both arrays
      doesItemShow =
        selectedCategories.indexOf(categoryFiltered) !== -1 &&
        selectedLocales.indexOf(nodeBase?.onSiteOrOffSite) !== -1
          ? true
          : false;
    } else {
      if (selectedCategories.length || selectedLocales.length) {
        doesItemShow =
          selectedCategories.indexOf(categoryFiltered) !== -1 ||
          selectedLocales.indexOf(nodeBase?.onSiteOrOffSite) !== -1
            ? true
            : false;
      }
    }

    return doesItemShow;
  }

  // Specially-scheduled events
  specialEventEntryArray?.forEach((event) => {
    // Check for multi-day events
    const eventStart = event?.node?.calendarOfEvents?.startDate;
    const eventEnd = event?.node?.calendarOfEvents?.endDate;

    if (eventStart === dateNumberNoDashes && (!eventEnd || eventStart === eventEnd)) {
      eventsScheduledToday.push(event);
    } else {
      if (eventEnd && eventEnd !== eventStart) {
        multiDateEventsScheduledToday.push(event);
      }
    }
  });

  // Uses the dayOfWeek value to interpolate 'mondaySchedule','tuesdaySchedule',etc...
  const dayKeyed = `${dayNameArray[dayOfWeekKey]}Schedule`;

  // Regular events
  regularEventsArray?.forEach((obj) => {
    obj?.forEach((objMember) => {
      if (dayOfWeekKey !== null) {
        const objSchedule = objMember?.node?.RegularlyScheduledEvents;
        // A regular event can be set to be held daily --or-- on select days of the week but not both
        const objNodeRegEvents = objMember?.node?.RegularlyScheduledEvents;

        // If an event date range is provided, exclude it
        const dateRangeStart = objNodeRegEvents?.eventDisplayStartDate
          ? parseInt(objNodeRegEvents?.eventDisplayStartDate)
          : null;
        const dateRangeEnd = objNodeRegEvents?.eventDisplayEndDate
          ? parseInt(objNodeRegEvents?.eventDisplayEndDate)
          : null;

        const todayDateNumber =
          !dateNumberNoDashes || dateNumberNoDashes === '' ? null : parseInt(dateNumberNoDashes);

        // Create a date check to ensure events with limited date runs only show within their set range.
        let includeEvent = true;

        // Dates provided must be in yyyymmdd format
        const between = (todayDate, minDate, maxDate) => {
          return todayDate >= minDate && todayDate <= maxDate;
        };

        // Beginning and end values are provided
        if ((dateRangeStart || dateRangeEnd) && todayDateNumber) {
          // If there's a start date but no end date set
          if (dateRangeStart && !dateRangeEnd && todayDateNumber < dateRangeStart)
            includeEvent = false;
          // If there's no start date but a concluding date set
          if (!dateRangeStart && dateRangeEnd && todayDateNumber > dateRangeEnd)
            includeEvent = false;
          // If both start and end dates are set
          if (dateRangeStart && dateRangeEnd) {
            if (!between(todayDateNumber, dateRangeStart, dateRangeEnd)) includeEvent = false;
          }
        }

        // Condition for events held on select days of the week
        if (includeEvent) {
          if (
            objSchedule?.[dayKeyed]?.scheduled &&
            (objSchedule?.[dayKeyed]?.startTime || objSchedule?.[dayKeyed]?.allDay)
          )
            eventsScheduledToday.push(objMember);
          // Condition for events scheduled every day
          if (
            objSchedule?.allDays?.heldDaily &&
            (objSchedule?.allDays?.allDay || objSchedule?.allDays?.startTime)
          )
            eventsScheduledToday.push(objMember);
        }
      }
    });
  });

  // Remove duplicates
  eventsScheduledToday = [...new Set(eventsScheduledToday)];

  // The final array for presentation -- this shows all-day events first then the sorted partial-day events
  const fullDayEventCollection = [];
  const partialDayEvents: (EventCalendarRegularEventProps | SpecialEventPropsForType)[] = [];
  // Sort event by start time or no start time (e.g. an all day event)
  eventsScheduledToday?.forEach((obj) => {
    // Test regular events for all-day or part-of-day scheduling
    const reg = obj?.node?.RegularlyScheduledEvents;
    if (reg !== undefined) {
      if (
        (reg?.[dayKeyed]?.startTime || reg?.allDays?.startTime !== null) &&
        (!reg?.allDays?.allDay || !reg?.[dayKeyed]?.allDay)
      ) {
        partialDayEvents.push(obj);
      } else {
        if (reg?.[dayKeyed]?.allDay === true || reg?.allDays?.allDay === true) {
          fullDayEventCollection.push(obj);
        }
      }
    }

    // Test special events for all-day or part-of-day scheduling
    const spl = obj?.node?.calendarOfEvents;
    if (spl !== undefined) {
      // Is this an all-day event (no start time... not possible to have an end time w/out start stime)
      if (!spl?.startTime) {
        fullDayEventCollection.push(obj);
      } else {
        if (spl?.startTime !== null) {
          partialDayEvents.push(obj);
        }
      }
    }
  });

  // Make a key of times to use to re-sort the larger objects
  if (partialDayEvents.length > 1) {
    // Make an array for sorting
    const partialDayTitleAndTimes = [];

    // Extrapolate time for sorting
    partialDayEvents?.forEach((e) => {
      const splStart =
        'calendarOfEvents' in e.node && e.node !== undefined
          ? e?.node?.calendarOfEvents?.startTime
          : '';

      // Determine value based on whether it's a daily-held event or not
      const nodeEvents =
        'RegularlyScheduledEvents' in e.node &&
        e.node !== undefined &&
        e?.node?.RegularlyScheduledEvents;
      let regStart = '';
      if (nodeEvents && !nodeEvents?.allDays?.heldDaily) {
        regStart = nodeEvents?.[dayKeyed]?.startTime;
      } else {
        if (nodeEvents?.allDays?.heldDaily && !nodeEvents?.allDays?.allDay) {
          regStart = nodeEvents?.allDays?.startTime;
        }
      }
      const timeVal = !splStart ? regStart : convertTimeTo24Hour(splStart);

      const obj = {
        title: e?.node?.title,
        databaseId: 'databaseId' in e.node && e?.node?.databaseId,
        calendarId: 'calendarId' in e.node && e?.node?.calendarId,
        start: timeVal,
      };
      partialDayTitleAndTimes.push(obj);
    });

    // Sort by start time
    partialDayTitleAndTimes.sort(compareTimes);
    // Now that key array is sorted, use it to sort the collection of full objects based on whether a databaseId (recurring events) or calendarId (special events) has a value.
    partialDayTitleAndTimes?.forEach((e) => {
      //let itemToMatch = Object;
      if (e?.databaseId && !e?.calendarId) {
        const itemToMatch = partialDayEvents?.find(
          ({ node }) => 'databaseId' in node && node?.databaseId === e?.databaseId
        );
        fullDayEventCollection.push(itemToMatch);
      } else {
        if (e?.calendarId && !e?.databaseId) {
          const itemToMatch = partialDayEvents.find(
            ({ node }) => 'calendarId' in node && node?.calendarId === e?.calendarId
          );
          fullDayEventCollection.push(itemToMatch);
        }
      }
    });
  } else {
    if (partialDayEvents.length === 1) {
      fullDayEventCollection.push(partialDayEvents[0]);
    }
  }

  const dateButtons: Array<ReactElement> = [];

  // Multi-date event display
  /**
   * Multi-date events are placed differently than the other event types. To preserve the placement of an event in
   * an unbroken row across date cells if there is more than one scheduled, the number of date line row slots remain
   * consistent across the entire week, even if any of the events don't apply to a specific date.
   */

  const multiDateEventArray = [];
  if (multiDateEventsInWeek.length) {
    multiDateEventsInWeek.forEach((el) => {
      [...specialEventObjects]?.forEach((item) => {
        (item as any)?.node?.calendarId === el && multiDateEventArray.push(item);
      });
    });
  }

  const multiDateEventObjectsSorted = multiDateEventArray.sort(sortByStartDate);

  // Sort overlapping multi-date events according to whichever event's start date is earlier

  multiDateEventObjectsSorted?.forEach((e, i) => {
    const nodeBase = e?.node?.calendarOfEvents;

    const startDtStr = nodeBase?.startDate;
    const endDtStr = nodeBase?.endDate;
    const startDate: number = parseInt(startDtStr);
    const endDate: number = parseInt(endDtStr);
    const startDateDateFormatted = new Date(
      startDtStr.slice(0, 4),
      startDtStr.slice(4, 6) - 1,
      startDtStr.slice(6, 8)
    );
    const endDateDateFormatted = new Date(
      endDtStr.slice(0, 4),
      endDtStr.slice(4, 6) - 1,
      endDtStr.slice(6, 8)
    );

    const lang = hotelInfoContext?.localeLanguage;

    const weekStartsOnInt = eventCalendarLocalesSundayStart.indexOf(lang) !== -1 ? 0 : 1;

    const endOfThisWeek = endOfWeek(new Date(year, monthKey, dateNumber), {
      weekStartsOn: weekStartsOnInt,
    });
    const startOfThisWeek = startOfWeek(new Date(year, monthKey, dateNumber), {
      weekStartsOn: weekStartsOnInt,
    });

    if (dateInt) {
      // Use whichever is earlier -- end of the week or the end date of the event
      const calculatedLastDateInWeek =
        endDateDateFormatted >= endOfThisWeek ? endOfThisWeek : endDateDateFormatted;
      const calculatedFirstDateInWeek =
        startDateDateFormatted <= startOfThisWeek ? startOfThisWeek : startDateDateFormatted;

      const multiDateDaysRemaining = calculatedLastDateInWeek || null;

      let todayToEndDiff: number =
        differenceInDays(calculatedLastDateInWeek, calculatedFirstDateInWeek) + 1;

      if (dateNumber !== undefined && todayToEndDiff + dateNumber >= daysInMonth) {
        todayToEndDiff = daysInMonth - dateNumber + 1;
      }

      // Extra check against multi-date events not overlapping on weeks that end on Sunday (0)
      const daysRemainingForEventThisWk =
        differenceInDays(endOfThisWeek, startDateDateFormatted) + weekStartsOnInt;
      if (todayToEndDiff > daysRemainingForEventThisWk && weekStartsOnInt === 1) {
        todayToEndDiff = daysRemainingForEventThisWk;
      }

      let daysToEndOfMultiDate = multiDateDaysRemaining ? todayToEndDiff?.toString() : '';

      if (dateNumber === 1) {
        const firstOfNewMOnth = new Date(year, monthKey, 1);

        const followMonthRemainingDays =
          differenceInDays(endDateDateFormatted, firstOfNewMOnth) + 1;
        daysToEndOfMultiDate = followMonthRemainingDays?.toString();
      }

      if (
        dateInt === startDate ||
        (dayOfWeekKey === weekStartsOnInt && calculatedFirstDateInWeek > startDateDateFormatted) ||
        (dateNumber === 1 && dateInt >= startDate && dateInt <= endDate)
      ) {
        let eventTimeString = '';
        // Start time will be in display only
        if (dateStringNom && dateStringSafariSafe) {
          eventTimeString += !nodeBase?.startTime
            ? t('calendar.allDay')
            : formatHourDisplay(dateStringNom, nodeBase?.startTime, locale);

          // Provide visibility control based on selected filters
          const itemShow = selectedCategoryDisplayFilter(nodeBase, hotelInfoContext);

          const event = (
            <EventCalendarLineItem
              regularOrSpecialEvent="multidate"
              dateStringNom={dateStringNom}
              dateStringNoDashes={dateNumberNoDashes}
              eventDateStr={format(new Date(dateStringSafariSafe), 'EEEE LLLL do yyyy', {
                locale,
              })}
              eventTitle={e?.node?.title}
              eventTimes={eventTimeString}
              eventContent={e?.node}
              eventLineItemLocale={
                !nodeBase?.onSiteOrOffSite ? 'On-site' : nodeBase?.onSiteOrOffSite
              }
              eventLineItemCategory={!nodeBase?.category ? 'Attractions' : nodeBase?.category}
              dateNumber={dateNumber}
              showHide={itemShow}
              key={`btn${i}`}
              disabledState={disabledState}
              dayOfWeek={dayOfWeekKey || undefined}
              multiDateDaysRemaning={multiDateDaysRemaining && parseInt(daysToEndOfMultiDate)}
            />
          );

          dateButtons.push(
            <li
              className={cx(
                'relative mx-0 mb-2 h-10  p-0',
                daysToEndOfMultiDate === '1' && (dayOfWeekKey === 0 || dateNumber === 1)
                  ? 'lg:mx-0'
                  : 'lg:mx-0'
              )}
            >
              {event}
            </li>
          );
        }
      } else {
        dateButtons.push(
          <li className={cx('relative m-0 mb-1 p-0 lg:-mx-2.5 lg:h-10')} tabIndex={-1} />
        );
      }
    }
  });

  // Place the sorted nodes into a list of events
  fullDayEventCollection?.forEach((e, i) => {
    // Determine if this is a special or regularly-scheduled event node
    const nType = !e?.node?.calendarOfEvents ? 'regular' : 'special';
    // Make node shortcut
    const nodeBase =
      nType === 'regular' ? e?.node?.RegularlyScheduledEvents : e?.node?.calendarOfEvents;

    // Make time string display for button
    let eventTimeString = '';
    // Extrapolate start time based on type
    if (nType === 'regular') {
      // All day regular: event is held daily but has no start time
      if (nodeBase?.allDays?.heldDaily === true) {
        if (nodeBase?.allDays?.allDay === true) {
          eventTimeString = t('calendar.allDay');
        } else {
          if (!nodeBase?.allDays?.allDay) {
            if (nodeBase?.allDays?.startTime !== null) {
              eventTimeString = formatHourDisplay(
                dateStringNom,
                nodeBase?.allDays?.startTime,
                locale
              );
            }
            if (nodeBase?.allDays?.startTime !== null && nodeBase?.allDays?.endTime !== null) {
              eventTimeString += ` ${t('calendar.to')} ${formatHourDisplay(
                dateStringNom,
                nodeBase?.allDays?.endTime,
                locale
              )}`;
            }
          }
        }
      } else {
        // If this is a regular event that isn't held daily and has a start time and (optional) end time
        if (!nodeBase?.allDays?.heldDaily) {
          if (!nodeBase?.[dayKeyed]?.allDay) {
            eventTimeString =
              nodeBase?.[dayKeyed]?.startTime &&
              formatHourDisplay(dateStringNom, nodeBase?.[dayKeyed]?.startTime, locale);
            eventTimeString +=
              nodeBase?.[dayKeyed]?.startTime && nodeBase?.[dayKeyed]?.endTime
                ? ` ${t('calendar.to')} ${formatHourDisplay(
                    dateStringNom,
                    nodeBase?.[dayKeyed]?.endTime,
                    locale
                  )}`
                : '';
          } else {
            // If this is a regular day, not scheduled every day of the week, but one that runs all day
            if (nodeBase?.[dayKeyed]?.allDay) {
              eventTimeString = t('calendar.allDay');
            }
          }
        }
      }
    } else {
      // If this is a special, non-regular event.
      if (nType === 'special') {
        eventTimeString = !nodeBase?.startTime
          ? t('calendar.allDay')
          : formatHourDisplay(dateStringNom, nodeBase?.startTime, locale);
        eventTimeString +=
          nodeBase?.startTime && nodeBase?.endTime
            ? ` ${t('calendar.to')} ${formatHourDisplay(dateStringNom, nodeBase?.endTime, locale)}`
            : '';
      }
    }

    // Provide visibility control based on selected filters
    const itemShow = selectedCategoryDisplayFilter(nodeBase, hotelInfoContext);

    const event = (
      <EventCalendarLineItem
        regularOrSpecialEvent={nType}
        dateStringNom={dateStringNom}
        dateStringNoDashes={dateNumberNoDashes}
        eventDateStr={format(new Date(dateStringSafariSafe), 'EEEE LLLL do yyyy', {
          locale,
        })}
        eventTitle={e?.node?.title}
        eventTimes={eventTimeString}
        eventContent={e?.node}
        eventLineItemLocale={
          !e?.node?.calendarDailyEvents
            ? e?.node?.RegularlyScheduledEvents?.onSiteOrOffSite
            : e?.node?.calendarDailyEvents?.onSiteOrOffSite
        }
        eventLineItemCategory={!nodeBase?.category ? 'Attractions' : nodeBase?.category}
        dateNumber={dateNumber}
        showHide={itemShow}
        key={`btn${i}`}
        disabledState={disabledState}
      />
    );
    dateButtons.push(<li key={`li${dateStringNom}${i}`}>{event}</li>);
  });

  return (
    dateNumber && (
      <>
        <h3
          aria-label={`${monthName} ${dateNumber.toString()}`}
          id={`${monthName}${dateNumber.toString()}`}
          className={`mb-2 font-bold ${!disabledState ? 'text-primary' : 'text-text-disabled'}`}
          style={{
            color: !disabledState && inlineStyles?.eventsCalAdditionalTextFilterColor,
          }}
        >
          <span className="block lg:hidden">{`${monthName} ${dateNumber.toString()}`}</span>
          <span className="hidden lg:block">{dateNumber.toString()}</span>
        </h3>
        {dateButtons?.length ? (
          <ul
            data-testid="eventCalendarList"
            className="m-0 flex list-none flex-col p-0"
            role="list"
            aria-labelledby={`${monthName}${dateNumber.toString()}`}
          >
            {dateButtons?.map((e) => {
              return e;
            })}
          </ul>
        ) : (
          ''
        )}
      </>
    )
  );
}
