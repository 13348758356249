import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./auhetci/hotel-logo').then((i) => i.HotelLogo)
);
const BrandLogo = dynamic<{ className: string }>(() =>
  import('./auhetci/brand-logo').then((i) => i.BrandLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="xl:w-42 w-28 fill-current text-bg antialiased" />
);

export const hotelLogoFooter = (
  <BrandLogo className="h-12 w-[180px] fill-current text-bg antialiased md:w-48" />
);
