const commaOverrides = {
  ar: '،',
  ja: '、',
  'zh-hans': '、',
  'zh-hant': '、',
};

export function getLocaleComma({ locale }: { locale: string }) {
  return commaOverrides[locale as keyof typeof commaOverrides] || ',';
}
