import type { ReactElement } from 'react';

interface WrappedSubtitleProps {
  children?: ReactElement;
  className?: string;
}

export function WrappedSubtitle({ children, className }: WrappedSubtitleProps) {
  const wrappedComponent = (
    <div className={className} data-element-id="subtitle-wrapper">
      {children}
    </div>
  );

  return wrappedComponent;
}
