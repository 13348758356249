import gql from 'graphql-tag-transform.macro';

export const RetailRail = gql`
  fragment RetailRail on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_RetailRail {
      fieldGroupName
      copy
      title
      buttons {
        buttonStyle
        fieldGroupName
        link {
          target
          title
          url
        }
      }
      retailRailComponentSettings {
        componentBackgroundColor
        componentBackgroundPosition
        componentBackgroundRepeat
        componentBackgroundSize
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundColor
        contentBackgroundPosition
        contentBackgroundRepeat
        contentBackgroundSize
        contentBackgroundImage {
          sourceUrl
        }
        textAlignment
        textColor
        titleColor
        closeIconColour
        closeTextColour
        showHide
      }
    }
  }
`;
