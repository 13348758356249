import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./deshihf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="h-24 fill-current text-current antialiased" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="h-24 fill-current text-current antialiased" fillColor="#ffffff" />
);
