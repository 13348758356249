import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./auahhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-30 h-20 fill-current text-bg antialiased md:w-36" />
);

export const hotelLogoFooter = <HotelLogo className="h-20 w-32 fill-current text-bg antialiased" />;
