import { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import cx from 'classnames';
import type { StyleObject } from '../functions/global-instance-styles';
import { GIS_Array, GIS_Padder, GIS_TextAlignment } from '../functions/global-instance-styles';
import type { WordpressPageInfoQuery } from '@dx-ui/queries-dx-curated-ui/generated/wp';
import { EditorialSnippet } from '@dx-ui/osc-editorial-snippet';
import { iconmapper } from '../functions/helper';
import type { CropProps } from '../cropped-image/cropped-image';
import { customLoader } from '../cropped-image/cropped-image';
import { appliedCloudinaryParams, makeRandomInt } from '@curated-property/utils';

export type EditorinalSnippetIconType = keyof typeof iconmapper;

export interface EditorialSnippetComponentProps {
  heading?: string;
  subtitle?: string;
  description?: string;
  iconList?: EditorinalSnippetIconType | string;
  link?: {
    target?: string;
    title?: string;
    url?: string;
  };
  image?: {
    altText?: string;
    sourceUrl?: string;
    slug?: string;
    title?: string;
  };
  cropImageToCircle?: boolean;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

interface CombinedEditorialSnippetProps extends CropProps, EditorialSnippetComponentProps {}

/* istanbul ignore next */
export function editorialSnippetPropMapper(
  componentData: EditorialSnippetComponentProps &
    CropProps & {
      editorialSnippetComponentSettings?: StyleObject;
    },
  globalData: NonNullable<
    NonNullable<WordpressPageInfoQuery['componentStyles']>['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    heading: componentData?.heading,
    subtitle: componentData?.subtitle,
    description: componentData?.description,
    iconList: componentData?.iconList,
    link: componentData?.link,
    image: componentData?.image,
    cropImageToCircle: componentData?.cropImageToCircle,
    globalStyles: globalData?.editorialSnippetComponentSettings,
    instanceStyles: componentData?.editorialSnippetComponentSettings,
    enableCropping: componentData?.enableCropping || false,
    cropType: componentData?.cropType || '',
    xPosition: componentData?.xPosition || '',
    xPositionAdvanced: componentData?.xPositionAdvanced || '',
    yPosition: componentData?.yPosition || '',
    yPositionAdvanced: componentData?.yPositionAdvanced || '',
    cropWidth: componentData?.cropWidth || '',
    cropHeight: componentData?.cropHeight || '',
    autoPosition: componentData?.autoPosition || false,
  };
}

export function EditorialSnippetComponent({
  heading,
  subtitle,
  description,
  iconList,
  cropImageToCircle,
  link,
  image,
  enableCropping,
  cropType,
  xPosition,
  xPositionAdvanced,
  yPosition,
  yPositionAdvanced,
  cropWidth,
  cropHeight,
  autoPosition,
  instanceStyles,
  globalStyles,
}: CombinedEditorialSnippetProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);

  const imgSource = enableCropping
    ? customLoader({
        src: image?.sourceUrl,
        width: '1920',
        height: '1080',
        crop: enableCropping,
        cropType,
        cropHeight,
        cropWidth,
        xPosition,
        xPositionAdvanced,
        yPosition,
        yPositionAdvanced,
        autoPosition,
      })
    : image?.sourceUrl;

  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'editorialSnippetComponent';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `#${componentStyleID}`;
  // Title, subtitle and text colors
  const titleColor = inlineStyles?.titleColor;
  const subtitleColor = inlineStyles?.subtitleColor;
  const textColor = inlineStyles?.textColor;

  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment) || 'left';
  let styleString = '';

  if (titleColor || subtitleColor || textColor) {
    if (titleColor) styleString += `${mainSelector} hgroup h2 { color: ${titleColor} !important; }`;

    if (subtitleColor)
      styleString += `${mainSelector} hgroup div[class*="editorial-snippet-short-description"] { color: ${subtitleColor} !important;}`;

    if (textColor) styleString += `${mainSelector} div { color: ${textColor} !important; }  `;
  }

  if (textAlignment !== 'left') {
    styleString += `
      ${mainSelector} hgroup h2,
      ${mainSelector} div,
      ${mainSelector} hgroup div[class*="editorial-snippet-short-description"] {
        text-align: ${textAlignment} !important;
      }
      `;
  }

  useEffect(() => {
    if (!document.getElementById(styleElementID) && styleString) {
      const $style = document.createElement('style');
      $style.setAttribute('id', styleElementID);
      document.head.appendChild($style);
      $style.innerHTML = styleString;
    }
  }, [styleElementID, styleString]);

  const iconMap = iconmapper();
  const CellIcon = iconMap[(iconList as keyof typeof iconMap) || ''];

  useEffect(() => {
    if (iconList && link?.url && componentStyleID) {
      const componentChildrenOuter = document.querySelector(
        `#${componentStyleID} > [data-element-id="editorial-snippet-content"] > div > div > div`
      );
      if (!componentChildrenOuter) return;
      if (componentChildrenOuter?.children?.length > 0) {
        const targetDiv =
          componentChildrenOuter?.children[componentChildrenOuter?.children.length - 1];
        if (targetDiv && targetDiv.firstChild.nodeName === 'A') {
          // Insert icon selection
          const link = targetDiv.firstChild;
          const icon = document.createElement('span');
          icon.setAttribute('class', 'inline-block -mb-3 mr-1');
          icon.setAttribute('data-testid', 'editorialLinkIcon');
          icon.setAttribute('data-element-id', 'editorial-link-icon');
          icon.innerHTML = renderToString(
            <CellIcon className="max-h-8 w-8" fillColor={inlineStyles?.textColor} />
          );
          targetDiv.prepend(icon, link);
        }
      }
    }
  }, [iconList, link?.url, componentStyleID, CellIcon, inlineStyles?.textColor]);

  return (
    <div
      id={componentStyleID}
      style={{
        background: inlineStyles?.componentBackgroundImage
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : inlineStyles.componentBackgroundColor,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        backgroundColor: inlineStyles.componentBackgroundColor,
      }}
      className={inlineStyles?.showHide ? 'hidden' : ''}
      data-element-id="editorial-snippet-wrapper"
      data-testid="editorialSnippetOuterWrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          maxWidth: inlineStyles?.containerMaxWidth && `${inlineStyles?.containerMaxWidth}px`,
          background: inlineStyles?.contentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )})`
            : inlineStyles.contentBackgroundColor,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: inlineStyles?.contentBackgroundColor,
          alignItems: inlineStyles?.textAlignment || 'flex-start',
          justifyContent: inlineStyles?.textAlignment,
        }}
        data-element-id="editorial-snippet-content"
        data-testid="editorialSnippetContentWrapper"
      >
        <EditorialSnippet
          heading={heading}
          shortDescription={subtitle}
          longDescription={description}
          isSquareImage={cropImageToCircle === true ? false : true}
          image={{
            url: imgSource,
            altText: !image?.altText ? ' ' : image?.altText,
          }}
          ctaLink={{
            url: link?.url,
            label: link?.title,
            isNewWindow: link?.target === '_blank' ? true : false,
          }}
        />
      </div>
    </div>
  );
}
