import matcher from 'matcher';
import type { Rule } from '@dx-shared/rule-engine';
import { RuleEngine } from '@dx-shared/rule-engine';

const HOST_REGEX = new RegExp('^www(.\\w+)?.hilton.com$');
const ENGLISH = 'en';
const SLASH = '/';
const DOUBLE_SLASH = '//';

/**
 * Utility to determine if a provided URL supports translations for a non-English page by validating the URL
 * against the tranlate URLs rule.
 *
 * Also corrects OHW URLs to fix the URL origin to the current origin, and appends a trailing slash to the path.
 *
 * The URL config returned contains the following fields:
 * 1. The transformed URL based on the language and/or standardization.
 * 2. A flag indicating if a redirect is necessary if the provided URL doesn't match the final transformed URL.
 * 3. A flag indicating if a speedbump should be displayed if we need to route the user from a non-English URL to an English URL.
 *    The speedbump flag can also be use server side to determine if a page not found error (404) should be displayed to the user.
 * 4. A flag indicating if the URL is an internal application URL.
 * 1475984025
 *
 * @param tranlateUrlsRuleEngine
 * @param url
 * @param origin
 * @param language
 */
export function getUrlConfig(
  tranlateUrlsRuleEngine: RuleEngine,
  url: string,
  origin: string,
  language = ENGLISH,
  appBasePaths?: string[],
  facts?: UrlRuleFacts
): UrlConfig {
  if (url && url.startsWith('#')) {
    return { appUrl: false, redirect: false, speedbump: false, url };
  }
  // Parse the URL. Sets the origin if the URL is a relative path.
  const parsedURL: URL = new URL(url, origin);
  const originalUrl = parsedURL.toString();
  const originalPath = originalUrl.slice(parsedURL.origin.length);
  // Correct the host and language in the path of all OHW URLs.
  const isOHWUrl = HOST_REGEX.test(parsedURL.host);
  if (isOHWUrl) {
    // Standarize all URLS by appending a trailing slash.
    if (!parsedURL.pathname.endsWith(SLASH)) {
      parsedURL.pathname = `${parsedURL.pathname}${SLASH}`;
    }
    // Replace the language in the path to English. Assume all pages are only supported in English
    // and will swicth it back if the page is supported in-language.
    parsedURL.pathname = parsedURL.pathname.replace(`/${language}/`, `/${ENGLISH}/`);
    // If the OHW URL origin doesn't match the current origin, correct the OHW URL with the current origin.
    if (parsedURL.origin !== origin) {
      parsedURL.host = origin.slice(origin.indexOf(DOUBLE_SLASH) + 2);
      parsedURL.protocol = origin.slice(0, origin.indexOf(DOUBLE_SLASH));
    }
  }

  let speedbump = false;

  // For non-english languages, determine if the URL is translated
  if (language !== ENGLISH) {
    // When sending an URL into the rule engine, use the relative path for OHW URLs, and the absolute path for external URLs.
    let ruleUrl = isOHWUrl ? parsedURL.pathname : `${parsedURL.origin}${parsedURL.pathname}`;
    // Standarize all URLS by appending a trailing slash so the rule engine matching is consistent.
    if (!ruleUrl.endsWith(SLASH)) {
      ruleUrl = `${ruleUrl}${SLASH}`;
    }

    // Run the rule to determine if the URL is translated
    const ruleFacts = { ...(facts || {}), url: ruleUrl, language };
    const isTranslatedUrl = tranlateUrlsRuleEngine.run(ruleFacts);

    // Display speedbump if URL is not translated
    speedbump = !isTranslatedUrl;
    // If a OHW URL and the URL is translated, then replace the language in the path
    if (isOHWUrl && isTranslatedUrl) {
      parsedURL.pathname = parsedURL.pathname.replace(`/${ENGLISH}/`, `/${language}/`);
    }
  }

  const finalUrl = parsedURL.toString();
  const finalPath = finalUrl.slice(parsedURL.origin.length);
  const appUrl =
    isOHWUrl &&
    !!appBasePaths?.some((appBasePath) => matcher.isMatch(parsedURL.pathname, appBasePath));
  const redirect = isOHWUrl && originalPath !== finalPath;

  // Return URL config
  return { appUrl, redirect, speedbump, url: isOHWUrl ? finalPath : finalUrl };
}

export type { Rule };

export { RuleEngine };

export type UrlConfig = {
  appUrl: boolean;
  redirect: boolean;
  speedbump: boolean;
  url: string;
};

export type UrlRuleFacts = {
  brandCode?: string;
  ctyhocn?: string;
};
