import classnames from 'classnames';
import { useMemo } from 'react';

type UseVideoPlayerClassesProps = Partial<{ isLandscape: boolean }>;

export function useVideoPlayerClasses({ isLandscape = true }: UseVideoPlayerClassesProps) {
  const baseControlsClasses = 'absolute -bottom-6 start-1/2 z-10 -translate-x-1/2';

  return useMemo(
    () => ({
      captionClassNames: classnames(
        'absolute inset-x-0 bottom-8 mx-auto w-[calc(100%-2rem)] sm:max-w-fit',
        {
          'sm:bottom-16 sm:mb-2': isLandscape,
        }
      ),
      controlsClassNames: {
        default: classnames(baseControlsClasses, 'rtl:translate-x-1/2', {
          'sm:bottom-4 sm:start-4 sm:translate-x-0 sm:rtl:translate-x-0': isLandscape,
        }),
        center: classnames(baseControlsClasses, 'lg:bottom-4 rtl:translate-x-1/2'),
        left: classnames(baseControlsClasses, 'lg:bottom-4 lg:start-4 lg:translate-x-0'),
        right: classnames(
          baseControlsClasses,
          'lg:bottom-4 lg:end-4 lg:start-auto lg:translate-x-0'
        ),
      },
      transcriptClassNames: 'container p-4 pt-8 sm:pt-4',
      wrapperClassNames: 'relative',
    }),
    [isLandscape]
  );
}
