import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./laswdwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-current text-bg antialiased lg:w-40" fillColor="#000" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current text-bg antialiased lg:w-40" fillColor="#fff" />
);
