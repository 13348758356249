import gql from 'graphql-tag-transform.macro';

export const Spacer = gql`
  fragment Spacer on Page_Acfflexible_FlexibleComponents {
    ... on Page_Acfflexible_FlexibleComponents_Spacer {
      componentHeight
      group {
        fieldGroupName
        large1024px
        medium768px
        small640px
        extraLarge1280px
        twoXExtraLarge1440px
      }
      fieldGroupName
      spacerComponentSettings {
        fieldGroupName
        showHide
        #ClonedComponentSettingsStart
        componentBackgroundColor
        componentBackgroundSize
        componentBackgroundRepeat
        componentBackgroundPosition
        contentBackgroundColor
        contentBackgroundSize
        contentBackgroundRepeat
        contentBackgroundPosition
        contentBackgroundColorLayerTwo
        subtitleColor
        textAlignment
        textColor
        titleColor
        componentBackgroundImage {
          sourceUrl
        }
        contentBackgroundImage {
          sourceUrl
        }
        paddingTop {
          paddingTopDesktop
          paddingTopTablet
          paddingTopMobile
        }
        paddingBottom {
          paddingBottomDesktop
          paddingBottomTablet
          paddingBottomMobile
        }
        showHide
        hideAnimations
        animationDirection
        hideParallax
        sliderAnimations
        zoomOnHover
        containerMaxWidth
        headingsNoTranslate
        #ClonedComponentSettingsEnd
      }
    }
  }
`;
