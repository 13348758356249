export type Coordinate = {
  latitude: number;
  longitude: number;
};

export type UserLocation = {
  addressBiasCoordinate?: Coordinate;
  addressBiasDegrees?: number;
  city?: string;
  continent?: string;
  coordinate?: Coordinate;
  country?: string;
  marketingBiasCoordinate?: Coordinate;
  marketingBiasDegrees?: number;
  refreshTTL?: number;
  region?: string;
  searchBiasCoordinate?: Coordinate;
  searchBiasDegrees?: number;
  zipCode?: string;
};

/**
 * Generates the coordinate bias based on the zone degrees provided. Defaults to a zone degree of 10.
 *
 * @param coordinate
 * @param biasDegrees
 */
export function getCoordinateBias(coordinate: Coordinate, biasDegrees = 10): Coordinate {
  const calcBias = (point: number): number =>
    Math.floor(point / biasDegrees) * biasDegrees + biasDegrees / 2;
  return {
    latitude: calcBias(coordinate.latitude),
    longitude: calcBias(coordinate.longitude),
  };
}
