import type { AuthClient } from '@dx-ui/framework-auth-provider';
import { makeAuthClient } from '@dx-ui/framework-auth-provider';
import type { IncomingMessage, OutgoingMessage } from 'http';
import type { DefaultOptions } from '@tanstack/react-query';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import type { MakeDefaultFnArgs } from './default-fetch-fn';
import { makeDefaultMutationFn, makeDefaultQueryFn } from './default-fetch-fn';
import type { QueryDocument, QueryVariables } from '@dx-ui/types-graphql';
import { GraphError, ServerAuthError } from '@dx-ui/types-graphql';

export type MakeQueryClientArgs = MakeDefaultFnArgs & {
  reactQueryClientOverrideOptions?: DefaultOptions['queries'];
};

export const CDN_STALE_TIME = 1000 * 60 * 60 * 6; // 6 hours

function retry(failureCount: number, error: unknown) {
  // If there error was a GraphError or ServerAuthError (401/403), then do not retry, we manually retry or handle in the UI
  if (error instanceof ServerAuthError || error instanceof GraphError) {
    return false;
  }
  return failureCount < 2;
}

export function makeQueryClient({
  reactQueryClientOverrideOptions = {},
  ssrMode = false,
  ...makeDefaultFnArgs
}: MakeQueryClientArgs) {
  return new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: makeDefaultQueryFn({
          ...makeDefaultFnArgs,
          ssrMode,
        }),
        ...(!ssrMode
          ? {
              refetchOnWindowFocus: false,
              cacheTime: Infinity,
              retry,
              staleTime: 60 * 60 * 1000, // 1 hour
              ...reactQueryClientOverrideOptions,
            }
          : { ...reactQueryClientOverrideOptions }),
      },
      mutations: {
        mutationFn: makeDefaultMutationFn({
          ...makeDefaultFnArgs,
          ssrMode,
        }),
      },
    },
  });
}

export function makeServerQueryClient({
  appName,
  authClient,
  customHeaders,
  referrer,
  serverResponse,
}: {
  appName: string;
  authClient: AuthClient;
  customHeaders?: Record<string, string>;
  referrer?: string;
  serverResponse: OutgoingMessage;
}) {
  return makeQueryClient({
    appName,
    authClient,
    customHeaders,
    referrer,
    serverResponse,
    ssrMode: true,
  });
}

export function getServerSideClients({
  appName,
  customHeaders,
  request,
  response,
  appId,
  authEndpoints,
  gqlEndpoints,
  referrer,
  language,
}: {
  appName: string;
  request?: IncomingMessage;
  response: OutgoingMessage;
  appId: string;
  authEndpoints: {
    browser: string;
    server: string;
  };
  customHeaders?: Record<string, string>;
  referrer?: string;
  gqlEndpoints: {
    browser: string;
    server: string;
  };
  language: string;
}) {
  const authClient = makeAuthClient({
    appName,
    request,
    ssrMode: true,
    response,
    appId,
    authEndpoints,
    gqlEndpoints,
    language,
  });
  const queryClient = makeServerQueryClient({
    appName,
    authClient,
    customHeaders,
    referrer,
    serverResponse: response,
  });
  return { queryClient, authClient };
}

export async function makeServerSideGql({
  appName,
  queries,
  request,
  response,
  appId,
  authEndpoints,
  customHeaders,
  gqlEndpoints,
  referrer,
  language,
}: {
  appName: string;
  queries: [QueryDocument, QueryVariables][];
  request?: IncomingMessage;
  response: OutgoingMessage;
  appId: string;
  authEndpoints: {
    browser: string;
    server: string;
  };
  customHeaders?: Record<string, string>;
  gqlEndpoints: {
    browser: string;
    server: string;
  };
  referrer?: string;
  language: string;
}) {
  const authClient = makeAuthClient({
    appName,
    request,
    ssrMode: true,
    response,
    appId,
    authEndpoints,
    gqlEndpoints,
    language,
  });
  const queryClient = makeServerQueryClient({
    appName,
    authClient,
    customHeaders,
    referrer,
    serverResponse: response,
  });

  const promises = queries.map((queryKey) => {
    return queryClient.prefetchQuery({ queryKey });
  });

  await Promise.all(promises);
  return {
    queryClient,
    authClient,
    dehydratedState: dehydrate(queryClient),
  };
}
