import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./lonmetw/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-white text-current antialiased" fillColor="#d3d3d0" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="mr-2 w-28 fill-black text-current antialiased sm:mr-0"
    fillColor="#d3d3d0"
  />
);
