type IExpire<T> = {
  expireDate: number;
  instance: T;
};

/**
 * @param ttl milliseconds
 */
export const saveToSession = <T>(object: T, key: string, ttl: number) => {
  const env: IExpire<T> = {
    instance: object,
    expireDate: new Date().getTime() + ttl,
  };
  window.sessionStorage.setItem(key, JSON.stringify(env));
};

export const retrieveFromSession = <T>(key: string) => {
  const env: { expireDate: Date; instance: T } | null = window.sessionStorage.getItem(key)
    ? JSON.parse(window.sessionStorage.getItem(key) as string)
    : null;

  if (!env) return null;

  const ttl = new Date(env.expireDate).getTime() - new Date().getTime();

  if (ttl <= 0) {
    window.sessionStorage.removeItem(key);
    return null;
  }
  return env.instance;
};
