export function Entertainment({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M9.7373,17.59,8.1484,19.1484a1.66,1.66,0,0,1-2.2968,0,1.6249,1.6249,0,0,1,0-2.2968l.0006-.0017.6556-.6565a.3749.3749,0,1,0-.5312-.5293L5.322,16.32,5.32,16.32a2.3751,2.3751,0,0,0,3.3565,3.3613l1.5918-1.5625a1.58,1.58,0,0,1,2.4628,0,.375.375,0,0,0,.5372-.5234A2.3321,2.3321,0,0,0,9.7373,17.59Z" />
          <path d="M15,3.625A4.3487,4.3487,0,0,0,11.7742,5.07c-.0107.0086-.026.005-.0359.015-.0158.0157-.0112.04-.0236.0571a4.3343,4.3343,0,0,0-1.0742,2.7046L6.5273,12.8916a1.378,1.378,0,0,0-.0009,1.9453L7.94,16.251a1.3662,1.3662,0,0,0,.9727.4013,1.3158,1.3158,0,0,0,.9307-.3652L15.1445,12.36a4.3352,4.3352,0,0,0,2.7242-1.084c.0166-.0119.0392-.0075.0542-.0225.0092-.0093.0057-.0237.0139-.0337A4.3593,4.3593,0,0,0,15,3.625ZM9.3545,15.72a.642.642,0,0,1-.8828,0l-1.4151-1.414a.6191.6191,0,0,1-.1816-.4414.6516.6516,0,0,1,.208-.47l3.9453-4.8359,3.3994,3.3994Zm5.7874-4.109L11.3893,7.8581a3.5949,3.5949,0,0,1,.6528-1.9407l5.0473,5.035A3.5952,3.5952,0,0,1,15.1419,11.6107Zm2.51-1.1568-5.1122-5.1a3.6124,3.6124,0,0,1,5.1122,5.1Z" />
          <path d="M10.7344,11.7344l-1,1a.3756.3756,0,0,0,.5312.5312l1-1a.3756.3756,0,0,0-.5312-.5312Z" />
        </g>
      </g>
    </svg>
  );
}
