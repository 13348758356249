import type { FeatureConfig, FeatureToggle } from '@dx-ui/gql-types';

export type FeatureTogglesQueryData = {
  featureToggles: Pick<FeatureToggle, 'name' | 'enabled'>[];
};

export type FeatureConfigsQueryData = FeatureTogglesQueryData & {
  featureConfigs: Pick<FeatureConfig, 'name' | 'conditions' | 'config'>[];
};

export function getIsFeatureToggleEnabled({
  featureToggleData,
  name,
}: {
  featureToggleData: FeatureTogglesQueryData | null | undefined;
  name: string;
}) {
  return (
    featureToggleData?.featureToggles?.find((toggle) => toggle.name === name)?.enabled || false
  );
}

export function getFeatureConfig<T = Record<string, unknown>>({
  featureToggleData,
  name,
}: {
  featureToggleData: FeatureConfigsQueryData | undefined;
  name: string;
}): T | null {
  const toggleEnabled = getIsFeatureToggleEnabled({ featureToggleData, name });
  const allConfigs = featureToggleData?.featureConfigs || [];
  const searchedConfig = allConfigs.find((toggle) => toggle.name === name);
  return toggleEnabled ? searchedConfig?.config : null;
}
