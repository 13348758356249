import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./cunwawa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="w-28 fill-current antialiased lg:w-40" fillColor="#000000" />
);

export const hotelLogoFooter = (
  <HotelLogo className="w-28 fill-current antialiased lg:w-40" fillColor="#ffffff" />
);
