import cx from 'classnames';
import styles from './css/contentStyle.module.css';
import { HeadingStyle } from './includes/heading-style';
import type { ACFAnchorProps } from './global/anchor-link';
import { AnchorLink } from './global/anchor-link';
import { HandleWYSIWYGContent, HandleAnimations, makeBorderWidthValue } from './functions/helper';
import type { CropProps } from './cropped-image/cropped-image';
import { CroppedImage, customLoader } from './cropped-image/cropped-image';
import { ParallaxLayer, isReducedMotion, appliedCloudinaryParams } from '@curated-property/utils';
import type { StyleObject } from './functions/global-instance-styles';
import { GIS_Array, GIS_Padder, GIS_TextAlignment } from './functions/global-instance-styles';
import type { MediaBlocksMediaLayoutsInterface, MediaBlocksBorderInterface } from './media-blocks';
import { mediaBlocksFactory } from './media-blocks';

export function MediaAndCopyOverlayPropMapper(
  componentData?: Props &
    CropProps & {
      image?: { sourceUrl?: string; altText?: string };
      mediaAndCopyOverlayComponentSettings?: StyleObject;
    },
  globalData?: StyleObject
) {
  return {
    title: componentData?.title || '',
    copy: componentData?.copy || '',
    imgSrc: componentData?.image?.sourceUrl || '',
    imgAlt: componentData?.image?.altText || '',
    mediaBlock: componentData?.mediaBlock,
    enableCropping: componentData?.enableCropping || false,
    flipImageHorizontally: componentData?.flipImageHorizontally || false,
    cropType: componentData?.cropType || '',
    xPosition: componentData?.xPosition || '',
    xPositionAdvanced: componentData?.xPositionAdvanced || '',
    yPosition: componentData?.yPosition || '',
    yPositionAdvanced: componentData?.yPositionAdvanced || '',
    cropWidth: componentData?.cropWidth || '',
    cropHeight: componentData?.cropHeight || '',
    autoPosition: componentData?.autoPosition || false,
    buttons: componentData?.buttons,
    layoutMode: componentData?.layoutMode || '',
    globalStyles: globalData?.mediaAndCopyOverlayComponentSettings,
    instanceStyles: componentData?.mediaAndCopyOverlayComponentSettings,
  };
}

interface Props {
  title?: string;
  title_noTx?: string;
  copy?: string;
  imgSrc?: string;
  imgAlt?: string;
  mediaBlock?: MediaBlocksMediaLayoutsInterface;
  flipImageHorizontally?: boolean;
  buttons?: Array<ACFAnchorProps>;
  layoutMode?: string;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

interface CombinedProps extends CropProps, Props {}

export function MediaAndCopyOverlay({
  title,
  title_noTx,
  copy,
  buttons,
  layoutMode,
  imgSrc,
  imgAlt,
  enableCropping,
  cropType,
  xPosition,
  xPositionAdvanced,
  yPosition,
  yPositionAdvanced,
  cropWidth,
  cropHeight,
  autoPosition,
  mediaBlock,
  instanceStyles,
  globalStyles,
  flipImageHorizontally,
}: CombinedProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(inlineStyles?.paddingTop, inlineStyles?.paddingBottom);
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;
  let direction: string, positioning: string;

  switch (layoutMode) {
    case 'Copy / Media':
      direction = 'justify-end';
      positioning = 'lg:left-0';
      break;
    case 'Media / Copy':
      direction = 'justify-start';
      positioning = 'lg:right-0';
      break;
    default:
      direction = 'justify-end';
      positioning = 'lg:left-0';
  }

  //override layout mode with the new horizontal direction field
  switch (inlineStyles?.horizontalDirection) {
    case 'regular':
      direction = 'justify-end';
      positioning = 'lg:left-0';
      break;
    case 'reverse':
      direction = 'justify-start';
      positioning = 'lg:right-0';
      break;
  }

  const insetBorderColour = inlineStyles?.imageInsetBorderColour || '#ffffff';
  const insetBorderWidth = makeBorderWidthValue(inlineStyles?.imageInsetBorderWidth);

  const borderSettings: MediaBlocksBorderInterface = {
    imageInsetBorderWidth: insetBorderWidth,
    imageInsetBorderColour: insetBorderColour,
  };

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : inlineStyles?.horizontalDirection === 'regular'
      ? 'lg:-translate-x-8'
      : 'lg:translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
  });
  const parallaxSpeed = -55;
  const imageArea = mediaBlock ? (
    <div className="aspect-video w-full">
      {mediaBlocksFactory(mediaBlock, 1200, borderSettings)}
    </div>
  ) : (
    <div
      className={cx({
        'scale-x-[-1]': flipImageHorizontally,
      })}
    >
      <CroppedImage
        loader={() => {
          return customLoader({
            src: imgSrc,
            crop: enableCropping,
            width: '1200',
            height: '675',
            cropType,
            cropHeight,
            cropWidth,
            xPosition,
            xPositionAdvanced,
            yPosition,
            yPositionAdvanced,
            autoPosition,
          });
        }}
        src={imgSrc || ''}
        alt={imgAlt || ''}
        layout="responsive"
        className={cx('block transition-all duration-500', {
          'hover:scale-125': zoomOnHover,
        })}
        width="1200"
        height="675"
      />
    </div>
  );

  return (
    <div
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        background: inlineStyles?.componentBackgroundImage
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : inlineStyles.componentBackgroundColor,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || 'top left',
        backgroundColor: inlineStyles.componentBackgroundColor,
      }}
      data-element-id="media-and-copy-overlay-wrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          maxWidth: inlineStyles?.containerMaxWidth && `${inlineStyles?.containerMaxWidth}px`,
        }}
      >
        <div
          className={cx('relative flex flex-col lg:flex-row', direction)}
          data-testid="media-copy-direction"
        >
          <div
            ref={animations?.ref}
            className={cx(
              'lg:w-3/7 z-10 w-full lg:absolute lg:top-1/2 lg:-translate-y-1/2',
              positioning
            )}
          >
            <ParallaxLayer
              hideAnimations={animations?.hideAnimations || inlineStyles?.hideParallax === 'hide'}
              inView={animations?.inView}
              parallaxSpeed={parallaxSpeed}
            >
              <div
                data-element-id="media-copy-overlay-content-wrapper"
                className="bg-bg-alt isolate order-2 p-4 lg:p-16"
                style={{
                  background: inlineStyles?.contentBackgroundImage
                    ? `url(${appliedCloudinaryParams(
                        inlineStyles?.contentBackgroundImage,
                        inlineStyles?.contentBackgroundRepeat
                      )})`
                    : inlineStyles.contentBackgroundColor,
                  backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
                  backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
                  backgroundPosition: inlineStyles?.contentBackgroundPosition || 'left center',
                  backgroundColor: inlineStyles?.contentBackgroundColor,
                  alignItems: inlineStyles?.textAlignment || 'flex-start',
                  justifyContent: inlineStyles?.textAlignment,
                  textAlign: textAlignment,
                }}
              >
                <HeadingStyle
                  text={inlineStyles?.headingsNoTranslate ? title_noTx : title}
                  type="h2"
                  className={cx(
                    animations?.one,
                    'text-inverse font-headline mb-6 text-2xl font-black leading-none md:text-4xl',
                    {
                      OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                    }
                  )}
                  textColorInline={inlineStyles?.titleColor}
                />
                <div
                  className={cx(
                    animations?.two,
                    'text-lg',
                    styles.listStyle,
                    styles.paragraphStyle
                  )}
                  style={{ color: inlineStyles?.textColor }}
                  dangerouslySetInnerHTML={{
                    __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
                  }}
                />
                {buttons && (
                  <div className="mt-6">
                    {buttons?.map((link, key) => {
                      if (!link || !link.link) {
                        return null;
                      }
                      return (
                        <div key={key} className="me-2 inline-block">
                          <AnchorLink
                            url={link.link?.url || ''}
                            title={link.link?.title || ''}
                            target={link.link?.target || ''}
                            buttonStyle={link?.buttonStyle ?? 'primary'}
                            noHorizontalMargin={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </ParallaxLayer>
          </div>
          <div className="relative order-1 overflow-hidden lg:w-10/12">{imageArea}</div>
        </div>
      </div>
    </div>
  );
}
