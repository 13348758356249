import type { MenuProps, FooterProps, BreadcrumbProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { SmallChevron } from '@curated-property/icon-list';
import { MakaiLogo } from '@curated-property/icons';

const myFontWeight = 'bold';

const brandLogo = (
  <MakaiLogo fill="#231f20" className="mr-4 hidden w-36 fill-current pl-4 antialiased sm:block" />
);

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const AdditionalHead = (props: MenuProps) => {
  return (
    props?.pageSlug === 'makai' && (
      <style>{`
          [data-osc-product="shop-form-dates"] > div > span.text-primary,
          [data-element-id="fybr-nav-reset"], [data-element-id="fybr-nav-next"],
          [data-element-id="language-wrapper"] div div div div ul li bdi a span,
          [data-element-id="fybr-nav-prev"] {
            color: #231F20 !important;
          }

          .cp-generic-modal > div, .form-checkbox:checked,
          [data-osc-product='search-dates'] table tbody tr td button.bg-primary {
            background-color: #231F20 !important;
          }

          [data-osc-product='search-dates'] table tbody tr td button.bg-bg-primary {
            background-color: #ebdecc;
          }

          .filter-results:hover {
            color: #231F20 !important;
            border-color: #231F20 !important;
          }

          [data-testid="panel-guests"] button svg.stroke-primary {
            stroke: #231F20 !important;
          }

          [data-element-id="language-wrapper"] div div div div ul li:hover,
          [data-element-id="language-wrapper"] div div div div ul li:hover::before,
          [data-element-id="language-wrapper"] div div div div ul li:hover::after {
            background-color: #231F20 !important;
          }
          
          [data-element-id="language-wrapper"] div div div div ul li:hover bdi a span {
            color: #fff !important;
          }

          [data-element-id="language-wrapper"] div div div div ul li:hover bdi a {
            text-decoration-color: #fff !important;
          }
        `}</style>
    )
  );
};

export const Header = (props: MenuProps) => {
  const makaiHeaderStyles =
    props?.pageSlug === 'makai'
      ? {
          headerClassName: '',
          separatorColor: '#fff',
          textColorOnLoad: '#fff',
          bgColorOnLoad: '#242124',
          textColorOnActive: '#fff',
          bgColorOnActive: '#242124',
          bgSubmenuColor: '#242124',
          fontWeight: myFontWeight,
          menuItems: {
            paddingClasses: 'p-2.5',
            colorOnHover: '#fff',
            underline: {
              active: true,
              color: '#fff',
            },
          },
          iconMenu: {
            underline: true,
          },
          bookingButton: {
            mainClasses: 'btn-teritary',
          },
        }
      : {
          headerClassName: '',
          textColorOnLoad: '#fff',
          bgColorOnLoad: 'rgba(42, 58, 78, 0.7)',
          textColorOnActive: '#fff',
          bgColorOnActive: 'rgba(10, 57, 113, 0.98)',
          bgSubmenuColor: 'rgba(10, 57, 113, 0.97)',
        };

  return (
    <SharedHeader
      {...props}
      brandLogo={props?.pageSlug === 'makai' ? brandLogo : null}
      styleOptions={makaiHeaderStyles}
    />
  );
};
// this override footer

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  const makaiFooterStyles =
    props?.pageSlug === 'makai'
      ? {
          backgroundColor: 'makaiBg',
        }
      : undefined;

  return <SharedFooter {...props} styleOptions={makaiFooterStyles} />;
};

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  const makaiCrumbStyles =
    props?.pageSlug === 'makai'
      ? {
          background: 'background-light',
          anchorClasses: 'text-brand-5 hover:underline',
          activeAnchorClasses: 'text-brand-5 font-regular inline-block',
        }
      : {};

  const makaiDelimiter =
    props?.pageSlug === 'makai' ? (
      <span className="-tx-2 px-2 text-bg">
        <SmallChevron className="inline h-4 w-2" />
      </span>
    ) : (
      <span className="px-2 text-bg">/</span>
    );

  return (
    <SharedBreadCrumbs {...props} styleOptions={makaiCrumbStyles} delimiter={makaiDelimiter} />
  );
};
